export const BookingCalculate = (adults, dataPrice, discount, children, children11, infants, extra_service, totalAmount) => {
    //let totalAmount = 0

    if(adults > 0){
        totalAmount += dataPrice.adult_price*adults;
    }

    if(discount > 0){
        totalAmount -= discount;
    }

    if(children > 0){
        totalAmount += dataPrice.child_price*children;
    }

    if(children11 > 0){
        totalAmount += dataPrice.child_price_2*children11;
    }

    if(infants > 0 && dataPrice.infant_price !== undefined){
        totalAmount += dataPrice.infant_price*infants;
    }

    if(extra_service > 0){
        totalAmount += extra_service;
    }

    return totalAmount;
};