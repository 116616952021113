import { Fragment, useContext, useEffect, useState } from 'react';
import { CruiseContext } from '../../contexts/CruiseContext';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import HtmlToDocx from 'html-docx';
import { ExportCustomerListXLSX } from '../../components/export/ExportCustomerListXLSX';
import CustomerList from './CustomerList';
import { useTranslation } from 'react-i18next';

const Customer = () => {
	const { t } = useTranslation();
	const {
		cruiseState: { cruise },
		FindACruise
	} = useContext(CruiseContext);

	let param1 = window.location.pathname;
	let arrUrl = param1.split('/');
	let idCruise = 0;
	let dateInfo = '';
	let cruiseName = '';
	let checkDay = '';

	if (arrUrl !== null) {
		var arrInfo = arrUrl[2].split('-');
		var cruiseIdInfo = arrInfo[3];
		var dateInfoCheck = `${arrInfo[0]}-${arrInfo[1]}-${arrInfo[2]}`;
		if (cruiseIdInfo) {
			idCruise = cruiseIdInfo;
		}
		if (dateInfoCheck) {
			dateInfo = dateInfoCheck;
		}
	}

	useEffect(() => {
		if (idCruise) {
			FindACruise(idCruise);
		}
	}, [idCruise]); // eslint-disable-line react-hooks/exhaustive-deps

	let dateFile = moment(dateInfo).format('MMMM D, YYYY');

	if (cruise) {
		cruiseName = cruise.name;
		checkDay = cruise.typeDay;
	}

	const docExport = () => {
		HtmlToDocx({
			exportElement: '#html-docx',
			exportFileName: t('Customer List') + ` - ${cruiseName} - ${dateFile}.docx`,
			StringStyle: '',
			margins: { top: 1440, right: 1440, bottom: 1440, left: 1440, header: 720, footer: 720, gutter: 0 }
		});
	};

	const [exportExcelData, setExportExcelData] = useState({});
	const { customerQuantity, exportCustomerRoomData, exportForMaritimeAdministrationData, exportCustomerBasicData } =
		exportExcelData;
	return (
		<Fragment>
			{cruise ? (
				<Helmet>
					<title> {t('Customer List')} - {cruiseName} - Cruises Management Software</title>
				</Helmet>
			) : (
				''
			)}

			<div className='horizontal-mainwrapper container-fluid clearfix'>
				<div className='explain-dashboard mt-5'>
					<div className='explain-header mb-3'>
						<div className='row'>
							<div className='col-md-12'>
								<div className='info-header'>
									<h1 className='float-left'>
										{t('Customer List')} - {cruiseName} - <span>{moment(dateInfo).format('MMMM D, YYYY')}</span>
									</h1>
									<ExportCustomerListXLSX
										exportCustomerBasicData={exportCustomerBasicData}
										fileName='list_customer_basic'
										label={t('Export Customer Basic')}
										dateInfo={dateInfo}
										customerQuantity={customerQuantity}
										classes='export-to-excel'
									/>
									<ExportCustomerListXLSX
										exportCustomerRoomData={exportCustomerRoomData}
										fileName='list_customer'
										label={t('Export Customer Rooms')}
										dateInfo={dateInfo}
										customerQuantity={customerQuantity}
										classes='export-to-excel'
									/>
									<ExportCustomerListXLSX
										fileName='list_customer'
										label={t('Export for Port Authority')}
										dateInfo={dateInfo}
										customerQuantity={customerQuantity}
										exportForMaritimeAdministrationData={exportForMaritimeAdministrationData}
										classes='export-to-excel'
									/>
								</div>
							</div>
						</div>
						<hr />
					</div>
					<div id='html-docx'>
						<div className='row'>
							<div className='col-md-12'>
								<h2 className='title'>
									{t('Customer List')} - {cruiseName}{' '}
									<p className='float-right'>
										<strong>{moment(dateInfo).format('MMMM D, YYYY')}</strong>
									</p>
								</h2>
							</div>
						</div>
						<hr />
						<CustomerList
							cruiseIdProps={idCruise}
							dateInfoProps={dateInfo}
							checkDayProps={checkDay}
							setExportExcelData={setExportExcelData}
						/>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Customer;
