import { Fragment, useContext, useEffect } from 'react';
import { RoomTypeContext } from '../../../contexts/RoomTypeContext';
import { AuthContext } from '../../../contexts/AuthContext';
import UpdateRoomTypeModal from './UpdateRoomTypeModal';
import { Spinner, Card } from 'react-bootstrap';
import DeleteModal from '../DeleteModal';
// import 'datatables.net-dt/js/dataTables.dataTables.min';
// import 'datatables.net-dt/css/jquery.dataTables.min.css';
// import $ from 'jquery';
import { getTable, getTableOptions, getDefaultSort } from '../../../utils/table';
import { ROLES, PERMISSIONS } from '../../../contexts/constants';
import { useTranslation } from 'react-i18next';

const ListRoomType = () => {
	const { t } = useTranslation();
	// Context
	const {
		authState: { user }
	} = useContext(AuthContext);
	const {
		roomTypeState: { roomType, roomTypes, roomTypesLoading },
		getRoomTypes,
		findRoomType,
		deleteRoomType,
		setShowUpdateRoomTypeModal,
		setShowToast
	} = useContext(RoomTypeContext);

	const chooseRoomType = (roomTypeId) => {
		findRoomType(roomTypeId);
		setShowUpdateRoomTypeModal(true);
	};

	useEffect(() => {
		if (!roomTypes || roomTypes.length === 0) {
			getRoomTypes('');
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	// let body = null;
	// setTimeout(function () {
	// 	$('#list-room').DataTable();
	// }, 0);
	if (roomTypesLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info'></Spinner>
			</div>
		);
	}
	if (!roomTypes) {
		return (
			<Fragment>
				<Card className='text-center mx-5 my-5'>
					<Card.Header as='h1'>{t('Hi')} {user.name}</Card.Header>
					<Card.Body>
						<Card.Title>{t('Welcome to Room Type Manage')}</Card.Title>
						<Card.Text>{t('Click the Add Room Type button to create your first Room Type')}</Card.Text>
					</Card.Body>
				</Card>
				{roomType !== null && <UpdateRoomTypeModal />}
			</Fragment>
		);
	}
	const roomTypeTable = getListRoomTypeTable(roomTypes, user, chooseRoomType, deleteRoomType, setShowToast, t);
	return (
		<Fragment>
			{roomTypeTable}
			{roomType !== null && <UpdateRoomTypeModal />}
		</Fragment>
	);
};

const getTableData = (roomTypes, user, chooseRoomType, deleteRoomType, setShowToast, t) => {
	return roomTypes.map((roomType, index) => {
		return {
			index: index + 1,
			id: roomType.id,
			name: roomType.name,
			description: roomType.description,
			action: (
				<div className='row'>
					<div className='col-sm'>
						{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
						user.Permissions.includes(PERMISSIONS.UPDATE_ROOM_TYPE) ? (
							<button
								className='btn btn-success btn-with-icon btn-block'
								onClick={chooseRoomType.bind(this, roomType.id)}
							>
								<i className='fe fe-edit'></i> {t('Edit')}
							</button>
						) : null}
					</div>
					<div className='col-sm'>
						{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
						user.Permissions.includes(PERMISSIONS.DELETE_ROOM_TYPE) ? (
							<DeleteModal
								nameProps={roomType.name}
								idProps={roomType.id}
								deleteFunc={deleteRoomType}
								setShowToast={setShowToast}
							/>
						) : null}
					</div>
				</div>
			)
		};
	});
};

const getColumns = (t) => {
	return [
		{
			dataField: 'index',
			text: 'STT',
			sort: true
		},
		{
			dataField: 'id',
			text: 'ID',
			sort: true
		},
		{
			dataField: 'name',
			text: t('Name'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'description',
			text: t('Description'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'action',
			text: ''
		}
	];
};

const getListRoomTypeTable = (roomTypes, user, chooseRoomType, deleteRoomType, setShowToast, t) => {
	if (!roomTypes || roomTypes.length === 0) return null;
	const tableData = getTableData(roomTypes, user, chooseRoomType, deleteRoomType, setShowToast, t);
	const columns = getColumns(t);
	const defaultSorted = getDefaultSort();
	const options = getTableOptions(tableData.length, t);

	return getTable(tableData, columns, defaultSorted, options);
};

export default ListRoomType;
