import { Fragment, useState, useEffect, useContext } from 'react';
import { apiUrl } from '../../../contexts/constants';
import { ItineraryContext } from '../../../contexts/ItineraryContext';
import OperatingSetting from './OperatingSetting';
import { Link } from 'react-router-dom';
import { getAPI } from '../../../utils/api';
import { getBookingCellBackground } from '../../../utils/common';
import { useTranslation } from 'react-i18next';

const TableOperatingDayTrip = (props) => {
	const { t } = useTranslation();
	const cruise = props.cruiseProps;
	const day = props.dayProps;
	const month = props.monthProps;
	const year = props.yearProps;
	const textCheck = props.textCheckProps;
	const cruiseSetting = props.cruiseSetting;
	const date = `${year}-${month}-${day}`;

	const {
		itineraryState: { itineraries },
		getItineraries
	} = useContext(ItineraryContext);

	// Start: Get all specials , []
	useEffect(() => getItineraries(''), []); // eslint-disable-line react-hooks/exhaustive-deps

	const [bookings, setDataBookings] = useState([]);

	useEffect(() => {
		async function getBookings() {
			try {
				const response = await getAPI(
					`${apiUrl}/customer/bookings`,
					`start_date=${date}&filterFields=cruise_id&filterFieldValues=${cruise.id}&isNight=0`
				);
				setDataBookings(response.data);
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		getBookings();
	}, [cruise.id]); // eslint-disable-line react-hooks/exhaustive-deps

	const prepareBookingData = (bookings) => {
		let data = {};
		if (bookings) {
			bookings.map((item) => {
				if (!item.booking_id) {
					return;
				}
				if (!data[item.booking_id]) {
					data[item.booking_id] = [item];
				} else {
					data[item.booking_id].push(item);
				}
			});
		}
		return data;
	};

	const getNameDuration = (idItinerary) => {
		var duration = 0;
		itineraries.map((itinerary) => {
			if (itinerary.id === idItinerary) {
				duration = itinerary.duration ? itinerary.duration : 0;
			}
		});
		return duration;
	};

	const dataBookings = prepareBookingData(bookings);
	const arrTodays = [];
	Object.keys(dataBookings).forEach((key) => arrTodays.push({ name: key, value: dataBookings[key] }));
	let allAdult = 0;
	let allChild = 0;
	let allChild11 = 0;
	let allInfan = 0;

	return (
		<Fragment>
			<div className='fullWidth100 fullWidth operating'>
				<table className='table table-bordered mg-b-0 text-md-nowrap'>
					<thead>
						<tr>
							<th>STT</th>
							<th>{t('Booking code')}</th>
							<th>{t('Status')}</th>
							<th>{t('Partner')}</th>
							<th>{t('Group name')}</th>
							<th>{t('Pick-up')}</th>
							<th>{t('Itinerary')}</th>
							<th>{t('Adults')}</th>
							<th>{cruiseSetting && cruiseSetting.child_label1? t(cruiseSetting.child_label1): t('Children') + '(5-7)'}</th>
							<th>{cruiseSetting && cruiseSetting.child_lable2? t(cruiseSetting.child_lable2): t('Children') + '(8-11)'}</th>
							<th>{t('Infants')}</th>
							<th>{t('Special requests')}</th>
						</tr>
					</thead>
					<tbody>
						{arrTodays
							? arrTodays.map(
									(booking, i) => (
										booking.value[0].status !== 'Canceled' && booking.value[0].status !== 'Transferred'
											? (booking.value[0].adults > 0 ? (allAdult += booking.value[0].adults) : (allAdult += 0),
											  booking.value[0].children > 0 ? (allChild += booking.value[0].children) : (allChild += 0),
											  booking.value[0].children11 > 0
													? (allChild11 += booking.value[0].children11)
													: (allChild11 += 0),
											  booking.value[0].infants > 0 ? (allInfan += booking.value[0].infants) : (allInfan += 0))
											: '',
										(
											<tr key={i}>
												<td>{i + 1}</td>
												<td>
													<Link
														className='show-booking'
														to={`/bookings/show/${booking.value[0].booking_id}`}
														target='_blank'
													>
														{booking.value[0].new_code}
													</Link>
												</td>
												<td>
													<span className={getBookingCellBackground(booking.value[0].status)}>
														{t(booking.value[0].status)}
													</span>
												</td>
												<td>{booking.value[0].partner_name}</td>
												<td>
													{booking.value.map((customer, index) => (
														<span key={index}>{customer.full_name ? customer.full_name : ''}</span>
													))}
												</td>
												<td dangerouslySetInnerHTML={{ __html: booking.value[0].pickup }} />
												<td>{getNameDuration(booking.value[0].cruise_itinerary_id) + 'N'}</td>
												<td>{booking.value[0].adults}</td>
												<td>{booking.value[0].children > 0 ? booking.value[0].children : 0}</td>
												<td>{booking.value[0].children11 > 0 ? booking.value[0].children11 : 0}</td>
												<td>{booking.value[0].infants > 0 ? booking.value[0].infants : 0}</td>
												<td dangerouslySetInnerHTML={{ __html: booking.value[0].sale_note }} />
											</tr>
										)
									)
							  )
							: ''}
						<tr>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td>{allAdult}</td>
							<td>{allChild}</td>
							<td>{allChild11}</td>
							<td>{allInfan}</td>
							<td></td>
						</tr>
					</tbody>
				</table>
			</div>

			<OperatingSetting cruiseIdProps={cruise.id} dateProps={date} textCheckProps={textCheck} />
		</Fragment>
	);
};

export default TableOperatingDayTrip;
