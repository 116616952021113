import * as XLSX from 'xlsx';
import * as XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';
export const handleExportCustomerRoom = async (exportCustomerRoomData, dateInfo, customerQuantity) => {
	const ws = XLSX.utils.json_to_sheet(exportCustomerRoomData, { origin: 'A7' });
	XLSX.utils.sheet_add_aoa(ws, [['DANH SÁCH KHÁCH LƯU TRÚ']], { origin: 1 });
	XLSX.utils.sheet_add_aoa(ws, [[dateInfo]], { origin: 'E3' });
	XLSX.utils.sheet_add_aoa(ws, [[customerQuantity.adults]], { origin: 'D3' });
	XLSX.utils.sheet_add_aoa(ws, [[customerQuantity.children11]], { origin: 'D4' });
	XLSX.utils.sheet_add_aoa(ws, [[customerQuantity.children]], { origin: 'D5' });
	XLSX.utils.sheet_add_aoa(ws, [[customerQuantity.infant]], { origin: 'D6' });
	XLSX.utils.sheet_add_aoa(ws, [['Người lớn:']], { origin: 'C3' });
	XLSX.utils.sheet_add_aoa(ws, [['Trẻ em (8-11 tuổi):']], { origin: 'C4' });
	XLSX.utils.sheet_add_aoa(ws, [['Trẻ em (5-7 tuổi):']], { origin: 'C5' });
	XLSX.utils.sheet_add_aoa(ws, [['Trẻ em (dưới 5 tuổi):']], { origin: 'C6' });
	const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
	const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	// ws['D4'].v = 'NEW VALUE from NODE';

	// merge example
	const merge = [
		{ s: { r: 1, c: 0 }, e: { r: 1, c: 12 } }, // title, r = row, c = column
		{ s: { r: 2, c: 0 }, e: { r: 5, c: 1 } }, //
		{ s: { r: 2, c: 4 }, e: { r: 2, c: 10 } } // date time
	];
	ws['!merges'] = merge;

	// Use this code for merge A2:A3 ({ s: { r: 1, c: 0 }, e: { r: 2, c: 0 } }) and A4:A5 ({ s: { r: 3, c: 0 }, e: { r: 4, c: 0 } })

	// Here s = start, r = row, c=col, e= end

	const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
	const data = new Blob([excelBuffer], { type: fileType });
	const rowLength = exportCustomerRoomData.length;
	return addStyleCustomerRoom(data, rowLength);
};

const addStyleCustomerRoom = (workbookBlob, rowLength) => {
	return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
		workbook.sheets().forEach((sheet) => {
			// general style
			sheet.usedRange().style({
				fontFamily: 'Times New Roman',
				verticalAlignment: 'center',
				horizontalAlignment: 'center',
				wrapText: true
			});

			// title
			sheet.range('A2:M2').style({
				fill: '82AEE8',
				fontSize: 14,
				bold: true,
				horizontalAlignment: 'center',
				border: true
				// fontColor: 'ffffff',
			});
			// summary style
			sheet.range('C3:C6').style({
				fontSize: 11,
				bold: true,
				horizontalAlignment: 'left'
			});
			sheet.range('C3:D6').style({
				fontSize: 11,
				border: true
			});
			// +7 because header rows = 7.
			// content table
			sheet.range(`A7:M${rowLength + 7}`).style({
				fontSize: 11,
				border: true
			});
			// date time header
			sheet.range('E3:K3').style({
				fontSize: 14,
				bold: true,
				horizontalAlignment: 'center'
			});

			// set width for columns
			sheet.column('C').width(30).style({ horizontalAlignment: 'left' });
			sheet.column('B').width(15);
			sheet.column('F').width(15);
			sheet.column('G').width(15);
			sheet.column('J').width(20).style({ horizontalAlignment: 'left' });
			sheet.column('K').width(25).style({ horizontalAlignment: 'left' });
			sheet.column('L').width(35).style({ horizontalAlignment: 'left' });
			sheet.column('M').width(35).style({ horizontalAlignment: 'left' });

			// header style
			sheet.range('A7:M7').style({
				fill: '82AEE8',
				bold: true,
				horizontalAlignment: 'center'
			});
		});

		return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob));
	});
};
