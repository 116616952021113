import { Fragment } from 'react';
import NumberFormat from 'react-number-format';

const DebtItem = (props) => {
	const partner = props.bookingsProps;
	const stt = props.sttProps;
	let usdRemain = 0;
	let vndRemain = 0;

	usdRemain = partner.totalUSD - (partner.discountUSDValue + partner.depositUSDValue);
	vndRemain = partner.totalVND - (partner.discountVNDValue + partner.depositVNDValue);

	return (
		<Fragment>
			<tr>
				<td>{stt}</td>
				<td className='td-left'>{partner.partner_name}</td>
				<td className='td-right'>
					<NumberFormat
						value={partner.totalVND}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={0}
					/>
				</td>
				<td className='td-right'>
					<NumberFormat
						value={partner.totalUSD}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={2}
					/>
				</td>
				<td className='td-right'>
					<NumberFormat
						value={partner.depositVNDValue}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={0}
					/>
				</td>
				<td className='td-right'>
					<NumberFormat
						value={partner.depositUSDValue}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={2}
					/>
				</td>
				<td className='td-right'>
					<NumberFormat
						value={vndRemain}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={0}
					/>
				</td>
				<td className='td-right'>
					<NumberFormat
						value={usdRemain}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={2}
					/>
				</td>
				<td className='td-right'>
					<NumberFormat
						value={partner.comVNDValue}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={0}
					/>
				</td>
				<td className='td-right'>
					<NumberFormat
						value={partner.comUSDValue}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={2}
					/>
				</td>
				<td className='td-left'>{partner.asign_to_name}</td>
				<td></td>
				<td></td>
				<td></td>
			</tr>
		</Fragment>
	);
};

export default DebtItem;
