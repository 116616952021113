import moment from 'moment';
import React from 'react';
import Button from 'react-bootstrap/Button';
import { handleExportPartnerRevenue } from './HandleExportPartnerRevenue';

const createDownLoadData = ({
	exportPartnerRevenueData,
	dateInfo,
	cruises,
	reportTitle
}) => {
	const dateFormatted = moment(dateInfo).format('DD/MM/YYYY');
	if (exportPartnerRevenueData && exportPartnerRevenueData.length > 0) {
		handleExportPartnerRevenue(exportPartnerRevenueData, dateFormatted, cruises, reportTitle).then((url) => {
			const downloadAnchorNode = document.createElement('a');
			downloadAnchorNode.setAttribute('href', url);
			downloadAnchorNode.setAttribute('download', `partner-revenue-list-${dateFormatted}.xlsx`);
			downloadAnchorNode.click();
			downloadAnchorNode.remove();
		});
		return;
	}
};

export const ExportPartnerRevenueListXLSX = ({
	exportPartnerRevenueData,
	fileName,
	label,
	dateInfo,
	cruises,
	classes,
	reportTitle
}) => {
	return (
		<Button
			className={`btn btn-primary float-right ${classes}`}
			// variant='warning'
			onClick={() => {
				createDownLoadData({
					exportPartnerRevenueData,
					fileName,
					dateInfo,
					cruises,
					reportTitle
				});
			}}
		>
			{label}
		</Button>
	);
};
