import { Fragment, useContext } from 'react';
import { Toast } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import { RoleContext } from '../../contexts/RoleContext';
import AddRole from './AddRole';
import ListRole from './ListRole';
import { AuthContext } from '../../contexts/AuthContext'

const Role = () => {

    const { 
        setShowAddRoleModal,
        showToast: {show, message, type},
        setShowToast
    } = useContext(RoleContext)

    const {
        authState: {user }
    } = useContext(AuthContext)

    return (
        <Fragment>
            <Helmet>
                <title>List Roles - Cruises Management Software</title>
            </Helmet>
            <div className="horizontal-mainwrapper container clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-3">
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <h3 className="pull-left">List Roles</h3>
                            </div>
                            <div className="col-md-4 ml-auto">
                            {(user && (user.Permissions.includes('add_role') || user.Roles.includes('superadministrator'))) ? (<button className="right-content btn btn-success btn-block" onClick={setShowAddRoleModal.bind(this)}><i className="fe fe-plus-circle"></i> Add Role</button>):null}
                            </div>
                        </div>
                        <hr className="mt-3" />
                        <ListRole />
                        <AddRole />
                        <Toast 
                            show={show} 
                            style={ {position: 'fixed', top: '20%', right: '10px'} } 
                            className={`bg-${type} text-white`} 
                            onClose={setShowToast.bind(this, {show: false, message: '', type: null})}
                            delay={3000}
                            autohide
                        >
                            <Toast.Body>
                                <strong style={ {color: '#ffffff'} }>{message}</strong>
                            </Toast.Body>
                        </Toast>
                    </div>
                </div>
            </div>
        </Fragment>    
    )

}

export default Role