import { Fragment, useContext, useEffect, useState } from 'react';
import { CruiseContext } from '../../contexts/CruiseContext';
import { Row, Col, Toast } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import moment from 'moment';
import Select from 'react-select';
import TableOperatingDayTrip from '../../components/layout/operating/TableOperatingDayTrip';
import TableOperatingOvernight from '../../components/layout/operating/TableOperatingOvernight';
import { SettingContext } from '../../contexts/SettingContext';
import { Link } from 'react-router-dom';
import { CRUISE_TYPES, NO_PERMISSION, PERMISSIONS, ROLES } from '../../contexts/constants'
import { useTranslation } from 'react-i18next';
import { CruiseSettingContext } from '../../contexts/CruiseSettingContext';
import { findOptionItem, getYearOptions } from '../../utils/common';
import { AuthContext } from '../../contexts/AuthContext';


const Operating = () => {
    const {
		authState: { user }
	} = useContext(AuthContext);
    const { t } = useTranslation();
    const {
        showToast: {show, message, type},
        setShowToast
    } = useContext(SettingContext)

    const {
        cruiseState: { cruise, cruises },
        FindACruise,
        getCruises
    } = useContext(CruiseContext)

    const {
        cruiseSettingState: { cruiseSettings },
        getCruiseSettings,
    } = useContext(CruiseSettingContext)

    useEffect( () => {
		if (!cruiseSettings || cruiseSettings.length === 0) {
			getCruiseSettings()
		}
		
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

    const [cruiseSetting, setCruiseSetting] = useState({});
    useEffect(() => {
        if (!cruise) return;
		const cruiseSetting = cruiseSettings? cruiseSettings.find((item) => {
			return item.cruise_id == cruise.id;
		}): {}
		setCruiseSetting(cruiseSetting);
	}, [cruise]);

    let idCruise = 0
    let dateInfo = ''

    let param1 = window.location.pathname;
    let arrUrl = param1.split('/')
    let month = 0;
    let year = 0;
    let dayCurrent = 0;
    let textCruise = ''

    if(arrUrl !== null){
        var arrInfo = arrUrl[2].split('-')
        var cruiseIdInfo = arrInfo[3]
        var dateInfoCheck = `${arrInfo[0]}-${arrInfo[1]}-${arrInfo[2]}`
        month = arrInfo[1]
        year = arrInfo[0]
        dayCurrent = arrInfo[2]
        if(cruiseIdInfo){
            idCruise = cruiseIdInfo
        }
        if(dateInfoCheck){
            dateInfo = dateInfoCheck
        }
    }
    // current link
    const [currentDay, setCurrentDay] = useState(dayCurrent)
    const [currentMonth, setCurrentMonth] = useState(month)
    const [currentYear, setCurrentYear] = useState(year)
    const [currentCruise, setCurrentCruise] = useState(idCruise)

    useEffect( () => getCruises(), [] ) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () => {
        if(idCruise) {
            FindACruise(idCruise)
        }
    }, [idCruise] ) // eslint-disable-line react-hooks/exhaustive-deps

    let months = [t('January'),t('February'),t('March'),t('April'), t('May'), t('June'), t('July'), t('August'), t('September'), t('October'), t('November'), t('December')]
    const daysInMonths = new Date(year, month, 0).getDate();

    let arrDays = []
    let arrMonths = []
    let arrYears = []
    let arrCruises = []
    let textDay = ''
    let textMonth = ''
    let textYear = ''
    let cruiseName = ''
    let checkDayCruise = ''

    if(cruise !== null){
        cruiseName = cruise.name

        if (cruise.cabins === 0){
            checkDayCruise = CRUISE_TYPES.DAY
        } else{
            checkDayCruise = CRUISE_TYPES.NIGHT
        }
    }

    // eslint-disable-next-line no-lone-blocks
    {Array.from(Array(daysInMonths), (e, i) => {
        if((i + 1) === Number(dayCurrent)){
            textDay = (i + 1)
        }
        arrDays = [...arrDays,
            {
                value: (i + 1),
                label: (i + 1)
            }
        ]
    })}

    // eslint-disable-next-line no-lone-blocks
    {Array.from(Array(12), (e, i) => {
        if((i + 1) === Number(month)){
            textMonth = months[i]
        }
        arrMonths = [...arrMonths,
            {
                value: (i + 1),
                label: months[i]
            }
        ]
    })}

    arrYears = getYearOptions();

    if(cruises){
        cruises.map((cruise) => {

            if(Number(cruise.id) === Number(idCruise)){
                textCruise = cruise.name
            }

            arrCruises = [ ...arrCruises,
                {
                    value: cruise.id,
                    label: cruise.name,
                }
            ]
        })
    }

    const handleChangeDay = (newValue, actionMeta) => {
        const dataDay = newValue;
        if(dataDay && actionMeta){
            if(actionMeta.name === `currentDay` ){
                setCurrentDay(dataDay.value)
            }

            if(actionMeta.name === `currentMonth` ){
                setCurrentMonth(dataDay.value)
            }

            if(actionMeta.name === `currentYear` ){
                setCurrentYear(dataDay.value)
            }

            if(actionMeta.name === `currentCruise` ){
                setCurrentCruise(dataDay.value)
            }
        }
    }

    const openLinkNewCruise = () => {
        var checkDay = currentDay
        var newDay = checkDay
        if(Number(checkDay) < 10) {
            newDay = ("0" + (checkDay)).slice(-2);
        }
        var newDate = `${currentYear}-${currentMonth}-${newDay}`;
        var newPageUrl = `/operating/${newDate}-${currentCruise}`
        window.open(newPageUrl, "_blank")
    }

    if (!user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR)
			&& !user.Permissions.includes(PERMISSIONS.VIEW_OPERATION)) return NO_PERMISSION;

    return (
        <Fragment>
            <Helmet>
                <title>{t('Operating')} {cruise ? cruise.name : '' } - Cruises Management Software</title>
            </Helmet>
            <div className="horizontal-mainwrapper container-fluid clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-3">
                        <Row className='sel-month-year' md={4}>
                            <Col xs={2}>
                                <div className="form-group">
                                    <label><strong>{t('Day')}</strong></label>
                                    <Select isClearable
                                        name="currentDay"
                                        defaultValue={{ label: textDay, value: dayCurrent }}
                                        onChange={handleChangeDay.bind(this)}
                                        options={arrDays}
                                        placeholder={t("Day")}
                                    />
                                </div>
                            </Col>
                            <Col xs={2}>
                                <div className="form-group">
                                    <label><strong>{t('Month')}</strong></label>
                                    <Select isClearable
                                        name="currentMonth"
                                        defaultValue={{ label: textMonth, value: month }}
                                        onChange={handleChangeDay.bind(this)}
                                        options={arrMonths}
                                        placeholder={t("Month")}
                                    />
                                </div>
                            </Col>
                            <Col xs={2}>
                                <div className="form-group">
                                    <label><strong>{t('Year')}</strong></label>
                                    <Select isClearable
                                        name="currentYear"
                                        defaultValue={findOptionItem(arrYears, currentYear)}
                                        onChange={handleChangeDay.bind(this)}
                                        options={arrYears}
                                        placeholder={t("Year")}
                                    />
                                </div>
                            </Col>
                            <Col xs={2}>
                                <div className="form-group">
                                    <label><strong>{t('Cruise')}</strong></label>
                                    {textCruise ? (
                                    <Select isClearable
                                        name="currentCruise"
                                        defaultValue={{ label: textCruise, value: idCruise }}
                                        onChange={handleChangeDay.bind(this)}
                                        options={arrCruises}
                                        placeholder={t("Choose Cruise")}
                                    />
                                    ) : ('')}
                                </div>
                            </Col>
                            <Col xs={2}>
                                <div className="form-group">
                                    <button className="btn btn-primary btn-block mt-17" onClick={openLinkNewCruise.bind(this)}><i className="fe fe-corner-down-right"></i> {t('Go')}</button>
                                </div>
                            </Col>
                        </Row>
                        <div className="row">
                            <div className="col-md-4">
                                <h3 className="pull-left">{t('Operating Manage Booking Day')}: {moment(dateInfo).format("MMMM D, YYYY")}</h3>
                            </div>
                            <div className="col-md-7 ml-auto">
                                <div className="row">
                                    {arrUrl !== null && cruiseName !== undefined ? (
                                        <div className="col-md-3">
                                            <Link to={`/command/${arrUrl[2]}`} title={t('Tour Command') + ` ${cruiseName} - ${moment(dateInfo).format("MMMM D, YYYY")}`} className="right-content btn btn-customer btn-block"><i className="las la-clipboard-list"></i> {t('Tour Operator Command')}</Link>
                                        </div>
                                    ) : ('')}
                                    <div className="col-md-3">
                                        <Link to={`/no-transportations/${arrUrl[2]}`} title={t('No Transportation List on') + ` ${cruiseName} - ${moment(dateInfo).format("MMMM D, YYYY")}`} className="right-content btn btn-customer btn-block"><i style={ {fontSize: '16px'} } className="ti-na"></i> {t('No Transportation List')}</Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to={`/transportations/${arrUrl[2]}`} title={t('Transportation List on') + ` ${cruiseName} - ${moment(dateInfo).format("MMMM D, YYYY")}`} className="right-content btn btn-customer btn-block"><i style={ {fontSize: '16px'} } className="ti-car"></i> {t('Transportation List')}</Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to={`/customers/${arrUrl[2]}`} title={t('Customer List on') + ` ${cruiseName} - ${moment(dateInfo).format("MMMM D, YYYY")}`} className="right-content btn btn-customer btn-block"><i className="fe fe-list"></i> {t('Customer List')}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    {cruise ? (
                        (checkDayCruise === CRUISE_TYPES.DAY || checkDayCruise === CRUISE_TYPES.TOUR) ? (
                            <TableOperatingDayTrip cruiseProps={cruise} dayProps={dayCurrent} monthProps={month} yearProps={year} cruiseSetting={cruiseSetting} />
                        ) : (
                            <TableOperatingOvernight cruiseProps={cruise} dayProps={dayCurrent} monthProps={month} yearProps={year} cruiseSetting={cruiseSetting} />
                        )
                    ) : ('')}
                    <Toast
                        show={show}
                        style={ {position: 'fixed', top: '20%', right: '10px'} }
                        className={`bg-${type} text-white`}
                        onClose={setShowToast.bind(this, {show: false, message: '', type: null})}
                        delay={3000}
                        autohide
                    >
                        <Toast.Body>
                            <strong>{message}</strong>
                        </Toast.Body>
                    </Toast>
                </div>
            </div>


        </Fragment>
    )
}

export default Operating
