import moment from 'moment';
import { BOOKING_STATUSES, CRUISE_TYPES, ROLES } from '../contexts/constants';
import { apiUrl } from '../contexts/constants';
import { getAPI, postAPI } from './api';
export const getBookingCellBackground = (statusBg, duration, user) => {
	let statusText = '';
	switch (statusBg) {
		case BOOKING_STATUSES.PENDING:
			statusText = 'list-booking bg-pending';
			break;
		case BOOKING_STATUSES.CONFIRMED:
			if (duration === 3) {
				statusText = 'list-booking bg-green';
			} else {
				statusText = 'list-booking bg-blue';
			}
			break;
		case BOOKING_STATUSES.CANCELED:
			statusText = 'hide-link list-booking bg-canceled';
			break;
		case BOOKING_STATUSES.TRANSFERRED:
			statusText = 'hide-link list-booking bg-transferred';
			break;
		case BOOKING_STATUSES.INSPECTION:
			statusText = 'list-booking bg-inspection';
			break;
		case BOOKING_STATUSES.CHARTER:
			statusText = 'list-booking bg-red';
			break;
		case BOOKING_STATUSES.UPTO:
			if (user && user.roles && user.Roles.includes(ROLES.OPT)) {
				if (duration === 3) {
					statusText = 'list-booking bg-green';
					break;
				}
				statusText = 'list-booking bg-blue';
				break;
			}

			statusText = 'list-booking bg-upTo';
			break;
		default:
			statusText = 'list-booking';
	}
	if (!statusText.includes('hide-link')) {
		statusText += ' show-booking';
	}
	return statusText;
};

export const convertUTCtoGMT7 = (strDatetime) => {
	const dt = strDatetime && strDatetime !== '0001-01-01 00:00:00 +0000 UTC' ? new Date(strDatetime) : '';
	if (dt) {
		const convertedDT =
			dt.getFullYear() +
			'/' +
			(dt.getMonth() + 1) +
			'/' +
			dt.getDate() +
			' ' +
			dt.getHours() +
			':' +
			dt.getMinutes() +
			':' +
			dt.getSeconds();
		return convertedDT;
	}

	return '';
};

export const getOptions = (data, keys, valNames) => {
	if (!data || data.length === 0 || !keys || !valNames || keys.length != valNames.length) return [];

	let optVales = [];
	data.forEach((item) => {
		const optItem = {};
		keys.forEach((key, index) => {
			optItem[key] = item[valNames[index]];
		});

		optVales.push(optItem);
	});

	return optVales;
};

export const moneyOptions = [
	{ value: 'usd', label: 'USD' },
	{ value: 'vnd', label: 'VNĐ' }
];
export const routeTypeOptions = [
	{ value: 'all', label: 'All' },
	{ value: 'return', label: 'Return' },
	{ value: 'oneway', label: 'Oneway' }
];

export const findOptionItem = (options, optionVal) => {
	if (!options || options.length === 0 || !optionVal) return null;
	const itemResult = options.find((item) => {
		return item.value.toString() === optionVal.toString();
	});

	return itemResult;
};

export const findOptionItems = (options, optionVal) => {
	if (!options || options.length === 0 || !optionVal) return [];
	const itemsResult = options.filter((item) => {
		const vals = optionVal.split(',');
		return vals.includes(item.value.toString());
	});

	return itemsResult;
};

export const ChangeToSlug = (Text) => {
	var slug;

	//Đổi chữ hoa thành chữ thường
	slug = Text.toLowerCase();

	//Đổi ký tự có dấu thành không dấu
	slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
	slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
	slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
	slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
	slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
	slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
	slug = slug.replace(/đ/gi, 'd');
	//Xóa các ký tự đặt biệt
	slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi, '');
	//Đổi khoảng trắng thành ký tự gạch ngang
	slug = slug.replace(/ /gi, '-');
	//Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
	//Phòng trường hợp người nhập vào quá nhiều ký tự trắng
	slug = slug.replace(/\-\-\-\-\-/gi, '-');
	slug = slug.replace(/\-\-\-\-/gi, '-');
	slug = slug.replace(/\-\-\-/gi, '-');
	slug = slug.replace(/\-\-/gi, '-');
	//Xóa các ký tự gạch ngang ở đầu và cuối
	slug = '@' + slug + '@';
	slug = slug.replace(/\@\-|\-\@|\@/gi, '');
	//In slug ra textbox có id “slug”
	return slug;
};

export const randomText = (length) => {
	var result = '';
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const removeFiles = async (filename) => {
	// call delete image api
	const response = await getAPI(`${apiUrl}/file/delete?filename=${filename}`);
	if (response.error) {
		console.log(`Delete file ${filename} error`, response.error);
	}
	return response;
};

export const fixFilename = (file) => {
	const ext = file.name.split('.').slice(-1)[0];
	const slug = ChangeToSlug(file.name.split('.')[0]);
	const fileName = `${slug}-${randomText(8)}.${ext}`;

	return fileName;
};

const GetCruiseSetting = (cruiseSettings, cruiseId) => {
	if (!cruiseSettings) return {};
	return cruiseSettings.find((item) => {
		return item.cruise_id === cruiseId;
	});
};

export const CheckWeekend = (cruiseSettings, cruiseId, date) => {
	const setting = GetCruiseSetting(cruiseSettings, cruiseId);
	const weekendDays = setting && setting.weekend ? setting.weekend.split(',') : ['6', '0'];
	const dayOfWeek = moment(date).day().toString();
	if (weekendDays.includes(dayOfWeek)) {
		return 'weekend';
	}

	return '';
};

export const isSpecialDate = (cruiseSettings, cruiseId, date) => {
	const weekend = CheckWeekend(cruiseSettings, cruiseId, date);
	const holiday = CheckHoliday(cruiseSettings, cruiseId, date);
	return weekend === 'weekend' || holiday === 'holiday';
};

export const CheckHoliday = (cruiseSettings, cruiseId, date) => {
	const setting = GetCruiseSetting(cruiseSettings, cruiseId);
	const holidays = setting && setting.holidays ? setting.holidays.split(',') : [];
	const solarDate = moment(date).format('YYYY-MM-DD');
	if (holidays.includes(solarDate)) {
		return 'holiday';
	}

	return '';
};

export const getDayOfWeek = (date) => {
	let weekday = [];
	weekday[1] = 'Monday';
	weekday[2] = 'Tuesday';
	weekday[3] = 'Wednesday';
	weekday[4] = 'Thursday';
	weekday[5] = 'Friday';
	weekday[6] = 'Saturday';
	weekday[0] = 'Sunday';
	const dayOfWeek = moment(date).day();
	return weekday[dayOfWeek];
};

export const uploadFiles = async (roomImageUrls, formDataValue) => {
	const roomUploadedImages = roomImageUrls.filter((item) => {
		return !item.includes('blob');
	});
	let roomImagesData = roomUploadedImages.join(',');
	if (formDataValue) {
		const response = await postAPI(`${apiUrl}/file/upload-multiple?folder=rooms`, formDataValue);
		if (response && response.data && response.data.urls && response.data.urls.length > 0) {
			const pathnameImages = response.data.urls.map((item) => {
				const url = new URL(item);
				return url.pathname;
			});
			const roomImages = [...roomUploadedImages, ...pathnameImages];
			roomImagesData = roomImages.join(',');
		}
	}

	return roomImagesData;
};

export const deleteFilesApi = async (deleteFiles) => {
	if (!deleteFiles || !deleteFiles.length) return;

	for (let index = 0; index < deleteFiles.length; index++) {
		const filename = deleteFiles[index];
		await getAPI(`${apiUrl}/file/delete?filename=${filename}`);
	}
};

export const sort = (array, sortBy) => {
	const compare = (a, b) => {
		if (a[sortBy] < b[sortBy]) {
			return -1;
		}
		if (a[sortBy] > b[sortBy]) {
			return 1;
		}
		return 0;
	};
	array.sort(compare);
};

export const formatDate = (strDate, strFormat) => {
	if (!strDate || strDate === '1970-01-01') return '';
	if (!strFormat) return strDate;

	return moment(strDate).format(strFormat);
};

export const formatDateGMT7 = (strDate, strFormat) => {
	if (!strDate || strDate === '1970-01-01') return '';
	if (!strFormat) return strDate;

	return moment(strDate).utc().format(strFormat);
};

export const convertStringToDateISO = (strDate) => {
	const _date = new Date(strDate);
	return _date.toISOString();
};

export const getCompany = () => {
	const domain = window.location.hostname;
	const company = domain.split('.')[0];
	return company;
};

// Create our number formatter.
export const currencyFormatter = (currency) => {
	const formatterUSD = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD'

		// These options are needed to round to whole numbers if that's what you want.
		//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
		//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	});

	const formatterVND = new Intl.NumberFormat('vi-VN', {
		style: 'currency',
		currency: 'VND'
	});

	if (currency && currency.toUpperCase() === 'USD') {
		return formatterUSD;
	}

	return formatterVND;
};

export const numberFormatter = (fraction) => {
	if (!fraction) {
		fraction = 0;
	}
	const formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: fraction });

	return formatter;
};

export const getCruiseTripType = (cruises, cruiseID) => {
	const cruise = cruises.find((item) => item.id === cruiseID);

	if (cruise.cabins > 0) {
		return CRUISE_TYPES.NIGHT
	}

	return CRUISE_TYPES.DAY;
};

export const isEmptyObj = (obj) => {
    return Object.keys(obj).length === 0;
}

export const escapedNewLineToLineBreakTag = (str) => {
	if (!str) {
		return '';
	}

	return str.replace(/\n/g,'<br/>');
}

export const getYearMonth = (str) => {
	let dt = new Date();
	if (str) {
		dt = new Date(str);
	}
	const month = ("0" + (dt.getMonth() + 1)).slice(-2); //("0" + 3).slice(-2); //("0" + (dt.getMonth() + 1)).slice(-2);
    const year = dt.getFullYear(); //2019 //dt.getFullYear();
	return {
		'year': year,
		'month': month
	}
}

export const getYearMonthDay = (str) => {
	let dt = new Date();
	if (str) {
		dt = new Date(str);
	}
	
	const month = ("0" + (dt.getMonth() + 1)).slice(-2); //("0" + 3).slice(-2); //("0" + (dt.getMonth() + 1)).slice(-2);
    const year = dt.getFullYear(); //2019 //dt.getFullYear();
	const day = ("0" + (dt.getDate())).slice(-2);
	return {
		'year': year,
		'month': month,
		'day': day,
		'currentDate': `${year}-${month}-${day}`
	}
}

export const not_count_booked_statuses = ['Pending', 'Transferred', 'Canceled'];

export const diffDays = (fromDate, toDate) => {
	const date1 = new Date(fromDate);
	const date2 = new Date(toDate);
	const diffTime = date2 - date1;
	const diffDays = diffTime / (1000 * 60 * 60 * 24); 

	return diffDays;
};

export const getYearOptions = () => {
	const currentYear = new Date().getFullYear();
	let arrYears = []
	const yearNum = 10;
	const minYear = currentYear - 5 > 2022? currentYear - 5: 2022;
	// eslint-disable-next-line no-lone-blocks
    {Array.from(Array(yearNum), (e, i) => {
        arrYears = [...arrYears,
            {
                value: (minYear + i),
                label: (minYear + i)
            }
        ]
    })}

	return arrYears;
}

export const getMonthOptions = (t) => {
	let arrMonths = [];
	let months = [t('January'),t('February'),t('March'),t('April'), t('May'), t('June'), t('July'), t('August'), t('September'), t('October'), t('November'), t('December')]

    // eslint-disable-next-line no-lone-blocks
    {Array.from(Array(12), (e, i) => {
        arrMonths = [...arrMonths,
            {
                value: (i + 1),
                label: months[i]
            }
        ]
    })}

    arrMonths.unshift({
        value: 'all',
        label: t('All')
    });

	return arrMonths;
}