import { Fragment, useContext, useState, useEffect } from 'react';
import { CountryContext } from '../../../contexts/CountryContext';
import {Modal, Button, Form} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const UpdateCountryModal = () => {
    const { t } = useTranslation();
    // Context
    const {
        countryState: {country},
        showUpdateCountryModal,
        setShowUpdateCountryModal,
        updateCountry,
        setShowToast
    } = useContext(CountryContext)

    // State
    const [updatedCountry, setUpdatedCountry] = useState(country)
    useEffect( () => setUpdatedCountry(country), [country] )
    const {
        id,
        name,
        iso_code_2,
        iso_code_3
    } = updatedCountry

    const onChangeCountry = event => setUpdatedCountry( {...updatedCountry, [event.target.name]: event.target.value } )

    const closeDialog = () => {
        setUpdatedCountry(country)
        setShowUpdateCountryModal(false)
    }

    const onSubmit = async event => {
        event.preventDefault()
        const response = await updateCountry(updatedCountry)
        if (!response.error) {
            setShowUpdateCountryModal(false)
            setShowToast({show: true, message: t('Update sucessful!'), type: 'success'})
            return
        }
        setShowToast({show: true, message: response.error.ErrorCode, type: 'danger'})
    }

    return (
        <Fragment>
            <Modal show={showUpdateCountryModal} onHide={closeDialog}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t('Update Country')}
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="row">
                            <input type="hidden" name="id" value={id} />
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label><strong>{t('Name')}</strong></label>
                                    <input type="text" className="form-control" id="nameCruise" name="name" value={name} placeholder={t("Country Name")} required aria-describedby='name-help' onChange={onChangeCountry} />
                                    <Form.Text id='name-help' muted>{t('Required')}</Form.Text>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group">
                                    <label><strong>Iso Code 2</strong></label>
                                    <input type="text" className="form-control" id="iso_code_2" name="iso_code_2" value={iso_code_2} placeholder="VN" required aria-describedby='iso-code-2' onChange={onChangeCountry} />
                                    <Form.Text id='iso-code-2' muted>{t('Required')}</Form.Text>
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <label><strong>Iso Code 3</strong></label>
                                    <input type="text" className="form-control" id="iso_code_3" name="iso_code_3" value={iso_code_3} placeholder="VNM" required aria-describedby='iso-code-3' onChange={onChangeCountry} />
                                    <Form.Text id='iso-code-3' muted>{t('Required')}</Form.Text>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> {t('Cancel')}</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> {t('Save')}!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default UpdateCountryModal
