import { Fragment, useContext, useEffect, useState } from 'react';
import ListUser from '../../components/auth/ListUser';
import AddUserModal from '../../components/auth/AddUserModal';
import { AuthContext } from '../../contexts/AuthContext'
import { ROLES, PERMISSIONS } from '../../contexts/constants'
import { Toast } from 'react-bootstrap';

const User = () => {

    const {
        authState: { users, user },
        showToast: {show, message, type},
        getUsers,
        setShowAddUserModal,
        setShowToast
    } = useContext(AuthContext)
    useEffect(() => getUsers(''), []) // eslint-disable-line react-hooks/exhaustive-deps

    const [currentUsers, setCurrentUsers] = useState(users)
    useEffect(() => {
        setCurrentUsers(users)
    }, [users])

    return (
        <Fragment>
            <div className="horizontal-mainwrapper container clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-3">
                        <div className="row">
                            <div className="col-md-4">
                                <h3 className="pull-left">List User</h3>
                            </div>
                            <div className="col-md-2 ml-auto">
                            {(user && (user.Permissions.includes(PERMISSIONS.UPDATE_USER) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))) ? (<button className="right-content btn btn-success btn-block" onClick={setShowAddUserModal.bind(this, true)}><i className="fe fe-plus-circle"></i> Add User</button>): null}
                            </div>
                        </div>
                    </div>

                    <ListUser usersProps={currentUsers} />
                    <AddUserModal currentUsers={currentUsers} setCurrentUsers={setCurrentUsers} />
                    <Toast
                        show={show}
                        style={ {position: 'fixed', top: '30%', right: '10px'} }
                        className={`bg-${type} text-white`}
                        onClose={setShowToast.bind(this, {show: false, message: '', type: null})}
                        delay={3000}
                        autohide
                    >
                        <Toast.Body>
                            <strong style={ {color: '#ffffff'} }>{message}</strong>
                        </Toast.Body>
                    </Toast>
                </div>
            </div>
        </Fragment>
    )
}

export default User
