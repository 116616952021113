import { Fragment, useEffect, useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { GuideContext } from '../../../contexts/GuideContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { SettingContext } from '../../../contexts/SettingContext';
import { useTranslation } from 'react-i18next';

const OperatingUpdateSetting = props => {
    const { t } = useTranslation();
    const setting = props.settingProps
    const dateSetting = props.settingDateProps
    let checkBoolean = null
    //let checkDefault = null
    if(setting.oos !== undefined && setting.oos === 'true'){
        checkBoolean = true
    }else{
        checkBoolean = false
    }

    const [checkOOS, setCheckOOS] = useState(checkBoolean)

    const {
        guideState: {guides},
        getGuides
    } = useContext(GuideContext)

    const {
        authState: { user, users },
        getUsers
    } = useContext(AuthContext)

    useEffect(() => getUsers('?department=opt'), []) // eslint-disable-line react-hooks/exhaustive-deps

    // Start: Get all rooms , []
    useEffect( () => getGuides(), []) // eslint-disable-line react-hooks/exhaustive-deps

    // Update Setting
    const {
        updateSetting,
        setShowToast
    } = useContext(SettingContext)

    const [updatedSetting, setUpdatedSetting] = useState(setting)
    useEffect( () => setUpdatedSetting(setting), [setting] )

    const {
        id,
        cruise_id,
        driver_id,
        guide_id,
        note,
        oos,
        oos_reason,
        operator_id,
        user_id,
        day_setting
    } = updatedSetting

    const onChangeNew = event => setUpdatedSetting( {...updatedSetting, [event.target.name]: event.target.value } )



    let optionGuides = []
    let optionDrivers = []
    let optionOperators = []
    let lableGuide = ''
    let lableDriver = ''
    let lableOperator = ''

    if(guides){
        guides.map((guide) =>
            guide.service_type === "guide" ? (
                guide.id === setting.guide_id ? lableGuide = guide.name : '',
                optionGuides = [ ...optionGuides,
                {
                    value: guide.id,
                    label: guide.name,
                }
            ] )
            : guide.service_type === "car" ? (
                guide.id === setting.driver_id ? lableDriver = guide.name : '',
                optionDrivers = [ ...optionDrivers,
                {
                    value: guide.id,
                    label: guide.name,
                }
            ] )
            : ('')
        )
    }

    if(users.length > 0){
        users.map((user) => {

            if(Number(user.id) === Number(setting.operator_id)){
                lableOperator = user.name
            }

            optionOperators = [ ...optionOperators,
            {
                value: user.id,
                label: user.name,
            }
            ]

        })
    }

    const handleChangeSelected = (newValue, actionMeta) => {
        const dataSelect = newValue;
        if(dataSelect){
            setUpdatedSetting( {...updatedSetting, [actionMeta.name]: dataSelect.value } )
        }
    }

    const handleChangeCheckBox = (event) => {
        setCheckOOS(event.target.checked)
        setUpdatedSetting( {...updatedSetting, [event.target.name]: `${event.target.checked}` } )
    }

    const onSubmit = async event => {
        event.preventDefault()
        const {success, message} = await updateSetting(updatedSetting)
        setShowToast({show: true, message, type: success ? 'success' : 'danger'})
        window.location.reload()
    }


    return (
        <Fragment>
            <Form onSubmit={onSubmit}>
                <input type="hidden" name="id" value={id} />
                <input type="hidden" name="cruise_id" value={cruise_id} />
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-2">
                                    <label><strong>{t('Guide')}</strong></label>
                                </div>
                                <div className="col-md-4">
                                    {lableDriver !== '' ? (
                                    <Select isClearable
                                        defaultValue={{ label: lableGuide, value: guide_id }}
                                        onChange={handleChangeSelected.bind(this)}
                                        options={optionGuides}
                                        placeholder={t("Choose Guide")}
                                        menuPosition={'fixed'}
                                        name="guide_id"
                                    />
                                    ) : ('')}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-2">
                                    <label><strong>{t('Driver')}</strong></label>
                                </div>
                                <div className="col-md-4">
                                    {lableDriver !== '' ? (
                                    <Select isClearable
                                        defaultValue={{ label: lableDriver, value: driver_id }}
                                        onChange={handleChangeSelected.bind(this)}
                                        options={optionDrivers}
                                        placeholder={t("Choose Driver")}
                                        menuPosition={'fixed'}
                                        name="driver_id"
                                    />
                                    ) : ('')}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-2">
                                    <label><strong>{t('Operator')}</strong></label>
                                </div>
                                <div className="col-md-4">
                                    {lableOperator !== '' ? (
                                    <Select isClearable
                                        defaultValue={{ label: lableOperator, value: operator_id }}
                                        onChange={handleChangeSelected.bind(this)}
                                        options={optionOperators}
                                        placeholder={t("Choose Operator")}
                                        menuPosition={'fixed'}
                                        name="operator_id"
                                    />
                                    ) : ('')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <div className="checkbox">
                                <div className="custom-checkbox custom-control">
                                    <input type="checkbox" className="custom-control-input" id="checkbox-2" name="oos" value={oos} defaultChecked={checkOOS} onChange={handleChangeCheckBox.bind(this)} />
                                    <label htmlFor="checkbox-2" className="custom-control-label mt-1"><strong>{t('Out of Service')}</strong></label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label><strong>{t('Reasons')}</strong></label>
                            <textarea className="form-control" placeholder={t("Reasons")} rows="4" name="oos_reason" value={oos_reason} onChange={onChangeNew} spellCheck="false"></textarea>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label><strong>{t('Notes')}</strong></label>
                            <CKEditor
                                name="note"
                                editor={ ClassicEditor }
                                data={note}
                                //onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    //console.log( 'Editor is ready to use!', editor );
                                //} }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setUpdatedSetting( {...updatedSetting, note: data } )
                                } }
                                /*onBlur={ ( event, editor ) => {
                                    console.log( 'Blur.', editor );
                                } }
                                onFocus={ ( event, editor ) => {
                                    console.log( 'Focus.', editor );
                                } }*/
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2 ml-auto"><button className="right-content btn btn-success btn-block mb-3"><i className="las la-save"></i> {t('Save')}</button></div>
                </div>
            </Form>
        </Fragment>
    )
}

export default OperatingUpdateSetting
