import { useState, useEffect, useContext } from 'react';
import { OnboardBookingContext } from '../../../contexts/OnboardBookingContext';
import { OnboardServiceContext } from '../../../contexts/OnboardServiceContext';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import AddMoreOnboardService from './AddMoreOnboardService'
import { convertUTCtoGMT7 } from '../../../utils/common';

const AddOnboardBookingModal = (props) => {

    const bookingData = props.bookingData
    const rooms = props.rooms
    const setRooms = props.setRooms
    const [roomOptions, setRoomOptions] = useState([])
    const [bookingOptions, setBookingOptions] = useState([])
    const [currentOnboardBooking, setCurrentOnboardBooking] = useState({})
    const [total, setTotal] = useState({totalVND: 0, totalUSD: 0})
    const [currencyVal, setCurrencyVal] = useState('')

    // Context
    const {
        showAddOnboardBookingModal,
        setShowAddOnboardBookingModal,
        setShowToast,
        onboardBookingData,
        setOnBoardBookingData,
        addOnboardBooking,
        updateOnboardBooking,
    } = useContext(OnboardBookingContext)

    const {
        onboardServiceState: {onboardServices},
        getOnboardServices
    } = useContext(OnboardServiceContext)

    const [inputList, setinputList]= useState([{onboard_service:'', quantity:''}]);
    const calculateTotal = (list, cVal) => {
        if (!list) {
          setTotal({totalVND: 0, totalUSD: 0});
          return
        }
        if (list.length === 0) {
          setTotal({totalVND: 0, totalUSD: 0});
          return
        }
        let total = { totalVND: 0, totalUSD: 0};
        let services = [];
        list.forEach((item) => {
          let serObj = {};
          let quantity = parseInt(item.quantity);
          if (!quantity) {
            quantity = 0;
          }
          const service = onboardServiceOptions.find((s) => s.value === item.onboard_service);
          if (service) {
            total.totalVND += service.data.price_vnd * quantity;
            total.totalUSD += service.data.price_usd * quantity;
            serObj = {
              service_id: service.value,
              service_name: service.label,
              price_vnd: service.data.price_vnd,
              price_usd: service.data.price_usd,
              quantity: quantity,
            }
            services.push(serObj);
          }
        });
        setTotal(total);
        const strServices = JSON.stringify(services);
        const currency = cVal? cVal: currencyVal;
        if (currency.value === 'usd' && total && total.totalUSD) {
            setNewOnboardBooking({...newOnboardBooking, total: total.totalUSD, onboard_service: strServices, currency: currency.value});
            return
        }
        if (currency.value === 'vnd' && total && total.totalVND) {
            setNewOnboardBooking({...newOnboardBooking, total: total.totalVND, onboard_service: strServices, currency: currency.value});
        }
    }

    useEffect(() => {
        getOnboardServices('')
    }, [])

    const [onboardServiceOptions, setOnboardServiceOptions] = useState([])
    useEffect(() => {
        if (onboardServices && onboardServices.length > 0) {
            const obServiceOpts = onboardServices.map((item) => {
                return {
                    label: `${item.name} ( ${new Intl.NumberFormat().format(item.price_vnd)} VNĐ | $${item.price_usd} )`,
                    value: item.id,
                    data: item
                }
            })
            if (obServiceOpts) {
                setOnboardServiceOptions(obServiceOpts)
            }
        }
    }, [onboardServices])

    const setRoomOptionsData = (date) => {
        const bookingDataByDate = bookingData && bookingData[date] ? bookingData[date] : []
        if (bookingDataByDate.length > 0) {
            const rooms = bookingDataByDate.map((item) => {
                return {
                    label: item.roomName,
                    value: item.roomId,
                    bookings: item.bookings
                }
            });
            setRoomOptions(rooms);
            return;
        }
        setRoomOptions([]);
    }

    const setBookingOptionsData = (room) => {
        if (!room || !room.bookings) {
            setBookingOptions([]);
            return;
        }
        const bookings = Object.values(room.bookings).map(booking => {
            return {
                label: booking.new_code,
                value: booking.booking_id
            }
        });
        
        setBookingOptions(bookings);
    }

    // State
    const [newOnboardBooking, setNewOnboardBooking] = useState({
        id: null,
        booking_id: '',
        room_id: '',
        checked_in: '',
        checked_out: '',
        onboard_service: '',
        total: '',
        description: '',
        currency: '',
    })

    useEffect(() => {
        // set room options
        if (onboardBookingData.date) {
            setRoomOptionsData(onboardBookingData.date);
        }
        // set booking options
        const bookingDataByDate = bookingData && bookingData[onboardBookingData.date] ? bookingData[onboardBookingData.date] : []
        if (bookingDataByDate.length > 0) {
            const roomOpts = bookingDataByDate.map((item) => {
                return {
                    label: item.roomName,
                    value: item.roomId,
                    bookings: item.bookings
                }
            });
            if (onboardBookingData.roomId && roomOpts.length > 0) {
                const room = roomOpts.find((r) => {
                    return r.value === onboardBookingData.roomId;
                })
                
                setBookingOptionsData(room);
            }
        }
        // set current onboard booking data
        setCurrentOnboardBooking(onboardBookingData);
        const checkedIn = onboardBookingData.roomData && onboardBookingData.roomData.checked_in? onboardBookingData.roomData.checked_in: '';
        const checkedOut = onboardBookingData.roomData && onboardBookingData.roomData.checked_out? onboardBookingData.roomData.checked_out: '';
        const checkedInGMT7 = convertUTCtoGMT7(checkedIn);
        const checkedOutGMT7 = convertUTCtoGMT7(checkedOut);
        let isoCheckedIn = '';
        if (checkedIn) {
            const checkedInDatetime = new Date(checkedIn)
            isoCheckedIn = checkedInDatetime.toISOString();
        }
        let isoCheckedOut = '';
        if (checkedOut) {
            const checkedOutDatetime = new Date(checkedOut)
            isoCheckedOut = checkedOutDatetime.toISOString();
        }
        
        const currency = onboardBookingData.roomData && onboardBookingData.roomData.onboard_currency? onboardBookingData.roomData.onboard_currency: '';
        const totalDefault = onboardBookingData.roomData && onboardBookingData.roomData.onboard_total? onboardBookingData.roomData.onboard_total: 0;
        setNewOnboardBooking({
            ...newOnboardBooking,
            id: onboardBookingData.roomData && onboardBookingData.roomData.onboard_booking_id? onboardBookingData.roomData.onboard_booking_id: null,
            room_id: onboardBookingData.roomId, 
            booking_id:onboardBookingData.bookingId,
            description: onboardBookingData.roomData && onboardBookingData.roomData.onboard_booking_description? onboardBookingData.roomData.onboard_booking_description : '',
            checked_in: isoCheckedIn,
            checked_out: isoCheckedOut,
            onboard_service: onboardBookingData.roomData && onboardBookingData.roomData.onboard_service? onboardBookingData.roomData.onboard_service: '',
            total: totalDefault,
            currency: currency,
        })
        setCheckedInDatetime(checkedInGMT7);
        setCheckedOutDatetime(checkedOutGMT7);
        if (currency === 'vnd' && totalDefault > 0) {
            setTotal({...total, totalVND: totalDefault});
        }
        if (currency === 'usd' && totalDefault > 0) {
            setTotal({...total, totalUSD: totalDefault});
        }
        const cVal = currencyOptions.find((item) => item.value === currency)
        if (cVal) {
            setCurrencyVal(cVal)
        } else {
            setCurrencyVal('')
        }
        
    }, [onboardBookingData])

    // set booking select value
    const [bookingVal, setBookingVal] = useState('')
    useEffect(() => {
        const bVal = bookingOptions.find((item) => item.value === newOnboardBooking.booking_id)
        if (bVal) {
            setBookingVal(bVal)
        } else {
            setBookingVal('')
        }
    }, [newOnboardBooking, bookingOptions])

    // set room select value
    const [roomVal, setRoomVal] = useState('')
    useEffect(() => {
        const rVal = roomOptions.find((item) => item.value === newOnboardBooking.room_id)
        if (rVal) {
            setRoomVal(rVal)
        } else {
            setRoomVal('')
        }
        
    }, [newOnboardBooking, roomOptions])

    // useEffect(() => {
    //     if (currencyVal.value === 'usd' && total && total.totalUSD) {
    //         setNewOnboardBooking({...newOnboardBooking, total: total.totalUSD});
    //         return
    //     }
    //     if (currencyVal.value === 'vnd' && total && total.totalVND) {
    //         setNewOnboardBooking({...newOnboardBooking, total: total.totalVND});
    //     }
    //     setNewOnboardBooking({...newOnboardBooking, currency: currencyVal.value})
    // }, [currencyVal.value, total])

    const handleDateChange = (data) => {
        setBookingOptions([]);
        resetaddOnboardBookingData()
        if (!data.target) {
            setRoomOptions([]);
            return
        }
        if (!data.target.value) {
            setRoomOptions([]);
            return
        }
        const date = data.target.value
        setRoomOptionsData(date);
        // set current onboard booking data
        setCurrentOnboardBooking({
            date: date,
            roomId: '',
            bookingId: ''
        })
    }
    
    const handleChangeRoom = (room) => {
        setBookingOptionsData(room)
        if (!room) {
            setCurrentOnboardBooking({
                date: currentOnboardBooking.date,
                roomId: '',
                bookingId: ''
            })
            return;
        }
        
        setCurrentOnboardBooking({
            date: currentOnboardBooking.date,
            roomId: room.value,
            bookingId: ''
        })
        setNewOnboardBooking({...newOnboardBooking, room_id: room.value})
    };
    const handleChangeBooking = (booking) => {
        
        if (!booking) {
            setCurrentOnboardBooking({
                date: currentOnboardBooking.date,
                roomId: currentOnboardBooking.roomId,
                bookingId: ''
            })
            return
        }

        setCurrentOnboardBooking({
            date: currentOnboardBooking.date,
            roomId: currentOnboardBooking.roomId,
            bookingId: booking.value
        })
        setNewOnboardBooking({...newOnboardBooking, booking_id: booking.value})
    }

    // currency control
    const currencyOptions = [
        {label: 'VNĐ', value: 'vnd'},
        {label: 'USD', value: 'usd'}
    ];

    const handleChangeCurrency = (currency) => {
        const cc = currency && currency.value ? currency.value: '';
        if (cc === '' && newOnboardBooking.onboard_service) {
            setShowToast({show: true, message: 'Please set payment currency', type: 'danger'})
        }
        
        const cVal = currencyOptions.find((item) => item.value === cc)
        
        if (cVal) {
            setCurrencyVal(cVal)
        } else {
            setCurrencyVal('')
        }
        
        calculateTotal(inputList, cVal);
    }

    const resetaddOnboardBookingData = () => {
        setNewOnboardBooking({
            booking_id: '',
            room_id: '',
            checked_in: '',
            checked_out: '',
            onboard_service: '',
            total: '',
            description: '',
            currency: '',
        })
        setOnBoardBookingData({})
        setCheckedInDatetime('')
        setCheckedOutDatetime('')
        setTotal({ totalVND: 0, totalUSD: 0})
        setCheckedInDatetime('');
        setCheckedOutDatetime('');
        setinputList([{onboard_service:'', quantity:''}]);
        setCurrencyVal('');
    }

    const onChangeNewOnboardBookingForm = event => setNewOnboardBooking( {...newOnboardBooking, [event.target.name]: event.target.value } )

    const [checkedInDatetime, setCheckedInDatetime] = useState(newOnboardBooking.checked_in)
    const [checkedOutDatetime, setCheckedOutDatetime] = useState(newOnboardBooking.checked_out)

    const handleChangeCheckbox = (event) => {
        if (!event.target.checked) {
            newOnboardBooking[event.target.name] = ''

            switch(event.target.name) {
                case 'checked_in':
                    setCheckedInDatetime('')
                    break
                case 'checked_out':
                    setCheckedOutDatetime('')
                    break
            }
            return
        }

        const currentDateTime = new Date()
        newOnboardBooking[event.target.name] = currentDateTime.toISOString()
        const datetimeForDisplay = currentDateTime.getFullYear() +"/"+ (currentDateTime.getMonth()+1) +"/"+ currentDateTime.getDate() + " " + currentDateTime.getHours() + ":" + currentDateTime.getMinutes() + ":" + currentDateTime.getSeconds();

        switch(event.target.name) {
            case 'checked_in':
                setCheckedInDatetime(datetimeForDisplay)
                return
            case 'checked_out':
                setCheckedOutDatetime(datetimeForDisplay)
                return
        }
    }

    const onSubmit = async event => {
        event.preventDefault()
        
        if (!newOnboardBooking.currency && newOnboardBooking.onboard_service) {
            setShowToast({show: true, message: 'Please set payment currency', type: 'danger'})
            return
        }

        if (!newOnboardBooking.checked_in || newOnboardBooking.checked_in === '2001-01-01T00:00:00.000Z') {
            delete newOnboardBooking.checked_in
        }
        if (!newOnboardBooking.checked_out  || newOnboardBooking.checked_out === '2001-01-01T00:00:00.000Z') {
            delete newOnboardBooking.checked_out
        }
        if (!newOnboardBooking.total) {
            delete newOnboardBooking.total
        }

        // update
        if (newOnboardBooking.id) {
            // write update here
            const response = await updateOnboardBooking(newOnboardBooking)
            if (!response.error) {
                closeDialog()
                // set rooms state change
                const updatedRooms = rooms.map((item) => {
                    if (item.bookings && item.bookings[currentOnboardBooking.date] && item.id == newOnboardBooking.room_id) {
                        const customers = item.bookings[currentOnboardBooking.date];
                        const updatedCustomer = customers.map((item) => {
                            item.checked_in = newOnboardBooking.checked_in;
                            item.checked_out = newOnboardBooking.checked_out;
                            item.onboard_booking_description = newOnboardBooking.description;
                            item.onboard_currency = newOnboardBooking.currency;
                            item.onboard_service = newOnboardBooking.onboard_service;
                            item.onboard_total = newOnboardBooking.total;
                            return item;
                        })
                        item.bookings[currentOnboardBooking.date] = updatedCustomer;
                        return item;
                    }
                    return item;
                });
                setRooms(updatedRooms)

                setShowToast({show: true, message: 'Updated successful!', type: 'success'})
                return
            }
            setShowToast({show: true, message: response.ErrorCode, type: 'danger'})

            return
        }

        // add new
        const response = await addOnboardBooking(newOnboardBooking)
        if (!response.error) {
            closeDialog()
            
            // set rooms state change
            const updatedRooms = rooms.map((item) => {
                if (item.bookings && item.bookings[currentOnboardBooking.date] && item.id == newOnboardBooking.room_id) {
                    const customers = item.bookings[currentOnboardBooking.date];
                    const updatedCustomer = customers.map((item) => {
                        item.checked_in = newOnboardBooking.checked_in;
                        item.checked_out = newOnboardBooking.checked_out;
                        item.onboard_booking_description = newOnboardBooking.description;
                        item.onboard_currency = newOnboardBooking.currency;
                        item.onboard_service = newOnboardBooking.onboard_service;
                        item.onboard_total = newOnboardBooking.total;
                        item.onboard_booking_id = response.data.id;
                        return item;
                    })
                    item.bookings[currentOnboardBooking.date] = updatedCustomer;
                    return item;
                }
                return item;
            });
            setRooms(updatedRooms)

            setShowToast({show: true, message: 'Added successful!', type: 'success'})
            return
        }
        setShowToast({show: true, message: response.ErrorCode, type: 'danger'})
    }

    const closeDialog = () => {
        resetaddOnboardBookingData()
        setShowAddOnboardBookingModal(false)
    }

    const customStyles = {
        menuPortal: provided => ({ ...provided, zIndex: 5 }),
    }
    return (
        <Modal show={showAddOnboardBookingModal} onHide={closeDialog}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Onboard Booking Service
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group">
                                <label>Date</label>
                                <input className="form-control" type="date" name='date' onChange={handleDateChange.bind(this)} value={currentOnboardBooking.date} />
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <label>Room</label>
                                <Select isClearable
                                    name="room_id"
                                    onChange={handleChangeRoom.bind(this)}
                                    options={roomOptions}
                                    placeholder="Choose Room"
                                    value={roomVal}
                                    menuPosition={'fixed'}
                                    styles={customStyles}
                                />
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <label>Booking Code</label>
                                <Select isClearable
                                    name="booking_id"
                                    onChange={handleChangeBooking.bind(this)}
                                    options={bookingOptions}
                                    placeholder="Choose Booking"
                                    value={bookingVal}
                                    menuPosition={'fixed'}
                                    styles={customStyles}
                                />
                                            
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group">
                                <AddMoreOnboardService calculateTotal={calculateTotal} inputList={inputList} setinputList={setinputList} onboardServices={newOnboardBooking.onboard_service} onboardServiceOptions={onboardServiceOptions} setTotal={setTotal} setNewOnboardBooking={setNewOnboardBooking} newOnboardBooking={newOnboardBooking}/>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-6">
                            <div className="form-group">
                                {/* checkox: checked or unchecked. Default is checked in date time */}
                                <div className="checkbox">
                                    <div className="custom-checkbox custom-control">
                                        <input type="checkbox" className="custom-control-input" name="checked_in" id="checked_in" onChange={handleChangeCheckbox.bind(this)} checked={checkedInDatetime? 'checked': null}/>
                                        <label htmlFor="checked_in" className="custom-control-label mt-1">Check In {checkedInDatetime ? `( ${checkedInDatetime} )`: ''}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                {/* checkox: checked or unchecked. Default is checked out date time */}
                                <div className="checkbox">
                                    <div className="custom-checkbox custom-control">
                                        <input type="checkbox" className="custom-control-input" name="checked_out" id="checked_out" onChange={handleChangeCheckbox.bind(this)} checked={checkedOutDatetime? 'checked': null}/>
                                        <label htmlFor="checked_out" className="custom-control-label mt-1">Check Out {checkedOutDatetime ? `( ${checkedOutDatetime} )`: ''}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <Select isClearable
                                    name="booking_id"
                                    onChange={handleChangeCurrency.bind(this)}
                                    options={currencyOptions}
                                    placeholder="Payment Currency"
                                    value={currencyVal}
                                    menuPosition={'fixed'}
                                    styles={customStyles}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Total (USD): { new Intl.NumberFormat('de-DE', {maximumSignificantDigits:2}).format(total.totalUSD)}</label>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Total (VNĐ): { new Intl.NumberFormat().format(total.totalVND)}</label>
                            </div>
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Note</label>
                                <textarea className="form-control" placeholder="Description" rows="3" name='description' defaultValue={newOnboardBooking.description} spellCheck="false" onChange={onChangeNewOnboardBookingForm}></textarea>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                    <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default AddOnboardBookingModal
