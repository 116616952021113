import { TRANSPORTATION_TYPES_LOADED_SUCCESS, TRANSPORTATION_TYPES_LOADED_FAIL, ADD_TRANSPORTATION_TYPE, FIND_TRANSPORTATION_TYPE, UPDATE_TRANSPORTATION_TYPE, DELETE_TRANSPORTATION_TYPE } from '../contexts/constants';

export const transportationTypeReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case TRANSPORTATION_TYPES_LOADED_SUCCESS:
            return {
                ...state,
                transportationTypes: payload,
                transportationTypesLoading: false
            }
        case TRANSPORTATION_TYPES_LOADED_FAIL:
            return {
                ...state,
                transportationTypes: [],
                transportationTypesLoading: false
            }
        case ADD_TRANSPORTATION_TYPE:
            {
                return {
                    ...state,
                    transportationTypes: [payload, ...state.transportationTypes],
                }
            }
        case FIND_TRANSPORTATION_TYPE:
            return {
                ...state,
                transportationType: payload
            }
        case UPDATE_TRANSPORTATION_TYPE:
            const newTransportationType = state.transportationTypes.map(transportationType => {
                if (transportationType.id === payload.id) {
                    return payload
                } else {
                    return transportationType
                }
            })
            return {
                ...state,
                transportationTypes: newTransportationType
            }
        case DELETE_TRANSPORTATION_TYPE:
            return {
                ...state,
                transportationTypes: state.transportationTypes.filter(transportationType => transportationType.id !== payload)
            }
        default:
            return state
    }
}