import moment from 'moment';
import React from 'react';
import Button from 'react-bootstrap/Button';
import { handleExportCustomerNoTransport } from './HandleExportCustomerNoTransport';
import { getCompany } from '../../utils/common';
import { companyInfo } from './revenue/Company';

const createDownLoadData = ({ customerTransports, dateInfo, quantity }) => {
	const dateFormatted = moment(dateInfo).format('DD/MM/YYYY');
	const company = getCompany();
	const comInfo = companyInfo[company] ? companyInfo[company] : [];

	handleExportCustomerNoTransport(customerTransports, dateInfo, quantity, comInfo).then((url) => {
		const downloadAnchorNode = document.createElement('a');
		downloadAnchorNode.setAttribute('href', url);
		downloadAnchorNode.setAttribute('download', `customer-no-transportation-${dateFormatted}.xlsx`);
		downloadAnchorNode.click();
		downloadAnchorNode.remove();
	});
};

export const ExportCustomerNoTransportXLSX = ({ customerTransports, label, dateInfo, quantity, classes }) => {
	return (
		<Button
			className={`btn btn-primary float-right ${classes}`}
			// variant='warning'
			onClick={() => {
				createDownLoadData({
					customerTransports,
					dateInfo,
					quantity
				});
			}}
		>
			{label}
		</Button>
	);
};
