import { Fragment } from 'react'
import ListSetting from '../../components/layout/settings/ListSetting'

const Setting = () => {
    return (
        <Fragment>
            <div className="horizontal-mainwrapper container clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-3">
                        <div className="row">
                            <div className="col-md-4">
                                <h3 className="pull-left">List Date Setting for Cruises </h3>
                            </div>
                        </div>
                    </div>
                    <ListSetting />
                </div>
            </div>
        </Fragment>
    )
}

export default Setting
