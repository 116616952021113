import { Fragment, useEffect, useState } from 'react';
import { apiUrl } from '../../contexts/constants';
import axios from 'axios';
import { getAPI } from '../../utils/api';

const InfoCommand = props => {

    const setting = props.settingProps

    const [guide, setDataGuide] = useState([])
    const [driver, setDataDriver] = useState([])
    const [operator, setDataOperator] = useState([])
    let guideId = 0
    let driver_id = 0
    let operator_id = 0
    if(setting !== undefined){
        guideId = setting.guide_id
        driver_id = setting.driver_id
        operator_id = setting.operator_id
    }

    useEffect( () => {
        if(guideId !== 0){
            async function getGuide(){
                try {
                    const response = await getAPI(`${apiUrl}/guide/${guideId}`)
                    setDataGuide(response.data)
                } catch (error) {
                    return error.response ? error.response : {success: false, message: 'Server error!'}
                }
            }
            getGuide()
            //getGuideCar(setting.driver_id, 'driver')
        }
    }, [guideId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () => {
        if(driver_id !== 0){
            async function getDriver(){
                try {
                    const response = await getAPI(`${apiUrl}/guide/${driver_id}`)
                    setDataDriver(response.data)
                } catch (error) {
                    return error.response ? error.response : {success: false, message: 'Server error!'}
                }
            }

            getDriver()
        }
    }, [driver_id]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () => {
        if(operator_id !== 0){
            async function getOperator(){
                try {
                    const response = await getAPI(`${apiUrl}/user/${operator_id}`)
                    setDataOperator(response.data)
                } catch (error) {
                    return error.response ? error.response : {success: false, message: 'Server error!'}
                }
            }

            getOperator()
        }
    }, [operator_id]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fragment>
            {guide.length !== 0 ? (
            <p><strong>Guide:</strong>&nbsp;&nbsp;{guide.title + '. ' + guide.name + ' - ' + guide.phone}</p>
            ) : ('')}
            {driver.length !== 0 ? (
            <p><strong>Driver:</strong>&nbsp;&nbsp;{driver.title + '. ' + driver.name + ' - ' + driver.phone}</p>
            ) : ('')}
            {operator.length !== 0 ? (
            <p><strong>Operator:</strong>&nbsp;&nbsp;{operator.name}</p>
            ) : ('')}
        </Fragment>
    )
}

export default InfoCommand
