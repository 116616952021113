import { Fragment, useState, useEffect, useContext } from 'react';
import { CRUISE_TYPES, apiUrl, ROLES, PERMISSIONS, NO_PERMISSION } from '../../contexts/constants';
import { Helmet } from 'react-helmet';
import { AuthContext } from '../../contexts/AuthContext';
import { BookingContext } from '../../contexts/BookingContext';
import { CruiseContext } from '../../contexts/CruiseContext';

import moment from 'moment';
import NumberFormat from 'react-number-format';
import CustomerInfo from '../../components/layout/bookings/CustomerInfo';
import CustomerActivities from '../../components/layout/bookings/CustomerActivities';
import UpdateBookingModal from '../../components/layout/bookings/UpdateBookingModal';
import { Link } from 'react-router-dom';
import AddPaymentModal from './AddPaymentModal';
import ListPaymentBooking from './ListPaymentBooking';
import { PartnerContext } from '../../contexts/PartnerContext';
import { CountryContext } from '../../contexts/CountryContext';
import { getAPI } from '../../utils/api';
import { useTranslation } from 'react-i18next';
import { getYearMonth } from '../../utils/common';

const Show = () => {
	const { t } = useTranslation();
	const [paidPayment, setPaidPayment] = useState(0);
	const [tripType, setTripType] = useState(CRUISE_TYPES.NIGHT);

	const {
		authState: { user }
	} = useContext(AuthContext);
	const { setShowAddPaymentModal, setShowUpdateBookingModal } = useContext(BookingContext);

	const {
		cruiseState: { cruises }
	} = useContext(CruiseContext);

	const {
		partnerState: { partners },
		getPartners
	} = useContext(PartnerContext);

	const {
		countryState: { countries },
		getCountries
	} = useContext(CountryContext);

	useEffect(() => {
		getPartners();
		if (!countries || !countries.length) getCountries();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	let fulUrl = window.location.href;
	let param1 = window.location.pathname;
	let arrUrl = param1.split('/');
	let arrCurrentUrl = fulUrl.split('.');
	let arrCurrent = arrCurrentUrl[0].split('//');
	let urlCurrent = arrCurrent[1];
	const [isCopy, setIsCopy] = useState(false);

	let bookingId = 0;
	if (arrUrl.length > 1) {
		bookingId = arrUrl[3];
	}

	const [bookingDetail, setDataBookingDetail] = useState([]);
	const [customers, setCustomersData] = useState([]);
	const bookedRoomIds = [...new Set(customers.filter((item) => item.room_id).map(item => item.room_id))]; 
	
	useEffect(() => {
		async function getBookings() {
			try {
				const response = await getAPI(`${apiUrl}/booking/${bookingId}`);
				setDataBookingDetail(response.data);
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		getBookings();

		// get customers
		async function getCustomers() {
			try {
				const response = await getAPI(
					`${apiUrl}/customer/bookings`,
					`filterFields=booking_id&filterFieldValues=${bookingId}`
				);
				setCustomersData(response.data);
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		getCustomers();
	}, [bookingId]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (bookingDetail !== null && cruises.length > 0) {
			const cruise = cruises.find((item) => {
				return item.id === bookingDetail.cruise_id;
			});

			if (!cruise) {
				return;
			}

			if (cruise.cabins > 0) {
				setTripType(CRUISE_TYPES.NIGHT);
			} else {
				setTripType(CRUISE_TYPES.DAY);
			}
		}
	}, [cruises, bookingDetail]); // eslint-disable-line react-hooks/exhaustive-deps

	const chooseBooking = () => {
		document.getElementById('root').scrollIntoView({ behavior: 'smooth' });
		setTimeout(function () {
			setShowUpdateBookingModal(true);
			setIsCopy(false);
		}, 300);
	};

	const chooseCopyBooking = () => {
		document.getElementById('root').scrollIntoView({ behavior: 'smooth' });
		setTimeout(function () {
			setShowUpdateBookingModal(true);
			setIsCopy(true);
		}, 300);
	};

	const docExport = () => {
		/*HtmlToDocx({
            exportElement: '#html-docx',
            exportFileName:`Confirm Booking for ${bookingDetail.partner_name} date ${moment(bookingDetail.start_date).format("MMMM D, YYYY")}.docx`,
            StringStyle: '',
            margins:{top: 1440,right: 1440,bottom: 1440,left: 1440,header: 720,footer: 720,gutter: 0}
        })*/
		const nameFile = `Confirm Booking for ${bookingDetail.partner_name} date ${moment(
			bookingDetail.start_date
		).format('MMMM D, YYYY')}`;
		Export2Word('html-docx', nameFile);
	};

	function Export2Word(element, filename = '') {
		var preHtml =
			"<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
		var postHtml = '</body></html>';
		var html = preHtml + document.getElementById(element).innerHTML + postHtml;

		var blob = new Blob(['\ufeff', html], {
			type: 'application/msword'
		});

		// Specify link url
		var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

		// Specify file name
		filename = filename ? filename + '.doc' : 'document.doc';

		// Create download link element
		var downloadLink = document.createElement('a');

		document.body.appendChild(downloadLink);

		if (navigator.msSaveOrOpenBlob) {
			navigator.msSaveOrOpenBlob(blob, filename);
		} else {
			// Create a link to the file
			downloadLink.href = url;

			// Setting the file name
			downloadLink.download = filename;

			//triggering the function
			downloadLink.click();
		}

		document.body.removeChild(downloadLink);
	}

	let paidAmount = 0;
	let balance = 0;
	let netIncome = 0;
	let totalAll = bookingDetail ? bookingDetail.total : 0;
	let body = null;

	if (paidPayment > 0) {
		paidAmount = paidPayment;
	}

	const moneyCalculate = (bookingDetail) => {
		balance = bookingDetail.total - paidAmount;
		if (!bookingDetail.com_year || bookingDetail.com_year < 0) {
			bookingDetail.com_year = 0;
		}

		netIncome = bookingDetail.total - bookingDetail.com_year;

		if (bookingDetail.discount > 0) {
			totalAll = totalAll + bookingDetail.discount;
		}

		return { balance, netIncome, totalAll };
	};

	if (!user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR)
			&& !user.Permissions.includes(PERMISSIONS.VIEW_BOOKING)) return NO_PERMISSION;

	if (bookingDetail) {
		const {year, month} = getYearMonth(bookingDetail.start_date);
		const { balance, netIncome, totalAll } = moneyCalculate(bookingDetail);

		body = (
			<>
				<Helmet>
					<title>Show Booking - Cruises Management Software</title>
				</Helmet>
				<div className='horizontal-mainwrapper container-fluid clearfix'>
					<div className='explain-dashboard mt-5'>
						<div className='explain-header mb-3'>
							<div className='row'>
								<div className='col-md-12'>
									<div className='info-header-detail'>
										<h1 className='float-left'>{t('Information Booking')} - {bookingDetail.new_code}</h1>
										<a href={'/bookings/' + month + '-' + year + '-' + bookingDetail.cruise_id}><i className='fe fe-arrow-up-right go-to-list' title={t('Go to booking list')}></i></a>
										{user.id == bookingDetail.assign_to ||
										user.Permissions.includes(PERMISSIONS.UPDATE_BOOKING) ||
										user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ? (
											<button
												className='btn btn-success float-right'
												onClick={chooseCopyBooking.bind(this)}
											>
												<i className='icon ion-ios-copy'></i> {t('Copy')} Booking
											</button>
										) : null}
										{user.id == bookingDetail.assign_to ||
										user.Permissions.includes(PERMISSIONS.UPDATE_BOOKING) ||
										user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ? (
											<button
												className='btn btn-customer float-right'
												style={{ marginRight: '10px' }}
												onClick={chooseBooking.bind(this)}
											>
												<i className='fe fe-edit'></i> {t('Update')} Booking
											</button>
										) : null}
										<button
											className='btn btn-primary float-right'
											style={{ marginRight: '10px' }}
											onClick={docExport.bind(this)}
										>
											<i className='fe fe-check-circle'></i> {t('Export Confirm Booking')}
										</button>
										{paidAmount < bookingDetail.total &&
										(user.id == bookingDetail.assign_to ||
											user.Permissions.includes(PERMISSIONS.UPDATE_BOOKING_PAYMENT) ||
											user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR)) ? (
											<button
												className='btn btn-info float-right'
												style={{ marginRight: '10px' }}
												onClick={setShowAddPaymentModal.bind(this, true)}
											>
												<i className='las la-money-check-alt'></i> {t('Add Payment')}
											</button>
										) : null}
									</div>
								</div>
							</div>
							<hr />
							<div className='row'>
								<div className='col-md-6'>
									<article className='message is-info'>
										<div className='message-body'>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Booking Code')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															{bookingDetail.new_code
																? bookingDetail.new_code
																: bookingDetail.id}
														</p>
													</div>
												</div>
											</div>
											{user.department !== 'member' && (
												<div className='field is-horizontal'>
													<div className='field-label is-normal'>
														<label className='label'>{t('Partner')}</label>
													</div>
													<div className='field-body'>
														<div className='field'>
															<p className='control'>
																<Link
																	to={`/manage/partners/${bookingDetail.partner_id}`}
																	target='_blank'
																>
																	{bookingDetail.partner_name}
																</Link>
															</p>
														</div>
													</div>
												</div>
											)}
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Partner Code')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>{bookingDetail.booking_code}</p>
													</div>
												</div>
											</div>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Cruise')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>{bookingDetail.cruise_name}</p>
													</div>
												</div>
											</div>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Itinerary')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															<strong>{bookingDetail.itinerary_name}</strong>
														</p>
													</div>
												</div>
											</div>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Departure')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															<strong>
																{moment(bookingDetail.start_date).format(
																	'MMMM D, YYYY'
																)}
															</strong>
														</p>
													</div>
												</div>
											</div>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Return')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															<strong>
																{moment(bookingDetail.end_date).format('MMMM D, YYYY')}
															</strong>
														</p>
													</div>
												</div>
											</div>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Group size')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															<strong>
																{bookingDetail.adults + ' ' + t('Adult')}{' '}
																{bookingDetail.children > 0
																	? ' - ' +
																	  bookingDetail.children +
																	  ' ' + t('Children') + ' (5 - 7)'
																	: ''}{' '}
																{bookingDetail.children11 > 0
																	? ' - ' +
																	  bookingDetail.children11 +
																	  ' ' + t('Children')+ ' (8-11)'
																	: ''}{' '}
																{bookingDetail.infants > 0
																	? ' - ' + bookingDetail.infants + ' ' + t('Infant')
																	: ''} / {bookedRoomIds? bookedRoomIds.length: 0} {t('rooms')}
															</strong>
														</p>
													</div>
												</div>
											</div>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Status')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															<strong>{t(bookingDetail.status)}</strong>
														</p>
													</div>
												</div>
											</div>

											{bookingDetail.status === 'Pending' ? (
												<Fragment>
													<div className='field is-horizontal'>
														<div className='field-label is-normal'>
															<label className='label'>{t('Hold until')}</label>
														</div>
														<div className='field-body'>
															<div className='field'>
																<p className='control'>
																	<strong>
																		{moment(bookingDetail.hold_date).format(
																			'MMMM D, YYYY'
																		)}
																	</strong>
																</p>
															</div>
														</div>
													</div>
													<div className='field is-horizontal'>
														<div className='field-label is-normal'>
															<label className='label'>{t('Select time')}</label>
														</div>
														<div className='field-body'>
															<div className='field'>
																<p className='control'>
																	<strong>{bookingDetail.hold_time}</strong>
																</p>
															</div>
														</div>
													</div>
													<div className='field is-horizontal'>
														<div className='field-label is-normal'>
															<label className='label'>{t('Message')}</label>
														</div>
														<div className='field-body'>
															<div className='field'>
																<p className='control'>{bookingDetail.message}</p>
															</div>
														</div>
													</div>
												</Fragment>
											) : (
												''
											)}

											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Assign To')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>{bookingDetail.assign_name}</p>
													</div>
												</div>
											</div>
										</div>
									</article>
								</div>
								<div className='col-md-6'>
									<article className='message is-info'>
										<div className='message-body'>
											{bookingDetail.duration > 1 ? (
												<div className='field is-horizontal'>
													<div className='field-label is-normal'>
														<label className='label'>{t('Cabin')}</label>
													</div>
													<div className='field-body'>
														<div className='field'>
															<p className='control'>
																<strong>{bookingDetail.cabin_desc}</strong>
															</p>
														</div>
													</div>
												</div>
											) : (
												''
											)}
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Pick-up/Drop-off')}</label>
												</div>
												<div className='field-body'>
													<div
														className='field'
														dangerouslySetInnerHTML={{
															__html: bookingDetail.pickup
														}}
													></div>
												</div>
											</div>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Sales notes')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p
															className='control'
															dangerouslySetInnerHTML={{
																__html: bookingDetail.sale_note
															}}
														></p>
													</div>
												</div>
											</div>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Notes Accounting')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p
															className='control'
															dangerouslySetInnerHTML={{
																__html: bookingDetail.acc_note
															}}
														></p>
													</div>
												</div>
											</div>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Operation Notes')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p
															className='control'
															dangerouslySetInnerHTML={{
																__html: bookingDetail.opt_note
															}}
														></p>
													</div>
												</div>
											</div>

											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Create By')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>{bookingDetail.created_by_name}</p>
													</div>
												</div>
											</div>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Create Date')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															{moment(bookingDetail.created_at).format('MMMM D, YYYY')}
														</p>
													</div>
												</div>
											</div>
										</div>
									</article>
								</div>
							</div>
							<div className='row'>
								<div className='col-md-6'>
									<ListPaymentBooking
										bookingDetail={bookingDetail}
										paidAmount={paidAmount}
										balance={balance}
										netIncome={netIncome}
										setPaidPayment={setPaidPayment}
									/>
								</div>
								<div className='col-md-6'>
									<article className='message is-danger'>
										<div className='message-body'>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Total')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															{bookingDetail.money_type === 'usd' ? (
																<NumberFormat
																	value={totalAll ? totalAll : 0}
																	displayType={'text'}
																	thousandSeparator='.'
																	decimalSeparator=','
																	decimalScale={2}
																	prefix={'USD '}
																/>
															) : (
																//bookingDetail.total + ' USD'
																<NumberFormat
																	value={totalAll ? totalAll : 0}
																	displayType={'text'}
																	thousandSeparator='.'
																	decimalSeparator=','
																	decimalScale={0}
																	prefix={'VNĐ '}
																/>
															)}
														</p>
													</div>
												</div>
											</div>
											{bookingDetail.comYear > 0 && (
												<div className='field is-horizontal'>
													<div className='field-label is-normal'>
														<label className='label'>{t('Commission')}</label>
													</div>
													<div className='field-body'>
														<div className='field'>
															<p className='control'>
																{bookingDetail.money_type === 'usd' ? (
																	<NumberFormat
																		value={bookingDetail.comYear}
																		displayType={'text'}
																		thousandSeparator='.'
																		decimalSeparator=','
																		decimalScale={2}
																		prefix={'USD '}
																	/>
																) : (
																	<NumberFormat
																		value={bookingDetail.comYear}
																		displayType={'text'}
																		thousandSeparator='.'
																		decimalSeparator=','
																		decimalScale={0}
																		prefix={'VNĐ '}
																	/>
																)}
															</p>
															<p className='control'>{bookingDetail.com_note}</p>
														</div>
													</div>
												</div>
											)}

											{bookingDetail.discount > 0 ? (
												<div className='field is-horizontal'>
													<div className='field-label is-normal'>
														<label className='label'>{t('Discount')}</label>
													</div>
													<div className='field-body'>
														<div className='field'>
															<p className='control'>
																{bookingDetail.money_type === 'usd' ? (
																	<NumberFormat
																		value={bookingDetail.discount}
																		displayType={'text'}
																		thousandSeparator='.'
																		decimalSeparator=','
																		decimalScale={2}
																		prefix={'USD '}
																	/>
																) : (
																	<NumberFormat
																		value={bookingDetail.discount}
																		displayType={'text'}
																		thousandSeparator='.'
																		decimalSeparator=','
																		decimalScale={0}
																		prefix={'VNĐ '}
																	/>
																)}
															</p>
															<p className='control'>{bookingDetail.discountNote}</p>
														</div>
													</div>
												</div>
											) : null}

											{bookingDetail.extra_service > 0 ? (
												<div className='field is-horizontal'>
													<div className='field-label is-normal'>
														<label className='label'>{t('Extra Service')}</label>
													</div>
													<div className='field-body'>
														<div className='field'>
															<p className='control'>
																{bookingDetail.money_type === 'usd' ? (
																	<NumberFormat
																		value={bookingDetail.extra_service}
																		displayType={'text'}
																		thousandSeparator='.'
																		decimalSeparator=','
																		decimalScale={2}
																		prefix={'USD '}
																	/>
																) : (
																	<NumberFormat
																		value={bookingDetail.extra_service}
																		displayType={'text'}
																		thousandSeparator='.'
																		decimalSeparator=','
																		decimalScale={0}
																		prefix={'VNĐ '}
																	/>
																)}
															</p>
															<p className='control'>{bookingDetail.extra_note}</p>
														</div>
													</div>
												</div>
											) : null}

											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Paid Amount')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															{bookingDetail.money_type === 'usd' ? (
																<NumberFormat
																	value={Number(paidAmount)}
																	displayType={'text'}
																	thousandSeparator='.'
																	decimalSeparator=','
																	decimalScale={2}
																	prefix={'USD '}
																/>
															) : (
																//bookingDetail.total + ' USD'
																<NumberFormat
																	value={paidAmount}
																	displayType={'text'}
																	thousandSeparator='.'
																	decimalSeparator=','
																	decimalScale={0}
																	prefix={'VNĐ '}
																/>
															)}
														</p>
													</div>
												</div>
											</div>

											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Balance')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															{bookingDetail.money_type === 'usd' ? (
																<NumberFormat
																	value={balance ? Number(balance) : 0.0}
																	displayType={'text'}
																	thousandSeparator='.'
																	decimalSeparator=','
																	decimalScale={2}
																	prefix={'USD '}
																/>
															) : (
																<NumberFormat
																	value={balance ? balance : 0}
																	displayType={'text'}
																	thousandSeparator='.'
																	decimalSeparator=','
																	decimalScale={0}
																	prefix={'VNĐ '}
																/>
															)}
														</p>
													</div>
												</div>
											</div>

											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Net Income')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															{bookingDetail.money_type === 'usd' ? (
																<NumberFormat
																	value={netIncome ? netIncome : 0.0}
																	displayType={'text'}
																	thousandSeparator='.'
																	decimalSeparator=','
																	decimalScale={2}
																	prefix={'USD '}
																/>
															) : (
																<NumberFormat
																	value={netIncome ? netIncome : 0}
																	displayType={'text'}
																	thousandSeparator='.'
																	decimalSeparator=','
																	decimalScale={0}
																	prefix={'VNĐ '}
																/>
															)}
														</p>
													</div>
												</div>
											</div>
											{bookingDetail.vat ? (
												<div className='field is-horizontal'>
													<div className='field-label is-normal'>
														<label className='label'>VAT</label>
													</div>
													<div className='field-body'>
														<div className='field'>
															<p className='control'>{bookingDetail.vat}%</p>
														</div>
													</div>
												</div>
											) : null}
										</div>
									</article>
								</div>
							</div>
							<CustomerInfo
								bookingIdProps={bookingId}
								durationProps={bookingDetail.duration}
								tripType={tripType}
								customers={customers}
							/>
							{user.department !== 'member' && (
								<CustomerActivities bookingProps={bookingDetail} tripType={tripType} />
							)}
						</div>
					</div>
				</div>
				{bookingDetail !== null && (
					<UpdateBookingModal
						isCopy={isCopy}
						bookingProps={bookingDetail}
						customers={customers}
						cruises={cruises}
						tripType={tripType}
						partners={partners}
						countries={countries}
						setDataBookingDetail={setDataBookingDetail}
						setCustomersData={setCustomersData}
					/>
				)}
				{bookingDetail !== null && (
					<AddPaymentModal
						bookingDetail={bookingDetail}
						paidAmount={paidAmount}
						balance={balance}
						netIncome={netIncome}
					/>
				)}
				<div style={{ display: 'none' }} id='html-docx'>
					<div className='container'>
						{urlCurrent === 'sena' ? (
							<article className='message'>
								<div className='message-body'>
									<p style={{ marginBottom: '0' }} className='control'>
										Dear Partner,
									</p>
									<p className='control'>
										Thank you so much for your email. We would like to confirm your booking as
										below:
									</p>
									<p style={{ marginBottom: '0px' }} className='control'>
										Cruise Name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										<b>{bookingDetail.cruise_name}</b>
									</p>
									<p style={{ marginBottom: '0px' }} className='control'>
										Booking Code:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										{bookingDetail.new_code}
									</p>
									<p style={{ marginBottom: '0px' }} className='control'>
										Agent Code:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										{bookingDetail.booking_code ? bookingDetail.booking_code : null}
									</p>
									<p style={{ marginBottom: '0px' }} className='control'>
										Guest Name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</p>
									<p style={{ marginBottom: '0px' }} className='control'>
										Number Of Guest:&nbsp;{bookingDetail.adults + ' Adult'}{' '}
										{bookingDetail.children > 0
											? ' - ' + bookingDetail.children + ' Children (5 - 7)'
											: ''}{' '}
										{bookingDetail.children11 > 0
											? ' - ' + bookingDetail.children11 + ' Children (8-11)'
											: ''}{' '}
										{bookingDetail.infants > 0 ? ' - ' + bookingDetail.infants + ' Infant' : ''}
									</p>
									<p style={{ marginBottom: '0px' }} className='control'>
										Itineraries:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										{bookingDetail.itinerary_name}
									</p>
									<p style={{ marginBottom: '0px' }} className='control'>
										Check In:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										{moment(bookingDetail.start_date).format('MMMM D, YYYY')}
									</p>
									<p style={{ marginBottom: '0px' }} className='control'>
										Check Out:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										{moment(bookingDetail.end_date).format('MMMM D, YYYY')}
									</p>
									<p style={{ marginBottom: '0px' }} className='control'>
										Type Of Room:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										{bookingDetail.cabin_desc}
									</p>
									<p style={{ marginBottom: '0px' }} className='control'>
										Pick Up / Drop Off:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										{bookingDetail.pickup}
									</p>
									<p style={{ marginBottom: '0px' }} className='control'>
										Dietary:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</p>
									<p style={{ marginBottom: '0px' }} className='control'>
										Special Request:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</p>
									<p style={{ marginBottom: '0px' }} className='control'>
										Total Price {bookingDetail.vat !== undefined ? `(VAT)` : null}
										:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										{bookingDetail.total}
									</p>
									<p style={{ marginBottom: '0px' }} className='control'>
										Payment Due:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;03 days before check in
									</p>
									{bookingDetail.vat !== undefined ? (
										<>
											<h3
												style={{
													color: '#c51717',
													fontSize: '18px',
													fontFamily: 'Roboto, sans-serif'
												}}
											>
												PAYMENT INFORMATION (VAT):
											</h3>
											<p style={{ marginBottom: '0px' }} className='control'>
												Account number:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0011 004 398
												591
											</p>
											<p style={{ marginBottom: '0px' }} className='control'>
												Beneficiary name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CONG TY CO
												PHAN DU LICH VINH THIEN NGA
											</p>
											<p style={{ marginBottom: '0px' }} className='control'>
												Bank name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Vietcombank - Branch
												Transaction Office, Hanoi
											</p>
										</>
									) : (
										<>
											{bookingDetail.cruise_id === 20 ? (
												<>
													<h3
														style={{
															color: '#c51717',
															fontSize: '18px',
															fontFamily: 'Roboto, sans-serif'
														}}
													>
														PAYMENT INFORMATION (NO VAT):
													</h3>
													<p style={{ marginBottom: '0px' }} className='control'>
														Account
														number:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;28118686
													</p>
													<p style={{ marginBottom: '0px' }} className='control'>
														Beneficiary name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CAO
														THUY THI
													</p>
													<p style={{ marginBottom: '0px' }} className='control'>
														Bank name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ACB Bank
													</p>
												</>
											) : (
												<>
													<h3
														style={{
															color: '#c51717',
															fontSize: '18px',
															fontFamily: 'Roboto, sans-serif'
														}}
													>
														PAYMENT INFORMATION (NO VAT):
													</h3>
													<p style={{ marginBottom: '0px' }} className='control'>
														Account number:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0033
														880 28
													</p>
													<p style={{ marginBottom: '0px' }} className='control'>
														Beneficiary name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CAO
														THUY THI
													</p>
													<p style={{ marginBottom: '0px' }} className='control'>
														Bank name:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;VIB Bank
													</p>
												</>
											)}
										</>
									)}
									<h3
										style={{
											color: '#c51717',
											fontSize: '18px',
											fontFamily: 'Roboto, sans-serif'
										}}
									>
										IMPORTANT NOTE:
									</h3>
									<p style={{ marginBottom: '0px' }}>
										1.&nbsp;&nbsp;Please send us <strong>{`guest's Passport information`}</strong>{' '}
										03 days before check-in as attached file and ask guests to take hand luggage
										only. If the guests have too much or oversized luggage, kindly inform us in
										advance.
									</p>
									<p style={{ marginBottom: '0px' }}>
										2.&nbsp;&nbsp;Please inform us if there is{' '}
										<strong>any special request on food</strong> such as vegetarian, food allergy...
										for our best arrangement.
									</p>
									<p style={{ marginBottom: '0px' }}>
										3.&nbsp;&nbsp;The pick-up time in The Old Quarter is{' '}
										<strong>08:00 AM - 08:30 AM</strong>. To make the service smooth for all
										passengers,{' '}
										<strong>
											please ask your guests to be ready at 08:00 AM at the hotel lobby. The
											pick-up time will be informed before 08:AM on the departure date
										</strong>
										. Any late readiness beyond this time and miss the bus, our cruise is not
										responsible.
									</p>
									<p style={{ marginBottom: '0px' }}>
										4.&nbsp;&nbsp;<strong>Arriving time</strong> at Swan Cruises Office (
										<strong>Tuan Chau Harbour</strong>) should be at{' '}
										<strong>11:45 AM - 12:00 PM</strong> and{' '}
										<strong>check-out time is 11:30 AM - 12:00 PM next day</strong>. Please ask your
										guide/driver to contact the receptionist by <strong>+84 869 699 337</strong> to
										pick up your guests at Tuan Chau Harbour smoothly.
									</p>

									<p style={{ marginBottom: '0px' }} className='control'>
										We thank you for your kind cooperation and look forward to welcoming our guests.
									</p>
									<p style={{ marginBottom: '0px' }} className='control'>
										If you need further information, please do not hesitate to contact us!
									</p>
									<p style={{ marginTop: '10px', marginBottom: '0' }} className='control'>
										<i>Best regards,</i>
										<br />
										<i>{user.name}</i>
									</p>
								</div>
							</article>
						) : (
							<div className='info-confirm'>
								<h3
									style={{
										fontSize: '15px',
										fontWeight: 'bold',
										textAlign: 'center',
										textTransform: 'uppercase'
									}}
								>
									MẪU FORM XÁC NHẬN DỊCH VỤ {bookingDetail.cruise_name}
								</h3>
								<p style={{ fontSize: '15px', textAlign: 'left' }}>
									Dear {bookingDetail.partner_name},
								</p>
								<p style={{ fontSize: '15px', textAlign: 'left' }}>
									Thank you very much for choosing {bookingDetail.cruise_name} for your{' '}
									{bookingDetail.itinerary_name} vacation in Lan Ha Bay.
								</p>
								<table
									cellPadding='0'
									cellSpacing='0'
									style={{
										width: '100%',
										borderCollapse: 'collapse',
										borderSpacing: '0'
									}}
								>
									<tbody>
										<tr>
											<td
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
											>
												Tour code
											</td>
											<td
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
											>
												{bookingDetail.new_code}
											</td>
										</tr>
										<tr>
											<td
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
											>{`Guests’ names`}</td>
											<td
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
											>
												{customers &&
													customers.map((customer, index) => (
														<p key={customer.id}>
															{customer.full_name
																? index === 0
																	? `- ${customer.full_name}`
																	: `- ${customer.full_name}`
																: '-'}
														</p>
													))}
											</td>
										</tr>
										<tr>
											<td
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
											>
												Number of guests
											</td>
											<td
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
											>
												{bookingDetail.adults + ' Adult'}{' '}
												{bookingDetail.children > 0
													? ' - ' + bookingDetail.children + ' Children (5 - 7)'
													: ''}{' '}
												{bookingDetail.children11 > 0
													? ' - ' + bookingDetail.children11 + ' Children (8-11)'
													: ''}{' '}
												{bookingDetail.infants > 0
													? ' - ' + bookingDetail.infants + ' Infant'
													: ''}
											</td>
										</tr>
										<tr>
											<td
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
											>
												Number of rooms & Type of rooms
											</td>
											<td
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
											>
												{customers &&
													customers.map((customer, index) => (
														<p key={customer.id}>
															{index === 0
																? `- ${customer.room_name}`
																: `- ${customer.room_name}`}
														</p>
													))}
												<p>{bookingDetail.cabin_desc}</p>
											</td>
										</tr>
										<tr>
											<td
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
											>
												Check-in date: {moment(bookingDetail.start_date).format('MMMM D, YYYY')}
											</td>
											<td
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
											>
												Check-out date: {moment(bookingDetail.end_date).format('MMMM D, YYYY')}
											</td>
										</tr>
										<tr>
											<td
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
											>
												Cabin rate
											</td>
											<td
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
											>
												{bookingDetail.cabin_desc}
											</td>
										</tr>
										<tr>
											<td
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
											>
												Shared limousine service
											</td>
											<td
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
											>
												{customers &&
													customers.map((customer, index) => (
														<p key={customer.id}>
															{customer.cabin_speical_name
																? index === 0
																	? `- ${
																			customer.cabin_speical_name !== undefined &&
																			customer.cabin_speical_name
																	  }`
																	: `- ${
																			customer.cabin_speical_name !== undefined &&
																			customer.cabin_speical_name
																	  }`
																: '-'}
														</p>
													))}
											</td>
										</tr>
										<tr>
											<td
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
											>
												Pick-up time & point
											</td>
											<td
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
												dangerouslySetInnerHTML={{
													__html: bookingDetail.pickup
												}}
											></td>
										</tr>
										<tr>
											<td
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
											>
												Drop-off time & point
											</td>
											<td
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
												dangerouslySetInnerHTML={{
													__html: bookingDetail.pickup
												}}
											></td>
										</tr>
										<tr>
											<td
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
											>
												Total amount (exclude VAT of 8%)
											</td>
											<td
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
											>
												{bookingDetail.money_type === 'usd' ? (
													<NumberFormat
														value={bookingDetail.total}
														displayType={'text'}
														thousandSeparator='.'
														decimalSeparator=','
														decimalScale={2}
														prefix={'USD '}
													/>
												) : (
													<NumberFormat
														value={bookingDetail.total}
														displayType={'text'}
														thousandSeparator='.'
														decimalSeparator=','
														decimalScale={0}
														prefix={'VNĐ '}
													/>
												)}
											</td>
										</tr>
										<tr>
											<td
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
											>
												Payment
											</td>
											<td
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
											>
												<p>Deadline for deposit: </p>
												<p>Deadline for balance: </p>
											</td>
										</tr>
										<tr>
											<td
												colSpan='2'
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
											>
												<span style={{ color: '#ed0d0d' }}>Bank transfer information:</span>{' '}
												<br />
												Số tài khoản: 22210005176073 <br />
												Chủ tài khoản: Nguyễn Ngọc Diệp <br />
												Ngân hàng BIDV - chi nhánh Thanh Xuân <br />
											</td>
										</tr>
										<tr>
											<td
												colSpan='2'
												style={{
													border: '1px dotted #111111',
													width: '50%',
													padding: '10px',
													fontSize: '15px',
													fontWeight: 'bold'
												}}
											>
												<p style={{ fontStyle: 'italic' }}>
													** Passenger information including Full name shown on the passport,
													Date of birth, Gender, Nationality, Passport number, Guide's mobile
													number and Pick-up address, Special dietary (if yes) ... must be
													sent to us 03 days before departure.
												</p>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						)}
					</div>
				</div>
			</>
		);
	}

	return <Fragment>{body !== null && body}</Fragment>;
};

export default Show;
