import { useContext, useState, useEffect } from 'react';
import { TransportationPickUpContext } from '../../contexts/TransportationPickUpContext';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const UpdateTransportationPickUpModal = () => {
	const { t } = useTranslation();
	const {
		transportationPickUpState: { transportationPickUp },
		showUpdateTransportationPickUpModal,
		showAddTransportationPickUpModal,
		setShowAddTransportationPickUpModal,
		setShowUpdateTransportationPickUpModal,
		updateTransportationPickUp,
		addTransportationPickUp,
		setShowToast,
		clearTransportationPickUp
	} = useContext(TransportationPickUpContext);
	const [updatedTransportationPickUp, setUpdatedTransportationPickUp] = useState(
		transportationPickUp ? transportationPickUp : {}
	);

	const { id, name, description } = updatedTransportationPickUp;
	useEffect(() => {
		setUpdatedTransportationPickUp(transportationPickUp ? transportationPickUp : {});
	}, [transportationPickUp]);
	const onChangeName = (event) =>
		setUpdatedTransportationPickUp({
			...updatedTransportationPickUp,
			[event.target.name]: event.target.value
		});
	const onChangeDescription = (event) => {
		setUpdatedTransportationPickUp({
			...updatedTransportationPickUp,
			[event.target.name]: event.target.value
		});
	};
	const onSubmit = async (event) => {
		event.preventDefault();

		// add transportation pickup
		if (showAddTransportationPickUpModal) {
			delete updatedTransportationPickUp.id;
			const response = await addTransportationPickUp(updatedTransportationPickUp);
			if (response.data) {
				closeDialog();
				setShowToast({ show: true, message: t('Added successful!'), type: 'success' });
				return;
			}
			setShowToast({
				show: true,
				message: response.error && response.error.ErrorCode ? response.error.ErrorCode : 'Add failed!',
				type: 'danger'
			});
			return;
		}

		// update transportation pickup
		const response = await updateTransportationPickUp(updatedTransportationPickUp);
		if (response.status === 200) {
			closeDialog();
			setShowToast({
				show: true,
				message: t('Updated successful!'),
				type: 'success'
			});
			return;
		}
		setShowToast({
			show: true,
			message: response.error && response.error.ErrorCode ? response.error.ErrorCode : 'Update failed!',
			type: 'danger'
		});
	};
	const closeDialog = () => {
		clearTransportationPickUp();
		setShowUpdateTransportationPickUpModal(false);
		setShowAddTransportationPickUpModal(false);
	};

	return (
		<Modal
			show={showAddTransportationPickUpModal || showUpdateTransportationPickUpModal}
			onHide={closeDialog}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>
					{showUpdateTransportationPickUpModal
						? t('Update Transportation PickUp')
						: t('Create New Transportation PickUp')}
				</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					<div className=''>
						<div className='col-sm'>
							<div className='form-group'>
								<label>{t('Name')}</label>
								<input
									type='text'
									className='form-control'
									name='name'
									value={name}
									placeholder={t('Enter Transportation PickUp Name')}
									required
									aria-describedby='name-help'
									onChange={onChangeName}
								/>
								<Form.Text id='name-help' muted>
									{t('Required')}
								</Form.Text>
							</div>
						</div>
						<div className=''>
							<div className='col-md-12'>
								<div className='form-group'>
									<label>{t('Description')}</label>
									<textarea
										className='form-control'
										placeholder={t('Description')}
										rows='3'
										name='description'
										value={description}
										spellCheck='false'
										onChange={onChangeDescription}
									></textarea>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button className='btn btn-danger btn-with-icon' variant='secondary' onClick={closeDialog}>
						<i className='fe fe-x-circle'></i> {t('Cancel')}
					</Button>
					<Button className='btn btn-primary btn-with-icon' variant='primary' type='submit'>
						<i className='fe fe-save'></i> {t('Save')}!!!
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default UpdateTransportationPickUpModal;
