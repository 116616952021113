import { Fragment, useContext, useState, useEffect } from 'react';
import { PriceContext } from '../../../contexts/PriceContext';
import { Modal, Button, Form, Accordion, Card } from 'react-bootstrap';
import Select from 'react-select';
import { CruiseContext } from '../../../contexts/CruiseContext';
import { apiUrl, CRUISE_TYPES } from '../../../contexts/constants';
import { getAPI } from '../../../utils/api';
import { getOptions, moneyOptions } from '../../../utils/common';
import { useTranslation } from 'react-i18next';
import { CruiseSettingContext } from '../../../contexts/CruiseSettingContext';

const AddPriceModal = (props) => {
	const { t } = useTranslation();
	const setCurrentCruise = props.setCurrentCruise;

	const {
        cruiseSettingState: { cruiseSettings },
    } = useContext(CruiseSettingContext)
	
	// Context
	const { getPrices, addPrice, showAddPriceModal, setShowAddPriceModal, setShowToast } = useContext(PriceContext);

	const {
		cruiseState: { cruises },
		getCruises
	} = useContext(CruiseContext);

	const [arrItineraries, setArrItineraries] = useState([]);
	const [checkIti, setCheckIti] = useState(false);
	const [cruiseID, setCruiseID] = useState(0);
	const [cruiseSetting, setCruiseSetting] = useState({});
	const [idx, setIdx] = useState([]);

	const [prices, setPrices] = useState([]);
	let optionCruises = [];

	// Start Get all cruise , []
	useEffect(() => {
		if (cruises && cruises.length) return;
		getCruises('');
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Start: Get all cruise , []
	useEffect(() => {
		if (cruiseID > 0) {
			const cruiseSetting = cruiseSettings? cruiseSettings.find((item) => {
				return item.cruise_id == cruiseID;
			}): {}
			setCruiseSetting(cruiseSetting);
			async function itineraryData() {
				try {
					const response = await getAPI(
						`${apiUrl}/cruise-itineraries`,
						`filterFields=cruise_id&filterFieldValues=${cruiseID}`
					);
					setArrItineraries(response.data);
				} catch (error) {
					return error.response ? error.response : { success: false, message: 'Server error!' };
				}
			}
			itineraryData();
		}

	}, [cruiseID]); // eslint-disable-line react-hooks/exhaustive-deps

	// State
	const [newPrice, setNewPrice] = useState({
		price_name: '',
		season_type: '',
		from_date: '',
		to_date: ''
	});

	const { price_name, season_type, from_date, to_date } = newPrice;

	const onChangeText = (event) => setNewPrice({ ...newPrice, [event.target.name]: event.target.value });
	const onChangeNumber = (index, event) => {
		if (!prices[index]) {
			prices[index] = {};
		}
		const fieldNames = event.target.name.split('-');
		const fieldName = fieldNames[0];
		prices[index][fieldName] = Number(event.target.value);
	};

	optionCruises = getOptions(cruises, ['label', 'value', 'typeDay'], ['name', 'id', 'typeDay']);

	// select cruise
	const handleChangeCruise = (newValue) => {
		const dataCruise = newValue;

		if (dataCruise) {
			setCurrentCruise(dataCruise);
			setCruiseID(dataCruise.value);
			setNewPrice({ ...newPrice, cruise_id: Number(dataCruise.value), typeDay: dataCruise.typeDay });
			setCheckIti(true);
		} else {
			setCheckIti(false);
			setNewPrice({ ...newPrice, cruise_id: '', typeDay: '' });
			setCruiseID(0);
		}
	};

	const handleChangeMoney = (duration, itineraryID, index, newValue) => {
		const data = newValue;
		if (data) {
			if (!prices[index]) {
				prices[index] = {};
			}
			prices[index]['itinerary_id'] = Number(itineraryID);
			prices[index]['duration'] = Number(duration);
			prices[index]['money_type'] = data.value;
		} else {
			prices[index]['itinerary_id'] = '';
			prices[index]['duration'] = '';
			prices[index]['money_type'] = '';
		}
	};

	const onSubmit = async (event) => {
		event.preventDefault();
		newPrice['prices'] = prices;
		const response = await addPrice(newPrice);
		if (response.data) {
			closeDialog();
			setShowToast({
				show: true,
				message: t('Added sucessful!'),
				type: 'success'
			});

			getPrices();
			return;
		}
		setShowToast({
			show: true,
			message: response.error && response.error.ErrorCode ? response.error.ErrorCode : 'Add failed!',
			type: 'danger'
		});
	};

	const closeDialog = () => {
		setNewPrice({});
		setShowAddPriceModal(false);
	};

	const handleChangeAccordion = (numberIndex) => {
		if (idx.includes(numberIndex)) setIdx(idx.filter((i) => i !== numberIndex));
		else setIdx([numberIndex]);
	};

	return (
		<Fragment>
			<Modal
				show={showAddPriceModal}
				onHide={closeDialog}
				size='full'
				aria-labelledby='contained-modal-title-vcenter'
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>{t('Create New Price')}</Modal.Title>
				</Modal.Header>
				<Form onSubmit={onSubmit}>
					<Modal.Body>
						<div className='row'>
							<div className='col-md-8'>
								<div className='form-group'>
									<label>
										<strong>{t('Name')}</strong>
									</label>
									<input
										type='text'
										className='form-control'
										id='price_name'
										name='price_name'
										placeholder={t('Price Name')}
										value={price_name}
										required
										aria-describedby='name-help'
										onChange={onChangeText}
									/>
									<Form.Text id='name-help' muted>
										{t('Required')}
									</Form.Text>
								</div>
							</div>
							<div className='col-md-4'>
								<div className='form-group'>
									<label>
										<strong>{t('Cruise')}</strong>
									</label>
									<Select
										isClearable
										name='cruise_id'
										onChange={handleChangeCruise.bind(this)}
										options={optionCruises}
										placeholder={t('Choose Cruise')}
										aria-describedby='cruise-type'
									/>
									<Form.Text id='cruise-type' muted>
										{t('Required')}
									</Form.Text>
								</div>
							</div>
						</div>
						<div className='info-price'>
							<div className='row'>
								<div className='col-md-6'>
									<div className='form-group'>
										<label>
											<strong>{t('Price Type')}</strong>
										</label>
										<input
											type='text'
											className='form-control'
											id='season_type'
											name='season_type'
											value={season_type}
											placeholder={t('Ex: High Season')}
											required
											aria-describedby='type-price'
											onChange={onChangeText}
										/>
										<Form.Text id='type-price' muted>
											{t('Required')}
										</Form.Text>
									</div>
								</div>
								<div className='col-md-6'>
									<div className='row'>
										<div className='col-md-6'>
											<div className='form-group'>
												<label>
													<strong>{t('From')}</strong>
												</label>
												<Form.Control
													type='date'
													name='from_date'
													onChange={onChangeText}
													value={from_date}
													required
													aria-describedby='from-date'
												/>
												<Form.Text id='from-date' muted>
													{t('Required')}
												</Form.Text>
											</div>
										</div>
										<div className='col-md-6'>
											<div className='form-group'>
												<label>
													<strong>{t('To')}</strong>
												</label>
												<Form.Control
													type='date'
													name='to_date'
													onChange={onChangeText}
													value={to_date}
													required
													aria-describedby='to-date'
												/>
												<Form.Text id='to-date' muted>
													{t('Required')}
												</Form.Text>
											</div>
										</div>
									</div>
								</div>
							</div>
							<hr />
							{checkIti === true ? (
								<div id='accordion'>
									{arrItineraries &&
										arrItineraries.map((item, index) => (
											<Card key={item.id}>
												<Accordion>
													<Card.Header className='position-relative'>
														<Accordion.Toggle
															as={Button}
															variant='link'
															eventKey={`${index}`}
															onClick={handleChangeAccordion.bind(this, index)}
															className={`text-left`}
														>
															<label
																style={{
																	margin: '0px',
																	lineHeight: '35px',
																	cursor: 'pointer'
																}}
															>
																{idx.includes(index) ? <i className='fe fe-minus'></i> : <i className='fe fe-plus'></i>}
																&nbsp;&nbsp;&nbsp;<strong>{item.name}</strong>
															</label>
														</Accordion.Toggle>
													</Card.Header>
													<Accordion.Collapse eventKey={`${index}`}>
														<Card.Body>
															<div className='content-accordion'>
																<div className='row'>
																	<div className='col-sm'>
																		<div className='form-group'>
																			<label>
																				<strong>1 {t('Adult')}</strong>
																			</label>
																			<input
																				type='number'
																				step='any'
																				className='form-control'
																				id='adult_price'
																				name={`adult_price-${index}`}
																				placeholder={t('No. Price')}
																				required
																				onChange={onChangeNumber.bind(this, index)}
																			/>
																		</div>
																	</div>
																	<div className='col-sm'>
																		<div className='form-group'>
																			<label>
																				<strong>{cruiseSetting && cruiseSetting.child_label1? t(cruiseSetting.child_label1): t('Child (5-7)')}</strong>
																			</label>
																			<input
																				type='number'
																				step='any'
																				className='form-control'
																				id='child_price'
																				name={`child_price-${index}`}
																				placeholder={t('No. Price')}
																				required
																				onChange={onChangeNumber.bind(this, index)}
																			/>
																		</div>
																	</div>
																	<div className='col-sm'>
																		<div className='form-group'>
																			<label>
																				<strong>{cruiseSetting && cruiseSetting.child_lable2? t(cruiseSetting.child_lable2): t('Child (8-11)')}</strong>
																			</label>
																			<input
																				type='number'
																				step='any'
																				className='form-control'
																				id='child_price_2'
																				name={`child_price_2-${index}`}
																				placeholder={t('No. Price')}
																				required
																				onChange={onChangeNumber.bind(this, index)}
																			/>
																		</div>
																	</div>
																	<div className='col-sm'>
																		<div className='form-group'>
																			<label>
																				<strong>{`1 `  + t('Infant') + ` (< 5)`}</strong>
																			</label>
																			<input
																				type='number'
																				step='any'
																				className='form-control'
																				id='infant_price'
																				name={`infant_price-${index}`}
																				placeholder={t('No. Price')}
																				onChange={onChangeNumber.bind(this, index)}
																			/>
																		</div>
																	</div>
																	{/* <div className="col-sm">
                                                                        <div className="form-group">
                                                                            <label><strong>{`Discount`}</strong></label>
                                                                            <input type="number" step="any" className="form-control" id="discount" name={`discount-${index}`} placeholder="No. Price" onChange={onChangeNumber.bind(this, index)} />
                                                                        </div>
                                                                    </div> */}
																	{newPrice.typeDay === CRUISE_TYPES.NIGHT && (
																		<div className='col-sm'>
																			<div className='form-group'>
																				<label>
																					<strong>{t(`Single`)}</strong>
																				</label>
																				<input
																					type='number'
																					step='any'
																					className='form-control'
																					id='single_price'
																					name={`single_price-${index}`}
																					placeholder={t('No. Price')}
																					onChange={onChangeNumber.bind(this, index)}
																				/>
																			</div>
																		</div>
																	)}
																	<div className='col-sm'>
																		<div className='form-group'>
																			<label>
																				<strong>{t('Money Type')}</strong>
																			</label>
																			<Select
																				isClearable
																				name='money'
																				onChange={handleChangeMoney.bind(this, item.duration, item.id, index)}
																				options={moneyOptions}
																				placeholder={t('Choose Money Type')}
																				aria-describedby='money-type'
																				menuPosition={'fixed'}
																			/>
																		</div>
																	</div>
																</div>
															</div>
														</Card.Body>
													</Accordion.Collapse>
												</Accordion>
											</Card>
										))}
								</div>
							) : (
								''
							)}
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button className='btn btn-danger btn-with-icon' variant='secondary' onClick={closeDialog}>
							<i className='fe fe-x-circle'></i> {t('Cancel')}
						</Button>
						<Button className='btn btn-primary btn-with-icon' variant='primary' type='submit'>
							<i className='fe fe-save'></i> {t('Save')}!!!
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</Fragment>
	);
};

export default AddPriceModal;
