import { Fragment, useContext, useEffect } from 'react';
import { Bar, Doughnut } from "react-chartjs-2";
import { AuthContext } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { currencyFormatter, numberFormatter, sort } from '../../utils/common';
import { CruiseContext } from '../../contexts/CruiseContext';

const RoomChart = props => {
    const { t } = useTranslation();
    const { authState: { users }, getUsers } = useContext(AuthContext)
    useEffect(() => getUsers(''), []) // eslint-disable-line react-hooks/exhaustive-deps

    const {
        cruiseState: { cruises },
        getCruises
    } = useContext(CruiseContext)

    if (!cruises || !cruises.length) {
        getCruises();
    }

    const bookings = props.bookingProps;
    const cruiseName = props.cruiseNameProps;
    const companyData = props.companyData;
    const oosDaysNumber = props.oosDaysNumber;

    // cruise
    let bookedTotal = 0;
    let totalRevenueVND = 0;
    let totalRevenueUSD = 0;
    let roomTotal = 0;
    let daysInMonths = new Date(props.year, props.month, 0).getDate();
    // roomTotal = roomTotal * daysInMonths;
    let excludedRoomNum = 0;

    let pieChartData = []
    
    if(users !== null){
        users.map((item) => {
            //var total = 0
            if(item.department === 'sale' || item.department === 'isale'){
                let dataItem = {
                    name: item.name,
                    booked_rooms: 0,
                    vndValue: 0,
                    usdValue: 0
                }
                
                const p = bookings.find((i) => {
                    return i.user_id == item.id;
                })
                
                if (p) {
                    dataItem = {...dataItem, ...p};
                    dataItem.vndValue = p.revenue_vnd;
                    dataItem.usdValue = p.revenue_usd;
                }
                roomTotal = dataItem.room_total;
                if (oosDaysNumber > 0) {
                    excludedRoomNum = (roomTotal / daysInMonths) * oosDaysNumber;
                    roomTotal = (roomTotal / daysInMonths) * (daysInMonths - oosDaysNumber);
                }
                
                bookedTotal += dataItem.booked_rooms? dataItem.booked_rooms: 0;
                totalRevenueVND += dataItem.vndValue? dataItem.vndValue: 0;
                totalRevenueUSD += dataItem.usdValue? dataItem.usdValue: 0;
                pieChartData.push(dataItem);
            }
        })
    }
    
    sort(pieChartData, 'booked_rooms');
    const revenueLabels = pieChartData.map((item) => item.name);
    const stillRoom = roomTotal - bookedTotal
    const dataRooms = pieChartData.map((booking) => {
        return booking.booked_rooms;
    });
    pieChartData.push({
        name: t('Still Empty'),
        booked_rooms: stillRoom
    });

    // sort(pieChartData, 'value');

    const pieChartLabels = pieChartData.map((item) => {
        item.name = `${item.name}: ${roomTotal? numberFormatter(2).format(item.booked_rooms * 100 / roomTotal): 0}%`;
        return item.name;
    });
    const pieChartValues = pieChartData.map((item) => item.booked_rooms);
    const pieChartVNDValues = pieChartData.map((item) => item.vndValue);
    const pieChartUSDValues = pieChartData.map((item) => item.usdValue);
    // end cruise

    // company
    let companyRoom = 0;
    let companyRevenueVND = 0;
    let companyRevenueUSD = 0;
    let companyroomTotalInMonth = 0;
    const cData = companyData.map((item) => {
        companyRoom += item.booked_rooms? item.booked_rooms: 0;
        companyRevenueVND += item.revenue_vnd? item.revenue_vnd: 0;
        companyRevenueUSD += item.revenue_usd? item.revenue_usd: 0;
        companyroomTotalInMonth += item.room_total? item.room_total: 0;
        return item;
    });

    if (excludedRoomNum) {
        companyroomTotalInMonth -= excludedRoomNum;
    }

    sort(cData, 'booked_rooms');
    const companyRooms = cData.map((item) => {
        return item.booked_rooms;
    });
    
    const companyLabels = cData.map((item) => item.cruise_name);
    const companyRevenueVNDLabels = cData.map((item) => {
        return `${item.cruise_name}: ${companyRevenueVND? numberFormatter(2).format(item.revenue_vnd * 100 / companyRevenueVND): 0}%`
    });
    const companyRevenueUSDLabels = cData.map((item) => {
        return `${item.cruise_name}: ${companyRevenueUSD? numberFormatter(2).format(item.revenue_usd * 100 / companyRevenueUSD): 0}%`
    });
    cData.push({
        cruise_name: t('Still Empty'),
        booked_rooms: companyroomTotalInMonth - companyRoom
    });
    const companyPieChartLabels = cData.map((item) => {
        return `${item.cruise_name}: ${companyroomTotalInMonth? numberFormatter(2).format(item.booked_rooms * 100 / companyroomTotalInMonth): 0}%`
    });
    const companyPieChartValues = cData.map((item) => item.booked_rooms);
    const cPieChartRevenuesVND = cData.map((item) => item.revenue_vnd);
    const cPieChartRevenuesUSD = cData.map((item) => item.revenue_usd);
    
    // end company
    
    return (
        <Fragment>
            <div className="horizontal-mainwrapper clearfix">
                <div className="charjs">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>{cruiseName}</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="style-bar">
                                <div className="style-bar-chart">
                                    <Bar
                                        data={{
                                        labels: revenueLabels,
                                        datasets: [
                                            {
                                                label: t('Booked Rooms'),
                                                backgroundColor: "rgba(240,197,65,.6)",
                                                borderColor: "rgba(240,197,65,.6)",
                                                borderWidth: 1,
                                                stack: 1,
                                                //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                                                //hoverBorderColor: 'rgba(255,99,132,1)',
                                                data: dataRooms
                                            }
                                            ]
                                        }}
                                        options={{
                                            responsive: true,
                                            legend: { display: false },
                                            title: {
                                                display: true,
                                                text: t("Sale Room Report")
                                            },
                                            // scales: {
                                            //     xAxes: [{
                                            //         stacked: true
                                            //     }],
                                            //     yAxes: [{
                                            //         stacked: true
                                            //     }]
                                            // }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h2>{t('Total Room')} : {bookedTotal} / {roomTotal} ({roomTotal? numberFormatter(2).format(bookedTotal * 100/roomTotal): 0}%)</h2>
                            <div className="pie-charts mt-2">
                                <div className="pie-charts-doughnut">
                                    <Doughnut
                                        data={{
                                        labels: pieChartLabels,
                                        datasets: [
                                            {
                                                label: t("Population (millions)"),
                                                backgroundColor: [
                                                    '#36A2F5', '#34BFA3', '#FF518A', '#E07A10', '#631D7F', '#A426B399', '#F3236699', '#081AF1', '#129A3C', '#102F75', '#e2ec52', '#67655F99'
                                                ],
                                                data: pieChartValues
                                            }
                                        ]
                                        }}
                                        option={{
                                            title: {
                                                display: true,
                                                text: t("Total Room")
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className='margin-bottom-50'></hr>
                    <div className='row margin-bottom-50'>
                        <div className='col-md-12'>
                            <h2 className='text-center'>{t('Revenue Chart')}</h2>
                            <p className='text-center'> <strong>{t('Revenue Total (VND)')}:</strong> {currencyFormatter().format(totalRevenueVND)}, <strong>{t('Revenue Total (USD)')}:</strong> {currencyFormatter('USD').format(totalRevenueUSD)}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="style-bar">
                                <div className="style-bar-chart">
                                <Bar
                                    data={{
                                    labels: revenueLabels,
                                    datasets: [
                                        {
                                            label: t('Revenue (VND)'),
                                            backgroundColor: "rgba(240,197,65,.6)",
                                            borderColor: "rgba(240,197,65,.6)",
                                            borderWidth: 1,
                                            stack: 1,
                                            //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                                            //hoverBorderColor: 'rgba(255,99,132,1)',
                                            data: pieChartVNDValues
                                        },
                                        ]
                                    }}
                                    options={{
                                        responsive: true,
                                        legend: { display: false },
                                        title: {
                                            display: true,
                                            text: t("Sale Report")
                                        },
                                        // scales: {
                                        //     xAxes: [{
                                        //         stacked: true
                                        //     }],
                                        //     yAxes: [{
                                        //         stacked: true
                                        //     }]
                                        // }
                                    }}
                                />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="style-bar">
                                <div className="style-bar-chart">
                                <Bar
                                    data={{
                                    labels: revenueLabels,
                                    datasets: [
                                        {
                                            label: t('Revenue (USD)'),
                                            backgroundColor: "rgba(240,197,65,.6)",
                                            borderColor: "rgba(240,197,65,.6)",
                                            borderWidth: 1,
                                            stack: 1,
                                            //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                                            //hoverBorderColor: 'rgba(255,99,132,1)',
                                            data: pieChartUSDValues
                                        },
                                        ]
                                    }}
                                    options={{
                                        responsive: true,
                                        legend: { display: false },
                                        title: {
                                            display: true,
                                            text: t("Sale Report")
                                        },
                                        // scales: {
                                        //     xAxes: [{
                                        //         stacked: true
                                        //     }],
                                        //     yAxes: [{
                                        //         stacked: true
                                        //     }]
                                        // }
                                    }}
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className='margin-bottom-50'></hr>
                    <div className='row margin-bottom-50'>
                        <div className='col-md-12'>
                            <h2 className='text-center'>{t('Company Summary Chart')}</h2>
                            <p className='text-center'> <strong>{t('Revenue Total (VND)')}:</strong> {currencyFormatter().format(companyRevenueVND)}, <strong>{t('Revenue Total (USD)')}:</strong> {currencyFormatter('USD').format(companyRevenueUSD)}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="style-bar">
                                <div className="style-bar-chart">
                                    <Bar
                                        data={{
                                        labels: companyLabels,
                                        datasets: [
                                            {
                                                label: t('Booked Rooms'),
                                                backgroundColor: "rgba(240,197,65,.6)",
                                                borderColor: "rgba(240,197,65,.6)",
                                                borderWidth: 1,
                                                stack: 1,
                                                //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                                                //hoverBorderColor: 'rgba(255,99,132,1)',
                                                data: companyRooms
                                            }
                                            ]
                                        }}
                                        options={{
                                            responsive: true,
                                            legend: { display: false },
                                            title: {
                                                display: true,
                                                text: t("Company Room Report")
                                            },
                                            // scales: {
                                            //     xAxes: [{
                                            //         stacked: true
                                            //     }],
                                            //     yAxes: [{
                                            //         stacked: true
                                            //     }]
                                            // }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h3 className='text-center'>{t('Total Room')} : {companyRoom} / {companyroomTotalInMonth} ({companyroomTotalInMonth? numberFormatter(2).format(companyRoom * 100/companyroomTotalInMonth): 0}%)</h3>
                            <div className="pie-charts mt-2">
                                <div className="pie-charts-doughnut">
                                    <Doughnut
                                        data={{
                                        labels: companyPieChartLabels,
                                        datasets: [
                                            {
                                                label: t("Population (millions)"),
                                                backgroundColor: [
                                                    '#36A2F5', '#34BFA3', '#FF518A', '#E07A10', '#631D7F', '#A426B399', '#F3236699', '#081AF1', '#129A3C', '#102F75', '#e2ec52', '#67655F99'
                                                ],
                                                data: companyPieChartValues
                                            }
                                        ]
                                        }}
                                        option={{
                                            title: {
                                                display: true,
                                                text: t("Total Room")
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className='margin-bottom-50'></hr>
                    <div className='row'>
                        <div className="col-md-6">
                            <h3 className='text-center'>{t('Revenue Chart (VND)')}</h3>
                            <div className="pie-charts mt-2">
                                <div className="pie-charts-doughnut">
                                    <Doughnut
                                        data={{
                                        labels: companyRevenueVNDLabels,
                                        datasets: [
                                            {
                                                label: t("Population (millions)"),
                                                backgroundColor: [
                                                    '#36A2F5', '#34BFA3', '#FF518A', '#E07A10', '#631D7F', '#A426B399', '#F3236699', '#081AF1', '#129A3C', '#102F75', '#e2ec52', '#67655F99'
                                                ],
                                                data: cPieChartRevenuesVND
                                            }
                                        ]
                                        }}
                                        option={{
                                            title: {
                                                display: true,
                                                text: t("Revenue")
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h3 className='text-center'>{t('Revenue Chart (USD)')}</h3>
                            <div className="pie-charts mt-2">
                                <div className="pie-charts-doughnut">
                                    <Doughnut
                                        data={{
                                        labels: companyRevenueUSDLabels,
                                        datasets: [
                                            {
                                                label: t("Population (millions)"),
                                                backgroundColor: [
                                                    '#36A2F5', '#34BFA3', '#FF518A', '#E07A10', '#631D7F', '#A426B399', '#F3236699', '#081AF1', '#129A3C', '#102F75', '#e2ec52', '#67655F99'
                                                ],
                                                data: cPieChartRevenuesUSD
                                            }
                                        ]
                                        }}
                                        option={{
                                            title: {
                                                display: true,
                                                text: t("Revenue")
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default RoomChart
