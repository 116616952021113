import { Fragment, useContext, useState, useEffect } from 'react';
import { GuideContext } from '../../../contexts/GuideContext';
import {Modal, Button, Form} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const UpdateGuideModal = () => {
    const { t } = useTranslation();
    // Context
    const {
        guideState: {guide},
        showUpdateGuideModal, 
        setShowUpdateGuideModal, 
        updateGuide, 
        setShowToast
    } = useContext(GuideContext)

    // State
    const [updatedGuide, setUpdatedGuide] = useState(guide)
    useEffect( () => setUpdatedGuide(guide), [guide] )
    const { 
        id, 
        title,
        name,
        email,
        phone,
        address,
        description,
        daily_salary,
        service_type
    } = updatedGuide

    const onChangeNewGuide = event => setUpdatedGuide( {...updatedGuide, [event.target.name]: event.target.value } )
    const onChangeNewGuideNumber = event => setUpdatedGuide( {...updatedGuide, [event.target.name]: Number(event.target.value) } )

    

    const onSubmit = async event => {
        event.preventDefault()
        const response = await updateGuide(updatedGuide)
        if (response.status === 200) {
            setShowUpdateGuideModal(false)
            setShowToast({show: true, message: t('Updated successful!'), type: 'success'})
            return
        }
        
        setShowToast({show: true, message: response.error.ErrorCode, type: 'danger'})
    }

    const closeDialog = () => {
        setUpdatedGuide(guide)
        setShowUpdateGuideModal(false)
    }

    return (
        <Fragment>
            <Modal show={showUpdateGuideModal} onHide={closeDialog}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t('Update guide/driver')}
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-3">
                                <input type="hidden" name="id" value={id} />
                                <div className="form-group">
                                    <label><strong>{t('Title')}</strong></label>
                                    <select name="title" value={title} className="SlectBox form-control" required aria-describedby='title-help' onChange={onChangeNewGuide}>
                                        <option label={t("Choose Title")}></option>
                                        <option value="Mr">Mr</option>
                                        <option value="Mrs">Mrs</option>
                                        <option value="Ms">Ms</option>
                                        <option value="Dr">Dr</option>
                                    </select>
                                    <Form.Text id='title-help' muted>{t('Required')}</Form.Text>
                                    
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="form-group">
                                    <label><strong>{t('Name')}</strong></label>
                                    <input type="text" className="form-control" id="nameCruise" name="name" value={name} placeholder={t("Full Name")} required aria-describedby='name-help' onChange={onChangeNewGuide} />
                                    <Form.Text id='name-help' muted>{t('Required')}</Form.Text>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group">
                                    <label><strong>{t('Phone Number')}</strong></label>
                                    <input type="text" className="form-control" id="phone" name="phone" value={phone} placeholder={t("Number Phone")} required aria-describedby='phone-help' onChange={onChangeNewGuide} />
                                    <Form.Text id='phone-help' muted>{t('Required')}</Form.Text>
                                    
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <label><strong>{t('Type')}</strong></label>
                                    <select name="service_type" value={service_type} className="SlectBox form-control" required aria-describedby='type-help' onChange={onChangeNewGuide}>
                                        <option label={t("Choose Type")}></option>
                                        <option value="car">{t('Driver')}</option>
                                        <option value="guide">{t('Guide')}</option>
                                    </select>
                                    <Form.Text id='type-help' muted>{t('Required')}</Form.Text>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group">
                                    <label><strong>{t('Email')}</strong></label>
                                    <input type="text" className="form-control" id="email" name="email" value={email} placeholder={t("Enter Email")} onChange={onChangeNewGuide} />
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <label><strong>{t('Daily Salary')}</strong></label>
                                    <input type="text" className="form-control" id="daily_salary" name="daily_salary" value={daily_salary} placeholder={t("No. Money")} onChange={onChangeNewGuideNumber} />
                                </div>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label><strong>{t('Address')}</strong></label>
                                    <input type="text" className="form-control" id="address" name="address" value={address} placeholder={t("Enter Address")} onChange={onChangeNewGuide} />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label><strong>{t('Description')}</strong></label>
                                    <textarea className="form-control" placeholder={t("Description")} rows="3" name='description' defaultValue={description} spellCheck="false" onChange={onChangeNewGuide}></textarea>
                                </div>
                            </div>
                        </div>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> {t('Cancel')}</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> {t('Save')}!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default UpdateGuideModal
