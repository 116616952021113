import { Fragment, useContext, useState } from 'react';
import { BookingContext } from '../../contexts/BookingContext';
import { PaymentContext } from '../../contexts/PaymentContext';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import AlertMessage from '../../components/layout/AlertMessage';

const AddPaymentModal = ({ bookingDetail, paidAmount, balance, netIncome }) => {
	const [alert, setAlert] = useState(null);

	const {
		showAddPaymentModal,
		setShowAddPaymentModal
		//updateBooking,
		//setShowToast
	} = useContext(BookingContext);

	const { addPayment } = useContext(PaymentContext);

	// State
	const [newPayment, setNewPayment] = useState({
		booking_id: '',
		payment_amount: '',
		payment_date: moment().format('YYYY-MM-DD'),
		currency: 'usd',
		currency_rate: '',
		description: ''
	});

	const { payment_amount, payment_date, currency_rate, description } = newPayment;

	const onChangeText = (event) => setNewPayment({ ...newPayment, [event.target.name]: event.target.value });
	const onChangeDate = (event) =>
		setNewPayment({
			...newPayment,
			[event.target.name]: moment(event.target.value).format('YYYY-MM-DD')
		});
	const onChangeNumber = (event) =>
		setNewPayment({
			...newPayment,
			[event.target.name]: Number(event.target.value)
		});

	const moneys = [
		{ value: 'usd', label: 'USD' },
		{ value: 'vnđ', label: 'VNĐ' }
	];

	const handleChangeMoney = (newValue, actionMeta) => {
		const dataMoney = newValue;
		if (dataMoney) {
			setNewPayment({ ...newPayment, currency: dataMoney.value });
		} else {
			setNewPayment({ ...newPayment, currency: '' });
		}
	};

	const onSubmit = async (event) => {
		event.preventDefault();
		newPayment['booking_id'] = bookingDetail.id;

		const response = await addPayment(newPayment);
		if (response.status === 200) {
			setAlert({ type: 'success', message: 'Add Payment Successful!' });
			closeDialog();
			return;
		}

		setAlert({ type: 'danger', message: 'Add Payment Failed!' });
	};

	const closeDialog = () => {
		setNewPayment({
			booking_id: '',
			payment_amount: '',
			payment_date: '',
			currency: 'usd',
			currency_rate: '',
			description: ''
		});
		setShowAddPaymentModal(false);
	};

	return (
		<Fragment>
			<Modal
				show={showAddPaymentModal}
				onHide={closeDialog}
				size='full'
				aria-labelledby='contained-modal-title-vcenter'
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Add Payment for Booking {bookingDetail.new_code}</Modal.Title>
				</Modal.Header>
				<Form onSubmit={onSubmit}>
					<Modal.Body>
						<div className='row'>
							<div className='col-md-6'>
								<article className='message is-info'>
									<div className='message-body'>
										<div className='field is-horizontal'>
											<div className='field-label is-normal'>
												<label className='label'>Booking Code</label>
											</div>
											<div className='field-body'>
												<div className='field'>
													<p className='control'>
														{bookingDetail.new_code ? bookingDetail.new_code : bookingDetail.id}
													</p>
												</div>
											</div>
										</div>
										<div className='field is-horizontal'>
											<div className='field-label is-normal'>
												<label className='label'>Partner</label>
											</div>
											<div className='field-body'>
												<div className='field'>
													<p className='control'>
														<Link to={`/manage/partners/${bookingDetail.partner_id}`} target='_blank'>
															{bookingDetail.partner_name}
														</Link>
													</p>
												</div>
											</div>
										</div>
										<div className='field is-horizontal'>
											<div className='field-label is-normal'>
												<label className='label'>Partner Code</label>
											</div>
											<div className='field-body'>
												<div className='field'>
													<p className='control'>{bookingDetail.booking_code}</p>
												</div>
											</div>
										</div>
										<div className='field is-horizontal'>
											<div className='field-label is-normal'>
												<label className='label'>Cruise</label>
											</div>
											<div className='field-body'>
												<div className='field'>
													<p className='control'>{bookingDetail.cruise_name}</p>
												</div>
											</div>
										</div>
										<div className='field is-horizontal'>
											<div className='field-label is-normal'>
												<label className='label'>Itinerary</label>
											</div>
											<div className='field-body'>
												<div className='field'>
													<p className='control'>
														<strong>{bookingDetail.itinerary_name}</strong>
													</p>
												</div>
											</div>
										</div>
										<div className='field is-horizontal'>
											<div className='field-label is-normal'>
												<label className='label'>Departure</label>
											</div>
											<div className='field-body'>
												<div className='field'>
													<p className='control'>
														<strong>{moment(bookingDetail.start_date).format('MMMM D, YYYY')}</strong>
													</p>
												</div>
											</div>
										</div>
										<div className='field is-horizontal'>
											<div className='field-label is-normal'>
												<label className='label'>Return</label>
											</div>
											<div className='field-body'>
												<div className='field'>
													<p className='control'>
														<strong>{moment(bookingDetail.end_date).format('MMMM D, YYYY')}</strong>
													</p>
												</div>
											</div>
										</div>
										<div className='field is-horizontal'>
											<div className='field-label is-normal'>
												<label className='label'>Group size</label>
											</div>
											<div className='field-body'>
												<div className='field'>
													<p className='control'>
														<strong>
															{bookingDetail.adults + ' Adult'}{' '}
															{bookingDetail.children > 0 ? ' - ' + bookingDetail.children + ' Children (5 - 7)' : ''}{' '}
															{bookingDetail.children11 > 0
																? ' - ' + bookingDetail.children11 + ' Children (8-11)'
																: ''}{' '}
															{bookingDetail.infants > 0 ? ' - ' + bookingDetail.infants + ' Infant' : ''}
														</strong>
													</p>
												</div>
											</div>
										</div>
										<div className='field is-horizontal'>
											<div className='field-label is-normal'>
												<label className='label'>Status</label>
											</div>
											<div className='field-body'>
												<div className='field'>
													<p className='control'>
														<strong>{bookingDetail.status}</strong>
													</p>
												</div>
											</div>
										</div>

										{bookingDetail.status === 'Pending' ? (
											<Fragment>
												<div className='field is-horizontal'>
													<div className='field-label is-normal'>
														<label className='label'>Hold until</label>
													</div>
													<div className='field-body'>
														<div className='field'>
															<p className='control'>
																<strong>{moment(bookingDetail.hold_date).format('MMMM D, YYYY')}</strong>
															</p>
														</div>
													</div>
												</div>
												<div className='field is-horizontal'>
													<div className='field-label is-normal'>
														<label className='label'>Select time</label>
													</div>
													<div className='field-body'>
														<div className='field'>
															<p className='control'>
																<strong>{bookingDetail.hold_time}</strong>
															</p>
														</div>
													</div>
												</div>
												<div className='field is-horizontal'>
													<div className='field-label is-normal'>
														<label className='label'>Message</label>
													</div>
													<div className='field-body'>
														<div className='field'>
															<p className='control'>{bookingDetail.message}</p>
														</div>
													</div>
												</div>
											</Fragment>
										) : (
											''
										)}

										<div className='field is-horizontal'>
											<div className='field-label is-normal'>
												<label className='label'>Assign To</label>
											</div>
											<div className='field-body'>
												<div className='field'>
													<p className='control'>{bookingDetail.assign_name}</p>
												</div>
											</div>
										</div>
									</div>
								</article>
							</div>
							<div className='col-md-6'>
								<article className='message is-info'>
									<div className='message-body'>
										{bookingDetail.duration > 1 ? (
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>Cabin</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															<strong>{bookingDetail.cabin_desc}</strong>
														</p>
													</div>
												</div>
											</div>
										) : (
											''
										)}
										<div className='field is-horizontal'>
											<div className='field-label is-normal'>
												<label className='label'>Pick-up/Drop-off</label>
											</div>
											<div className='field-body'>
												<div
													className='field'
													dangerouslySetInnerHTML={{
														__html: bookingDetail.pickup
													}}
												></div>
											</div>
										</div>
										<div className='field is-horizontal'>
											<div className='field-label is-normal'>
												<label className='label'>Sales notes</label>
											</div>
											<div className='field-body'>
												<div className='field'>
													<p
														className='control'
														dangerouslySetInnerHTML={{
															__html: bookingDetail.sale_note
														}}
													></p>
												</div>
											</div>
										</div>
										<div className='field is-horizontal'>
											<div className='field-label is-normal'>
												<label className='label'>Notes Accounting</label>
											</div>
											<div className='field-body'>
												<div className='field'>
													<p
														className='control'
														dangerouslySetInnerHTML={{
															__html: bookingDetail.acc_note
														}}
													></p>
												</div>
											</div>
										</div>
										<div className='field is-horizontal'>
											<div className='field-label is-normal'>
												<label className='label'>Operation Notes</label>
											</div>
											<div className='field-body'>
												<div className='field'>
													<p
														className='control'
														dangerouslySetInnerHTML={{
															__html: bookingDetail.opt_note
														}}
													></p>
												</div>
											</div>
										</div>

										<div className='field is-horizontal'>
											<div className='field-label is-normal'>
												<label className='label'>Create By</label>
											</div>
											<div className='field-body'>
												<div className='field'>
													<p className='control'>{bookingDetail.created_by_name}</p>
												</div>
											</div>
										</div>
										<div className='field is-horizontal'>
											<div className='field-label is-normal'>
												<label className='label'>Create Date</label>
											</div>
											<div className='field-body'>
												<div className='field'>
													<p className='control'>{moment(bookingDetail.created_at).format('MMMM D, YYYY')}</p>
												</div>
											</div>
										</div>
									</div>
								</article>
							</div>
						</div>
						<div className='row'>
							<div className='col-md-6'></div>
							<div className='col-md-6'>
								<article className='message is-danger'>
									<div className='message-body'>
										<div className='field is-horizontal'>
											<div className='field-label is-normal'>
												<label className='label'>Total</label>
											</div>
											<div className='field-body'>
												<div className='field'>
													<p className='control'>
														{bookingDetail.money_type === 'usd' ? (
															<NumberFormat
																value={bookingDetail.total}
																displayType={'text'}
																thousandSeparator='.'
																decimalSeparator=','
																decimalScale={2}
																prefix={'USD '}
															/>
														) : (
															//bookingDetail.total + ' USD'
															<NumberFormat
																value={bookingDetail.total}
																displayType={'text'}
																thousandSeparator='.'
																decimalSeparator=','
																decimalScale={0}
																prefix={'VNĐ '}
															/>
														)}
													</p>
												</div>
											</div>
										</div>

										{bookingDetail.discount > 0 ? (
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>Discount</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															{bookingDetail.money_type === 'usd'
																? `USD ${bookingDetail.discount}`
																: `VNĐ ${bookingDetail.discount}`}
														</p>
														<p className='control'>{bookingDetail.discountNote}</p>
													</div>
												</div>
											</div>
										) : null}
										<div className='field is-horizontal'>
											<div className='field-label is-normal'>
												<label className='label'>Paid Amount</label>
											</div>
											<div className='field-body'>
												<div className='field'>
													<p className='control'>
														{bookingDetail.money_type === 'usd' ? (
															<NumberFormat
																value={paidAmount}
																displayType={'text'}
																thousandSeparator='.'
																decimalSeparator=','
																decimalScale={2}
																prefix={'USD '}
															/>
														) : (
															<NumberFormat
																value={paidAmount}
																displayType={'text'}
																thousandSeparator='.'
																decimalSeparator=','
																decimalScale={0}
																prefix={'VNĐ '}
															/>
														)}
													</p>
												</div>
											</div>
										</div>

										<div className='field is-horizontal'>
											<div className='field-label is-normal'>
												<label className='label'>Balance</label>
											</div>
											<div className='field-body'>
												<div className='field'>
													<p className='control'>
														{bookingDetail.money_type === 'usd' ? (
															<NumberFormat
																value={balance}
																displayType={'text'}
																thousandSeparator='.'
																decimalSeparator=','
																decimalScale={2}
																prefix={'USD '}
															/>
														) : (
															<NumberFormat
																value={balance}
																displayType={'text'}
																thousandSeparator='.'
																decimalSeparator=','
																decimalScale={0}
																prefix={'VNĐ '}
															/>
														)}
													</p>
												</div>
											</div>
										</div>

										<div className='field is-horizontal'>
											<div className='field-label is-normal'>
												<label className='label'>Net Income</label>
											</div>
											<div className='field-body'>
												<div className='field'>
													<p className='control'>
														{bookingDetail.money_type === 'usd' ? (
															<NumberFormat
																value={netIncome}
																displayType={'text'}
																thousandSeparator='.'
																decimalSeparator=','
																decimalScale={2}
																prefix={'USD '}
															/>
														) : (
															<NumberFormat
																value={netIncome}
																displayType={'text'}
																thousandSeparator='.'
																decimalSeparator=','
																decimalScale={0}
																prefix={'VNĐ '}
															/>
														)}
													</p>
												</div>
											</div>
										</div>
										{bookingDetail.vat ? (
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>VAT</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>{bookingDetail.vat}%</p>
													</div>
												</div>
											</div>
										) : null}
									</div>
								</article>
							</div>
						</div>
						<div className='row mt-3'>
							<div className='col-md-12'>
								<AlertMessage info={alert} />
							</div>
						</div>
						<div className='row mt-3'>
							<div className='col-md-3'>
								<div className='form-group'>
									<label>
										<strong>Payment Amount</strong>
									</label>
									<input
										type='number'
										className='form-control'
										id='payment_amount'
										name='payment_amount'
										value={payment_amount}
										onChange={onChangeNumber}
										placeholder='No. Price'
									/>
								</div>
							</div>
							<div className='col-md-3'>
								<div className='form-group'>
									<label>
										<strong>Payment Date</strong>
									</label>
									<input
										type='date'
										className='form-control'
										name='payment_date'
										id='payment_date'
										value={payment_date}
										onChange={onChangeDate}
									/>
								</div>
							</div>
							<div className='col-md-3'>
								<div className='form-group'>
									<label>
										<strong>Currency Rate</strong>
									</label>
									<input
										type='number'
										className='form-control'
										name='currency_rate'
										id='currency_rate'
										value={currency_rate}
										onChange={onChangeNumber}
										placeholder='No. Currency Rate'
									/>
								</div>
							</div>
							<div className='col-md-3'>
								<div className='form-group'>
									<label>
										<strong>Money Type</strong>
									</label>
									<Select
										isClearable
										name='money_type'
										onChange={handleChangeMoney.bind(this)}
										options={moneys}
										placeholder='Money Type'
									/>
								</div>
							</div>
						</div>
						<div className='row mt-3'>
							<div className='col-md-12'>
								<div className='form-group'>
									<label>
										<strong>Description</strong>
									</label>
									<textarea
										className='form-control'
										placeholder='Description'
										rows='5'
										name='description'
										defaultValue={description}
										spellCheck='false'
										onChange={onChangeText}
									></textarea>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button className='btn btn-danger btn-with-icon' variant='secondary' onClick={closeDialog}>
							<i className='fe fe-x-circle'></i> Cancel
						</Button>
						<Button className='btn btn-primary btn-with-icon' variant='primary' type='submit'>
							<i className='fe fe-save'></i> Save!!!
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</Fragment>
	);
};

export default AddPaymentModal;
