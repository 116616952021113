import moment from 'moment';
import React from 'react';
import Button from 'react-bootstrap/Button';
import { handleExportRevenue } from './HandleExportRevenue';

const createDownLoadData = ({ fromDate, toDate, data }) => {
	const fromDateF = moment(fromDate).format('DD/MM/YYYY');
	const toDateF = moment(toDate).format('DD/MM/YYYY');
	handleExportRevenue(data).then((url) => {
		const downloadAnchorNode = document.createElement('a');
		downloadAnchorNode.setAttribute('href', url);
		downloadAnchorNode.setAttribute('download', `Revenue-from-${fromDateF}-to-${toDateF}.xlsx`);
		downloadAnchorNode.click();
		downloadAnchorNode.remove();
	});
	return;
};

export const ExportRevenueXLSX = ({ btnLabel, fromDate, toDate, classes, data }) => {
	return (
		<Button
			className={`btn btn-primary float-right ${classes}`}
			// variant='warning'
			onClick={() => {
				createDownLoadData({
					fromDate,
					toDate,
					data
				});
			}}
		>
			{btnLabel}
		</Button>
	);
};
