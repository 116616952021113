import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

const RevenueMoney = (props) => {
	const { t } = useTranslation();
	let usdTotal = 0;
	let vndTotal = 0;
	let usdCommission = 0;
	let vndCommission = 0;
	let usdDiscount = 0;
	let vndDiscount = 0;
	let usdPaid = 0;
	let vndPaid = 0;
	let usdNetRevenue = 0;
	let vndNetRevenue = 0;
	let totalAdults = 0;
	let totalChildren = 0;
	let totalInfant = 0;
	let usdRemain = 0;
	let vndRemain = 0;
	const bookings = props.bookingsProps;

	bookings.forEach((booking) => {
		if (booking.money_type === 'usd') {
			usdTotal += booking.total ? booking.total : 0;
		} else {
			vndTotal += booking.total ? booking.total : 0;
		}

		if (booking.money_type === 'usd') {
			usdCommission += booking.com_year ? booking.com_year : 0;
		} else {
			vndCommission += booking.com_year ? booking.com_year : 0;
		}

		if (booking.money_type === 'usd') {
			usdPaid += booking.total_payment ? booking.total_payment : 0;
		} else {
			vndPaid += booking.total_payment ? booking.total_payment : 0;
		}
		if (booking.money_type === 'usd') {
			usdDiscount += booking.discount ? booking.discount : 0;
		} else {
			vndDiscount += booking.discount ? booking.discount : 0;
		}

		totalAdults += booking.adults;

		if (booking.children) {
			totalChildren += booking.children;
		}
		if (booking.children11) {
			totalChildren += booking.children11;
		}

		if (booking.infants) {
			totalInfant += booking.infants;
		}
	});

	usdNetRevenue = usdTotal - usdCommission;
	vndNetRevenue = vndTotal - vndCommission;
	//$totalAllDo - $totalDisDo - $totalPaidDo
	usdRemain = usdTotal - usdPaid;
	vndRemain = vndTotal - vndPaid;

	return (
		<Fragment>
			<div className='row'>
				<div className='col-sm'>
					<div className='field is-horizontal'>
						<div className='field-label is-normal'>
							<label className='label'>{t('Total Revenue')} (USD)</label>
						</div>
						<div className='field-body'>
							<div className='field'>
								<p className='control'>
									<strong>
										<NumberFormat
											value={usdTotal}
											displayType={'text'}
											thousandSeparator='.'
											decimalSeparator=','
											decimalScale={2}
										/>
									</strong>{' '}
									{/*prefix={'USD '} */}
								</p>
							</div>
						</div>
					</div>
					<div className='field is-horizontal'>
						<div className='field-label is-normal'>
							<label className='label'>{t('Total Revenue')} (VNĐ)</label>
						</div>
						<div className='field-body'>
							<div className='field'>
								<p className='control'>
									<strong>
										<NumberFormat
											value={vndTotal}
											displayType={'text'}
											thousandSeparator='.'
											decimalSeparator=','
											decimalScale={0}
										/>
									</strong>
								</p>
							</div>
						</div>
					</div>
					<div className='field is-horizontal'>
						<div className='field-label is-normal'>
							<label className='label'>{t('Total Commission')} (USD)</label>
						</div>
						<div className='field-body'>
							<div className='field'>
								<p className='control'>
									<strong>
										<NumberFormat
											value={usdCommission}
											displayType={'text'}
											thousandSeparator='.'
											decimalSeparator=','
											decimalScale={2}
										/>
									</strong>
								</p>
							</div>
						</div>
					</div>
					<div className='field is-horizontal'>
						<div className='field-label is-normal'>
							<label className='label'>{t('Total Commission')} (VNĐ)</label>
						</div>
						<div className='field-body'>
							<div className='field'>
								<p className='control'>
									<strong>
										<NumberFormat
											value={vndCommission}
											displayType={'text'}
											thousandSeparator='.'
											decimalSeparator=','
											decimalScale={0}
										/>
									</strong>
								</p>
							</div>
						</div>
					</div>
					<div className='field is-horizontal'>
						<div className='field-label is-normal'>
							<label className='label'>{t('Total Discount')} (USD)</label>
						</div>
						<div className='field-body'>
							<div className='field'>
								<p className='control'>
									<strong>
										<NumberFormat
											value={Number(usdDiscount)}
											displayType={'text'}
											thousandSeparator='.'
											decimalSeparator=','
											decimalScale={2}
										/>
									</strong>
								</p>
							</div>
						</div>
					</div>
					<div className='field is-horizontal'>
						<div className='field-label is-normal'>
							<label className='label'>{t('Total Discount')} (VNĐ)</label>
						</div>
						<div className='field-body'>
							<div className='field'>
								<p className='control'>
									<strong>
										<NumberFormat
											value={vndDiscount}
											displayType={'text'}
											thousandSeparator='.'
											decimalSeparator=','
											decimalScale={0}
										/>
									</strong>
								</p>
							</div>
						</div>
					</div>
					<div className='field is-horizontal'>
						<div className='field-label is-normal'>
							<label className='label'>{t('Net Revenue')} (USD)</label>
						</div>
						<div className='field-body'>
							<div className='field'>
								<p className='control'>
									<strong>
										<NumberFormat
											value={usdNetRevenue}
											displayType={'text'}
											thousandSeparator='.'
											decimalSeparator=','
											decimalScale={2}
										/>
									</strong>
								</p>
							</div>
						</div>
					</div>
					<div className='field is-horizontal'>
						<div className='field-label is-normal'>
							<label className='label'>{t('Net Revenue')} (VNĐ)</label>
						</div>
						<div className='field-body'>
							<div className='field'>
								<p className='control'>
									<strong>
										<NumberFormat
											value={vndNetRevenue}
											displayType={'text'}
											thousandSeparator='.'
											decimalSeparator=','
											decimalScale={0}
										/>
									</strong>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='col-sm'>
					<div className='field is-horizontal'>
						<div className='field-label is-normal'>
							<label className='label'>{t('Total Adults')}</label>
						</div>
						<div className='field-body'>
							<div className='field'>
								<p className='control'>
									<strong>
										<NumberFormat
											value={totalAdults}
											displayType={'text'}
											thousandSeparator='.'
											decimalSeparator=','
											decimalScale={0}
										/>
									</strong>
								</p>
							</div>
						</div>
					</div>
					<div className='field is-horizontal'>
						<div className='field-label is-normal'>
							<label className='label'>{t('Total Children')}</label>
						</div>
						<div className='field-body'>
							<div className='field'>
								<p className='control'>
									<strong>
										<NumberFormat
											value={totalChildren}
											displayType={'text'}
											thousandSeparator='.'
											decimalSeparator=','
											decimalScale={0}
										/>
									</strong>
								</p>
							</div>
						</div>
					</div>
					<div className='field is-horizontal'>
						<div className='field-label is-normal'>
							<label className='label'>{t('Total Infant')}</label>
						</div>
						<div className='field-body'>
							<div className='field'>
								<p className='control'>
									<strong>
										<NumberFormat
											value={totalInfant}
											displayType={'text'}
											thousandSeparator='.'
											decimalSeparator=','
											decimalScale={0}
										/>
									</strong>
								</p>
							</div>
						</div>
					</div>
					<hr />
					<div className='field is-horizontal'>
						<div className='field-label is-normal'>
							<label className='label'>{t('Paid')} (USD)</label>
						</div>
						<div className='field-body'>
							<div className='field'>
								<p className='control'>
									<strong>
										<NumberFormat
											value={usdPaid}
											displayType={'text'}
											thousandSeparator='.'
											decimalSeparator=','
											decimalScale={2}
										/>
									</strong>
								</p>
							</div>
						</div>
					</div>
					<div className='field is-horizontal'>
						<div className='field-label is-normal'>
							<label className='label'>{t('Paid')} (VNĐ)</label>
						</div>
						<div className='field-body'>
							<div className='field'>
								<p className='control'>
									<strong>
										<NumberFormat
											value={vndPaid}
											displayType={'text'}
											thousandSeparator='.'
											decimalSeparator=','
											decimalScale={0}
										/>
									</strong>
								</p>
							</div>
						</div>
					</div>
					<div className='field is-horizontal'>
						<div className='field-label is-normal'>
							<label className='label'>{t('Remain')} (USD)</label>
						</div>
						<div className='field-body'>
							<div className='field'>
								<p className='control'>
									<strong>
										<NumberFormat
											value={usdRemain}
											displayType={'text'}
											thousandSeparator='.'
											decimalSeparator=','
											decimalScale={2}
										/>
									</strong>
								</p>
							</div>
						</div>
					</div>
					<div className='field is-horizontal'>
						<div className='field-label is-normal'>
							<label className='label'>{t('Remain')} (VNĐ)</label>
						</div>
						<div className='field-body'>
							<div className='field'>
								<p className='control'>
									<strong>
										<NumberFormat
											value={vndRemain}
											displayType={'text'}
											thousandSeparator='.'
											decimalSeparator=','
											decimalScale={0}
										/>
									</strong>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default RevenueMoney;
