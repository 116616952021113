import { Fragment, useContext, useEffect } from 'react';
import { RoomContext } from '../../../contexts/RoomContext';
import TableNightCruise from './TableNightCruise';

const ListBooking = (props) => {
	const setAddBookingDefaultData = props.setAddBookingDefaultData;
	const cruise = props.cruiseProps;
	const month = props.monthProps;
	const year = props.yearProps;
	const cancelledLabel = props.cancelledLabel;

	const {
		roomState: { rooms },
		getRooms,
	} = useContext(RoomContext);

	let body = null;
	let idCruise = 0;

	if (cruise === null) {
	} else {
		idCruise = cruise.id;
	}

	// Start: Get all rooms , []
	useEffect(() => {
		if (idCruise) {
			getRooms(`?cruise_id=${idCruise}`);
		}
	}, [idCruise, cruise]); // eslint-disable-line react-hooks/exhaustive-deps

	if (cruise) {
		body = (
			<Fragment>
				<div className='ajax-booking-cruise'>
					<div className='row'>
						<div className='col-xl-12'>
							<div className='table-info-cruise list-booking mg-b-20'>
								<div className='table-info-cruise-header pb-0'>
									<div className='d-flex justify-content-between'>
										<h3 className='table-info-cruise-title mg-b-15'>{cruise.name}</h3>
									</div>
								</div>
								<div className='table-info-cruise-body'>
									<div className='table-responsive'>
										<TableNightCruise
											cruiseProps={cruise}
											roomsProps={rooms}
											monthProps={month}
											yearProps={year}
											setAddBookingDefaultData={setAddBookingDefaultData}
											cancelledLabel={cancelledLabel}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	return <Fragment>{body}</Fragment>;
};

export default ListBooking;
