import { Fragment, useContext, useState, useEffect, useRef } from 'react';
import { BookingContext } from '../../../contexts/BookingContext';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import Select from 'react-select';
import { CRUISE_TYPES, apiUrl } from '../../../contexts/constants';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { PartnerContext } from '../../../contexts/PartnerContext';
import { RoomContext } from '../../../contexts/RoomContext';
import { CountryContext } from '../../../contexts/CountryContext';
import InfoUpdatedPrice from './subAdds/InfoUpdatedPrice';
import ItemInforUpdatedCustomer from './subAdds/ItemInforUpdatedCustomer';
import { getAPI } from '../../../utils/api';
import { TransportationPickUpContext } from '../../../contexts/TransportationPickUpContext';
import { TransportationRouteContext } from '../../../contexts/TransportationRouteContext';
import { TransportationTypeContext } from '../../../contexts/TransportationTypeContext';
import { convertStringToDateISO, getCruiseTripType, isSpecialDate } from '../../../utils/common';
import { CruiseSettingContext } from '../../../contexts/CruiseSettingContext';
import { useTranslation } from 'react-i18next';

let isFirstLoadingPrice = true;
let loadingIndex = 0;

const UpdateBookingModal = (props) => {
	const { t } = useTranslation();
	const setDataBookingDetail = props.setDataBookingDetail;

	const booking = props.bookingProps;
	const cruises = props.cruises;
	const [tripType, setTripType] = useState(props.tripType);
	const isCopy = props.isCopy;
	const setCustomersData = props.setCustomersData;
	// Context
	const { showUpdateBookingModal, setShowUpdateBookingModal, updateBooking, addBooking, setShowToast } =
		useContext(BookingContext);

	const {
		countryState: { countries }
	} = useContext(CountryContext);

	const {
		cruiseSettingState: { cruiseSettings },
		getCruiseSettings
	} = useContext(CruiseSettingContext);

	useEffect(() => {
		setTripType(props.tripType);
	}, [props.tripType]);

	useEffect(() => {
		if (cruiseSettings && cruiseSettings.length > 0) return;
		getCruiseSettings('');
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const [updatedBooking, setUpdatedBooking] = useState(booking);
	useEffect(() => {
		setUpdatedBooking(booking);
		setDataPrice({
			...dataPrice,
			adult_price: booking.adult_price,
			child_price: booking.child_price,
			child_price_2: booking.child_price2,
			infant_price: booking.infant_price,
			single_price: booking.single_price,
			money_type: booking.money_type
		});
	}, [booking]);

	const [customers, setCustomers] = useState(props.customers ? props.customers.slice() : []);
	useEffect(() => {
		setCustomers(props.customers ? props.customers.slice() : []);
	}, [props.customers]);

	const {
		id,
		booking_code,
		start_date,
		hold_date,
		hold_time,
		message,
		adults,
		children,
		children11,
		infants,
		cruise_itinerary_id,
		sale_note,
		pickup,
		acc_note,
		opt_note,
		vat,
		cabin_desc,
		status,
		money_type,
		total,
		assign_to,
		cruise_id,
		partner_id,
		transportation_amount,
		time_for_deposite,
		time_for_payment
	} = updatedBooking;

	const onChangeText = (event) =>
		setUpdatedBooking({
			...updatedBooking,
			[event.target.name]: event.target.value
		});
	const onChangeNumber = (event) =>
		setUpdatedBooking({
			...updatedBooking,
			[event.target.name]: Number(event.target.value)
		});

	const [availableRooms, setAvailableRooms] = useState(null);
	const [roomOptions, setRoomOptions] = useState([]);
	const [arrGroupPrice, setArrGroupPrice] = useState('');

	let checkCustomer = false;

	if (customers) {
		checkCustomer = true;
	}

	const [statusCustomer, setStatusCustomer] = useState(checkCustomer);

	useEffect(() => {
		async function getAvailableRooms() {
			try {
				if (cruise_id > 0 && start_date && cruise_itinerary_id) {
					const response = await getAPI(
						`${apiUrl}/rooms/available`,
						`start_date=${start_date}&cruise_id=${cruise_id}&cruise_itinerary_id=${cruise_itinerary_id}`
					);

					if (response.data) {
						setAvailableRooms(
							response.data.map((room) => {
								return {
									value: room.id,
									label: room.name,
									roomTypeID: room.room_type_id
								};
							})
						);
					}
				}
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		getAvailableRooms();
	}, [cruise_id, start_date, cruise_itinerary_id]); // eslint-disable-line react-hooks/exhaustive-deps

	const {
		roomState: { rooms },
		getRooms
	} = useContext(RoomContext);

	if (!rooms && cruise_id) {
		getRooms(`?cruise_id=${cruise_id}`);
	}
	useEffect(() => {
		if (cruise_id) {
			getRooms(`?cruise_id=${cruise_id}`);
		}
	}, [cruise_id]); // eslint-disable-line react-hooks/exhaustive-deps

	const {
		partnerState: { partners }
	} = useContext(PartnerContext);

	const [cruiseSetting, setCruiseSetting] = useState({});

	useEffect(() => {
		const cruiseSetting = cruiseSettings? cruiseSettings.find((item) => {
			return item.cruise_id == updatedBooking.cruise_id;
		}): {}
		setCruiseSetting(cruiseSetting);
	}, [updatedBooking.cruise_id]);

	// khai bao
	const [defaultPriceService, setDefaultPriceService] = useState([]);
	const [assignTo, setAssignTo] = useState(0);
	const [assignName, setAssignName] = useState(booking.assign_name);
	const [pending, setPending] = useState(false);
	const [itineraries, setItineraries] = useState([]);
	const [dataPrice, setDataPrice] = useState([]);
	const [chooseDuration, setChooseDuration] = useState(0);
	const [newDuration, setNewDuration] = useState(0);
	const [specialCabins, setSpecialCabins] = useState([]);
	const [totalCustomerService, setTotalCustomerService] = useState(0);
	const [customerTransportAmount, setCustomerTransportAmount] = useState(0);
	const [totalRoomPrice, setTotalRoomPrice] = useState(0);
	const [commissionNote, setCommissionNote] = useState(null);
	const [partnerDescription, setPartnerDescription] = useState(null);

	const [numberCustomers, setNumberCustomers] = useState(0);
	const [roomService, setRoomService] = useState(0);
	const [specialService, setSpecialService] = useState(0);

	const {
		transportationRouteState: { transportationRoutes },
		getTransportationRoutes
	} = useContext(TransportationRouteContext);

	const {
		transportationPickUpState: { transportationPickUps },
		getTransportationPickUps
	} = useContext(TransportationPickUpContext);

	const {
		transportationTypeState: { transportationTypes },
		getTransportationTypes
	} = useContext(TransportationTypeContext);

	useEffect(() => {
		if (!transportationRoutes || !transportationRoutes.length) getTransportationRoutes();
		if (!transportationPickUps || !transportationPickUps.length) getTransportationPickUps();
		if (!transportationTypes || !transportationTypes.length) getTransportationTypes();
	}, []);

	useEffect(() => {
		if (!customers || customers.length === 0) {
			return;
		}
		if (!dataPrice || !dataPrice.single_price) {
			return;
		}

		calculateRoomService();
	}, [customers, dataPrice, totalRoomPrice]);

	const onChangePrice = (event) =>
		setDataPrice({
			...dataPrice,
			[event.target.name]: Number(event.target.value)
		});

	useEffect(() => {
		if (dataPrice) {
			setUpdatedBooking({
				...updatedBooking,
				adult_price: dataPrice.adult_price,
				child_price: dataPrice.child_price,
				child_price2: dataPrice.child_price_2,
				infant_price: dataPrice.infant_price,
				single_price: dataPrice.single_price,
				money_type: dataPrice.money_type? dataPrice.money_type: updatedBooking.money_type
			});
		}
	}, [dataPrice]);

	useEffect(() => {
		const sService = specialService | 0;
		const rService = roomService | 0;
		setTotalCustomerService(sService + rService);
	}, [roomService, specialService]);

	const options = [
		{ value: 'Confirmed', label: t('Confirmed') },
		{ value: 'Pending', label: t('Pending') },
		{ value: 'Canceled', label: t('Canceled') },
		{ value: 'Transferred', label: t('Transferred') },
		{ value: 'Inspection', label: t('Inspection') },
		{ value: 'Charter', label: t('Charter') },
	];

	const genders = [
		{ value: 'male', label: t('Male') },
		{ value: 'female', label: t('Female') }
	];

	const mrCustoms = [
		{ value: 'Mr', label: 'Mr' },
		{ value: 'Mrs', label: 'Mrs' },
		{ value: 'Ms', label: 'Ms' },
		{ value: 'Dr', label: 'Dr' }
	];

	const moneys = [
		{ value: 'usd', label: 'USD' },
		{ value: 'vnd', label: 'VNĐ' }
	];

	let optionItineraries = [];
	const optionPartners = useRef({});
	const optionCountries = useRef({});
	const optionCruises = useRef({});
	const [optionSpecials, setOptionSpecials] = useState([]);

	let numberArray = [];

	// Start Get all cruise , []
	useEffect(() => {
		if (cruise_id) {
			setAssignTo(assign_to);
			getRooms(`?cruise_id=${cruise_id}`);
		}
	}, [cruise_id]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (itineraries.length > 0) {
			itineraries.forEach((itinerary) => {
				if (itinerary.id === cruise_itinerary_id) {
					setChooseDuration(itinerary.id);
					setNewDuration(itinerary.duration);
				}
			});
		}
	}, [itineraries]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (customers && customers.length > 0) {
			customers.forEach((itemCustomer) => {
				if (itemCustomer) {
					itemCustomer['start_date'] = start_date;
					itemCustomer['status'] = status;
					itemCustomer['money_type'] = money_type;
					itemCustomer['cruise_itinerary_id'] = cruise_itinerary_id;
					itemCustomer['cruise_id'] = cruise_id;
					itemCustomer['partner_id'] = partner_id;
				}
			});
		}
	}, [partner_id, cruise_id, cruise_itinerary_id, status, start_date, adults]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		async function GroupPriceData() {
			try {
				if (partner_id > 0) {
					const response = await getAPI(`${apiUrl}/partner/${partner_id}`);
					if (response.data.commission_note) {
						setCommissionNote(response.data.commission_note);
					}
					if (response.data.description) {
						setPartnerDescription(response.data.description);
					}
					const arrDataCategories = response.data.price_groups.split(',');
					setArrGroupPrice(arrDataCategories);
				}
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		GroupPriceData();
	}, [partner_id]); // eslint-disable-line react-hooks/exhaustive-deps

	//Cabin Specials
	useEffect(() => {
		async function getSpecialCabins() {
			try {
				if (cruise_id > 0 && chooseDuration > 0) {
					const response = await getAPI(
						`${apiUrl}/cabin-specials`,
						`filterFields=cruise_id,duration&filterFieldValues=${cruise_id},${chooseDuration}`
					);
					setSpecialCabins(response.data);
				}
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		getSpecialCabins();
	}, [cruise_id, chooseDuration]); // eslint-disable-line react-hooks/exhaustive-deps

	// get dataPrice &date=
	useEffect(() => {
		async function getDataPrice() {
			try {
				if (
					cruise_id > 0 &&
					chooseDuration > 0 &&
					start_date !== '' &&
					arrGroupPrice &&
					isFirstLoadingPrice &&
					dataPrice.adult_price <= 0
				) {
					const response = await getAPI(
						`${apiUrl}/prices`,
						`filterFields=cruise_id,itinerary_id&filterFieldValues=${cruise_id},${chooseDuration}&date=${start_date}&price_group=${arrGroupPrice}`
					);
					if (response.data !== null) {
						setDataPrice(response.data);
					}
					isFirstLoadingPrice = false;
				}
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		getDataPrice();
	}, [cruise_id, chooseDuration, start_date, arrGroupPrice]); // eslint-disable-line react-hooks/exhaustive-deps

	const onChangeCheckboxDefaultPrice = async () => {
		if (cruise_id > 0 && chooseDuration > 0 && start_date !== '' && arrGroupPrice) {
			const response = await getAPI(
				`${apiUrl}/prices`,
				`filterFields=cruise_id,itinerary_id&filterFieldValues=${cruise_id},${chooseDuration}&date=${start_date}&price_group=${arrGroupPrice}`
			);
			if (response.data !== null) {
				setDataPrice(response.data);
			}
		}
	};

	//get itineraries
	useEffect(() => {
		async function getDataItineraries() {
			try {
				if (cruise_id > 0) {
					const response = await getAPI(
						`${apiUrl}/cruise-itineraries`,
						`filterFields=cruise_id&filterFieldValues=${cruise_id}`
					);
					setItineraries(response.data);
				}
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		getDataItineraries();
	}, [cruise_id]); // eslint-disable-line react-hooks/exhaustive-deps

	let allRooms = [];

	if (cruise_id) {
		rooms.filter((item) => {
			return item.is_locked !== true;
		}).map(
			(room) =>
				(allRooms = [
					...allRooms,
					{
						value: room.id,
						label: room.name,
						roomTypeID: room.room_type_id
					}
				])
		);
	}

	useEffect(() => {
		let rOptions = [];
		// cruise_id, start_date, cruise_itinerary_id
		if (
			booking.cruise_id === cruise_id &&
			booking.start_date === start_date &&
			booking.cruise_itinerary_id === cruise_itinerary_id
		) {
			const oldRooms = allRooms.filter((r) => {
				return customers.find((c) => {
					return c.room_id === r.value;
				});
			});

			if (oldRooms && oldRooms.length > 0) {
				rOptions.push(...oldRooms);
			}
			if (availableRooms && availableRooms.length > 0) {
				rOptions.push(...availableRooms);
			}
			const opts = rOptions.reduce((unique, o) => {
				if (!unique.some((obj) => obj.label === o.label && obj.value === o.value)) {
					unique.push(o);
				}
				return unique;
			}, []);
			setRoomOptions(opts);

			return;
		}

		if (availableRooms && availableRooms.length > 0) {
			setRoomOptions(availableRooms);
			return;
		}
		setRoomOptions([]);
	}, [customers, rooms, availableRooms, cruise_id, start_date, cruise_itinerary_id]);

	useEffect(() => {
		if (cruises && cruises.length > 0) {
			optionCruises.current = cruises.map((cruise) => {
				return {
					value: cruise.id,
					label: cruise.name,
					typeDay: cruise.typeDay
				};
			});
		}
	}, [cruises]);

	Array(100)
		.fill(1)
		.map(
			(option, index) =>
				(numberArray = [
					...numberArray,
					{
						value: index + 1,
						label: index + 1 + ' Pax'
					}
				])
		);

	useEffect(() => {
		if (partners && partners.length > 0) {
			optionPartners.current = partners.map((partner) => {
				return {
					value: partner.id,
					label: partner.name,
					partnerNoteCommission: partner.commission_note,
					partnerDescription: partner.description,
					dataKey: Number(partner.assign_to)
				};
			});
		}
	}, [partners]);

	useEffect(() => {
		if (countries && countries.length > 0) {
			optionCountries.current = countries.map((country) => {
				return {
					value: country.name,
					label: country.name
				};
			});
		}
	}, [countries]);

	// get name sale
	useEffect(() => {
		async function userData() {
			try {
				if (assignTo) {
					const response = await getAPI(`${apiUrl}/user/${assignTo}`);
					// eslint-disable-next-line react-hooks/exhaustive-deps
					setAssignName(response.data.name);
					setUpdatedBooking({
						...updatedBooking,
						assign_name: response.data.name
					});
				}
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		userData();
	}, [assignTo]); // eslint-disable-line react-hooks/exhaustive-deps

	//itineraries

	if (itineraries) {
		// eslint-disable-next-line array-callback-return
		itineraries.map((itinerary) => {
			optionItineraries = [
				...optionItineraries,
				{
					value: itinerary.id,
					label: itinerary.name,
					valueID: itinerary.id,
					duration: itinerary.duration
				}
			];
		});
	}

	useEffect(() => {
		console.log('specialCabins', specialCabins);
		console.log('updatedBooking.money_type', updatedBooking.money_type);
		if (specialCabins) {
			let speOpts = [];
			specialCabins.forEach((special) => {
				if (special.money_type !== updatedBooking.money_type) {
					return;
				}
				speOpts = [
					...speOpts,
					{
						value: special.id,
						label: special.name,
						price: special.price,
						roomTypes: special.room_types
					}
				];
			});

			setOptionSpecials(speOpts);
		}
	}, [updatedBooking.money_type, specialCabins]);

	useEffect(() => {
		// calculate room service
		let singleRoomCount = 0;
		customers.forEach((itemCustomer) => {
			if (itemCustomer.room_id) {
				const customersInRoom = customers.filter((c) => c.room_id === itemCustomer.room_id);
				if (customersInRoom.length === 1) {
					singleRoomCount += 1;
				}
			}
		});

		setRoomService(singleRoomCount * dataPrice.single_price);
	}, [dataPrice.single_price]);

	useEffect(() => {
		if (customers && customers.length > 0 && optionSpecials.length > 0) {
			let totalSpecial = 0;
			customers.forEach((customer) => {
				if (customer && customer.cabin_type) {
					const arrCabinType = customer.cabin_type.split(',');
					let defaultArraySpecials = optionSpecials.filter((u) => arrCabinType.includes(String(u.value)));
					defaultArraySpecials.forEach((item) => {
						totalSpecial += item.price;
					});
				}
			});
			setSpecialService(totalSpecial);
		}
	}, [customers, optionSpecials]);

	// pending choose
	const handleChange = (newValue) => {
		const dataOption = newValue;
		if (dataOption) {
			if (dataOption.value === 'Pending') {
				setPending(true);
			} else {
				setPending(false);
			}
			setUpdatedBooking({ ...updatedBooking, status: dataOption.value });
		}
	};

	// select partner
	const handleChangePartner = (dataPartner) => {
		if (dataPartner && dataPartner.dataKey > 0) {
			setAssignTo(dataPartner.dataKey);
			setCommissionNote(dataPartner.partnerNoteCommission);
			setPartnerDescription(dataPartner.partnerDescription);
			const updatedCustomers = customers.map((c) => {
				c.assign_to = dataPartner.dataKey;
				return c;
			});
			setCustomers(updatedCustomers);
			setUpdatedBooking({
				...updatedBooking,
				partner_id: Number(dataPartner.value),
				assign_to: dataPartner.dataKey,
				partner_name: dataPartner.label
			});

			return;
		}

		setAssignTo(0);
		setCommissionNote(null);
		setPartnerDescription(null);
		setAssignName('');
		setUpdatedBooking({
			...updatedBooking,
			partner_id: Number(0),
			partner_name: '',
			assign_name: ''
		});
	};

	// select cruise
	const handleChangeCruise = (newValue) => {
		const dataCruise = newValue;
		if (dataCruise) {
			//setCruiseID(dataCruise.value)
			setUpdatedBooking({
				...updatedBooking,
				cruise_id: Number(dataCruise.value),
				cruise_itinerary_id: Number(0)
			});
			const tripType = getCruiseTripType(cruises, dataCruise.value);
			setTripType(tripType);
		} else {
			setUpdatedBooking({ ...updatedBooking, cruise_id: Number(0), cruise_itinerary_id: Number(0) });
			//setCruiseID(null)
		}
		resetCustomerRoom(customers);
		setChooseDuration(0);
	};

	// select itinerary
	const handleChangeItinerary = (newValue) => {
		const dataItinerary = newValue;
		if (dataItinerary) {
			setStatusCustomer(true);
			setChooseDuration(Number(dataItinerary.valueID));
			setNewDuration(dataItinerary.duration);
			setUpdatedBooking({
				...updatedBooking,
				cruise_itinerary_id: Number(dataItinerary.value)
			});
		} else {
			setStatusCustomer(false);
			setChooseDuration(0);
		}
	};

	// select number pax adult

	const handleChangeNuberPaxAdult = (event) => {
		const dataNumber = event.target.value;

		if (dataNumber) {
			setUpdatedBooking({ ...updatedBooking, adults: Number(dataNumber) });
		} else {
			setUpdatedBooking({ ...updatedBooking, adults: Number(0) });
		}
	};

	const handleChangeNuberPaxChild = (event) => {
		const dataNumber = event.target.value;
		if (dataNumber) {
			setUpdatedBooking({ ...updatedBooking, children: Number(dataNumber) });
		} else {
			setUpdatedBooking({ ...updatedBooking, children: Number(0) });
		}
	};

	const handleChangeNuberPaxChild11 = (event) => {
		const dataNumber = event.target.value;
		if (dataNumber) {
			setUpdatedBooking({ ...updatedBooking, children11: Number(dataNumber) });
		} else {
			setUpdatedBooking({ ...updatedBooking, children11: Number(0) });
		}
	};

	const handleChangeNuberPaxInfant = (event) => {
		const dataNumber = event.target.value;
		if (dataNumber) {
			setUpdatedBooking({ ...updatedBooking, infants: Number(dataNumber) });
		} else {
			setUpdatedBooking({ ...updatedBooking, infants: Number(0) });
		}
	};

	const handleChangeMoney = (newValue, actionMeta) => {
		const dataMoney = newValue;
		if (dataMoney) {
			setUpdatedBooking({ ...updatedBooking, money_type: dataMoney.value });
		}
	};

	useEffect(() => {
		var tongNumber = 0;
		tongNumber = adults;
		if (children11) {
			tongNumber = tongNumber + children11;
		}
		if (children) {
			tongNumber = tongNumber + children;
		}
		if (infants) {
			tongNumber = tongNumber + infants;
		}
		setNumberCustomers(tongNumber);
		reduceCustomers(tongNumber);
	}, [adults, children11, children, infants]); // eslint-disable-line react-hooks/exhaustive-deps

	const reduceCustomers = (customerLength) => {
		const newCustomerLen = customerLength - customers.length;
		if (newCustomerLen > 0) {
			for (let i = 0; i < newCustomerLen; i++) {
				customers.push({
					cruise_id: cruise_id,
					assign_to: assign_to,
					start_date: start_date,
					partner_id: partner_id,
					cruise_itinerary_id: cruise_itinerary_id,
					booking_id: id
				});
			}

			return;
		}

		if (customerLength <= 0) {
			return;
		}

		const deleteCustomerLen = customers.length - customerLength;
		let deleteCustomerCount = 0;

		for (let i = customers.length - 1; i > 0; i--) {
			if (deleteCustomerCount < deleteCustomerLen) {
				customers.splice(i, 1);

				deleteCustomerCount++;
			}
		}
	};

	useEffect(() => {
		calculateRoomService();
	}, [numberCustomers, customers]);

	function dupCharsRooms(arr) {
		let dupChars = arr.filter((c, index) => {
			return arr.indexOf(c) !== index;
		});

		return dupChars;
	}

	const calculateRoomService = () => {
		let singleRoomCount = 0;
		customers.forEach((itemCustomer) => {
			if (itemCustomer && itemCustomer.room_id) {
				const customersInRoom = customers.filter((c) => c.room_id === itemCustomer.room_id);
				if (customersInRoom.length === 1) {
					singleRoomCount += 1;
				}
			}
		});

		setRoomService(singleRoomCount * dataPrice.single_price);
	};

	const handleChangeRoom = (newValue, actionMeta) => {
		const dataRoom = newValue;
		var textName = actionMeta.name;
		var theNumber = textName.match(/\d+/)[0];
		if (actionMeta.action === 'select-option') {
			if (!customers[theNumber]) {
				customers[theNumber] = {};
			}
			const fieldNames = actionMeta.name.split('-');
			const fieldName = fieldNames[0];
			customers[theNumber][fieldName] = dataRoom.value;
			customers[theNumber]['room_name'] = dataRoom.label;
			customers[theNumber]['booking_id'] = id;
			customers[theNumber]['cruise_id'] = cruise_id;
			customers[theNumber]['assign_to'] = assignTo;
			customers[theNumber]['partner_id'] = partner_id;
			customers[theNumber]['start_date'] = start_date;
			customers[theNumber]['cruise_itinerary_id'] = cruise_itinerary_id;
		} else {
			customers[theNumber]['room_id'] = null;
			customers[theNumber]['room_name'] = '';
		}
		calculateRoomService();
	};

	const handleChangeCountry = (newValue, actionMeta) => {
		const dataCountry = newValue;
		if (dataCountry) {
			var textName = actionMeta.name;
			var theNumber = textName.match(/\d+/)[0];
			if (!customers[theNumber]) {
				customers[theNumber] = {};
			}
			const fieldNames = actionMeta.name.split('-');
			const fieldName = fieldNames[0];
			customers[theNumber][fieldName] = dataCountry.value;
		}
	};

	const handleChangeGender = (newValue, actionMeta) => {
		const dataGender = newValue;
		if (dataGender) {
			var textName = actionMeta.name;
			var theNumber = textName.match(/\d+/)[0];
			if (!customers[theNumber]) {
				customers[theNumber] = {};
			}
			const fieldNames = actionMeta.name.split('-');
			const fieldName = fieldNames[0];
			customers[theNumber][fieldName] = dataGender.value;
		}
	};

	const handleTotalRoomPrice = (itemTotal) => {
		setTotalRoomPrice(itemTotal);
	};

	const handleVat = (numberVat) => {};

	const [isCustomTotal, setIsCustomTotal] = useState(false);

	useEffect(() => {
		if (showUpdateBookingModal && (loadingIndex === 0 || isCustomTotal)) {
			loadingIndex++;
			return;
		}
		async function changeTotal() {
			let cVAT = vat;
			if (!(cVAT >= 0)) {
				cVAT = 0;
			}
			const totalCS = totalCustomerService ? totalCustomerService : 0;
			const totalRP = totalRoomPrice ? totalRoomPrice : 0;
			let total = totalCS + totalRP + ((totalCS + totalRP) / 100) * cVAT;
			total += customerTransportAmount > 0 ? customerTransportAmount : 0;

			setUpdatedBooking({
				...updatedBooking,
				total: total,
				transportation_amount: customerTransportAmount > 0 ? customerTransportAmount : 0
			});
		}

		changeTotal();
	}, [totalCustomerService, totalRoomPrice, vat, customerTransportAmount]); // eslint-disable-line react-hooks/exhaustive-deps

	const onSubmit = async (event) => {
		event.preventDefault();

		var result = new Date(start_date);
		result.setDate(result.getDate() + (newDuration - 1));
		updatedBooking['end_date'] = result;
		updatedBooking['customers'] = customers.slice();

		if (!updatedBooking.cruise_itinerary_id) {
			alert(t('Please set Itinerary.'));
			return;
		}

		if (!updatedBooking.money_type) {
			alert(t('Have an error setting money type. Please change money type and select again.'));
			return;
		}

		if (!updatedBooking.status) {
			alert(t('Have an error setting status. Please change status and select again.'));
			return;
		}

		if (!updatedBooking.assign_to) {
			alert(t('Have an error setting assign to. Please change partner and select again.'));
			return;
		}

		if (!updatedBooking.adults) {
			alert(t('Adults value must be greater than zero'));
			return;
		}

		if (!customers || customers.length <= 0) {
			alert(t('Please select customers'));
			return;
		}
		
		const bookedRoomIds = [...new Set(customers.filter((item) => item.room_id).map(item => item.room_id))];
		if (tripType === CRUISE_TYPES.NIGHT && (!bookedRoomIds || bookedRoomIds.length <= 0)) {
			alert(t('Please select rooms for customers'));
			return;
		}

		const customerTransports = getCustomerTransports(
			updatedBooking['customers'],
			isCopy,
			start_date,
			result,
			updatedBooking.id,
			cruise_id
		);
		
		// return false;
		updatedBooking['customerTransports'] = customerTransports;
		if (isCopy) {
			delete updatedBooking.id;
			updatedBooking['customers'] = updatedBooking['customers'].map((c) => {
				delete c.id;
				delete c.booking_id;
				return c;
			});
			const response = await addBooking(updatedBooking);
			if (response.data && response.data.id) {
				setShowToast({
					show: true,
					message: 'Copy booking sucessful!',
					type: 'success'
				});
				window.open(`/bookings/show/${response.data.id}`, '_blank');
				closeDialog();
				return;
			}

			setShowToast({
				show: true,
				message: response.error.ErrorCode ? `Copy failed: ${response.error.ErrorCode}` : 'Add failed!',
				type: 'danger'
			});
			return;
		}

		const response = await updateBooking(updatedBooking);
		if (response.data) {
			setCustomersData(customers);
			setShowUpdateBookingModal(false);
			setShowToast({
				show: true,
				message: 'Updated successful!',
				type: 'sucess'
			});
			closeDialog(null, true);
			setDataBookingDetail(updatedBooking);
			return;
		}

		setShowToast({
			show: true,
			message: response.error.ErrorCode ? response.error.ErrorCode : 'Update failed!',
			type: 'danger'
		});
		setShowUpdateBookingModal(false);
	};

	const closeDialog = (event, isSubmit = false) => {
		loadingIndex = 1;
		setIsCustomTotal(false);
		if (!isSubmit) {
			setUpdatedBooking(booking);
			setCustomersData(props.customers.slice());
		}

		setShowUpdateBookingModal(false);
	};

	const isValueBookingChanged = () => {
		return booking.total !== updatedBooking.total;
	};

	let body = null;

	body = (
		<Modal
			show={showUpdateBookingModal}
			onHide={closeDialog}
			size='full'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>{isCopy ? t('Copy Booking') : t('Update Booking')}</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					<div className='row row-sm'>
						<div className='col-md-12 col-sm-12'>
							<div className='information'>
								<div className='information-header'>
									<h4 className='information-title mb-1'>{t('General Information')}</h4>
								</div>
								<div className='information-body'>
									<div className='row'>
										<div className='col-sm'>
											<div className='form-group'>
												<label>
													<strong>{t('Partner')}</strong>
												</label>
												<Select
													isClearable
													name='partner_id'
													defaultValue={
														optionPartners &&
														optionPartners.current &&
														optionPartners.current.length > 0 &&
														optionPartners.current.filter((option) =>
															option.value === partner_id ? { label: option.label, value: option.value } : ''
														)
													}
													onChange={handleChangePartner.bind(this)}
													options={optionPartners.current}
													placeholder={t('Choose Partner')}
												/>
											</div>
										</div>
										<div className='col-sm'>
											<div className='form-group'>
												<label>
													<strong>{t('Departure')}</strong>
												</label>
												<Form.Control
													type='date'
													name='start_date'
													value={start_date}
													onChange={onChangeText}
													className={isSpecialDate(cruiseSettings, cruise_id, start_date) ? 'special-date' : ''}
												/>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-sm'>
											<div className='form-group'>
												<label>
													<strong>{t('Partner Code')}</strong>
												</label>
												<input
													type='text'
													name='booking_code'
													value={booking_code}
													className='form-control'
													placeholder={t('optional')}
													onChange={onChangeText}
												/>
											</div>
										</div>
										<div className='col-sm'>
											<div className='form-group'>
												<label>
													<strong>{t('Status')}</strong>
												</label>
												<Select
													isClearable
													name='status'
													defaultValue={options.filter((option) =>
														option.value === status ? { label: option.label, value: option.value } : ''
													)}
													onChange={handleChange.bind(this)}
													options={options}
													placeholder={t('Choose Status')}
													className={updatedBooking.status ? '' : 'item-error'}
												/>
											</div>
										</div>
									</div>
									{pending ? (
										<div className='row'>
											<div className='col-sm'></div>
											<div className='col-sm'>
												<div className='form-group'>
													<div className='row'>
														<div className='col-sm'>
															<div className='form-group'>
																<label>
																	<strong>{t('Hold until')}</strong>
																</label>
																<Form.Control type='date' name='hold_date' value={hold_date} onChange={onChangeText} />
															</div>
														</div>
														<div className='col-sm'>
															<div className='form-group'>
																<label>
																	<strong>{t('Select time')}</strong>
																</label>
																<Form.Control type='time' value={hold_time} name='hold_time' onChange={onChangeText} />
															</div>
														</div>
													</div>
													<div className='row'>
														<div className='col-sm'>
															<label>
																<strong>{t('Note Hold')}</strong>
															</label>
															<textarea
																className='form-control'
																rows='3'
																name='message'
																value={message}
																placeholder={t('Note Hold')}
																onChange={onChangeText}
															></textarea>
														</div>
													</div>
												</div>
											</div>
										</div>
									) : (
										<div className='row'>
											<div className='col-sm'></div>
											<div className='col-sm'>
												<div className='form-group'>
													<div className='row'>
														<div className='col-sm'>
															<div className='form-group'>
																<label>
																	<strong>{t('Time for Deposit')}</strong>
																</label>
																<Form.Control
																	type='date'
																	name='time_for_deposite'
																	value={time_for_deposite}
																	onChange={onChangeText}
																/>
															</div>
														</div>
														<div className='col-sm'>
															<div className='form-group'>
																<label>
																	<strong>{t('Time for Payment')}</strong>
																</label>
																<Form.Control
																	type='date'
																	name='time_for_payment'
																	value={time_for_payment}
																	onChange={onChangeText}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									)}
									<div className='row'>
										<div className='col-md-6'>
											<div className='form-group'>
												<label>
													<strong>{t('Assign To')}</strong>
												</label>
												<input
													type='text'
													className='form-control'
													value={assignName}
													placeholder={t('Sale Name')}
													readOnly
												/>
											</div>
										</div>
										<div className='col-md-6'>
											<div className='form-group'>
												<div className='row'>
													<div className='col-sm'>
														<label>
															<strong>{t('Adults')}</strong>
														</label>
														<select
															className='form-control'
															name='adults'
															onChange={handleChangeNuberPaxAdult.bind(this)}
															required
														>
															{numberArray.map((number, index) => (
																<option
																	key={`adults-${index}`}
																	selected={index + 1 === adults ? `selected` : null}
																	value={index + 1}
																>{`${index + 1} ` + t('Pax')}</option>
															))}
														</select>
													</div>
													<div className='col-sm'>
														<label>
															<strong>{cruiseSetting && cruiseSetting.child_label1? t(cruiseSetting.child_label1): t('Child (5-7)')}</strong>
														</label>
														<select
															className='form-control'
															name='children'
															onChange={handleChangeNuberPaxChild.bind(this)}
														>
															{numberArray.map((number, index) => (
																<option
																	key={`children-${index}`}
																	selected={index === children ? `selected` : null}
																	value={index}
																>{`${index === 0 ? 0 : index} ` + t('Pax')}</option>
															))}
														</select>
													</div>
													<div className='col-sm'>
														<label>
															<strong>{cruiseSetting && cruiseSetting.child_lable2? t(cruiseSetting.child_lable2): t('Child (8-11)')}</strong>
														</label>
														<select
															className='form-control'
															name='children11'
															onChange={handleChangeNuberPaxChild11.bind(this)}
														>
															{numberArray.map((number, index) => (
																<option
																	key={`children11-${index}`}
																	selected={index === children11 ? `selected` : null}
																	value={index}
																>{`${index === 0 ? 0 : index} Pax`}</option>
															))}
														</select>
													</div>
													<div className='col-sm'>
														<label>
															<strong>{t('Infants')} (&#60; 5)</strong>
														</label>
														<select
															key='infants'
															className='form-control'
															name='infants'
															onChange={handleChangeNuberPaxInfant.bind(this)}
														>
															{numberArray.map((number, index) => (
																<option
																	key={`infants-${index}`}
																	selected={index === infants ? `selected` : null}
																	value={index}
																>{`${index === 0 ? 0 : index} ` + t('Pax')}</option>
															))}
														</select>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{booking ? (
						<div className='row row-sm'>
							<div className='col-md-12 col-sm-12'>
								<div className='information-booking'>
									<div className='information-booking-header'>
										<h4 className='information-title mb-1'>{t('Booking Item Details')}</h4>
									</div>
									<div className='information-booking-body'>
										<div className='row'>
											<div className='col-md-6'>
												<div className='form-group'>
													<label>
														<strong>{t('Cruise')}</strong>
													</label>
													<Select
														isClearable
														name='cruise_id'
														defaultValue={
															optionCruises &&
															optionCruises.current &&
															optionCruises.current.length > 0 &&
															optionCruises.current.filter((option) =>
																option.value === cruise_id ? { label: option.label, value: option.value } : ''
															)
														}
														onChange={handleChangeCruise.bind(this)}
														options={optionCruises.current}
														placeholder={t('Choose Cruise')}
													/>
												</div>
												<div className='form-group'>
													<label>
														<strong>{t('Itinerary')}</strong>
													</label>
													<Select
														isClearable
														name='duration'
														value={optionItineraries.filter((option) =>
															option.value === cruise_itinerary_id ? { label: option.label, value: option.value } : ''
														)}
														onChange={handleChangeItinerary.bind(this)}
														options={optionItineraries}
														placeholder={t('Choose Itinerary')}
													/>
												</div>
												{tripType === CRUISE_TYPES.NIGHT && (
													<div className='form-group'>
														<label>
															<strong>{t('Cabin Description')}</strong>
														</label>
														<textarea
															className='form-control'
															rows='3'
															name='cabin_desc'
															value={cabin_desc || ''}
															placeholder={t('Cabin Description')}
															onChange={onChangeText}
														></textarea>
													</div>
												)}
												{commissionNote ? (
													<div className='form-group'>
														<label>
															<strong>{t('Note the Commission for Partner')}</strong>
														</label>
														<p style={{ color: '#b72323', marginBottom: 0 }}>
															<strong>{commissionNote}</strong>
														</p>
													</div>
												) : null}
												{partnerDescription ? (
													<div className='form-group'>
														<label>
															<strong>{t('Partner Description')}</strong>
														</label>
														<p style={{ color: '#b72323', marginBottom: 0 }}>
															<strong>{partnerDescription}</strong>
														</p>
													</div>
												) : null}
											</div>
											<InfoUpdatedPrice
												tripType={tripType}
												moneys={moneys}
												dataPrice={dataPrice}
												handleChangeMoney={handleChangeMoney}
												onChangeNumber={onChangeNumber}
												onChangeText={onChangeText}
												setUpdatedBooking={setUpdatedBooking}
												updatedBooking={updatedBooking}
												total={total ? total : 0}
												customers={customers}
												handleTotalRoomPrice={handleTotalRoomPrice}
												dupCharsRooms={dupCharsRooms}
												handleVat={handleVat}
												onChangeCheckboxDefaultPrice={onChangeCheckboxDefaultPrice}
												onChangePrice={onChangePrice}
												isCustomTotal={isCustomTotal}
												setIsCustomTotal={setIsCustomTotal}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						''
					)}
					{statusCustomer ? (
						<div className='row row-sm'>
							<div className='col-md-12 col-sm-12'>
								<div className='information-customer'>
									<div className='information-other-header'>
										<h4 className='information-title mb-2'>{t('Participators')}</h4>
									</div>
									<div className='information-other-body table-responsive participators-table-mobile'>
										<Table striped bordered hover>
											<thead>
												<tr>
													{tripType === CRUISE_TYPES.NIGHT && <th style={{ width: '243px' }}>{t('Room')}</th>}
													{tripType === CRUISE_TYPES.NIGHT && <th>{t('Special Cabin')}</th>}
													<th style={{ width: '140px' }}>{t('Title')}</th>
													<th style={{ width: '215px' }}>{t('Full Name')}</th>
													<th style={{ width: '80px' }}>{t('Leader')}</th>
													<th style={{ width: '110px' }}></th>
													<th style={{ width: '110px' }}></th>
													<th style={{ width: '110px' }}></th>
												</tr>
											</thead>
											<tbody>
												{customers &&
													customers.length > 0 &&
													customers.map(
														(customer, index) =>
															customer && (
																<ItemInforUpdatedCustomer
																	tripType={tripType}
																	booking_id={id}
																	cruise_id={cruise_id}
																	assign_to={assign_to}
																	partner_id={partner_id}
																	cruise_itinerary_id={cruise_itinerary_id}
																	start_date={start_date}
																	key={index}
																	index={index}
																	customer={customer}
																	adults={adults}
																	children11={children11}
																	children={children}
																	infants={infants}
																	setUpdatedBooking={setUpdatedBooking}
																	updatedBooking={updatedBooking}
																	handleChangeRoom={handleChangeRoom}
																	allRooms={allRooms}
																	roomOptions={roomOptions}
																	optionSpecials={optionSpecials}
																	mrCustoms={mrCustoms}
																	numberCustomers={numberCustomers}
																	handleChangeCountry={handleChangeCountry}
																	optionCountries={optionCountries.current}
																	handleChangeGender={handleChangeGender}
																	genders={genders}
																	// handleTotalCustomersAll={handleTotalCustomersAll}
																	setSpecialService={setSpecialService}
																	customers={customers}
																	setCustomers={setCustomers}
																	defaultPriceService={defaultPriceService}
																	setDefaultPriceService={setDefaultPriceService}
																	moneyType={updatedBooking.money_type}
																	setCustomerTransportAmount={setCustomerTransportAmount}
																/>
															)
													)}
											</tbody>
										</Table>
									</div>
								</div>
							</div>
						</div>
					) : (
						''
					)}
					<div className='row row-sm'>
						<div className='col-md-12 col-sm-12'>
							<div className='information-other'>
								<div className='information-other-header'>
									<h4 className='information-title mb-1'>{t('Other Information')}</h4>
								</div>
								<div className='information-other-body'>
									<div className='row'>
										<div className='col-md-6'>
											<div className='form-group'>
												<label>
													<strong>{t('Sale Note')}</strong>
												</label>
												<CKEditor
													editor={ClassicEditor}
													data={sale_note}
													onReady={(editor) => {
														// You can store the "editor" and use when it is needed.
														//console.log( 'Editor is ready to use!', editor );
													}}
													onChange={(event, editor) => {
														const data = editor.getData();
														setUpdatedBooking({
															...updatedBooking,
															sale_note: data
														});
														//console.log( { event, editor, data } );
													}}
													onBlur={(event, editor) => {
														//console.log( 'Blur.', editor );
													}}
													onFocus={(event, editor) => {
														//console.log( 'Focus.', editor );
													}}
												/>
											</div>
										</div>
										<div className='col-md-6'>
											<div className='form-group'>
												<label>
													<strong>{t('Pick-up/Drop-off')}</strong>
												</label>
												<CKEditor
													editor={ClassicEditor}
													data={pickup}
													onReady={(editor) => {
														// You can store the "editor" and use when it is needed.
														//console.log( 'Editor is ready to use!', editor );
													}}
													onChange={(event, editor) => {
														const data = editor.getData();
														setUpdatedBooking({
															...updatedBooking,
															pickup: data
														});
														//console.log( { event, editor, data } );
													}}
													onBlur={(event, editor) => {
														//console.log( 'Blur.', editor );
													}}
													onFocus={(event, editor) => {
														//console.log( 'Focus.', editor );
													}}
												/>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-md-6'>
											<div className='form-group'>
												<label>
													<strong>{t('Note for Accounting')}</strong>
												</label>
												<CKEditor
													editor={ClassicEditor}
													data={acc_note}
													onReady={(editor) => {
														// You can store the "editor" and use when it is needed.
														//console.log( 'Editor is ready to use!', editor );
													}}
													onChange={(event, editor) => {
														const data = editor.getData();
														setUpdatedBooking({
															...updatedBooking,
															acc_note: data
														});
														//console.log( { event, editor, data } );
													}}
													onBlur={(event, editor) => {
														//console.log( 'Blur.', editor );
													}}
													onFocus={(event, editor) => {
														//console.log( 'Focus.', editor );
													}}
												/>
											</div>
										</div>
										<div className='col-md-6'>
											<div className='form-group'>
												<label>
													<strong>{t('Operation Note')}</strong>
												</label>
												<CKEditor
													editor={ClassicEditor}
													data={opt_note}
													onReady={(editor) => {
														// You can store the "editor" and use when it is needed.
														//console.log( 'Editor is ready to use!', editor );
													}}
													onChange={(event, editor) => {
														const operation = editor.getData();
														setUpdatedBooking({
															...updatedBooking,
															opt_note: operation
														});
														//console.log( { event, editor, operation } );
													}}
													onBlur={(event, editor) => {
														//console.log( 'Blur.', editor );
													}}
													onFocus={(event, editor) => {
														//console.log( 'Focus.', editor );
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button className='btn btn-danger btn-with-icon' variant='secondary' onClick={closeDialog}>
						<i className='fe fe-x-circle'></i> {t('Cancel')}
					</Button>
					<Button className='btn btn-primary btn-with-icon' variant='primary' type='submit'>
						<i className='fe fe-save'></i> {t('Save')}!!!
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);

	return <Fragment>{body !== null && body}</Fragment>;
};

const getCustomerTransports = (customers, isCopy = false, startDate, endDate, bookingID, cruiseID) => {
	const tmpCustomers = customers?.slice();
	let customerTransports = [];
	tmpCustomers.forEach((customer) => {
		const customerTransport = {};
		
		if (!customer.transport || ((!customer.transport.oneway || customer.transport.oneway === false) && (!customer.transport.return || customer.transport.return === false))) return;
		if (!isCopy) {
			customerTransport.customer_id = customer.id;
			customerTransport.id = customer.transport.id;
			customerTransport.booking_id = bookingID;
			customerTransport.cruise_id = cruiseID;
		}

		customerTransport.pickup_id = customer.transport.pickup_id;
		customerTransport.dropoff_id = customer.transport.dropoff_id;
		customerTransport.transportation_type_id = customer.transport.transportation_type_id;
		customerTransport.route_type = customer.transport.return ? 'Return' : 'Departure';
		customerTransport.pickup_note = customer.transport.pickup_note ? customer.transport.pickup_note : '';
		customerTransport.transportation_route_id = customer.transport.transportation_route_id;
		customerTransport.transportation_type_price = customer.transport.transportation_type_price;
		customerTransport.transportation_route_price = customer.transport.transportation_route_price;
		customerTransport.start_date = customer.transport.start_date
			? convertStringToDateISO(customer.transport.start_date)
			: convertStringToDateISO(startDate);
		customerTransport.end_date = convertStringToDateISO(endDate);

		customerTransports.push(customerTransport);
	});

	return customerTransports;
};

const resetCustomerRoom = (customers) => {
	customers.forEach((customer, index) => {
		customers[index]['room_id'] = 0;
		customers[index]['cruise_id'] = 0;
		customers[index]['cruise_itinerary_id'] = 0;
	});
};

export default UpdateBookingModal;
