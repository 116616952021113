import { Fragment, useContext, useEffect, useState } from 'react';
import { Alert, OverlayTrigger, Popover } from 'react-bootstrap';
import { AuthContext } from '../../../contexts/AuthContext';
import { BOOKING_STATUSES } from '../../../contexts/constants';
import { currencyFormatter, getBookingCellBackground } from '../../../utils/common';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BookingContext } from '../../../contexts/BookingContext';
import { CruiseSettingContext } from '../../../contexts/CruiseSettingContext';
import { ROLES, PERMISSIONS } from '../../../contexts/constants';

const InfoDetailNightBooking = (props) => {
	const {
		authState: { user }
	} = useContext(AuthContext);

	const {
        cruiseSettingState: { cruiseSettings },
    } = useContext(CruiseSettingContext)

	const getChildLabelSetting = (cruise_id) => {
		const cruiseSetting = cruiseSettings? cruiseSettings.find((item) => {
			return item.cruise_id == cruise_id;
		}): {}
		
		return cruiseSetting;
	}

	const bookingRoomData = props.bookingRoomData;
	const setShowAddBookingModal = props.setShowAddBookingModal;
	const setAddBookingDefaultData = props.setAddBookingDefaultData;
	let dataBooking = [];

	const customers = props.customerProps;
	const bookingId = props.idBookingProps;
	const itineraries = props.itineraries;
	const roomName = props.roomName;
	const roomNameFull = props.roomNameFull;
	const cancelledLabel = props.cancelledLabel;
	let dataArrays = [];
	let canceledAndTransCustomers = [];
	let activeCustomers = [];
	let idCheck = [];

	const [windowSize, setWindowSize] = useState(window.innerWidth);

	const handleWindowSizeChange = () => {
		setWindowSize(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);
	const isMobile = windowSize <= 576;

	const { t } = useTranslation();

	const getRoomCount = (bookingId) => {
		return bookingRoomData? bookingRoomData[bookingId]: '';
	}

	const popover = (dataBooking) => {
		if (!user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR)
			&& !user.Permissions.includes(PERMISSIONS.VIEW_BOOKING)) return (<div></div>);
		const cruiseSetting = getChildLabelSetting(dataBooking.cruise_id)
		return (
			<Popover id='popover-basic'>
				<Popover.Content className='message is-info'>
					<div className='message-body'>
						<div className='row'>
							<div className='col-5'>
								<label>Booking</label>
							</div>
							<div className='col-7'>
								<strong>{dataBooking.new_code ? dataBooking.new_code : dataBooking.id}</strong>
							</div>
						</div>
						{user.department !== 'member' && (
							<div className='row'>
								<div className='col-5'>
									<label>{t('Partner')}</label>
								</div>
								<div className='col-7'>
									<strong>{dataBooking.partner_name}</strong>
								</div>
							</div>
						)}
						<div className='row'>
							<div className='col-5'>
								<label>{t('Departure')}</label>
							</div>
							<div className='col-7'>
								<strong>{Moment(dataBooking.start_date).format('MMMM D, YYYY')}</strong>
							</div>
						</div>
						<div className='row'>
							<div className='col-5'>
								<label>{t('Group Size')}</label>
							</div>
							<div className='col-7'>
								<strong>
									{dataBooking.adults} {t('Adult')}
									{dataBooking.children ? ', ' + dataBooking.children + ` [${cruiseSetting && cruiseSetting.child_label1? t(cruiseSetting.child_label1): '5-7'}]` : ''}
									{dataBooking.children11 ? ', ' + dataBooking.children11 + ` [${cruiseSetting && cruiseSetting.child_lable2? t(cruiseSetting.child_lable2): '8-11'}]` : ''}
									{dataBooking.infants ? ' - ' + dataBooking.infants + ' ' + t('Infant') : ''} / {getRoomCount(dataBooking.booking_id)} {t('rooms')}
								</strong>
							</div>
						</div>
						<div className='row'>
							<div className='col-5'>
								<label>{t('Participators')}</label>
							</div>
							<div className='col-7'>
								<strong>{getNameCustomer(dataBooking.data, dataBooking.booking_id)}</strong>
							</div>
						</div>
						<div className='row'>
							<div className='col-5'>
								<label>{t('Cabin')}</label>
							</div>
							<div className='col-7'>
								<strong>{dataBooking.cabin_desc}</strong>
							</div>
						</div>
						<div className='row'>
							<div className='col-5'>
								<label>{t('Cabin Name')}</label>
							</div>
							<div className='col-7'>
								<strong>{roomNameFull}</strong>
							</div>
						</div>
						<div className='row'>
							<div className='col-5'>
								<label>{t('Assign To')}</label>
							</div>
							<div className='col-7'>
								<strong>{dataBooking.assign_to_name}</strong>
							</div>
						</div>
						<div className='row'>
							<div className='col-5'>
								<label>{t('Total')}</label>
							</div>
							<div className='col-7'>
								<strong>
									{currencyFormatter(dataBooking.money_type).format(dataBooking.total? dataBooking.total: 0)}
								</strong>
							</div>
						</div>
						<div className='show-more-cell'>
							<>
								<Link to={`/bookings/show/${dataBooking.booking_id}`} target='_blank'>
									{t('Show more')}
								</Link>
							</>
						</div>
					</div>
				</Popover.Content>
			</Popover>
		);
	};

	if (customers.length > 0) {
		if (bookingId.length > 1) {
			bookingId.forEach((booking) => {
				customers.forEach((customer) => {
					if (customer.booking_id === booking) {
						if (idCheck.includes(customer.booking_id) !== true) {
							idCheck.push(customer.booking_id);
							customer.data = customers;
							dataArrays.push(customer);
							if (
								customer.status === BOOKING_STATUSES.CANCELED ||
								customer.status === BOOKING_STATUSES.TRANSFERRED
							) {
								canceledAndTransCustomers.push(customer);
							} else {
								activeCustomers.push(customer);
							}
						}
					}
				});
			});
		} else {
			if (customers.length > 1) {
				customers.forEach((customer, i) => {
					customer.data = customers;
					dataBooking = customer;
				});
			} else {
				customers[0].data = customers[0];
				dataBooking = customers[0];
			}
		}
	}

	const getNameCustomer = (customers, bookingID) => {
		var namePopover = '';
		if (customers !== undefined) {
			if (customers.length > 1) {
				customers.forEach((customer) => {
					if (
						customer.full_name !== undefined &&
						customer.leader === 1 &&
						customer.booking_id === bookingID
					) {
						namePopover = customer.full_name;
					}
				});
			} else {
				if (customers.full_name !== undefined && customers.booking_id === bookingID) {
					namePopover = customers.full_name;
				}
			}
		}

		return namePopover;
	};

	const getNameDuration = (idItinerary) => {
		const itinerary = itineraries.find((itinerary) => {
			return itinerary.id === idItinerary;
		});
		return itinerary && itinerary.duration ? itinerary.duration : 0;
	};

	const checkData = (dataArrays) => {
		let result = 0;
		dataArrays.forEach((data) => {
			if (data.status !== BOOKING_STATUSES.CANCELED && data.status !== BOOKING_STATUSES.TRANSFERRED) {
				result = result + 1;
			}
		});

		return result;
	};
	const checkClickCell = (event) => {
		if (!isMobile) return true;
		event.preventDefault();
	};

	if (dataArrays.length > 1) {
		let checkBorder = checkData(dataArrays);
		let isShowedItem = false;
		return (
			<Fragment>
				{checkBorder > 1 ? (
					<div className='border-black'>
						{dataArrays.map((data, index) => {
							if (
								data.status !== BOOKING_STATUSES.CANCELED &&
								data.status !== BOOKING_STATUSES.TRANSFERRED
							) {
								if (!isShowedItem) {
									isShowedItem = true;
									return (
										<OverlayTrigger
											rootClose
											key={index}
											trigger={isMobile ? 'click' : ['hover', 'hover']}
											placement='bottom'
											overlay={popover(data)}
										>
											<Link
												to={`/bookings/show/${data.booking_id}`}
												target='_blank'
												className={
													getBookingCellBackground(
														data.status,
														getNameDuration(data.cruise_itinerary_id)
													)
												}
												onClick={checkClickCell.bind(this)}
											>
												{data.new_code ? data.new_code : data.id}
											</Link>
										</OverlayTrigger>
									);
								}

								return (
									<OverlayTrigger
										rootClose
										key={index}
										trigger={isMobile ? 'click' : ['hover', 'hover']}
										placement='bottom'
										overlay={popover(data)}
									>
										<Link
											to={`/bookings/show/${data.booking_id}`}
											target='_blank'
											className={
												getBookingCellBackground(
													data.status,
													getNameDuration(data.cruise_itinerary_id)
												) + ' hide-link'
											}
											onClick={checkClickCell.bind(this)}
										>
											{<label onClick={setShowAddBookingModal}></label>}
										</Link>
									</OverlayTrigger>
								);
							}
							return (
								<OverlayTrigger
									rootClose
									key={index}
									trigger={isMobile ? 'click' : ['hover', 'hover']}
									placement='bottom'
									overlay={popover(data)}
								>
									<Link
										to={`/bookings/show/${data.booking_id}`}
										target='_blank'
										className={
											getBookingCellBackground(
												data.status,
												getNameDuration(data.cruise_itinerary_id)
											)
										}
										onClick={checkClickCell.bind(this)}
									>
										{
											<label
												className='disable-room-label'
												onClick={setShowAddBookingModal}
											></label>
										}
									</Link>
								</OverlayTrigger>
							);
						})}
					</div>
				) : (
					<>
						{activeCustomers.map((data, index) => (
							<>
								<OverlayTrigger
									rootClose
									key={index}
									trigger={isMobile ? 'click' : ['hover', 'hover']}
									placement='bottom'
									overlay={popover(data)}
								>
									<Link
										to={`/bookings/show/${data.booking_id}`}
										target='_blank'
										className={
											getBookingCellBackground(
												data.status,
												getNameDuration(data.cruise_itinerary_id)
											) +
											` cou-${index}`
										}
										onClick={checkClickCell.bind(this)}
									>
										{data.new_code}
									</Link>
								</OverlayTrigger>
							</>
						))}
						<div className='group-point'>
							{canceledAndTransCustomers && canceledAndTransCustomers.length > 0 ? (
								<label className='disable-room-label' onClick={setShowAddBookingModal}></label>
							) : null}
							<div className='canceled-group'>
								{canceledAndTransCustomers.map((data, index) => (
									<>
										<OverlayTrigger
											rootClose
											key={index}
											trigger={isMobile ? 'click' : ['hover', 'hover']}
											placement='bottom'
											overlay={popover(data)}
										>
											<Link
												to={`/bookings/show/${data.booking_id}`}
												target='_blank'
												className={
													getBookingCellBackground(
														data.status,
														getNameDuration(data.cruise_itinerary_id)
													) +
													` cou-${index}`
												}
												onClick={checkClickCell.bind(this)}
												style={{ right: `${index * 9}px`, display:  !cancelledLabel.isShow && data.status == BOOKING_STATUSES.CANCELED? 'none': 'block'}}
											></Link>
										</OverlayTrigger>
									</>
								))}
							</div>
						</div>
					</>
				)}
			</Fragment>
		);
	}

	return (
		<Fragment>
			{dataBooking && dataBooking.booking_id ? (
				<>
					{dataBooking.status == 'Canceled' || dataBooking.status == 'Transferred' ? (
						<label className='disable-room-label' onClick={setShowAddBookingModal}></label>
					) : null}
					<OverlayTrigger
						rootClose
						trigger={isMobile ? 'click' : ['hover', 'hover']}
						placement='bottom'
						overlay={popover(dataBooking)}
					>
						<Link
							to={`/bookings/show/${dataBooking.booking_id}`}
							target='_blank'
							className={
								getBookingCellBackground(
									dataBooking.status,
									getNameDuration(dataBooking.cruise_itinerary_id)
								)
							}
							onClick={checkClickCell.bind(this)}
						>
							{dataBooking.status !== 'Canceled' && dataBooking.status !== 'Transferred'
								? dataBooking.new_code
									? dataBooking.new_code
									: dataBooking.id
								: null}
						</Link>
					</OverlayTrigger>
				</>
			) : (
				<label className='disable-room-label' onClick={setShowAddBookingModal}></label>
			)}
		</Fragment>
	);
};

export default InfoDetailNightBooking;
