import { Fragment, useContext } from 'react'
import { ServiceContext } from '../../contexts/ServiceContext'
import ListService from '../../components/layout/services/ListService'
import AddServiceModal from '../../components/layout/services/AddServiceModal'
import { AuthContext } from '../../contexts/AuthContext';
import { ROLES, PERMISSIONS, NO_PERMISSION } from '../../contexts/constants';

const Service = () => {

    const { 
        setShowAddServiceModal
    } = useContext(ServiceContext)
    const {
        authState: {user }
    } = useContext(AuthContext)

    if (!user) {
        return NO_PERMISSION
    }

    if (!(user.Permissions.includes(PERMISSIONS.GET_SERVICE) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))) {
        return NO_PERMISSION
    }
    return (
        <Fragment>
            <div className="horizontal-mainwrapper container clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-3">
                        <div className="row">
                            <div className="col-md-4">
                                <h3 className="pull-left">Service List</h3>
                            </div>
                            <div className="col-md-2 ml-auto">
                            {(user.Permissions.includes(PERMISSIONS.INSERT_SERVICE) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))? <button className="right-content btn btn-success btn-block" onClick={setShowAddServiceModal.bind(this, true)}><i className="fe fe-plus-circle"></i> Add Service</button>: null }
                            </div>
                        </div>
                    </div>
                    <ListService />
                    <AddServiceModal />
                </div>
            </div>
        </Fragment>
    )
}

export default Service
