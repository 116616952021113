import { Fragment, useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { CruiseContext } from '../../../contexts/CruiseContext';
import { findOptionItem, getOptions } from '../../../utils/common';
import { useTranslation } from 'react-i18next';

const RevenueCruises = (props) => {
	const { t } = useTranslation();
	const filterDataRevenue = props.filterDataRevenue;
	const applyFilter = props.applyFilter;

	const {
		cruiseState: { cruises },
		getCruises
	} = useContext(CruiseContext);

	useEffect(() => {
		if (!cruises || !cruises.length) {
			getCruises();
		}
	}, []);
	const [optionCruises, setOptionCruises] = useState([]);
	useEffect(() => {
		const cruiseOpts = getOptions(cruises, ['label', 'value', 'typeDay'], ['name', 'id', 'typeDay']);
		setOptionCruises(cruiseOpts);
	}, [cruises]);

	return (
		<Fragment>
			<div className='form-group'>
				<label>
					<strong>{t('Cruise')}</strong>
				</label>
				<Select
					isClearable
					name='cruise_id'
					menuPosition={'fixed'}
					menuPortalTarget={document.body}
					className='basic-multi-select'
					classNamePrefix='select'
					onChange={applyFilter.bind(this)}
					options={optionCruises}
					value={findOptionItem(optionCruises, filterDataRevenue.cruise_id)}
					placeholder={t('Choose Cruise')}
				/>
			</div>
		</Fragment>
	);
};

export default RevenueCruises;
