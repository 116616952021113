import { createContext, useState, useReducer } from 'react';
import { cruiseSettingReducer } from '../reducers/cruiseSettingReducer';
import {
	apiUrl,
	CRUISE_SETTINGS_LOADED_SUCCESS,
	CRUISE_SETTINGS_LOADED_FAIL,
	ADD_CRUISE_SETTING,
	UPDATE_CRUISE_SETTING,
	FIND_CRUISE_SETTING,
	DELETE_CRUISE_SETTING
} from './constants';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';

export const CruiseSettingContext = createContext();

const CruiseSettingContextProvider = ({ children }) => {
	// State
	const [cruiseSettingState, dispatch] = useReducer(cruiseSettingReducer, {
		cruiseSetting: null,
		cruiseSettings: [],
		cruiseSettingsLoading: true
	});

	const [showAddCruiseSettingModal, setShowAddCruiseSettingModal] = useState(false);
	const [showUpdateCruiseSettingModal, setShowUpdateCruiseSettingModal] = useState(false);
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null
	});

	// Get all Cruise Settings
	const getCruiseSettings = async () => {
		try {
			const response = await getAPI(`${apiUrl}/cruise-settings`);
			if (response) {
				dispatch({ type: CRUISE_SETTINGS_LOADED_SUCCESS, payload: response.data });
			}
		} catch (error) {
			dispatch({ type: CRUISE_SETTINGS_LOADED_FAIL });
			return error.response ? error.response : { success: false, message: 'Server error!' };
		}
	};

	// Add Cruise Setting
	const addCruiseSetting = async (newCruise) => {
		const response = await postAPI(`${apiUrl}/cruise-setting/insert`, newCruise);
		if (response.data) {
			dispatch({
				type: ADD_CRUISE_SETTING,
				payload: { ...newCruise, id: response.data.id }
			});
		}
		return response;
	};

	// Find Cruise when user is updating post
	const findCruiseSetting = (cruiseId) => {
		const cruiseSetting = cruiseSettingState.cruiseSettings.find((cruiseSetting) => cruiseSetting.id === cruiseId);
		dispatch({
			type: FIND_CRUISE_SETTING,
			payload: cruiseSetting
		});
	};

	const clearCruise = () => {
		dispatch({
			type: FIND_CRUISE_SETTING,
			payload: {
				important_note: '',
				property_information: ''
			}
		});
	};

	// update Cruise Setting
	const updateCruiseSetting = async (updateCruise) => {
		const response = await postAPI(`${apiUrl}/cruise-setting/update`, updateCruise);
		if (response.status === 200) {
			dispatch({
				type: UPDATE_CRUISE_SETTING,
				payload: updateCruise
			});
		}
		return response;
	};

	// Delete Cruise
	const deleteCruiseSetting = async (cruiseId) => {
		const response = await getDeleteAPI(`${apiUrl}/cruise-setting/${cruiseId}/delete`);
		if (response.status === 200) {
			dispatch({
				type: DELETE_CRUISE_SETTING,
				payload: cruiseId
			});
		}
		return response;
	};

	// Post Context Data
	const cruiseContextData = {
		cruiseSettingState,
		getCruiseSettings,
		addCruiseSetting,
		findCruiseSetting,
		updateCruiseSetting,
		deleteCruiseSetting,
		showToast,
		setShowToast,
		showAddCruiseSettingModal,
		setShowAddCruiseSettingModal,
		showUpdateCruiseSettingModal,
		setShowUpdateCruiseSettingModal,
		clearCruise
	};

	return <CruiseSettingContext.Provider value={cruiseContextData}>{children}</CruiseSettingContext.Provider>;
};

export default CruiseSettingContextProvider;
