import { PERMISSIONS_LOADED_SUCCESS, PERMISSIONS_LOADED_FAIL, ADD_PERMISSION, FIND_PERMISSION, UPDATE_PERMISSION, DELETE_PERMISSION } from '../contexts/constants';

export const permissionReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case PERMISSIONS_LOADED_SUCCESS:
            return {
                ...state,
                permissions: payload,
                permissionsLoading: false
            }
        case PERMISSIONS_LOADED_FAIL:
            return {
                ...state,
                permissions: [],
                permissionsLoading: false
            }
        case ADD_PERMISSION:
            {
                return {
                    ...state,
                    permissions: [...state.permissions, payload],
                }
            }
        case FIND_PERMISSION:
            return {
                ...state,
                permission: payload
            }
        case UPDATE_PERMISSION:
            const newPermission = state.permissions.map(permission => {
                if (permission.id === payload.id) {
                    return payload
                } else {
                    return permission
                }
            })
            return {
                ...state,
                permissions: newPermission
            }
        case DELETE_PERMISSION:
            return {
                ...state,
                permissions: state.permissions.filter(permission => permission.id !== payload)
            }
        default:
            return state
    }
}