import { createContext, useReducer, useState, useContext } from 'react';
import { roomReducer } from '../reducers/roomReducer';
import {
	apiUrl,
	ROOMS_LOADED_SUCCESS,
	ROOMS_LOADED_FAIL,
	ADD_ROOM,
	FIND_ROOM,
	UPDATE_ROOM,
	DELETE_ROOM
} from './constants';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';
import { CruiseContext } from './CruiseContext';
import { removeFiles } from '../utils/common';

export const RoomContext = createContext();

const RoomContextProvider = ({ children }) => {
	const {
		cruiseState: { cruises }
	} = useContext(CruiseContext);

	// State
	const [roomState, dispatch] = useReducer(roomReducer, {
		room: null,
		rooms: [],
		roomsLoading: true
	});
	const [updatedRoom, setUpdatedRoom] = useState(roomState.room);
	const [deleteFiles, setDeleteFiles] = useState([]);
	const [roomImageUrls, setRoomImageUrls] = useState([]);
	const [showAddRoomModal, setShowAddRoomModal] = useState(false);
	const [showUpdateRoomModal, setShowUpdateRoomModal] = useState(false);
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null
	});

	// Get all Rooms
	const getRooms = async (objectValidate) => {
		const response = await getAPI(`${apiUrl}/rooms`, `${objectValidate}`);
		if (response.data) {
			dispatch({ type: ROOMS_LOADED_SUCCESS, payload: response.data });
			return response;
		}

		dispatch({ type: ROOMS_LOADED_FAIL });
		return response;
	};

	// Add Room
	const addRoom = async (newRoom) => {
		const response = await postAPI(`${apiUrl}/room/insert`, newRoom);
		if (response.data) {
			const cruise = cruises.find((c) => c.id === response.data.cruise_id);
			response.data.ROOM_name = cruise.name;
			dispatch({
				type: ADD_ROOM,
				payload: response.data
			});
		}

		return response;
	};

	// Find Room when user is updating room
	const findRoom = (roomId) => {
		const room = roomState.rooms.find((room) => room.id === roomId);
		dispatch({
			type: FIND_ROOM,
			payload: room
		});
	};

	// Update Room
	const updateRoom = async (updateRoom) => {
		const response = await postAPI(`${apiUrl}/room/update`, updateRoom);
		if (response.status === 200) {
			const cruise = cruises.find((c) => c.id === updateRoom.cruise_id);
			updateRoom.ROOM_name = cruise.name;
			dispatch({
				type: UPDATE_ROOM,
				payload: response.data
			});
		}
		return response;
	};

	// Delete Room
	const deleteRoom = async (roomId) => {
		// get images by id
		const room = roomState.rooms.find((room) => room.id === roomId);
		const response = await getDeleteAPI(`${apiUrl}/room/${roomId}/delete`);
		if (response.status === 200) {
			dispatch({
				type: DELETE_ROOM,
				payload: roomId
			});
			// delete images here
			await removeFiles(room.images);
		}
		return response;
	};

	const clearRoom = () => {
		dispatch({
			type: FIND_ROOM,
			payload: {}
		});
	};

	const roomContextData = {
		roomState,
		getRooms,
		addRoom,
		findRoom,
		clearRoom,
		updateRoom,
		deleteRoom,
		showAddRoomModal,
		setShowAddRoomModal,
		showUpdateRoomModal,
		setShowUpdateRoomModal,
		showToast,
		setShowToast,
		roomImageUrls,
		setRoomImageUrls,
		deleteFiles,
		setDeleteFiles,
		updatedRoom,
		setUpdatedRoom
	};

	return <RoomContext.Provider value={roomContextData}>{children}</RoomContext.Provider>;
};

export default RoomContextProvider;
