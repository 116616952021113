import { Fragment } from 'react';
import { CRUISE_TYPES } from '../../../contexts/constants';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { TransportationPickUpContext } from '../../../contexts/TransportationPickUpContext';
import { formatDate } from '../../../utils/common';
import { useTranslation } from 'react-i18next';

const CustomerInfo = (props) => {
	const tripType = props.tripType;
	const customers = props.customers;

	const {
		transportationPickUpState: { transportationPickUps },
		getTransportationPickUps
	} = useContext(TransportationPickUpContext);

	useEffect(() => {
		if (!transportationPickUps || !transportationPickUps.length) getTransportationPickUps();
	}, []);

	const getRouteName = (customerTransport) => {
		if (!customerTransport) return '';
		const pickup = transportationPickUps.find((item) => item.id === customerTransport.pickup_id);
		const dropoff = transportationPickUps.find((item) => item.id === customerTransport.dropoff_id);

		if (!pickup && !dropoff) return '';

		const route = `${pickup ? pickup.name : ''} - ${dropoff ? dropoff.name : ''} ( ${
			customerTransport.route_type === 'Return' ? customerTransport.route_type : 'Oneway'
		} )`;
		return route;
	};

	const { t } = useTranslation();

	return (
		<Fragment>
			<div className='row'>
				<div className='col-md-12 table-mobile-scroll'>
					<div className='info-header-detail mt-3'>
						<h1 className='float-left'>{t("Customers' Information")}</h1>
						<hr />
					</div>
					{customers && customers.length > 0 && (
						<table className='table table-bordered mg-b-0 text-md-nowrap'>
							<thead>
								<tr>
									<th>STT</th>
									{tripType === CRUISE_TYPES.NIGHT && <th>{t('Cabin')}</th>}
									{tripType === CRUISE_TYPES.NIGHT && <th>{t('Special')}</th>}
									<th>{t('Title')}</th>
									<th>{t('Full Name')}</th>
									<th>{t('Email')}</th>
									<th>{t('Leader')}</th>
									<th>{t('Age')}</th>
									<th>{t('Passport No')}.</th>
									<th>{t('Visa Expiration')}</th>
									<th>{t('Country')}</th>
									<th>{t('Gender')}</th>
									<th>{t('Transportation')}</th>
									<th>{t('Phone')}</th>
									<th>{t('Notes')}</th>
								</tr>
							</thead>
							<tbody>
								{customers.map((customer, i) => (
									<tr key={customer.id}>
										<td>{i + 1}</td>
										{tripType === CRUISE_TYPES.NIGHT && <td>{customer.room_name}&nbsp;</td>}
										{tripType === CRUISE_TYPES.NIGHT && <td>{customer.cabin_speical_name}&nbsp;</td>}
										<td>{customer.title}&nbsp;</td>
										<td>{customer.full_name}&nbsp;</td>
										<td>{customer.email}&nbsp;</td>
										<td>
											{customer.leader === 1 ? (
												<i className='fe fe-check-square' aria-hidden='true'>
													&nbsp;
												</i>
											) : (
												''
											)}
										</td>
										<td>{customer.age}&nbsp;</td>
										<td>{customer.passport_number}&nbsp;</td>
										<td>{formatDate(customer.passport_expired, 'MMMM D, YYYY')}</td>
										<td>{customer.country}&nbsp;</td>
										<td>{customer.gender === 'male' ? t('M') : t('F')}&nbsp;</td>
										{/* <td>{customer.birthday ? moment(customer.birthday).format('MMMM D, YYYY') : ''}&nbsp;</td> */}
										<td>{getRouteName(customer.transport)}</td>
										<td>{customer.phone}&nbsp;</td>
										<td>{customer.note}&nbsp;</td>
									</tr>
								))}
							</tbody>
						</table>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default CustomerInfo;
