import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Dropdown, Button, Form, Alert, Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import { TransportationPickUpContext } from '../../../../contexts/TransportationPickUpContext';
import { TransportationRouteContext } from '../../../../contexts/TransportationRouteContext';
import { TransportationTypeContext } from '../../../../contexts/TransportationTypeContext';
import { findOptionItem, formatDate, getOptions, isEmptyObj } from '../../../../utils/common';
import { useTranslation } from 'react-i18next';

const ItemCustomerTransportation = ({ currency, customers, customerIndex, setCustomerTransportAmount, start_date }) => {
	const {
		transportationRouteState: { transportationRoutes },
		getTransportationRoutes
	} = useContext(TransportationRouteContext);

	const {
		transportationPickUpState: { transportationPickUps },
		getTransportationPickUps
	} = useContext(TransportationPickUpContext);

	const {
		transportationTypeState: { transportationTypes },
		getTransportationTypes
	} = useContext(TransportationTypeContext);

	const [pickupOpts, setPickupOpts] = useState([]);
	const [dropOffOpts, setDropOffOpts] = useState([]);
	const [showAlert, setShowAlert] = useState(false);
	const [textContent, setTextContent] = useState(null);
	const [isShown, setIsShown] = useState(false);
	const [customerTransport, setCustomerTransport] = useState(
		customers && customers[customerIndex] && customers[customerIndex].transport
			? customers[customerIndex].transport
			: { return: true, oneway: false, start_date }
	);

	useEffect(() => {
		const currentCustomerTransport =
			customers && customers[customerIndex] && customers[customerIndex].transport
				? customers[customerIndex].transport
				: { return: true, oneway: false, start_date };
		if (!currentCustomerTransport.transportation_type_price) currentCustomerTransport.transportation_type_price = 0;
		if (!currentCustomerTransport.transportation_route_price) currentCustomerTransport.transportation_route_price = 0;

		currentCustomerTransport.return =
			currentCustomerTransport.return || currentCustomerTransport.route_type === 'Return';
		currentCustomerTransport.oneway =
			currentCustomerTransport.oneway || currentCustomerTransport.route_type === 'Departure';
		currentCustomerTransport.price =
			currentCustomerTransport.price ||
			currentCustomerTransport.transportation_route_price + currentCustomerTransport.transportation_type_price;
		currentCustomerTransport.start_date = currentCustomerTransport.start_date
			? formatDate(currentCustomerTransport.start_date, 'YYYY-MM-DD')
			: start_date;
		calculateTransportAmount();
		const priceInfo = getTotalPrice(
			currentCustomerTransport.dropoff_id,
			currentCustomerTransport.transportation_type_id,
			currentCustomerTransport.return,
			currentCustomerTransport.pickup_id
		);

		setCustomerTransport({ ...currentCustomerTransport, price: priceInfo.total });
	}, [isShown]);

	const calculateTransportAmount = () => {
		const customerTransportAmount = customers.reduce((total, c) => {
			const cTranPrice = c.transport && c.transport.price > 0 ? c.transport.price : 0;

			return total + cTranPrice;
		}, 0);

		setCustomerTransportAmount(customerTransportAmount);
	};

	useEffect(() => {
		if (!transportationPickUps || !transportationPickUps.length) return;
		if (!transportationRoutes || !transportationRoutes.length) return;

		const pickupIds = transportationRoutes.map((item) => item.pickup_id);
		const dropoffIds = transportationRoutes.map((item) => item.dropoff_id);
		const pickups = transportationPickUps.filter((item) => {
			return pickupIds.includes(item.id) || dropoffIds.includes(item.id);
		});

		const pOpts = getOptions(pickups, ['label', 'value'], ['name', 'id']);
		setPickupOpts(pOpts);
		setDropOffOpts(pOpts);
	}, [transportationPickUps, transportationRoutes]);

	const [tranTypeOpts, setTranTypeOpts] = useState([]);

	useEffect(() => {
		const transTypes = transportationTypes
			.filter((tr) => tr.currency === currency)
			.map((item) => {
				return {
					label: `${item.name} | ${currency === 'usd' ? '$' + item.price : item.price + ' Đ'}`,
					value: item.id
				};
			});
		setTranTypeOpts(transTypes);
	}, [currency]);

	const getPickupOptions = (value) => {
		const pickupIds = transportationRoutes.map((item) => {
			if (item.pickup_id === value) {
				return item.dropoff_id;
			}

			if (item.dropoff_id === value) {
				return item.pickup_id;
			}
		});
		const pickups = transportationPickUps.filter((item) => {
			return pickupIds.includes(item.id);
		});
		return getOptions(pickups, ['label', 'value'], ['name', 'id']);
	};

	const onChangeText = (event) => {
		setCustomerTransport({
			...customerTransport,
			[event.target.name]: event.target.value
		});
	};

	const getTotalPrice = (dropoff_id, transportation_type_id, isReturn, pickup_id) => {
		const _pickupId = pickup_id ? pickup_id : customerTransport.pickup_id;
		const selectedRoute = getSelectedRoute(_pickupId, dropoff_id, currency);
		const selectedTransportType = transportationTypes.find((item) => item.id === transportation_type_id);
		const transportTypePrice = selectedTransportType ? selectedTransportType.price : 0;
		const selectedRoutePrice = selectedRoute ? selectedRoute.price : 0;
		const total = isReturn ? 2 * (selectedRoutePrice + transportTypePrice) : selectedRoutePrice + transportTypePrice;

		return { selectedRoute, selectedTransportType, total: total };
	};

	const handleChange = (data, event) => {
		if (!data) return;

		const name = event.name;
		const value = data.value;
		switch (name) {
			case 'pickup_id':
				const opts = getPickupOptions(value);
				setCustomerTransport({ ...customerTransport, pickup_id: value, price: 0, dropoff_id: '' });
				setDropOffOpts(opts);
				break;
			case 'dropoff_id':
				const priceInfo = getTotalPrice(value, customerTransport.transportation_type_id, customerTransport.return);
				setCustomerTransport({
					...customerTransport,
					dropoff_id: value,
					price: priceInfo.total,
					transportation_route_id: priceInfo.selectedRoute?.id,
					transportation_route_price: priceInfo.selectedRoute?.price
				});
				break;
			case 'type':
				const newPriceInfo = getTotalPrice(customerTransport.dropoff_id, value, customerTransport.return);
				setCustomerTransport({
					...customerTransport,
					transportation_type_id: value,
					transportation_type_price: newPriceInfo.selectedTransportType?.price,
					price: newPriceInfo.total
				});
				break;
			default:
		}
	};

	const onChangeCheckReturn = (event) => {
		const priceInfo = getTotalPrice(
			customerTransport.dropoff_id,
			customerTransport.transportation_type_id,
			event.target.checked
		);

		if (!customers[customerIndex].transport) customers[customerIndex].transport = {};
		if (event.target.checked === true) {
			customers[customerIndex].transport.route_type = 'Return';
			setCustomerTransport({
				...customerTransport,
				return: true,
				oneway: false,
				route_type: 'Return',
				price: priceInfo.total
			});
			return;
		}
		customers[customerIndex].transport.route_type = '';
		setCustomerTransport({ ...customerTransport, return: false, route_type: '', price: priceInfo.total });
	};

	const onChangeCheckOneway = (event) => {
		const priceInfo = getTotalPrice(customerTransport.dropoff_id, customerTransport.transportation_type_id, false);

		if (!customers[customerIndex].transport) customers[customerIndex].transport = {};
		if (event.target.checked === true) {
			customers[customerIndex].transport.route_type = 'Departure';
			setCustomerTransport({
				...customerTransport,
				oneway: true,
				return: false,
				price: priceInfo.total,
				route_type: 'Departure'
			});
			return;
		}
		customers[customerIndex].transport.route_type = '';
		setCustomerTransport({ ...customerTransport, oneway: false, price: priceInfo.total, route_type: '' });
	};

	const getSelectedRoute = (pickupId, dropoffId, currency) => {
		const selectedRoute = transportationRoutes.find((item) => {
			return (
				item.currency === currency &&
				((item.pickup_id === pickupId && item.dropoff_id === dropoffId) ||
					(item.pickup_id === dropoffId && item.dropoff_id === pickupId))
			);
		});

		return selectedRoute;
	};
	const AlertDismissible = () => {
		setTimeout(function () {
			setShowAlert(false);
		}, 3000);
		return (
			<Alert variant='danger'>
				{textContent !== null ? (
					<p>
						<strong>{textContent}</strong>
					</p>
				) : null}
			</Alert>
		);
	};

	const onToggleHandler = (isOpen, e, metadata) => {
		if (metadata.source != 'select') {
			setIsShown(isOpen);
		}
	};

	const btnOKhandleClick = (event) => {
		event.preventDefault();
		const isValid = validateFormValue();
		if (!isValid) return;

		if (!customers[customerIndex].transport) customers[customerIndex].transport = {};
		const transportObj = (({ id, ...o }) => o)(customerTransport);
		customers[customerIndex].transport = { id: customers[customerIndex].transport.id, ...transportObj };

		// calculate customer transport amount.
		calculateTransportAmount();
	};

	const validateFormValue = () => {
		if (!customerTransport.return && !customerTransport.oneway) {
			setIsShown(!isShown);
			return true;
		}

		if (!customerTransport.pickup_id) {
			setTextContent('Please choose Pick Up!');
			setShowAlert(true);
			return false;
		}
		if (!customerTransport.dropoff_id) {
			setTextContent('Please choose Drop-Off!');
			setShowAlert(true);
			return false;
		}

		if (!customerTransport.transportation_type_id) {
			setTextContent('Please Choose Transportation Type!');
			setShowAlert(true);
			return false;
		}
		setIsShown(!isShown);

		return true;
	};

	const appllyAllHandleClick = (event) => {
		event.preventDefault();
		validateFormValue();
		const transportObj = (({ id, ...o }) => o)(customerTransport);
		customers.forEach((_, index) => {
			if (isEmptyObj(transportObj)) {
				delete customers[index].transport;
			} else {
				const id = customers[index].transport ? customers[index].transport.id : null;
				customers[index].transport = { id: id, ...transportObj };
			}
		});

		// calculate customer transport amount.
		calculateTransportAmount();
	};

	const btnClearHandle = () => {
		setCustomerTransport({});
		customers[customerIndex].transport = {};
	};

	const { t } = useTranslation();

	return (
		<Fragment>
			<Dropdown
				show={isShown}
				className='info-customer'
				onToggle={(isOpen, e, metadata) => onToggleHandler(isOpen, e, metadata)}
			>
				<Dropdown.Toggle style={{ width: '100%' }} variant='success' id='dropdown-basic'>
					<i style={{ fontSize: '18px' }} className='fas fa-route'></i>
				</Dropdown.Toggle>
				<Form>
					<Dropdown.Menu className='content-button' id='content-button-route'>
						{showAlert ? <AlertDismissible /> : null}
						<div className='row mb-2'>
							<div className='col-sm'>
								<div className='checkbox'>
									<input
										className='route-control-input'
										id='return'
										type='checkbox'
										name='return'
										checked={customerTransport.return ? customerTransport.return : false}
										onChange={onChangeCheckReturn}
									/>
									<label htmlFor='return' className='route-control-label mt-1 ml-1'>
										<strong>{t('Return')}</strong>
									</label>
								</div>
							</div>
							<div className='col-sm'>
								<div className='checkbox'>
									<input
										className='route-control-input'
										id='oneway'
										type='checkbox'
										name='oneway'
										checked={customerTransport.oneway ? customerTransport.oneway : false}
										onChange={onChangeCheckOneway}
									/>
									<label htmlFor='oneway' className='route-control-label mt-1 ml-1'>
										<strong>{t('One-way')}</strong>
									</label>
								</div>
							</div>
							<div className='col-sm'>
								<Button
									className='btn btn-warning float-right'
									variant='warning'
									type='button'
									onClick={btnClearHandle}
								>
									<i className='fe fe-trash-2'></i>
								</Button>
							</div>
						</div>
						<div className='row'>
							<div className='col-sm'>
								<div className='form-group'>
									<label>
										<strong>{t('Departure')}</strong>
									</label>
									<Form.Control
										type='date'
										name='start_date'
										value={customerTransport.start_date}
										onChange={onChangeText}
										required
									/>
								</div>
							</div>
						</div>
						<div className='header-more'>
							<div className='form-group'>
								<div className='row'>
									<div className='col-sm-5'>
										<label>
											<strong>{t('From')}</strong>
										</label>
									</div>
									<div className='col-sm-7'>
										<div className='select'>
											<Select
												name='pickup_id'
												required
												placeholder={t('Choose Pick Up')}
												options={pickupOpts}
												onChange={handleChange.bind(this)}
												value={findOptionItem(pickupOpts, customerTransport.pickup_id)}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='form-group'>
								<div className='row'>
									<div className='col-sm-5'>
										<label>
											<strong>{t('To')}</strong>
										</label>
									</div>
									<div className='col-sm-7'>
										<Select
											name='dropoff_id'
											onChange={handleChange.bind(this)}
											required
											placeholder={t('Choose Drop Off')}
											options={dropOffOpts}
											value={findOptionItem(dropOffOpts, customerTransport.dropoff_id)}
										/>
									</div>
								</div>
							</div>
							<div className='form-group'>
								<div className='row'>
									<div className='col-sm-5'>
										<label>
											<strong>{t('Transportation Type')}</strong>
										</label>
									</div>
									<div className='col-sm-7'>
										<Select
											name='type'
											required
											onChange={handleChange.bind(this)}
											placeholder={t('Transportation Type')}
											options={tranTypeOpts}
											value={findOptionItem(tranTypeOpts, customerTransport.transportation_type_id)}
										/>
									</div>
								</div>
							</div>
							<div className='form-group'>
								<div className='row'>
									<div className='col-sm-5'>
										<label>
											<strong>{t('Price')}</strong>
										</label>
									</div>
									<div className='col-sm-7'>
										{currency === 'usd' ? (
											<NumberFormat
												className='form-control'
												id='price'
												name='price'
												value={customerTransport.price ? customerTransport.price : 0}
												thousandSeparator='.'
												decimalSeparator=','
												decimalScale={2}
												prefix={'$'}
												placeholder={t('Price')}
												readOnly
											/>
										) : (
											<NumberFormat
												className='form-control'
												id='price'
												name='price'
												value={customerTransport.price ? customerTransport.price : 0}
												thousandSeparator='.'
												decimalSeparator=','
												suffix={'VNĐ '}
												placeholder={t('Price')}
												readOnly
											/>
										)}
									</div>
								</div>
							</div>
							<div className='form-group'>
								<div className=''>
									<label>
										<strong>{t('Pickup Note')}</strong>
									</label>
									<textarea
										className='form-control'
										placeholder={t('Pickup Note')}
										rows='3'
										name='pickup_note'
										value={customerTransport.pickup_note}
										spellCheck='false'
										onChange={onChangeText}
									></textarea>
								</div>
							</div>
						</div>
						<div className='modal-end-item'>
							<Button className='btn btn-primary ' variant='success' type='success' onClick={appllyAllHandleClick}>
								{t('Apply All Customers')}
							</Button>
							<Button className='btn btn-primary' variant='primary' type='button' onClick={btnOKhandleClick}>
								<i className='fe fe-save'></i> {t('OK')}!!!
							</Button>
						</div>
					</Dropdown.Menu>
				</Form>
			</Dropdown>
		</Fragment>
	);
};

export default ItemCustomerTransportation;
