import { Fragment, useContext, useEffect, useState } from 'react';
import InfoDetailNightBooking from './InfoDetailNightBooking';
import { BookingContext } from '../../../contexts/BookingContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { ROLES, PERMISSIONS } from '../../../contexts/constants';
const BookingNightItem = (props) => {
	const {
		setShowAddBookingModal,
		showToast: { show, message, type },
		setShowToast,
		updateBooking
	} = useContext(BookingContext);
	const {
		authState: { user }
	} = useContext(AuthContext);
	const bookingRoomData = props.bookingRoomData;
	const cancelledLabel = props.cancelledLabel;
	const [windowSize, setWindowSize] = useState(window.innerWidth);

	const handleWindowSizeChange = () => {
		setWindowSize(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);
	const isMobile = windowSize <= 768;

	let clickCount = 0;
	let clickTimer = null;

	const cellClick = () => {
		if (isMobile) {
			clickCount++;
			clearTimeout(clickTimer);
			clickTimer = setTimeout(() => {
				clickCount = 0;
			}, 1000); // Đặt thời gian giới hạn 1 giây
			// khi click 2 lần
			if (clickCount === 2) {
				setAddBookingDefaultData({
					room_id: props.room.id,
					start_date: props.startDate,
					cruise: props.cruise
				});
				setShowAddBookingModal(true);
				clickCount = 0;
			}
			return;
		}

		// Xử lý cho trường hợp không phải trên thiết bị di động
		document.getElementById('root').scrollIntoView({ behavior: 'smooth' });
		setTimeout(function () {
			setAddBookingDefaultData({
				room_id: props.room.id,
				start_date: props.startDate,
				cruise: props.cruise
			});
			setShowAddBookingModal(true);
		}, 500);
	};

	const setAddBookingDefaultData = props.setAddBookingDefaultData;
	const customerBookings = props.bookingProps;
	const itineraries = props.itineraries;
	const dateSetting = props.dateSettingProps;
	const roomName = props.room.name ? props.room.name.substring(0, 10) + '...' : '';

	let body = null;
	let checkID = null;
	let idCustomerBooking = [];

	let bgBlack = '';

	if (dateSetting !== undefined) {
		bgBlack = 'day-black';
	}

	if (customerBookings === undefined) {
		body = (
			<td className={bgBlack}>
				{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
				user.Permissions.includes(PERMISSIONS.ADD_BOOKING) ? (
					<label className='disable-room-label hide-mobile' onClick={cellClick}>
						{roomName}
					</label>
				) : (
					<label className='disable-room-label hide-mobile'>{roomName}</label>
				)}
			</td>
		);
	} else if (customerBookings === null) {
		body = (
			<td className={bgBlack}>
				<label className='disable-room-label hide-mobile' onClick={cellClick}>
					{roomName}
				</label>
			</td>
		);
	} else {
		customerBookings.forEach((customer) => {
			if (customer.booking_id !== checkID) {
				idCustomerBooking.push(customer.booking_id);
				checkID = customer.booking_id;
			}
		});

		body = (
			<td className={bgBlack}>
				<Fragment>
					<div className='info-td-room'>
						{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
						user.Permissions.includes(PERMISSIONS.ADD_BOOKING) ? (
							<label className='disable-room-label hide-mobile' onClick={cellClick}>
								{roomName}
							</label>
						) : (
							<label className='disable-room-label hide-mobile'>{roomName}</label>
						)}
						<InfoDetailNightBooking
							setShowAddBookingModal={cellClick}
							setAddBookingDefaultData={setAddBookingDefaultData}
							customerProps={customerBookings}
							idBookingProps={idCustomerBooking}
							itineraries={itineraries}
							roomName={roomName}
							roomNameFull={props.room.name}
							bookingRoomData={bookingRoomData}
							cancelledLabel={cancelledLabel}
						/>
					</div>
				</Fragment>
			</td>
		);
	}

	return <Fragment>{body}</Fragment>;
};

export default BookingNightItem;
