import { Fragment } from 'react';
import DebtItem from './DebtItem';
import { useTranslation } from 'react-i18next';

const DebtTable = props => {
    const { t } = useTranslation();
    const partnerRevenues = props.bookingsProps
    return (
        <Fragment>
            <div className="table-responsive revenue-table">
                <div className="fullWidth100 widthFull">
                    <table id="debt-table" border="1" className="table table-bordered mg-b-0 text-md-nowrap">
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>{t('Agents')}</th>
                                <th>{t('Total Debts')} (VNĐ)</th>
                                <th>{t('Total Debts')} (USD)</th>
                                <th>{t('Paid')} (VNĐ)</th>
                                <th>{t('Paid')} (USD)</th>
                                <th>{t('Remain')} (VNĐ)</th>
                                <th>{t('Remain')} (USD)</th>
                                <th>{t('Commission')} (VNĐ)</th>
                                <th>{t('Commission')} (USD)</th>
                                <th>{t('Sales')}</th>
                                <th>{t('Paid (com)')}</th>
                                <th>{t('Debt Collection Date')}</th>
                                <th>{t('Note')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {partnerRevenues.map((partner, index) => (
                                <DebtItem key={index} sttProps={index+1} bookingsProps={partner} />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Fragment>
    )
}

export default DebtTable
