import { Fragment, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { CustomerTransportContext } from '../../contexts/CustomerTransportContext';
// import DeleteModal from '../../components/layout/DeleteModal';
import { Card, Form, Spinner } from 'react-bootstrap';
// import { PERMISSIONS, ROLES } from '../../contexts/constants';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { getTable, getTableOptions, getDefaultSort } from '../../utils/table';
import { getOptions, routeTypeOptions, findOptionItem } from '../../utils/common';
import { CruiseContext } from '../../contexts/CruiseContext';
import { TransportationPickUpContext } from '../../contexts/TransportationPickUpContext';
import { TransportationTypeContext } from '../../contexts/TransportationTypeContext';
import { ExportCustomerTransportXLSX } from '../../components/export/ExportCustomerTransportXLSX';
import { useTranslation } from 'react-i18next';

const ListCustomerTransport = ({ cruise, dateInfo }) => {
	const { t } = useTranslation();
	const routeTypeOptionsTran = routeTypeOptions.map((item) => {
		return { value: item.value, label: t(item.label) };
	});
	
	// Context
	const {
		authState: { user }
	} = useContext(AuthContext);

	const {
		cruiseState: { cruises },
		getCruises
	} = useContext(CruiseContext);
	const {
		transportationPickUpState: { transportationPickUps },
		getTransportationPickUps
	} = useContext(TransportationPickUpContext);
	const {
		transportationTypeState: { transportationTypes },
		getTransportationTypes
	} = useContext(TransportationTypeContext);

	useEffect(() => {
		if (!transportationTypes || !transportationTypes.length) {
			getTransportationTypes();
		}
	}, []);

	useEffect(() => {
		if (!transportationPickUps || !transportationPickUps.length) {
			getTransportationPickUps();
		}
	}, []);

	useEffect(() => {
		if (!cruises || !cruises.length) {
			getCruises();
		}
	}, []);

	const [filterData, setFilterData] = useState({
		cruise_id: cruise.id,
		start_date: dateInfo,
		pickup_id: 'all',
		dropoff_id: 'all',
		route_type_id: 'all',
		transportation_type_id: 'all'
	});

	const {
		customerTransportState: { customerTransport, customerTransports, customerTransportLoading },
		getcustomerTransports,
		findCustomerTransport,
		deleteCustomerTransport,
		setShowUpdateCustomerTransportModal,
		setShowToast
	} = useContext(CustomerTransportContext);

	const chooseCustomerTransport = (customerTransportId) => {
		findCustomerTransport(customerTransportId);
		setShowUpdateCustomerTransportModal(true);
	};

	const [optionCruises, setOptionCruises] = useState([]);
	const [pickupOptions, setPickupOptions] = useState([]);
	const [transportTypeOptions, setTransportTypeOptions] = useState([]);

	useEffect(() => {
		const cruiseOpts = getOptions(cruises, ['label', 'value', 'typeDay'], ['name', 'id', 'typeDay']);
		cruiseOpts.unshift({ label: t('All'), value: 'all' });
		setOptionCruises(cruiseOpts);
	}, [cruises]);

	useEffect(() => {
		const pickupOpts = getOptions(transportationPickUps, ['label', 'value'], ['name', 'id']);
		pickupOpts.unshift({ label: t('All'), value: 'all' });
		setPickupOptions(pickupOpts);
	}, [transportationPickUps]);

	useEffect(() => {
		const transportTypeOpts = getOptions(transportationTypes, ['label', 'value'], ['name', 'id']);
		transportTypeOpts.unshift({ label: t('All'), value: 'all' });
		setTransportTypeOptions(transportTypeOpts);
	}, [transportationPickUps]);

	useEffect(() => {
		const filter = async () => {
			let queryString = `&date=${filterData.start_date}`;
			let filterFieldValues = [];
			let filterFields = [];
			if (filterData.cruise_id !== 'all') {
				filterFields.push('ct.cruise_id');
				filterFieldValues.push(filterData.cruise_id);
			}
			if (filterData.route_type_id !== 'all') {
				filterFields.push('ct.route_type');
				const route_type = filterData.route_type_id === 'oneway' ? 'Departure' : filterData.route_type_id;
				filterFieldValues.push(route_type);
			}
			if (filterData.transportation_type_id !== 'all') {
				filterFields.push('ct.transportation_type_id');
				filterFieldValues.push(filterData.transportation_type_id);
			}

			if (filterFields.length > 0) {
				queryString +=
					'&filterFields=' + filterFields.join(',') + '&filterFieldValues=' + filterFieldValues.join(',');
			}
			if (filterData.pickup_id !== 'all') {
				// filterFields.push('ct.pickup_id');
				// filterFieldValues.push(filterData.pickup_id);
				queryString += `&pickup_id=${filterData.pickup_id}`;
			}
			if (filterData.dropoff_id !== 'all') {
				// filterFields.push('ct.dropoff_id');
				// filterFieldValues.push(filterData.dropoff_id);
				queryString += `&dropoff_id=${filterData.dropoff_id}`;
			}

			await getcustomerTransports(queryString);
		};
		filter();
	}, [filterData]);

	if (customerTransportLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info'></Spinner>
			</div>
		);
	}

	let data = getListTransportTable(
		customerTransports,
		user,
		chooseCustomerTransport,
		deleteCustomerTransport,
		setShowToast,
		t,
		filterData.start_date
	);

	if (!data) data = {};

	const customStyles = {
		menuPortal: (provided) => ({ ...provided, zIndex: 5 })
	};

	const applyFilter = (data, action) => {
		if (data && data.target) {
			setFilterData({ ...filterData, [data.target.name]: data.target.value });
			return;
		}

		if (data) {
			setFilterData({ ...filterData, [action.name]: data.value });
		}
	};

	const dataExport = prepareDataExport(data.tableData, t);

	return (
		<Fragment>
			<div className='row'>
				<div className='col-sm float-right'>
					<ExportCustomerTransportXLSX
						customerTransports={dataExport}
						fileName='list_customer_basic'
						label={t('Export to Excel')}
						dateInfo={dateInfo}
						classes='export-to-excel'
					/>
				</div>
			</div>
			<hr className='mt-3' />
			<div className='row'>
				<div className='col-md-1'>
					<h5>{t('Filter')}</h5>
				</div>
			</div>
			<div className='row'>
				<div className='col-md-2'>
					<div className='form-group'>
						<label>{t('Cruise')}</label>

						<Select
							isClearable
							name='cruise_id'
							onChange={applyFilter.bind(this)}
							options={optionCruises}
							placeholder={t('Choose Cruise')}
							value={findOptionItem(optionCruises, filterData.cruise_id)}
							menuPosition={'fixed'}
							styles={customStyles}
						/>
					</div>
				</div>
				<div className='col-md-2'>
					<div className='form-group'>
						<label>{t('Departure')}</label>
						<Form.Control
							type='date'
							name='start_date'
							value={filterData.start_date}
							onChange={applyFilter.bind(this)}
						/>
					</div>
				</div>
				<div className='col-md-2'>
					<div className='form-group'>
						<label>{t('Pickup')}</label>

						<Select
							isClearable
							name='pickup_id'
							onChange={applyFilter.bind(this)}
							options={pickupOptions}
							placeholder={t('Choose Pickup')}
							value={findOptionItem(pickupOptions, filterData.pickup_id)}
							menuPosition={'fixed'}
							styles={customStyles}
						/>
					</div>
				</div>
				<div className='col-md-2'>
					<div className='form-group'>
						<label>{t('Dropoff')}</label>

						<Select
							isClearable
							name='dropoff_id'
							onChange={applyFilter.bind(this)}
							options={pickupOptions}
							placeholder={t('Choose Dropoff')}
							value={findOptionItem(pickupOptions, filterData.dropoff_id)}
							menuPosition={'fixed'}
							styles={customStyles}
						/>
					</div>
				</div>
				<div className='col-md-2'>
					<div className='form-group'>
						<label>{t('Route Type')}</label>

						<Select
							isClearable
							name='route_type_id'
							onChange={applyFilter.bind(this)}
							options={routeTypeOptionsTran}
							placeholder={t('Choose Route Type')}
							value={findOptionItem(routeTypeOptionsTran, filterData.route_type_id)}
							menuPosition={'fixed'}
							styles={customStyles}
						/>
					</div>
				</div>
				<div className='col-md-2'>
					<div className='form-group'>
						<label>{t('Transportation Type')}</label>

						<Select
							isClearable
							name='transportation_type_id'
							onChange={applyFilter.bind(this)}
							options={transportTypeOptions}
							placeholder={t('Choose Trans Type')}
							value={findOptionItem(transportTypeOptions, filterData.transportation_type_id)}
							menuPosition={'fixed'}
							styles={customStyles}
						/>
					</div>
				</div>
			</div>
			{data.tableFormatted}
			{/* {customerTransport !== null && <UpdatecustomerTransportModal />} */}
			{/* {customerTransport !== null && <ShowcustomerTransportModal />} */}
		</Fragment>
	);
};

const getTableData = (customerTransports, user, chooseCustomerTransport, deleteCustomerTransport, setShowToast, t, start_date) => {
	return customerTransports.map((customerTransport, index) => {
		return {
			index: index + 1,
			id: customerTransport.id,
			booking_code: customerTransport.booking_code,
			customer_id: customerTransport.customer_id,
			customer_name: customerTransport.customer_name,
			transportation_type_id: customerTransport.transportation_type_id,
			transportation_type_name: customerTransport.transportation_type_name,
			transportation_route_id: customerTransport.transportation_route_id,
			route_type: t(customerTransport.route_type === 'Departure' ? 'Oneway' : customerTransport.route_type),
			transportation_route_price: customerTransport.transportation_route_price,
			pickup_id: customerTransport.pickup_id,
			pickup_name: start_date === customerTransport.start_date? customerTransport.pickup_name: customerTransport.dropoff_name,
			dropoff_id: customerTransport.dropoff_id,
			dropoff_name: start_date === customerTransport.start_date? customerTransport.dropoff_name: customerTransport.pickup_name,
			booking_id: customerTransport.booking_id,
			// start_date: customerTransport.start_date,
			// end_date: customerTransport.route_type !== 'Departure' ? customerTransport.end_date : '',
			pickup_note: customerTransport.pickup_note
		};
	});
};

const getColumns = (t) => {
	return [
		{
			dataField: 'index',
			text: 'STT',
			sort: true
		},
		{
			dataField: 'id',
			text: 'ID',
			hidden: true
		},
		{
			dataField: 'booking_code',
			text: t('Booking Code'),
			sort: true,
			formatter: (cell, row) => {
				return (
					<Link className='' to={`/bookings/show/${row.booking_id}`} title={cell} target='_blank'>
						{cell}
					</Link>
				);
			}
		},
		{
			dataField: 'customer_id',
			text: t('Customer ID'),
			sort: true
		},
		{
			dataField: 'customer_name',
			text: t('Customer'),
			sort: true
		},
		{
			dataField: 'transportation_type_id',
			text: t('Transportation Type ID'),
			hidden: true
		},
		{
			dataField: 'transportation_type_name',
			text: t('Transportation Type'),
			sort: true
		},
		{
			dataField: 'transportation_route_id',
			text: t('Transportation Route ID'),
			hidden: true
		},
		{
			dataField: 'route_type',
			text: t('Route Type'),
			sort: true
		},
		{
			dataField: 'transportation_route_price',
			text: t('Transportation Route Price'),
			hidden: true
		},
		{
			dataField: 'pickup_note',
			text: t('Pickup Note')
		},
		{
			dataField: 'pickup_id',
			text: t('Pickup ID'),
			hidden: true
		},
		{
			dataField: 'pickup_name',
			text: t('Pickup'),
			sort: true
		},
		{
			dataField: 'dropoff_id',
			text: t('Dropoff ID'),
			hidden: true
		},
		{
			dataField: 'dropoff_name',
			text: t('Dropoff'),
			sort: true
		},
		{
			dataField: 'booking_id',
			text: t('Booking ID'),
			hidden: true
		},
		// {
		// 	dataField: 'start_date',
		// 	text: t('Start Date'),
		// 	sort: true
		// },
		// {
		// 	dataField: 'end_date',
		// 	text: t('End Date'),
		// 	sort: true
		// }
	];
};

const getListTransportTable = (
	customerTransports,
	user,
	chooseCustomerTransport,
	deleteCustomerTransport,
	setShowToast,
	t,
	start_date
) => {
	if (!customerTransports || customerTransports.length === 0) return null;
	const tableData = getTableData(
		customerTransports,
		user,
		chooseCustomerTransport,
		deleteCustomerTransport,
		setShowToast,
		t,
		start_date
	);
	const columns = getColumns(t);
	const defaultSorted = getDefaultSort();
	const options = getTableOptions(tableData.length, t);
	const tableFormatted = getTable(tableData, columns, defaultSorted, options);

	return { tableData, tableFormatted };
};

const prepareDataExport = (data, t) => {
	if (!data || data.length === 0) return [];
	const exportData = data.map(object => ({ ...object }))

	exportData.forEach((item) => {
		delete item.id;
		delete item.transportation_type_id;
		delete item.transportation_route_id;
		delete item.transportation_route_price;
		delete item.pickup_id;
		delete item.dropoff_id;
		delete item.booking_id;
	});

	return exportData.map((item) => {
		const obj = {}
		obj['STT'] = item.index;
		obj[t('Booking Code')] = item.booking_code;
		obj[t('Customer ID')] = item.customer_id;
		obj[t('Customer Name')] = item.customer_name;
		obj[t('Transportation Type')] = item.transportation_type_name;
		obj[t('Route Type')] = t(item.route_type);
		obj[t('Pickup Note')] = item.pickup_note;
		obj[t('Pickup')] = item.pickup_name;
		obj[t('Drop-Off')] = item.dropoff_name;
		// obj[t('Start Date')] = item.start_date;
		// obj[t('End Date')] = item.end_date;

		return obj;
	});
};
export default ListCustomerTransport;
