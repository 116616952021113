import { Fragment } from 'react';
import TableDayCruise from './TableDayCruise';

const ListBookingDayTrip = (props) => {
	const setAddBookingDefaultData = props.setAddBookingDefaultData;
	const cruise = props.cruiseProps;
	const month = props.monthProps;
	const year = props.yearProps;

	let body = null;

	if (cruise) {
		body = (
			<Fragment>
				<div className='ajax-booking-cruise'>
					<div className='row'>
						<div className='col-xl-12'>
							<div className='table-info-cruise mg-b-20'>
								<div className='table-info-cruise-header pb-0'>
									<div className='d-flex justify-content-between'>
										<h3 className='table-info-cruise-title mg-b-15'>
											{cruise.name}
										</h3>
									</div>
								</div>
								<div className='table-info-cruise-body'>
									<div className='table-responsive'>
										<TableDayCruise
											cruiseProps={cruise}
											monthProps={month}
											yearProps={year}
											setAddBookingDefaultData={setAddBookingDefaultData}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	return <Fragment>{body}</Fragment>;
};

export default ListBookingDayTrip;
