import React, { Fragment, useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { CRUISE_TYPES } from '../../../../contexts/constants';
import { BookingCalculate } from './BookingCalculate';
import { useTranslation } from 'react-i18next';
import { CruiseSettingContext } from '../../../../contexts/CruiseSettingContext';

const InfoPrice = ({
	moneys,
	dataPrice,
	handleChangeMoney,
	onChangeNumber,
	onChangePrice,
	onChangeText,
	setNewBooking,
	newBooking,
	total,
	handleTotalRoomPrice,
	handleVat,
	onChangeCheckboxDefaultPrice,
	checkDayCruise,
	isCustomTotal,
	setIsCustomTotal
}) => {
	const {
		adults,
		children,
		children11,
		infants,
		extra_service,
		extra_note,
		vat,
		com_year,
		com_note,
		discount,
		discount_note,
		deposit,
		deposit_note,
		cruise_id
	} = newBooking;
	let defaultCheckVat = false;
	let clickCheck = 0;
	if (vat) {
		defaultCheckVat = true;
		clickCheck = 1;
	}

	const {
        cruiseSettingState: { cruiseSettings },
    } = useContext(CruiseSettingContext)

	const [cruiseSetting, setCruiseSetting] = useState({});

	const [moneyType, setMoneyType] = useState(dataPrice.money_type);
	const [extraService, setExtraService] = useState(false);
	const [vatPercentage, setVatPercentage] = useState(defaultCheckVat);
	const [checkVatClick, setCheckVatClick] = useState(clickCheck);

	useEffect(() => {
		if (dataPrice.money_type) {
			setMoneyType(dataPrice.money_type);
		}
		
	}, [dataPrice.money_type]);

	const moneyTypeChange = (newValue, actionMeta) => {
		const dataMoney = newValue;
		if (dataMoney) {
			setMoneyType(dataMoney.value);
			handleChangeMoney(dataMoney, actionMeta);
		}
	};
	useEffect(() => {
		const cruiseSetting = cruiseSettings? cruiseSettings.find((item) => {
			return item.cruise_id == cruise_id;
		}): {}
		setCruiseSetting(cruiseSetting);
	}, [cruise_id]);

	useEffect(() => {
		async function defaultText() {
			try {
				if (dataPrice && dataPrice.length === 0) {
					return;
				}

				let totalAmount = 0;

				if (dataPrice.discount > 0) {
					setNewBooking({ ...newBooking, discount: dataPrice.discount });
				}

				if (dataPrice.discount > 0) {
					setNewBooking({ ...newBooking, com_year: dataPrice.commission });
				}

				totalAmount = BookingCalculate(
					adults,
					dataPrice,
					discount,
					children,
					children11,
					infants,
					extra_service,
					totalAmount
				);
				handleTotalRoomPrice(totalAmount);
				if (vat >= 0) {
					handleVat(vat);
				}
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		defaultText();
	}, [dataPrice, adults, discount, children, children11, infants, extra_service, vatPercentage, vat, checkVatClick]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleExtraCheck = (event) => {
		setExtraService(event.target.checked);
	};

	const handleVATCheck = (event) => {
		if (event.target.checked === true) {
			if (vat) {
				handleVat(vat);
				setVatPercentage(true);
				setCheckVatClick(1);
			} else {
				setNewBooking({ ...newBooking, vat: 10 });
				handleVat(vat);
				setVatPercentage(true);
				setCheckVatClick(1);
			}
		} else {
			setNewBooking({ ...newBooking, vat: 0 });
			handleVat(0);
			setVatPercentage(false);
			setCheckVatClick(0);
		}
	};

	const onChangeNumberPercentage = (event) => {
		setNewBooking({ ...newBooking, vat: Number(event.target.value) });
		handleVat(Number(event.target.value));
		//setVatPercentage(true)
	};

	const setTotalChange = (event) => {
		if (event.target.checked === true) {
			setIsCustomTotal(true);
			return;
		}

		setIsCustomTotal(false);
	};

	const { t } = useTranslation();

	return (
		<Fragment>
			<div className='col-md-6'>
				<div className='form-group'>
					<div className='booking-prices'>
						<div className='form-group'>
							<div className='row'>
								<div className='col-sm'>
									<label>
										<strong>{t('Adult Price')}</strong>
									</label>
									<input
										type='number'
										className='form-control'
										name='adult_price'
										min='0'
										step='any'
										value={dataPrice.adult_price || 0}
										onChange={onChangePrice}
										required
									></input>
								</div>
								<div className='col-sm'>
									<label>
										<strong>{t('Price')} {cruiseSetting && cruiseSetting.child_label1? t(cruiseSetting.child_label1): t('Child (5-7)')}</strong>
									</label>
									<input
										type='number'
										className='form-control'
										name='child_price'
										min='0'
										step='any'
										value={dataPrice.child_price || 0}
										onChange={onChangePrice}
									></input>
								</div>
								<div className='col-sm'>
									<label>
										<strong>{t('Price')} {cruiseSetting && cruiseSetting.child_lable2? t(cruiseSetting.child_lable2): t('Child (8-11)')}</strong>
									</label>
									<input
										type='number'
										className='form-control'
										name='child_price_2'
										min='0'
										step='any'
										value={dataPrice.child_price_2 || 0}
										onChange={onChangePrice}
									></input>
								</div>
								<div className='col-sm'>
									<label>
										<strong>{t('Infant Price')}</strong>
									</label>
									<input
										type='number'
										className='form-control'
										name='infant_price'
										min='0'
										step='any'
										value={dataPrice.infant_price || 0}
										onChange={onChangePrice}
									></input>
								</div>
							</div>
						</div>
						<div className='form-group'>
							<div className='row'>
								{checkDayCruise === CRUISE_TYPES.NIGHT ? (
									<div className='col-sm'>
										<label>
											<strong>{t('Single Price')}</strong>
										</label>
										<input
											type='number'
											className='form-control'
											name='single_price'
											min='0'
											step='any'
											value={dataPrice.single_price || 0}
											onChange={onChangePrice}
										></input>
									</div>
								) : (
									''
								)}
								<div className='col-sm'>
									<label className='btn btn-primary' onClick={onChangeCheckboxDefaultPrice}>
									{t('Set Default Prices')}
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='form-group'>
					<div className='row'>
						<div className='col-sm'>
							<label>
								<strong>{t('Money Type')}</strong>
							</label>
							<Select
								isClearable
								value={moneys.find((m) => m.value === moneyType)}
								name='money_type'
								onChange={moneyTypeChange.bind(this)}
								options={moneys}
								placeholder={t('Money Type')}
								className={moneyType ? '' : 'item-error'}
								// isDisabled="true"
							/>
						</div>
						<div className='col-sm'>
							<label>
								<strong>{t('Commission')}</strong>
							</label>
							<input
								type='number'
								step='any'
								className='form-control'
								name='com_year'
								value={com_year || 0}
								placeholder={t('No. Commission')}
								onChange={onChangeNumber}
							/>
						</div>
					</div>
				</div>
				<div className='form-group'>
					<div className='row'>
						<div className='col-md-3'>
							<label>
								<strong>{t('Discount')}</strong>
							</label>
							<input
								type='number'
								step='any'
								className='form-control'
								name='discount'
								value={discount || 0}
								placeholder={t('No. Discount')}
								onChange={onChangeNumber}
							/>
						</div>
						<div className='col-md-3'>
							<label>
								<strong>{t('Note Discount')}</strong>
							</label>
							<textarea
								className='form-control'
								id='discount_note'
								name='discount_note'
								rows='1'
								placeholder={t('Note Discount')}
								value={discount_note}
								onChange={onChangeText}
							></textarea>
						</div>
						<div className='col-md-6'>
							<label>
								<strong>{t('Commission Note')}</strong>
							</label>
							<textarea
								className='form-control'
								id='com_note'
								name='com_note'
								rows='1'
								placeholder={t('Commission Note')}
								value={com_note}
								onChange={onChangeText}
							></textarea>
						</div>
					</div>
				</div>
				<div className='form-group'>
					<div className='row'>
						{/* <div className="col-sm">
                            <label><strong>Deposit</strong></label>
                            <input type='number' step="any" className="form-control" name="deposit" value={deposit} placeholder="No. Deposit" onChange={onChangeNumber} />
                        </div>
                        <div className="col-sm">
                            <label><strong>Note Deposit</strong></label>
                            <textarea className="form-control" id="deposit_note" name="deposit_note" rows="1" value={deposit_note} placeholder="Note Deposit" onChange={onChangeText}></textarea>
                        </div> */}
						<div className='col-sm'>
							<label>
								<strong>{t('Extra Service')}</strong>
							</label>
							<div className='checkbox'>
								<div className='custom-checkbox custom-control'>
									<input
										type='checkbox'
										className='custom-control-input'
										id='extraService-1'
										onChange={handleExtraCheck.bind(this)}
									/>
									<label htmlFor='extraService-1' className='custom-control-label mt-1'>
									{t('Extra Service')}
									</label>
								</div>
							</div>
						</div>
						<div className='col-sm'>
							<label>
								<strong>VAT</strong>
							</label>
							<div className='checkbox'>
								<div className='custom-checkbox custom-control'>
									<input
										type='checkbox'
										className='custom-control-input'
										id='vat-1'
										onChange={handleVATCheck.bind(this)}
										checked={checkVatClick > 0 ? `checked` : null}
									/>
									<label htmlFor='vat-1' className='custom-control-label mt-1'>
										VAT
									</label>
								</div>
							</div>
						</div>
						{vatPercentage ? (
							<div className='col-sm'>
								<label>
									<strong>{t('Percentage')} (10%)</strong>
								</label>
								<input
									className='form-control'
									id='vat'
									name='vat'
									value={vat}
									placeholder='default 10%'
									onChange={onChangeNumberPercentage}
								/>
							</div>
						) : (
							''
						)}
					</div>
				</div>
				{extraService === true ? (
					<div className='form-group'>
						<div className='row'>
							<div className='col-sm'>
								<label>
									<strong>{t('No. Extra Service')}</strong>
								</label>
								<input
									type='number'
									step='any'
									className='form-control'
									id='extra_service'
									name='extra_service'
									value={extra_service}
									placeholder='No. Money Extra Service'
									onChange={onChangeNumber}
								/>
							</div>
							<div className='col-sm'>
								<label>
									<strong>{t('Note Extra Service')}</strong>
								</label>
								<textarea
									className='form-control'
									id='extra_note'
									name='extra_note'
									rows='1'
									value={extra_note}
									placeholder={t('Note Extra Service')}
									onChange={onChangeText}
								></textarea>
							</div>
						</div>
					</div>
				) : (
					''
				)}
				<div className='form-group'>
					<div className='row'>
						<div className='col-sm'>
							<div className='checkbox'>
								<div className='custom-checkbox custom-control'>
									<input
										type='checkbox'
										className='custom-control-input'
										id='total-custom'
										onChange={setTotalChange.bind(this)}
									/>
									<label htmlFor='total-custom' className='custom-control-label mt-1'>
									{t('Set Total')}
									</label>
								</div>
							</div>
							<label>
								<strong>{t('Total')}</strong>
							</label>
							<input
								className='form-control'
								id='total'
								name='total'
								value={total}
								placeholder={t('No. Total')}
								onChange={onChangeNumber}
								readOnly={!isCustomTotal}
							/>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default InfoPrice;
