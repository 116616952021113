import { GUIDES_LOADED_SUCCESS, GUIDES_LOADED_FAIL, ADD_GUIDE, FIND_GUIDE, UPDATE_GUIDE, DELETE_GUIDE } from '../contexts/constants';

export const guideReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case GUIDES_LOADED_SUCCESS:
            return {
                ...state,
                guides: payload,
                guidesLoading: false
            }
        case GUIDES_LOADED_FAIL:
            return {
                ...state,
                guides: [],
                guidesLoading: false
            }
        case ADD_GUIDE:
            return {
                ...state,
                guides: [...state.guides, payload],
            }
        case FIND_GUIDE:
            return {
                ...state,
                guide: payload
            }
        case UPDATE_GUIDE:
            const newGuide = state.guides.map(guide => {
                if (guide.id === payload.id) {
                    return payload
                } else {
                    return guide
                }
            })
            return {
                ...state,
                guides: newGuide
            }
        case DELETE_GUIDE:
            return {
                ...state,
                guides: state.guides.filter(guide => guide.id !== payload)
            }
        default:
            return state
    }
}