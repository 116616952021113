import { Fragment, useContext, useEffect } from 'react'
import { SettingContext } from '../../../contexts/SettingContext'

const ListSetting = () => {

    const { 
        settingState: { settings },
        getSettings
    } = useContext(SettingContext)

    useEffect( () => getSettings(''), []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fragment>
            <div className="ajax-booking-cruise">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="table-info-cruise mg-b-20">
                            <div className="table-info-cruise-body">
                                <div className="table-responsive">
                                    <table className="table mg-b-0 text-md-nowrap">
                                        <thead>
                                            <tr>
                                                <th>STT</th>
                                                <th>Cruise Name</th>
                                                <th>Date</th>
                                                <th className="w-220"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {settings.map((setting, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ListSetting
