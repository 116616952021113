import { useState, useContext } from 'react';
import { RoomTypeContext } from '../../../contexts/RoomTypeContext';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const AddRoomModal = () => {
    const { t } = useTranslation();
    // Context
    const {
        addRoomType,
        showAddRoomTypeModal,
        setShowAddRoomTypeModal,
        setShowToast
    } = useContext(RoomTypeContext)

    // State
    const [newRoomType, setNewRoomType] = useState({
        name: '',
        description: '',
    })

    const {name, description} = newRoomType

    const onChangeNewRoomTypeForm = event => setNewRoomType( {...newRoomType, [event.target.name]: event.target.value } )



    const onSubmit = async event => {
        event.preventDefault()
        const response = await addRoomType(newRoomType)
        if (response.data) {
            closeDialog()
            setShowToast({show: true, message: t('Added sucessful!'), type: 'success'})
            return
        }
        setShowToast({show: true, message: response.error && response.error.ErrorCode? response.error.ErrorCode: t('Add failed!'), type: 'danger'})
    }

    const closeDialog = () => {
        resetAddRoomData()
    }

    const resetAddRoomData = () => {
        setNewRoomType({
            name: '',
            description: '',
        })
        setShowAddRoomTypeModal(false)
    }


    // Start: Get all cruise , []

    return (
        <Modal show={showAddRoomTypeModal} onHide={closeDialog}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('Create New Room Type')}
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit}>
                <Modal.Body>
                    <div className="">
                        <div className="col-sm">
                            <div className="form-group">
                                <label>{t('Name')}</label>
                                <input type="text" className="form-control" id="nameCruise" name="name" value={name} placeholder={t("Enter Room Type Name")} required aria-describedby='name-help' onChange={onChangeNewRoomTypeForm} />
                                <Form.Text id='name-help' muted>{t('Required')}</Form.Text>
                            </div>
                        </div>
                        <div className="">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>{t('Description')}</label>
                                    <textarea className="form-control" placeholder={t("Description")} rows="3" name='description' defaultValue={description} spellCheck="false" onChange={onChangeNewRoomTypeForm}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> {t('Cancel')}</Button>
                    <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> {t('Save')}!!!</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default AddRoomModal
