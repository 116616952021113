import { Fragment, useContext } from "react";
import { Helmet } from "react-helmet";
import { CarContext } from '../../contexts/CarContext';
import {Toast} from 'react-bootstrap';
import ListCars from "./ListCars";
import AddCarModal from './AddCarModal';
import { AuthContext } from '../../contexts/AuthContext';
import { ROLES, PERMISSIONS, NO_PERMISSION } from '../../contexts/constants';
import { useTranslation } from "react-i18next";

const Car = () => {
    const { t } = useTranslation();
    const {
        setShowAddCarModal,
        showToast: {show, message, type},
        setShowToast
    } = useContext(CarContext)

    const {
        authState: {user }
    } = useContext(AuthContext)

    if (!user) {
        return NO_PERMISSION
    }

    if (!(user.Permissions.includes(PERMISSIONS.GET_CAR) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))) {
        return NO_PERMISSION
    }
    return (
        <Fragment>
            <Helmet>
                <title>{t('Car List - Cruises Management Software')}</title>
            </Helmet>
            <div className="horizontal-mainwrapper container clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-3">
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <h3 className="pull-left">{t('Car List')}</h3>
                            </div>
                            <div className="col-md-2 ml-auto">
                            {(user.Permissions.includes(PERMISSIONS.INSERT_CAR) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))? <button className="right-content btn btn-success btn-block" onClick={setShowAddCarModal.bind(this, true)}><i className="fe fe-plus-circle"></i> {t('Add Car')}</button>: null }
                            </div>
                        </div>
                        <hr className="mt-3" />
                        <ListCars />
                        <AddCarModal />
                        <Toast
                            show={show}
                            style={ {position: 'fixed', top: '20%', right: '10px'} }
                            className={`bg-${type} text-white`}
                            onClose={setShowToast.bind(this, {show: false, message: '', type: null})}
                            delay={3000}
                            autohide
                        >
                            <Toast.Body>
                                <strong style={ {color: '#ffffff'} }>{message}</strong>
                            </Toast.Body>
                        </Toast>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Car
