import { createContext, useReducer, useState } from 'react';
import { transportationRouteReducer } from '../reducers/transportationRouteReducer';
import {
	apiUrl,
	TRANSPORTATION_ROUTES_LOADED_SUCCESS,
	TRANSPORTATION_ROUTES_LOADED_FAIL,
	ADD_TRANSPORTATION_ROUTE,
	FIND_TRANSPORTATION_ROUTE,
	UPDATE_TRANSPORTATION_ROUTE,
	DELETE_TRANSPORTATION_ROUTE
} from './constants';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';

export const TransportationRouteContext = createContext();

const TransportationRouteContextProvider = ({ children }) => {
	// State
	const [transportationRouteState, dispatch] = useReducer(transportationRouteReducer, {
		transportationRoute: null,
		transportationRoutes: [],
		transportationRoutesLoading: true
	});

	const [showAddTransportationRouteModal, setShowAddTransportationRouteModal] = useState(false);
	const [showUpdateTransportationRouteModal, setShowUpdateTransportationRouteModal] = useState(false);
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null
	});

	// Get all transportation routes
	const getTransportationRoutes = async () => {
		const response = await getAPI(`${apiUrl}/transportation-routes?oderBy=create_at&direction=desc`);
		if (response.data) {
			dispatch({ type: TRANSPORTATION_ROUTES_LOADED_SUCCESS, payload: response.data });
			return response;
		}

		dispatch({ type: TRANSPORTATION_ROUTES_LOADED_FAIL });
		return response;
	};

	// Add transportation route
	const addTransportationRoute = async (newTransportationRoute) => {
		const response = await postAPI(`${apiUrl}/transportation-route/insert`, newTransportationRoute);
		if (response.data) {
			dispatch({
				type: ADD_TRANSPORTATION_ROUTE,
				payload: response.data
			});
		}

		return response;
	};

	// Find transportation route when user is updating TransportationRoute
	const findTransportationRoute = (transportationRouteId) => {
		const transportationRoute = transportationRouteState.transportationRoutes.find(
			(item) => item.id === transportationRouteId
		);
		dispatch({
			type: FIND_TRANSPORTATION_ROUTE,
			payload: transportationRoute
		});
	};

	// Update transportation route
	const updateTransportationRoute = async (updateTransportationRoute) => {
		const response = await postAPI(`${apiUrl}/transportation-route/update`, updateTransportationRoute);
		if (response.status === 200) {
			dispatch({
				type: UPDATE_TRANSPORTATION_ROUTE,
				payload: updateTransportationRoute
			});
		}
		return response;
	};

	// Delete transportation route
	const deleteTransportationRoute = async (transportationRouteId) => {
		const response = await getDeleteAPI(`${apiUrl}/transportation-route/${transportationRouteId}/delete`);
		if (response.status === 200) {
			dispatch({
				type: DELETE_TRANSPORTATION_ROUTE,
				payload: transportationRouteId
			});
		}
		return response;
	};

	const clearTransportationRoute = () => {
		dispatch({
			type: FIND_TRANSPORTATION_ROUTE,
			payload: {}
		});
	};

	const transportationRouteContextData = {
		transportationRouteState,
		getTransportationRoutes,
		addTransportationRoute,
		findTransportationRoute,
		updateTransportationRoute,
		deleteTransportationRoute,
		showAddTransportationRouteModal,
		setShowAddTransportationRouteModal,
		showUpdateTransportationRouteModal,
		setShowUpdateTransportationRouteModal,
		showToast,
		setShowToast,
		clearTransportationRoute
	};

	return (
		<TransportationRouteContext.Provider value={transportationRouteContextData}>
			{children}
		</TransportationRouteContext.Provider>
	);
};

export default TransportationRouteContextProvider;
