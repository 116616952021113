import React, { Fragment, useState, useEffect, useContext } from "react"
import {Modal, Button, Form} from 'react-bootstrap';
import { CruiseSettingContext } from "../../contexts/CruiseSettingContext";
import { useTranslation } from "react-i18next";

const DeleteCustomerModal = ( {id, adults, children11, children, infants, setUpdatedBooking, updatedBooking, customers, setCustomers, index } ) => {

    const [showDelete, setShowDelete] = useState(false)
    const [nameChoose, setNameChoose] = useState(null)
    const handleClose = () => setShowDelete(false)
    const handleShow = () => setShowDelete(true)

    const {
        cruiseSettingState: { cruiseSettings },
    } = useContext(CruiseSettingContext)

	const [cruiseSetting, setCruiseSetting] = useState({});

	useEffect(() => {
		const cruiseSetting = cruiseSettings? cruiseSettings.find((item) => {
			return item.cruise_id == updatedBooking.cruise_id;
		}): {}
		setCruiseSetting(cruiseSetting);
	}, [updatedBooking.cruise_id]);

    useEffect(() => {
        if((children11 === undefined || children11 === 0) && (children === undefined || children === 0) && (infants === undefined || infants === 0)){
            setNameChoose('adult')
        }
        //setPriceServiceDefault(totalItem)
    }, [children11, children, infants]) // eslint-disable-line react-hooks/exhaustive-deps

    const { t } = useTranslation();
    const deleteModal = async (id, index) => {
        let numberDem = 0
        
        if (customers[index]) {
            customers.splice(index, 1);
            setCustomers(customers)
        }
        
        if(nameChoose === 'adult'){
            numberDem = adults - 1;
            setUpdatedBooking({...updatedBooking, adults: numberDem})
        }
        if(nameChoose === 'child11'){
            numberDem = children11 - 1;
            setUpdatedBooking({...updatedBooking, children11: numberDem})
        }
        if(nameChoose === 'child7'){
            numberDem = children - 1;
            setUpdatedBooking({...updatedBooking, children: numberDem})
        }
        if(nameChoose === 'infants'){
            numberDem = infants - 1;
            setUpdatedBooking({...updatedBooking, infants: numberDem})
        }       
    }

    const deleteConfirm = (id, index) => {
        deleteModal(id, index)
        handleClose()
    }

    const onChangeCustomerDelete = event => {
        //event.preventDefault()
        const fieldName = event.target.value;
        setNameChoose(fieldName)
    }



    return (
        <Fragment>
            <Button className="btn btn-danger btn-with-icon" variant="primary" onClick={handleShow}>
                <i className="fe fe-trash-2"></i>
            </Button>
            <Modal show={showDelete} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{marginBottom: 0}}>Are you sure you want to delete this?</p>
                    {(children11 > 0) || (children > 0) || (infants > 0) ? (
                        <>
                            <p style={{marginBottom: 0}}>Please choose the object you want to delete!!!</p>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <div className="list-customer-delete">
                                        <Form.Check
                                            type="radio"
                                            name={`customerDelete`}
                                            id="formHorizontalRadiosAdults"
                                            value={`adult`}
                                            onChange={onChangeCustomerDelete.bind(this)}
                                            label={`Adults`}
                                        />
                                        {children11 !== undefined && children11 > 0 ? (
                                        <Form.Check
                                            type="radio"
                                            name={`customerDelete`}
                                            id="formHorizontalRadiosChild11"
                                            value={`child11`}
                                            onChange={onChangeCustomerDelete.bind(this)}
                                            label={cruiseSetting && cruiseSetting.child_lable2? t(cruiseSetting.child_lable2): t('Child (8-11)')}
                                        />
                                        ) : null }

                                        {children !== undefined && children > 0 ? (
                                        <Form.Check
                                            type="radio"
                                            name={`customerDelete`}
                                            id="formHorizontalRadiosChild7"
                                            value={`child7`}
                                            onChange={onChangeCustomerDelete.bind(this)}
                                            label={cruiseSetting && cruiseSetting.child_label1? t(cruiseSetting.child_label1): t('Child (5-7)')}
                                        />
                                        ) : null }

                                        {infants !== undefined && infants > 0 ? (
                                        <Form.Check
                                            type="radio"
                                            name={`customerDelete`}
                                            id="formHorizontalRadiosInfants"
                                            value={`infants`}
                                            onChange={onChangeCustomerDelete.bind(this)}
                                            label={`Infants (< 5)`}
                                        />
                                        ) : null }
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-secondary btn-with-icon" variant="secondary" onClick={handleClose}>
                        <i className="fe fe-x-circle"></i> Cancel
                    </Button>
                    <Button className="btn btn-danger btn-with-icon" disabled={nameChoose !== null ? false : true } variant="primary" onClick={deleteConfirm.bind(this, id, index)} >
                        <i className="fe fe-trash-2"></i> Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default DeleteCustomerModal
