import moment from 'moment';
import React from 'react';
import Button from 'react-bootstrap/Button';
import { handleExportCustomerRoom } from './HandleExportCustomerRoom';
import { handleExportCustomerForMaritimeAdministration } from './HandleExportCustomerForMaritimeAdministration';
import { handleExportCustomerBasic } from './HandleExportCustomerBasic';

const createDownLoadData = ({
	exportCustomerBasicData,
	exportCustomerRoomData,
	fileName,
	dateInfo,
	customerQuantity,
	exportForMaritimeAdministrationData
}) => {
	const dateFormatted = moment(dateInfo).format('DD/MM/YYYY');
	if (exportCustomerRoomData && exportCustomerRoomData.length > 0) {
		handleExportCustomerRoom(exportCustomerRoomData, dateFormatted, customerQuantity).then((url) => {
			const downloadAnchorNode = document.createElement('a');
			downloadAnchorNode.setAttribute('href', url);
			downloadAnchorNode.setAttribute('download', `danh-sach-phong-${dateFormatted}.xlsx`);
			downloadAnchorNode.click();
			downloadAnchorNode.remove();
		});
		return;
	}

	if (exportCustomerBasicData && exportCustomerBasicData.length > 0) {
		handleExportCustomerBasic(exportCustomerBasicData, dateFormatted, customerQuantity).then((url) => {
			const downloadAnchorNode = document.createElement('a');
			downloadAnchorNode.setAttribute('href', url);
			downloadAnchorNode.setAttribute('download', `customer-basic-${dateFormatted}.xlsx`);
			downloadAnchorNode.click();
			downloadAnchorNode.remove();
		});
		return;
	}

	handleExportCustomerForMaritimeAdministration(exportForMaritimeAdministrationData, dateInfo, customerQuantity).then(
		(url) => {
			const downloadAnchorNode = document.createElement('a');
			downloadAnchorNode.setAttribute('href', url);
			downloadAnchorNode.setAttribute('download', `lenh-cang-vu-${dateFormatted}.xlsx`);
			downloadAnchorNode.click();
			downloadAnchorNode.remove();
		}
	);
};

export const ExportCustomerListXLSX = ({
	exportCustomerBasicData,
	exportCustomerRoomData,
	fileName,
	label,
	dateInfo,
	customerQuantity,
	exportForMaritimeAdministrationData,
	classes
}) => {
	return (
		<Button
			className={`btn btn-primary float-right ${classes}`}
			// variant='warning'
			onClick={() => {
				createDownLoadData({
					exportCustomerBasicData,
					exportCustomerRoomData,
					fileName,
					dateInfo,
					customerQuantity,
					exportForMaritimeAdministrationData
				});
			}}
		>
			{label}
		</Button>
	);
};
