import { Fragment, useEffect, useContext } from 'react';
import { SettingContext } from '../../../contexts/SettingContext';
import OperatingUpdateSetting from './OperatingUpdateSetting';
import OperatingAddSetting from './OperatingAddSetting';
import { useTranslation } from 'react-i18next';


const OperatingSetting = props => {
    const { t } = useTranslation();
    const cruiseId = props.cruiseIdProps
    const dateSetting = props.dateProps
    const textCheck = props.textCheckProps

    const {
        settingState: { settings },
        getSettings,
    } = useContext(SettingContext)

    useEffect(() => getSettings(`?filterFields=cruise_id,day_setting&filterFieldValues=${cruiseId},${dateSetting}`), []) // eslint-disable-line react-hooks/exhaustive-deps 

    return (
        <Fragment>
            {textCheck === undefined ? (
            <div className="operating-setting mt-3">
                <h3>{t('Operating Settings')}</h3>
                <hr className="mt-3 mb-3" />
                {settings.length === 1 ? (
                    <OperatingUpdateSetting settingProps={settings[0]} settingDateProps={dateSetting} />
                ) : (
                    <OperatingAddSetting settingDateProps={dateSetting} cruiseIdProps={cruiseId} />
                )}
            </div>
            ): ('')}
        </Fragment>
    )
}

export default OperatingSetting
