import { useContext, useEffect, useState, useRef } from 'react';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import { BookingContext } from '../../../contexts/BookingContext';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { PartnerContext } from '../../../contexts/PartnerContext';
import { CruiseContext } from '../../../contexts/CruiseContext';
import { CountryContext } from '../../../contexts/CountryContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { PickupContext } from '../../../contexts/PickupContext';
import Select from 'react-select';
import { BOOKING_STATUSES, CRUISE_TYPES, apiUrl } from '../../../contexts/constants';
import InfoPrice from './subAdds/InfoPrice';
import ItemInforCustomer from './subAdds/ItemInforCustomer';
import { getAPI } from '../../../utils/api';
import { convertStringToDateISO, findOptionItem, getCruiseTripType, isSpecialDate } from '../../../utils/common';
import { TransportationPickUpContext } from '../../../contexts/TransportationPickUpContext';
import { TransportationRouteContext } from '../../../contexts/TransportationRouteContext';
import { TransportationTypeContext } from '../../../contexts/TransportationTypeContext';
import { CruiseSettingContext } from '../../../contexts/CruiseSettingContext';
import { useTranslation } from 'react-i18next';

let isFirstLoadingPrice = true;

const AddBookingModal = ({ checkDayCruise, defaultData, setAddBookingDefaultData }) => {
	const { t } = useTranslation();
	const [tripType, setTripType] = useState(checkDayCruise);
	const {
		transportationRouteState: { transportationRoutes },
		getTransportationRoutes
	} = useContext(TransportationRouteContext);

	const {
		transportationPickUpState: { transportationPickUps },
		getTransportationPickUps
	} = useContext(TransportationPickUpContext);

	const {
		transportationTypeState: { transportationTypes },
		getTransportationTypes
	} = useContext(TransportationTypeContext);

	const {
		cruiseSettingState: { cruiseSettings },
		getCruiseSettings
	} = useContext(CruiseSettingContext);

	useEffect(() => {
		if (cruiseSettings && cruiseSettings.length > 0) return;
		getCruiseSettings('');
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!transportationRoutes || !transportationRoutes.length) getTransportationRoutes();
		if (!transportationPickUps || !transportationPickUps.length) getTransportationPickUps();
		if (!transportationTypes || !transportationTypes.length) getTransportationTypes();
	}, []);

	const {
		bookingState: { bookings },
		setBookingsData
	} = useContext(BookingContext);

	const {
		authState: { user }
	} = useContext(AuthContext);

	const {
		countryState: { countries },
		getCountries
	} = useContext(CountryContext);

	const {
		pickupsState: { pickups },
		getPickups
	} = useContext(PickupContext);

	const [showPrice, setShowPrice] = useState(false);
	const onChangeCheckboxShowPrice = (event) => {
		setShowPrice(event.target.checked);
	};

	// Context
	const { addBooking, showAddBookingModal, setShowAddBookingModal, setShowToast } = useContext(BookingContext);
	
	// State
	const [newBooking, setNewBooking] = useState({
		booking_code: '',
		start_date: defaultData ? defaultData.start_date : '',
		hold_date: '',
		hold_time: '',
		message: '',
		adults: 0,
		children: 0,
		children11: 0,
		infants: 0,
		duration: 0,
		sale_note: '',
		pickup: '',
		acc_note: '',
		opt_note: '',
		extra_service: 0,
		extra_note: '',
		vat: 0,
		cabin_desc: '',
		status: '',
		com_year: 0,
		com_note: '',
		discount: 0,
		discount_note: '',
		money_type: '',
		single_price: 0,
		premium_price: 0,
		premium_price_sig: 0,
		deposit: 0,
		deposit_note: '',
		total: 0,
		assign_to: '',
		user_id: Number(user.id),
		cruise_id: defaultData && defaultData.cruise ? defaultData.cruise.id : '',
		partner_id: '',
		transportation_amount: 0,
		time_for_deposite: '',
		time_for_payment: ''
	});
	const {
		booking_code,
		start_date,
		hold_date,
		hold_time,
		message,
		sale_note,
		pickup,
		acc_note,
		opt_note,
		cabin_desc,
		total,
		time_for_deposite,
		time_for_payment
	} = newBooking;

	const onChangeText = (event) =>
		setNewBooking({
			...newBooking,
			[event.target.name]: event.target.value
		});
	const onChangeNumber = (event) =>
		setNewBooking({
			...newBooking,
			[event.target.name]: Number(event.target.value)
		});
	const onChangeNumberFloat = (event) =>
		setNewBooking({
			...newBooking,
			[event.target.name]: parseFloat(event.target.value).toFixed(2)
		});

	const [customers, setCustomers] = useState([]);

	const onChangeCustomerText = (index, event) => {
		if (!customers[index]) {
			customers[index] = {};
		}
		const fieldNames = event.target.name.split('-');
		const fieldName = fieldNames[0];
		customers[index][fieldName] = event.target.value;
	};

	const onChangeCustomerNumber = (index, event) => {
		if (!customers[index]) {
			customers[index] = {};
		}
		const fieldNames = event.target.name.split('-');
		const fieldName = fieldNames[0];
		customers[index][fieldName] = Number(event.target.value);
	};

	const {
		partnerState: { partners },
		getPartners
	} = useContext(PartnerContext);

	// Context
	const {
		cruiseState: { cruises }
	} = useContext(CruiseContext);

	const [cruiseSetting, setCruiseSetting] = useState({});

	useEffect(() => {
		const cruiseSetting = cruiseSettings? cruiseSettings.find((item) => {
			return item.cruise_id == newBooking.cruise_id;
		}): {}
		setCruiseSetting(cruiseSetting);
	}, [newBooking.cruise_id]);

	const [cruiseID, setCruiseID] = useState(null);
	const [assignTo, setAssignTo] = useState(0);
	const [partnerID, setPartnerID] = useState(0);
	const [arrGroupPrice, setArrGroupPrice] = useState('');
	const [dataPrice, setDataPrice] = useState([]);
	const [nameSale, setNameSale] = useState('');
	const [pending, setPending] = useState(false);
	const [statusCustomer, setStatusCustomer] = useState(false);
	const [bookingDetail, setBookingDetail] = useState(false);
	const [itineraries, setItineraries] = useState([]);
	const [chooseDuration, setChooseDuration] = useState(0);
	const [newDuration, setNewDuration] = useState(0);
	const [priceBooking, setPriceBooking] = useState(false);
	const [specialCabins, setSpecialCabins] = useState([]);
	// vat and commission note
	const [commissionNote, setCommissionNote] = useState(null);
	const [partnerDescription, setPartnerDescription] = useState(null);
	const [availableRooms, setAvailableRooms] = useState(null);
	const [isCheckRoom, setIsCheckRoom] = useState(true);

	// useEffect(() => {
	// 	if (defaultData && defaultData.room_id) {
	// 		setIsCheckRoom(false);
	// 	}
	// }, [defaultData]);

	const options = [
		{ value: 'Confirmed', label: t('Confirmed') },
		{ value: 'Pending', label: t('Pending') },
		{ value: 'Canceled', label: t('Canceled') },
		{ value: 'Transferred', label: t('Transferred') },
		{ value: 'Inspection', label: t('Inspection') },
		{ value: 'Charter', label: t('Charter') },
	];

	const genders = [
		{ value: 'male', label: 'Male' },
		{ value: 'female', label: 'Female' }
	];

	const mrCustoms = [
		{ value: 'Mr', label: 'Mr' },
		{ value: 'Mrs', label: 'Mrs' },
		{ value: 'Ms', label: 'Ms' },
		{ value: 'Dr', label: 'Dr' }
	];

	const moneys = [
		{ value: 'usd', label: 'USD' },
		{ value: 'vnd', label: 'VNĐ' }
	];

	let optionPartners = [];
	let optionCountries = [];
	let optionItineraries = [];
	let numberArray = [];
	let optionPickups = [];
	const [optionSpecials, setOptionSpecials] = useState([]);

	const optionCruises = useRef({});

	// Start: Get all cruise , []
	useEffect(() => getPickups(), []); // eslint-disable-line react-hooks/exhaustive-deps

	// Start Get all countries , []
	useEffect(() => {
		if (!countries || !countries.length) getCountries();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		async function getDataOldCustomers() {
			try {
				if (tripType !== CRUISE_TYPES.NIGHT || cruiseID <= 0 || !start_date || chooseDuration <= 0) {
					return;
				}

				const response = await getAPI(
					`${apiUrl}/rooms/available`,
					`start_date=${start_date}&cruise_id=${cruiseID}&cruise_itinerary_id=${chooseDuration}`
				);

				if (response.data) {
					setAvailableRooms(
						response.data.map((room) => {
							return {
								value: room.id,
								label: room.name,
								roomTypeID: room.room_type_id
							};
						})
					);
				}
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		getDataOldCustomers();
		onChangeCheckboxDefaultPrice();
	}, [cruiseID, start_date, chooseDuration]); // eslint-disable-line react-hooks/exhaustive-deps

	// Start Get all cruise , []
	useEffect(() => {
		if (cruises && cruises.length > 0) {
			optionCruises.current = cruises.map((cruise) => {
				return {
					value: cruise.id,
					label: cruise.name,
					typeDay: cruise.typeDay
				};
			});
		}
	}, [cruises]); // eslint-disable-line react-hooks/exhaustive-deps

	Array(100)
		.fill(1)
		.map(
			(option, index) =>
				(numberArray = [
					...numberArray,
					{
						value: index + 1,
						label: index + 1 + t(' Pax')
					}
				])
		);

	// Start: Get all partner , []
	useEffect(() => getPartners(), []); // eslint-disable-line react-hooks/exhaustive-deps

	if (partners !== null) {
		partners.map(
			(partner) =>
				(optionPartners = [
					...optionPartners,
					{
						value: partner.id,
						label: partner.name,
						dataKey: Number(partner.assign_to),
						partnerCommission: partner.commission,
						partnerNoteCommission: partner.commission_note,
						partnerVat: partner.vat,
						partnerDescription: partner.description
					}
				])
		);
	}

	countries.map(
		(country) =>
			(optionCountries = [
				...optionCountries,
				{
					value: country.name,
					label: country.name
				}
			])
	);

	//get itineraries
	useEffect(() => {
		async function getDataItineraries() {
			try {
				if (cruiseID > 0) {
					const response = await getAPI(
						`${apiUrl}/cruise-itineraries`,
						`filterFields=cruise_id&filterFieldValues=${cruiseID}`
					);
					setItineraries(response.data);
					setAvailableRooms([]);
					setAddBookingDefaultData({ ...defaultData, cruise: null });
				}
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		getDataItineraries();
	}, [cruiseID]); // eslint-disable-line react-hooks/exhaustive-deps
	// get name sale
	useEffect(() => {
		async function userData() {
			try {
				if (assignTo) {
					const response = await getAPI(`${apiUrl}/user/${assignTo}`);
					if (response.data && response.data.name) {
						setNameSale(response.data.name);
					}
				}
				setNewBooking({ ...newBooking, assign_to: Number(assignTo) });
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		userData();
	}, [assignTo]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (customers && customers.length > 0) {
			customers.forEach((itemCustomer) => {
				if (itemCustomer) {
					itemCustomer['start_date'] = start_date;
					itemCustomer['status'] = newBooking.status;
					itemCustomer['money_type'] = newBooking.money_type;
					itemCustomer['cruise_itinerary_id'] = chooseDuration;
					itemCustomer['cruise_id'] = cruiseID;
					itemCustomer['partner_id'] = partnerID;
				}
			});
		}
	}, [partnerID, cruiseID, chooseDuration, newBooking.status, start_date, newBooking.adults]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		async function GroupPriceData() {
			try {
				if (partnerID > 0) {
					const partner = partners.find((item) => {
						return item.id === partnerID;
					});

					const arrDataCategories = partner.price_groups.split(',');
					setArrGroupPrice(arrDataCategories);
				}
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		GroupPriceData();
	}, [partnerID]); // eslint-disable-line react-hooks/exhaustive-deps

	//Cabin Specials
	useEffect(() => {
		async function getSpecialCabins() {
			try {
				if (cruiseID > 0) {
					const response = await getAPI(
						`${apiUrl}/cabin-specials`,
						`filterFields=cruise_id,duration&filterFieldValues=${cruiseID},${chooseDuration}`
					);
					setSpecialCabins(response.data);
				}
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		getSpecialCabins();
	}, [cruiseID, chooseDuration]); // eslint-disable-line react-hooks/exhaustive-deps

	// get dataPrice &date=
	useEffect(() => {
		async function getDataPrice() {
			try {
				if (cruiseID > 0 && chooseDuration > 0 && start_date !== '' && arrGroupPrice && isFirstLoadingPrice) {
					const response = await getAPI(
						`${apiUrl}/prices`,
						`filterFields=cruise_id,itinerary_id&filterFieldValues=${cruiseID},${chooseDuration}&date=${start_date}&price_group=${arrGroupPrice}`
					);
					if (response.data) {
						setDataPrice(response.data);
					}
					isFirstLoadingPrice = false;
				}
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		getDataPrice();
	}, [cruiseID, chooseDuration, start_date, arrGroupPrice]); // eslint-disable-line react-hooks/exhaustive-deps
	const onChangeCheckboxDefaultPrice = async () => {
		if (cruiseID > 0 && chooseDuration > 0 && start_date !== '' && arrGroupPrice) {
			const response = await getAPI(
				`${apiUrl}/prices`,
				`filterFields=cruise_id,itinerary_id&filterFieldValues=${cruiseID},${chooseDuration}&date=${start_date}&price_group=${arrGroupPrice}`
			);
			if (response.data !== null) {
				setDataPrice(response.data);
			}
		}
	};

	if (pickups) {
		pickups.forEach((pickup) => {
			optionPickups = [
				...optionPickups,
				{
					value: pickup.id,
					label: pickup.address
				}
			];
		});
	}

	useEffect(() => {
		if (specialCabins) {
			let speOpts = [];
			specialCabins.forEach((special) => {
				if (special.money_type !== newBooking.money_type) {
					return;
				}
				speOpts = [
					...speOpts,
					{
						value: special.id,
						label: special.name,
						price: special.price,
						roomTypes: special.room_types
					}
				];
			});
			setOptionSpecials(speOpts);
		}
	}, [newBooking.money_type, specialCabins]);

	// pending choose
	const handleChange = (newValue, actionMeta) => {
		const dataOption = newValue;
		if (dataOption) {
			if (dataOption.value === 'Pending') {
				setPending(true);
			} else {
				setPending(false);
			}
			setNewBooking({ ...newBooking, status: dataOption.value });
		}
	};

	const isSetPartnerPrices = (partnerId) => {
		const p = partners.find((partner) => {
			return partner.id === partnerId && partner.price_groups;
		});

		return p ? true : false;
	};

	// select partner
	const handleChangePartner = (dataPartner) => {
		if (dataPartner && dataPartner.dataKey > 0) {
			setAssignTo(dataPartner.dataKey);
			setPartnerID(dataPartner.value);
			setCommissionNote(dataPartner.partnerNoteCommission);
			setPartnerDescription(dataPartner.partnerDescription);
			if (dataPartner.partnerVat !== undefined || dataPartner.partnerVat > 0) {
				setNewBooking({
					...newBooking,
					vat: Number(dataPartner.partnerVat)
				});
			}
		} else {
			setAssignTo(0);
			setPartnerID(0);
			setNameSale('');
			setPartnerDescription(null);
			setNewBooking({ ...newBooking, partner_id: Number(0) });
		}
	};

	const [totalCustomerService, setTotalCustomerService] = useState(0);
	const [customerTransportAmount, setCustomerTransportAmount] = useState(0);
	const [roomService, setRoomService] = useState(0);
	const [specialService, setSpecialService] = useState(0);
	const [totalRoomPrice, setTotalRoomPrice] = useState(0);
	const [vatPrice, setVatPrice] = useState(0);

	const onChangePrice = (event) =>
		setDataPrice({
			...dataPrice,
			[event.target.name]: Number(event.target.value)
		});

	useEffect(() => {
		if (dataPrice) {
			setNewBooking({
				...newBooking,
				adult_price: dataPrice.adult_price ? dataPrice.adult_price : 0,
				child_price: dataPrice.child_price,
				child_price2: dataPrice.child_price_2,
				infant_price: dataPrice.infant_price,
				single_price: dataPrice.single_price,
				money_type: dataPrice.money_type? dataPrice.money_type: newBooking.money_type
			});
		}
	}, [dataPrice]);
	useEffect(() => {
		// calculate room service
		let singleRoomCount = 0;
		customers.forEach((itemCustomer) => {
			if (itemCustomer.room_id) {
				const customersInRoom = customers.filter((c) => c.room_id === itemCustomer.room_id);
				if (customersInRoom.length === 1) {
					singleRoomCount += 1;
				}
			}
		});

		setRoomService(singleRoomCount * dataPrice.single_price);
	}, [dataPrice.single_price]);

	useEffect(() => {
		const sService = specialService | 0;
		const rService = roomService | 0;
		setTotalCustomerService(sService + rService);
	}, [roomService, specialService]);

	const handleTotalRoomPrice = (itemTotal) => {
		setTotalRoomPrice(itemTotal);
	};

	const handleVat = (numberVat) => {
		setVatPrice(numberVat);
	};
	const [isCustomTotal, setIsCustomTotal] = useState(false);

	useEffect(() => {
		if (isCustomTotal) {
			return;
		}
		async function changeTotal() {
			let cVAT = vatPrice;
			if (!(cVAT >= 0)) {
				cVAT = 0;
			}
			const totalCS = totalCustomerService ? totalCustomerService : 0;
			const totalRP = totalRoomPrice ? totalRoomPrice : 0;
			let total = totalCS + totalRP + ((totalCS + totalRP) / 100) * cVAT;
			total += customerTransportAmount > 0 ? customerTransportAmount : 0;

			setNewBooking({
				...newBooking,
				total: total,
				transportation_amount: customerTransportAmount > 0 ? customerTransportAmount : 0
			});
		}
		changeTotal();
	}, [totalCustomerService, totalRoomPrice, vatPrice, customerTransportAmount]); // eslint-disable-line react-hooks/exhaustive-deps

	const [cruiseData, setCruiseData] = useState();

	useEffect(() => {
		if (!defaultData) return;
		setNewBooking({
			...newBooking,
			start_date: defaultData.start_date ? defaultData.start_date : newBooking.start_date,
			cruise_id: defaultData.cruise ? defaultData.cruise.id : newBooking.cruise_id
		});
		if (defaultData.cruise) {
			setCruiseData({
				label: defaultData.cruise.name,
				value: defaultData.cruise.id,
				typeDay: defaultData.cruise.typeDay
			});
			setCruiseID(defaultData.cruise.id);
		}
		if (defaultData && defaultData.room_id) {
			setIsCheckRoom(false);
		}
	}, [defaultData]);

	const handleChangeCruise = (newValue) => {
		const dataCruise = newValue;
		// dData = {};
		setAddBookingDefaultData({ ...defaultData, cruise: null });
		if (dataCruise) {
			setCruiseID(dataCruise.value);
			setNewBooking({
				...newBooking,
				cruise_id: Number(dataCruise.value)
			});
			setCruiseData(dataCruise);
			const tripType = getCruiseTripType(cruises, dataCruise.value);
			setTripType(tripType);
		} else {
			setCruiseData(null);
			setCruiseID(null);
		}
		resetCustomerRoom(customers);
		setChooseDuration(0);
	};

	//itineraries
	if (itineraries) {
		itineraries.forEach((itinerary) => {
			optionItineraries = [
				...optionItineraries,
				{
					value: itinerary.id,
					label: itinerary.name,
					valueID: itinerary.id,
					duration: itinerary.duration
				}
			];
		});
	}

	// select itinerary
	const handleChangeItinerary = (newValue, actionMeta) => {
		const dataItinerary = newValue;

		if (dataItinerary) {
			setStatusCustomer(true);
			setPriceBooking(true);
			setChooseDuration(Number(dataItinerary.valueID));
			setNewDuration(dataItinerary.duration);
		}
	};

	// select number pax adult
	const [numberAdult, setNumberAdult] = useState(0);
	const [numberChild, setNumberChild] = useState(0);
	const [numberChild11, setNumberChild11] = useState(0);
	const [numberInfant, setNumberInfant] = useState(0);

	let numberCustomers = 0;

	const handleChangeNuberPaxAdult = (newValue, actionMeta) => {
		const dataNumber = newValue;
		if (dataNumber) {
			setBookingDetail(true);
			setNumberAdult(dataNumber.value);
			setNewBooking({ ...newBooking, adults: Number(dataNumber.value) });
		} else {
			setNumberAdult(0);
			setBookingDetail(false);
			setNewBooking({ ...newBooking, adults: Number(0) });
		}
	};

	const handleChangeNuberPaxChild = (newValue, actionMeta) => {
		const dataNumber = newValue;
		if (dataNumber) {
			setBookingDetail(true);
			setNumberChild(dataNumber.value);
			setNewBooking({
				...newBooking,
				children: Number(dataNumber.value)
			});
		} else {
			setNumberChild(0);
			setNewBooking({ ...newBooking, children: Number(0) });
		}
	};

	const handleChangeNuberPaxChild2 = (newValue, actionMeta) => {
		const dataNumber = newValue;
		if (dataNumber) {
			setBookingDetail(true);
			setNumberChild11(dataNumber.value);
			setNewBooking({
				...newBooking,
				children11: Number(dataNumber.value)
			});
		} else {
			setNumberChild11(0);
			setNewBooking({ ...newBooking, children11: Number(0) });
		}
	};

	const handleChangeNuberPaxInfant = (newValue, actionMeta) => {
		const dataNumber = newValue;
		if (dataNumber) {
			setBookingDetail(true);
			setNumberInfant(dataNumber.value);
			setNewBooking({ ...newBooking, infants: Number(dataNumber.value) });
		} else {
			setNumberInfant(0);
			setNumberInfant(false);
			setNewBooking({ ...newBooking, infants: Number(0) });
		}
	};

	const handleChangeMoney = (newValue, actionMeta) => {
		const dataMoney = newValue;
		console.log('dataMoney', dataMoney);
		if (dataMoney) {
			setNewBooking({ ...newBooking, money_type: dataMoney.value });
		}
	};

	numberCustomers = numberAdult + numberChild + numberChild11 + numberInfant;

	const handleChangeRoom = (newValue, actionMeta) => {
		const dataRoom = newValue;
		var textName = actionMeta.name;
		var theNumber = textName.match(/\d+/)[0];
		if (actionMeta.action === 'select-option') {
			setIsCheckRoom(false);
			if (!customers[theNumber]) {
				customers[theNumber] = {};
			}
			customers[theNumber]['room_id'] = dataRoom.value;
			customers[theNumber]['cruise_id'] = cruiseID;
			customers[theNumber]['assign_to'] = assignTo;
			customers[theNumber]['partner_id'] = partnerID;
			customers[theNumber]['start_date'] = start_date;
			customers[theNumber]['cruise_itinerary_id'] = chooseDuration;
			if (theNumber === 0) {
				customers[theNumber]['leader'] = 1;
			}
		} else {
			customers[theNumber]['room_id'] = null;
			setIsCheckRoom(true);
		}
		let singleRoomCount = 0;
		customers.forEach((itemCustomer) => {
			if (itemCustomer.room_id) {
				const customersInRoom = customers.filter((c) => c.room_id === itemCustomer.room_id);
				if (customersInRoom.length === 1) {
					singleRoomCount += 1;
				}
			}
		});

		setRoomService(singleRoomCount * dataPrice.single_price);
	};

	const handleChangeMrCustoms = (newValue, actionMeta) => {
		const dataMrCustom = newValue;
		if (dataMrCustom) {
			var textName = actionMeta.name;
			var theNumber = textName.match(/\d+/)[0];
			if (!customers[theNumber]) {
				customers[theNumber] = {};
			}
			const fieldNames = actionMeta.name.split('-');
			const fieldName = fieldNames[0];
			customers[theNumber][fieldName] = dataMrCustom.value;
		}
	};

	const handleChangeCountry = (newValue, actionMeta) => {
		const dataCountry = newValue;
		if (dataCountry) {
			var textName = actionMeta.name;
			var theNumber = textName.match(/\d+/)[0];
			if (!customers[theNumber]) {
				customers[theNumber] = {};
			}
			const fieldNames = actionMeta.name.split('-');
			const fieldName = fieldNames[0];
			customers[theNumber][fieldName] = dataCountry.value;
		}
	};

	const handleChangeGender = (newValue, actionMeta) => {
		const dataGender = newValue;
		if (dataGender) {
			var textName = actionMeta.name;
			var theNumber = textName.match(/\d+/)[0];
			if (!customers[theNumber]) {
				customers[theNumber] = {};
			}
			const fieldNames = actionMeta.name.split('-');
			const fieldName = fieldNames[0];
			customers[theNumber][fieldName] = dataGender.value;
		}
	};

	const onChangeLeader = (index, event) => {
		var fieldName = event.target.name;
		// eslint-disable-next-line array-callback-return
		for (var i = 0; i < numberCustomers; i++) {
			if (!customers[i]) {
				customers[i] = {};
			}
			if (i === index) {
				customers[i][fieldName] = 1;
			} else {
				customers[i][fieldName] = 0;
			}
		}
	};

	const onSubmit = async (event) => {
		event.preventDefault();
		let endDate = start_date;
		if (newDuration && newDuration > 1) {
			endDate = new Date(start_date);
			endDate.setDate(endDate.getDate() + (newDuration - 1));
		}

		if (!newBooking.money_type) {
			alert(t('Have an error setting money type. Please change money type and select again.'));
			return;
		}

		if (!newBooking.status) {
			alert(t('Have an error setting status. Please change status and select again.'));
			return;
		}

		if (!newBooking.assign_to) {
			alert(t('Have an error setting assign to. Please change partner and select again.'));
			return;
		}

		if (!newBooking.adults) {
			alert(t('Adults value must be greater than zero'));
			return;
		}

		if (!customers || customers.length <= 0) {
			alert(t('Please select customers'));
			return;
		}

		const bookedRoomIds = [...new Set(customers.filter((item) => item.room_id).map(item => item.room_id))];
		if (tripType === CRUISE_TYPES.NIGHT && (!bookedRoomIds || bookedRoomIds.length <= 0)) {
			alert(t('Please select rooms for customers'));
			return;
		}

		const customerTransports = getCustomerTransports(customers, start_date, endDate);
		newBooking['customerTransports'] = customerTransports;

		newBooking['end_date'] = endDate;
		newBooking['customers'] = customers;
		newBooking['cruise_itinerary_id'] = chooseDuration;
		newBooking['partner_id'] = partnerID;
		if (!newBooking.cruise_itinerary_id) {
			alert(t('Please set Itinerary.'));
			return;
		}

		const response = await addBooking(newBooking);
		if (response.data && response.data.id) {
			setShowToast({
				show: true,
				message: 'Added sucessful!',
				type: 'success'
			});
			// set booking list state
			await setBookingsData(tripType, bookings, response.data);
			//
			window.open(`/bookings/show/${response.data.id}`, '_blank');
			closeDialog();
			return;
		}

		setShowToast({
			show: true,
			message: response.error.ErrorCode ? `Add failed: ${response.error.ErrorCode}` : 'Add failed!',
			type: 'danger'
		});
		return;
	};
	const closeDialog = () => {
		setCustomerTransportAmount(0);
		setDataPrice({});
		numberCustomers = 0;
		setCustomers([]);
		setStatusCustomer(false);
		setPriceBooking(false);
		setChooseDuration(0);
		setNewDuration(0);
		setBookingDetail(false);
		setNameSale('');
		setAssignTo(0);
		setCruiseData(null);
		// dData = {};
		setAddBookingDefaultData(null);
		isFirstLoadingPrice = true;
		setNewBooking({
			booking_code: '',
			start_date: '',
			hold_date: '',
			hold_time: '',
			message: '',
			adults: 0,
			children: 0,
			children11: 0,
			infants: 0,
			duration: 0,
			sale_note: '',
			pickup: '',
			acc_note: '',
			opt_note: '',
			extra_service: 0,
			extra_note: '',
			vat: 0,
			cabin_desc: '',
			status: '',
			com_year: 0,
			com_note: '',
			discount: 0,
			discount_note: '',
			money_type: '',
			single_price: 0,
			premium_price: 0,
			premium_price_sig: 0,
			deposit: 0,
			deposit_note: '',
			total: 0,
			assign_to: '',
			user_id: Number(user.id),
			cruise_id: '',
			partner_id: '',
			time_for_deposite: '',
			time_for_payment: ''
		});
		setShowAddBookingModal(false);
	};

	return (
		<Modal
			show={showAddBookingModal}
			onHide={closeDialog}
			size='full'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>{t('Create Booking')}</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					<div className='row row-sm'>
						<div className='col-md-12 col-sm-12'>
							<div className='information'>
								<div className='information-header'>
									<h4 className='information-title mb-1'>{t('General Information')}</h4>
								</div>
								<div className='information-body'>
									<div className='row'>
										<div className='col-sm'>
											<div className='form-group'>
												<label>
													<strong>{t('Partner')}</strong>
												</label>
												<Select
													isClearable
													name='partner_id'
													onChange={handleChangePartner.bind(this)}
													options={optionPartners}
													placeholder={t('Choose Partner')}
												/>
											</div>
										</div>
										<div className='col-sm'>
											<div className='form-group'>
												<label>
													<strong>{t('Departure')}</strong>
												</label>
												<Form.Control
													type='date'
													name='start_date'
													value={start_date || ''}
													onChange={onChangeText}
													required
													className={isSpecialDate(cruiseSettings, cruiseID, start_date) ? 'special-date' : ''}
												/>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-sm'>
											<div className='form-group'>
												<label>
													<strong>{t('Partner Code')}</strong>
												</label>
												<input
													type='text'
													name='booking_code'
													value={booking_code || ''}
													className='form-control'
													placeholder={t('optional')}
													onChange={onChangeText}
												/>
											</div>
										</div>
										<div className='col-sm'>
											<div className='form-group'>
												<label>
													<strong>{t('Status')}</strong>
												</label>
												<Select
													isClearable
													name='status'
													onChange={handleChange.bind(this)}
													options={options}
													placeholder={t('Choose Status')}
													className={newBooking.status ? '' : 'item-error'}
												/>
											</div>
										</div>
									</div>
									{pending ? (
										<div className='row'>
											<div className='col-sm'></div>
											<div className='col-sm'>
												<div className='form-group'>
													<div className='row'>
														<div className='col-sm'>
															<div className='form-group'>
																<label>
																	<strong>{t('Hold until')}</strong>
																</label>
																<Form.Control
																	type='date'
																	name='hold_date'
																	value={hold_date || ''}
																	onChange={onChangeText}
																/>
															</div>
														</div>
														<div className='col-sm'>
															<div className='form-group'>
																<label>
																	<strong>{t('Select time')}</strong>
																</label>
																<Form.Control
																	type='time'
																	value={hold_time || ''}
																	name='hold_time'
																	onChange={onChangeText}
																/>
															</div>
														</div>
													</div>
													<div className='row'>
														<div className='col-sm'>
															<label>
																<strong>{t('Hold Note')}</strong>
															</label>
															<textarea
																className='form-control'
																rows='3'
																name='message'
																value={message || ''}
																placeholder={t('Hold Note')}
																onChange={onChangeText}
															></textarea>
														</div>
													</div>
												</div>
											</div>
										</div>
									) : (
										<div className='row'>
											<div className='col-sm'></div>
											<div className='col-sm'>
												<div className='form-group'>
													<div className='row'>
														<div className='col-sm'>
															<div className='form-group'>
																<label>
																	<strong>{t('Time for Deposit')}</strong>
																</label>
																<Form.Control
																	type='date'
																	name='time_for_deposite'
																	value={time_for_deposite || ''}
																	onChange={onChangeText}
																/>
															</div>
														</div>
														<div className='col-sm'>
															<div className='form-group'>
																<label>
																	<strong>{t('Time for Payment')}</strong>
																</label>
																<Form.Control
																	type='date'
																	name='time_for_payment'
																	value={time_for_payment || ''}
																	onChange={onChangeText}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									)}
									<div className='row'>
										<div className='col-md-6'>
											<div className='form-group'>
												<label>
													<strong>{t('Assign To')}</strong>
												</label>
												<input
													type='text'
													className='form-control'
													value={nameSale || ''}
													placeholder={t('Sale Name')}
													readOnly
												/>
											</div>
										</div>
										<div className='col-md-6'>
											<div className='form-group'>
												<div className='row'>
													<div className='col-sm'>
														<label>
															<strong>{t('Adults')}</strong>
														</label>
														<Select
															isClearable
															name='adults'
															onChange={handleChangeNuberPaxAdult.bind(this)}
															options={numberArray}
															placeholder={t('No. Pax')}
														/>
													</div>
													<div className='col-sm'>
														<label>
															<strong>{cruiseSetting && cruiseSetting.child_label1? t(cruiseSetting.child_label1): t('Child (5-7)')}</strong>
														</label>
														<Select
															isClearable
															name='children'
															onChange={handleChangeNuberPaxChild.bind(this)}
															options={numberArray}
															placeholder={t('No. Pax')}
														/>
													</div>
													<div className='col-sm'>
														<label>
															<strong>{cruiseSetting && cruiseSetting.child_lable2? t(cruiseSetting.child_lable2): t('Child (8-11)')}</strong>
														</label>
														<Select
															isClearable
															name='children2'
															onChange={handleChangeNuberPaxChild2.bind(this)}
															options={numberArray}
															placeholder={t('No. Pax')}
														/>
													</div>
													<div className='col-sm'>
														<label>
															<strong>{t('Infants')} (&#60; 5)</strong>
														</label>
														<Select
															isClearable
															name='infants'
															onChange={handleChangeNuberPaxInfant.bind(this)}
															options={numberArray}
															placeholder={t('No. Pax')}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{bookingDetail ? (
						<div className='row row-sm'>
							<div className='col-md-12 col-sm-12'>
								<div className='information-booking'>
									<div className='information-booking-header'>
										<h4 className='information-title mb-1'>{t('Booking Item Details')}</h4>
									</div>
									<div className='information-booking-body'>
										<div className='row'>
											<div className='col-md-6'>
												<div className='form-group'>
													<label>
														<strong>{t('Cruise')}</strong>
													</label>
													<Select
														isClearable
														name='cruise_id'
														onChange={handleChangeCruise.bind(this)}
														options={optionCruises.current}
														placeholder={t('Choose Cruise')}
														value={cruiseData || ''}
													/>
												</div>
												<div className='form-group'>
													<label>
														<strong>{t('Itinerary')}</strong>
													</label>
													<Select
														isClearable
														name='duration'
														onChange={handleChangeItinerary.bind(this)}
														options={optionItineraries}
														placeholder={t('Choose Itinerary')}
														value={findOptionItem(optionItineraries, chooseDuration) || ''}
													/>
												</div>
												{tripType !== CRUISE_TYPES.DAY && (
													<div className='form-group'>
														<label>
															<strong>{t('Cabin Description')}</strong>
														</label>
														<textarea
															className='form-control'
															rows='3'
															name='cabin_desc'
															value={cabin_desc || ''}
															placeholder={t('Cabin Description')}
															onChange={onChangeText}
														></textarea>
													</div>
												)}
												{priceBooking && commissionNote ? (
													<div className='form-group'>
														<label>
															<strong>{t('Note the Commission for Partner')}</strong>
														</label>
														<p
															style={{
																color: '#b72323',
																marginBottom: 0
															}}
														>
															<strong>{commissionNote}</strong>
														</p>
													</div>
												) : null}
												{priceBooking && partnerDescription ? (
													<div className='form-group'>
														<label>
															<strong>{t('Partner Description')}</strong>
														</label>
														<p
															style={{
																color: '#b72323',
																marginBottom: 0
															}}
														>
															<strong>{partnerDescription}</strong>
														</p>
													</div>
												) : null}
											</div>
											{priceBooking ? (
												<InfoPrice
													moneys={moneys}
													dataPrice={dataPrice}
													handleChangeMoney={handleChangeMoney}
													onChangeNumber={onChangeNumber}
													onChangeNumberFloat={onChangeNumberFloat}
													onChangeText={onChangeText}
													setNewBooking={setNewBooking}
													newBooking={newBooking}
													total={total ? total : 0}
													handleTotalRoomPrice={handleTotalRoomPrice}
													handleVat={handleVat}
													onChangeCheckboxDefaultPrice={onChangeCheckboxDefaultPrice}
													onChangePrice={onChangePrice}
													checkDayCruise={tripType}
													isCustomTotal={isCustomTotal}
													setIsCustomTotal={setIsCustomTotal}
												/>
											) : (
												''
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						''
					)}
					{statusCustomer ? (
						<div className='row row-sm'>
							<div className='col-md-12 col-sm-12'>
								<div className='information-customer'>
									<div className='information-other-header'>
										<h4 className='information-title mb-2'>{t('Participators')}</h4>
									</div>
									<div className='information-other-body table-responsive participators-table-mobile'>
										<Table striped bordered hover>
											<thead>
												<tr>
													{tripType === CRUISE_TYPES.NIGHT && <th>{t('Room')}</th>}
													{tripType === CRUISE_TYPES.NIGHT && (
														<th
															style={{
																width: '150px'
															}}
														>
															{t('Special Cabin')}
														</th>
													)}
													<th
														style={{
															width: '140px'
														}}
													>
														{t('Title')}
													</th>
													<th
														style={{
															width: '215px'
														}}
													>
														{t('Full Name')}
													</th>
													<th
														style={{
															width: '80px'
														}}
													>
														{t('Leader')}
													</th>
													<th
														// colspan='2'
														style={{
															width: '110px'
														}}
													></th>
													<th
														style={{
															width: '110px'
														}}
													></th>
												</tr>
											</thead>
											<tbody>
												{Array(numberCustomers)
													.fill(1)
													.map((value, index) => (
														<ItemInforCustomer
															transportationRoutes={transportationRoutes}
															transportationPickUps={transportationPickUps}
															transportationTypes={transportationTypes}
															defaultData={defaultData}
															chooseDuration={chooseDuration}
															assignTo={assignTo}
															cruiseID={cruiseID}
															partnerID={partnerID}
															start_date={start_date}
															checkDayCruise={tripType}
															customers={customers}
															key={index}
															index={index}
															handleChangeRoom={handleChangeRoom}
															optionRooms={availableRooms}
															optionSpecials={optionSpecials}
															handleChangeMrCustoms={handleChangeMrCustoms}
															mrCustoms={mrCustoms}
															onChangeCustomerText={onChangeCustomerText}
															onChangeLeader={onChangeLeader}
															handleChangeCountry={handleChangeCountry}
															optionCountries={optionCountries}
															handleChangeGender={handleChangeGender}
															genders={genders}
															optionPickups={optionPickups}
															onChangeCustomerNumber={onChangeCustomerNumber}
															setNewBooking={setNewBooking}
															newBooking={newBooking}
															bookingStatus={newBooking.status}
															setSpecialService={setSpecialService}
															moneyType={newBooking.money_type}
															setCustomerTransportAmount={setCustomerTransportAmount}
														/>
													))}
											</tbody>
										</Table>
									</div>
								</div>
							</div>
						</div>
					) : (
						''
					)}
					<div className='row row-sm'>
						<div className='col-md-12 col-sm-12'>
							<div className='information-other'>
								<div className='information-other-header'>
									<h4 className='information-title mb-1'>{t('Other Information')}</h4>
								</div>
								<div className='information-other-body'>
									<div className='row'>
										<div className='col-md-6'>
											<div className='form-group'>
												<label>
													<strong>{t('Sale Note')}</strong>
												</label>
												<CKEditor
													editor={ClassicEditor}
													value={newBooking.sale_note}
													onReady={(editor) => {
														// You can store the "editor" and use when it is needed.
														//console.log( 'Editor is ready to use!', editor );
													}}
													onChange={(event, editor) => {
														const data = editor.getData();
														setNewBooking({
															...newBooking,
															sale_note: data
														});
													}}
													onBlur={(event, editor) => {
														//console.log( 'Blur.', editor );
													}}
													onFocus={(event, editor) => {
														//console.log( 'Focus.', editor );
													}}
												/>
											</div>
										</div>
										<div className='col-md-6'>
											<div className='form-group'>
												<label>
													<strong>{t('Note Pick-up/Drop-off')}</strong>
												</label>
												<CKEditor
													editor={ClassicEditor}
													data={pickup}
													onReady={(editor) => {
														// You can store the "editor" and use when it is needed.
														//console.log( 'Editor is ready to use!', editor );
													}}
													onChange={(event, editor) => {
														const data = editor.getData();
														setNewBooking({
															...newBooking,
															pickup: data
														});
														//console.log( { event, editor, data } );
													}}
													onBlur={(event, editor) => {
														//console.log( 'Blur.', editor );
													}}
													onFocus={(event, editor) => {
														//console.log( 'Focus.', editor );
													}}
												/>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-md-6'>
											<div className='form-group'>
												<label>
													<strong>{t('Note for Accounting')}</strong>
												</label>
												<CKEditor
													editor={ClassicEditor}
													data={newBooking.acc_note}
													onReady={(editor) => {
														// You can store the "editor" and use when it is needed.
														//console.log( 'Editor is ready to use!', editor );
													}}
													onChange={(event, editor) => {
														const data = editor.getData();
														setNewBooking({
															...newBooking,
															acc_note: data
														});
														//console.log( { event, editor, data } );
													}}
													onBlur={(event, editor) => {
														//console.log( 'Blur.', editor );
													}}
													onFocus={(event, editor) => {
														//console.log( 'Focus.', editor );
													}}
												/>
											</div>
										</div>
										<div className='col-md-6'>
											<div className='form-group'>
												<label>
													<strong>{t('Operation Note')}</strong>
												</label>
												<CKEditor
													editor={ClassicEditor}
													data={opt_note}
													onReady={(editor) => {
														// You can store the "editor" and use when it is needed.
														//console.log( 'Editor is ready to use!', editor );
													}}
													onChange={(event, editor) => {
														const operation = editor.getData();
														setNewBooking({
															...newBooking,
															opt_note: operation
														});
														//console.log( { event, editor, operation } );
													}}
													onBlur={(event, editor) => {
														//console.log( 'Blur.', editor );
													}}
													onFocus={(event, editor) => {
														//console.log( 'Focus.', editor );
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button className='btn btn-danger btn-with-icon' variant='secondary' onClick={closeDialog}>
						<i className='fe fe-x-circle'></i> {t('Cancel')}
					</Button>
					<Button
						className='btn btn-primary btn-with-icon'
						variant='primary'
						disabled={isCheckRoom && tripType === CRUISE_TYPES.NIGHT}
						type='submit'
					>
						<i className='fe fe-save'></i> {t('Save')}!!!
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

const resetCustomerRoom = (customers) => {
	customers.forEach((customer, index) => {
		customers[index]['room_id'] = 0;
	});
};

const getCustomerTransports = (customers, startDate, endDate) => {
	const tmpCustomers = customers?.slice();
	let customerTransports = [];
	tmpCustomers.forEach((customer) => {
		const customerTransport = {};
		if (!customer.transport || ((!customer.transport.oneway || customer.transport.oneway === false) && (!customer.transport.return || customer.transport.return === false))) return;

		customerTransport.pickup_id = customer.transport.pickup_id;
		customerTransport.dropoff_id = customer.transport.dropoff_id;
		customerTransport.transportation_type_id = customer.transport.transportation_type_id;
		customerTransport.route_type = customer.transport.return ? 'Return' : 'Departure';
		customerTransport.pickup_note = customer.transport.pickup_note ? customer.transport.pickup_note : '';
		customerTransport.transportation_route_id = customer.transport.transportation_route_id;
		customerTransport.transportation_type_price = customer.transport.transportation_type_price;
		customerTransport.transportation_route_price = customer.transport.transportation_route_price;
		customerTransport.start_date = customer.transport.start_date
			? convertStringToDateISO(customer.transport.start_date)
			: convertStringToDateISO(startDate);
		customerTransport.end_date = convertStringToDateISO(endDate);

		customerTransports.push(customerTransport);
	});

	return customerTransports;
};

export default AddBookingModal;
