import { createContext, useState, useReducer } from 'react';
import { pickupReducer } from '../reducers/pickupReducer';
import { apiUrl, PICKUP_LOADED_SUCCESS, PICKUP_LOADED_FAIL, ADD_PICKUP, FIND_PICKUP, UPDATE_PICKUP, DELETE_PICKUP } from './constants';
import axios from 'axios';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';

export const PickupContext = createContext()

const PickupContextProvider = ({ children }) => {

    // State
    const [pickupsState, dispatch] = useReducer(pickupReducer, {
        pickup: null,
        pickups: [],
        pickupsLoading: true
    })

    const [showAddPickupModal, setShowAddPickupModal] = useState(false)
    const [showUpdatePickupModal, setShowUpdatePickupModal] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all Pickups
    const getPickups = async() => {
        const response = await getAPI(`${apiUrl}/dispatch-addresses`)
        if (response.data) {
            dispatch({ type: PICKUP_LOADED_SUCCESS, payload: response.data })
            return response
        }
    
        dispatch({ type: PICKUP_LOADED_FAIL })
        return response
    }

    // Add Pickup
    const addPickup = async newCruise => {
        const response = await postAPI(`${apiUrl}/dispatch-address/insert`, newCruise)
        if (response.data) {
            dispatch({
                type: ADD_PICKUP,
                payload: response.data
            })
        }
        return response
    }

    // Find Pickup when user is updating post
    const findPickup = pickupId => {
        const pickup = pickupsState.pickups.find(pickup => pickup.id === pickupId)
        dispatch({
            type: FIND_PICKUP,
            payload: pickup
        })
    }

    // update Pickup
    const updatePickup = async updatePickup => {
        const response = await postAPI(`${apiUrl}/dispatch-address/update`, updatePickup)
        if (response.status === 200) {
            dispatch({
                type: UPDATE_PICKUP,
                payload: updatePickup
            })
        }
        return response
    }

    // Delete Pickup
    const deletePickup = async pickupId => {
        const response = await getDeleteAPI(`${apiUrl}/dispatch-address/${pickupId}/delete`)
        if (response.status === 200) {
            dispatch({
                type: DELETE_PICKUP,
                payload: pickupId
            })
        }
        return response
    }

    // Post Context Data
    const pickupContextData = {
        pickupsState,
        getPickups,
        addPickup,
        findPickup,
        updatePickup,
        deletePickup,
        showToast,
        setShowToast,
        showAddPickupModal,
        setShowAddPickupModal,
        showUpdatePickupModal,
        setShowUpdatePickupModal
    }

    return (
        <PickupContext.Provider value = { pickupContextData } >
            { children }
        </PickupContext.Provider>
    )

}

export default PickupContextProvider