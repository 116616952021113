import { Fragment, useContext, useEffect, useState } from 'react';
import { CruiseContext } from '../../contexts/CruiseContext';
import { SettingContext } from '../../contexts/SettingContext';
import TableOperatingDayTrip from '../../components/layout/operating/TableOperatingDayTrip';
import InfoCommand from './InfoCommand';
import {Helmet} from "react-helmet";
import moment from 'moment';
import HtmlToDocx from 'html-docx';
import TableCommandOvernight from './TableCommandOvernight';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import TableCommandExportOvernight from './TableCommandExportOvernight';
import { CRUISE_TYPES, NO_PERMISSION, PERMISSIONS, ROLES } from '../../contexts/constants'
import TableCommandExportDayTrip from './TableCommandExportDayTrip';
import { useTranslation } from 'react-i18next';
import { CruiseSettingContext } from '../../contexts/CruiseSettingContext';
import { AuthContext } from '../../contexts/AuthContext';

//import { Link } from 'react-router-dom';


const Command = () => {
    const {
		authState: { user }
	} = useContext(AuthContext);
    const { t } = useTranslation();
    const {
        cruiseState: { cruise },
        FindACruise
    } = useContext(CruiseContext)

    const {
        settingState: { settings },
        getSettings,
    } = useContext(SettingContext)

    const {
        cruiseSettingState: { cruiseSettings },
        getCruiseSettings,
    } = useContext(CruiseSettingContext)

    useEffect( () => {
		if (!cruiseSettings || cruiseSettings.length === 0) {
			getCruiseSettings()
		}
		
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

    const [cruiseSetting, setCruiseSetting] = useState({});
    useEffect(() => {
        if (!cruise) return;
		const cruiseSetting = cruiseSettings? cruiseSettings.find((item) => {
			return item.cruise_id == cruise.id;
		}): {}
		setCruiseSetting(cruiseSetting);
	}, [cruise]);

    let idCruise = 0
    let dateInfo = ''
    let textCheckCommand = 'command'

    let param1 = window.location.pathname;
    let arrUrl = param1.split('/')
    let month = 0;
    let year = 0;
    let dayCurrent = 0;

    if(arrUrl !== null){
        var arrInfo = arrUrl[2].split('-')
        var cruiseIdInfo = arrInfo[3]
        var dateInfoCheck = `${arrInfo[0]}-${arrInfo[1]}-${arrInfo[2]}`
        month = arrInfo[1]
        year = arrInfo[0]
        dayCurrent = arrInfo[2]
        if(cruiseIdInfo){
            idCruise = cruiseIdInfo
        }
        if(dateInfoCheck){
            dateInfo = dateInfoCheck
        }
    }

    useEffect( () => {
        if(idCruise) {
            FindACruise(idCruise)
        }
    }, [idCruise] ) // eslint-disable-line react-hooks/exhaustive-deps

    let cruiseName = ''
    let dateFile = moment(dateInfo).format("MMMM D, YYYY")
    let checkDayCruise = ''

    if(cruise !== null){
        cruiseName = cruise.name

        if (cruise.cabins === 0){
            checkDayCruise = CRUISE_TYPES.DAY
        } else{
            checkDayCruise = CRUISE_TYPES.NIGHT
        }
    }

    useEffect(() => getSettings(`?filterFields=cruise_id,day_setting&filterFieldValues=${idCruise},${dateInfo}`), []) // eslint-disable-line react-hooks/exhaustive-deps



    const docExport = () => {
        HtmlToDocx({
            exportElement: '#html-docx',
            exportFileName: t('Tour Command') + ` ${cruiseName} in ${dateFile}.docx`,
            StringStyle: '#html-docx {font-family: "Times New Roman", Times, serif}',
            margins:{top: 1440,right: 1440,bottom: 1440,left: 1440,header: 720,footer: 720,gutter: 0}
        })
    }

    if (!user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR)
			&& !user.Permissions.includes(PERMISSIONS.VIEW_COMMAND)) return NO_PERMISSION;

    return (
        <Fragment>
            {cruise ? (
            <Helmet>
                <title>{t('Tour Command')} {cruiseName} - Cruises Management Software</title>
            </Helmet>
            ) : ('')}
            <div className="horizontal-mainwrapper container-fluid clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-3">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="info-header">
                                    <h1 className="float-left">{t('Tour Command')} - {cruiseName} - <span>{moment(dateInfo).format("MMMM D, YYYY")}</span></h1>
                                    <button id="exportToFile" style={{marginLeft: '10px'}} className="btn btn-customer float-right" onClick={docExport.bind(this)}><i className="las la-file-word m-r-10"></i>{t('Export to .doc File')}</button>
                                    <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="btn btn-primary float-right"
                                        table="table-to-xls"
                                        filename={t('Tour Command') + ` - ${cruiseName} - ${moment(dateInfo).format("MMMM D, YYYY")}`}
                                        sheet="sheet 1"
                                        buttonText={t(`Export to Excel`)}/>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div id="html-docx" style={{fontFamily: 'Roboto, sans-serif'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="title">{t('Tour Command')} - {cruiseName} <p className="float-right"><strong>{moment(dateInfo).format("MMMM D, YYYY")}</strong></p></h2>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content-info">
                                    <div className="header-title mt-2 mb-2">
                                        <InfoCommand settingProps={settings[0]} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {cruise ? (
                            (checkDayCruise === CRUISE_TYPES.DAY || checkDayCruise === CRUISE_TYPES.TOUR) ? (
                                <TableOperatingDayTrip cruiseProps={cruise} dayProps={dayCurrent} monthProps={month} yearProps={year} textCheckProps={textCheckCommand} cruiseSetting={cruiseSetting}/>
                            ) : (
                                <TableCommandOvernight cruiseProps={cruise} dayProps={dayCurrent} monthProps={month} yearProps={year} textCheckProps={textCheckCommand}cruiseSetting={cruiseSetting}/>
                            )
                        ) : ('')}

                        {settings.length === 1 ? (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="command-footer">
                                    <p className="mt-3"><strong>{t('Notes')}:</strong></p>
                                    <div className="command-footer-info" dangerouslySetInnerHTML={{__html: settings[0].note}}></div>
                                </div>
                            </div>
                        </div>
                        ) : ('')}
                    </div>
                    {cruise ? (
                            (checkDayCruise === CRUISE_TYPES.DAY || checkDayCruise === CRUISE_TYPES.TOUR) ? (
                                <TableCommandExportDayTrip cruiseProps={cruise} dayProps={dayCurrent} monthProps={month} yearProps={year} textCheckProps={textCheckCommand} cruiseSetting={cruiseSetting}/>
                            ) : (
                                <TableCommandExportOvernight cruiseProps={cruise} dayProps={dayCurrent} monthProps={month} yearProps={year} textCheckProps={textCheckCommand} cruiseSetting={cruiseSetting}/>
                            )
                    ) : ('')}

                </div>
            </div>

        </Fragment>
    )
}

export default Command
