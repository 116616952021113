import {
	PARTNERS_LOADED_SUCCESS,
	PARTNERS_LOADED_FAIL,
	ADD_PARTNER,
	UPDATE_PARTNER,
	FIND_PARTNER,
	DELETE_PARTNER,
	A_PARTNER,
	PARTNERS_PRICE_GROUPS,
} from '../contexts/constants';

export const partnerReducer = (state, action) => {
	const { type, payload } = action;

	switch (type) {
		case PARTNERS_LOADED_SUCCESS:
			return {
				...state,
				partnersLoading: false,
				partners: payload,
			};
		case PARTNERS_LOADED_FAIL:
			return {
				...state,
				partners: [],
				partnersLoading: false,
			};
		case ADD_PARTNER:
			return {
				...state,
				partners: [...state.partners, payload],
			};
		case FIND_PARTNER:
			return {
				...state,
				partner: payload,
			};
		case A_PARTNER:
			return {
				...state,
				partner: payload,
				partnersLoading: false,
			};
		case UPDATE_PARTNER:
			const newPartner = state.partners.map((partner) => {
				if (partner.id === payload.id) {
					return payload;
				} else {
					return partner;
				}
			});
			return {
				...state,
				partners: newPartner,
			};
		case DELETE_PARTNER:
			return {
				...state,
				partners: state.partners.filter(
					(partner) => partner.id !== payload
				),
			};
		case PARTNERS_PRICE_GROUPS:
			return {
				...state,
				pricePartners: payload
			};
		default:
			return state;
	}
};
