import { createContext, useReducer, useState } from 'react';
import { partnerReducer } from '../reducers/partnerReducer';
import {
	apiUrl,
	PARTNERS_LOADED_SUCCESS,
	PARTNERS_LOADED_FAIL,
	ADD_PARTNER,
	UPDATE_PARTNER,
	FIND_PARTNER,
	DELETE_PARTNER,
	A_PARTNER,
	PARTNERS_PRICE_GROUPS,
	ADD_MULTIPLE_PARTNERS,
} from './constants';

import { getAPI, getDeleteAPI, postAPI } from '../utils/api';

export const PartnerContext = createContext();

const PartnerContextProvider = ({ children }) => {
	// State
	const [partnerState, dispatch] = useReducer(partnerReducer, {
		partner: null,
		partners: [],
		partnersLoading: true,
	});

	const [showAddPartnerModal, setShowAddPartnerModal] = useState(false);
	const [showImportPartnerModal, setShowImportPartnerModal] = useState(false);
	const [showUpdatePartnerModal, setShowUpdatePartnerModal] = useState(false);
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null,
	});

	// Get all Cruises
	const getPartners = async () => {
		const response = await getAPI(`${apiUrl}/partners`);
		if (response.data) {
			dispatch({ type: PARTNERS_LOADED_SUCCESS, payload: response.data });
			return response;
		}

		dispatch({ type: PARTNERS_LOADED_FAIL });
		return response;
	};

	// Add Partner
	const addPartner = async (newPartner) => {
		const response = await postAPI(`${apiUrl}/partner/insert`, newPartner);
		if (response.data) {
			dispatch({
				type: ADD_PARTNER,
				payload: response.data,
			});
		}
		return response;
	};

	// Add Multiple Partners
	const addMultiplePartners = async (newPartners) => {
		const data = {
			partners: newPartners
		};
		const response = await postAPI(`${apiUrl}/partner/insert-multiple`, data);
		if (response.status == 200) {
			// get partners
			await getPartners();
		}
		return response;
	};

	// Find Partner when user is updating post
	const findPartner = (partnerId) => {
		const partner = partnerState.partners.find(
			(partner) => partner.id === partnerId
		);
		dispatch({
			type: FIND_PARTNER,
			payload: partner,
		});
	};

	// Find a Partner
	const FindAPartner = async (partnerId) => {
		if (partnerId) {
			const response = await getAPI(`${apiUrl}/partner/${partnerId}`);
			if (response.data) {
				dispatch({
					type: A_PARTNER,
					payload: response.data,
				});
			}
			return response;
		}
	};

	// Update Partner
	const updatePartner = async (updatePartner) => {
		const response = await postAPI(
			`${apiUrl}/partner/update`,
			updatePartner
		);
		if (response.status === 200) {
			dispatch({
				type: UPDATE_PARTNER,
				payload: updatePartner,
			});
		}
		return response;
	};

	// Delete Partner
	const deletePartner = async (partnerId) => {
		const response = await getDeleteAPI(
			`${apiUrl}/partner/${partnerId}/delete`
		);
		if (response.status === 200) {
			dispatch({
				type: DELETE_PARTNER,
				payload: partnerId,
			});
		}
		return response;
	};

	const GetPartnersByPriceGroup = async (price_groups) => {
		const response = await getAPI(
			`${apiUrl}/partners?price_groups=${price_groups}`
		);
		if (response.status === 200) {
			dispatch({
				type: PARTNERS_PRICE_GROUPS,
				payload: response.data,
			});
		}
		return response;
	};
	// Post Context Data
	const partnerContextData = {
		partnerState,
		getPartners,
		addPartner,
		findPartner,
		FindAPartner,
		updatePartner,
		deletePartner,
		showAddPartnerModal,
		setShowAddPartnerModal,
		showUpdatePartnerModal,
		setShowUpdatePartnerModal,
		showToast,
		setShowToast,
		GetPartnersByPriceGroup,
		showImportPartnerModal,
		setShowImportPartnerModal,
		addMultiplePartners,
	};

	return (
		<PartnerContext.Provider value={partnerContextData}>
			{children}
		</PartnerContext.Provider>
	);
};

export default PartnerContextProvider;
