import axios from 'axios';

export const getAllFromAPI = async (url, strParams='', limit = 2000, offset = 0) => {
  let result = {
    data: [],
    error: null,
  }
  try {
    if (limit === 0) {
      limit = 2000;
    }
    let apiResult = await getAPI(url, strParams, {}, limit, offset);
    if (apiResult.data) {
      result.data = result.data.concat(apiResult.data);
    }

    while (apiResult.data && apiResult.data.length > 0) {
      offset += apiResult.data.length;
      apiResult = await getAPI(url, strParams, {}, limit, offset);
      if (apiResult.data) {
        result.data = result.data.concat(apiResult.data);
      }
    }

    return result;
  } catch (error) {
    result.error = error;
    return result;
  }
};

export const getAPI = async (url, strParams='', configs = {}, limit, offset) => {
  let result = {
    data: null,
    error: null,
    status: null,
  }
  if (strParams && strParams !== "") {
    strParams = strParams.replace('?', '');
  }
  let response;
  try {
    const queryParam = getQueryString(strParams, limit, offset)
    url += queryParam;
    response = await axios.get(url, configs);
  } catch (error) {
    result.data = null;
    result.error = error
    
    return result;
  } finally {
    if (result.error) {
      result.status = result.error.status? result.error.status: 404;
      result.error = result.error.response? {'ErrorCode': result.error.response.data}: {'ErrorCode': 'Undefined'};
      return result;
    }
    result.status = response && response.status ? response.status : 404;
    if (response && response.data && response.data.ErrorCode) {
      result.data = null;
      result.error = response.data
      return result;
    }
    if (response && response.data) {
      result.data = response.data;
    }
    
    return result;
  }
};

const getQueryString = (strParams, limit, offset) => {
  if (!strParams && !limit) {
    return '';
  }

  let query = '?';
  let andLimit = '&';
  if (strParams) {
    query += strParams;
  } else {
    andLimit = '';
  }
  if (limit) {
    query += `${andLimit}limit=${limit}`;
  }
  if (offset) {
    query += '&offset=' + offset;
  }

  return query;
};

export const getDeleteAPI = async (url) => {
  let response;
  let result = {
    data: null,
    error: null,
    status: null,
  }
  try {
    response = await axios.get(url);
  } catch (error) {
    result.data = null;
    result.error = error
    return result;
  } finally {
    if (result.error) {
      result.status = result.error.status? result.error.status: 404;
      result.error = result.error.response? {'ErrorCode': result.error.response.data}: {'ErrorCode': 'Undefined'};
      return result;
    }
    result.status = response && response.status ? response.status : 404;
    if (response && response.data && response.data.ErrorCode) {
      result.data = null;
      result.error = response.date
      return result;
    }

    if (response && response.data) {
      result.data = response.data;
    }
    return result;
  }
};

export const postAPI = async (url, data, configs = {}) => {
  let response;
  let result = {
    data: null,
    error: null,
    status: null,
  }
  try {
    response = await axios.post(url, data, configs);
  } catch (error) {
    result.data = null;
    result.error = error
    return result;
  } finally {
    if (result.error) {
      result.status = result.error.status? result.error.status: 404;
      result.error = result.error.response? {'ErrorCode': result.error.response.data}: {'ErrorCode': 'Undefined'};
      return result;
    }
    result.status = response && response.status ? response.status : 404;
    if (response && response.data && response.data.ErrorCode) {
      result.data = null;
      result.error = response.date
      return result;
    }

    if (response && response.data) {
      result.data = response.data;
    }
    return result;
  }
};
