import { Fragment, useContext, useEffect, useState } from 'react';
import { Dropdown, Button } from 'react-bootstrap';

const Languages = () => {
    const getURL = () => {
        let currentLoc = window.location.href;
        const paths = currentLoc.split("?lng=");
        return paths[0];
    };    
    



	return (
		<Fragment>
			<Dropdown className="dropdown nav-item main-header-message languages">
                <Dropdown.Toggle className="new nav-link">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="header-icon-svgs feather feather-globe"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu">
                    <div className="menu-header-content bg-primary text-left">
                        <div className="d-flex">
                            <h6 className="dropdown-title mb-1 tx-15 text-white font-weight-semibold">Languages</h6>
                        </div>
                    </div>
                    <div className="main-message-list chat-scroll">
                        <Dropdown.Item href={getURL() + "?lng=vi"} className="p-3 d-flex border-bottom">
                            <div className="wd-90p">
                                <div className="d-flex">
                                    <h5 className="mb-1 name">VI</h5>
                                </div>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Item href={getURL() + "?lng=en"} className="p-3 d-flex border-bottom">
                            <div className="wd-90p">
                                <div className="d-flex">
                                    <h5 className="mb-1 name">EN</h5>
                                </div>
                            </div>
                        </Dropdown.Item>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
		</Fragment>
	);
};

export default Languages;
