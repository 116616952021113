import { Fragment, useState, useEffect, useContext } from 'react';
import { apiUrl } from '../../../contexts/constants';
import { ItineraryContext } from '../../../contexts/ItineraryContext';
import RevenueTable from './RevenueTable';
import RevenuePartner from '../includes/RevenuePartner';
import RevenuePartnerAssign from '../includes/RevenuePartnerAssign';
import RevenueItineraries from '../includes/RevenueItineraries';
import RevenueCruises from '../includes/RevenueCruises';
import RevenueStatus from '../includes/RevenueStatus';
import RevenueMoney from '../includes/RevenueMoney';
import RevenueDate from '../includes/RevenueDate';
import { getAPI } from '../../../utils/api';
import { ExportRevenueXLSX } from '../../export/revenue/ExportRevenueXLSX';
import Moment from 'moment';
import { currencyFormatter, diffDays, formatDateGMT7, sort } from '../../../utils/common';
import { CruiseContext } from '../../../contexts/CruiseContext';
import { BookingContext } from '../../../contexts/BookingContext';
import { PartnerContext } from '../../../contexts/PartnerContext';
import { useTranslation } from 'react-i18next';
import { CruiseSettingContext } from '../../../contexts/CruiseSettingContext';

const RevenueController = (props) => {
	const { t } = useTranslation();
	const month = props.monthProps;
	const year = props.yearProps;
	const daysInMonths = new Date(year, month, 0).getDate();

	const {
		itineraryState: { itineraries },
		getItineraries
	} = useContext(ItineraryContext);
	const {
		cruiseState: { cruises },
		getCruises
	} = useContext(CruiseContext);

	const {
        cruiseSettingState: { cruiseSettings },
    } = useContext(CruiseSettingContext)

	const [defaultStatus, setDefaultStatus] = useState(['Confirmed', 'Pending', 'Transferred', 'Charter']);
	const [bookings, setBookings] = useState([]);
	const [oBookings, setOBookings] = useState([]);
	const [newBookings, setNewBookings] = useState([]);
	const [fromDate, setFromDate] = useState(`${year}-${month}-01`);
	const [endDate, setEndDate] = useState(`${year}-${month}-${daysInMonths}`);
	const [dateCondition, setDateCondition] = useState(`?start_date=${fromDate}&end_date=${endDate}`);
	const [partnerID, setPartnerID] = useState(null);
	const [assignID, setAssignID] = useState(null);
	const [itinerary, setItinerary] = useState(null);
	const [arrCruises, setArrCruises] = useState([]);
	const [totalPaidAmount, setTotalPaidAmount] = useState(0);

	const {
		partnerState: { partners },
		getPartners
	} = useContext(PartnerContext);
	useEffect(() => {
		if (!cruises || !cruises.length) {
			getCruises();
		}
		if (!partners || !partners.length) {
			getPartners();
		}
	}, []);
	const [filterRevenue, setFilterRevenue] = useState({
		cruise_id: '',
		partner_id: '',
		assign_to: '',
		cruise_itinerary_id: ''
	});
	const applyFilter = (data, action) => {
		if (data?.target) {
			setFilterRevenue({ ...filterRevenue, [data.target.name]: data.target.value });
			return;
		}

		if (data && action) {
			setFilterRevenue({ ...filterRevenue, [action.name]: data.value });
		} else if (!data) {
			setFilterRevenue({ ...filterRevenue, [action.name]: '' });
		}
	};
	useEffect(() => {
		const filter = async () => {
			try {
				const dataArr = [];
				let filterFieldValues = [];
				let filterFields = [];
				let queryString = `${apiUrl}/report/bookings${dateCondition}`;
				if (filterRevenue.partner_id !== '') {
					filterFields.push('partner_id');
					filterFieldValues.push(filterRevenue.partner_id);
				}
				if (filterRevenue.cruise_id !== '') {
					filterFields.push('cruise_id');
					filterFieldValues.push(filterRevenue.cruise_id);
				}
				if (filterRevenue.cruise_itinerary_id !== '') {
					filterFields.push('cruise_itinerary_id');
					filterFieldValues.push(filterRevenue.cruise_itinerary_id);
				}
				if (filterRevenue.assign_to !== '') {
					filterFields.push('assign_to');
					filterFieldValues.push(filterRevenue.assign_to);
				}

				if (filterFields.length > 0) {
					queryString +=
						'&filterFields=' + filterFields.join(',') + '&filterFieldValues=' + filterFieldValues.join(',');
				}
				const response = await getAPI(queryString);

				if (response.data && response.data.total_payment) {
					setTotalPaidAmount(response.data.total_payment);
				}
				if (response.data && response.data.booking_report) {
					setBookings(response.data.booking_report);
					response.data.booking_report.forEach((booking) => {
						if (defaultStatus.includes(booking.status) === true) {
							dataArr.push(booking);
						}
					});

					setNewBookings(dataArr);
					setOBookings(dataArr);
				} else if (response.data || response.data.booking_report == null) {
					setNewBookings([]);
				}
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		};
		filter();
	}, [filterRevenue, dateCondition]);

	const getChildLabelSetting = (cruise_id) => {
		const cruiseSetting = cruiseSettings? cruiseSettings.find((item) => {
			return item.cruise_id == cruise_id;
		}): {}
		
		return cruiseSetting;
	}

	// Start: Get all specials , []
	useEffect(() => getItineraries(''), []); // eslint-disable-line react-hooks/exhaustive-deps

	const getNameDuration = (idItinerary) => {
		const itinerary = itineraries.find((itinerary) => {
			return itinerary.id === idItinerary;
		});
		return itinerary && itinerary.duration ? itinerary.duration : 0;
	};

	const onChangeFrom = (date) => {
		setFromDate(date);
		if (diffDays(date, endDate) > 31) {
			const inputDate = new Date(date);
			const eDate = new Date(inputDate.setMonth(inputDate.getMonth()+1));
			const strEDate = formatDateGMT7(eDate, 'YYYY-MM-DD')
			setEndDate(strEDate);
			// alert(t('Only accept choose range date in a month'));
			setDateCondition(`?start_date=${date}&end_date=${strEDate}`);
			return;
		}
		
		setDateCondition(`?start_date=${date}&end_date=${endDate}`);
	};
	const onChangeEnd = (date) => {
		setEndDate(date);
		if (diffDays(fromDate, date) > 31) {
			const inputDate = new Date(date);
			const fDate = new Date(inputDate.setMonth(inputDate.getMonth()-1));
			const strFDate = formatDateGMT7(fDate, 'YYYY-MM-DD')
			setFromDate(strFDate);
			// alert(t('Only accept choose range date in a month'));
			setDateCondition(`?start_date=${strFDate}&end_date=${date}`);
			return;
		}
		
		setDateCondition(`?start_date=${fromDate}&end_date=${date}`);
	};

	function unique(arr) {
		var newArr = [];
		newArr = arr.filter(function (item) {
			return newArr.includes(item) ? '' : newArr.push(item);
		});
		return newArr;
	}

	function setDataBooking(dataArr) {
		setNewBookings(dataArr);
	}

	function setDataStatus(data) {
		setDefaultStatus(data);
	}

	function setDataCheckActive(dataPartner, dataAssign, dataItinerary, dataCruises) {
		if (dataPartner) {
			setPartnerID(dataPartner);
		} else {
			setPartnerID(null);
		}
		if (dataAssign) {
			setAssignID(dataAssign);
		} else {
			setAssignID(null);
		}
		if (dataItinerary) {
			setItinerary(dataItinerary);
		} else {
			setItinerary(null);
		}
		if (dataCruises) {
			setArrCruises(dataCruises);
		} else {
			setArrCruises([]);
		}
	}

	let arrChecks = [];

	arrChecks = [
		...arrChecks,
		{
			partner: partnerID,
			assign: assignID,
			iti: itinerary,
			cruises: arrCruises
		}
	];

	return (
		<Fragment>
			<div className='revenue-card-content'>
				<div className='header-controller'>
					<div className='row'>
						<div className='col-sm'>
							<div className='row'>
								<RevenueDate
									fromDateProps={fromDate}
									endDateProps={endDate}
									onChangeFromProps={onChangeFrom}
									onChangeEndProps={onChangeEnd}
								/>
								{/*<div className="col-sm">
                                    <div className="form-group">
                                        <label>&nbsp;</label>
                                        <button className="btn btn-success btn-block"><i className="typcn typcn-edit"></i>&nbsp;Change Date</button>
                                    </div>
                                </div>*/}
							</div>
							<div className='row'>
								<div className='col-md'>
									<RevenuePartner
										oBookings={oBookings}
										arrChecksProps={arrChecks}
										setDataCheckActiveProps={setDataCheckActive}
										bookingsDefaultProps={bookings}
										bookingsProps={newBookings}
										defaultStatusProps={defaultStatus}
										uniqueFunc={unique}
										partners={partners}
										setNewBookingFunc={setDataBooking}
										filterDataRevenue={filterRevenue}
										applyFilter={applyFilter}
									/>
								</div>
								<div className='col-md'>
									<RevenuePartnerAssign
										oBookings={oBookings}
										arrChecksProps={arrChecks}
										setDataCheckActiveProps={setDataCheckActive}
										bookingsDefaultProps={bookings}
										bookingsProps={newBookings}
										defaultStatusProps={defaultStatus}
										uniqueFunc={unique}
										setNewBookingFunc={setDataBooking}
										filterDataRevenue={filterRevenue}
										applyFilter={applyFilter}
									/>
								</div>
							</div>
							<div className='row'>
								<div className='col-md'>
									<RevenueCruises
										oBookings={oBookings}
										arrChecksProps={arrChecks}
										setDataCheckActiveProps={setDataCheckActive}
										bookingsDefaultProps={bookings}
										bookingsProps={newBookings}
										defaultStatusProps={defaultStatus}
										uniqueFunc={unique}
										setNewBookingFunc={setDataBooking}
										getNameDuration={getNameDuration}
										filterDataRevenue={filterRevenue}
										applyFilter={applyFilter}
									/>
								</div>
								<div className='col-md'>
									<RevenueItineraries
										oBookings={oBookings}
										arrChecksProps={arrChecks}
										setDataCheckActiveProps={setDataCheckActive}
										bookingsDefaultProps={bookings}
										bookingsProps={newBookings}
										defaultStatusProps={defaultStatus}
										uniqueFunc={unique}
										setNewBookingFunc={setDataBooking}
										getNameDuration={getNameDuration}
										itineraries={itineraries}
										getItineraries={getItineraries}
										filterDataRevenue={filterRevenue}
										setFilterDataRevenue={setFilterRevenue}
										applyFilter={applyFilter}
									/>
								</div>
							</div>
							<div className='row'>
								<div className='col-sm'>
									<RevenueStatus
										oBookings={oBookings}
										arrChecksProps={arrChecks}
										bookingsDefaultProps={bookings}
										bookingsProps={newBookings}
										defaultStatusProps={defaultStatus}
										setDataStatusFunc={setDataStatus}
										setNewBookingFunc={setDataBooking}
										getNameDuration={getNameDuration}
									/>
								</div>
							</div>
							<div className='row'>
								<div className='col-sm'>
									<ExportRevenueXLSX
										btnLabel={t('Export to Excel')}
										fromDate={fromDate}
										toDate={endDate}
										classes='export-to-excel'
										data={perepareExportData(newBookings, getNameDuration, t, getChildLabelSetting)}
									/>
								</div>
							</div>
						</div>
						<div className='col-sm revenue-info'>
							<article className='message is-info'>
								<div className='message-body'>
									<RevenueMoney bookingsProps={newBookings} totalPaidAmount={totalPaidAmount} />
								</div>
							</article>
						</div>
					</div>
				</div>
			</div>
			<hr />
			{/* <RevenueTableExport
				bookingsProps={newBookings}
				getNameDuration={getNameDuration}
				totalPaidAmount={totalPaidAmount}
			/> */}
			<RevenueTable
				bookingsProps={newBookings}
				oBookings={oBookings}
				getNameDuration={getNameDuration}
				fromDate={fromDate}
				endDate={endDate}
				setNewBookingFunc={setDataBooking}
				getChildLabelSetting={getChildLabelSetting}
			/>
		</Fragment>
	);
};

const perepareExportData = (bookings, getNameDuration, t, getChildLabelSetting) => {
	let usdTotal = 0;
	let vndTotal = 0;
	let usdPaid = 0;
	let vndPaid = 0;
	let totalAdults = 0;
	let totalChildren = 0;
	let totalInfant = 0;
	let usdRemain = 0;
	let vndRemain = 0;

	// sort by start_date
	sort(bookings, 'start_date');

	const data = bookings.map((booking, index) => {
		if (booking.money_type === 'usd' && booking.total > 0) {
			usdTotal += booking.total;
		}
		if (booking.money_type === 'vnd' && booking.total > 0) {
			vndTotal += booking.total;
		}

		if (booking.money_type === 'usd' && booking.total_payment > 0) {
			usdPaid += booking.total_payment;
		}
		if (booking.money_type === 'vnd' && booking.total_payment > 0) {
			vndPaid += booking.total_payment;
		}

		totalAdults += booking.adults;

		if (booking.children !== undefined) {
			totalChildren += booking.children;
		}
		if (booking.children11 !== undefined) {
			totalChildren += booking.children11;
		}

		if (booking.infants !== undefined) {
			totalInfant += booking.infants;
		}

		let totalAll = booking.total;
		// let paidAmount = 0
		// let balance = 0
		let netIncome = 0;

		if (booking.total_payment > 0) {
			// paidAmount = booking.total_payment
			// balance = booking.total - booking.total_payment

			if (bookings.com_year !== undefined) {
				netIncome = booking.total - booking.com_year;
			} else {
				netIncome = booking.total;
			}

			if (booking.discount > 0) {
				totalAll = totalAll + booking.discount;
			}
		} else {
			// balance = booking.total

			if (booking.com_year !== undefined) {
				netIncome = booking.total - booking.com_year;
			} else {
				netIncome = booking.total;
			}

			if (booking.discount > 0) {
				totalAll = totalAll + booking.discount;
			}
		}
		
		const cruiseSetting = getChildLabelSetting(booking.cruise_id)
		const child =
		(booking.children11 ? `${booking.children11} [${cruiseSetting && cruiseSetting.child_lable2? t(cruiseSetting.child_lable2): '8-11'}]` : '') +
		', ' +
		(booking.children ? `${booking.children} [${cruiseSetting && cruiseSetting.child_label1? t(cruiseSetting.child_label1): '5-7'}]` : '');

		const formatter = currencyFormatter(booking.money_type);
		const moneyObj = {
			total: formatter.format(totalAll),
			net: formatter.format(netIncome),
			paid: booking.total_payment > 0 ? formatter.format(booking.total_payment) : formatter.format(0),
			discount: booking.discount > 0 ? formatter.format(booking.discount) : formatter.format(0),
			extra: booking.extra_service > 0 ? formatter.format(booking.extra_service) : formatter.format(0),
			com: booking.com_year > 0 ? formatter.format(booking.com_year) : formatter.format(0)
		};

		const dataObj = {};
		dataObj[t('Booking Code')] = booking.new_code ? booking.new_code : booking.id;
		dataObj[t('Partner')] = booking.partner_name;
		dataObj[t('Partner Code')] = booking.booking_code;
		dataObj[t('Guest Name')] = getGuestName(booking.customers);
		dataObj[t('Itinerary')] = getNameDuration(booking.cruise_itinerary_id) > 1 ? getNameDuration(booking.cruise_itinerary_id) + 'N' + (getNameDuration(booking.cruise_itinerary_id) - 1) + 'D': getNameDuration(booking.cruise_itinerary_id) + 'N';
		dataObj[t('Departure')] = Moment(booking.start_date).format('MMMM D, YYYY');
		dataObj[t('Return')] = Moment(booking.end_date).format('MMMM D, YYYY');
		dataObj[t('Adults')] = booking.adults;
		dataObj[t('Child')] = child;
		dataObj[t('Infants')] = booking.infants;
		dataObj[t('Pick-up/Drop-off')] = booking.pickup? booking.pickup.replace(/<\/?[^>]+(>|$)/g, '\n').replace(/\&nbsp;/g, ''): '';
		dataObj[t('Cruise')] = booking.cruise_name;
		dataObj[t('Total')] = moneyObj.total;
		dataObj[t('Net')] = moneyObj.net;
		dataObj[t('Paid')] = moneyObj.paid;
		dataObj[t('Discount')] = moneyObj.discount;
		dataObj[t('Extra')] = moneyObj.extra;
		dataObj[t('Com')] = moneyObj.com;
		dataObj[t('Currency')] = booking.money_type;
		dataObj[t('Assign')] = booking.assign_to_name;
		dataObj[t('Note for Accounting')] = booking.acc_note? booking.acc_note.replace(/<\/?[^>]+(>|$)/g, '\n').replace(/\&nbsp;/g, ''): '';

		return dataObj;
	});

	usdRemain = usdTotal - usdPaid;
	vndRemain = vndTotal - vndPaid;

	const totalRow = blankRow(t);
	totalRow[t('Adults')] = totalAdults;
	totalRow[t('Child')] = totalChildren;
	totalRow[t('Infants')] = totalInfant;

	const totalRevenueRowUSD = blankRow(t);
	totalRevenueRowUSD[t('Booking Code')] = t('Total Revenue') + ' (USD)';
	totalRevenueRowUSD[t('Partner Code')] = usdTotal;

	const totalRevenueRowVND = blankRow(t);
	totalRevenueRowVND[t('Booking Code')] = t('Total Revenue') + ' (VNĐ)';
	totalRevenueRowVND[t('Partner Code')] = vndTotal;

	const depositUSD = blankRow(t);
	depositUSD[t('Booking Code')] = t('Deposit') + ' (USD)';
	depositUSD[t('Partner Code')] = usdPaid;

	const depositVND = blankRow(t);
	depositVND[t('Booking Code')] = t('Deposit') + ' (VNĐ)';
	depositVND[t('Partner Code')] = vndPaid;

	const remainUSD = blankRow(t);
	remainUSD[t('Booking Code')] = t('Remain') + ' (USD)';
	remainUSD[t('Partner Code')] = usdRemain;

	const remainVND = blankRow(t);
	remainVND[t('Booking Code')] = t('Remain') + ' (VNĐ)';
	remainVND[t('Partner Code')] = vndRemain;

	data.push(totalRow, totalRevenueRowUSD, totalRevenueRowVND, depositUSD, depositVND, remainUSD, remainVND);

	return data;
};

const getGuestName = (names) => {
	if (!names || names.length === 0) return '';

	const validNames = names.filter((item) => item.trim().length > 0);
	if (!validNames || validNames.length === 0) return '';

	return validNames.join(', ');
};

const blankRow = (t) => {
	const dataObj = {};
	dataObj[t('Booking Code')] = '';
	dataObj[t('Partner')] = '';
	dataObj[t('Partner Code')] = '';
	dataObj[t('Guest Name')] = '';
	dataObj[t('Itinerary')] = '';
	dataObj[t('Departure')] = '';
	dataObj[t('Return')] = '';
	dataObj[t('Adults')] = '';
	dataObj[t('Child')] = '';
	dataObj[t('Infants')] = '';
	dataObj[t('Pick-up/Drop-off')] = '';
	dataObj[t('Cruise')] = '';
	dataObj[t('Total')] = '';
	dataObj[t('Net')] = '';
	dataObj[t('Paid')] = '';
	dataObj[t('Discount')] = '';
	dataObj[t('Extra')] = '';
	dataObj[t('Com')] = '';
	dataObj[t('Currency')] = '';
	dataObj[t('Assign')] = '';
	dataObj[t('Note for Accounting')] = '';

	return dataObj;
};

export default RevenueController;
