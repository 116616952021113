import moment from 'moment';
import * as XLSX from 'xlsx';
import * as XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';
export const handleExportCustomerForMaritimeAdministration = async (
	exportForMaritimeAdministrationData,
	dateInfo,
	customerQuantity
) => {
	const rowLength = exportForMaritimeAdministrationData.length;
	const month = moment(dateInfo).format('MM'),
		year = moment(dateInfo).format('YYYY'),
		date = moment(dateInfo).format('DD');
	const ws = XLSX.utils.json_to_sheet(exportForMaritimeAdministrationData, { origin: 'A23' });
	XLSX.utils.sheet_add_aoa(ws, [['Cộng Hòa Xã Hội Chủ Nghĩa Việt Nam']], { origin: 'B1' });
	XLSX.utils.sheet_add_aoa(ws, [['Độc lập- Tự do- Hạnh phúc']], { origin: 'B2' });
	XLSX.utils.sheet_add_aoa(ws, [['---o0o---']], { origin: 'B3' });
	XLSX.utils.sheet_add_aoa(ws, [[`..., ngày ${date} tháng ${month} năm ${year}`]], { origin: 'B4' });
	XLSX.utils.sheet_add_aoa(ws, [['DANH SÁCH']], { origin: 'B6' });
	XLSX.utils.sheet_add_aoa(ws, [['HÀNH KHÁCH VẬN TẢI ĐƯỜNG THỦY NỘI ĐỊA']], { origin: 'B7' });
	XLSX.utils.sheet_add_aoa(ws, [['Tên phương tiện:		  	                              Số đăng ký: ']], { origin: 'B9' });
	XLSX.utils.sheet_add_aoa(ws, [['Tải trọng: ']], { origin: 'B10' });
	XLSX.utils.sheet_add_aoa(ws, [['Chủ phương tiện:	 ']], { origin: 'B11' });
	XLSX.utils.sheet_add_aoa(ws, [['Địa chỉ:	 ']], { origin: 'B12' });
	XLSX.utils.sheet_add_aoa(ws, [['Tên thuyền trưởng:	 ']], { origin: 'B13' });
	XLSX.utils.sheet_add_aoa(ws, [['Số GCNKNCM (CCCM): ']], { origin: 'B15' });
	XLSX.utils.sheet_add_aoa(ws, [['Số thuyền viên:		 ……. người']], { origin: 'B16' });
	XLSX.utils.sheet_add_aoa(ws, [['Tuyến vận tải: ']], { origin: 'B18' });
	XLSX.utils.sheet_add_aoa(ws, [['Thời gian rời cảng/bến:	……h…….., ngày ……./……./……….']], { origin: 'B19' });
	XLSX.utils.sheet_add_aoa(ws, [['Quốc tịch Việt Nam:	……… người;  Quốc tịch nước ngoài:  ........... người']], {
		origin: 'B20'
	});
	XLSX.utils.sheet_add_aoa(ws, [[`Tổng số khách: ${rowLength} khách`]], {
		origin: 'B21'
	});

	// footer
	XLSX.utils.sheet_add_aoa(ws, [[`Tổng Số Hành Khách: ${rowLength}`]], {
		origin: `B${rowLength + 23 + 2}`
	});
	XLSX.utils.sheet_add_aoa(
		ws,
		[[`Đại diện đơn vị cảng/bến                                                       Người lập danh sách`]],
		{
			origin: `A${rowLength + 23 + 3}`
		}
	);
	XLSX.utils.sheet_add_aoa(ws, [[`    (Ký ghi rõ họ tên)  `]], {
		origin: `A${rowLength + 23 + 4}`
	});

	const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
	const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	// ws['D4'].v = 'NEW VALUE from NODE';

	// merge example
	const merge = [
		{ s: { r: 0, c: 1 }, e: { r: 0, c: 6 } }, // title, r = row, c = column
		{ s: { r: 1, c: 1 }, e: { r: 1, c: 6 } }, //
		{ s: { r: 2, c: 1 }, e: { r: 2, c: 6 } }, //
		{ s: { r: 3, c: 1 }, e: { r: 3, c: 6 } }, //
		{ s: { r: 4, c: 1 }, e: { r: 4, c: 6 } }, // date time
		{ s: { r: 5, c: 1 }, e: { r: 5, c: 6 } },
		{ s: { r: 6, c: 1 }, e: { r: 6, c: 6 } },
		{ s: { r: 7, c: 1 }, e: { r: 7, c: 6 } },
		{ s: { r: 8, c: 1 }, e: { r: 8, c: 6 } },
		{ s: { r: 9, c: 1 }, e: { r: 9, c: 6 } },
		{ s: { r: 10, c: 1 }, e: { r: 10, c: 6 } },
		{ s: { r: 11, c: 1 }, e: { r: 11, c: 6 } },
		{ s: { r: 12, c: 1 }, e: { r: 12, c: 6 } },
		{ s: { r: 13, c: 1 }, e: { r: 13, c: 6 } },
		{ s: { r: 14, c: 1 }, e: { r: 14, c: 6 } },
		{ s: { r: 15, c: 1 }, e: { r: 15, c: 6 } },
		{ s: { r: 17, c: 1 }, e: { r: 17, c: 6 } },
		{ s: { r: 18, c: 1 }, e: { r: 18, c: 6 } },
		{ s: { r: 19, c: 1 }, e: { r: 19, c: 6 } },
		{ s: { r: 20, c: 1 }, e: { r: 20, c: 6 } },
		{ s: { r: 21, c: 1 }, e: { r: 21, c: 6 } },
		{ s: { r: rowLength + 25, c: 0 }, e: { r: rowLength + 25, c: 6 } },
		{ s: { r: rowLength + 26, c: 0 }, e: { r: rowLength + 26, c: 2 } }
	];
	ws['!merges'] = merge;

	// Use this code for merge A2:A3 ({ s: { r: 1, c: 0 }, e: { r: 2, c: 0 } }) and A4:A5 ({ s: { r: 3, c: 0 }, e: { r: 4, c: 0 } })

	// Here s = start, r = row, c=col, e= end

	const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
	const data = new Blob([excelBuffer], { type: fileType });

	return addStyleForMaritimeAddministration(data, rowLength);
};

const addStyleForMaritimeAddministration = (workbookBlob, rowLength) => {
	return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
		workbook.sheets().forEach((sheet) => {
			// general style
			sheet.usedRange().style({
				fontFamily: 'Times New Roman',
				verticalAlignment: 'center',
				// horizontalAlignment: 'center',
				wrapText: true
			});

			// +23 because header rows = 23.
			// content table
			sheet.range(`A24:G${rowLength + 23}`).style({
				fontSize: 11,
				border: true
			});

			// set width for columns
			sheet.column('A').width(10).style({ horizontalAlignment: 'center' });
			sheet.column('B').width(30).style({ horizontalAlignment: 'left' });
			sheet.column('C').width(15).style({ horizontalAlignment: 'center' });
			sheet.column('D').width(15).style({ horizontalAlignment: 'center' });
			sheet.column('E').width(25).style({ horizontalAlignment: 'center' });
			sheet.column('F').width(25).style({ horizontalAlignment: 'center' });
			sheet.column('G').width(15).style({ horizontalAlignment: 'center' });

			// summary style
			sheet.range('B1:G21').style({
				fontSize: 12,
				borderColor: 'FFFFFF'
			});
			sheet.range('B1:G3').style({
				bold: true,
				horizontalAlignment: 'center'
			});
			sheet.range('B4:G4').style({
				horizontalAlignment: 'right'
			});
			sheet.range('B6:G7').style({
				bold: true,
				horizontalAlignment: 'center'
			});
			sheet.range('B10:G22').style({
				horizontalAlignment: 'left'
			});

			// header style
			// title
			sheet.range('A23:G23').style({
				// fill: '82AEE8',
				fontSize: 12,
				bold: true,
				horizontalAlignment: 'center',
				border: true
				// fontColor: 'ffffff',
			});

			sheet.gridLinesVisible(false);
		});

		return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob));
	});
};
