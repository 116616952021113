import { Fragment, useEffect, useState } from 'react';
import { apiUrl } from '../../../contexts/constants';
import axios from 'axios';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import NumberFormat from 'react-number-format';
import 'jquery';
import 'datatables.net-dt/js/dataTables.dataTables.min';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import { currencyFormatter, findOptionItem, getMonthOptions, getYearOptions } from '../../../utils/common';
import { Col, Row } from 'react-bootstrap';
import { getAPI } from '../../../utils/api';
import { getDefaultSort, getTable, getTableOptions } from '../../../utils/table';
import moment from 'moment';

const ListBookingPartner = (props) => {
	const { t } = useTranslation();

	const year = new Date().getFullYear();
	const month = new Date().getMonth() + 1;
	const arrYears = getYearOptions();
	const arrMonths = getMonthOptions(t);
	
	const [bookings, setBookings] = useState([]);
	const [bookingsData, setBookingsData] = useState([]);
	const [currentMonth, setCurrentMonth] = useState(month);
    const [currentYear, setCurrentYear] = useState(year);

	useEffect(() => {
		if (!currentMonth || !currentYear || !props.partnerID) return;
		async function bookingData(){
			try {
				const response = await getAPI(`${apiUrl}/report/bookings`, `?filterFields=partner_id&filterFieldValues=${props.partnerID}&month=${currentMonth && currentMonth.toString().length == 1? '0' + currentMonth: currentMonth}&year=${currentYear}`)
				setBookings(response.data.booking_report? response.data.booking_report: [])
			} catch (error) {
				return error.response ? error.response : {success: false, message: 'Server error!'}
			}
		}

		bookingData();
	}, [currentMonth, currentYear, props.partnerID]);

	useEffect(() => {
		if (!bookings) return;
		setTimeout(function () {
			$('#example').DataTable();
		}, 1000);
	}, [bookings]);

	useEffect(() => {
		if (!bookings) return;
		const filterData = bookings.filter((item) => {

		});
	}, [bookings, currentMonth, currentYear]);


	const handleChangeDay = (newValue, actionMeta) => {
        const dataDay = newValue;
        if (!dataDay) return;
        if(actionMeta.name === `currentMonth` ){
            if (dataDay.value == 'all') {
                setCurrentMonth('all');
                return;
            }
            
            setCurrentMonth(dataDay.value)

            return;
        }

        if(actionMeta.name === `currentYear` ){
            setCurrentYear(dataDay.value)
        }
    }

	const bookingTable = getBookingTable(bookings, t);

	return (
		<Fragment>
			{bookings ? (
				<div className='margin-bottom-150'>
					<div className='row'>
						<div className='col-md-12'>
							<Row className='sel-month-year' md={3}>
								<Col xs={2}>
									<div className="form-group">
										<label><strong>{t('Month')}</strong></label>
										<Select isClearable
											name="currentMonth"
											defaultValue={findOptionItem(arrMonths, currentMonth)}
											onChange={handleChangeDay.bind(this)}
											options={arrMonths}
											placeholder={t("Month")}
											styles={{
												// Fixes the overlapping problem of the component
												menu: provided => ({ ...provided, zIndex: 2 })
											}}
										/>
									</div>
								</Col>
								<Col xs={2}>
									<div className="form-group">
										<label><strong>{t('Year')}</strong></label>
										<Select isClearable
											name="currentYear"
											defaultValue={findOptionItem(arrYears, currentYear)}
											onChange={handleChangeDay.bind(this)}
											options={arrYears}
											placeholder={t("Year")}
											styles={{
												// Fixes the overlapping problem of the component
												menu: provided => ({ ...provided, zIndex: 2 })
											}}
										/>
									</div>
								</Col>
							</Row>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-12'>
							<div>{bookingTable}</div>
						</div>
					</div>
				</div>
			) : (
				t('This partner has not sent any yet bookings')
			)}
		</Fragment>
	);
};

const getBookingsTableData = (bookings) => {
	return bookings.map((booking, index) => {
		let bk = {};
		bk.id = booking.id;
		bk.stt = index + 1;
		bk.new_code = booking.new_code;
		bk.code = (
			<Link className='show-booking' to={`/bookings/show/${booking.id}`} target='_blank'>
				{booking.new_code}
			</Link>
		);
		bk.status = booking.status;
		bk.start_date = moment(booking.start_date).format('MMMM D, YYYY');
		bk.itinerary_name = booking.itinerary_name;
		bk.cruise_name = booking.cruise_name;
		bk.total =currencyFormatter(booking.money_type).format(booking.total? booking.total: 0);
		bk.assign_to_name = booking.assign_to_name;
		return bk;
	});
};

const getBookingsColumns = (t) => {
	return [
		{
			dataField: 'id',
			text: 'ID',
			hidden: true
		},
		{
			dataField: 'stt',
			text: 'STT',
			sort: true
		},
		{
			dataField: 'code',
			text: 'Booking ID',
			sort: true
		},
		{
			dataField: 'new_code',
			text: 'New Code',
			hidden: true
		},
		{
			dataField: 'status',
			text: t('Status'),
			classes: 'td-left'
		},
		{
			dataField: 'start_date',
			text: t('Departure'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'itinerary_name',
			text: t('Itinerary'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'cruise_name',
			text: t('Cruise'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'total',
			text: t('Total'),
			sort: true,
			classes: 'td-right'
		},
		{
			dataField: 'assign_to_name',
			text: t('Assign To'),
			sort: true,
			classes: 'td-left'
		}
	];
};

const getBookingTable = (bookings, t) => {
	if (!bookings || bookings.length === 0) return null;
	const tableData = getBookingsTableData(bookings);
	const columns = getBookingsColumns(t);
	const defaultSorted = getDefaultSort();
	const options = getTableOptions(tableData.length, t);

	return getTable(tableData, columns, defaultSorted, options);
};

export default ListBookingPartner;
