import { Fragment, useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import {Spinner, Card} from 'react-bootstrap'
import UpdateUserModal from '../../components/auth/UpdateUserModal'
import UpdateUserRole from "../../views/userRoles/UpdateUserRole";
import { RoleContext } from '../../contexts/RoleContext';
import { UserRoleContext } from '../../contexts/UserRoleContext';

const ListUser = props => {

    const {
        authState: {user, authLoading },
        findUser,
        setShowUpdateUserModal,
        setShowUpdateUserRoleModal
    } = useContext(AuthContext)

    const {
        userRoleState: { userRoles },
        getUserRoles,
        // deleteUserRole,

    } = useContext(UserRoleContext)
    const {
        getRoles
    } = useContext(RoleContext)

    useEffect( () => getUserRoles(), []) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => getRoles(), []) // eslint-disable-line react-hooks/exhaustive-deps

    const users = props.usersProps

    const chooseUser = userId => {
        const selectUser = findUser(userId)
        setSelectedUser(selectUser)
        setShowUpdateUserModal(true)
    }

    const [editUserID, setEditUserID] = useState({});
    const chooseUserRole = userID => {
        setEditUserID(userID)

        setShowUpdateUserRoleModal(true)
    }


    const [selectedUser, setSelectedUser] = useState({})
    console.log('kilaku');
    if(authLoading){
        return (
            <div className="spinner-container">
                <Spinner animation='border' variant='info'></Spinner>
            </div>
        )
    }
    if(users === null || (users && users.length === 0)) {
        return (
            <Fragment>
                <Card className='text-center mx-5 my-5'>
                    <Card.Header as='h1'>Hi {user.name}</Card.Header>
                    <Card.Body>
                        <Card.Title>Welcome to Cruise Manage</Card.Title>
                        <Card.Text>
                            Click the button below to track your first skill to learn
                        </Card.Text>
                    </Card.Body>
                </Card>
                {user !== null && <UpdateUserModal selectedUser={selectedUser} setSelectedUser={setSelectedUser} />}
            {user !== null && <UpdateUserRole editUserID={editUserID} users={users} userRoles={userRoles} setEditUserID={setEditUserID} />}
            </Fragment>
        )
    }
    return (
        <Fragment>
            <div className="ajax-booking-cruise">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="table-info-cruise mg-b-20">
                            <div className="table-info-cruise-body">
                                <div className="table-responsive">
                                    <table className="table mg-b-0 text-md-nowrap">
                                        <thead>
                                            <tr>
                                                <th>STT</th>
                                                <th>Name</th>
                                                <th>Phone</th>
                                                <th>Department</th>
                                                <th>Active</th>
                                                <th className="w-220"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users && users.length > 0 && users.map((u, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{u.name}</td>
                                                <td>{u.telephone}</td>
                                                <td>{u.department}</td>
                                                <td>{u.status}</td>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-sm">
                                                            {(user && (user.Permissions.includes('update_user') || user.Roles.includes('superadministrator'))) ? (<button className="btn btn-success btn-with-icon btn-block" onClick={chooseUser.bind(this, u.id)}><i className="fe fe-edit"></i> Edit</button>): null}
                                                        </div>
                                                        <div className="col-sm">
                                                        {(user && (user.Permissions.includes('update_user') || user.Roles.includes('superadministrator'))) ? (<button className="btn btn-success btn-with-icon" onClick={chooseUserRole.bind(this, u.id)}><i className="fe fe-edit"></i> Set Roles</button>): null}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {user !== null && <UpdateUserModal selectedUser={selectedUser} setSelectedUser={setSelectedUser} />}
        {user !== null && <UpdateUserRole editUserID={editUserID} users={users} userRoles={userRoles} setEditUserID={setEditUserID} />}
        </Fragment>
    )
}

export default ListUser
