import { Fragment, useContext, useEffect } from 'react';
import { ServiceContext } from '../../../contexts/ServiceContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { Spinner, Card } from 'react-bootstrap';
import UpdateServiceModal from './UpdateServiceModal';
import DeleteModal from '../DeleteModal';
import { getTable, getTableOptions, getDefaultSort } from '../../../utils/table';
import { ROLES, PERMISSIONS } from '../../../contexts/constants';
import { useTranslation } from 'react-i18next';

const ListService = () => {
	const { t } = useTranslation();
	// Context
	const {
		authState: { user }
	} = useContext(AuthContext);
	const {
		serviceState: { service, services, servicesLoading },
		getServices,
		findService,
		deleteService,
		setShowUpdateServiceModal,
		setShowToast
	} = useContext(ServiceContext);

	const chooseService = (serviceId) => {
		findService(serviceId);
		setShowUpdateServiceModal(true);
	};

	// Start: Get all services , []
	useEffect(() => getServices(), []); // eslint-disable-line react-hooks/exhaustive-deps

	if (servicesLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info'></Spinner>
			</div>
		);
	}
	if (services === null || services.length === 0) {
		return (
			<Fragment>
				<Card className='text-center mx-5 my-5'>
					<Card.Header as='h1'>Hi {user.name}</Card.Header>
					<Card.Body>
						<Card.Title>Welcome to Service Manage</Card.Title>
						<Card.Text>Click the Add Service button to create your first Service</Card.Text>
					</Card.Body>
				</Card>
				{service !== null && <UpdateServiceModal />}
			</Fragment>
		);
	}
	const serviceTable = getListServiceTable(services, user, chooseService, deleteService, setShowToast, t);
	return (
		<Fragment>
			{serviceTable}
			{service !== null && <UpdateServiceModal />}
		</Fragment>
	);
};

const getTableData = (services, user, chooseService, deleteService, setShowToast) => {
	return services.map((service, index) => {
		return {
			index: index + 1,
			name: service.name,
			action: (
				<div className='row'>
					<div className='col-sm'>
						{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
						user.Permissions.includes(PERMISSIONS.UPDATE_SERVICE) ? (
							<button
								className='btn btn-success btn-with-icon btn-block'
								onClick={chooseService.bind(this, service.id)}
							>
								<i className='fe fe-edit'></i> Edit
							</button>
						) : null}
					</div>
					<div className='col-sm'>
						{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
						user.Permissions.includes(PERMISSIONS.DELETE_SERVICE) ? (
							<DeleteModal
								nameProps={service.name}
								idProps={service.id}
								deleteFunc={deleteService}
								setShowToast={setShowToast}
							/>
						) : null}
					</div>
				</div>
			)
		};
	});
};

const getColumns = () => {
	return [
		{
			dataField: 'index',
			text: 'STT',
			sort: true
		},

		{
			dataField: 'name',
			text: 'Name',
			sort: true
		},
		{
			dataField: 'action',
			text: ''
		}
	];
};

const getListServiceTable = (services, user, chooseService, deleteService, setShowToast, t) => {
	if (!services || services.length === 0) return null;
	const tableData = getTableData(services, user, chooseService, deleteService, setShowToast);
	const columns = getColumns();
	const defaultSorted = getDefaultSort();
	const options = getTableOptions(tableData.length, t);

	return getTable(tableData, columns, defaultSorted, options);
};

export default ListService;
