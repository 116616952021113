import { Fragment, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { CustomerTransportContext } from '../../contexts/CustomerTransportContext';
// import DeleteModal from '../../components/layout/DeleteModal';
import { Card, Form, Spinner } from 'react-bootstrap';
// import { PERMISSIONS, ROLES } from '../../contexts/constants';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { getTable, getTableOptions, getDefaultSort } from '../../utils/table';
import { getOptions, routeTypeOptions, findOptionItem } from '../../utils/common';
import { CruiseContext } from '../../contexts/CruiseContext';
import { TransportationPickUpContext } from '../../contexts/TransportationPickUpContext';
import { TransportationTypeContext } from '../../contexts/TransportationTypeContext';
import { ExportCustomerNoTransportXLSX } from '../../components/export/ExportCustomerNoTransportXLSX';
import { useTranslation } from 'react-i18next';

const ListCustomerNoTransportation = ({ cruise, dateInfo }) => {
	const { t } = useTranslation();
	
	// Context
	const {
		authState: { user }
	} = useContext(AuthContext);

	const {
		cruiseState: { cruises },
		getCruises
	} = useContext(CruiseContext);
	const {
		transportationPickUpState: { transportationPickUps },
		getTransportationPickUps
	} = useContext(TransportationPickUpContext);
	const {
		transportationTypeState: { transportationTypes },
		getTransportationTypes
	} = useContext(TransportationTypeContext);

	useEffect(() => {
		if (!transportationTypes || !transportationTypes.length) {
			getTransportationTypes();
		}
	}, []);

	useEffect(() => {
		if (!transportationPickUps || !transportationPickUps.length) {
			getTransportationPickUps();
		}
	}, []);

	useEffect(() => {
		if (!cruises || !cruises.length) {
			getCruises();
		}
	}, []);

	const [filterData, setFilterData] = useState({
		cruise_id: cruise.id,
		start_date: dateInfo,
		pickup_id: 'all',
		dropoff_id: 'all',
		route_type_id: 'all',
		transportation_type_id: 'all'
	});

	const {
		customerTransportState: { customerNoTransports, customerNoTransportsLoading },
		getcustomerNoTransports,
		findCustomerTransport,
		deleteCustomerTransport,
		setShowUpdateCustomerTransportModal,
		setShowToast
	} = useContext(CustomerTransportContext);

	const chooseCustomerTransport = (customerTransportId) => {
		findCustomerTransport(customerTransportId);
		setShowUpdateCustomerTransportModal(true);
	};
	
	const [optionCruises, setOptionCruises] = useState([]);
	const [pickupOptions, setPickupOptions] = useState([]);
	const [transportTypeOptions, setTransportTypeOptions] = useState([]);

	useEffect(() => {
		const cruiseOpts = getOptions(cruises, ['label', 'value', 'typeDay'], ['name', 'id', 'typeDay']);
		cruiseOpts.unshift({ label: t('All'), value: 'all' });
		setOptionCruises(cruiseOpts);
	}, [cruises]);

	useEffect(() => {
		const pickupOpts = getOptions(transportationPickUps, ['label', 'value'], ['name', 'id']);
		pickupOpts.unshift({ label: t('All'), value: 'all' });
		setPickupOptions(pickupOpts);
	}, [transportationPickUps]);

	useEffect(() => {
		const transportTypeOpts = getOptions(transportationTypes, ['label', 'value'], ['name', 'id']);
		transportTypeOpts.unshift({ label: t('All'), value: 'all' });
		setTransportTypeOptions(transportTypeOpts);
	}, [transportationPickUps]);

	useEffect(() => {
		const filter = async () => {
			let queryString = `&date=${filterData.start_date}`;
			let filterFieldValues = [];
			let filterFields = [];
			if (filterData.cruise_id !== 'all') {
				filterFields.push('c.cruise_id');
				filterFieldValues.push(filterData.cruise_id);
			}

			if (filterFields.length > 0) {
				queryString +=
					'&filterFields=' + filterFields.join(',') + '&filterFieldValues=' + filterFieldValues.join(',');
			}

			await getcustomerNoTransports(queryString);
		};
		filter();
	}, [filterData]);

	if (customerNoTransportsLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info'></Spinner>
			</div>
		);
	}

	let data = getListTransportTable(
		customerNoTransports,
		user,
		chooseCustomerTransport,
		deleteCustomerTransport,
		setShowToast,
		t
	);

	if (!data) data = {};

	const customStyles = {
		menuPortal: (provided) => ({ ...provided, zIndex: 5 })
	};

	const applyFilter = (data, action) => {
		if (data && data.target) {
			setFilterData({ ...filterData, [data.target.name]: data.target.value });
			return;
		}

		if (data) {
			setFilterData({ ...filterData, [action.name]: data.value });
		}
	};

	const dataExport = prepareDataExport(data.tableData, t);

	return (
		<Fragment>
			<div className='row'>
				<div className='col-sm float-right'>
					<ExportCustomerNoTransportXLSX
						customerTransports={dataExport}
						fileName='list_customer_basic'
						label={t('Export to Excel')}
						dateInfo={dateInfo}
						classes='export-to-excel'
					/>
				</div>
			</div>
			<hr className='mt-3' />
			<div className='row'>
				<div className='col-md-1'>
					<h5>{t('Filter')}</h5>
				</div>
			</div>
			<div className='row'>
				<div className='col-md-2'>
					<div className='form-group'>
						<label>{t('Cruise')}</label>

						<Select
							isClearable
							name='cruise_id'
							onChange={applyFilter.bind(this)}
							options={optionCruises}
							placeholder={t('Choose Cruise')}
							value={findOptionItem(optionCruises, filterData.cruise_id)}
							menuPosition={'fixed'}
							styles={customStyles}
						/>
					</div>
				</div>
				<div className='col-md-2'>
					<div className='form-group'>
						<label>{t('Departure')}</label>
						<Form.Control
							type='date'
							name='start_date'
							value={filterData.start_date}
							onChange={applyFilter.bind(this)}
						/>
					</div>
				</div>
			</div>
			{data.tableFormatted}
			{/* {customerTransport !== null && <UpdatecustomerTransportModal />} */}
			{/* {customerTransport !== null && <ShowcustomerTransportModal />} */}
		</Fragment>
	);
};

const getTableData = (customerTransports, user, chooseCustomerTransport, deleteCustomerTransport, setShowToast, t) => {
	return customerTransports.map((customerTransport, index) => {
		const item = {
			index: index + 1,
			booking_code: customerTransport.booking_code,
			customer_id: customerTransport.customer_id,
			customer_name: customerTransport.customer_name,
			booking_id: customerTransport.booking_id,
			start_date: customerTransport.start_date,
			end_date: customerTransport.route_type !== 'Departure' ? customerTransport.end_date : ''
		};
		item.booking_id = customerTransport.booking_id;
		return item;
	});
};

const getColumns = (t) => {
	return [
		{
			dataField: 'index',
			text: 'STT',
			sort: true
		},
		{
			dataField: 'booking_code',
			text: t('Booking Code'),
			sort: true,
			formatter: (cell, row) => {
				return (
					<Link className='' to={`/bookings/show/${row.booking_id}`} title={cell} target='_blank'>
						{cell}
					</Link>
				);
			}
		},
		{
			dataField: 'customer_id',
			text: t('Customer ID'),
			sort: true
		},
		{
			dataField: 'customer_name',
			text: t('Customer'),
			sort: true
		},
		{
			dataField: 'booking_id',
			text: t('Booking ID'),
			hidden: true
		},
		{
			dataField: 'start_date',
			text: t('Start Date'),
			sort: true
		},
		{
			dataField: 'end_date',
			text: t('End Date'),
			sort: true
		}
	];
};

const getListTransportTable = (
	customerTransports,
	user,
	chooseCustomerTransport,
	deleteCustomerTransport,
	setShowToast,
	t
) => {
	if (!customerTransports || customerTransports.length === 0) return null;
	const tableData = getTableData(
		customerTransports,
		user,
		chooseCustomerTransport,
		deleteCustomerTransport,
		setShowToast,
		t
	);
	
	const columns = getColumns(t);
	const defaultSorted = getDefaultSort();
	const options = getTableOptions(tableData.length, t);
	const tableFormatted = getTable(tableData, columns, defaultSorted, options);

	return { tableData, tableFormatted };
};

const prepareDataExport = (data, t) => {
	if (!data || data.length === 0) return [];
	const exportData = data.map(object => ({ ...object }))
	exportData.forEach((item) => {
		delete item.id;
		delete item.transportation_type_id;
		delete item.transportation_route_id;
		delete item.transportation_route_price;
		delete item.pickup_id;
		delete item.dropoff_id;
		delete item.booking_id;
	});

	return exportData.map((item) => {
		const obj = {}
		obj['STT'] = item.index;
		obj[t('Booking Code')] = item.booking_code;
		obj[t('Customer ID')] = item.customer_id;
		obj[t('Customer Name')] = item.customer_name;
		obj[t('Start Date')] = item.start_date;
		obj[t('End Date')] = item.end_date;

		return obj;
	});
};
export default ListCustomerNoTransportation;
