import { Fragment, useEffect, useState } from 'react';
import Select from 'react-select';
import { apiUrl } from '../../../contexts/constants';
import { getAPI } from '../../../utils/api';
import { getOptions, findOptionItem } from '../../../utils/common';
import { useTranslation } from 'react-i18next';

const RevenueItineraries = (props) => {
	const { t } = useTranslation();
	const filterDataRevenue = props.filterDataRevenue;
	const setFilterDataRevenue = props.setFilterDataRevenue;
	const applyFilter = props.applyFilter;
	const [arrItineraries, setArrItineraries] = useState([]);
	const [optionItineraries, setOptionItineraries] = useState([]);
	useEffect(() => {
		const itinerariesOpts = getOptions(arrItineraries, ['label', 'value', 'typeDay'], ['name', 'id', 'typeDay']);
		setOptionItineraries(itinerariesOpts);
	}, [arrItineraries]);

	useEffect(() => {
		async function itineraryData() {
			if (!filterDataRevenue.cruise_id || filterDataRevenue.cruise_id === 0) {
				setArrItineraries([]);
				setFilterDataRevenue({ ...filterDataRevenue, cruise_itinerary_id: '' });

				return;
			}
			try {
				const response = await getAPI(
					`${apiUrl}/cruise-itineraries`,
					`filterFields=cruise_id&filterFieldValues=${filterDataRevenue.cruise_id}`
				);
				setArrItineraries(response.data);
				setFilterDataRevenue({ ...filterDataRevenue, cruise_itinerary_id: '' });
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		itineraryData();
	}, [filterDataRevenue.cruise_id]);
	return (
		<Fragment>
			<div className='form-group'>
				<label>
					<strong>{t('Itinerary')}</strong>
				</label>
				<Select
					isClearable
					name='cruise_itinerary_id'
					menuPosition={'fixed'}
					menuPortalTarget={document.body}
					onChange={applyFilter.bind(this)}
					options={optionItineraries}
					value={findOptionItem(optionItineraries, filterDataRevenue.cruise_itinerary_id)}
					placeholder={t('Choose Itinerary')}
				/>
			</div>
		</Fragment>
	);
};

export default RevenueItineraries;
