import { Fragment, useContext, useEffect } from 'react';
import { PartnerContext } from '../../../contexts/PartnerContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { ROLES, PERMISSIONS, NO_PERMISSION } from '../../../contexts/constants';

const ListExportPartner = props => {

    const {authState: { user }} = useContext(AuthContext)
    const { 
        partnerState: { partners },
        // getPartners
    } = useContext(PartnerContext)

    const users = props.usersProps
    let partnerData = partners

    if (user && ((user.Roles && user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR)) || (user.Permissions && user.Permissions.includes(PERMISSIONS.GET_PARTNERS)))) {
        partnerData = partners
    } else if (user && user.Permissions && user.Permissions.includes(PERMISSIONS.GET_OWN_PARTNER)) {
        partnerData = partners.filter((partner) => {
            return partner.assign_to === user.id
        })
    }

    return (
        <Fragment>
            <div style={{display: 'none'}} className="ajax-booking-hide">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="table-info-cruise mg-b-20">
                            <div className="table-info-cruise-body">
                                <div className="table-responsive">
                                    <table id="table-to-xls" className="display">
                                        <thead>
                                            <tr>
                                                <th>STT</th>
                                                <th>Name</th>
                                                <th>Phone</th>
                                                <th>Address</th>
                                                <th>Type</th>
                                                <th>Description</th>
                                                <th>Assign To</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {partners && partners.map((partner, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{partner.name}</td>
                                                    <td>{partner.phone}</td>
                                                    <td>{partner.address}</td>
                                                    <td>{partner.partner_type}</td>
                                                    <td>{partner.description}</td>
                                                    <td>
                                                        {users && users
                                                            .filter(user => user.id === partner.assign_to)
                                                            .map((user, idx) => <p key={idx}>{user.name}</p>)
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ListExportPartner