import { Fragment, useContext, useEffect } from 'react';
import { CountryContext } from '../../../contexts/CountryContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { Spinner, Card } from 'react-bootstrap';
import UpdateCountryModal from './UpdateCountryModal';
import DeleteModal from '../DeleteModal';
import { ROLES, PERMISSIONS } from '../../../contexts/constants';
import { getTable, getTableOptions, getDefaultSort } from '../../../utils/table';
import { useTranslation } from 'react-i18next';

const ListCountry = () => {
	const { t } = useTranslation();
	// Context
	const {
		authState: { user }
	} = useContext(AuthContext);
	const {
		countryState: { country, countries, countriesLoading },
		getCountries,
		findCountry,
		deleteCountry,
		setShowUpdateCountryModal,
		setShowToast
	} = useContext(CountryContext);

	const chooseCountry = (countryId) => {
		findCountry(countryId);
		setShowUpdateCountryModal(true);
	};

	// Start: Get all rooms , []
	useEffect(() => {
		if (!countries || !countries.length) getCountries();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	if (countriesLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info'></Spinner>
			</div>
		);
	}
	if (countries.length === 0) {
		return (
			<Fragment>
				<Card className='text-center mx-5 my-5'>
					<Card.Header as='h1'>{t('Hi')} {user.name}</Card.Header>
					<Card.Body>
						<Card.Title>{t('Welcome to Country Manage')}</Card.Title>
						<Card.Text>{t('Click the button below to track your first Country')}</Card.Text>
					</Card.Body>
				</Card>
				{country !== null && <UpdateCountryModal />}
			</Fragment>
		);
	}
	const countryTable = getListCountryTable(countries, user, chooseCountry, deleteCountry, setShowToast, t);
	return (
		<Fragment>
			{countryTable}
			{country !== null && <UpdateCountryModal />}
		</Fragment>
	);
};

const getTableData = (countries, user, chooseCountry, deleteCountry, setShowToast, t) => {
	return countries.map((country, index) => {
		return {
			index: index + 1,
			name: country.name,
			iso_code_2: country.iso_code_2,
			iso_code_3: country.iso_code_3,
			action: (
				<div className='row'>
					<div className='col-sm'>
						{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
						user.Permissions.includes(PERMISSIONS.UPDATE_COUNTRY) ? (
							<button
								className='btn btn-success btn-with-icon btn-block'
								onClick={chooseCountry.bind(this, country.id)}
							>
								<i className='fe fe-edit'></i> {t('Edit')}
							</button>
						) : null}
					</div>
					<div className='col-sm'>
						{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
						user.Permissions.includes(PERMISSIONS.DELETE_COUNTRY) ? (
							<DeleteModal
								nameProps={country.name}
								idProps={country.id}
								deleteFunc={deleteCountry}
								setShowToast={setShowToast}
							/>
						) : null}
					</div>
				</div>
			)
		};
	});
};

const getColumns = (t) => {
	return [
		{
			dataField: 'index',
			text: 'STT',
			sort: true
		},
		{
			dataField: 'name',
			text: t('Name'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'iso_code_2',
			text: 'Iso Code 2',
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'iso_code_3',
			text: 'Iso Code 3',
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'action',
			text: ''
		}
	];
};

const getListCountryTable = (countries, user, chooseCountry, deleteCountry, setShowToast, t) => {
	if (!countries || countries.length === 0) return null;
	const tableData = getTableData(countries, user, chooseCountry, deleteCountry, setShowToast, t);
	const columns = getColumns(t);
	const defaultSorted = getDefaultSort();
	const options = getTableOptions(tableData.length, t);

	return getTable(tableData, columns, defaultSorted, options);
};

export default ListCountry;
