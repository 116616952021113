import { createContext, useReducer, useState } from 'react';
import { guideReducer } from '../reducers/guideReducer';
import { apiUrl, GUIDES_LOADED_SUCCESS, GUIDES_LOADED_FAIL, ADD_GUIDE, FIND_GUIDE, UPDATE_GUIDE, DELETE_GUIDE } from './constants';
import axios from 'axios';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';


export const GuideContext = createContext()

const GuideContextProvider = ({ children }) => {

    // State
    const [guideState, dispatch] = useReducer(guideReducer, {
        guide: null,
        guides: [],
        guidesLoading: true
    })

    const [showAddGuideModal, setShowAddGuideModal] = useState(false)
    const [showUpdateGuideModal, setShowUpdateGuideModal] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all guides
    const getGuides = async() => {
        const response = await getAPI(`${apiUrl}/guides`)
        if(response){
            dispatch({type: GUIDES_LOADED_SUCCESS, payload: response.data})
            return response
        }
    
        dispatch({ type: GUIDES_LOADED_FAIL })
        return response
    }

    // Add guide
    const addGuide = async newGuide => {
        const response = await postAPI(`${apiUrl}/guide/insert`, newGuide)
        if(response.status === 200) {
            dispatch( {
                type: ADD_GUIDE,
                payload: response.data
            } )
        }
        return response
    }

    // Find guide when user is updating guide
    const findGuide = guideId => {
        const guide = guideState.guides.find(guide => guide.id === guideId)
        dispatch({
            type: FIND_GUIDE,
            payload: guide
        })
    }

    // Update guide
    const updateGuide = async updateGuide => {
        const response = await postAPI(`${apiUrl}/guide/update`, updateGuide)
        if(response.status === 200){
            dispatch( {
                type: UPDATE_GUIDE,
                payload: updateGuide
            } )
        }
        return response
    }

    // Delete guide
    const deleteGuide = async guideId => {
        const response = await getDeleteAPI(`${apiUrl}/guide/${guideId}/delete`)
        if(response.status === 200){
            dispatch( {
                type: DELETE_GUIDE,
                payload: guideId
            } )
        }
        return response
    }

    const guideContextData = {
        guideState,
        getGuides,
        addGuide,
        findGuide,
        updateGuide,
        deleteGuide,
        showAddGuideModal,
        setShowAddGuideModal,
        showUpdateGuideModal,
        setShowUpdateGuideModal,
        showToast,
        setShowToast
    }

    return (
        <GuideContext.Provider value={guideContextData}>
            {children}
        </GuideContext.Provider>
    )
}

export default GuideContextProvider