import {Modal, Button, Form} from 'react-bootstrap';
import { useContext, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AlertMessage from '../layout/AlertMessage';

const AddUserModal = (props) => {

    // Context
    const {showAddUserModal, setShowAddUserModal, addUser, setShowToast} = useContext(AuthContext)
    const [alert, setAlert] = useState(null)
    const currentUsers = props.currentUsers
    // State
    const [newUser, setNewUser] = useState({
        name: '',
        email: '',
        password: '',
        telephone: '',
        address : '',
        detail: '',
        department: '',
        status: 'no',
        confirmPassword: ''
    })

    const {name, email, password, telephone, address, detail, department, confirmPassword} = newUser

    const onChangeUser = event => setNewUser( {...newUser, [event.target.name]: event.target.value } )
    //const onChangeUserNumber = event => setNewUser( {...newUser, [event.target.name]: Number(event.target.value) } )

    const handleChange = (event, editor) => {
        setNewUser( {...newUser, detail: editor.getData() } )
    }

    const closeDialog = () => {
        resetAddUserData()
    }

    const resetAddUserData = () => {
        setNewUser({
            name: '',
            email: '',
            password: '',
            telephone: '',
            address : '',
            detail: '',
            department: '',
            status: 'no',
            confirmPassword: ''
        })
        setShowAddUserModal(false)
    }

    const onSubmit = async event => {
        event.preventDefault()
        if(password !== confirmPassword) {
            setAlert({ type: 'danger', message: 'Password does not match!' })
            setTimeout(() => setAlert(null), 5000)
            return
        }

        try {
            const response = await addUser(newUser)
            // update new user list
            if (response.status !== 200) {
                setShowToast({show: true, message: `Add failed: ${response.error.ErrorCode}`, type: 'danger'})
                return
            }
            
            currentUsers.push(newUser);
            setShowToast({show: true, message: 'Added successfult!', type: 'success'})
            resetAddUserData()
            return
        } catch (error) {
            console.log(error)
            setShowToast({show: true, message: error.message, type: 'danger'})
        }
        resetAddUserData()
    }

    return (
        <Modal show={showAddUserModal} onHide={closeDialog}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Create New User
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit}>
                <AlertMessage info={alert} />
                <Modal.Body>
                    <div className="form-group">
                        <label>Full Name</label>
                        <input type="text" className="form-control" id="name" name="name" placeholder="Enter Full Name" value={name} onChange={onChangeUser} required aria-describedby='name-help' />
                        <Form.Text id='name-help' muted>Required</Form.Text>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-9">
                                <label>Email</label>
                                <input type="email" className="form-control" id="email" name="email" placeholder="Enter Email" value={email} onChange={onChangeUser} required aria-describedby='email-help' />
                                <Form.Text id='email-help' muted>Required</Form.Text>
                            </div>
                            <div className="col-md-3">
                                <label>Department</label>
                                <select name="department" value={department} className="SlectBox form-control" required aria-describedby='department-help' onChange={onChangeUser}>
                                    <option label="Choose Department"></option>
                                    <option value="member">Member</option>
                                    <option value="sale">Sale</option>
                                    <option value="isale">Isale</option>
                                    <option value="opt">Operating</option>
                                    <option value="acc">Accountant</option>
                                    <option value="admin">Admin</option>
                                </select>
                                <Form.Text id='department-help' muted>Required</Form.Text>
                            </div>
                        </div>

                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm">
                                <label>Password</label>
                                <input type="password" className="form-control" id="password" name="password" placeholder="Enter Password" value={password} onChange={onChangeUser} required aria-describedby='password-help' />
                                <Form.Text id='password-help' muted>Required</Form.Text>
                            </div>
                            <div className="col-sm">
                                <label>Confirm Password</label>
                                <Form.Control type="password" placeholder="Confirm Password" id="confirmPassword" name="confirmPassword" value={confirmPassword} onChange={onChangeUser} required aria-describedby='confirmPassword-help' />
                                <Form.Text id='confirmPassword-help' muted>Required</Form.Text>
                            </div>
                        </div>

                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm">
                                <label>Telephone</label>
                                <input type="text" className="form-control" id="telephone" name="telephone" placeholder="Enter Telephone" value={telephone} onChange={onChangeUser} />
                            </div>
                            <div className="col-sm">
                                <label>Address</label>
                                <input type="text" className="form-control" id="address" name="address" placeholder="Enter Address" value={address} onChange={onChangeUser} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Description</label>
                        <CKEditor
                            editor={ ClassicEditor }
                            data={detail}
                            onChange={handleChange}
                            /*onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                                console.log( 'Editor is ready to use!', editor );
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                console.log( { event, editor, data } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }*/
                        />
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                    <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default AddUserModal
