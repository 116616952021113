import { Fragment, useContext, useEffect } from 'react';
import { OnboardServiceContext } from '../../../contexts/OnboardServiceContext';
import { AuthContext } from '../../../contexts/AuthContext';
//import { CruiseContext } from '../../../contexts/CruiseContext';
import UpdateOnboardServiceModal from './UpdateOnboardServiceModal';
import {Spinner, Card} from 'react-bootstrap';
import DeleteModal from '../DeleteModal';
import 'jquery';
import "datatables.net-dt/js/dataTables.dataTables.min"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { ROLES, PERMISSIONS } from '../../../contexts/constants';

const ListOnboardService = () => {

    // Context
    const {
        authState: {user }
    } = useContext(AuthContext)
    const { 
        onboardServiceState: {onboardService, onboardServices, onboardServicesLoading},
        getOnboardServices,
        findOnboardService,
        deleteOnboardService,
        setShowUpdateOnboardServiceModal,
        setShowToast
    } = useContext(OnboardServiceContext)

    const chooseOnboardService = onboardServiceId => {
        findOnboardService(onboardServiceId)
        setShowUpdateOnboardServiceModal(true)
    }

    // Start: Get all onboard services , []
    useEffect( () => getOnboardServices(''), []) // eslint-disable-line react-hooks/exhaustive-deps
    let body = null

    setTimeout(function(){ $('#list-onboard-service').DataTable(); }, 1000);

    if(onboardServicesLoading){
        return (
            <div className="spinner-container">
                <Spinner animation='border' variant='info'></Spinner>
            </div>
        )
    }
    if(onboardServices === null){
        return (
            <Fragment>
                <Card className='text-center mx-5 my-5'>
                    <Card.Header as='h1'>Hi {user.name}</Card.Header>
                    <Card.Body>
                        <Card.Title>Welcome to Onboard Service Manage</Card.Title>
                        <Card.Text>
                            Click the Add Onboard Service button to create your first Onboard Service
                        </Card.Text>
                    </Card.Body>
                </Card>
                {onboardService !== null && <UpdateOnboardServiceModal />}
            </Fragment>
        )
    }
    return (
        <Fragment>
            <div className="ajax-booking-cruise">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="table-info-cruise mg-b-20">
                            <div className="table-info-cruise-body">
                                <div className="table-responsive">
                                    <table id="list-onboard-service" className="table display">
                                        <thead>
                                            <tr>
                                                <th>STT</th>
                                                <th>Name</th>
                                                <th>Price (VNĐ)</th>
                                                <th>Price (USD)</th>
                                                <th>Description</th>
                                                <th>Status</th>
                                                <th className="w-220"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {onboardServices.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.name}</td>
                                                <td className="capitalize">{item.price_vnd}</td>
                                                <td>{item.price_usd}</td>
                                                <td>{item.description}</td>
                                                <td>{item.status ? item.status : 'Active'}</td>
                                                <td>
                                                    <div className="row">
                                                        <div className="col-sm">
                                                            {(user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) || user.Permissions.includes(PERMISSIONS.UPDATE_ROOM)) ? <button className="btn btn-success btn-with-icon btn-block" onClick={chooseOnboardService.bind(this, item.id)}><i className="fe fe-edit"></i> Edit</button> : null}
                                                        </div>
                                                        <div className="col-sm">
                                                            {(user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) || user.Permissions.includes(PERMISSIONS.DELETE_ROOM)) ? <DeleteModal idProps={item.id} deleteFunc={deleteOnboardService} setShowToast={setShowToast} />: null}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {onboardService !== null && <UpdateOnboardServiceModal />}
        </Fragment>
    )
}

export default ListOnboardService
