import { Fragment, useContext, useEffect, useState } from "react";
import { AuthContext } from '../../contexts/AuthContext';
import { RoleContext } from '../../contexts/RoleContext';
import { Card, Spinner } from "react-bootstrap";
import DeleteModal from "../../components/layout/DeleteModal";
import UpdateRoleModal from "./UpdateRoleModal";
import { PermissionRoleContext } from '../../contexts/PermissionRoleContext';
import UpdatePermissionRole from "../../views/permissionRoles/UpdatePermissionRole";

const ListRole = () => {

    // Context
    const {authState: {user: {name} }} = useContext(AuthContext)

    const {
        permissionRoleState: { permissionRoles },
        getPermissionRoles,
    } = useContext(PermissionRoleContext)
    useEffect( () => getPermissionRoles(), []) // eslint-disable-line react-hooks/exhaustive-deps

    const {
        roleState: { role, roles, rolesLoading },
        getRoles,
        findRole,
        deleteRole,
        setShowUpdateRoleModal,
        setShowUpdateRolePermissionModal,
        setShowToast
    } = useContext(RoleContext)

    // Start: Get all specials , []
    useEffect( () => getRoles(), []) // eslint-disable-line react-hooks/exhaustive-deps

    const chooseRole = roleId => {
        findRole(roleId)
        setShowUpdateRoleModal(true)
    }

    const [editRoleID, setEditRoleID] = useState({});
    const choosePermissionRole = roleID => {
        setEditRoleID(roleID)
        findRole(roleID)
        setShowUpdateRolePermissionModal(true)
    }

    let body = null

    if(rolesLoading){
        body = (
            <div className="spinner-container">
                <Spinner animation='border' variant='info'></Spinner>
            </div>
        )
    }else if(roles === null || roles.length === 0){
        body = (
            <Fragment>
                <Card className='text-center mx-5 my-5'>
                    <Card.Header as='h1'>Hi {name}</Card.Header>
                    <Card.Body>
                        <Card.Title>Welcome to List Role Manage</Card.Title>
                        <Card.Text>
                            Click The Button Add Role to Create Your First Role
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Fragment>
        )
    }else{
        body = (
            <div className="ajax-booking-cruise">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="table-info-cruise mg-b-20">
                            <div className="table-info-cruise-body">
                                <div className="table-responsive">
                                    <table id="list-pickup" className="table display">
                                        <thead>
                                            <tr>
                                                <th>STT</th>
                                                <th>Name</th>
                                                <th>Display Name</th>
                                                <th className="w-50"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {roles.map((role, index) => (
                                                <tr key={index}>
                                                    <td>{index+1}</td>
                                                    <td>{role.name}</td>
                                                    <td>{role.display_name}</td>
                                                    <td>
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <button className="btn btn-success btn-with-icon" onClick={chooseRole.bind(this, role.id)}><i className="fe fe-edit"></i> Edit</button>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <button className="btn btn-success btn-with-icon" onClick={choosePermissionRole.bind(this, role.id)}><i className="fe fe-edit"></i> Set permissions</button>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <DeleteModal idProps={role.id} deleteFunc={deleteRole} setShowToast={setShowToast} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (

        <Fragment>
            {body}
            {role !== null && <UpdateRoleModal />}
            {role !== null && <UpdatePermissionRole editRoleID={editRoleID} setEditRoleID={setEditRoleID} roles={roles} permissionRoles={permissionRoles} />}
        </Fragment>

    )

}

export default ListRole