import { Fragment, useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { ItineraryContext } from '../../contexts/ItineraryContext';
import { CruiseContext } from '../../contexts/CruiseContext';
import DeleteModal from '../../components/layout/DeleteModal';
import UpdateItineraryModal from './UpdateItineraryModal';
import { Card, Spinner } from 'react-bootstrap';
import { ROLES, PERMISSIONS } from '../../contexts/constants';
import { getTable, getTableOptions, getDefaultSort } from '../../utils/table';
import { useTranslation } from 'react-i18next';

const ListItineraries = () => {
	const { t } = useTranslation();
	// Context
	const {
		authState: { user }
	} = useContext(AuthContext);

	const {
		itineraryState: { itinerary, itineraries, itinerariesLoading },
		getItineraries,
		findItinerary,
		deleteItinerary,
		setShowUpdateItineraryModal,
		setShowToast
	} = useContext(ItineraryContext);

	const {
		cruiseState: { cruises }
	} = useContext(CruiseContext);

	useEffect(() => getItineraries(''), []); // eslint-disable-line react-hooks/exhaustive-deps

	const chooseItinerary = (itineraryId) => {
		findItinerary(itineraryId);
		setShowUpdateItineraryModal(true);
	};

	const getNameCruise = (idCruise) => {
		var result = '';
		cruises.filter((cruise) => {
			if (cruise.id === idCruise) {
				result = cruise.name;
			}
		});
		return result;
	};

	if (itinerariesLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info'></Spinner>
			</div>
		);
	}
	if (itineraries === null || itineraries.length === 0) {
		return (
			<Fragment>
				<Card className='text-center mx-5 my-5'>
					<Card.Header as='h1'>Hi {user.name}</Card.Header>
					<Card.Body>
						<Card.Title>{t('Welcome to Itinerary List')}</Card.Title>
						<Card.Text>{t('Click The Button Add Itinerary to Create Your First Itinerary')}</Card.Text>
					</Card.Body>
				</Card>
				{itinerary !== null && <UpdateItineraryModal />}
			</Fragment>
		);
	}
	const itineraryTable = getListItineraryTable(
		itineraries,
		user,
		chooseItinerary,
		deleteItinerary,
		setShowToast,
		getNameCruise,
		t
	);
	return (
		<Fragment>
			{itineraryTable}
			{itinerary !== null && <UpdateItineraryModal />}
		</Fragment>
	);
};
const getTableData = (itineraries, user, chooseItinerary, deleteItinerary, setShowToast, getNameCruise, t) => {
	return itineraries.map((itinerary, index) => {
		return {
			index: index + 1,
			name: itinerary.name,
			duration: itinerary.duration,
			cruise_name: getNameCruise(itinerary.cruise_id),
			action: (
				<div className='row'>
					<div className='col-sm'>
						{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
						user.Permissions.includes(PERMISSIONS.UPDATE_ITINERARY) ? (
							<button
								className='btn btn-success btn-with-icon btn-block'
								onClick={chooseItinerary.bind(this, itinerary.id)}
							>
								<i className='fe fe-edit'></i> {t('Edit')}
							</button>
						) : null}
					</div>
					<div className='col-sm'>
						{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
						user.Permissions.includes(PERMISSIONS.DELETE_ITINERARY) ? (
							<DeleteModal
								nameProps={itinerary.name}
								idProps={itinerary.id}
								deleteFunc={deleteItinerary}
								setShowToast={setShowToast}
							/>
						) : null}
					</div>
				</div>
			)
		};
	});
};

const getColumns = (t) => {
	return [
		{
			dataField: 'index',
			text: 'STT',
			sort: true
		},

		{
			dataField: 'name',
			text: t('Name'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'duration',
			text: t('Duration'),
			sort: true
		},
		{
			dataField: 'cruise_name',
			text: t('Cruise'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'action',
			text: ''
		}
	];
};

const getListItineraryTable = (itineraries, user, chooseItinerary, deleteItinerary, setShowToast, getNameCruise, t) => {
	if (!itineraries || itineraries.length === 0) return null;
	const tableData = getTableData(itineraries, user, chooseItinerary, deleteItinerary, setShowToast, getNameCruise, t);
	const columns = getColumns(t);
	const defaultSorted = getDefaultSort();
	const options = getTableOptions(tableData.length, t);

	return getTable(tableData, columns, defaultSorted, options);
};

export default ListItineraries;
