import { Fragment, useContext, useEffect, useState } from 'react';
import { PriceContext } from '../../../contexts/PriceContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { Spinner, Card } from 'react-bootstrap';
import UpdatePriceModal from './UpdatePriceModal';
import DeleteModal from '../DeleteModal';
import Moment from 'moment';
import { ROLES, PERMISSIONS } from '../../../contexts/constants';
import ShowPriceModal from './ShowPriceModal';
import { PartnerContext } from '../../../contexts/PartnerContext';
import { getTable, getTableOptions, getDefaultSort } from '../../../utils/table';
import { useTranslation } from 'react-i18next';

const ListPrice = (props) => {
	const { t } = useTranslation();
	// Context
	const [updatedPrices, setUpdatedPrices] = useState(null);
	const [priceData, setPriceData] = useState({});

	const {
		authState: { user }
	} = useContext(AuthContext);
	const {
		priceState: { prices, pricesLoading },
		getPrices,
		deletePrice,
		setShowUpdatePriceModal,
		setShowToast,
		showPriceModal,
		setShowPriceModal
	} = useContext(PriceContext);
	const { GetPartnersByPriceGroup } = useContext(PartnerContext);

	const cruise = props.cruise;
	const year = props.year;

	const filterPrices = async (price_group) => {
		const result = prices.filter((item) => {
			return item.price_group === price_group;
		});

		setUpdatedPrices(result);
	};

	const choosePrice = (price_group) => {
		filterPrices(price_group);
		setShowUpdatePriceModal(true);
	};
	////partnerPrice
	const showPartnerPrice = (price_group, pData) => {
		GetPartnersByPriceGroup(price_group);
		setPriceData(pData);
		setShowPriceModal(true);
	};
	// Start: Get all rooms , []
	useEffect(() => getPrices(), []); // eslint-disable-line react-hooks/exhaustive-deps

	const [dataItemGroups, setDataItemGroups] = useState([]);
	const [dataArrays, setDataArrays] = useState([]);

	useEffect(() => {
		let pricesFilter = prices;
		let dem = 0;
		let dArrays = [];
		let dIGs = [];

		if (cruise && cruise.value) {
			pricesFilter = prices.filter((p) => {
				return p.cruise_id === cruise.value;
			});
		}

		if (year && year.value) {
			pricesFilter = pricesFilter.filter((p) => {
				const dateFrom = new Date(p.from_date);
				const yearFrom = dateFrom.getFullYear();
				const dateTo = new Date(p.to_date);
				const yearTo = dateTo.getFullYear();
				if (year.value >= yearFrom && year.value <= yearTo) {
					return true;
				}
			});
		}

		pricesFilter.forEach((price) => {
			if (!dArrays[price.price_group]) {
				dArrays[price.price_group] = {};
				dIGs.push(price.price_group);
				dem = 0;
			}
			dArrays[price.price_group][dem] = price;
			dem++;
		});
		setDataItemGroups(dIGs);
		setDataArrays(dArrays);
	}, [cruise, prices, year]);

	// setTimeout(function(){ $('#list-price').DataTable(); }, 1000);

	if (pricesLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info'></Spinner>
			</div>
		);
	}

	if (prices === null || prices.length === 0) {
		return (
			<Fragment>
				<Card className='text-center mx-5 my-5'>
					<Card.Header as='h1'>Hi {user.name}</Card.Header>
					<Card.Body>
						<Card.Title>{t('Welcome to Price Manage')}</Card.Title>
						<Card.Text>{t('Click the button add price to track your first price!')}</Card.Text>
					</Card.Body>
				</Card>
				{updatedPrices !== null && <UpdatePriceModal updatedPrices={updatedPrices} />}
			</Fragment>
		);
	}

	const tableProps = {
		dataArrays,
		choosePrice,
		dataItemGroups,
		user,
		deletePrice,
		setShowToast,
		showPartnerPrice,
		t
	};
	const tableContent = getListPriceTable(tableProps);

	return (
		<Fragment>
			{tableContent}
			{updatedPrices !== null && <UpdatePriceModal updatedPrices={updatedPrices} />}
			{showPriceModal !== null && <ShowPriceModal showPriceModal={showPriceModal} priceData={priceData} />}
		</Fragment>
	);
};

const getListPriceTable = (tableProps) => {
	if (!tableProps.dataItemGroups || tableProps.dataItemGroups.length === 0) return null;
	const tableData = getTableData(tableProps);
	const columns = getColumns(tableProps.t);
	const defaultSorted = getDefaultSort([['index', 'asc']]);
	const options = getTableOptions(tableData.length, tableProps.t);

	return getTable(tableData, columns, defaultSorted, options);
};

const getTableData = (tableProps) => {
	const { dataArrays, choosePrice, dataItemGroups, user, deletePrice, setShowToast, showPartnerPrice, t } = tableProps;
	return dataItemGroups.map((data, index) => {
		const c = dataArrays[data];
		return {
			index: index + 1,
			name: c[0].price_name,
			cruise: c[0].cruise_name,
			type: c[0].season_type,
			from: c[0].from_date,
			to: c[0].to_date,
			action: (
				<div className='row'>
					<div className='col-sm'>
						<button className='btn btn-info btn-with-icon btn-block' onClick={showPartnerPrice.bind(this, data, c[0])}>
							<i className='fe fe-eye'></i> {t('View')}
						</button>
					</div>
					<div className='col-sm'>
						{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) || user.Permissions.includes(PERMISSIONS.UPDATE_PRICE) ? (
							<button className='btn btn-success btn-with-icon btn-block' onClick={choosePrice.bind(this, data)}>
								<i className='fe fe-edit'></i> {t('Edit')}
							</button>
						) : null}
					</div>
					<div className='col-sm'>
						{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) || user.Permissions.includes(PERMISSIONS.DELETE_PRICE) ? (
							<DeleteModal idProps={data} deleteFunc={deletePrice} setShowToast={setShowToast} />
						) : null}
					</div>
				</div>
			)
		};
	});
};

const getColumns = (t) => {
	return [
		{
			dataField: 'index',
			text: 'STT',
			sort: true
		},
		{
			dataField: 'name',
			text: t('Name'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'cruise',
			text: t('Cruise'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'type',
			text: t('Type'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'from',
			text: t('From (MM/DD/YYYY)'),
			sort: true,
			formatter: (cell) => {
				return <>{Moment(cell).format('MM/DD/YYYY')}</>;
			}
		},
		{
			dataField: 'to',
			text: t('To (MM/DD/YYYY)'),
			sort: true,
			formatter: (cell) => {
				return <>{Moment(cell).format('MM/DD/YYYY')}</>;
			}
		},
		{
			dataField: 'action',
			text: ''
		}
	];
};

export default ListPrice;
