import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { getYearMonthDay } from '../utils/common';

const DashboardBookingSummaryFilter = props => {
    const { t } = useTranslation();

    const fromDate = props.fromDate;
    const filterBy = props.filterBy;
    const onChangeFrom = props.onChangeFrom;
    const onChangeFilterBy = props.onChangeFilterBy;
    const onChangeCruiseType = props.onChangeCruiseType;

    //let dataNewBooking = []

    const changeFrom = (event) => {
        var dateFrom = event.target.value
        onChangeFrom(dateFrom)
    }

    const changeFilterBy = (event) => {
        var filterBy = event.target.value
        onChangeFilterBy(filterBy)
    }

    const setCruiseType = (event) => {
        onChangeCruiseType(event.target.value);
    }

    const filterSelected = () => {
        const tmpDate = getYearMonthDay(fromDate);
        if (filterBy === 'day') {
            return `${tmpDate.day}-${tmpDate.month}-${tmpDate.year}`;
        }

        return `${tmpDate.month}-${tmpDate.year}`;
    }

    return (
        <Fragment>

            <div className="col-md-2">
                <div className="form-group">
                        <label>{t('Cruise Type')}</label>
                        <select className='form-control' onChange={setCruiseType.bind(this)}>
                            <option value='night sleep'>{t('Night Sleep')}</option>
                            <option value='tour'>{t('Tour')}</option>
                        </select>
                </div>
            </div>

            <div className="col-md-2">
                <div className="form-group">
                        <label>{t('Filter By')}</label>
                        <select className='form-control' onChange={changeFilterBy.bind(this)}>
                            <option value='day'>{t('Day')}</option>
                            <option value='month'>{t('Month')}</option>
                        </select>
                </div>
            </div>
            
            <div className="col-md-3">
                <div className="form-group">
                    <label>{t('Day')}/{t('Month')}</label>
                    <input className="form-control" type="date" value={fromDate} name='fromDate' onChange={changeFrom.bind(this)} />
                </div>
            </div>
            <div className='col-md-3'>
                <div className="form-group margin-top-30">
                    <label className='booking-summary-filter'><strong>{t(filterBy)}: {filterSelected()}</strong></label>
                </div>
            </div>
        </Fragment>
    )
}

export default DashboardBookingSummaryFilter
