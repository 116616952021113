import { createContext, useState, useReducer } from 'react';
import { transportationTypeReducer } from '../reducers/transportationTypeReducer';
import {
	apiUrl,
	TRANSPORTATION_TYPES_LOADED_SUCCESS,
	TRANSPORTATION_TYPES_LOADED_FAIL,
	ADD_TRANSPORTATION_TYPE,
	FIND_TRANSPORTATION_TYPE,
	UPDATE_TRANSPORTATION_TYPE,
	DELETE_TRANSPORTATION_TYPE
} from '../contexts/constants';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';

export const TransportationTypeContext = createContext();

const TransportationTypeContextProvider = ({ children }) => {
	// State
	const [transportationTypeState, dispatch] = useReducer(transportationTypeReducer, {
		transportationType: null,
		transportationTypes: [],
		transportationTypesLoading: true
	});

	const [showAddTransportationTypeModal, setShowAddTransportationTypeModal] = useState(false);
	const [showUpdateTransportationTypeModal, setShowUpdateTransportationTypeModal] = useState(false);
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null
	});

	// Get all Transportation Types
	const getTransportationTypes = async () => {
		const response = await getAPI(`${apiUrl}/transportation-types?orderBy=created_at&direction=desc`);
		if (response.data) {
			dispatch({ type: TRANSPORTATION_TYPES_LOADED_SUCCESS, payload: response.data });
			return response;
		}

		dispatch({ type: TRANSPORTATION_TYPES_LOADED_FAIL });
		return response;
	};

	// Add Transportation Type
	const addTransportationType = async (newTransportationType) => {
		const response = await postAPI(`${apiUrl}/transportation-type/insert`, newTransportationType);
		if (response.data) {
			dispatch({
				type: ADD_TRANSPORTATION_TYPE,
				payload: response.data
			});
		}
		return response;
	};

	// Find Transportation Type when user is updating post
	const findTransportationType = (transportationTypeId) => {
		const transportationType = transportationTypeState.transportationTypes.find(
			(transportationType) => transportationType.id === transportationTypeId
		);
		dispatch({
			type: FIND_TRANSPORTATION_TYPE,
			payload: transportationType
		});
	};

	// update Transportation Type
	const updateTransportationType = async (updateTransportationType) => {
		const response = await postAPI(`${apiUrl}/transportation-type/update`, updateTransportationType);
		if (response.status === 200) {
			dispatch({
				type: UPDATE_TRANSPORTATION_TYPE,
				payload: updateTransportationType
			});
		}
		return response;
	};

	// Delete Transportation Type
	const deleteTransportationType = async (TransportationTypeId) => {
		const response = await getDeleteAPI(`${apiUrl}/transportation-type/${TransportationTypeId}/delete`);
		if (response.status === 200) {
			dispatch({
				type: DELETE_TRANSPORTATION_TYPE,
				payload: TransportationTypeId
			});
		}
		return response;
	};

	const clearTransportationType = () => {
		dispatch({
			type: FIND_TRANSPORTATION_TYPE,
			payload: {}
		});
	};

	// Post Context Data
	const transportationTypeContextData = {
		transportationTypeState,
		getTransportationTypes,
		addTransportationType,
		findTransportationType,
		updateTransportationType,
		deleteTransportationType,
		showToast,
		setShowToast,
		showAddTransportationTypeModal,
		setShowAddTransportationTypeModal,
		showUpdateTransportationTypeModal,
		setShowUpdateTransportationTypeModal,
		clearTransportationType
	};

	return (
		<TransportationTypeContext.Provider value={transportationTypeContextData}>
			{children}
		</TransportationTypeContext.Provider>
	);
};

export default TransportationTypeContextProvider;
