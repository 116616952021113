import { Fragment, useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { ItineraryContext } from '../../contexts/ItineraryContext';
import { apiUrl, BOOKING_STATUSES } from '../../contexts/constants';
import Moment from 'moment';
//import OperatingSetting from '../../components/layout/operating/OperatingSetting';
import { Link } from 'react-router-dom';
import { getAPI } from '../../utils/api';
import { getBookingCellBackground } from '../../utils/common';
import { useTranslation } from 'react-i18next';

const TableCommandExportOvernight = (props) => {
	const { t } = useTranslation();
	const {
		authState: { user }
	} = useContext(AuthContext);

	const {
		itineraryState: { itineraries },
		getItineraries
	} = useContext(ItineraryContext);

	const cruise = props.cruiseProps;
	const day = props.dayProps;
	const month = props.monthProps;
	const year = props.yearProps;
	const textCheck = props.textCheckProps;
	const cruiseSetting = props.cruiseSetting;

	let oldArray = [];

	const date = `${year}-${month}-${day}`;

	// Start: Get all specials , []
	useEffect(() => getItineraries(''), []); // eslint-disable-line react-hooks/exhaustive-deps

	const [bookings, setDataBookings] = useState([]);

	useEffect(() => {
		async function getBookings() {
			try {
				const response = await getAPI(
					`${apiUrl}/customer/bookings`,
					`start_date=${date}&filterFields=cruise_id&filterFieldValues=${cruise.id}`
				);
				setDataBookings(response.data);
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		getBookings();
	}, [cruise.id]); // eslint-disable-line react-hooks/exhaustive-deps

	const prepareBookingData = (bookings) => {
		let data = {};
		if (bookings) {
			bookings.map((item) => {
				if (item.status !== BOOKING_STATUSES.CANCELED && item.status !== BOOKING_STATUSES.PENDING && item.status !== BOOKING_STATUSES.TRANSFERRED) {
					let yesterday = new Moment(item.start_date).format('YYYY-MM-DD');
					yesterday = Moment(yesterday).add(1, 'day').format('YYYY-MM-DD');

					if (yesterday === date) {
						if (!oldArray[item.booking_id]) {
							oldArray[item.booking_id] = [item];
						} else {
							oldArray[item.booking_id].push(item);
						}
					} else {
						if (!data[item.booking_id]) {
							data[item.booking_id] = [item];
						} else {
							data[item.booking_id].push(item);
						}
					}
				}
			});
		}
		return data;
	};

	const getNameDuration = (idItinerary) => {
		var duration = 0;
		itineraries.map((itinerary) => {
			if (itinerary.id === idItinerary) {
				duration = itinerary.duration;
			}
		});
		return duration;
	};

	const dataBookings = prepareBookingData(bookings);
	const arrTodays = [];
	const arrYesterdays = [];
	Object.keys(dataBookings).forEach((key) => arrTodays.push({ name: key, value: dataBookings[key] }));
	Object.keys(oldArray).forEach((key) => arrYesterdays.push({ name: key, value: oldArray[key] }));
	let allAdult = 0;
	let allChild = 0;
	let allChild11 = 0;
	let allInfan = 0;
	let arrCabin = [];
	let arrNameCabin = [];
	let arrReduce = [];
	//old
	let allAdultOld = 0;
	let allChildOld = 0;
	let allChild11Old = 0;
	let allInfanOld = 0;
	let arrCabinOld = [];
	let arrReduceOld = [];
	let startDate = null;
	let arrUniqueNameRooms = [];

	return (
		<Fragment>
			<table id='table-to-xls' border='1' className='table table-bordered mg-b-0 text-md-nowrap'>
				<thead>
					<tr>
						<th colspan='11'>&nbsp;</th>
					</tr>
					<tr>
						<th colspan='11'>&nbsp;</th>
					</tr>
					<tr>
						<th colspan='11' style={{ lineHeight: '40px' }}>
							{t('Operating Manage Booking Day')}: {Moment(date).format('MMMM D, YYYY')}
						</th>
					</tr>
					<tr style={{ background: '#cdcdcd' }}>
						<th>STT</th>
						<th>{t('Booking code')}</th>
						<th>{t('Status')}</th>
						<th>{t('Partner')}</th>
						<th>{t('Group name')}</th>
						<th>{t('Pick-up')}</th>
						<th>{t('Itinerary')}</th>
						<th>{t('Adults')}</th>
						<th>{cruiseSetting && cruiseSetting.child_label1? t(cruiseSetting.child_label1): t('Children') + '(5-7)'}</th>
						<th>{cruiseSetting && cruiseSetting.child_lable2? t(cruiseSetting.child_lable2): t('Children') + '(8-11)'}</th>
						<th>{t('Infants')}</th>
						<th>{t('Cabins')}</th>
						<th>{t('Cabin notes')}</th>
						<th>{t('Special requests')}</th>
					</tr>
				</thead>
				<tbody>
					{arrTodays
						? arrTodays.map(
								(booking, i) => (
									(arrNameCabin = []),
									booking.value[0].status !== BOOKING_STATUSES.CANCELED &&
									booking.value[0].status !== BOOKING_STATUSES.PENDING && booking.value[0].status !== BOOKING_STATUSES.TRANSFERRED
										? (booking.value[0].adults > 0 ? (allAdult += booking.value[0].adults) : (allAdult += 0),
										  booking.value[0].children > 0 ? (allChild += booking.value[0].children) : (allChild += 0),
										  booking.value[0].children11 > 0 ? (allChild11 += booking.value[0].children11) : (allChild11 += 0),
										  booking.value[0].infants > 0 ? (allInfan += booking.value[0].infants) : (allInfan += 0))
										: '',
									(
										<tr key={i}>
											<td>{i + 1}</td>
											<td>
												<Link
													className='show-booking'
													to={`/bookings/show/${booking.value[0].booking_id}`}
													target='_blank'
												>
													&#8203;{booking.value[0].new_code}
												</Link>
											</td>
											<td>{booking.value[0].status}</td>
											<td>{booking.value[0].partner_name}</td>
											<td>
												{booking.value.map((customer, index) => (
													<span key={index}>
														{index === 0
															? customer.full_name !== undefined
																? customer.full_name
																: ''
															: customer.full_name !== undefined
															? `, ${customer.full_name}`
															: null}
													</span>
												))}
											</td>
											<td dangerouslySetInnerHTML={{ __html: booking.value[0].pickup }} />
											<td>
												{getNameDuration(booking.value[0].cruise_itinerary_id) +
													'N' +
													(getNameDuration(booking.value[0].cruise_itinerary_id) - 1) +
													'D'}
											</td>
											<td>{booking.value[0].adults > 0 ? booking.value[0].adults : 0}</td>
											<td>{booking.value[0].children > 0 ? booking.value[0].children : 0}</td>
											<td>{booking.value[0].children11 > 0 ? booking.value[0].children11 : 0}</td>
											<td>{booking.value[0].infants > 0 ? booking.value[0].infants : 0}</td>
											<td>
												{booking.value.map((customer, index) => {
													if (
														customer.status !== BOOKING_STATUSES.CANCELED &&
														customer.status !== BOOKING_STATUSES.PENDING &&
														customer.status !== BOOKING_STATUSES.TRANSFERRED &&
														customer.room_id
													) {
														arrCabin.push(customer.room_name);
														arrNameCabin.push(customer.room_name);
													}
												})}
												{arrNameCabin &&  arrNameCabin.length > 0
													? [...new Set(arrNameCabin)].map((nameRoom, index) => {
															if (index === 0) {
																return <span key={index}>{nameRoom ? nameRoom : ''}</span>;
															} else {
																return <span key={index}>, {nameRoom ? nameRoom : ''}</span>;
															}
													  })
													: null}
											</td>
											<td>{booking.value[0].cabin_desc}</td>
											<td dangerouslySetInnerHTML={{ __html: booking.value[0].sale_note }} />
										</tr>
									)
								)
						  )
						: ''}
					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td>{allAdult}</td>
						<td>{allChild}</td>
						<td>{allChild11}</td>
						<td>{allInfan}</td>
						<td>
							{
								arrCabin.reduce(
									(accumulator, currentItem) =>
										accumulator.includes(currentItem) ? accumulator : [...accumulator, currentItem],
									[]
								).length
							}
						</td>
						<td></td>
						<td></td>
					</tr>
				</tbody>
				<br />
				{arrYesterdays.length > 0 ? (
					<>
						<thead>
							<tr>
								<th colspan='11'>&nbsp;</th>
							</tr>
							<tr>
								<th colspan='11'>&nbsp;</th>
							</tr>
							<tr>
								<th colspan='11'>&nbsp;</th>
							</tr>
							<tr>
								<th colspan='11' style={{ lineHeight: '40px' }}>
									{t('Operating Manage Booking Day')}: {Moment(`${date}`).subtract(1, 'days').format('MMMM D, YYYY')}{' '}
								</th>
							</tr>
							<tr style={{ background: '#cdcdcd' }}>
								<th>STT</th>
								<th>{t('Booking code')}</th>
								<th>{t('Status')}</th>
								<th>{t('Partner')}</th>
								<th>{t('Group name')}</th>
								<th>{t('Pick-up')}</th>
								<th>{t('Itinerary')}</th>
								<th>{t('Adults')}</th>
								<th>{cruiseSetting && cruiseSetting.child_label1? t(cruiseSetting.child_label1): t('Children') + '(5-7)'}</th>
								<th>{cruiseSetting && cruiseSetting.child_lable2? t(cruiseSetting.child_lable2): t('Children') + '(8-11)'}</th>
								<th>{t('Infants')}</th>
								<th>{t('Cabins')}</th>
								<th>{t('Cabin notes')}</th>
								<th>{t('Special requests')}</th>
							</tr>
						</thead>
						<tbody>
							{arrYesterdays
								? arrYesterdays.map(
										(booking, i) => (
											(arrNameCabin = []),
											booking.value[0].status !== BOOKING_STATUSES.CANCELED &&
											booking.value[0].status !== BOOKING_STATUSES.PENDING &&
											booking.value[0].status !== BOOKING_STATUSES.TRANSFERRED
												? (booking.value[0].adults > 0 ? (allAdultOld += booking.value[0].adults) : (allAdultOld += 0),
												  booking.value[0].children > 0
														? (allChildOld += booking.value[0].children)
														: (allChildOld += 0),
												  booking.value[0].children11 > 0
														? (allChild11Old += booking.value[0].children11)
														: (allChild11Old += 0),
												  booking.value[0].infants > 0 ? (allInfanOld += booking.value[0].infants) : (allInfanOld += 0))
												: '',
											(
												<tr key={i}>
													<td>{i + 1}</td>
													<td>
														<Link
															className='show-booking'
															to={`/bookings/show/${booking.value[0].booking_id}`}
															target='_blank'
														>
															&#8203;{booking.value[0].new_code}
														</Link>
													</td>
													<td>
														<span className={getBookingCellBackground(booking.value[0].status)}>
															{booking.value[0].status}
														</span>
													</td>
													<td>{booking.value[0].partner_name}</td>
													<td>
														{booking.value.map((customer, index) => (
															<span key={index}>
																{index === 0
																	? customer.full_name !== undefined
																		? customer.full_name
																		: ''
																	: customer.full_name !== undefined
																	? `, ${customer.full_name}`
																	: null}
															</span>
														))}
													</td>
													<td dangerouslySetInnerHTML={{ __html: booking.value[0].pickup }} />
													<td>
														{getNameDuration(booking.value[0].cruise_itinerary_id) +
															'N' +
															(getNameDuration(booking.value[0].cruise_itinerary_id) - 1) +
															'D'}
													</td>
													<td>{booking.value[0].adults > 0 ? booking.value[0].adults : 0}</td>
													<td>{booking.value[0].children > 0 ? booking.value[0].children : 0}</td>
													<td>{booking.value[0].children11 > 0 ? booking.value[0].children11 : 0}</td>
													<td>{booking.value[0].infants > 0 ? booking.value[0].infants : 0}</td>
													<td>
														{booking.value.map((customer, index) => {
															if (
																customer.status !== BOOKING_STATUSES.CANCELED &&
																customer.status !== BOOKING_STATUSES.PENDING &&
																customer.status !== BOOKING_STATUSES.TRANSFERRED &&
																customer.room_id
															) {
																arrCabinOld.push(customer.room_name);
																arrNameCabin.push(customer.room_name);
															}
														})}
														{arrNameCabin && arrNameCabin.length > 0
															? [...new Set(arrNameCabin)].map((nameRoom, index) => {
																	if (index === 0) {
																		return <span key={index}>{nameRoom ? nameRoom : ''}</span>;
																	} else {
																		return <span key={index}>, {nameRoom ? nameRoom : ''}</span>;
																	}
															  })
															: null}
													</td>
													<td>{booking.value[0].cabin_desc}</td>
													<td dangerouslySetInnerHTML={{ __html: booking.value[0].sale_note }} />
												</tr>
											)
										)
								  )
								: ''}
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td>{allAdultOld}</td>
								<td>{allChildOld}</td>
								<td>{allChild11Old}</td>
								<td>{allInfanOld}</td>
								<td>
									{
										arrCabinOld.reduce(
											(accumulator, currentItem) =>
												accumulator.includes(currentItem) ? accumulator : [...accumulator, currentItem],
											[]
										).length
									}
								</td>
								<td></td>
								<td></td>
							</tr>
						</tbody>
					</>
				) : (
					''
				)}
			</table>
		</Fragment>
	);
};

export default TableCommandExportOvernight;
