import { Fragment, useEffect, useContext, useState } from 'react';
import NightCheckinItem from './NightCheckinItem';
import { BookingContext } from '../../../contexts/BookingContext';
import { OnboardBookingContext } from '../../../contexts/OnboardBookingContext';
import { Toast } from 'react-bootstrap';
import moment from 'moment';
import { CRUISE_TYPES, apiUrl, BOOKING_STATUSES } from '../../../contexts/constants';
import { getAPI } from '../../../utils/api';
import AddOnboardBookingModal from './AddOnboardBookingModal';

const NightCheckin = props => {

    const {
        bookingState: { bookings },
        getBookingIdsInMonth
    } = useContext(BookingContext)

    const {
        setShowToast,
        showToast: {show, message, type},
    } = useContext(OnboardBookingContext)

    const cruise = props.cruiseProps
    const month = props.monthProps
    const year = props.yearProps
    const daysInMonths = new Date(year, month, 0).getDate();

    const [rooms, setRooms] = useState([]);
    useEffect(() => {
        setRooms(props.roomsProps)
    }, [props.roomsProps]);

    const [dataSettings, setDataSettings] = useState([])
    const arrDateSetting = []

    useEffect( () => getBookingIdsInMonth(cruise.id, month, year, CRUISE_TYPES.NIGHT, [BOOKING_STATUSES.PENDING, BOOKING_STATUSES.CANCELED], 1), [cruise.id, month, year]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect( () =>
    {
        async function settingData(){
            try {
                const response = await getAPI(`${apiUrl}/cruise-day-settings`, `filterFields=cruise_id,oos&filterFieldValues=${cruise.id},true&start_date=${year}-${month}-01&end_date=${year}-${month}-${daysInMonths}`)
                    setDataSettings(response.data)
            } catch (error) {
                return error.response ? error.response : {success: false, message: 'Server error!'}
            }
        }
        settingData()
    }, [cruise.id]) // eslint-disable-line react-hooks/exhaustive-deps

    const arrCountCustomers = []
    const arrDayCountCustomers = []
    const [itineraries, setItineraries] = useState([])

    //get itineraries
    useEffect(() => {
        async function getDataItineraries(){
            try {
                if(cruise){
                    const response = await getAPI(`${apiUrl}/cruise-itineraries`, `filterFields=cruise_id&filterFieldValues=${cruise.id}`)
                    setItineraries(response.data)
                }
            } catch (error) {
                return error.response ? error.response : {success: false, message: 'Server error!'}
            }
        }
        getDataItineraries()
    }, [cruise]) // eslint-disable-line react-hooks/exhaustive-deps

    let groupDataByDate = []

    if (bookings && rooms && rooms.length > 0) {
        rooms.forEach((room, index) => {
            if (bookings[room.id]) {
                const roomData = bookings[room.id]
                arrCountCustomers[room.id] = roomData
                rooms[index].bookings = roomData;

                // group data by date
                const dates = Object.keys(roomData);
                dates.forEach((d) => {
                    if (!groupDataByDate[d]) {
                        groupDataByDate[d] = []
                    }
                    const bookingData = {}
                    roomData[d].forEach((b) => {
                        bookingData[b.booking_id] = {
                            booking_id: b.booking_id,
                            new_code: b.new_code,
                        }
                    })
                    const rData = {
                        roomId: room.id,
                        roomName: room.name,
                        bookings: bookingData
                    }

                    groupDataByDate[d].push(rData)
                })
            }
        });
        
        // eslint-disable-next-line no-lone-blocks
        {Array.from(Array(daysInMonths), (e, i) => {
            const dayCustomer = ("0" + (i+1)).slice(-2);
            const dateCustomer = `${year}-${month.toString().length === 1 ? "0" + month: month}-${dayCustomer}`;
            var countCustomer = 0
            if (rooms && rooms.length > 0) {
                rooms.map((room) => {
                    if(arrCountCustomers !== undefined){
                        if(arrCountCustomers[room.id] !== undefined ){
                            if(arrCountCustomers[room.id][dateCustomer] !== undefined ){
                                if(arrCountCustomers[room.id][dateCustomer].length > 1){
                                    arrCountCustomers[room.id][dateCustomer].map((arrCus) => {
                                        countCustomer += 1
                                        arrDayCountCustomers[i] = countCustomer
                                    })
                                }else{
                                    arrCountCustomers[room.id][dateCustomer].map((arrCus) => {
                                        countCustomer += 1
                                        arrDayCountCustomers[i] = countCustomer
                                    })
                                }
                            }
                        }
                    }
                })
            }
        })}
    }

    if (dataSettings){
        dataSettings.map((setting) => {
            var dateSetting = moment(setting.day_setting).format('YYYY-MM-DD')
            arrDateSetting[dateSetting] = setting.oos
        })
    }


    return (
        <Fragment>
            <div className="fullWidth100">
                <table className="table table-bordered mg-b-0 text-md-nowrap">
                    <thead>
                        <tr>
                            <th className="zui-sticky-col">Cabin</th>
                            <th className="zui-sticky-col2">Type</th>
                            {Array.from(Array(daysInMonths), (e, i) => {
                                return <th key={i}>{i+1}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {rooms && rooms.map((room, index) => (
                            <tr key={index}>
                                <td className="zui-sticky-col">
                                    {room.name} {room.connect_room !== undefined ? ( <><i className="fe fe-repeat"></i> {room.connect_room}</>  ) : null}
                                </td>
                                <td className="zui-sticky-col2 capitalize">{room.bed_type}</td>
                                {Array.from(Array(daysInMonths), (e, i) => {
                                    const day = ("0" + (i+1)).slice(-2);
                                    const date = `${year}-${month.toString().length === 1 ? "0" + month: month}-${day}`; //${year}-${month}-${day}
                                    return <NightCheckinItem key={i} bookingProps={room.bookings ? room.bookings[date] : []} dateSettingProps={arrDateSetting ? arrDateSetting[date] : []} itineraries={itineraries} date={date} roomName={room.name}/>
                                })}
                            </tr>
                        ))}
                        <tr>
                            <td className="zui-sticky-col last-child">&nbsp;</td>
                            <td className="zui-sticky-col2 last-child">&nbsp;</td>
                            {Array.from(Array(daysInMonths), (e, i) => {
                                const day = ("0" + (i+1)).slice(-2);
                                const date = `${year}-${month.toString().length === 1 ? "0" + month: month}-${day}`; 
                                return <td className={arrDateSetting[date] !== undefined ? 'day-black' : ''} key={i}>{arrDayCountCustomers[i]}</td>
                            })}
                        </tr>
                    </tbody>
                </table>
            </div>
            <AddOnboardBookingModal bookingData={groupDataByDate} rooms={rooms} setRooms={setRooms}/>
            <Toast
                show={show}
                style={ {position: 'fixed', top: '20%', right: '10px'} }
                className={`bg-${type} text-white`}
                onClose={setShowToast.bind(this, {show: false, message: '', type: null})}
                delay={3000}
                autohide
            >
                <Toast.Body>
                    <strong style={ {color: '#ffffff'} }>{message}</strong>
                </Toast.Body>
            </Toast>
        </Fragment>
    )
}

export default NightCheckin
