import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const RevenueStatus = props => {
    const { t } = useTranslation();
    const defaultBookings = props.bookingsDefaultProps
    const defaultStatus = props.defaultStatusProps
    const setDataStatus = props.setDataStatusFunc
    const setNewBookings = props.setNewBookingFunc
    const arrChecks = props.arrChecksProps
    const getNameDuration = props.getNameDuration

    let duration = arrChecks[0].iti
    let assign = arrChecks[0].assign
    let cruises = arrChecks[0].cruises
    let idPartner = arrChecks[0].partner

    const statusCheckBox = (event) => {
        var valueData = null
        var dataDefault = []
        if(event.target.checked === true){
            valueData = event.target.value
            defaultStatus.push(valueData)

            if(idPartner > 0 && assign > 0 && duration > 0 && cruises.length > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.partner_id === idPartner && booking.assign_to === assign && getNameDuration(booking.cruise_itinerary_id) === duration && cruises.includes(booking.cruise_id) === true && defaultStatus.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else if(idPartner > 0 && assign > 0 && duration > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.partner_id === idPartner && booking.assign_to === assign && getNameDuration(booking.cruise_itinerary_id) === duration && defaultStatus.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })    
            }else if(idPartner > 0 && assign > 0 && cruises.length > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.partner_id === idPartner && booking.assign_to === assign && cruises.includes(booking.cruise_id) === true && defaultStatus.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else if(idPartner > 0 && duration > 0 && cruises.length > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.partner_id === idPartner && getNameDuration(booking.cruise_itinerary_id) === duration && cruises.includes(booking.cruise_id) === true && defaultStatus.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else if(assign > 0 && duration > 0 && cruises.length > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.assign_to === assign && getNameDuration(booking.cruise_itinerary_id) === duration && cruises.includes(booking.cruise_id) === true && defaultStatus.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else if(idPartner > 0 && assign > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.partner_id === idPartner && booking.assign_to === assign && defaultStatus.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })    
            }else if(idPartner > 0 && duration > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.partner_id === idPartner && getNameDuration(booking.cruise_itinerary_id) === duration && defaultStatus.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else if(idPartner > 0 && cruises.length > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.partner_id === idPartner && cruises.includes(booking.cruise_id) === true && defaultStatus.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else if(assign > 0 && cruises.length > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.assign_to === assign && cruises.includes(booking.cruise_id) === true && defaultStatus.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else if(duration > 0 && cruises.length > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(getNameDuration(booking.cruise_itinerary_id) === duration && cruises.includes(booking.cruise_id) === true && defaultStatus.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else if(idPartner > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.partner_id === idPartner && defaultStatus.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else if(assign > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.assign_to === assign && defaultStatus.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else if(duration > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(getNameDuration(booking.cruise_itinerary_id) === duration && defaultStatus.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else if(cruises.length > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(cruises.includes(booking.cruise_id) === true && defaultStatus.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else{
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(defaultStatus.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }
            setDataStatus(defaultStatus)
            setNewBookings(dataDefault)
        }else{
            var new_arr = []
            valueData = event.target.value
            new_arr = defaultStatus.filter(item => item !== valueData);
            if(idPartner > 0 && assign > 0 && duration > 0 && cruises.length > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.partner_id === idPartner && booking.assign_to === assign && getNameDuration(booking.cruise_itinerary_id) === duration && cruises.includes(booking.cruise_id) === true && new_arr.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else if(idPartner > 0 && assign > 0 && duration > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.partner_id === idPartner && booking.assign_to === assign && getNameDuration(booking.cruise_itinerary_id) === duration && new_arr.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })    
            }else if(idPartner > 0 && assign > 0 && cruises.length > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.partner_id === idPartner && booking.assign_to === assign && cruises.includes(booking.cruise_id) === true && new_arr.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else if(idPartner > 0 && duration > 0 && cruises.length > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.partner_id === idPartner && getNameDuration(booking.cruise_itinerary_id) === duration && cruises.includes(booking.cruise_id) === true && new_arr.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else if(assign > 0 && duration > 0 && cruises.length > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.assign_to === assign && getNameDuration(booking.cruise_itinerary_id) === duration && cruises.includes(booking.cruise_id) === true && new_arr.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else if(idPartner > 0 && assign > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.partner_id === idPartner && booking.assign_to === assign && new_arr.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })    
            }else if(idPartner > 0 && duration > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.partner_id === idPartner && getNameDuration(booking.cruise_itinerary_id) === duration && new_arr.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else if(idPartner > 0 && cruises.length > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.partner_id === idPartner && cruises.includes(booking.cruise_id) === true && new_arr.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else if(assign > 0 && cruises.length > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.assign_to === assign && cruises.includes(booking.cruise_id) === true && new_arr.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else if(duration > 0 && cruises.length > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.duration === duration && cruises.includes(booking.cruise_id) === true && new_arr.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else if(idPartner > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.partner_id === idPartner && new_arr.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else if(assign > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(booking.assign_to === assign && new_arr.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else if(duration > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(getNameDuration(booking.cruise_itinerary_id) === duration && new_arr.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else if(cruises.length > 0){
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(cruises.includes(booking.cruise_id) === true && new_arr.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }else{
                // eslint-disable-next-line array-callback-return
                defaultBookings.map((booking) => {
                    if(new_arr.includes(booking.status) === true){
                        dataDefault.push(booking)
                    }
                })
            }
            setDataStatus(new_arr)
            setNewBookings(dataDefault)
        }
        //const new_arr = arr.filter(item => item !== valueToRemove);
    }

    return (
        <Fragment>
            <div className="form-group">
                <label><strong>{t('Status')}</strong></label>
                <div className="row row-mobile">
                    <div className="col-sm">
                        <div className="checkbox">
                            <div className="custom-checkbox custom-control">
                                <input type="checkbox" className="custom-control-input" id="confirmed" value='Confirmed' onChange={statusCheckBox.bind(this)} defaultChecked />
                                <label htmlFor="confirmed" className="custom-control-label mt-1">{t('Confirmed')}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="checkbox">
                            <div className="custom-checkbox custom-control">
                                <input type="checkbox" className="custom-control-input" id="pending" value='Pending' onChange={statusCheckBox.bind(this)} defaultChecked />
                                <label htmlFor="pending" className="custom-control-label mt-1">{t('Pending')}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="checkbox">
                            <div className="custom-checkbox custom-control">
                                <input type="checkbox" className="custom-control-input" id="canceled" value='Canceled' onChange={statusCheckBox.bind(this)} />
                                <label htmlFor="canceled" className="custom-control-label mt-1">{t('Canceled')}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="checkbox">
                            <div className="custom-checkbox custom-control">
                                <input type="checkbox" className="custom-control-input" id="transferred" value='Transferred' onChange={statusCheckBox.bind(this)} defaultChecked />
                                <label htmlFor="transferred" className="custom-control-label mt-1">{t('Transferred')}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="checkbox">
                            <div className="custom-checkbox custom-control">
                                <input type="checkbox" className="custom-control-input" id="inspection" value='Inspection' onChange={statusCheckBox.bind(this)} />
                                <label htmlFor="inspection" className="custom-control-label mt-1">{t('Inspection')}</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="checkbox">
                            <div className="custom-checkbox custom-control">
                                <input type="checkbox" className="custom-control-input" id="charter" value='Charter' onChange={statusCheckBox.bind(this)} defaultChecked />
                                <label htmlFor="charter" className="custom-control-label mt-1">{t('Charter')}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default RevenueStatus
