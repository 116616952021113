import { Fragment, useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { CarContext } from '../../contexts/CarContext';
import DeleteModal from '../../components/layout/DeleteModal';
import UpdateCarModal from './UpdateCarModal';
import { Card, Spinner } from 'react-bootstrap';
import { ROLES, PERMISSIONS } from '../../contexts/constants';
import { getTable, getTableOptions, getDefaultSort } from '../../utils/table';
import { useTranslation } from 'react-i18next';

const ListCars = () => {
	const { t } = useTranslation();
	// Context
	const {
		authState: { user }
	} = useContext(AuthContext);

	const {
		carState: { car, cars, carsLoading },
		getCars,
		findCar,
		deleteCar,
		setShowUpdateCarModal,
		setShowToast
	} = useContext(CarContext);

	// Start: Get all cruise , []
	useEffect(() => getCars(), []); // eslint-disable-line react-hooks/exhaustive-deps

	const chooseCar = (carId) => {
		findCar(carId);
		setShowUpdateCarModal(true);
	};

	if (carsLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info'></Spinner>
			</div>
		);
	}
	if (cars === null || cars.length === 0) {
		return (
			<Fragment>
				<Card className='text-center mx-5 my-5'>
					<Card.Header as='h1'>{t('Hi')} {user.name}</Card.Header>
					<Card.Body>
						<Card.Title>{t('Welcome to Car Manage')}</Card.Title>
						<Card.Text>{t('Click the Add Car button to create your first Car')}</Card.Text>
					</Card.Body>
				</Card>
				{car !== null && <UpdateCarModal />}
			</Fragment>
		);
	}
	const carTable = getListCarTable(cars, user, chooseCar, deleteCar, setShowToast, t);
	return (
		<Fragment>
			{carTable}
			{car !== null && <UpdateCarModal />}
		</Fragment>
	);
};

const getTableData = (cars, user, chooseCar, deleteCar, setShowToast, t) => {
	return cars.map((car, index) => {
		return {
			index: index + 1,
			name: car.label,
			capacity: car.capacity,
			description: car.description,
			action: (
				<div className='row'>
					<div className='col-sm'>
						{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
						user.Permissions.includes(PERMISSIONS.UPDATE_CAR) ? (
							<button className='btn btn-success btn-with-icon' onClick={chooseCar.bind(this, car.id)}>
								<i className='fe fe-edit'></i> {t('Edit')}
							</button>
						) : null}
					</div>
					<div className='col-sm'>
						{/* {(user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) || user.Permissions.includes(PERMISSIONS.DELETE_CAR)) ? <DeleteModal idProps={car.id} deleteFunc={deleteCar} setShowToast={setShowToast} />: null } */}
					</div>
				</div>
			)
		};
	});
};

const getColumns = (t) => {
	return [
		{
			dataField: 'index',
			text: 'STT',
			sort: true
		},
		{
			dataField: 'name',
			text: t('Name'),
			sort: true
		},
		{
			dataField: 'capacity',
			text: t('Capacity'),
			sort: true
		},
		{
			dataField: 'description',
			text: t('Description'),
			sort: true
		},
		{
			dataField: 'action',
			text: ''
		}
	];
};

const getListCarTable = (cars, user, chooseCar, deleteCar, setShowToast, t) => {
	if (!cars || cars.length === 0) return null;
	const tableData = getTableData(cars, user, chooseCar, deleteCar, setShowToast, t);
	const columns = getColumns(t);
	const defaultSorted = getDefaultSort();
	const options = getTableOptions(tableData.length, t);

	return getTable(tableData, columns, defaultSorted, options);
};

export default ListCars;
