import { USER_ROLES_LOADED_SUCCESS, USER_ROLES_LOADED_FAIL, ADD_USER_ROLE, FIND_USER_ROLE, UPDATE_USER_ROLE, DELETE_USER_ROLE } from '../contexts/constants';

export const userRoleReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case USER_ROLES_LOADED_SUCCESS:
            return {
                ...state,
                userRoles: payload,
                userRolesLoading: false
            }
        case USER_ROLES_LOADED_FAIL:
            return {
                ...state,
                userRoles: [],
                userRolesLoading: false
            }
        case ADD_USER_ROLE:
            {
                return {
                    ...state,
                    userRoles: [...state.userRoles, payload],
                }
            }
        case FIND_USER_ROLE:
            return {
                ...state,
                userRole: payload
            }
        case UPDATE_USER_ROLE:
            const newUserRole = state.userRoles.map(userRole => {
                if (userRole.id === payload.id) {
                    return payload
                } else {
                    return userRole
                }
            })
            return {
                ...state,
                userRoles: newUserRole
            }
        case DELETE_USER_ROLE:
            return {
                ...state,
                userRoles: state.userRoles.filter(userRole => userRole.id !== payload)
            }
        default:
            return state
    }
}