import { Fragment, useContext, useState, useEffect } from 'react';
import { PermissionRoleContext } from '../../contexts/PermissionRoleContext';
import { PermissionContext } from '../../contexts/PermissionContext';
import { RoleContext } from '../../contexts/RoleContext';
import { Button, Form, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { apiUrl } from '../../contexts/constants';
import { postAPI } from '../../utils/api';

const groupPermissionByColumn = (permissions, columnLength) => {
	if (!permissions || (permissions && permissions.length == 0)) {
		return [];
	}
	let optionPermissions = [];
	let groupedPermissions = [];
	let groupedItems = [];
	let columnLen = Math.round(permissions.length / columnLength);
	for (let i = 0; i < permissions.length; i++) {
		optionPermissions = [
			...optionPermissions,
			{
				value: permissions[i].id,
				name: permissions[i].get_booking_history,
				label: permissions[i].display_name
			}
		];

		if (i % columnLen === 0) {
			groupedItems = [];
		}
		groupedItems = [...groupedItems, permissions[i]];
		if (i + 1 === permissions.length || i % columnLen === columnLen - 1) {
			groupedPermissions = [...groupedPermissions, groupedItems];
		}
	}

	return groupedPermissions;
};

const orderPermisisonsByGroup = (permissions) => {
	permissions.sort((a, b) => {
		if (a.pgroup < b.pgroup) {
			return -1;
		}
		if (a.pgroup > b.pgroup) {
			return 1;
		}
		return 0;
	});

	let pData = [];
	let addGroupPermisisons = [];
	permissions.forEach((p) => {
		if (!p.pgroup) {
			p.pgroup = 'Other';
		}
		if (!pData[p.pgroup]) {
			pData[p.pgroup] = p.pgroup;
			addGroupPermisisons.push(p.pgroup);
		}
		addGroupPermisisons.push(p);
	});

	return addGroupPermisisons;
};

const UpdatePermissionRole = ({ editRoleID, setEditRoleID, roles, permissionRoles }) => {
	const {
		permissionState: { permissions },
		getPermissions
	} = useContext(PermissionContext);

	const { showUpdateRolePermissionModal, setShowUpdateRolePermissionModal, setShowToast } = useContext(RoleContext);

	const { setRolePermissions } = useContext(PermissionRoleContext);

	// Start: Get all specials , []
	useEffect(() => getPermissions(), []); // eslint-disable-line react-hooks/exhaustive-deps

	const [currentRID, setCurrentRID] = useState(editRoleID);
	const [currentRolePermissions, setCurrentRolePermissions] = useState([]);

	const [newPermissionRole, setNewPermissionRole] = useState({
		role_id: Number(editRoleID)
	});

	useEffect(() => {
		setCurrentRID(editRoleID);
	}, [editRoleID]);

	let optionRoles = [];
	let sortedPermissions = orderPermisisonsByGroup(permissions);
	let groupedPermissions = groupPermissionByColumn(sortedPermissions, 3);

	if (roles) {
		roles.map(
			(role) =>
				(optionRoles = [
					...optionRoles,
					{
						value: role.id,
						label: role.display_name
					}
				])
		);
	}

	// Start: Get all specials , []
	useEffect(() => {
		if (permissionRoles) {
			let arrText = [];
			permissionRoles.map((permissionRole) => {
				if (permissionRole.role_id === Number(currentRID)) {
					arrText.push(Number(permissionRole.permission_id));
				}
			});
			setCurrentRolePermissions(arrText);
		}
		setNewPermissionRole({ ...newPermissionRole, role_id: Number(currentRID) });
		sortedPermissions = orderPermisisonsByGroup(permissions);
		groupedPermissions = groupPermissionByColumn(sortedPermissions, 3);
	}, [currentRID]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleChangeChk = (e) => {
		let updatedList = [...currentRolePermissions];
		// if (checked) { <- this part, checked is the array of checked item right?
		if (e.target.checked) {
			updatedList = [...currentRolePermissions, Number(e.target.value)];
		} else {
			updatedList.splice(currentRolePermissions.indexOf(Number(e.target.value)), 1);
		}
		setCurrentRolePermissions(updatedList);
	};

	const handleChange = (newValue) => {
		const dataOption = newValue;
		if (dataOption) {
			setCurrentRID(Number(dataOption.value));
		}
	};

	const addPermissionRole = async (newPermission) => {
		try {
			const response = await postAPI(`${apiUrl}/role-permission/update-multiple`, newPermission);
			if (response) {
				return response;
			}
		} catch (error) {
			return error.response ? error.response : { success: false, message: 'Server error!' };
		}
	};

	const onSubmit = async (event) => {
		event.preventDefault();
		newPermissionRole['permission_ids'] = currentRolePermissions;
		const response = await addPermissionRole(newPermissionRole);
		if (response.status === 200) {
			let newRolePermissions = [];
			// set user roles
			permissionRoles.forEach((permissionRole) => {
				if (permissionRole.role_id == currentRID) {
					return;
				}
				newRolePermissions.push(permissionRole);
			});
			currentRolePermissions.forEach((permissionId) => {
				const userRole = {
					role_id: newPermissionRole.role_id,
					permission_id: permissionId
				};
				newRolePermissions.push(userRole);
			});
			setRolePermissions(newRolePermissions);
			closeDialog();
			setShowToast({ show: true, message: 'Updated successful!', type: 'success' });
			return;
		}

		setShowToast({
			show: true,
			message: response.error && response.error.ErrorCode ? response.error.ErrorCode : 'Update failed!',
			type: 'danger'
		});
	};

	const closeDialog = () => {
		setNewPermissionRole({
			role_id: ''
		});
		setEditRoleID(0);
		setShowUpdateRolePermissionModal(false);
	};

	return (
		<Fragment>
			<Modal
				show={showUpdateRolePermissionModal}
				onHide={closeDialog}
				size='lg'
				aria-labelledby='contained-modal-title-vcenter'
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Set Permissions</Modal.Title>
				</Modal.Header>
				<Form onSubmit={onSubmit}>
					<Modal.Body>
						<div className='form-group'>
							<label>
								<strong>Role</strong>
							</label>
							<Select
								isClearable
								defaultValue={optionRoles.filter((option) =>
									Number(option.value) === Number(editRoleID) ? { label: option.label, value: option.value } : ''
								)}
								name='roles'
								onChange={handleChange.bind(this)}
								options={optionRoles}
								placeholder='Choose Role'
								menuPosition={'fixed'}
								styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
							/>
						</div>
						<div className='form-group'>
							<label>
								<strong>Permissions</strong>
							</label>
							<div className='row'>
								{groupedPermissions.map((groupedItem, index) => (
									<div className='col-md-4'>
										{groupedItem.map((permission, index) => {
											if (permission.id) {
												return (
													<div className='checkbox'>
														<div className='custom-checkbox custom-control'>
															<input
																type='checkbox'
																data-checkboxes='mygroup'
																name={`checkbox-${permission.id}`}
																className='custom-control-input'
																id={`checkbox-${permission.id}`}
																value={permission.id}
																onChange={handleChangeChk.bind(this)}
																checked={currentRolePermissions.find((t) => (t === permission.id ? true : false))}
															/>
															<label htmlFor={`checkbox-${permission.id}`} className='custom-control-label mt-1'>
																{permission.display_name}
															</label>
														</div>
													</div>
												);
											}

											return <h6 className='p-group'>{permission}</h6>;
										})}
									</div>
								))}
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button className='btn btn-danger btn-with-icon' variant='secondary' onClick={closeDialog}>
							<i className='fe fe-x-circle'></i> Cancel
						</Button>
						<Button className='btn btn-primary btn-with-icon' variant='primary' type='submit'>
							<i className='fe fe-save'></i> Save!!!
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</Fragment>
	);
};

export default UpdatePermissionRole;
