import { useState, useContext } from 'react';
import { GuideContext } from '../../../contexts/GuideContext';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const AddGuideModal = () => {
    const { t } = useTranslation();
    // Context
    const {
        addGuide,
        showAddGuideModal, 
        setShowAddGuideModal,
        setShowToast
    } = useContext(GuideContext)

    // State
    const [newGuide, setNewGuide] = useState({
        title: '',
        name: '',
        email: '',
        phone: '',
        address: '',
        description: '',
        daily_salary: 0,
        service_type: '',
        status: 'no'
    })

    const {title, name, email, phone, address, description, daily_salary, service_type} = newGuide

    const onChangeNewGuide = event => setNewGuide( {...newGuide, [event.target.name]: event.target.value } )
    const onChangeNewGuideNumber = event => setNewGuide( {...newGuide, [event.target.name]: Number(event.target.value) } )

    const onSubmit = async event => {
        event.preventDefault()
        
        const response = await addGuide(newGuide)
        if (response.status === 200) {
            resetAddRoomData()
            setShowToast({show: true, message: t('Added successful!'), type: 'success'})
            return
        }
        setShowToast({show: true, message: response.error.ErrorCode, type: 'danger'})
    }

    const closeDialog = () => {
        resetAddRoomData()
    }

    const resetAddRoomData = () => {
        setNewGuide({
            title: '',
            name: '',
            email: '',
            phone: '',
            address: '',
            description: '',
            daily_salary: 0,
            service_type: '',
            status: 'no'
        })
        setShowAddGuideModal(false)
    }

    return (
        <Modal show={showAddGuideModal} onHide={closeDialog}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('Create New Guide/Driver')}
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label><strong>{t('Title')}</strong></label>
                                <select name="title" value={title} className="SlectBox form-control" required aria-describedby='title-help' onChange={onChangeNewGuide}>
                                    <option label={t("Choose Title")}></option>
                                    <option value="Mr">Mr</option>
                                    <option value="Mrs">Mrs</option>
                                    <option value="Ms">Ms</option>
                                    <option value="Dr">Dr</option>
                                </select>
                                <Form.Text id='title-help' muted>{t('Required')}</Form.Text>
                                
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="form-group">
                                <label><strong>{t('Name')}</strong></label>
                                <input type="text" className="form-control" id="nameCruise" name="name" value={name} placeholder={t("Full Name")} required aria-describedby='name-help' onChange={onChangeNewGuide} />
                                <Form.Text id='name-help' muted>{t('Required')}</Form.Text>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group">
                                <label><strong>{t('Phone Number')}</strong></label>
                                <input type="text" className="form-control" id="phone" name="phone" value={phone} placeholder={t("Phone Number")} required aria-describedby='phone-help' onChange={onChangeNewGuide} />
                                <Form.Text id='phone-help' muted>{t('Required')}</Form.Text>
                                
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <label><strong>{t('Type')}</strong></label>
                                <select name="service_type" value={service_type} className="SlectBox form-control" required aria-describedby='type-help' onChange={onChangeNewGuide}>
                                    <option label={t("Choose Type")}></option>
                                    <option value="car">{t('Driver')}</option>
                                    <option value="guide">{t('Guide')}</option>
                                </select>
                                <Form.Text id='type-help' muted>{t('Required')}</Form.Text>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group">
                                <label><strong>{t('Email')}</strong></label>
                                <input type="text" className="form-control" id="email" name="email" value={email} placeholder={t("Enter Email")} onChange={onChangeNewGuide} />
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <label><strong>{t('Daily Salary')}</strong></label>
                                <input type="text" className="form-control" id="daily_salary" name={t("daily_salary")} value={daily_salary} placeholder={t("No. Money")} onChange={onChangeNewGuideNumber} />
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label><strong>{t('Address')}</strong></label>
                                <input type="text" className="form-control" id="address" name="address" value={address} placeholder={t("Enter Address")} onChange={onChangeNewGuide} />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label><strong>{t('Description')}</strong></label>
                                <textarea className="form-control" placeholder={t("Description")} rows="3" name='description' defaultValue={description} spellCheck="false" onChange={onChangeNewGuide}></textarea>
                            </div>
                        </div>
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> {t('Cancel')}</Button>
                    <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> {t('Save')}!!!</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default AddGuideModal
