import { Fragment, useEffect, useState } from 'react';
import Select from 'react-select';
import { getOptions, findOptionItem } from '../../../utils/common';
import { useTranslation } from 'react-i18next';
const RevenuePartner = (props) => {
	const { t } = useTranslation();
	const filterDataRevenue = props.filterDataRevenue;
	const applyFilter = props.applyFilter;
	const partners = props.partners;
	const [optionPartners, setOptionPartners] = useState([]);
	useEffect(() => {
		const partnerOpts = getOptions(partners, ['label', 'value'], ['name', 'id']);
		setOptionPartners(partnerOpts);
	}, [partners]);

	return (
		<Fragment>
			<div className='form-group'>
				<label>
					<strong>{t('Partner')}</strong>
				</label>
				<Select
					isClearable
					name='partner_id'
					onChange={applyFilter.bind(this)}
					menuPosition={'fixed'}
					menuPortalTarget={document.body}
					options={optionPartners}
					value={findOptionItem(optionPartners, filterDataRevenue.partner_id)}
					placeholder={t('Choose Partner')}
				/>
			</div>
		</Fragment>
	);
};

export default RevenuePartner;
