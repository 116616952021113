import { Fragment, useContext, useEffect } from 'react';
import { CruiseContext } from '../../contexts/CruiseContext';
import TopAccountTrItem from './TopAccountTrItem';
import { useTranslation } from 'react-i18next';
import { sort } from '../../utils/common';

const TopAccountTable = props => {
    const { t } = useTranslation();
    const partnerRevenues = props.bookingsProps
    const allTotalPeople = props.allTotalPeople

    const { 
        cruiseState: { cruises },
        getCruises
    } = useContext(CruiseContext)

    useEffect( () => getCruises(), []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fragment>
            <div className="table-responsive revenue-table">
                <div className="fullWidth100 widthFull">
                    <table border="1" className="table table-bordered mg-b-0 text-md-nowrap">
                        <thead>
                            <tr>
                                <th className="th-style" rowSpan="2">STT</th>
                                <th className="th-style" rowSpan="2">{t('Partner')}</th>
                                <th className="th-style" rowSpan="2">{t('Assign To')}</th>
                                <th colSpan="3">{t('Total')}</th>
                                {cruises ? cruises.map((cruise, index) => (
                                    <Fragment key={index}>
                                        <th colSpan="3">{cruise.name}</th>
                                        {/* <th colSpan="2">{t('Com')} ({cruise.name})</th>    */}
                                    </Fragment>
                                )) : null}
                                <th className="th-style" rowSpan="2">Bookings</th>
                                <th className="th-style" rowSpan="2">{t('Reach')} %</th>
                                {/* <th colSpan="2">{t('Total Com')}</th> */}
                            </tr>
                            <tr>
                                <th>USD</th>
                                <th>VNĐ</th>
                                <th>{t('Pax')}</th>
                                {cruises ? cruises.map((cruise, index) => (
                                    <Fragment key={index}>
                                        <th>USD</th>
                                        <th>VNĐ</th>
                                        <th className='main-color'>{t('Pax')}</th>
                                        {/* <th>USD</th> */}
                                        {/* <th>VNĐ</th> */}
                                    </Fragment>
                                )) : null}
                                {/* <th>USD</th> */}
                                {/* <th>VNĐ</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {partnerRevenues && partnerRevenues.map((partner, index) => (
                                <TopAccountTrItem key={index} partnerIDProps={partner.partner_id} sttProps={index+1} bookingsProps={partner} cruisesProps={cruises} allTotalPeopleProps={allTotalPeople} />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>        
        </Fragment>
    )
}

export default TopAccountTable
