import { useContext, useState, useEffect } from 'react';
import { TransportationTypeContext } from '../../contexts/TransportationTypeContext';
import { Button, Form, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { getOptions, findOptionItem, moneyOptions } from '../../utils/common';
import { useTranslation } from 'react-i18next';

const UpdateTransportationTypeModal = () => {
	const { t } = useTranslation()
	const {
		transportationTypeState: { transportationType },
		showUpdateTransportationTypeModal,
		showAddTransportationTypeModal,
		setShowAddTransportationTypeModal,
		setShowUpdateTransportationTypeModal,
		updateTransportationType,
		addTransportationType,
		setShowToast,
		clearTransportationType
	} = useContext(TransportationTypeContext);
	const [updatedTransportationType, setUpdatedTransportationType] = useState(
		transportationType ? transportationType : {}
	);

	const { id, name, description, currency, price } = updatedTransportationType;
	useEffect(() => {
		setUpdatedTransportationType(transportationType ? transportationType : {});
	}, [transportationType]);
	const onChangeText = (event) => {
		setUpdatedTransportationType({
			...updatedTransportationType,
			[event.target.name]: event.target.name === 'price' ? Number(event.target.value) : event.target.value
		});
	};
	const onChangeSelect = (newValue, actionMeta) => {
		if (!newValue) {
			return;
		}
		switch (actionMeta.name) {
			case 'currency':
				setUpdatedTransportationType({
					...updatedTransportationType,
					currency: newValue.value
				});
				break;
			default:
				break;
		}
	};
	const onSubmit = async (event) => {
		event.preventDefault();

		// add transportation Type
		if (showAddTransportationTypeModal) {
			delete updatedTransportationType.id;
			const response = await addTransportationType(updatedTransportationType);
			if (response.data) {
				closeDialog();
				setShowToast({ show: true, message: t('Added successful!'), type: 'success' });
				return;
			}
			setShowToast({
				show: true,
				message: response.error && response.error.ErrorCode ? response.error.ErrorCode : 'Add failed!',
				type: 'danger'
			});
			return;
		}

		// update transportation Type
		const response = await updateTransportationType(updatedTransportationType);
		if (response.status === 200) {
			closeDialog();
			setShowToast({
				show: true,
				message: t('Updated successful!'),
				type: 'success'
			});
			return;
		}
		setShowToast({
			show: true,
			message: response.error && response.error.ErrorCode ? response.error.ErrorCode : 'Update failed!',
			type: 'danger'
		});
	};
	const closeDialog = () => {
		clearTransportationType();
		setShowUpdateTransportationTypeModal(false);
		setShowAddTransportationTypeModal(false);
	};

	return (
		<Modal
			show={showAddTransportationTypeModal || showUpdateTransportationTypeModal}
			onHide={closeDialog}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>
					{showUpdateTransportationTypeModal ? t('Update Transportation Type') : t('Create New Transportation Type')}
				</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					<div className='row'>
						<div className='col-sm'>
							<div className='form-group'>
								<label>{t('Name')}</label>
								<input
									type='text'
									className='form-control'
									name='name'
									value={name}
									placeholder={t('Enter Transportation Type Name')}
									required
									aria-describedby='name-help'
									onChange={onChangeText}
								/>
								<Form.Text id='name-help' muted>
									{t('Required')}
								</Form.Text>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-sm'>
							<div className='form-group'>
								<label>{t('Currency')}</label>
								<Select
									placeholder={t('Choose currency')}
									name='currency'
									value={findOptionItem(moneyOptions, currency)}
									options={moneyOptions}
									onChange={onChangeSelect.bind(this)}
									required
								/>
							</div>
						</div>
						<div className='col-sm'>
							<div className='form-group'>
								<label>{t('Price')}</label>
								<input
									type='number'
									step='any'
									className='form-control'
									placeholder={t('Price')}
									name='price'
									value={price}
									onChange={onChangeText}
									required
								></input>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-12'>
							<div className='form-group'>
								<label>{t('Description')}</label>
								<textarea
									className='form-control'
									placeholder={t('Description')}
									rows='3'
									name='description'
									value={description}
									spellCheck='false'
									onChange={onChangeText}
								></textarea>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button className='btn btn-danger btn-with-icon' variant='secondary' onClick={closeDialog}>
						<i className='fe fe-x-circle'></i> {t('Cancel')}
					</Button>
					<Button className='btn btn-primary btn-with-icon' variant='primary' type='submit'>
						<i className='fe fe-save'></i> {t('Save')}!!!
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default UpdateTransportationTypeModal;
