import { createContext, useReducer, useState } from 'react';
import { priceReducer } from '../reducers/priceReducer';
import {
	apiUrl,
	PRICES_LOADED_SUCCESS,
	PRICES_LOADED_FAIL,
	ADD_PRICE,
	FIND_PRICE,
	UPDATE_PRICE,
	DELETE_PRICE
} from './constants';
import axios from 'axios';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';

export const PriceContext = createContext();

const PriceContextProvider = ({ children }) => {
	// State
	const [priceState, dispatch] = useReducer(priceReducer, {
		price: null,
		prices: [],
		pricesLoading: true
	});

	const [showAddPriceModal, setShowAddPriceModal] = useState(false);
	const [showUpdatePriceModal, setShowUpdatePriceModal] = useState(false);
	const [showPriceModal, setShowPriceModal] = useState(false);
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null
	});

	// Get all Prices
	const getPrices = async () => {
		const response = await getAPI(`${apiUrl}/prices?orderBy=id&direction=desc`);
		if (response.data) {
			dispatch({ type: PRICES_LOADED_SUCCESS, payload: response.data });
			return response;
		}

		dispatch({ type: PRICES_LOADED_FAIL });
		return response;
	};

	// Add Price
	const addPrice = async (addPrice) => {
		const response = await postAPI(`${apiUrl}/price/insert-multiple`, addPrice);
		if (response.data) {
			dispatch({
				type: ADD_PRICE,
				payload: response.data
			});
		}
		return response;
	};

	// Find Price when user is updating Price
	const findPrice = (priceId) => {
		const price = priceState.prices.find((price) => price.id === priceId);
		dispatch({
			type: FIND_PRICE,
			payload: price
		});
	};

	// Update Price
	const updatePrice = async (updatePrice) => {
		const response = await postAPI(`${apiUrl}/price/update`, updatePrice);
		if (response.status === 200) {
			dispatch({
				type: UPDATE_PRICE,
				payload: updatePrice
			});
		}
		return response;
	};

	// Delete Price
	const deletePrice = async (priceId) => {
		const response = await getDeleteAPI(`${apiUrl}/price/${priceId}/delete`);
		if (response.status === 200) {
			dispatch({
				type: DELETE_PRICE,
				payload: priceId
			});
		}
		return response;
	};

	const priceContextData = {
		priceState,
		getPrices,
		addPrice,
		findPrice,
		updatePrice,
		deletePrice,
		showAddPriceModal,
		setShowAddPriceModal,
		showUpdatePriceModal,
		setShowUpdatePriceModal,
		showToast,
		setShowToast,
		showPriceModal,
		setShowPriceModal
	};

	return <PriceContext.Provider value={priceContextData}>{children}</PriceContext.Provider>;
};

export default PriceContextProvider;
