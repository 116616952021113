import { createContext, useState, useReducer } from 'react';
import { cruiseReducer } from '../reducers/cruiseReducer';
import {
	apiUrl,
	CRUISES_LOADED_SUCCESS,
	CRUISES_LOADED_FAIL,
	ADD_CRUISE,
	FIND_CRUISE,
	UPDATE_CRUISE,
	DELETE_CRUISE,
	A_CRUISE,
} from './constants';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';

export const CruiseContext = createContext();

const CruiseContextProvider = ({ children }) => {
	// State
	const [cruiseState, dispatch] = useReducer(cruiseReducer, {
		cruise: null,
		cruises: [],
		cruisesLoading: true,
	});

	const [showAddCruiseModal, setShowAddCruiseModal] = useState(false);
	const [showUpdateCruiseModal, setShowUpdateCruiseModal] = useState(false);
	const [showCruiseModal, setShowCruiseModal] = useState(false);
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null,
	});

	// Get all Cruises
	const getCruises = async () => {
		const response = await getAPI(`${apiUrl}/cruises`);
		if (response.status === 200) {
			dispatch({ type: CRUISES_LOADED_SUCCESS, payload: response.data });
			return response;
		}

		dispatch({ type: CRUISES_LOADED_FAIL });
		return response;
	};

	// Add Cruise
	const addCruise = async (newCruise) => {
		const response = await postAPI(`${apiUrl}/cruise/insert`, newCruise);
		if (response.status === 200) {
			dispatch({
				type: ADD_CRUISE,
				payload: response.data,
			});
		}
		return response;
	};

	// Find Cruise when user is updating post
	const findCruise = (cruiseId) => {
		const cruise = cruiseState.cruises.find((cruise) => cruise.id === cruiseId);
		dispatch({
			type: FIND_CRUISE,
			payload: cruise,
		});
	};

	// updateCruise
	const updateCruise = async (updateCruise) => {
		const response = await postAPI(`${apiUrl}/cruise/update`, updateCruise);
		if (response.status === 200) {
			dispatch({
				type: UPDATE_CRUISE,
				payload: updateCruise,
			});
		}
		return response;
	};

	// Find a cruise
	const FindACruise = async (cruiseId) => {
		if (cruiseId) {
			const response = await getAPI(`${apiUrl}/cruise/${cruiseId}`);
			if (response.status === 200) {
				dispatch({
					type: A_CRUISE,
					payload: response.data,
				});
			}
			return response;
		}
	};

	// Delete Cruise
	const deleteCruise = async (cruiseId) => {
		const response = await getDeleteAPI(`${apiUrl}/cruise/${cruiseId}/delete`);
		if (response.status === 200) {
			dispatch({
				type: DELETE_CRUISE,
				payload: cruiseId,
			});
		}
		return response;
	};
	const clearCruise = () => {
		dispatch({
			type: FIND_CRUISE,
			payload: {},
		});
	};

	// Post Context Data
	const cruiseContextData = {
		cruiseState,
		getCruises,
		addCruise,
		findCruise,
		FindACruise,
		clearCruise,
		updateCruise,
		deleteCruise,
		showToast,
		setShowToast,
		showAddCruiseModal,
		setShowAddCruiseModal,
		showUpdateCruiseModal,
		setShowUpdateCruiseModal,
		showCruiseModal,
		setShowCruiseModal,
	};

	return <CruiseContext.Provider value={cruiseContextData}>{children}</CruiseContext.Provider>;
};

export default CruiseContextProvider;
