import { BOOKINGS_LOADED_SUCCESS, BOOKINGS_LOADED_FAIL, ADD_BOOKING, FIND_BOOKING, UPDATE_BOOKING, DELETE_BOOKING, A_BOOKING, CUSTOMERS_BOOKINGS_LOADED_SUCCESS, CUSTOMERS_DAY_BOOKINGS_LOADED_SUCCESS } from '../contexts/constants';

export const bookingReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case CUSTOMERS_DAY_BOOKINGS_LOADED_SUCCESS:
            return {
                ...state,
                bookings: payload,
                bookingsLoading: false
            }
        case CUSTOMERS_BOOKINGS_LOADED_SUCCESS:
            return {
                ...state,
                bookings: payload,
                bookingsLoading: false
            }
        case BOOKINGS_LOADED_SUCCESS:
            return {
                ...state,
                bookings: payload,
                bookingsLoading: false
            }
        case BOOKINGS_LOADED_FAIL:
            return {
                ...state,
                bookings: [],
                bookingsLoading: false
            }
        case ADD_BOOKING:
            {
                return {
                    state,
                    //bookings: [...state.bookings, payload],
                }
            }
        case FIND_BOOKING:
            return {
                ...state,
                booking: payload
            }
        case A_BOOKING:
            return {
                ...state,
                booking: payload
            }
        case UPDATE_BOOKING:
            const newBooking = state.bookings.map(booking => {
                if (booking.id === payload.id) {
                    return payload
                } else {
                    return booking
                }
            })
            return {
                ...state,
                bookings: newBooking
            }
        case DELETE_BOOKING:
            return {
                ...state,
                bookings: state.bookings.filter(booking => booking.id !== payload)
            }
        default:
            return state
    }
}