import { createContext, useState, useReducer } from 'react';
import { bookingExpireReducer } from '../reducers/bookingExpireReducer';
import {
	apiUrl,
	BOOKINGS_PENDING_LOADED_SUCCESS,
	BOOKINGS_PENDING_LOADED_FAIL,
	BOOKINGS_PAYMENT_LOADED_SUCCESS,
	BOOKINGS_PAYMENT_LOADED_FAIL,
	BOOKINGS_DEPOSIT_LOADED_SUCCESS,
	BOOKINGS_DEPOSIT_LOADED_FAIL
} from './constants';
import { getAPI } from '../utils/api';

export const BookingExpireContext = createContext();

const BookingExpireContextProvider = ({ children }) => {
	// State
	const [bookingExpireState, dispatch] = useReducer(bookingExpireReducer, {
		bookingPending: null,
		bookingsPending: [],
		bookingsPendingLoading: true,
		bookingPayment: null,
		bookingsPayment: [],
		bookingsPaymentLoading: true,
		bookingDeposit: null,
		bookingsDeposit: [],
		bookingsDepositLoading: true
	});
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null
	});

	const getExpirePending = async (objectValidate) => {
		try {
			const response = await getAPI(`${apiUrl}/bookings/expire-pending`, `${objectValidate}`);
			if (response.data) {
				dispatch({
					type: BOOKINGS_PENDING_LOADED_SUCCESS,
					payload: response.data
				});
			}
		} catch (error) {
			dispatch({ type: BOOKINGS_PENDING_LOADED_FAIL });
			return error.response ? error.response : { success: false, message: 'Server error!' };
		}
	};

	const getExpirePayment = async (objectValidate) => {
		try {
			const response = await getAPI(`${apiUrl}/bookings/expire-payment`, `${objectValidate}`);
			if (response.data) {
				dispatch({
					type: BOOKINGS_PAYMENT_LOADED_SUCCESS,
					payload: response.data
				});
			}
		} catch (error) {
			dispatch({ type: BOOKINGS_PAYMENT_LOADED_FAIL });
			return error.response ? error.response : { success: false, message: 'Server error!' };
		}
	};

	const getExpireDeposit = async (objectValidate) => {
		try {
			const response = await getAPI(`${apiUrl}/bookings/expire-deposite`, `${objectValidate}`);
			if (response.data) {
				dispatch({
					type: BOOKINGS_DEPOSIT_LOADED_SUCCESS,
					payload: response.data
				});
			}
		} catch (error) {
			dispatch({ type: BOOKINGS_DEPOSIT_LOADED_FAIL });
			return error.response ? error.response : { success: false, message: 'Server error!' };
		}
	};

	const bookingPendingContextData = {
		bookingExpireState,
		setShowToast,
		showToast,
		getExpirePending,
		getExpirePayment,
		getExpireDeposit
	};

	return <BookingExpireContext.Provider value={bookingPendingContextData}>{children}</BookingExpireContext.Provider>;
};

export default BookingExpireContextProvider;
