import { createContext, useReducer, useState } from 'react';
import { onboardBookingReducer } from '../reducers/onboardBookingReducer';
import { apiUrl, ONBOARD_BOOKINGS_LOADED_SUCCESS, ONBOARD_BOOKINGS_LOADED_FAIL, ADD_ONBOARD_BOOKING, FIND_ONBOARD_BOOKING, UPDATE_ONBOARD_BOOKING, DELETE_ONBOARD_BOOKING } from './constants';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';

export const OnboardBookingContext = createContext()

const OnboardBookingContextProvider = ({ children }) => {
    // State
    const [onboardBookingState, dispatch] = useReducer(onboardBookingReducer, {
        onboardBooking: null,
        onboardBookings: [],
        onboardBookingsLoading: true
    })

    const [showAddOnboardBookingModal, setShowAddOnboardBookingModal] = useState(false)
    const [showUpdateOnboardBookingModal, setShowUpdateOnboardBookingModal] = useState(false)
    const [onboardBookingData, setOnBoardBookingData] = useState({})
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all onboard bookings
    const getOnboardBookings = async objectValidate => {
        const response = await getAPI(`${apiUrl}/onboard-bookings`, `${objectValidate}`)
        if(response.data){
            dispatch({type: ONBOARD_BOOKINGS_LOADED_SUCCESS, payload: response.data})
            return response
        }

        dispatch({ type: ONBOARD_BOOKINGS_LOADED_FAIL })
        return response
    }

    // Add Onboard Booking
    const addOnboardBooking = async newOnboardBooking => {
        const response = await postAPI(`${apiUrl}/onboard-booking/insert`, newOnboardBooking)
        if(response.data){
            dispatch( {
                type: ADD_ONBOARD_BOOKING,
                payload: response.data
            } )
        }

        return response
    }

    // Find Onboard Booking when user is updating onboardBooking
    const findOnboardBooking = onboardBookingId => {
        const onboardBooking = onboardBookingState.onboardBookings.find(item => item.id === onboardBookingId)
        dispatch({
            type: FIND_ONBOARD_BOOKING,
            payload: onboardBooking
        })
    }

    // Update Onboard Booking
    const updateOnboardBooking = async updateOnboardBooking => {
        const response = await postAPI(`${apiUrl}/onboard-booking/update`, updateOnboardBooking)
        if(response.status === 200) {
            dispatch( {
                type: UPDATE_ONBOARD_BOOKING,
                payload: updateOnboardBooking
            } )
        }
        return response
    }

    // Delete Onboard Booking
    const deleteOnboardBooking = async onboardBookingId => {
        const response = await getDeleteAPI(`${apiUrl}/onboard-booking/${onboardBookingId}/delete`)
        if(response.status === 200) {
            dispatch( {
                type: DELETE_ONBOARD_BOOKING,
                payload: onboardBookingId
            } )
        }
        return response
    }

    const OnboardBookingContextData = {
        onboardBookingState,
        getOnboardBookings,
        addOnboardBooking,
        findOnboardBooking,
        updateOnboardBooking,
        deleteOnboardBooking,
        showAddOnboardBookingModal,
        setShowAddOnboardBookingModal,
        showUpdateOnboardBookingModal,
        setShowUpdateOnboardBookingModal,
        showToast,
        setShowToast,
        onboardBookingData,
        setOnBoardBookingData
    }

    return (
        <OnboardBookingContext.Provider value={OnboardBookingContextData}>
            {children}
        </OnboardBookingContext.Provider>
    )
}

export default OnboardBookingContextProvider