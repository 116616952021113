import {
	PRICES_LOADED_SUCCESS,
	PRICES_LOADED_FAIL,
	ADD_PRICE,
	FIND_PRICE,
	UPDATE_PRICE,
	DELETE_PRICE,
} from '../contexts/constants';

export const priceReducer = (state, action) => {
	const { type, payload } = action;
	switch (type) {
		case PRICES_LOADED_SUCCESS:
			return {
				...state,
				prices: payload,
				pricesLoading: false,
			};
		case PRICES_LOADED_FAIL:
			return {
				...state,
				prices: [],
				pricesLoading: false,
			};
		case ADD_PRICE:
			return {
				...state,
				prices: [...state.prices, payload],
			};
		case FIND_PRICE:
			return {
				...state,
				price: payload,
			};
		case UPDATE_PRICE:
			const newPrices = state.prices.map((price) => {
				const findItem = payload.prices.find((item) => {
					return item.id === price.id;
				});

				if (findItem) {
					const tmpPayload = { ...payload };
					delete tmpPayload.prices;
					return { ...findItem, ...tmpPayload };
				}
				return price;
			});

			return {
				...state,
				prices: newPrices,
			};
		case DELETE_PRICE:
			return {
				...state,
				prices: state.prices.filter((price) => price.price_group !== payload),
			};
		default:
			return state;
	}
};
