import { Fragment, useContext, useEffect, useState } from 'react';
import { PartnerContext } from '../../../contexts/PartnerContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { Spinner, Card } from 'react-bootstrap';
import UpdatePartnerModal from './UpdatePartnerModal';
import { Link } from 'react-router-dom';
import { getTable, getTableOptions, getDefaultSort } from '../../../utils/table';
import { useTranslation } from 'react-i18next';

const ListPartner = props => {
    const { t } = useTranslation();
    const {authState: { user }} = useContext(AuthContext)
    const {
        partnerState: {partner, partners, partnersLoading},
        setShowUpdatePartnerModal,
        findPartner,
        getPartners,
        deletePartner
    } = useContext(PartnerContext)

    const users = props.usersProps;
    const assignTo = props.assignTo;

    // Start: Get all partner , []
    useEffect(() => getPartners(), []) // eslint-disable-line react-hooks/exhaustive-deps

    const choosePartner = partnerId => {
        findPartner(partnerId)
        setShowUpdatePartnerModal(true)
    }

    let partnerTable = getListPartnerTable(
		partners,
		users,
		choosePartner,
        t,
        assignTo
	);

    useEffect(() => {
        partnerTable = getListPartnerTable(
            partners,
            users,
            choosePartner,
            t,
            assignTo
        );
    }, [partners]);
    
    if(partnersLoading){
        return  (
            <div className="spinner-container">
                <Spinner animation='border' variant='info'></Spinner>
            </div>
        );
    }
    
    if (partners === null || partners.length === 0) {
        return (
            <Fragment>
                <Card className='text-center mx-5 my-5'>
                    <Card.Header as='h1'>{t('Hi')} {user.name}</Card.Header>
                    <Card.Body>
                        <Card.Title>{t('Welcome to Partner Manage')}</Card.Title>
                        <Card.Text>
                            {t('Click the Add Partner button to create your first Partner')}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Fragment>
        );
    }
    
    return (
        <Fragment>
            {partnerTable}
            {partner !== null && <UpdatePartnerModal usersProps={users} />}
        </Fragment>
    )
}

const getTableData = (partners, users, choosePartner, setShowToast, t, assignTo) => {
	return partners
        .filter((item) => {
            return assignTo ? item.assign_to === assignTo : true;
        })
        .map((partner, index) => {
            return {
                id: partner.id,
                index: index + 1,
                name: partner.name,
                phone: partner.phone,
                address: partner.address,
                assign_to:(
                    users && users
                        .filter(user => user.id === partner.assign_to)
                        .map((user, idx) => <p key={idx}>{user.name}</p>)
                    
                ),
                action: (
                    <div className="row">
                    <div className="col-sm">
                            <Link to={`/manage/partners/${partner.id}`} target="_blank" className="btn btn-success btn-with-icon btn-block" title={partner.name}><i className="typcn typcn-clipboard"></i> {t('Show')}</Link>
                        </div>
                        <div className="col-sm">
                            <button className="btn btn-success btn-with-icon btn-block" onClick={choosePartner.bind(this, partner.id)}><i className="fe fe-edit"></i> {t('Edit')}</button>
                        </div>
                        {/*<div className="col-sm">
                            <DeleteModal idProps={partner.id} deleteFunc={deletePartner} />
                        </div>*/}
                    </div>
                )
            };
        });
};

const getColumns = (t) => {
	return [
        {
			dataField: 'id',
			text: 'ID',
			hidden: true
		},

		{
			dataField: 'index',
			text: 'STT',
			sort: true
		},

		{
			dataField: 'name',
			text: t('Name'),
			sort: true,
            classes: 'td-left'
		},
		{
			dataField: 'phone',
			text: t('Phone'),
			sort: true,
            classes: 'td-left'
		},
		{
			dataField: 'address',
			text: t('Address'),
			sort: true,
            classes: 'td-left'
		},
		{
			dataField: 'assign_to',
			text: t('Assign To'),
			sort: true,
            classes: 'td-left'
		},
        {
			dataField: 'action',
			text: ''
		}
	];
};

const getListPartnerTable = (partners, users, choosePartner, t, assignTo) => {
	if (!partners || partners.length === 0) return null;
	const tableData = getTableData(partners, users, choosePartner, null, t, assignTo);
	const columns = getColumns(t);
	const defaultSorted = getDefaultSort();
	const options = getTableOptions(tableData.length, t);

	return getTable(tableData, columns, defaultSorted, options);
};


export default ListPartner
