import { Fragment, useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { TransportationRouteContext } from '../../contexts/TransportationRouteContext';
import DeleteModal from '../../components/layout/DeleteModal';
import UpdateTransportationRouteModal from './UpdateTransportationRoute';
import { Card, Spinner } from 'react-bootstrap';
import { ROLES, PERMISSIONS } from '../../contexts/constants';
import { getTable, getTableOptions, getDefaultSort } from '../../utils/table';
import { TransportationPickUpContext } from '../../contexts/TransportationPickUpContext';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

const ListTransportationRoute = () => {
	const { t } = useTranslation();
	const {
		authState: { user }
	} = useContext(AuthContext);

	const {
		transportationPickUpState: { transportationPickUps },
		getTransportationPickUps
	} = useContext(TransportationPickUpContext);

	useEffect(() => {
		if (!transportationPickUps || !transportationPickUps.length) getTransportationPickUps();
	}, []);

	const {
		transportationRouteState: { transportationRoute, transportationRoutes, transportationRouteLoading },
		getTransportationRoutes,
		findTransportationRoute,
		deleteTransportationRoute,
		setShowUpdateTransportationRouteModal,
		setShowToast
	} = useContext(TransportationRouteContext);

	useEffect(() => {
		if (!transportationRoutes || !transportationRoutes.length) getTransportationRoutes();
	}, []);
	
	const chooseTransportationRoute = (transportationRouteId) => {
		findTransportationRoute(transportationRouteId);
		setShowUpdateTransportationRouteModal(true);
	};

	if (transportationRouteLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info'></Spinner>
			</div>
		);
	}
	if (transportationRoutes === null || transportationRoutes.length === 0) {
		return (
			<Fragment>
				<Card className='text-center mx-5 my-5'>
					<Card.Header as='h1'>{t('Hi')} {user.name}</Card.Header>
					<Card.Body>
						<Card.Title>{t('Welcome to Transportation Route Manage')}</Card.Title>
						<Card.Text>
							{t('Click The Button Add Transportation Route to Create Your First Transportation Route')}
						</Card.Text>
					</Card.Body>
				</Card>
				{transportationRoute !== null && <UpdateTransportationRouteModal />}
			</Fragment>
		);
	}

	const tableProps = {
		user,
		transportationRoutes,
		chooseTransportationRoute,
		deleteTransportationRoute,
		setShowToast,
		transportationPickUps,
		t
	};
	return getTransportationRoute(tableProps);
};

const findTransportationPickUp = (transportationPickUps, transportationPickUpId) => {
	const transportationPickUp = transportationPickUps.find((tpu) => {
		return tpu.id === transportationPickUpId;
	});
	return transportationPickUp ? transportationPickUp.name : '';
};

const getTransportationRoute = (tableProps) => {
	if (!tableProps.transportationRoutes || tableProps.transportationRoutes.length === 0) return null;
	const tableData = getTableData(tableProps);
	const columns = getColumns(tableProps.t);
	const defaultSorted = getDefaultSort([['index', 'asc']]);
	const options = getTableOptions(tableData.length, tableProps.t);

	return getTable(tableData, columns, defaultSorted, options);
};
const getTableData = (tableProps) => {
	const {
		user,
		transportationRoutes,
		chooseTransportationRoute,
		deleteTransportationRoute,
		setShowToast,
		transportationPickUps,
		t
	} = tableProps;
	return transportationRoutes.map((transportationRoute, index) => {
		return {
			index: index + 1,
			id: transportationRoute.id,
			pickup_id: transportationRoute.pickup_id
				? findTransportationPickUp(transportationPickUps, transportationRoute.pickup_id)
				: '',
			dropoff_id: transportationRoute.dropoff_id
				? findTransportationPickUp(transportationPickUps, transportationRoute.dropoff_id)
				: '',
			price: (
				<NumberFormat
					value={transportationRoute.price}
					displayType={'text'}
					thousandSeparator='.'
					decimalSeparator=','
					decimalScale={2}
				/>
			),
			currency: transportationRoute.currency?.toUpperCase(),
			description: transportationRoute.description,
			action: (
				<div className='row'>
					<div className='action'>
						{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
						user.Permissions.includes(PERMISSIONS.UPDATE_TRANSPORTATION_ROUTE) ? (
							<button
								className='btn btn-success btn-with-icon'
								onClick={chooseTransportationRoute.bind(this, transportationRoute.id)}
							>
								<i className='fe fe-edit'></i> {t('Edit')}
							</button>
						) : null}
					</div>
					<div className='action'>
						{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
						user.Permissions.includes(PERMISSIONS.DELETE_TRANSPORTATION_Route) ? (
							<DeleteModal
								nameProps={transportationRoute.name}
								idProps={transportationRoute.id}
								deleteFunc={deleteTransportationRoute}
								setShowToast={setShowToast}
							/>
						) : null}
					</div>
				</div>
			)
		};
	});
};
const getColumns = (t) => {
	return [
		{
			dataField: 'index',
			text: 'STT',
			sort: true
		},
		{
			dataField: 'id',
			text: 'ID',
			sort: true
		},
		{
			dataField: 'pickup_id',
			text: t('Pickup'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'dropoff_id',
			text: t('Drop-off'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'price',
			text: t('Price'),
			sort: true,
			classes: 'td-right'
		},
		{
			dataField: 'currency',
			text: t('Currency'),
			sort: true
		},
		{
			dataField: 'description',
			text: t('Description'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'action',
			text: '',
			sort: true
		}
	];
};

export default ListTransportationRoute;
