import { Fragment, useState, useEffect, useContext } from 'react';
import {Helmet} from "react-helmet";
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { apiUrl, ROLES, NO_PERMISSION } from '../../contexts/constants';
import TopAccountTable from './TopAccountTable';
import { getAPI } from '../../utils/api';
import { AuthContext } from '../../contexts/AuthContext'
import { useTranslation } from 'react-i18next';
import { ExportPartnerRevenueListXLSX } from '../../components/export/ExportPartnerRevenueListXLSX';
import { CruiseContext } from '../../contexts/CruiseContext';
import { currencyFormatter, sort } from '../../utils/common';

const TopAccount = () => {
    const { t } = useTranslation();
    const {
        authState: {user }
    } = useContext(AuthContext)

    const {
        cruiseState: {cruises},
        getCruises
    } = useContext(CruiseContext)

    useEffect(() => {
		if (!cruises || !cruises.length) {
			getCruises();
		}
	}, []);

    const [allTotalPeople, setAllTotalPeople] = useState(0);
    const [partnerRevenues, setPartnerRevenues] = useState([])

    let param1 = window.location.pathname;
    let arrUrl = param1.split('/')
    let month = 0;
    let year = 0;

    if(arrUrl !== null){
        var arrInfo = arrUrl[3].split('-')
        month = arrInfo[0]
        year = arrInfo[1]
    }

    let daysInMonths = new Date(year, month, 0).getDate();
    let arrDays = []
    let arrMonths = []
    let arrYears = []
    let textMonth = ''
    let textYear = ''
    let textDay = ''
    let dayCurrent = 1;
    
    const [partnerOriginalData, setPartnerOriginalData] = useState();
    const [exportPartnerRevenueData, setExportPartnerRevenueData] = useState([])
    const [currentMonth, setCurrentMonth] = useState(month)
    const [currentYear, setCurrentYear] = useState(year)

    let months = [t('January'),t('February'),t('March'),t('April'), t('May'), t('June'), t('July'), t('August'), t('September'), t('October'), t('November'), t('December')]


    // eslint-disable-next-line no-lone-blocks
    {Array.from(Array(daysInMonths), (e, i) => {
        if((i + 1) === Number(dayCurrent)){
            textDay = (i + 1)
        }
        arrDays = [...arrDays,
            {
                value: (i + 1),
                label: (i + 1)
            }
        ]
    })}

    // eslint-disable-next-line no-lone-blocks
    {Array.from(Array(12), (e, i) => {
        if((i + 1) === Number(month)){
            textMonth = months[i]
        }
        arrMonths = [...arrMonths,
            {
                value: (i + 1),
                label: months[i]
            }
        ]
    })}

    arrMonths.unshift({
        value: 'all',
        label: t('All')
    });

    const yearNum = 10;
	const minYear = year - 5 > 2022? year - 5: 2022;
	// eslint-disable-next-line no-lone-blocks
    {Array.from(Array(yearNum), (e, i) => {
        if(Number(i + minYear) === Number(year)){
            textYear = (minYear + i)
        }
        arrYears = [...arrYears,
            {
                value: (minYear + i),
                label: (minYear + i)
            }
        ]
    })}


    useEffect(async () => {
        
        const filterDate = `year=${currentYear}&month=${currentMonth}`;
        async function bookingData(){
            const response = await getAPI(`${apiUrl}/report/partner-revenue`, `${filterDate}`)
            if (response.data) {
                setPartnerOriginalData(response.data);
            } else {
                setPartnerRevenues([]);
            }
        }
        await bookingData()
    }, [currentYear, currentMonth]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeDay = (newValue, actionMeta) => {
        const dataDay = newValue;
        if (!dataDay) return;
        if(actionMeta.name === `currentMonth` ){
            if (dataDay.value == 'all') {
                setCurrentMonth('all');
                return;
            }
            var textMonth = ("0" + dataDay.value).slice(-2);
            setCurrentMonth(textMonth)

            return;
        }

        if(actionMeta.name === `currentYear` ){
            setCurrentYear(dataDay.value)
        }
    }

    const openLinkNewTopAccount = () => {
        var newPageUrl = `/accounting/top-account/${currentMonth}-${currentYear}`
        window.open(newPageUrl, "_blank")
    }

    useEffect(() => {
        if (!partnerOriginalData || !partnerOriginalData.length) return;
        preparePartnerRevenue(partnerOriginalData)
    }, [cruises, partnerOriginalData])

    if (!user) {
        return NO_PERMISSION
    }

    if (!(user.Roles.includes(ROLES.OPT) || user.Roles.includes(ROLES.ACC) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))) {
        return NO_PERMISSION
    }

    const preparePartnerRevenue = (partnerRevenues) => {
        let data = {};
        let allTotalPeople = 0;
        partnerRevenues.forEach((item) => {
            allTotalPeople += item.pax_total;
            if (!data[item.partner_id]) {
                data[item.partner_id] = {
                    partner_id: item.partner_id,
                    totalVND: 0,
                    totalUSD: 0,
                    totalPax: 0,
                    bookingCount: 0
                };
            }

            data[item.partner_id].partner_name = item.partner_name;
            data[item.partner_id].asign_to_name = item.assign_name;
            data[item.partner_id].totalVND += item.money_type == 'vnd'? item.revenue: 0;
            data[item.partner_id].totalUSD += item.money_type == 'usd'? item.revenue: 0;
            data[item.partner_id].totalPax += item.pax_total;
            data[item.partner_id].bookingCount += item.booking_count;

            // cruises
            if (!data[item.partner_id][item.cruise_id]) {
                data[item.partner_id][item.cruise_id] = {
                    cruise_id: item.cruise_id,
                    cruise_name: item.cruise_name,
                    totalVND: 0,
                    totalUSD: 0,
                    totalPax: 0,
                    bookingCount: 0
                }
            }

            data[item.partner_id][item.cruise_id].totalVND += item.money_type == 'vnd'? item.revenue: 0;
            data[item.partner_id][item.cruise_id].totalUSD += item.money_type == 'usd'? item.revenue: 0;
            data[item.partner_id][item.cruise_id].totalPax += item.pax_total;
        });

        const partnerRecords = Object.values(data);

        sort(partnerRecords, 'totalPax');
        partnerRecords.reverse();
        setPartnerRevenues(partnerRecords);
        setAllTotalPeople(allTotalPeople)
        setExportPartnerRevenue(partnerRecords, allTotalPeople);
    }
    

    const setExportPartnerRevenue = (data=[], allTotalPeople) => {
        let exportPartnerRevenueData = [];
        
        data.forEach((item, i) => {
            const partnerRevenueData = {
                STT: i + 1,
                'ĐỐI TÁC': item.partner_name,
                'GIAO CHO': item.asign_to_name,
                'TỔNG USD': currencyFormatter('USD').format(item.totalUSD),
                'TỔNG VND': currencyFormatter('VND').format(item.totalVND),
                'TỔNG NGƯỜI': item.totalPax
            };

            cruises.forEach((c) => {
                if (item[c.id]) {
                    partnerRevenueData[`USD ${c.id}`] = currencyFormatter('USD').format(item[c.id].totalUSD);
                    partnerRevenueData[`VND ${c.id}`] = currencyFormatter('VND').format(item[c.id].totalVND);
                    partnerRevenueData[`NGƯỜI ${c.id}`] = item[c.id].totalPax;
                } else {
                    partnerRevenueData[`USD ${c.id}`] = 0;
                    partnerRevenueData[`VND ${c.id}`] = 0;
                    partnerRevenueData[`NGƯỜI ${c.id}`] = 0;
                }
            });

            partnerRevenueData['BOOKINGS'] = item.bookingCount;
            partnerRevenueData['ĐẠT'] = Number((item.totalPax / allTotalPeople) * 100).toFixed(2) + '%'

            exportPartnerRevenueData.push(partnerRevenueData);
        });
        
        setExportPartnerRevenueData(exportPartnerRevenueData);
    };

    return (
        <Fragment>
            <Helmet>
                {/* <title>{t('Top Account')} {currentMonth} - {currentYear}</title> */}
            </Helmet>
            <div className="horizontal-mainwrapper container-fluid clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-3">
                        <Row className='sel-month-year' md={3}>
                            <Col xs={2}>
                                <div className="form-group">
                                    <label><strong>{t('Month')}</strong></label>
                                    <Select isClearable
                                        name="currentMonth"
                                        defaultValue={{ label: textMonth, value: month }}
                                        onChange={handleChangeDay.bind(this)}
                                        options={arrMonths}
                                        placeholder={t("Month")}
                                        styles={{
                                            // Fixes the overlapping problem of the component
                                            menu: provided => ({ ...provided, zIndex: 2 })
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col xs={2}>
                                <div className="form-group">
                                    <label><strong>{t('Year')}</strong></label>
                                    <Select isClearable
                                        name="currentYear"
                                        defaultValue={{ label: textYear, value: year }}
                                        onChange={handleChangeDay.bind(this)}
                                        options={arrYears}
                                        placeholder={t("Year")}
                                        styles={{
                                            // Fixes the overlapping problem of the component
                                            menu: provided => ({ ...provided, zIndex: 2 })
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col xs={2}>
                                <div className="form-group">
                                    <button className="btn btn-primary btn-block mt-17" onClick={openLinkNewTopAccount.bind(this)}><i className="fe fe-corner-down-right"></i> {t('Go')}</button>
                                </div>
                            </Col>
                        </Row>
                        <div className="row mt-3">
                            <div className="col-md-9">
                                <h3 className="pull-left">{t('Personal Report')}: {t('Top Accounts')} {currentMonth == 'all'? '': currentMonth} - {currentYear}</h3> {/*@if ($saleUser->department == "sale" || $saleUser->department == "isale")- {{$saleUser->name}}*/}
                            </div>
                            <div className='col-md-3'>
                                <ExportPartnerRevenueListXLSX
                                    exportPartnerRevenueData={exportPartnerRevenueData}
                                    fileName='partner_revenue_list'
                                    label={t('Export')}
                                    cruises={cruises}
                                    classes='export-to-excel'
                                    reportTitle={`${t('Personal Report')}: ${t('Top Accounts')} ${currentMonth == 'all'? '': currentMonth} - ${currentYear}`}
                                />
                            </div>
                        </div>
                        <hr className="mt-3" />
                        <TopAccountTable bookingsProps={partnerRevenues} allTotalPeople={allTotalPeople} />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default TopAccount
