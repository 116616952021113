import { Fragment, useState, useEffect } from 'react';
import { CRUISE_TYPES, apiUrl } from '../../../contexts/constants';
import { getAPI } from '../../../utils/api';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const CustomerInfoHistory = (props) => {
	const bookingHistoryId = props.bookingIdProps;
	const tripType = props.tripType;

	const [customers, setDataCustomers] = useState([]);

	useEffect(() => {
		async function getCustomers() {
			try {
				const response = await getAPI(
					`${apiUrl}/customer-histories`,
					`filterFields=booking_history_id&filterFieldValues=${bookingHistoryId}`
				);
				setDataCustomers(response.data);
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		getCustomers();
	}, [bookingHistoryId]);

	const { t } = useTranslation();

	return (
		<Fragment>
			<div className='row'>
				<div className='col-md-12'>
					<div className='info-header-detail mt-3'>
						<h1 className='float-left'>{t("Customers' Information")}</h1>
						<hr />
					</div>
					<table className='table table-bordered mg-b-0 text-md-nowrap'>
						<thead>
							<tr>
								<th>STT</th>
								{tripType === CRUISE_TYPES.NIGHT && <th>{t('Cabin')}</th>}
								{tripType === CRUISE_TYPES.NIGHT && <th>{t('Special')}</th>}
								<th>{t('Title')}</th>
								<th>{t('Full Name')}</th>
								<th>{t('Email')}</th>
								<th>{t('Leader')}</th>
								<th>{t('Age')}</th>
								<th>{t('Passport No')}.</th>
								<th>{t('Visa Expiration')}</th>
								<th>{t('Country')}</th>
								<th>{t('Gender')}</th>
								<th>{t('Birthday')}</th>
								<th>{t('Phone')}</th>
								<th>{t('Notes')}</th>
							</tr>
						</thead>
						<tbody>
							{customers &&
								customers.map((customer, i) => (
									<tr key={customer.id}>
										<td>{i + 1}</td>
										{tripType === CRUISE_TYPES.NIGHT && <td>{customer.room_name}&nbsp;</td>}
										{tripType === CRUISE_TYPES.NIGHT && <td>{customer.cabin_speical_name}&nbsp;</td>}
										<td>{customer.title}&nbsp;</td>
										<td>{customer.full_name}&nbsp;</td>
										<td>{customer.email}&nbsp;</td>
										<td>
											{customer.leader === 1 ? (
												<i className='fe fe-check-square' aria-hidden='true'>
													&nbsp;
												</i>
											) : (
												''
											)}
										</td>
										<td>{customer.age}&nbsp;</td>
										<td>{customer.passport_number}&nbsp;</td>
										<td>
											{customer.passport_expired ? moment(customer.passport_expired).format('MMMM D, YYYY') : ''}&nbsp;
										</td>
										<td>{customer.country}&nbsp;</td>
										<td>{customer.gender === 'male' ? t('M') : t('F')}&nbsp;</td>
										<td>{customer.birthday ? moment(customer.birthday).format('MMMM D, YYYY') : ''}&nbsp;</td>
										<td>{customer.phone}&nbsp;</td>
										<td>{customer.note}&nbsp;</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			</div>
		</Fragment>
	);
};

export default CustomerInfoHistory;
