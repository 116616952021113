import { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { AuthContext } from '../../../contexts/AuthContext';

const SessionTimeout = () => {
	const [signOutTimer, setSignOutTimer] = useState(60); // seconds
	const [isSessionExpiringSoon, setIsSessionExpiringSoon] = useState(false);
	const [currentTime, setCurrentTime] = useState(new Date());
	const [sessionExpirationWarningTime, setSessionExpirationWarningTime] = useState();
	const [sessionExpirationTime, setSessionExpirationTime] = useState();
	const [isSessionExpired, setIsSessionExpired] = useState(false);

	let timer;

	const {
		authState: { user },
		logoutUser,
		extendToken
	} = useContext(AuthContext);
	const logout = async () => await logoutUser();

	const [sessionExpiration, setSessionExpiration] = useState(user?.Expiration);
	// const [sessionExpiration, setSessionExpiration] = useState('2023-06-20 16:48:00');

	useEffect(() => {
		setSessionExpiration(user.Expiration);
	}, [user.Expiration]);

	const handleExtend = () => {
		if (isSessionExpiringSoon) {
			// set extended session api_token
			extendToken(user);
			setIsSessionExpiringSoon(false);
		}
		clearInterval(timer);
		setTimeout(() => {
			setSignOutTimer(60);
		}, 500);
	};

	useEffect(() => {
		if (isSessionExpired) {
			logout();
		} else if (isSessionExpiringSoon) {
			clearInterval(timer);
			timer = setInterval(() => {
				setSignOutTimer((signOutTimer) => signOutTimer - 1);
			}, 1000);
		}
	}, [isSessionExpiringSoon, isSessionExpired]);

	useEffect(() => {
		if (isSessionExpiringSoon && signOutTimer === 0) {
			clearInterval(timer);
			setTimeout(() => {
				setSignOutTimer(10);
			}, 500);
			logout();
		}
	}, [signOutTimer, isSessionExpiringSoon]);

	useEffect(() => {
		setTimeout(() => {
			setCurrentTime(new Date());
		}, 1000);
	}, [currentTime]);

	// get token expiration time here.
	// const sessionExpiration = user?.Expiration;
	useEffect(() => {
		if (sessionExpiration) {
			const sessionExpirationWarningTime = new Date(sessionExpiration);
			sessionExpirationWarningTime.setMinutes(sessionExpirationWarningTime.getMinutes() - 3);
			console.log('sessionExpirationWarningTime', sessionExpirationWarningTime);
			setSessionExpirationTime(new Date(sessionExpiration));
			setSessionExpirationWarningTime(sessionExpirationWarningTime);
		}
	}, [sessionExpiration]);

	useEffect(() => {
		if (sessionExpirationTime) {
			if (sessionExpirationTime.getTime() < currentTime.getTime()) {
				setIsSessionExpired(true);
			}
		}
		if (sessionExpirationWarningTime) {
			if (sessionExpirationWarningTime.getTime() < currentTime.getTime()) {
				setIsSessionExpiringSoon(true);
			}
		}
	}, [sessionExpirationTime, sessionExpirationWarningTime, currentTime]);

	return (
		<Fragment>
			<Modal show={isSessionExpiringSoon} onHide={logout}>
				<Modal.Header closeButton>
					<Modal.Title>Session Timout</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p style={{ marginBottom: 0 }}>
						You've been inactive for a while. For your security, we'll automatically sign you out in {signOutTimer}{' '}
						seconds. Choose 'Stay Signed In' to continue or 'Sign Out' if you're done.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button className='btn btn-secondary btn-with-icon' variant='secondary' onClick={logout}>
						<i className='fe fe-x-circle'></i> Sign Out
					</Button>
					<Button className='btn btn-danger btn-with-icon' variant='primary' onClick={handleExtend}>
						<i className='fe fe-trash-2'></i> Stay Signed In
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
};

export default SessionTimeout;
