import {
	GET_SALES_LOADED_SUCCESS,
	GET_SALES_LOADED_FAIL,
	ADD_USER,
	FIND_USER,
	UPDATE_USER,
	DELETE_USER,
	A_USER,
	EXTEND_TOKEN
} from '../contexts/constants';
// luu giu trang thai xac thuc nguoi dung
export const authReducer = (state, action) => {
	const {
		type,
		payload: { isAuthenticated, user, users }
	} = action;
	switch (type) {
		case 'SET_AUTH':
			return {
				...state,
				authLoading: false,
				isAuthenticated,
				user
			};
		case GET_SALES_LOADED_SUCCESS:
			return {
				...state,
				users,
				isAuthenticated,
				authLoading: false
			};
		case GET_SALES_LOADED_FAIL:
			return {
				...state,
				users: [],
				authLoading: false
			};
		case ADD_USER:
			return {
				...state,
				users: [...state.users, users],
				isAuthenticated,
				authLoading: false
			};
		case FIND_USER:
			return {
				...state,
				user
			};
		case A_USER:
			return {
				...state,
				isAuthenticated,
				user
			};
		case UPDATE_USER:
			const newUser = state.users.map((olduser) => {
				if (olduser.id === user.id) {
					return user;
				} else {
					return olduser;
				}
			});
			return {
				...state,
				users: newUser
			};
		case DELETE_USER:
			return {
				...state,
				users: state.users.filter((olduser) => olduser.id !== user)
			};
		case EXTEND_TOKEN:
			return {
				...state,
				user
			};
		default:
			return state;
	}
};
