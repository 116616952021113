import { createContext, useReducer, useState } from 'react';
import { onboardServiceReducer } from '../reducers/onboardServiceReducer';
import { apiUrl, ONBOARD_SERVICES_LOADED_SUCCESS, ONBOARD_SERVICES_LOADED_FAIL, ADD_ONBOARD_SERVICE, FIND_ONBOARD_SERVICE, UPDATE_ONBOARD_SERVICE, DELETE_ONBOARD_SERVICE } from './constants';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';

export const OnboardServiceContext = createContext()

const OnboardServiceContextProvider = ({ children }) => {
    // State
    const [onboardServiceState, dispatch] = useReducer(onboardServiceReducer, {
        onboardService: null,
        onboardServices: [],
        OnboardServicesLoading: true
    })

    const [showAddOnboardServiceModal, setShowAddOnboardServiceModal] = useState(false)
    const [showUpdateOnboardServiceModal, setShowUpdateOnboardServiceModal] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all onboard services
    const getOnboardServices = async objectValidate => {
        const response = await getAPI(`${apiUrl}/onboard-services`, `${objectValidate}`)
        if(response.data){
            dispatch({type: ONBOARD_SERVICES_LOADED_SUCCESS, payload: response.data})
            return response
        }

        dispatch({ type: ONBOARD_SERVICES_LOADED_FAIL })
        return response
    }

    // Add Onboard Service
    const addOnboardService = async newOnboardService => {
        const response = await postAPI(`${apiUrl}/onboard-service/insert`, newOnboardService)
        if(response.data){
            dispatch( {
                type: ADD_ONBOARD_SERVICE,
                payload: response.data
            } )
        }

        return response
    }

    // Find Onboard Service when user is updating onboardService
    const findOnboardService = onboardServiceId => {
        const onboardService = onboardServiceState.onboardServices.find(item => item.id === onboardServiceId)
        dispatch({
            type: FIND_ONBOARD_SERVICE,
            payload: onboardService
        })
    }

    // Update Onboard Service
    const updateOnboardService = async updateOnboardService => {
        const response = await postAPI(`${apiUrl}/onboard-service/update`, updateOnboardService)
        if(response.status === 200) {
            dispatch( {
                type: UPDATE_ONBOARD_SERVICE,
                payload: updateOnboardService
            } )
        }
        return response
    }

    // Delete Onboard Service
    const deleteOnboardService = async onboardServiceId => {
        const response = await getDeleteAPI(`${apiUrl}/onboard-service/${onboardServiceId}/delete`)
        if(response.status === 200) {
            dispatch( {
                type: DELETE_ONBOARD_SERVICE,
                payload: onboardServiceId
            } )
        }
        return response
    }

    const onboardServiceContextData = {
        onboardServiceState,
        getOnboardServices,
        addOnboardService,
        findOnboardService,
        updateOnboardService,
        deleteOnboardService,
        showAddOnboardServiceModal,
        setShowAddOnboardServiceModal,
        showUpdateOnboardServiceModal,
        setShowUpdateOnboardServiceModal,
        showToast,
        setShowToast
    }

    return (
        <OnboardServiceContext.Provider value={onboardServiceContextData}>
            {children}
        </OnboardServiceContext.Provider>
    )
}

export default OnboardServiceContextProvider