import { Fragment, useContext } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import { getBookingCellBackground } from '../../../utils/common';
import { BookingContext } from '../../../contexts/BookingContext';
import { BOOKING_STATUSES } from '../../../contexts/constants';

const BookingDayItem = (props) => {
	// Khai bao cac bien
	const customerBookings = props.bookingProps;
	const dateSetting = props.dateSettingProps;
	const setAddBookingDefaultData = props.setAddBookingDefaultData;

	const { setShowAddBookingModal } = useContext(BookingContext);

	let body = null;
	//let infoCustomer = []
	let checkID = null;
	let customerBookingIds = [];
	let idCheck = 0;
	const bgBlack = dateSetting ? 'day-black' : '';

	const cellClick = () => {
		setAddBookingDefaultData({
			start_date: props.startDate,
			cruise: props.cruise,
		});
		setShowAddBookingModal(true);
	};

	// tra ve td rong khi khong co booking
	if (!customerBookings) {
		return (
			<Fragment>
				<td className={bgBlack} onClick={cellClick}>
					{body}
				</td>
			</Fragment>
		);
	}

	// prepare data
	const dataArrays = getBookingItems(customerBookings);

	// Hien thi du lieu
	return getBody(dataArrays, bgBlack, cellClick);
};

/*
 *
 */
const getBody = (dataArrays, bgBlack, cellClick) => {
	let body = '';
	if (!dataArrays || dataArrays.length === 0) return '';

	body = dataArrays.map((data, index) => (
		<OverlayTrigger
			key={index}
			trigger={['hover', 'hover']}
			placement='bottom'
			overlay={popover(data)}
		>
			<Link
				to={`/bookings/show/${data.booking_id}`}
				target='_blank'
				className={
					getBookingCellBackground(data.status, data.duration) + ' show-booking'
				}
			>
				{data.status !== BOOKING_STATUSES.CANCELED &&
				data.status !== BOOKING_STATUSES.TRANSFERRED
					? data.new_code
						? data.new_code
						: data.id
					: ''}
			</Link>
		</OverlayTrigger>
	));

	return (
		<Fragment>
			<td className={bgBlack}>
				<div class='add-booking' onClick={cellClick}>
					+
				</div>
				{body}
			</td>
		</Fragment>
	);
};

const getBookingItems = (customerBookings) => {
	let dataArrays = {};

	if (!customerBookings || customerBookings.length === 0) {
		return [];
	}

	customerBookings.forEach((c) => {
		if (!dataArrays[c.booking_id]) {
			dataArrays[c.booking_id] = c;
		}
	});

	return Object.values(dataArrays);
};

const popover = (dataBooking) => (
	<Popover id='popover-basic'>
		<Popover.Content className='message is-info'>
			<div className='message-body'>
				<div className='row'>
					<div className='col-md-5'>
						<label>Booking</label>
					</div>
					<div className='col-md-7'>
						<strong>{dataBooking.new_code}</strong>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-5'>
						<label>Partner</label>
					</div>
					<div className='col-md-7'>
						<strong>{dataBooking.partner_name}</strong>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-5'>
						<label>Departure</label>
					</div>
					<div className='col-md-7'>
						<strong>
							{Moment(dataBooking.start_date).format('MMMM D, YYYY')}
						</strong>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-5'>
						<label>Group Size</label>
					</div>
					<div className='col-md-7'>
						<strong>
							{dataBooking.adults} Adult{' '}
							{dataBooking.children
								? dataBooking.children + 'Children (5 - 11)'
								: ''}{' '}
							{dataBooking.children11
								? dataBooking.children11 + 'Children (> 11)'
								: ''}{' '}
							{dataBooking.infants
								? ' - ' + dataBooking.infants + ' Infant'
								: ''}
						</strong>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-5'>
						<label>Participators</label>
					</div>
					<div className='col-md-7'>
						<strong>
							{dataBooking.full_name ? dataBooking.full_name : ''}
						</strong>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-5'>
						<label>Assign To</label>
					</div>
					<div className='col-md-7'>
						<strong>{dataBooking.assign_to_name}</strong>
					</div>
				</div>
			</div>
		</Popover.Content>
	</Popover>
);

export default BookingDayItem;
