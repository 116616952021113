import { Fragment, useContext, useEffect, useState } from 'react';
import { CruiseSettingContext } from '../../contexts/CruiseSettingContext';
import { CruiseContext } from '../../contexts/CruiseContext';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { apiUrl } from '../../contexts/constants';
import { postAPI } from '../../utils/api';
import { useTranslation } from 'react-i18next';
import { event } from 'jquery';

const AddCruiseSettingModal = () => {
	const { t } = useTranslation();
	const {
		cruiseSettingState: { cruiseSettings },
		showAddCruiseSettingModal,
		setShowAddCruiseSettingModal,
		addCruiseSetting,
		clearCruise,
		setShowToast
	} = useContext(CruiseSettingContext);

	const {
		cruiseState: { cruises },
		getCruises
	} = useContext(CruiseContext);

	const [nameCruise, setNameCruise] = useState(null);
	const [cruiseWarning, setCruiseWarning] = useState(false);

	// Start: Get all cruise , []
	useEffect(() => getCruises(), []); // eslint-disable-line react-hooks/exhaustive-deps

	let optionCruises = [];

	if (cruises) {
		cruises.map((cruise) => {
			if (cruise.typeDay !== 'tour' && cruise.typeDay !== 'day trip') {
				optionCruises = [
					...optionCruises,
					{
						value: cruise.id,
						label: cruise.name
					}
				];
			}
		});
	}

	// State
	const [newCruiseSetting, setNewCruiseSetting] = useState({
		payment_information: '',
		important_note: `
            <p style={{marginBottom: '0px'}}>1.&nbsp;&nbsp;Please send us <strong>guest's Passport information</strong> 03 days before check-in as attached file and ask guests to take hand luggage only. If the guests have too much or oversized luggage, kindly inform us in advance.</p>
            <p style={{marginBottom: '0px'}}>2.&nbsp;&nbsp;Please inform us if there is <strong>any special request on food</strong> such as vegetarian, food allergy... for our best arrangement.</p>
            <p style={{marginBottom: '0px'}}>3.&nbsp;&nbsp;The pick-up time in The Old Quarter is <strong>08:00 AM - 08:30 AM</strong>. To make the service smooth for all passengers, <strong>please ask your guests to be ready at 08:00 AM at the hotel lobby. The pick-up time will be informed before 08:AM on the departure date</strong>. Any late readiness beyond this time and miss the bus, our cruise is not responsible.</p>
            <p style={{marginBottom: '0px'}}>4.&nbsp;&nbsp;<strong>Arriving time</strong> at Swan Cruises Office (<strong>Tuan Chau Harbour</strong>) should be at <strong>11:45 AM - 12:00 PM</strong> and <strong>check-out time is 11:30 AM - 12:00 PM next day</strong>. Please ask your guide/driver to contact the receptionist by <strong>+84 869 699 337</strong> to pick up your guests at Tuan Chau Harbour smoothly.</p>
            <p style={{marginBottom: '0px'}} className="control">We thank you for your kind cooperation and look forward to welcoming our guests.</p>
            <p style={{marginBottom: '0px'}} className="control">If you need further information, please do not hesitate to contact us!</p>
        `,
		property_information: `
            <div className="map_info">
                <p><span>Property Name:</span> Sena Cruises</p>
                <p><span>Address 1:</span> Tuan Chau Marina</p>
                <p><span>City:</span> Ha Long - Quang Ninh</p>
                <p>
                    <span>Phone:</span>
                    <a href="tel:+84974001062">+84974001062</a>
                </p>
                <p>
                    <span>Email:</span>
                    <a href="mailto:info@senacruises.com">info@senacruises.com</a>
                </p>
            </div>
            <div style="clear:both;"></div>
            <h5>Amenities</h5>
            <ul className="amenities">
                <li title="Audio / Visual equipment rental">Audio / Visual equipment rental</li>
                <li title="Baggage storage">Baggage storage</li>
                <li title="Concierge">Concierge</li>
                <li title="Fitness center">Fitness center</li>
                <li title="Gift shop">Gift shop</li>
                <li title="Lounge">Lounge</li>
                <li title="Room service">Room service</li>
                <li title="Safe deposit boxes at front desk">Safe deposit boxes at front desk</li>
                <li title="Swimming pool">Swimming pool</li>
                <li title="Welcome drink">Welcome drink</li>
            </ul>
            <div style="clear:both;"></div>
            <hr>
            <div className="checkin-checkout-terms-block">
                <h5>Check-in/Check-out Policies</h5>
                <p className="checkin">This property has the following check-in and check-out times and policies: </p>
                <p className="checkin">
                    <span>Check-In:</span> 11:00 AM
                </p>
                <p className="checkin">
                    <span>Check-Out:</span> 11:30 AM
                </p>
            </div>
            <h5 className="politica">Terms and Conditions</h5>
            <div className="reservations_terms_conditions">
                <p className="pp">Please kindly read these terms and conditions carefully. On booking online or signing our booking form when making a reservation over the phone or by email, you are deemed to have read and accepted our Booking Terms and Conditions and are accepting the terms of these conditions on behalf of all persons in the party.These terms and conditions constitute an agreement with the Merchant. By entering the site you accept these terms and conditions. The terms and conditions are subject to change at any time, and it is your responsibility to check these terms and conditions regularly in case there are any changes.Continuing to use the site after a change has been made is your acceptance of those changes.</p>
                <h3><span>&nbsp;I. Rate Basis</span></h3>
                <ul>
                    <li>All rates quoted in Vietnam Dong or American Dollars, per room per night for double occupancy</li>
                    <li>The rates are inclusive of service charges and government tax and are non-commissionable</li>
                    <li>Rates are subject to the terms of the contract. General terms and conditions will be supplied</li>
                </ul>
                <h3>&nbsp;II. Cruise cancellation due to bad weather conditions</h3>
                Cruising and sailing in the Cat Ba Archipelago are regulated by the Cat Ba Port Authority. Every morning, the Port Authority delivers a permit to each cruise company allowing them to sail in the Cat Ba Archipelago. Both day cruises and overnight cruises are regulated this way.In case of bad weather conditions and risks of the&nbsp;tropical storm, the Port Authority might limit the possibilities of cruising and even decide to cancel all cruises.In such cases, the final decision by the Port of Authority is usually only known at 3.00pm on the day of departure. Below are the possible scenarios that can occur and alternatives offered to passengers by Sena Cruises:<h4>1. ON THE DAY OF ARRIVAL&nbsp;</h4><h5>1.1. SITUATION</h5>On a day with bad weather conditions or risks of the&nbsp;tropical storm,&nbsp;Sena Cruises will inform guests/travel agents as soon as possible&nbsp;of the risk of cancellation, the possible scenarios that can occur, and will offer different alternatives to the guests/travel agents.Cancellation before the check-in time on the departure date: Full refund<span>The Port Authority will inform to all ships operating on Halong Bay and Cat Ba Archipelago as well as&nbsp; Sena Cruises whether:
                <br>– the overnight permit is granted;
                <br>– the overnight permit is denied but day cruising is allowed;
                <br>– all cruising is forbidden for both day and night.</span>
                <h5>1.2. TERMS AND CONDITIONS</h5>
                Upon arrival, guests can choose:
                <span>1.2.1. Not to wait, and cancel their cruise altogether.
                    <br>1.2.1.1. If guests decide to cancel the cruise before receiving a&nbsp;final decision (around 3.00pm as lastest) from the Port Authority, Sena Cruises will not offer any refund.
                    <br>1.2.2. To wait until 3.00pm and enjoy lunch on board (if the presence of people on the boats anchored in the marina is allowed by the Port Authority).
                    <br>1.2.2.1. If the guests wait, enjoy lunch on board and at 3.00pm the cruise is canceled, then only 60USD will be charged to cover the lunch.
                    <br>1.2.2.2. If guests wait, enjoy lunch on board and at 3.00pm only a day cruise is allowed, then only 100 USD will be charged to cover the lunch and day cruise.
                    <br>1.2.2.3. If guests wait, enjoy lunch on board and at 3.00pm overnight cruising is permitted, then only will be charged as 2 days/1 night tour.
                </span>
                <h4><span>2.&nbsp;<span>&nbsp;ON THE SECOND DAY OF A 3-DAY/2-NIGHT CRUISE</span></span></h4>
                <h5>2.1. SITUATION</h5>
                The permit to cruise on the second day of the 2- or 3- night cruise is delivered by the Port Authority at 8.00am the day of cruising, under normal circumstances. If the cruising permit is denied at 8.00am, guests will go back to the harbor at 10.30am with guests checking out that day. They will have then the same possible scenarios and alternatives as in section 1 above.
                <h5>2.2. TERMS AND CONDITIONS</h5>
                <span>Sena Cruises will refund as follows:
                    <br>2.2.1. In case cruising is entirely denied on that day: cruise will only be charged as 2 days/1 night
                    <br>2.2.2. In case guests decide to remain for lunch on board when permitted, but cruising ends up being entirely denied on that day: cruise will be charged as 2 days/1 night +60USD to cover the lunch.
                    <br>2.2.3. In case guests decide to remain for lunch on board, when permitted and cruise a few hours during that day when permitted, but overnight cruising ends up being denied: Cruising until 17:00 on the second day will be charged as 75% 3 days/2 nights tour.
                    <br>2.2.4. No refund can be claimed when overnight cruising ends up being allowed and the guests spend the second night on board as well.
                    <br>2.2.5. No refund can be claimed if guests decide to end their cruise at 10.30am on the second day without waiting for the final decision from the Port Authority.
                </span>
                <h4>3. ON THE DAY OF DEPARTURE</h4>
                In case of bad weather conditions or risks of the tropical storm on the day of departure (check-out), the Port Authority may order the ship to return to the marina earlier than planned. No refund can be claimed in this situation.
                <h4>4. TRANSPORTATION&nbsp;</h4>
                <span>4.1. Guests using transportation not booked via Sena Cruises shall contact their travel agent/ transportation supplier directly to make all necessary arrangements deriving from a cruise cancellation.
                    <br>4.2. For guests using Sena Cruises Transportation Services, Sena Cruises will arrange transport back to Hanoi.
                    <br>4.3. As Sena Cruises shall not be held responsible for any cruise cancellation due to weather conditions, if transportation has been used, the cost of transportation cannot be refunded by Sena Cruises.
                </span>
                <h4>5. OTHER CONSEQUENCES OF POOR WEATHER CONDITIONS&nbsp;</h4>
                <h5>5.1. CRUISING ITINERARY</h5>
                <span>
                    Even when cruising permits are delivered, bad weather conditions might force our Management, Captains and Crew to modify the schedule: change of routes, visits canceled or modified, etc.
                    <br>By booking with Sena Cruises, all guests agree that all programs are subject to change without prior notice.
                </span>
                <h5>5.2. SPECIAL DINING VENUES</h5>
                Sena Cruises offers the opportunity to dine on a beach, in a cave or in a floating village. Guests booking these extra services should be aware that it might be necessary to cancel outside dining at the last minute in case of bad weather conditions and to replace it by dining in the ship’s dining room. Sena Cruises cannot be held responsible for the cancellation of outside dining due to bad weather conditions and thus will not offer any refund.
                <h4>6. BOAT INSPECTIONS&nbsp;</h4>
                <span>
                    The Port Authority, Haiphong Province Authorities and other Officials may&nbsp;request, without prior notice, to inspect one or more of the fleet’s ships.
                    <br>Sena Cruises shall not be held responsible for any delays, change of schedule, early check-out that could occur due to the Officials’ inspection, and will thus not offer any refund.
                </span>
                <h4>7. GUESTS SHOULD BE AWARE THAT</h4>
                <span>
                    7.1. Permits can be denied due to thick fog: in this case, it is impossible for Sena Cruises to know or predict this in advance.
                    <br>7.2. Although for most tropical storms we usually have warnings and information 24 to 48 hours in advance, it is very often impossible to predict how powerful it will be when reaching the coast of Vietnam and sudden trajectory changes are also very common, hence the delay in obtaining, then communicating, information.
                    <br>7.3. Very often Sena Cruises receive from the Port Authority many contradictory pieces of information before the final decision. Sena Cruises will communicate to the passengers all information as given by the Port Authority and should not be held responsible for inconsistencies, last-minute changes, and contradictions.
                    <br>7.4. In the case of risk of a tropical storm at night, the weather might show no sign of danger upon the guests’ arrival and only change much later and quite suddenly.
                    <br>7.5. Some other cruise companies in Cat Ba Archipelago might decide to go against the decision of the Port Authority. It is their personal choice not to observe the orders of the local authorities and to put at risks the lives of their passengers.
                </span>
                CONSIDERING THE SAFETY AND SECURITY OF ITS PASSENGERS AS ITS NUMBER ONE PRIORITY, Sena CRUISES WILL ALWAYS COMPLY WITH THE PORT AUTHORITY’S DECISION.Although Sena Cruises understands the importance for visitors to see Cat Ba Archipelago and Halong Bay and is highly honored to have been chosen by our guests to see Vietnam’s most mythical site, we will never put our passengers in danger.
                Note:
                <ol>
                    <li>Passengers using the services of a travel agent should then contact their travel agent to make the necessary arrangements for transportation, accommodation, etc.</li>
                    <li>In case of tropical storm warning, it is usually forbidden to have people on board, even when the vessels are anchored in the harbor.</li>
                    <li>Passengers using the services of a travel agent should then address their travel agent for refund. Sena Cruises directly refunds the agent the amount paid by them.</li>
                </ol>
                <h3>III. Refund of unused services</h3>
                No refunds or exchange can be made in respect of accommodation, meals, sightseeing tours, transport or any other services which are included in the tour price but not utilized by the guest.
                <h3>IV. Liability and Insurance</h3>
                Sena Cruises accept no responsibility for any loss, injury, damage or delay due to natural disaster, political actions, weather or causes beyond its controlTravel insurance and medical precautions are essential. Your travel insurance must provide cover against personal accident, medical expenses, emergency repatriation and personal liability. We strongly recommend that you take out travel insurance at the time of booking.
                <h3>V. Confirmation</h3>
                All confirmations are generally made by return fax or email within 2 working days. In case immediate confirmation is not possible, we will inform you of the booking status at the time of reservation.
                <h3>VI. Child and Extra Bed Surcharge</h3>
                <ul>
                    <li>Children at 12 years old and above are considered as adults.</li>
                    <li>FOC for 01 children under 5 years old sharing bed with parents. Only 01 children under 5 years is allowed to share bed with parents. The second child is applied as child 5-11 years old</li>
                    <li>Maximum 1 child 5-11 years of age sharing bed with adult. The second child is applied extra bed rate for children.</li>
                    <li>Surcharge Extra Sofabed is at 180USD/adult and 90USD/child</li>
                </ul>
                <h3>VII. Passengers’ information</h3>
                The client is requested to submit all passenger details at least 24 hours prior to check in (Full name, Date of birth, Gender, Nationality, Passport number, Vietnam visa expiry date, Contact number). Passengers are advised to bring their passport&nbsp;during the tour as representatives of the Cat Ba Port Authority can board to check at any time.
                <h3>VIII. Payment and Cancellation Instructions</h3>
                Payments can be made by credit card, bank transfer or cash. We accept Visa Card, American Express, MasterCard.
                <h4>1. Cancellation</h4>
                Cancellation will be charged as follows:
                <div>
                    <span>
                        <span>PERIOD PRIOR TO THE CRUISE DATEGIT &gt;, = 06 CABINS &amp; CHARTERFIT &lt; 6 CABINS</span>
                        <span>More than 30 days prior check-inNo chargeNo charge</span>
                        <span>From 16 to 30 days30 % of each canceled cabins10 % of each canceled cabins</span>
                        <span>From 7 to 15 days50 % of each cancelled cabins30 % of each cancelled cabins</span>
                        <span>Less than 7 days prior check-in100% of each cancelled cabin
                            <span>70% of each cancelled cabin100% for no show or less than 3 days prior check in</span>
                        </span>
                    </span>
                    <h4>2. Final payment</h4>
                    Full payment is required as follows, unless credit facilities have been established, by means of a Bank Guarantee, Floating Deposit, Telegraphic Transfer or such other security.
                    <div>
                        <span>
                            <span>Based on double occupancy1 – 9 Cabins10 Cabins and moreCharter</span>
                            <span>Deposit at time of booking10%30%50%</span>
                            <span>Full Payment15 Days prior to cruise date15 Days prior to cruise date20 Days prior to cruise date</span>
                        </span>
                        <ul>
                            <li>Late payment is subjected to an interest rate of 1.5% per month.</li>
                            <li>If the bookings are confirmed less than the mentioned date, guests shall make full payment within that mentioned date or such a shorter time to ensure that Sena Cruises has received full prepayment for all bookings before check-in</li>
                            <li>All rates are non-commissionable</li>
                        </ul>
                    </div>
                </div>
            </div>
        `
	});

	const [paymentInformation, setPaymentInformation] = useState([]);

	const { property_information, payment_information, important_note } = newCruiseSetting;

	const onChange = (event) => setNewCruiseSetting({ ...newCruiseSetting, [event.target.name]: event.target.value });

	// select cruise
	const handleChange = (newValue, actionMeta) => {
		if (!newValue) return;
		const cSetting = cruiseSettings.find((item) => {
			return item.cruise_id === Number(newValue.value);
		});
		if (cSetting) {
			setNameCruise('');
			setCruiseWarning(true);
			return;
		}
		setCruiseWarning(false);
		setNewCruiseSetting({ ...newCruiseSetting, cruise_id: Number(newValue.value), cruise_name: newValue.label });
		setNameCruise(newValue.label);
	};

	const onChangeCustomerText = (index, event) => {
		if (!paymentInformation[index]) {
			paymentInformation[index] = {};
		}
		const fieldNames = event.target.name.split('-');
		const fieldName = fieldNames[0];
		paymentInformation[index][fieldName] = event.target.value;
	};

	const onChangeChildLabel = (event) => {
		setNewCruiseSetting({ ...newCruiseSetting, [event.target.name]: event.target.value });
	}

	const onSubmit = async (event) => {
		event.preventDefault();
		newCruiseSetting['payment_information'] = JSON.stringify(paymentInformation);
		newCruiseSetting['weekend'] = weekend.join(',');
		newCruiseSetting['holidays'] = holidays.join(',');

		const response = await addCruiseSetting(newCruiseSetting);
		if (response.data) {
			closeDialog();
			setShowToast({ show: true, message: t('Added sucessful!'), type: 'success' });
			return;
		}
		setShowToast({
			show: true,
			message: response.error && response.error.ErrorCode ? response.error.ErrorCode : t('Add failed!'),
			type: 'danger'
		});
	};

	const closeDialog = () => {
		clearCruise();
		setNewCruiseSetting({
			payment_information: '',
			important_note: '',
			property_information: ''
		});
		setHolidays([]);
		setWeekend([]);
		setNameCruise('');
		setCruiseWarning(false);
		setShowAddCruiseSettingModal(false);
	};

	//JSON.stringify(newBooking)
	const ChangeToSlug = (Text) => {
		var slug;

		//Đổi chữ hoa thành chữ thường
		slug = Text.toLowerCase();

		//Đổi ký tự có dấu thành không dấu
		slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
		slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
		slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
		slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
		slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
		slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
		slug = slug.replace(/đ/gi, 'd');
		//Xóa các ký tự đặt biệt
		slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi, '');
		//Đổi khoảng trắng thành ký tự gạch ngang
		slug = slug.replace(/ /gi, '-');
		//Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
		//Phòng trường hợp người nhập vào quá nhiều ký tự trắng
		slug = slug.replace(/\-\-\-\-\-/gi, '-');
		slug = slug.replace(/\-\-\-\-/gi, '-');
		slug = slug.replace(/\-\-\-/gi, '-');
		slug = slug.replace(/\-\-/gi, '-');
		//Xóa các ký tự gạch ngang ở đầu và cuối
		slug = '@' + slug + '@';
		slug = slug.replace(/\@\-|\-\@|\@/gi, '');
		//In slug ra textbox có id “slug”
		return slug;
	};

	const randomText = (length) => {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	};

	const handleChangeUploadPhoto = async (event) => {
		event.preventDefault();
		const file = event.target.files[0];
		const formData = new FormData();
		const nameFile = ChangeToSlug(nameCruise);
		const ext = file.name.split('.').slice(-1)[0];
		formData.append('file', file, nameFile + '-' + randomText(8) + '.' + ext);
		const response = await postAPI(`${apiUrl}/file/upload?folder=cruises/logo`, formData);

		if (response) {
			const fileUrl = new URL(response.data.url);
			setNewCruiseSetting({ ...newCruiseSetting, logo: fileUrl.pathname });
		}
	};

	const handleChangeUploadSlides = async (event) => {
		//
		event.preventDefault();
		const files = event.target.files;
		var ext = '';
		var textFile = '';
		const formData = new FormData();
		for (let i = 0; i < files.length; i++) {
			if (i === 0) {
				ext = files[i].name.split('.').slice(-1)[0];
				formData.append(`files`, files[i], ChangeToSlug(files[i].name.split('.')[0]) + '-' + randomText(8) + '.' + ext);
				textFile = ChangeToSlug(files[i].name.split('.')[0]) + '-' + randomText(8) + '.' + ext;
			} else {
				ext = files[i].name.split('.').slice(-1)[0];
				formData.append(`files`, files[i], ChangeToSlug(files[i].name.split('.')[0]) + '-' + randomText(8) + '.' + ext);
				textFile = textFile + ', ' + ChangeToSlug(files[i].name.split('.')[0]) + '-' + randomText(8) + '.' + ext;
			}
		}

		try {
			const response = await postAPI(`${apiUrl}/file/upload-multiple?folder=cruises/slides`, formData);
			if (response) {
				var textDataFiles = '';
				response.data.urls.map((itemUrl, index) => {
					const fileUrl = new URL(itemUrl);
					if (index === 0) {
						textDataFiles = fileUrl.pathname;
					} else {
						textDataFiles = textDataFiles + ',' + fileUrl.pathname;
					}
				});
				setNewCruiseSetting({ ...newCruiseSetting, images: textDataFiles });
			}
		} catch (error) {
			return error.response ? error.response : { success: false, message: t('Server error!') };
		}
	};

	const [holidays, setHolidays] = useState([]);
	const onSelectDate = (data) => {
		if (!data || !data.target || !data.target.value) {
			return;
		}
		const index = holidays.indexOf(data.target.value);
		if (index > -1) return;
		setHolidays([...holidays, data.target.value]);
	};
	const removeHolidayItem = (item, object) => {
		if (!item) {
			return;
		}
		const index = holidays.indexOf(item);
		if (index > -1) {
			holidays.splice(index, 1);
			setHolidays([...holidays]);
		}
	};
	const removeAllHolidayItems = () => {
		setHolidays([]);
	};
	const [weekend, setWeekend] = useState([]);
	const weekendOnchange = (object) => {
		// checked
		if (object.target.checked) {
			setWeekend([...weekend, object.target.value]);

			return;
		}

		// unchecked
		const index = weekend.indexOf(object.target.value);
		if (index > -1) {
			weekend.splice(index, 1);
			setWeekend([...weekend]);
		}
	};
	const isWeekend = (val) => {
		return weekend.indexOf(val) > -1;
	};

	// const weekendOnchange = (data) => {
	// 	console.log('onchange data', data);
	// };

	return (
		<Fragment>
			<Modal
				show={showAddCruiseSettingModal}
				onHide={closeDialog}
				size='full'
				aria-labelledby='contained-modal-title-vcenter'
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>{t('Cruise Setting')}</Modal.Title>
				</Modal.Header>
				<Form onSubmit={onSubmit}>
					<Modal.Body>
						<div className='form-group'>
							<Row>
								<Col>
									<label>
										<strong>{t('Cruise')}</strong>
									</label>
									<Select
										isClearable
										name='cruiseID'
										onChange={handleChange.bind(this)}
										options={optionCruises}
										placeholder={t('Choose Cruise')}
									/>
									{cruiseWarning ? (
										<i className='text-danger'>
											{t('Setting for this cruise is exists. Please close this form and choose edit it.')}
										</i>
									) : (
										''
									)}
								</Col>
								<Col>
									<label>
										<strong>{t('Cruise Logo')} (300x300px)</strong>
									</label>
									<input
										type='file'
										id='imageAvatar'
										disabled={nameCruise === null ? true : false}
										onChange={handleChangeUploadPhoto.bind(this)}
										placeholder='Upload Logo'
										className='form-control'
									/>
								</Col>
								<Col>
									<label>
										<strong>{t('Slides')}</strong>
									</label>
									<input
										type='file'
										id='fileContract'
										onChange={handleChangeUploadSlides.bind(this)}
										placeholder={t('Upload Contract')}
										multiple
										className='form-control'
									/>
								</Col>
							</Row>
						</div>
						<div className='form-group'>
							<Card>
								<Card.Body>
									<label>
										<strong>{t('Payment Information Group')}</strong>
									</label>
									<div className='info-payment mt-3'>
										<Row>
											<Col>
												<div className='info-payment-item'>
													<label style={{ color: '#b40505', fontWeight: '600' }}>{t('Payment Information')} (VAT)</label>
													<div className='form-group'>
														<div className='row'>
															<div className='col-md-3'>
																<label>{t('Account number')}:</label>
															</div>
															<div className='col-md-9'>
																<input
																	type='text'
																	className='form-control'
																	placeholder='0099 xxx 688 xxx'
																	id='accountNumber-0'
																	name='accountNumber-0'
																	onChange={onChangeCustomerText.bind(this, 0)}
																/>
															</div>
														</div>
													</div>
													<div className='form-group'>
														<div className='row'>
															<div className='col-md-3'>
																<label>{t('Beneficiary name')}:</label>
															</div>
															<div className='col-md-9'>
																<input
																	type='text'
																	className='form-control'
																	placeholder='CONG TY CO PHAN K2 Software'
																	id='beneficiary-0'
																	name='beneficiary-0'
																	onChange={onChangeCustomerText.bind(this, 0)}
																/>
															</div>
														</div>
													</div>
													<div className='form-group'>
														<div className='row'>
															<div className='col-md-3'>
																<label>{t('Bank name')}:</label>
															</div>
															<div className='col-md-9'>
																<input
																	type='text'
																	className='form-control'
																	placeholder='Vietcombank - Branch Transaction Office, Hanoi'
																	id='bankName-0'
																	name='bankName-0'
																	onChange={onChangeCustomerText.bind(this, 0)}
																/>
															</div>
														</div>
													</div>
												</div>
											</Col>
											<Col>
												<div className='info-payment-item'>
													<label style={{ color: '#b40505', fontWeight: '600' }}>{t('Payment Information')} ({t('NO VAT')})</label>
													<div className='form-group'>
														<div className='row'>
															<div className='col-md-3'>
																<label>{t('Account number')}:</label>
															</div>
															<div className='col-md-9'>
																<input
																	type='text'
																	className='form-control'
																	placeholder='0099 xxx 688 xxx'
																	id='accountNumber-1'
																	name='accountNumber-1'
																	onChange={onChangeCustomerText.bind(this, 1)}
																/>
															</div>
														</div>
													</div>
													<div className='form-group'>
														<div className='row'>
															<div className='col-md-3'>
																<label>{t('Beneficiary name')}:</label>
															</div>
															<div className='col-md-9'>
																<input
																	type='text'
																	className='form-control'
																	placeholder='KIEU DUC KHAI'
																	id='beneficiary-1'
																	name='beneficiary-1'
																	onChange={onChangeCustomerText.bind(this, 1)}
																/>
															</div>
														</div>
													</div>
													<div className='form-group'>
														<div className='row'>
															<div className='col-md-3'>
																<label>{t('Bank name')}:</label>
															</div>
															<div className='col-md-9'>
																<input
																	type='text'
																	className='form-control'
																	placeholder='Vietcombank - Branch Transaction Office, Hanoi'
																	id='bankName-1'
																	name='bankName-1'
																	onChange={onChangeCustomerText.bind(this, 1)}
																/>
															</div>
														</div>
													</div>
												</div>
											</Col>
										</Row>
									</div>
								</Card.Body>
							</Card>
						</div>
						<div className='form-group'>
							<Card>
								<Card.Body>
									<fieldset className='form-group child-label'>
										<label>
											<strong>{t('Child label')}</strong>
										</label>
										<Row>
											<Col className='col-md-6'>
												<div className='form-group'>
													<div className='row'>
														<div className='col-md-3'>
															<label>{t('Child label 1')}</label>
														</div>
														<div className='col-md-9'>
															<input
																type='text'
																className='form-control'
																placeholder={t('Child (5-7)')}
																id='child-label-1'
																name='child_label1'
																onChange={onChangeChildLabel.bind(this)}
															/>
														</div>
													</div>
												</div>
												<div className='form-group'>
													<div className='row'>
														<div className='col-md-3'>
															<label>{t('Child label 2')}</label>
														</div>
														<div className='col-md-9'>
															<input
																type='text'
																className='form-control'
																placeholder={t('Child (8-11)')}
																id='child-label-2'
																name='child_lable2'
																onChange={onChangeChildLabel.bind(this)}
															/>
														</div>
													</div>
												</div>
											</Col>
										</Row>
									</fieldset>
								</Card.Body>
							</Card>
						</div>
						<div className='form-group'>
							<Card>
								<Card.Body>
									<fieldset className='form-group weekend'>
										<label>
											<strong>{t('Weekend')}?</strong>
										</label>
										<Row>
											<Col>
												<div className='weekend-item'>
													<input
														key='1'
														type='checkbox'
														name='weekend'
														value='1'
														onChange={weekendOnchange}
														checked={isWeekend('1')}
													/>
													{t('Monday')}
												</div>
												<div className='weekend-item'>
													<input
														key='2'
														type='checkbox'
														name='weekend'
														value='2'
														onChange={weekendOnchange}
														checked={isWeekend('2')}
													/>
													{t('Tuesday')}
												</div>
												<div className='weekend-item'>
													<input
														key='3'
														type='checkbox'
														name='weekend'
														value='3'
														onChange={weekendOnchange}
														checked={isWeekend('3')}
													/>
													{t('Wednesday')}
												</div>
												<div className='weekend-item'>
													<input
														key='4'
														type='checkbox'
														name='weekend'
														value='4'
														onChange={weekendOnchange}
														checked={isWeekend('4')}
													/>
													{t('Thursday')}
												</div>
												<div className='weekend-item'>
													<input
														key='5'
														type='checkbox'
														name='weekend'
														value='5'
														onChange={weekendOnchange}
														checked={isWeekend('5')}
													/>
													{t('Friday')}
												</div>
												<div className='weekend-item'>
													<input
														key='6'
														type='checkbox'
														name='weekend'
														value='6'
														onChange={weekendOnchange}
														checked={isWeekend('6')}
													/>
													{t('Saturday')}
												</div>
												<div className='weekend-item'>
													<input
														key='0'
														type='checkbox'
														name='weekend'
														value='0'
														onChange={weekendOnchange}
														checked={isWeekend('0')}
													/>
													{t('Sunday')}
												</div>
											</Col>
										</Row>
									</fieldset>
									<fieldset>
										<label>
											<strong>{t('Holidays')}?</strong>
										</label>
										<Row>
											<Col className='form-group col-md-3'>
												<label>
													<strong>{t('Select date')}</strong>
												</label>
												<Form.Control key='weekend' type='date' name='holiday' onChange={onSelectDate} />
											</Col>
											<Col className='form-group col-md-9'>
												<label>
													<strong>{t('Selected dates')}</strong>
												</label>
												<div className='holiday-group'>
													{holidays &&
														holidays.map((item) => {
															return (
																<div className='holiday-item'>
																	<label>{item}</label>
																	<button
																		type='button'
																		className='close'
																		aria-label='Close'
																		onClick={removeHolidayItem.bind(this, item)}
																	>
																		<span aria-hidden='true'>&times;</span>
																	</button>
																</div>
															);
														})}
													{holidays && holidays.length > 0 && (
														<button
															type='button'
															className='close remove-all'
															aria-label='Close'
															title={t('Remove All')}
															onClick={removeAllHolidayItems.bind(this)}
														>
															<span aria-hidden='true'>&times;</span>
														</button>
													)}
												</div>
											</Col>
										</Row>
									</fieldset>
								</Card.Body>
							</Card>
						</div>
						<div className='form-group'>
							<label>
								<strong>{t('Important Note')}</strong>
							</label>
							<div className='info-payment'>
								<CKEditor
									editor={ClassicEditor}
									data={important_note}
									onReady={(editor) => {
										// You can store the "editor" and use when it is needed.
										//console.log( 'Editor is ready to use!', editor );
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setNewCruiseSetting({ ...newCruiseSetting, important_note: data });
										//console.log( { event, editor, data } );
									}}
									onBlur={(event, editor) => {
										//console.log( 'Blur.', editor );
									}}
									onFocus={(event, editor) => {
										//console.log( 'Focus.', editor );
									}}
								/>
							</div>
						</div>
						<div className='form-group'>
							<label>
								<strong>{t('Property Information')}</strong>
							</label>
							<div className='info-property'>
								<CKEditor
									style={{ maxHeight: '650px', overflow: 'scroll' }}
									editor={ClassicEditor}
									data={property_information}
									onReady={(editor) => {
										// You can store the "editor" and use when it is needed.
										//console.log( 'Editor is ready to use!', editor );
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setNewCruiseSetting({ ...newCruiseSetting, property_information: data });
										//console.log( { event, editor, data } );
									}}
									onBlur={(event, editor) => {
										//console.log( 'Blur.', editor );
									}}
									onFocus={(event, editor) => {
										//console.log( 'Focus.', editor );
									}}
								/>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button className='btn btn-danger btn-with-icon' variant='secondary' onClick={closeDialog}>
							<i className='fe fe-x-circle'></i> {t('Cancel')}
						</Button>
						<Button
							className='btn btn-primary btn-with-icon'
							variant='primary'
							disabled={nameCruise ? false : true}
							type='submit'
						>
							<i className='fe fe-save'></i> {t('Save')}!!!
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</Fragment>
	);
};

export default AddCruiseSettingModal;
