import { Fragment, useContext } from 'react';
import { CruiseContext } from '../../contexts/CruiseContext';
import { Toast } from 'react-bootstrap';
import ListCruise from '../../components/layout/cruises/ListCruise';
import UpdateCruiseModal from '../../components/layout/cruises/UpdateCruiseModal';
import { ROLES, PERMISSIONS, NO_PERMISSION } from '../../contexts/constants';
import { AuthContext } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';

const Cruise = () => {
	const {
		setShowAddCruiseModal,
		showToast: { show, message, type },
		setShowToast,
	} = useContext(CruiseContext);

	const {
		authState: { user },
	} = useContext(AuthContext);

	const { t } = useTranslation();

	if (user.Roles.length == 1 && user.Roles.includes(ROLES.PARTNER)) return NO_PERMISSION;

	return (
		<Fragment>
			<div className='horizontal-mainwrapper container clearfix'>
				<div className='explain-dashboard mt-5'>
					<div className='explain-header mb-3'>
						<div className='row'>
							<div className='col-md-4'>
								<h3 className='pull-left'>{t('Cruise List')}</h3>
							</div>
							<div className='col-md-2 ml-auto'>
								{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
								user.Permissions.includes(PERMISSIONS.ADD_CRUISE) ? (
									<button
										className='right-content btn btn-success btn-block'
										onClick={setShowAddCruiseModal.bind(this, true)}
									>
										<i className='fe fe-plus-circle'></i> {t('Add Cruise')}
									</button>
								) : null}
							</div>
						</div>
						<hr />
					</div>

					{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) || user.Permissions.includes(PERMISSIONS.GET_CRUISE) ? (
						<ListCruise />
					) : null}
					{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) || user.Permissions.includes(PERMISSIONS.ADD_CRUISE) ? (
						<UpdateCruiseModal />
					) : null}
					<Toast
						show={show}
						style={{ position: 'fixed', top: '20%', right: '10px' }}
						className={`bg-${type} text-white`}
						onClose={setShowToast.bind(this, { show: false, message: '', type: null })}
						delay={3000}
						autohide
					>
						<Toast.Body>
							<strong>{message}</strong>
						</Toast.Body>
					</Toast>
				</div>
			</div>
		</Fragment>
	);
};

export default Cruise;
