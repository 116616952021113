import { Fragment, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AuthContext } from '../../contexts/AuthContext';
import { ROLES, NO_PERMISSION, apiUrl } from '../../contexts/constants';
import { CruiseContext } from '../../contexts/CruiseContext';
import Select from 'react-select';
import RevenueDate from '../../../src/components/layout/includes/RevenueDate';
import { getAPI } from '../../utils/api';
import { Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { diffDays, findOptionItem, formatDateGMT7, getYearOptions } from '../../utils/common';
import RevenueChartBar from './RevenueChartBar';

const RevenueStatistic = () => {
	const { t } = useTranslation();
	const {
		authState: { user }
	} = useContext(AuthContext);

	const {
		cruiseState: { cruises }
	} = useContext(CruiseContext);

	const [optionCruises, setOptionCruises] = useState(cruises);

	useEffect(() => {
		if (cruises && cruises.length > 0) {
			const optCruises = cruises.map((cruise) => {
				return {
					value: cruise.id,
					label: cruise.name,
					typeDay: cruise.typeDay
				};
			});
			setOptionCruises(optCruises);
		}
	}, [cruises]); // eslint-disable-line react-hooks/exhaustive-deps
	const today = new Date().toISOString().split('T')[0];

	const [fromDate, setFromDate] = useState(today);
	const [endDate, setEndDate] = useState(today);
	const [cruiseID, setCruiseID] = useState();
	const [revenueData, setRevenueData] = useState({});
	const [usdRate, setUsdRate] = useState();

	const handleChangeCruise = (newValue) => {
		if (newValue) {
			setCruiseID(newValue.value);
		}
	};
	const onChangeFrom = (date) => {
		setFromDate(date);
		if (diffDays(date, endDate) > 31) {
			const inputDate = new Date(date);
			const eDate = new Date(inputDate.setMonth(inputDate.getMonth()+1));
			const strEDate = formatDateGMT7(eDate, 'YYYY-MM-DD')
			setEndDate(strEDate);
			return;
		}
	};
	const onChangeEnd = (date) => {
		setEndDate(date);
		if (diffDays(fromDate, date) > 31) {
			const inputDate = new Date(date);
			const fDate = new Date(inputDate.setMonth(inputDate.getMonth()-1));
			const strFDate = formatDateGMT7(fDate, 'YYYY-MM-DD')
			setFromDate(strFDate);
			return;
		}
	};

	useEffect(() => {
		if (cruiseID && fromDate && endDate) {
			async function getRevenueStatistic() {
				try {
					if (cruiseID <= 0 || !fromDate || !endDate || !usdRate) {
						return;
					}

					const response = await getAPI(
						`${apiUrl}/revenue-statistic`,
						`from_date=${fromDate}&to_date=${endDate}&cruise_id=${cruiseID}&usd_rate=${usdRate}`
					);
					if (!response.data) {
						return;
					}

					const revStatistic = response.data.RevenueStatistic;
					const marketSegments = response.data.MarketSegment;

					if (!revStatistic) {
						return;
					}
					const data = {
						adr: 0,
						aor: 0,
						aorVal: 0,
						revpar: 0,
						marketSegments: []
					};

					if (revStatistic.adr) {
						data.adr = revStatistic.adr;
					}
					if (revStatistic.booked_rooms > 0 && revStatistic.room_total > 0) {
						data.aor = (revStatistic.booked_rooms * 100) / revStatistic.room_total;
						data.aor = data.aor.toFixed(2);
					}
					if (revStatistic.revenue) {
						data.aorVal = revStatistic.revenue;
					}
					if (revStatistic.revpa) {
						data.revpar = revStatistic.revpa;
					}
					if (marketSegments && marketSegments.length > 0) {
						data.marketSegments = marketSegments;
					}

					setRevenueData(data);
				} catch (error) {
					console.log('getRevenueStatistic error', error);
				}
			}
			getRevenueStatistic();
		}
	}, [cruiseID, fromDate, endDate, usdRate]);
	const [tabKey, setTabKey] = useState('revenue-month');
	const onChangeUSDRate = (event) => {
		if (event.target && event.target.value && event.target.value.length >= 5) {
			setUsdRate(event.target.value);
		}
	};
	const year = new Date().getFullYear();
	const arrYears = getYearOptions();
	
	const [currentYear, setCurrentYear] = useState(year)
    const handleChangeDay = (dataDay, actionMeta) => {
        if (!dataDay) return;

        if(actionMeta.name === `currentYear` ){
            setCurrentYear(dataDay.value)
        }
    }

	const [cruiseOptions, setCruiseOptions] = useState([]);
	const [currentCruise, setCurrentCruise] = useState({});
	useEffect(() => {
		if (cruises && cruises.length > 0) {
			const cruiseOpts = cruises.map((cruise) => {
				return {
					label: cruise.name,
					value: cruise.id,
				};
			});
			cruiseOpts.unshift({
				label: t('All'),
				value: 'all',
			});
			setCruiseOptions(cruiseOpts);
			setCurrentCruise(cruiseOpts[0]);
		}
	}, [cruises]);

	const handleCruiseChange = (cruise) => {
		setCurrentCruise(cruise);
	};

	if (!user) {
		return NO_PERMISSION;
	}

	if (
		!(
			user.Roles.includes(ROLES.OPT) ||
			user.Roles.includes(ROLES.ACC) ||
			user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR)
		)
	) {
		return NO_PERMISSION;
	}
	const customStyles = {
		menuPortal: (provided) => ({ ...provided, zIndex: 5 }),
	};
	return (
		<Fragment>
			<div className="horizontal-mainwrapper container clearfix margin-top-10"> 
				<Tabs
					id="revenue-tabs"
					activeKey={tabKey}
					onSelect={(k) => setTabKey(k)}
					className="mb-3">
					<Tab eventKey="revenue-month" title={t('Revenue by Month')}>
						<Row md={12}>
							<Col xs={2}>
								<div className="form-group">
									<label>{t('Year')}</label>
									<Select isClearable
										name="currentYear"
										defaultValue={findOptionItem(arrYears, currentYear)}
										onChange={handleChangeDay.bind(this)}
										options={arrYears}
										placeholder={t("Year")}
										menuPosition={'fixed'}
										value={arrYears.find((item) => item.value == currentYear)}
									/>
								</div>
							</Col>
							<Col xs={2}>
								<div className='form-group'>
									<label>{t('Cruise')}</label>
									<Select
										isClearable
										name='cruise_id'
										onChange={handleCruiseChange.bind(this)}
										options={cruiseOptions}
										placeholder={t('Choose Cruise')}
										value={currentCruise}
										menuPosition={'fixed'}
										styles={customStyles}
									/>
								</div>
							</Col>
						</Row>
						<RevenueChartBar year={currentYear} cruise={currentCruise} cruises={cruises} />
					</Tab>
					<Tab eventKey="revenue-statistic" title={t('Revenue Analysis')}>
						<div className='horizontal-mainwrapper container-fluid clearfix'>
							<div className='explain-dashboard mt-5'>
								<div className='row'>
									<div className='col-md-3'>
										<div className='form-group'>
											<label>
												<strong>{t('Cruise')}</strong>
											</label>
											<Select
												isClearable
												name='cruise_id'
												onChange={handleChangeCruise.bind(this)}
												options={optionCruises && optionCruises.length > 0 ? optionCruises : []}
												placeholder={t('Choose Cruise')}
											/>
										</div>
									</div>
									<div className='col-md-6'>
										<div className='row'>
											<RevenueDate
												fromDateProps={fromDate}
												endDateProps={endDate}
												onChangeFromProps={onChangeFrom}
												onChangeEndProps={onChangeEnd}
											/>
										</div>
									</div>
									<div className='col-md-3'>
										<label>
											<strong>1 USD to VND</strong>
										</label>
										<input
											type='number'
											className='form-control'
											id='usdRate'
											name='usd-rate'
											placeholder={t('Enter USD Rate')}
											onChange={onChangeUSDRate}
											required
											aria-describedby='name-help'
											step='.01'
											min='0'
										/>
										<Form.Text id='name-help' muted>
											{t('Required')}
										</Form.Text>
									</div>
								</div>

								<table id='revenue-statistic' border='1' className='table display table-bordered mg-b-0 text-md-nowrap'>
									<thead>
										<tr>
											<th className='col-md-6'>{t('Name')}</th>
											<th className='col-md-6'>{t('Value')}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>ADR - {t('Average Daily Rate')}</td>
											<td>
												$
												<NumberFormat
													value={revenueData.adr}
													displayType={'text'}
													thousandSeparator='.'
													decimalSeparator=','
													decimalScale={2}
												/>
											</td>
										</tr>
										<tr>
											<td>AOR - {t('Average Occupancy Rate')}</td>
											<td>{revenueData.aor}%</td>
										</tr>
										<tr>
											<td>{t('AOR Value')}</td>
											<td>
												$
												<NumberFormat
													value={revenueData.aorVal}
													displayType={'text'}
													thousandSeparator='.'
													decimalSeparator=','
													decimalScale={2}
												/>
											</td>
										</tr>
										<tr>
											<td>Revpa - {t('Revenue Per Available Room')}</td>
											<td>
												$
												<NumberFormat
													value={revenueData.revpar}
													displayType={'text'}
													thousandSeparator='.'
													decimalSeparator=','
													decimalScale={2}
												/>
											</td>
										</tr>
										<tr>
											<h6 className='revenue-statistic'>{t('Market Segment')}</h6>
										</tr>
										{revenueData.marketSegments &&
											revenueData.marketSegments.map((item) => {
												return (
													<tr>
														<td>{t(item.partner_type)}</td>
														<td>{item.rate}%</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							</div>
						</div>
					</Tab>
				</Tabs>
			</div>
		</Fragment>
	);
};

export default RevenueStatistic;
