import { Fragment, useContext, useState, useEffect } from "react";
import { PickupContext } from '../../contexts/PickupContext';
import { Button, Form, Modal } from "react-bootstrap";

const UpdatePickupModal = () => {

    const { 
        pickupsState: { pickup },
        showUpdatePickupModal, 
        setShowUpdatePickupModal, 
        updatePickup, 
        setShowToast
    } = useContext(PickupContext)

    // State
    const [updatedPickup, setUpdatedPickup] = useState(pickup)
    useEffect( () => setUpdatedPickup(pickup), [pickup] )

    const { id, address } = updatedPickup

    const onChangeNew = event => setUpdatedPickup( {...updatedPickup, [event.target.name]: event.target.value } )

    const onSubmit = async event => {
        event.preventDefault()
        const response = await updatePickup(updatedPickup)
        if (response.status === 200) {
            closeDialog()
            setShowToast({show: true, message: 'Updated successful!', type: 'success'})
            return
        }
        setShowToast({show: true, message: response.error && response.error.ErrorCode? response.error.ErrorCode: 'Update failed!', type: 'danger'})
    }    

    const closeDialog = () => {
        setUpdatedPickup(pickup)
        setShowUpdatePickupModal(false)
    }

    return (
        <Fragment>
            <Modal show={showUpdatePickupModal} onHide={closeDialog}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Update Address Pickup
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <input type="hidden" name="id" value={id} />
                        <div className="form-group">
                            <label>Address</label>
                            <input className="form-control" placeholder="Enter Pickup Address" name='address' value={address} onChange={onChangeNew} required aria-describedby='address-help' />
                            <Form.Text id='address-help' muted>Required</Form.Text>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default UpdatePickupModal
