import { Fragment, useEffect, useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { GuideContext } from '../../../contexts/GuideContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { SettingContext } from '../../../contexts/SettingContext';
import { useTranslation } from 'react-i18next';

const OperatingAddSetting = props => {
    const { t } = useTranslation();
    const dateSetting = props.settingDateProps
    const cruiseId = props.cruiseIdProps

    const [checkOOS, setCheckOOS] = useState(false)

    const {
        guideState: {guides},
        getGuides
    } = useContext(GuideContext)

    const {
        authState: { user, users },
        getUsers
    } = useContext(AuthContext)

    useEffect(() => getUsers('department=opt'), []) // eslint-disable-line react-hooks/exhaustive-deps

    // Start: Get all rooms , []
    useEffect( () => getGuides(), []) // eslint-disable-line react-hooks/exhaustive-deps

    // Update Setting
    const {
        addSetting,
        setShowToast
    } = useContext(SettingContext)

    // State
    const [newSetting, setNewSetting] = useState({
        cruise_id: cruiseId,
        driver_id: '',
        guide_id: '',
        note: `<p>Hướng Dẫn Viên dặn và quản lý khách về việc mặc áo phao trên tender. Khách phải về đến bến mới được cởi áo phao.</p>
        <p>Hướng Dẫn Viên báo phòng, kiêng chay cho Cruise Manager trước 10h sáng.</p>
        <p>Yêu cầu Hướng Dẫn Viên tuân thủ chương trình.</p>
        <p><strong>Lưu ý :</strong> Ban giám đốc yêu cầu:</p>
        <p>1. HDV đón khách phải nhắc khách mang đầy đủ passport trước khi lên xe, nếu không HDV sẽ chịu hoàn toàn trách nhiệm khi có vấn đề xảy ra.</p>
        <p>2. Hướng dẫn viên đi tour phải thể hiện sự nhiệt tình cao độ, tinh thần trách nhiệm cao, chăm sóc khách chu đáo và phải được khách viết comment tốt vào trong Feedback.</p>
        `,
        oos: '',
        oos_reason: '',
        operator_id: '',
        user_id: Number(user.id),
        day_setting: dateSetting
    })

    const { cruise_id, driver_id, guide_id, note, oos, oos_reason, operator_id, user_id, day_setting } = newSetting

    const onChangeNew = event => setNewSetting( {...newSetting, [event.target.name]: event.target.value } )

    let optionGuides = []
    let optionDrivers = []
    let optionOperators = []

    if(guides){
        guides.map((guide) =>
            guide.service_type === "guide" ? (
                optionGuides = [ ...optionGuides,
                {
                    value: guide.id,
                    label: guide.name,
                }
            ] )
            : guide.service_type === "car" ? (
                optionDrivers = [ ...optionDrivers,
                {
                    value: guide.id,
                    label: guide.name,
                }
            ] )
            : ('')
        )
    }

    if(users !== null){
        users.map((user) =>
            optionOperators = [ ...optionOperators,
            {
                value: user.id,
                label: user.name,
            }])
    }

    const handleChangeCheckBox = (event) => {
        setCheckOOS(event.target.checked)
        setNewSetting( {...newSetting, [event.target.name]: `${event.target.checked}` } )
    }

    const handleChangeSelected = (newValue, actionMeta) => {
        const dataSelect = newValue;
        if(dataSelect){
            setNewSetting( {...newSetting, [actionMeta.name]: Number(dataSelect.value) } )
        }
    }

    const onSubmit = async event => {
        event.preventDefault()
        const {success, message} = await addSetting(newSetting)
        setShowToast({show: true, message, type: success ? 'success' : 'danger'})
        window.location.reload()
    }

    return (
        <Fragment>
            <Form onSubmit={onSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-2">
                                    <label><strong>{t('Guide')}</strong></label>
                                </div>
                                <div className="col-md-4">
                                    <Select isClearable
                                        onChange={handleChangeSelected.bind(this)}
                                        options={optionGuides}
                                        placeholder={t("Choose Guide")}
                                        menuPosition={'fixed'}
                                        name="guide_id"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-2">
                                    <label><strong>{t('Driver')}</strong></label>
                                </div>
                                <div className="col-md-4">
                                    <Select isClearable
                                        onChange={handleChangeSelected.bind(this)}
                                        options={optionDrivers}
                                        placeholder={t("Choose Driver")}
                                        menuPosition={'fixed'}
                                        name="driver_id"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-2">
                                    <label><strong>{t('Operator')}</strong></label>
                                </div>
                                <div className="col-md-4">
                                    <Select isClearable
                                        onChange={handleChangeSelected.bind(this)}
                                        options={optionOperators}
                                        placeholder={t("Choose Operator")}
                                        menuPosition={'fixed'}
                                        name="operator_id"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <div className="checkbox">
                                <div className="custom-checkbox custom-control">
                                    <input type="checkbox" className="custom-control-input" id="checkbox-2" name="oos" onChange={handleChangeCheckBox.bind(this)} />
                                    <label htmlFor="checkbox-2" className="custom-control-label mt-1"><strong>{t('Out of Service')}</strong></label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label><strong>{t('Reasons')}</strong></label>
                            <textarea className="form-control" placeholder={t("Reasons")} rows="4" name="oos_reason" onChange={onChangeNew}></textarea>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label><strong>{t('Notes')}</strong></label>
                            <CKEditor
                                editor={ ClassicEditor }
                                name="note"
                                data={note}
                                //onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    //console.log( 'Editor is ready to use!', editor );
                                //} }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setNewSetting( {...newSetting, note: data } )
                                } }
                                /*onBlur={ ( event, editor ) => {
                                    console.log( 'Blur.', editor );
                                } }
                                onFocus={ ( event, editor ) => {
                                    console.log( 'Focus.', editor );
                                } }*/
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2 ml-auto"><button className="right-content btn btn-success btn-block mb-3"><i className="las la-save"></i> {t('Save')}</button></div>
                </div>
            </Form>
        </Fragment>
    )
}

export default OperatingAddSetting
