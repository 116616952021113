import {
	TRANSPORTATION_ROUTES_LOADED_SUCCESS,
	TRANSPORTATION_ROUTES_LOADED_FAIL,
	ADD_TRANSPORTATION_ROUTE,
	FIND_TRANSPORTATION_ROUTE,
	UPDATE_TRANSPORTATION_ROUTE,
	DELETE_TRANSPORTATION_ROUTE
} from '../contexts/constants';

export const transportationRouteReducer = (state, action) => {
	const { type, payload } = action;

	switch (type) {
		case TRANSPORTATION_ROUTES_LOADED_SUCCESS:
			return {
				...state,
				transportationRoutes: payload,
				transportationRoutesLoading: false
			};
		case TRANSPORTATION_ROUTES_LOADED_FAIL:
			return {
				...state,
				transportationRoutes: [],
				transportationRoutesLoading: false
			};
		case ADD_TRANSPORTATION_ROUTE:
			return {
				...state,
				transportationRoutes: [payload, ...state.transportationRoutes]
			};
		case FIND_TRANSPORTATION_ROUTE:
			return {
				...state,
				transportationRoute: payload
			};
		case UPDATE_TRANSPORTATION_ROUTE:
			const newtransportationRoute = state.transportationRoutes.map((transportationRoute) => {
				if (transportationRoute.id === payload.id) {
					return payload;
				} else {
					return transportationRoute;
				}
			});
			return {
				...state,
				transportationRoutes: newtransportationRoute
			};
		case DELETE_TRANSPORTATION_ROUTE:
			return {
				...state,
				transportationRoutes: state.transportationRoutes.filter(
					(transportationRoute) => transportationRoute.id !== payload
				)
			};
		default:
			return state;
	}
};
