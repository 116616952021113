import { Fragment, useContext } from 'react';
import { CruiseContext } from '../../../contexts/CruiseContext';
import {Modal} from 'react-bootstrap';

const ShowCruiseModal = () => {

    // Context
    const {
        cruiseState: {cruise},
        showCruiseModal, 
        setShowCruiseModal
    } = useContext(CruiseContext)

    let rating = null
    const showRating = numberRating => {
        if(numberRating === 3){
            rating = (
                <div className="showRating mg-b-10">
                    <i className="typcn typcn-star-full-outline"></i>
                    <i className="typcn typcn-star-full-outline"></i>
                    <i className="typcn typcn-star-full-outline"></i>
                </div>
            )
        }else if(numberRating === 3.5) {
            rating = (
                <div className="showRating mg-b-10">
                    <i className="typcn typcn-star-full-outline"></i>
                    <i className="typcn typcn-star-full-outline"></i>
                    <i className="typcn typcn-star-full-outline"></i>
                    <i className="typcn typcn-star-half-outline"></i>
                </div>
            )
        }else if(numberRating === 4) {
            rating = (
                <div className="showRating mg-b-10">
                    <i className="typcn typcn-star-full-outline"></i>
                    <i className="typcn typcn-star-full-outline"></i>
                    <i className="typcn typcn-star-full-outline"></i>
                    <i className="typcn typcn-star-full-outline"></i>
                </div>
            )
        }else if(numberRating === 4.5) {
            rating = (
                <div className="showRating mg-b-10">
                    <i className="typcn typcn-star-full-outline"></i>
                    <i className="typcn typcn-star-full-outline"></i>
                    <i className="typcn typcn-star-full-outline"></i>
                    <i className="typcn typcn-star-full-outline"></i>
                    <i className="typcn typcn-star-half-outline"></i>
                </div>
            )
        }else if(numberRating === 5) {
            rating = (
                <div className="showRating mg-b-10">
                    <i className="typcn typcn-star-full-outline"></i>
                    <i className="typcn typcn-star-full-outline"></i>
                    <i className="typcn typcn-star-full-outline"></i>
                    <i className="typcn typcn-star-full-outline"></i>
                    <i className="typcn typcn-star-full-outline"></i>
                </div>
            )
        }
        return rating
    }

    const closeDialog = () => {
        setShowCruiseModal(false)
    }
    return (
        <Fragment>
            <Modal show={showCruiseModal} onHide={closeDialog}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
                <div className="card">
                    <div className="card-body">
                        <h3 className="main-content-label mg-b-5">{cruise.name}</h3>
                        {showRating(cruise.rating)}
                        <p className="mg-b-10"><strong>Code:</strong> {cruise.code}</p>
                        <p className="mg-b-10"><strong>Class:</strong> {cruise.class_type}</p>
                    </div>
                </div>
            </Modal>
        </Fragment>
    )
}

export default ShowCruiseModal
