import { createContext, useReducer, useState } from 'react';
import { settingReducer } from '../reducers/settingReducer';
import { apiUrl, SETTINGS_LOADED_SUCCESS, SETTINGS_LOADED_FAIL, ADD_SETTING, FIND_SETTING, UPDATE_SETTING, DELETE_SETTING } from './constants';
import axios from 'axios';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';


export const SettingContext = createContext()

const SettingContextProvider = ({ children }) => {

    // State
    const [settingState, dispatch] = useReducer(settingReducer, {
        setting: null,
        settings: [],
        settingsLoading: true
    })

    const [showAddSettingModal, setShowAddSettingModal] = useState(false)
    const [showUpdateSettingModal, setShowUpdateSettingModal] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all Settings
    const getSettings = async objectValidate => {
        const response = await getAPI(`${apiUrl}/cruise-day-settings`, `${objectValidate}`)
        if(response.data){
            dispatch({type: SETTINGS_LOADED_SUCCESS, payload: response.data})
            return response
        }
    
        dispatch({ type: SETTINGS_LOADED_FAIL })
        return response
    }

    // Add Setting
    const addSetting = async newSetting => {
        const response = await postAPI(`${apiUrl}/cruise-day-setting/insert`, newSetting)
        if(response.data){
            dispatch( {
                type: ADD_SETTING,
                payload: response.data
            } )
        }
        return response
    }

    // Find Setting when user is updating Setting
    const findSetting = settingId => {
        const setting = settingState.settings.find(setting => setting.id === settingId)
        dispatch({
            type: FIND_SETTING,
            payload: setting
        })
    }

    // Update setting
    const updateSetting = async updateSetting => {
        const response = await postAPI(`${apiUrl}/cruise-day-setting/update`, updateSetting)
        if(response.status === 200){
            dispatch( {
                type: UPDATE_SETTING,
                payload: updateSetting
            } )
        }
        return response
    }

    // Delete setting
    const deleteSetting = async settingId => {
        const response = await getDeleteAPI(`${apiUrl}/cruise-day-setting/${settingId}/delete`)
        if(response.status === 200) {
            dispatch( {
                type: DELETE_SETTING,
                payload: settingId
            } )
        }
        return response
    }

    const settingContextData = {
        settingState,
        getSettings,
        addSetting,
        findSetting,
        updateSetting,
        deleteSetting,
        showAddSettingModal,
        setShowAddSettingModal,
        showUpdateSettingModal,
        setShowUpdateSettingModal,
        showToast,
        setShowToast
    }

    return (
        <SettingContext.Provider value={settingContextData}>
            {children}
        </SettingContext.Provider>
    )
}

export default SettingContextProvider