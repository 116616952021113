import { createContext, useReducer, useState } from 'react';
import { countryReducer } from '../reducers/countryReducer';
import { apiUrl, COUNTRIES_LOADED_SUCCESS, COUNTRIES_LOADED_FAIL, ADD_COUNTRY, FIND_COUNTRY, UPDATE_COUNTRY, DELETE_COUNTRY } from './constants';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';


export const CountryContext = createContext()

const CountryContextProvider = ({ children }) => {

    // State
    const [countryState, dispatch] = useReducer(countryReducer, {
        country: null,
        countries: [],
        countriesLoading: true
    })

    const [showAddCountryModal, setShowAddCountryModal] = useState(false)
    const [showUpdateCountryModal, setShowUpdateCountryModal] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all Countries
    const getCountries = async() => {
        const response = await getAPI(`${apiUrl}/countries`)
        if(response.status === 200){
            dispatch({type: COUNTRIES_LOADED_SUCCESS, payload: response.data})
            return response
        }
    
        dispatch({ type: COUNTRIES_LOADED_FAIL })
        return response
    }

    // Add Country
    const addCountry = async newCountry => {
        const response = await postAPI(`${apiUrl}/country/insert`, newCountry)
        
        if(response.status === 200){
            dispatch( {
                type: ADD_COUNTRY,
                payload: response.data
            } )
        }
    
        return response
    }

    // Find Country when user is updating Country
    const findCountry = countryId => {
        const country = countryState.countries.find(country => country.id === countryId)
        dispatch({
            type: FIND_COUNTRY,
            payload: country
        })
    }

    // Update country
    const updateCountry = async updateCountry => {
        const response = await postAPI(`${apiUrl}/country/update`, updateCountry)
        if(response.status === 200){
            dispatch( {
                type: UPDATE_COUNTRY,
                payload: updateCountry
            } )
        }
        
        return response
    }

    // Delete Country
    const deleteCountry = async countryId => {
        const response = await getDeleteAPI(`${apiUrl}/country/${countryId}/delete`)
        if(response.status === 200){
            dispatch( {
                type: DELETE_COUNTRY,
                payload: countryId
            } )
        }
        return response
    }

    const countryContextData = {
        countryState,
        getCountries,
        addCountry,
        findCountry,
        updateCountry,
        deleteCountry,
        showAddCountryModal,
        setShowAddCountryModal,
        showUpdateCountryModal,
        setShowUpdateCountryModal,
        showToast,
        setShowToast
    }

    return (
        <CountryContext.Provider value={countryContextData}>
            {children}
        </CountryContext.Provider>
    )
}

export default CountryContextProvider