import { createContext, useState, useReducer } from 'react';
import { paymentReducer } from '../reducers/paymentReducer';
import { apiUrl, PAYMENTS_LOADED_SUCCESS, PAYMENTS_LOADED_FAIL, ADD_PAYMENT, FIND_PAYMENT, UPDATE_PAYMENT, DELETE_PAYMENT } from './constants';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';

export const PaymentContext = createContext()

const PaymentContextProvider = ({ children }) => {

    // State
    const [paymentState, dispatch] = useReducer(paymentReducer, {
        payment: null,
        payments: [],
        paymentsLoading: true
    })

    const [showAddPaymentModal, setShowAddPaymentModal] = useState(false)
    const [showUpdatePaymentModal, setShowUpdatePaymentModal] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all Payments
    const getPayments = async objectValidate => {
        const response = await getAPI(`${apiUrl}/booking-payments`, `${objectValidate}`)
        if (response.data) {
            dispatch({ type: PAYMENTS_LOADED_SUCCESS, payload: response.data })
            return response
        }
    
        dispatch({ type: PAYMENTS_LOADED_FAIL })
        return response
    }

    // Add Payment
    const addPayment = async newPayment => {
        const response = await postAPI(`${apiUrl}/booking-payment/insert`, newPayment)
        if (response.data) {
            dispatch({
                type: ADD_PAYMENT,
                payload: response.data
            })
        }
        return response
    }

    // Find Payment when user is updating post
    const findPayment = paymentId => {
        const payment = paymentState.payments.find(payment => payment.id === paymentId)
        dispatch({
            type: FIND_PAYMENT,
            payload: payment
        })
    }

    // update Payment
    const updatePayment = async updatePayment => {
        const response = await postAPI(`${apiUrl}/booking-payment/update`, updatePayment)
        if (response.status === 200) {
            dispatch({
                type: UPDATE_PAYMENT,
                payload: updatePayment
            })
        }
        return response
    }

    // Delete Payment
    const deletePayment = async paymentId => {
        const response = await getDeleteAPI(`${apiUrl}/booking-payment/${paymentId}/delete`)
        if (response.status === 200) {
            dispatch({
                type: DELETE_PAYMENT,
                payload: paymentId
            })
        }
        return response
    }

    // Post Context Data
    const paymentContextData = {
        paymentState,
        getPayments,
        addPayment,
        findPayment,
        updatePayment,
        deletePayment,
        showToast,
        setShowToast,
        showAddPaymentModal,
        setShowAddPaymentModal,
        showUpdatePaymentModal,
        setShowUpdatePaymentModal
    }

    return (
        <PaymentContext.Provider value = { paymentContextData } >
            { children }
        </PaymentContext.Provider>
    )

}

export default PaymentContextProvider