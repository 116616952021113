import { PICKUP_LOADED_SUCCESS, PICKUP_LOADED_FAIL, ADD_PICKUP, FIND_PICKUP, UPDATE_PICKUP, DELETE_PICKUP } from '../contexts/constants';

export const pickupReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case PICKUP_LOADED_SUCCESS:
            return {
                ...state,
                pickups: payload,
                pickupsLoading: false
            }
        case PICKUP_LOADED_FAIL:
            return {
                ...state,
                pickups: [],
                pickupsLoading: false
            }
        case ADD_PICKUP:
            {
                return {
                    ...state,
                    pickups: [...state.pickups, payload],
                }
            }
        case FIND_PICKUP:
            return {
                ...state,
                pickup: payload
            }
        case UPDATE_PICKUP:
            const newPickup = state.pickups.map(pickup => {
                if (pickup.id === payload.id) {
                    return payload
                } else {
                    return pickup
                }
            })
            return {
                ...state,
                pickups: newPickup
            }
        case DELETE_PICKUP:
            return {
                ...state,
                pickups: state.pickups.filter(pickup => pickup.id !== payload)
            }
        default:
            return state
    }
}