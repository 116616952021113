import { Fragment, useState, useContext, useEffect } from 'react';
import { Form, Tabs, Tab } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AuthContext } from '../../contexts/AuthContext';
import avatar from '../../assets/img/faces/6.jpg';
import { apiUrl } from '../../contexts/constants';
import axios from 'axios';
import AlertMessage from '../../components/layout/AlertMessage';
import { getAPI, postAPI } from '../../utils/api';

const Profile = () => {
	const {
		authState: { user },
		// loadUser,
		updateUser,
		logoutUser
	} = useContext(AuthContext);

	const [show, setShow] = useState(false);
	const [confirmPassword, setConfirmPassword] = useState('');
	const [alert, setAlert] = useState(null);
	const [updatedUser, setUpdatedUser] = useState(user);
	useEffect(() => setUpdatedUser(user), [user]);

	const { name, telephone, address, password, email, detail, images } = updatedUser;

	const onChangeText = (event) => setUpdatedUser({ ...updatedUser, [event.target.name]: event.target.value });

	const ChangeToSlug = (Text) => {
		var slug;

		//Đổi chữ hoa thành chữ thường
		slug = Text.toLowerCase();

		//Đổi ký tự có dấu thành không dấu
		slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
		slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
		slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
		slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
		slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
		slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
		slug = slug.replace(/đ/gi, 'd');
		//Xóa các ký tự đặt biệt
		slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi, '');
		//Đổi khoảng trắng thành ký tự gạch ngang
		slug = slug.replace(/ /gi, '-');
		//Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
		//Phòng trường hợp người nhập vào quá nhiều ký tự trắng
		slug = slug.replace(/\-\-\-\-\-/gi, '-');
		slug = slug.replace(/\-\-\-\-/gi, '-');
		slug = slug.replace(/\-\-\-/gi, '-');
		slug = slug.replace(/\-\-/gi, '-');
		//Xóa các ký tự gạch ngang ở đầu và cuối
		slug = '@' + slug + '@';
		slug = slug.replace(/\@\-|\-\@|\@/gi, '');
		//In slug ra textbox có id “slug”
		return slug;
	};

	const randomText = (length) => {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	};

	const handleChangeChk = (event) => {
		if (event.target.checked) {
			setShow(true);
		} else {
			setShow(false);
		}
	};

	const handleChangeUploadAvatar = async (event) => {
		event.preventDefault();
		if (images) {
			await getAPI(`${images}`);
		}
		const file = event.target.files[0];
		const formData = new FormData();
		const nameFile = ChangeToSlug(user.name);
		const ext = file.name.split('.').slice(-1)[0];
		formData.append('file', file, nameFile + '-' + randomText(8) + '.' + ext);
		const response = await postAPI(`${apiUrl}/file/upload?folder=users`, formData);
		if (response) {
			const fileUrl = new URL(response.data.url);
			setUpdatedUser({ ...updatedUser, images: fileUrl.pathname });
		}
	};

	//
	const onSubmit = async (event) => {
		event.preventDefault();
		try {
			await updateUser(updatedUser);
			await logoutUser();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Fragment>
			<div className='page is-expanded'>
				<div className='main-content app-content mg-t-30'>
					<div className='container'>
						<div className='row row-sm'>
							<div className='col-lg-4'>
								<div className='card mg-b-20'>
									<div className='card-body'>
										<div className='pl-0'>
											<div className='main-profile-overview'>
												<div className='main-img-user profile-user'>
													<img alt='' src={images ? `${apiUrl}/${images}` : avatar} />
													<button className='fas fa-camera profile-edit'></button>
												</div>
												<div className='d-flex justify-content-between mg-b-20'>
													<div>
														<h5 className='main-profile-name'>{user.name}</h5>
														<p className='main-profile-name-text capitalize'>{user.department}</p>
													</div>
												</div>
												<hr className='mg-y-30' />
												<label className='main-content-label tx-13 mg-b-20'>Contact Me</label>
												<div className='main-profile-social-list'>
													<div className='media'>
														<div className='media-icon bg-primary-transparent text-primary'>
															<i className='si si-screen-smartphone'></i>
														</div>
														<div className='media-body'>
															<span>Phone</span> <a href='#'>{telephone}</a>
														</div>
													</div>
													<div className='media'>
														<div className='media-icon bg-primary-transparent text-primary'>
															<i className='si si-location-pin'></i>
														</div>
														<div className='media-body'>
															<span>Address</span> <a href='#'>{address}</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-lg-8'>
								<div className='card'>
									<div className='card-body'>
										<Tabs
											className='nav nav-tabs profile navtab-custom panel-tabs'
											defaultActiveKey='home'
											transition={false}
											id='noanim-tab-example'
										>
											<Tab
												className='border-left border-bottom border-right border-top-0 p-4'
												eventKey='home'
												title='About Me'
											>
												<h4 className='tx-15 text-uppercase mb-3'>About Me</h4>
												<p dangerouslySetInnerHTML={{ __html: detail }} className='m-b-5' />
											</Tab>
											<Tab
												className='border-left border-bottom border-right border-top-0 p-4'
												eventKey='contact'
												title='Change Info'
											>
												<Form onSubmit={onSubmit}>
													<AlertMessage info={alert} />
													<div className='form-group'>
														<label>
															<strong>Full Name</strong>
														</label>
														<input
															type='text'
															id='FullName'
															placeholder='Full Name'
															name='name'
															value={name}
															onChange={onChangeText}
															className='form-control'
														/>
													</div>
													<div className='form-group'>
														<label>
															<strong>Avatar</strong>
														</label>
														<input
															type='file'
															id='imageAvatar'
															onChange={handleChangeUploadAvatar.bind(this)}
															placeholder='Upload Avatar'
															className='form-control'
														/>
													</div>
													<div className='form-group'>
														<div className='checkbox'>
															<div className='custom-checkbox custom-control'>
																<input
																	type='checkbox'
																	data-checkboxes='mygroup'
																	className='custom-control-input'
																	id='checkbox-2'
																	onChange={handleChangeChk.bind(this)}
																/>
																<label htmlFor='checkbox-2' className='custom-control-label mt-1'>
																	<strong>Change Password</strong>
																</label>
															</div>
														</div>
													</div>
													<div className={show ? 'changePassword' : 'changePassword hideClass'}>
														<div className='row'>
															<div className='col-sm'>
																<div className='form-group'>
																	<label>
																		<strong>Old Password</strong>
																	</label>
																	<input
																		type='password'
																		placeholder='Old Password'
																		id='password'
																		name='password'
																		onChange={onChangeText}
																		autoComplete='on'
																		className='form-control'
																	/>
																</div>
															</div>
															<div className='col-sm'>
																<div className='form-group'>
																	<label>
																		<strong>New Password</strong>
																	</label>
																	<input
																		type='password'
																		placeholder='New Password'
																		id='new_password'
																		name='new_password'
																		onChange={onChangeText}
																		autoComplete='on'
																		className='form-control'
																	/>
																</div>
															</div>
														</div>
													</div>
													<div className='row'>
														<div className='col-sm'>
															<div className='form-group'>
																<label>
																	<strong>Phone</strong>
																</label>
																<input
																	type='text'
																	placeholder='Enter you phone number'
																	id='numberPhone'
																	name='telephone'
																	value={telephone}
																	onChange={onChangeText}
																	className='form-control'
																/>
															</div>
														</div>
														<div className='col-sm'>
															<div className='form-group'>
																<label>
																	<strong>Address</strong>
																</label>
																<input
																	type='text'
																	placeholder='Enter you address'
																	id='address'
																	name='address'
																	value={address}
																	onChange={onChangeText}
																	className='form-control'
																/>
															</div>
														</div>
													</div>
													<div className='form-group'>
														<label>
															<strong>Description</strong>
														</label>
														<CKEditor
															editor={ClassicEditor}
															data={detail}
															onChange={(event, editor) => {
																const data = editor.getData();
																setUpdatedUser({ ...updatedUser, detail: data });
															}}
														/>
													</div>
													<div className='form-group'>
														<button className='btn btn-primary-gradient btn-block'>
															<i className='las la-save'></i> Save
														</button>
													</div>
												</Form>
											</Tab>
										</Tabs>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Profile;
