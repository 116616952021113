import { createContext, useState, useReducer } from 'react';
import { permissionReducer } from '../reducers/permissionReducer';
import { apiUrl, PERMISSIONS_LOADED_SUCCESS, PERMISSIONS_LOADED_FAIL, ADD_PERMISSION, FIND_PERMISSION, UPDATE_PERMISSION, DELETE_PERMISSION } from './constants';
import axios from 'axios';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';

export const PermissionContext = createContext()

const PermissionContextProvider = ({ children }) => {

    // State
    const [permissionState, dispatch] = useReducer(permissionReducer, {
        permission: null,
        permissions: [],
        permissionsLoading: true
    })

    const [showAddPermissionModal, setShowAddPermissionModal] = useState(false)
    const [showUpdatePermissionModal, setShowUpdatePermissionModal] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all Permissions
    const getPermissions = async() => {
        const response = await getAPI(`${apiUrl}/permissions`)
        if (response.data) {
            dispatch({ type: PERMISSIONS_LOADED_SUCCESS, payload: response.data })
            return response
        }
    
        dispatch({ type: PERMISSIONS_LOADED_FAIL })
        return response
    }

    // Add Permission
    const addPermission = async newPermission => {
        const response = await postAPI(`${apiUrl}/permission/insert`, newPermission)
        if (response.data) {
            dispatch({
                type: ADD_PERMISSION,
                payload: response.data
            })
        }
        return response
    }

    // Find Permission when user is updating Permission
    const findPermission = permissionId => {
        const permission = permissionState.permissions.find(permission => permission.id === permissionId)
        dispatch({
            type: FIND_PERMISSION,
            payload: permission
        })
    }

    // update Permission
    const updatePermission = async updatePermission => {
        const response = await postAPI(`${apiUrl}/permission/update`, updatePermission)
        if (response.status === 200) {
            dispatch({
                type: UPDATE_PERMISSION,
                payload: updatePermission
            })
        }
        return response
    }

    // Delete Permission
    const deletePermission = async permissionId => {
        const response = await getDeleteAPI(`${apiUrl}/permission/${permissionId}/delete`)
        if (response.status === 200) {
            dispatch({
                type: DELETE_PERMISSION,
                payload: permissionId
            })
        }
        return response
    }

    // Permission Context Data
    const permissionContextData = {
        permissionState,
        getPermissions,
        addPermission,
        findPermission,
        updatePermission,
        deletePermission,
        showToast,
        setShowToast,
        showAddPermissionModal,
        setShowAddPermissionModal,
        showUpdatePermissionModal,
        setShowUpdatePermissionModal
    }

    return (
        <PermissionContext.Provider value = { permissionContextData } >
            { children }
        </PermissionContext.Provider>
    )

}

export default PermissionContextProvider