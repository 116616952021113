import { Fragment, useContext, useEffect } from 'react';
import { CruiseContext } from '../../contexts/CruiseContext';
import { TransportationContext } from '../../contexts/TransportationContext';
import { AuthContext } from '../../contexts/AuthContext';
import { Toast, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { ROLES, PERMISSIONS } from '../../contexts/constants';
import ListCustomerTransport from './ListCustomerTransport';
import { useTranslation } from 'react-i18next';

const Transportation = () => {
	const { t } = useTranslation();
	const {
		authState: { user }
	} = useContext(AuthContext);

	const {
		showToast: { show, message, type },
		setShowToast
	} = useContext(TransportationContext);

	const {
		cruiseState: { cruise },
		FindACruise
	} = useContext(CruiseContext);

	let idCruise = 0;
	let dateInfo = '';

	let param1 = window.location.pathname;
	let arrUrl = param1.split('/');
	let month = 0;
	let year = 0;
	let dayCurrent = 0;
	let textCruise = '';

	if (arrUrl !== null) {
		var arrInfo = arrUrl[2].split('-');
		var cruiseIdInfo = arrInfo[3];
		var dateInfoCheck = `${arrInfo[0]}-${arrInfo[1]}-${arrInfo[2]}`;
		month = arrInfo[1];
		year = arrInfo[0];
		dayCurrent = arrInfo[2];
		if (cruiseIdInfo) {
			idCruise = cruiseIdInfo;
		}
		if (dateInfoCheck) {
			dateInfo = dateInfoCheck;
		}
	}

	useEffect(() => {
		if (idCruise) {
			FindACruise(idCruise);
		}
	}, [idCruise]); // eslint-disable-line react-hooks/exhaustive-deps

	let body = null;

	if (cruise === null) {
		body = (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info'></Spinner>
			</div>
		);
	} else {
		body = (
			<Fragment>
				<Helmet>
					<title>
						{t('Transportation List')} - {cruise.name} - {moment(dateInfo).format('MMMM D, YYYY')} - Cruises Management
						Software
					</title>
				</Helmet>
				<div className='horizontal-mainwrapper container-fluid clearfix'>
					<div className='explain-dashboard mt-5'>
						<div className='explain-header mb-3'>
							<div className='row mt-3'>
								<div className='col-md-8'>
									<h3 className='pull-left'>{t('Customer Transportation List')}</h3>
								</div>
							</div>
							<hr className='mt-3' />
							{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
							user.Permissions.includes(PERMISSIONS.GET_CUSTOMER_TRANSPORTATION) ? (
								<ListCustomerTransport cruise={cruise} dateInfo={dateInfo} />
							) : null}
							<Toast
								show={show}
								style={{ position: 'fixed', top: '20%', right: '10px' }}
								className={`bg-${type} text-white`}
								onClose={setShowToast.bind(this, { show: false, message: '', type: null })}
								delay={3000}
								autohide
							>
								<Toast.Body>
									<strong style={{ color: '#ffffff' }}>{message}</strong>
								</Toast.Body>
							</Toast>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	return <Fragment>{body}</Fragment>;
};

export default Transportation;
