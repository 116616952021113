import { Button, Form, Modal } from "react-bootstrap";
import { useContext, useState } from 'react';
import { CarContext } from '../../contexts/CarContext';
import { useTranslation } from "react-i18next";


const AddCarModal = () => {
    const { t } = useTranslation();
    const { 
        showAddCarModal, 
        setShowAddCarModal, 
        addCar, 
        setShowToast
    } = useContext(CarContext)

    // State
    const [newCar, setNewCar] = useState({
        label: '',
        capacity: '',
        description: ''
    })
    
    const { label, capacity, description } = newCar

    const onChangeNewCruiseForm = event => setNewCar( {...newCar, [event.target.name]: event.target.value } )
    const onChangeNewCruiseFormParseNumber = event => setNewCar( {...newCar, [event.target.name]: Number(event.target.value) } )

    const onSubmit = async event => {
        event.preventDefault()
        const response = await addCar(newCar)
        if (!response.error) {
            closeDialog()
            setShowToast({show: true, message: t('Added successful!'), type: 'success'})
            return
        }
        setShowToast({show: true, message: response.error.ErrorCode, type: 'danger'})
    }    

    const closeDialog = () => {
        setNewCar({
            label: '',
            capacity: '',
            description: ''
        })
        setShowAddCarModal(false)
    }


    return (
        <Modal show={showAddCarModal} onHide={closeDialog}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('Create New Car')}
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit}>
                <Modal.Body>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-9">
                                <label>{t('Name')}</label>
                                <input type="text" className="form-control" id="label" name="label" placeholder="14B-032.77" value={label} onChange={onChangeNewCruiseForm} required aria-describedby='label-help' />
                                <Form.Text id='label-help' muted>{t('Required')}</Form.Text>
                            </div>
                            <div className="col-md-3">
                                <label>{t('Number of Seats')}</label>
                                <input type="text" className="form-control" id="capacity" name="capacity" placeholder={t("Number of Seats")} value={capacity} onChange={onChangeNewCruiseFormParseNumber} required aria-describedby='code-help' />
                                <Form.Text id='code-help' muted>{t('Required')}</Form.Text>
                            </div>
                        </div>
                    </div>
                    
                    <div className="form-group">
                        <label>{t('Description')}</label>
                        <textarea className="form-control" placeholder={t("Description")} rows="3" name='description' value={description} onChange={onChangeNewCruiseForm} spellCheck="false"></textarea>
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> {t('Cancel')}</Button>
                    <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> {t('Save')}!!!</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default AddCarModal
