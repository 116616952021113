import { Fragment, useContext } from 'react'
import { GuideContext } from '../../contexts/GuideContext'
import ListGuide from '../../components/layout/guides/ListGuide'
import AddGuideModal from '../../components/layout/guides/AddGuideModal'
import { AuthContext } from '../../contexts/AuthContext';
import { ROLES, PERMISSIONS, NO_PERMISSION } from '../../contexts/constants';
import { useTranslation } from 'react-i18next';

const Guide = () => {
    const { t } = useTranslation();
    const { 
        setShowAddGuideModal
    } = useContext(GuideContext)

    const {
        authState: {user }
    } = useContext(AuthContext)

    if (!user) {
        return NO_PERMISSION
    }

    if (!(user.Permissions.includes(PERMISSIONS.GET_GUIDE) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))) {
        return NO_PERMISSION
    }

    return (
        <Fragment>
            <div className="horizontal-mainwrapper container clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-3">
                        <div className="row">
                            <div className="col-md-4">
                                <h3 className="pull-left">{t('Guide List')}</h3>
                            </div>
                            <div className="col-md-2 ml-auto">
                            {(user.Permissions.includes(PERMISSIONS.INSERT_GUIDE) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))? <button className="right-content btn btn-success btn-block" onClick={setShowAddGuideModal.bind(this, true)}><i className="fe fe-plus-circle"></i> {t('Add Guide')}</button>: null }
                            </div>
                        </div>
                    </div>
                    <ListGuide />
                    <AddGuideModal />
                    <div className='margin-bottom-150'></div>
                </div>
            </div>
        </Fragment>
    )
}

export default Guide
