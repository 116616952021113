import { ONBOARD_SERVICES_LOADED_SUCCESS, ONBOARD_SERVICES_LOADED_FAIL, ADD_ONBOARD_SERVICE, FIND_ONBOARD_SERVICE, UPDATE_ONBOARD_SERVICE, DELETE_ONBOARD_SERVICE } from '../contexts/constants';

export const onboardServiceReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case ONBOARD_SERVICES_LOADED_SUCCESS:
            return {
                ...state,
                onboardServices: payload,
                onboardServicesLoading: false
            }
        case ONBOARD_SERVICES_LOADED_FAIL:
            return {
                ...state,
                onboardServices: [],
                onboardServicesLoading: false
            }
        case ADD_ONBOARD_SERVICE:
            return {
                ...state,
                onboardServices: [...state.onboardServices, payload],
            }
        case FIND_ONBOARD_SERVICE:
            return {
                ...state,
                onboardService: payload
            }
        case UPDATE_ONBOARD_SERVICE:
            const newOnboardService = state.onboardServices.map(onboardService => {
                if (onboardService.id === payload.id) {
                    return payload
                } else {
                    return onboardService
                }
            })
            return {
                ...state,
                onboardServices: newOnboardService
            }
        case DELETE_ONBOARD_SERVICE:
            return {
                ...state,
                onboardServices: state.onboardServices.filter(onboardService => onboardService.id !== payload)
            }
        default:
            return state
    }
}