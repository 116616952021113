import { Fragment, useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { CarContext } from '../../contexts/CarContext';
import { useTranslation } from "react-i18next";

const UpdateCarModal = () => {
    const { t } = useTranslation();
    const { 
        carState: { car },
        showUpdateCarModal, 
        setShowUpdateCarModal, 
        updateCar, 
        setShowToast
    } = useContext(CarContext)

    // State
    const [updatedCar, setUpdatedCar] = useState(car)
    useEffect( () => setUpdatedCar(car), [car] )

    const { id, label, capacity, description } = updatedCar

    const onChangeNewCruiseForm = event => setUpdatedCar( {...updatedCar, [event.target.name]: event.target.value } )
    const onChangeNewCruiseFormParseNumber = event => setUpdatedCar( {...updatedCar, [event.target.name]: Number(event.target.value) } )

    const onSubmit = async event => {
        event.preventDefault()
        const response = await updateCar(updatedCar)
        if (!response.error) {
            setShowUpdateCarModal(false)
            setShowToast({show: true, message: t('Updated successful!'), type: 'success'})
            return
        }
        
        setShowToast({show: true, message: response.error.ErrorCode, type: 'danger'})
    }    

    const closeDialog = () => {
        setUpdatedCar(car)
        setShowUpdateCarModal(false)
    }

    return (
        <Fragment>
            <Modal show={showUpdateCarModal} onHide={closeDialog}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t('Update Car')}
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="form-group">
                            <div className="row">
                                <input type="hidden" name="id" value={id} />
                                <div className="col-md-9">
                                    <label>{t('Name')}</label>
                                    <input type="text" className="form-control" id="label" name="label" placeholder="14B-032.77" value={label} onChange={onChangeNewCruiseForm} required aria-describedby='label-help' />
                                    <Form.Text id='label-help' muted>{t('Required')}</Form.Text>
                                </div>
                                <div className="col-md-3">
                                    <label>{t('Number of Seats')}</label>
                                    <input type="text" className="form-control" id="capacity" name="capacity" placeholder={t("Number of Seats")} value={capacity} onChange={onChangeNewCruiseFormParseNumber} required aria-describedby='code-help' />
                                    <Form.Text id='code-help' muted>{t('Required')}</Form.Text>
                                </div>
                            </div>
                        </div>
                        
                        <div className="form-group">
                            <label>{t('Description')}</label>
                            <textarea className="form-control" placeholder={t("Description")} rows="3" name='description' value={description} onChange={onChangeNewCruiseForm} spellCheck="false"></textarea>
                        </div>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> {t('Cancel')}</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> {t('Save')}!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default UpdateCarModal
