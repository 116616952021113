import { Fragment, useContext, useEffect } from 'react';
import { GuideContext } from '../../../contexts/GuideContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { Spinner, Card } from 'react-bootstrap';
import UpdateGuideModal from './UpdateGuideModal';
import DeleteModal from '../DeleteModal';
import { ROLES, PERMISSIONS } from '../../../contexts/constants';
import { getTable, getTableOptions, getDefaultSort } from '../../../utils/table';
import { useTranslation } from 'react-i18next';

const ListGuide = () => {
	const { t } = useTranslation();
	// Context
	const {
		authState: { user }
	} = useContext(AuthContext);
	const {
		guideState: { guide, guides, guidesLoading },
		getGuides,
		findGuide,
		deleteGuide,
		setShowUpdateGuideModal,
		setShowToast
	} = useContext(GuideContext);

	const chooseGuide = (guideId) => {
		findGuide(guideId);
		setShowUpdateGuideModal(true);
	};

	// Start: Get all rooms , []
	useEffect(() => getGuides(), []); // eslint-disable-line react-hooks/exhaustive-deps

	if (guidesLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info'></Spinner>
			</div>
		);
	}
	if (guides === null || guides.length === 0) {
		return (
			<Fragment>
				<Card className='text-center mx-5 my-5'>
					<Card.Header as='h1'>{t('Hi')} {user.name}</Card.Header>
					<Card.Body>
						<Card.Title>{t('Welcome to Guide &amp; Driver Manage')}</Card.Title>
						<Card.Text>
							{t('Click the Add Guide &amp; Driver button to create your first Guide &amp; Driver')}
						</Card.Text>
					</Card.Body>
				</Card>
				{guide !== null && <UpdateGuideModal />}
			</Fragment>
		);
	}
	const guideTable = getListGuideTable(guides, user, chooseGuide, deleteGuide, setShowToast, t);
	return (
		<Fragment>
			{guideTable}
			{guide !== null && <UpdateGuideModal />}
		</Fragment>
	);
};
const getTableData = (guides, user, chooseGuide, deleteGuide, setShowToast, t) => {
	return guides.map((guide, index) => {
		return {
			index: index + 1,
			name: guide.name,
			phone: guide.phone,
			type: guide.type,
			action: (
				<div className='row'>
					<div className='col-sm'>
						{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
						user.Permissions.includes(PERMISSIONS.UPDATE_GUIDE) ? (
							<button
								className='btn btn-success btn-with-icon btn-block'
								onClick={chooseGuide.bind(this, guide.id)}
							>
								<i className='fe fe-edit'></i> {t('Edit')}
							</button>
						) : null}
					</div>
					<div className='col-sm'>
						{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
						user.Permissions.includes(PERMISSIONS.DELETE_GUIDE) ? (
							<DeleteModal
								nameProps={guide.name}
								idProps={guide.id}
								deleteFunc={deleteGuide}
								setShowToast={setShowToast}
							/>
						) : null}
					</div>
				</div>
			)
		};
	});
};

const getColumns = (t) => {
	return [
		{
			dataField: 'index',
			text: 'STT',
			sort: true
		},
		{
			dataField: 'name',
			text: t('Name'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'phone',
			text: t('Phone'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'Type',
			text: t('Type'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'action',
			text: ''
		}
	];
};

const getListGuideTable = (guides, user, chooseGuide, deleteGuide, setShowToast, t) => {
	if (!guides || guides.length === 0) return null;
	const tableData = getTableData(guides, user, chooseGuide, deleteGuide, setShowToast, t);
	const columns = getColumns(t);
	const defaultSorted = getDefaultSort();
	const options = getTableOptions(tableData.length, t);

	return getTable(tableData, columns, defaultSorted, options);
};

export default ListGuide;
