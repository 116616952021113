import { Fragment, useContext, useState, useEffect } from 'react';
import { OnboardServiceContext } from '../../../contexts/OnboardServiceContext';
import {Modal, Button, Form} from 'react-bootstrap';

const UpdateOnboardServiceModal = () => {

    // Context

    const {
        onboardServiceState: {onboardService},
        showUpdateOnboardServiceModal,
        setShowUpdateOnboardServiceModal,
        updateOnboardService,
        setShowToast
    } = useContext(OnboardServiceContext)

    const checkLock = () => {
        if(onboardService.status === 'Disabled'){
            return true
        }else{
            return false
        }
    }
    const [checkedLock, setCheckedLock] = useState(checkLock());

    const [updatedOnboardService, setUpdatedOnboardService] = useState(onboardService)
    useEffect( () => setUpdatedOnboardService(onboardService), [onboardService] )
    const {
        id,
        name,
        price_vnd,
        price_usd,
        description,
        status
    } = updatedOnboardService

    const closeDialog = () => {
        setShowUpdateOnboardServiceModal(false)
    }

    const onChangeNewOnboardServiceForm = event => setUpdatedOnboardService( {...updatedOnboardService, [event.target.name]: event.target.value } )
    const onChangeNumber = event => setUpdatedOnboardService( {...updatedOnboardService, [event.target.name]: Number(event.target.value) } )
    const onChangeNumberFloat = event => setUpdatedOnboardService( {...updatedOnboardService, [event.target.name]: parseFloat(event.target.value) } )

    const onChangeCheckboxLock = event => {
        if(event.target.checked === true){
            setUpdatedOnboardService( {...updatedOnboardService, [event.target.name]: 'Disabled' } )
            setCheckedLock(true)
        }else{
            setUpdatedOnboardService( {...updatedOnboardService, [event.target.name]: 'Active' } )
            setCheckedLock(false)
        }
    }

    //updateOnboardService
    const onSubmit = async event => {
        event.preventDefault()
        const response = await updateOnboardService(updatedOnboardService)
        if (response.status === 200) {
            closeDialog()
            setShowToast({show: true, message: 'Updated successful!', type: 'success'})
            return
        }
        setShowToast({show: true, message: response.error && response.error.ErrorCode? response.error.ErrorCode: 'Update failed!', type: 'danger'})
    }

    return (
        <Fragment>
            <Modal show={showUpdateOnboardServiceModal} onHide={closeDialog}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Update Onboard Service
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-sm">
                                <input type="hidden" name="id" value={id} />
                                <div className="form-group">
                                    <label>Name</label>
                                    <input type="text" className="form-control" id="name" name="name" value={name} placeholder="Enter Service Name" required aria-describedby='name-help' onChange={onChangeNewOnboardServiceForm} />
                                    <Form.Text id='name-help' muted>Required</Form.Text>
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <label>Price (VNĐ)</label>
                                    <input type="number" className="form-control" id="price_vnd" name="price_vnd" value={price_vnd} placeholder="0" aria-describedby='price_vnd-help' onChange={onChangeNumber} min="0" />
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <label>Price (USD)</label>
                                    <input type="number" className="form-control" id="price_usd" name="price_usd" value={price_usd} placeholder="0" aria-describedby='price_usd-help' step=".01" onChange={onChangeNumberFloat} min="0" />
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group justify-content-end">
                                    <label>Disable</label>
                                    <div className="checkbox">
                                        <div className="custom-checkbox custom-control">
                                            <input type="checkbox" value={status} className="custom-control-input" name="status" id="status" onChange={onChangeCheckboxLock} defaultChecked={checkedLock}/>
                                            <label htmlFor="status" className="custom-control-label mt-1">Yes</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Description</label>
                                    <textarea className="form-control" placeholder="Description" rows="3" name='description' value={description} spellCheck="false" onChange={onChangeNewOnboardServiceForm}></textarea>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default UpdateOnboardServiceModal
