import { Fragment, useContext } from 'react';
import { TransportationRouteContext } from '../../contexts/TransportationRouteContext';
import ListTransportationRoute from './ListTransportationRoute';
import UpdateTransportationRoute from './UpdateTransportationRoute';
import { AuthContext } from '../../contexts/AuthContext';
import { ROLES, PERMISSIONS, NO_PERMISSION } from '../../contexts/constants';
import { useTranslation } from 'react-i18next';

const TransportationRoute = () => {
	const { t } = useTranslation();
	const { setShowAddTransportationRouteModal } = useContext(TransportationRouteContext);

	const {
		authState: { user }
	} = useContext(AuthContext);

	if (!user) {
		return NO_PERMISSION;
	}

	if (
		!(
			user.Permissions.includes(PERMISSIONS.GET_TRANSPORTATION_ROUTE) ||
			user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR)
		)
	) {
		return NO_PERMISSION;
	}
	return (
		<Fragment>
			<div className='horizontal-mainwrapper container clearfix'>
				<div className='explain-dashboard mt-5'>
					<div className='explain-header mb-3'>
						<div className='row'>
							<div className='col-md-4'>
								<h3 className='pull-left'>{t('Transportation Route')}</h3>
							</div>
							<div className='col-md-2 ml-auto'>
								{user.Permissions.includes(PERMISSIONS.INSERT_TRANSPORTATION_ROUTE) ||
								user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ? (
									<button
										className='right-content btn btn-success btn-block'
										onClick={setShowAddTransportationRouteModal.bind(this, true)}
									>
										<i className='fe fe-plus-circle'></i> {t('Add Transportation Route')}
									</button>
								) : null}
							</div>
						</div>
						<hr />
					</div>
					<ListTransportationRoute />
					<UpdateTransportationRoute />
					<div className='margin-bottom-150'></div>
				</div>
			</div>
		</Fragment>
	);
};

export default TransportationRoute;
