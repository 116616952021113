import { Fragment, useState } from 'react';
import Select from 'react-select';
import { findOptionItem, getYearOptions } from '../../../utils/common';

const CheckinDateControl = props => {

    const month = props.monthProps
    const year = props.yearProps
    // const idCruise = props.idCruiseProps
    const setCurrentMonthParent = props.setCurrentMonth
    const setCurrentYearParent = props.setCurrentYear

    const [currentMonth, setCurrentMonth] = useState(month)
    const [currentYear, setCurrentYear] = useState(year)

    let arrMonths = []
    let arrYears = []

    const months = ['January','February','March','April','May','June','July','August','September','October','November','December']

    // eslint-disable-next-line no-lone-blocks
    {Array.from(Array(12), (e, i) => {
        arrMonths = [...arrMonths,
            {
                value: (i + 1),
                label: months[i]
            }
        ]
    })}

    arrYears = getYearOptions();

    const handleChangeDay = (newValue, actionMeta) => {
        const dataDay = newValue;
        if (!dataDay) return;
        if(actionMeta.name === `currentMonth` ){
            setCurrentMonth(dataDay.value)
            setCurrentMonthParent(dataDay.value)
        }

        if(actionMeta.name === `currentYear` ){
            setCurrentYear(dataDay.value)
            setCurrentYearParent(dataDay.value)
        }
    }

    const openLinkMinus = () => {
        let year = currentYear;
        var month = Number(currentMonth) - 1
        if (month === 0) {
            month = 12;
            year--;
        }
        
        setCurrentMonth(month)
        setCurrentYear(year)
        setCurrentMonthParent(month)
        setCurrentYearParent(year)
    }

    const openLinkPlus = () => {
        let year = currentYear;
        var month = Number(currentMonth) + 1
        if (month === 13) {
            month = 1;
            year++;
        }
    
        setCurrentMonth(month)
        setCurrentYear(year)
        setCurrentMonthParent(month)
        setCurrentYearParent(year)
    }


    return (
        <Fragment>
            <div className="date-control">
                <div className="date-control-button-item mr-2">
                    <button type="button" className="btn btn-danger btn-icon" onClick={openLinkMinus.bind(this)}><i className="fe fe-minus"></i></button>
                </div>
                <div className="date-control-select mr-2">
                    <div className="date-control-month">
                        <label><strong>Month</strong></label>
                        <Select isClearable
                            name="currentMonth"
                            value={arrMonths.find(item => item.value === currentMonth)}
                            onChange={handleChangeDay.bind(this)}
                            options={arrMonths}
                            placeholder="Month"
                        />
                    </div>
                </div>
                <div className="date-control-select mr-2">
                    <div className="date-control-year">
                        <label><strong>Year</strong></label>
                        <Select isClearable
                            name="currentYear"
                            value={findOptionItem(arrYears, currentYear)}
                            onChange={handleChangeDay.bind(this)}
                            options={arrYears}
                            placeholder="Year"
                        />
                    </div>
                </div>
                <div className="date-control-button-item">
                    <button type="button" className="btn btn-danger btn-icon" onClick={openLinkPlus.bind(this)}><i className="fe fe-plus"></i></button>
                </div>
            </div>
        </Fragment>
    )
}

export default CheckinDateControl
