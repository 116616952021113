import { createContext, useReducer, useState, useContext } from 'react';
import { roomTypeReducer } from '../reducers/roomTypeReducer';
import {
	apiUrl,
	ROOM_TYPE_LOADED_SUCCESS,
	ROOM_TYPE_LOADED_FAIL,
	FIND_ROOM_TYPE,
	UPDATE_ROOM_TYPE,
	DELETE_ROOM_TYPE,
	ADD_ROOM_TYPE,
} from '../contexts/constants';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';

export const RoomTypeContext = createContext();

const RoomTypeContextProvider = ({ children }) => {
	// State
	const [roomTypeState, dispatch] = useReducer(roomTypeReducer, {
		roomType: null,
		roomTypes: [],
		roomTypesLoading: true,
	});

	const [showAddRoomTypeModal, setShowAddRoomTypeModal] = useState(false);
	const [showUpdateRoomTypeModal, setShowUpdateRoomTypeModal] = useState(false);
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null,
	});

	// Get all Rooms Type
	const getRoomTypes = async (objectValidate) => {
		const response = await getAPI(
			`${apiUrl}/room-types`,
			`${objectValidate}`
		);
		
		if (response.data) {
			dispatch({
				type: ROOM_TYPE_LOADED_SUCCESS,
				payload: response.data,
			});
			return response;
		}

		dispatch({ type: ROOM_TYPE_LOADED_FAIL });
		return response;
	};

	// Add Room Type
	const addRoomType = async (newRoomType) => {
		const response = await postAPI(
			`${apiUrl}/room-type/insert`,
			newRoomType
		);
		if (response.data) {
			dispatch({
				type: ADD_ROOM_TYPE,
				payload: response.data,
			});
		}

		return response;
	};

	// Find Room Type when user is updating room
	const findRoomType = (roomTypeId) => {
		const roomType = roomTypeState.roomTypes.find(
			(roomType) => roomType.id === roomTypeId
		);
		dispatch({
			type: FIND_ROOM_TYPE,
			payload: roomType,
		});
	};

	// Update Room Type
	const updateRoomType = async (updateRoomType) => {
		const response = await postAPI(
			`${apiUrl}/room-type/update`,
			updateRoomType
		);
		if (response.status === 200) {
			dispatch({
				type: UPDATE_ROOM_TYPE,
				payload: updateRoomType,
			});
		}
		return response;
	};

	// Delete Room Type
	const deleteRoomType = async (roomTypeId) => {
		const response = await getDeleteAPI(
			`${apiUrl}/room-type/${roomTypeId}/delete`
		);
		if (response.status === 200) {
			dispatch({
				type: DELETE_ROOM_TYPE,
				payload: roomTypeId,
			});
		}
		return response;
	};

	const roomTypeContextData = {
		roomTypeState,
		getRoomTypes,
		addRoomType,
		findRoomType,
		updateRoomType,
		deleteRoomType,
		showAddRoomTypeModal,
		setShowAddRoomTypeModal,
		showUpdateRoomTypeModal,
		setShowUpdateRoomTypeModal,
		showToast,
		setShowToast,
	};

	return (
		<RoomTypeContext.Provider value={roomTypeContextData}>
			{children}
		</RoomTypeContext.Provider>
	);
};

export default RoomTypeContextProvider;
