import React, { Fragment, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const DeleteModal = (props) => {
	const { t } = useTranslation();
	const id = props && props.idProps ? props.idProps : 0;
	const deleteModal = props && props.deleteFunc ? props.deleteFunc : null;
	const setShowToast = props && props.setShowToast ? props.setShowToast : null;
	const nameItem = props?.nameProps ? <strong>{props.nameProps}</strong> : 'this';

	const [showDelete, setShowDelete] = useState(false);
	const handleClose = () => setShowDelete(false);
	const handleShow = () => setShowDelete(true);
	const deleteConfirm = async (id) => {
		if (!id) {
			return;
		}
		const response = await deleteModal(id);
		if (response.status === 200) {
			setShowToast({ show: true, message: t('Deleted successful!'), type: 'success' });
			handleClose();
			return;
		}
		// show toast message failed
		setShowToast({
			show: true,
			message: response.error.ErrorCode ? response.error.ErrorCode : t('Delete failed!'),
			type: 'danger'
		});
	};

	return (
		<Fragment>
			<Button className='btn btn-danger btn-with-icon btn-block' variant='primary' onClick={handleShow}>
				<i className='fe fe-trash-2'></i> {t('Delete')}
			</Button>
			<Modal show={showDelete} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{t('Confirm Delete')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{t('Are you sure you want to delete')} {nameItem}?</Modal.Body>
				<Modal.Footer>
					<Button className='btn btn-secondary btn-with-icon' variant='secondary' onClick={handleClose}>
						<i className='fe fe-x-circle'></i> {t('Cancel')}
					</Button>
					<Button
						className='btn btn-danger btn-with-icon'
						variant='primary'
						onClick={deleteConfirm.bind(this, id)}
					>
						<i className='fe fe-trash-2'></i> {t('Delete')}
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
};

export default DeleteModal;
