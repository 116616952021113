import { COUNTRIES_LOADED_SUCCESS, COUNTRIES_LOADED_FAIL, ADD_COUNTRY, FIND_COUNTRY, UPDATE_COUNTRY, DELETE_COUNTRY } from '../contexts/constants';

export const countryReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case COUNTRIES_LOADED_SUCCESS:
            return {
                ...state,
                countries: payload,
                countriesLoading: false
            }
        case COUNTRIES_LOADED_FAIL:
            return {
                ...state,
                countries: [],
                countriesLoading: false
            }
        case ADD_COUNTRY:
            return {
                ...state,
                countries: [...state.countries, payload],
            }
        case FIND_COUNTRY:
            return {
                ...state,
                country: payload
            }
        case UPDATE_COUNTRY:
            const newCountry = state.countries.map(country => {
                if (country.id === payload.id) {
                    return payload
                } else {
                    return country
                }
            })
            return {
                ...state,
                countries: newCountry
            }
        case DELETE_COUNTRY:
            return {
                ...state,
                countries: state.countries.filter(country => country.id !== payload)
            }
        default:
            return state
    }
}