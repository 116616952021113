import { Fragment, useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { TransportationPickUpContext } from '../../contexts/TransportationPickUpContext';
import DeleteModal from '../../components/layout/DeleteModal';
import UpdateTransportationPickUpModal from './UpdateTransportationPickUp';
import { Card, Spinner } from 'react-bootstrap';
import { ROLES, PERMISSIONS } from '../../contexts/constants';
import { getTable, getTableOptions, getDefaultSort } from '../../utils/table';
import { useTranslation } from 'react-i18next';

const ListTransportationPickUp = () => {
	const { t } = useTranslation();
	const {
		authState: { user }
	} = useContext(AuthContext);

	const {
		transportationPickUpState: { transportationPickUp, transportationPickUps, transportationPickUpLoading },
		getTransportationPickUps,
		findTransportationPickUp,
		deleteTransportationPickUp,
		setShowUpdateTransportationPickUpModal,
		setShowToast
	} = useContext(TransportationPickUpContext);

	useEffect(() => {
		if (!transportationPickUps || !transportationPickUps.length) getTransportationPickUps();
	}, []);

	const chooseTransportationPickUp = (transportationPickUpId) => {
		findTransportationPickUp(transportationPickUpId);
		setShowUpdateTransportationPickUpModal(true);
	};

	if (transportationPickUpLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info'></Spinner>
			</div>
		);
	}
	if (transportationPickUps === null || transportationPickUps.length === 0) {
		return (
			<Fragment>
				<Card className='text-center mx-5 my-5'>
					<Card.Header as='h1'>{t('Hi')} {user.name}</Card.Header>
					<Card.Body>
						<Card.Title>{t('Welcome to Transportation PickUp Manage')}</Card.Title>
						<Card.Text>
							{t('Click The Button Add Transportation PickUp to Create Your First Transportation PickUp')}
						</Card.Text>
					</Card.Body>
				</Card>
				{transportationPickUp !== null && <UpdateTransportationPickUpModal />}
			</Fragment>
		);
	}

	const tableProps = {
		user,
		transportationPickUps,
		chooseTransportationPickUp,
		deleteTransportationPickUp,
		setShowToast,
		t
	};
	return getTransportationPickUp(tableProps);
};

const getTransportationPickUp = (tableProps) => {
	if (!tableProps.transportationPickUps || tableProps.transportationPickUps.length === 0) return null;
	const tableData = getTableData(tableProps);
	const columns = getColumns(tableProps.t);
	const defaultSorted = getDefaultSort([['index', 'asc']]);
	const options = getTableOptions(tableData.length, tableProps.t);

	return getTable(tableData, columns, defaultSorted, options);
};
const getTableData = (tableProps) => {
	const { user, transportationPickUps, chooseTransportationPickUp, deleteTransportationPickUp, setShowToast, t } =
		tableProps;
	return transportationPickUps.map((transportationPickUp, index) => {
		return {
			index: index + 1,
			id: transportationPickUp.id,
			name: transportationPickUp.name,
			description: transportationPickUp.description,
			action: (
				<div className='row'>
					<div className='action'>
						{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
						user.Permissions.includes(PERMISSIONS.UPDATE_TRANSPORTATION_PICKUP) ? (
							<button
								className='btn btn-success btn-with-icon'
								onClick={chooseTransportationPickUp.bind(this, transportationPickUp.id)}
							>
								<i className='fe fe-edit'></i> {t('Edit')}
							</button>
						) : null}
					</div>
					<div className='action'>
						{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
						user.Permissions.includes(PERMISSIONS.DELETE_TRANSPORTATION_PICKUP) ? (
							<DeleteModal
								nameProps={transportationPickUp.name}
								idProps={transportationPickUp.id}
								deleteFunc={deleteTransportationPickUp}
								setShowToast={setShowToast}
							/>
						) : null}
					</div>
				</div>
			)
		};
	});
};
const getColumns = (t) => {
	return [
		{
			dataField: 'index',
			text: 'STT',
			sort: true
		},
		{
			dataField: 'id',
			text: 'ID',
			sort: true
		},
		{
			dataField: 'name',
			text: t('Name'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'description',
			text: t('Description'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'action',
			text: '',
			sort: true
		}
	];
};

export default ListTransportationPickUp;
