import React, { Fragment, useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { PartnerContext } from '../../../contexts/PartnerContext';
import { PriceContext } from '../../../contexts/PriceContext';
import Moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, {
	PaginationProvider,
	PaginationListStandalone,
	SizePerPageDropdownStandalone,
	PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator';
import { useTranslation } from 'react-i18next';

const ShowPriceModal = (props) => {
	const { t } = useTranslation();
	const pData = props.priceData;

	const { showPriceModal, setShowPriceModal } = useContext(PriceContext);
	const closeDialog = () => {
		setShowPriceModal(false);
	};
	const {
		partnerState: { pricePartners },
	} = useContext(PartnerContext);

	// table
	const partnerPriceTableData = getPartnerTable(pricePartners, t);

	return (
		<Fragment>
			<Modal
				show={showPriceModal}
				onHide={closeDialog}
				size='lg'
				aria-labelledby='contained-modal-title-vcenter'
				centered
			>
				<div>
					<div className='card-body'>
						<h3 className='main-content-label mg-b-5'>
							<strong>{t('Price Name')}: </strong>
							{pData ? pData.price_name : ''}
						</h3>
						<p className='mg-b-10'>
							<strong>{t('Cruise')}:</strong>{' '}
							{pData ? pData.cruise_name : ''}
						</p>
						<p className='mg-b-10'>
							<strong>{t('From')}:</strong>{' '}
							{pData
								? Moment(pData.from_date).format('DD-MM-YYYY')
								: ''}
						</p>
						<p className='mg-b-10'>
							<strong>{t('To')}:</strong>{' '}
							{pData
								? Moment(pData.to_date).format('DD-MM-YYYY')
								: ''}
						</p>
						<div className='mg-b-10'>
							<strong>{t('Partners')}:</strong>
							<br />
						</div>
						<hr />
						<div>{partnerPriceTableData}</div>
					</div>
				</div>
			</Modal>
		</Fragment>
	);
};

const getPartnerTable = (pricePartners, t) => {
	const { SearchBar } = Search;
	let partnerPriceTableData = null;
	if (pricePartners && pricePartners.length > 0) {
		const partnerData = pricePartners.map((p, index) => {
			let pl = {};
			pl.index = index + 1;
			pl.id = p.id;
			pl.name = p.name;
			pl.partner_type = p.partner_type;
			return pl;
		});
		const columns = [
			{
				dataField: 'index',
				text: 'STT',
				sort: true,
			},
			{
				dataField: 'id',
				text: 'ID',
				sort: true,
			},
			{
				dataField: 'name',
				text: t('Partner Name'),
				sort: true,
			},
			{
				dataField: 'partner_type',
				text: t('Partner Type'),
				sort: true,
			},
		];
		const defaultSorted = [
			{
				dataField: 'id',
				order: 'asc',
			},
		];
		const options = {
			custom: true,
			sizePerPage: 10,
			paginationSize: 4,
			pageStartIndex: 1,
			firstPageText: t('First'),
			prePageText: t('Back'),
			nextPageText: t('Next'),
			lastPageText: t('Last'),
			nextPageTitle: t('First'),
			prePageTitle: t('Pre page'),
			firstPageTitle: t('Next page'),
			lastPageTitle: t('Last page'),
			showTotal: true,
			totalSize: partnerData.length,
		};

		const partnerTable = ({ paginationProps, paginationTableProps }) => (
			<div className='contentTable'>
				<div className='pull-right'>
					<SizePerPageDropdownStandalone {...paginationProps} />
					<PaginationTotalStandalone {...paginationProps} />
				</div>
				<PaginationListStandalone {...paginationProps} />
				<ToolkitProvider
					keyField='id'
					data={partnerData}
					columns={columns}
					search={{ defaultSearch: '' }}
					defaultSorted={defaultSorted}
					pagination={paginationFactory()}
				>
					{(props) => (
						<div>
							<SearchBar {...props.searchProps} />
							<hr />
							<div className='table-responsive unpaid-table'>
								<div className='fullWidth100 widthFull'>
									<BootstrapTable
										striped
										hover
										{...props.baseProps}
										{...paginationTableProps}
									/>
								</div>
							</div>
							<div className='pull-right'>
								<SizePerPageDropdownStandalone
									{...paginationProps}
								/>
								<PaginationTotalStandalone
									{...paginationProps}
								/>
							</div>
						</div>
					)}
				</ToolkitProvider>
				<PaginationListStandalone {...paginationProps} />
			</div>
		);
		partnerPriceTableData = (
			<PaginationProvider pagination={paginationFactory(options)}>
				{partnerTable}
			</PaginationProvider>
		);
	}

	return partnerPriceTableData;
};

export default ShowPriceModal;
