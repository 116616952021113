import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { findOptionItem, getYearOptions } from '../../../utils/common';
//import { Link } from 'react-router-dom';

const DateControl = props => {
    const { t } = useTranslation();
    const setCurrentMonth = props.setCurrentMonth
    const setCurrentYear = props.setCurrentYear
    const currentMonth = props.monthProps
    const currentYear = props.yearProps
    const idCruise = props.idCruiseProps

    let arrMonths = []
    let arrYears = []
    let textMonth = ''
    let textYear = ''

    //var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let months = [t('January'),t('February'),t('March'),t('April'), t('May'), t('June'), t('July'), t('August'), t('September'), t('October'), t('November'), t('December')]

    // eslint-disable-next-line no-lone-blocks
    {Array.from(Array(12), (e, i) => {
        if((i + 1) === Number(currentMonth)){
            textMonth = months[i]
        }
        arrMonths = [...arrMonths,
            {
                value: (i + 1),
                label: months[i]
            }
        ]
    })}

    arrYears = getYearOptions();

    const handleChangeDay = (newValue, actionMeta) => {
        const dataDay = newValue;
        if(actionMeta.name === `currentMonth` ){
            var textMonth = ''
            if(dataDay.value < 10){
                textMonth = ("0" + dataDay.value).slice(-2);
            }else{
                textMonth = dataDay.value
            }
            setCurrentMonth(textMonth)
        }

        if(actionMeta.name === `currentYear` ){
            setCurrentYear(dataDay.value)
        }
    }

    const openLinkMinus = () => {
        let year = currentYear;
        var monthLink = Number(currentMonth) - 1
        if (monthLink === 0) {
            monthLink = 12;
            year--;
        }
        if(monthLink < 10){
            monthLink = ("0" + monthLink).slice(-2);
        }

        var newPageUrl = `/bookings/${monthLink}-${year}-${idCruise}`
        window.open(newPageUrl, "_blank")
    }

    const openLinkPlus = () => {
        let year = currentYear;
        var monthLink = Number(currentMonth) + 1
        if (monthLink === 13) {
            monthLink = 1;
            year++;
        }
        if(monthLink < 10){
            monthLink = ("0" + monthLink).slice(-2);
        }
        var newPageUrl = `/bookings/${monthLink}-${year}-${idCruise}`
        window.open(newPageUrl, "_blank")
    }

    return (
        <Fragment>
            <div className="date-control">
                <div className="date-control-button-item mr-2">
                    <button type="button" className="btn btn-danger btn-icon" onClick={openLinkMinus.bind(this)}><i className="fe fe-minus"></i></button>
                </div>
                <div className="date-control-select mr-2">
                    <div className="date-control-month">
                        <label><strong>{t('Month')}</strong></label>
                        
                        <Select isClearable
                            name="currentMonth"
                            defaultValue={{ label: textMonth, value: Number(currentMonth) }}
                            onChange={handleChangeDay.bind(this)}
                            options={arrMonths}
                            placeholder="Month"
                        />
                    </div>
                </div>
                <div className="date-control-select mr-2">
                    <div className="date-control-year">
                        <label><strong>{t('Year')}</strong></label>
                        <Select isClearable
                            name="currentYear"
                            defaultValue={findOptionItem(arrYears, currentYear)}
                            onChange={handleChangeDay.bind(this)}
                            options={arrYears}
                            placeholder="Year"
                        />
                    </div>
                </div>
                <div className="date-control-button-item">
                    <button type="button" className="btn btn-danger btn-icon" onClick={openLinkPlus.bind(this)}><i className="fe fe-plus"></i></button>
                </div>
            </div>
        </Fragment>
    )
}

export default DateControl
