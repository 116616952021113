import * as XLSX from 'xlsx';
import * as XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';
export const exportProcessing = async (data, comInfo) => {
	let startRowIndex = comInfo ? comInfo.length + 1 : 1;
	if (!startRowIndex) startRowIndex = 1;
	const ws = XLSX.utils.json_to_sheet(data, { origin: `A${startRowIndex + 1}` });

	// add company information
	const mergeForCompanyInfo = [];
	comInfo.forEach((item, index) => {
		const rowIndex = comInfo.length - index;
		XLSX.utils.sheet_add_aoa(ws, [[item]], { origin: `A${startRowIndex - rowIndex}` });
		mergeForCompanyInfo.push({
			s: { r: startRowIndex - rowIndex - 1, c: 0 },
			e: { r: startRowIndex - rowIndex - 1, c: 20 }
		});
	});

	XLSX.utils.sheet_add_aoa(ws, [['BẢNG KÊ CÔNG NỢ DỊCH VỤ PHÒNG']], { origin: `A${startRowIndex}` });
	const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
	const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	const rowLength = data.length + startRowIndex;

	const merge = [
		{ s: { r: startRowIndex - 1, c: 0 }, e: { r: startRowIndex - 1, c: 20 } },

		{ s: { r: rowLength - 5, c: 0 }, e: { r: rowLength - 5, c: 1 } },
		{ s: { r: rowLength - 4, c: 0 }, e: { r: rowLength - 4, c: 1 } },
		{ s: { r: rowLength - 3, c: 0 }, e: { r: rowLength - 3, c: 1 } },
		{ s: { r: rowLength - 2, c: 0 }, e: { r: rowLength - 2, c: 1 } },
		{ s: { r: rowLength - 1, c: 0 }, e: { r: rowLength - 1, c: 1 } },
		{ s: { r: rowLength, c: 0 }, e: { r: rowLength, c: 1 } },

		{ s: { r: rowLength - 5, c: 2 }, e: { r: rowLength - 5, c: 3 } },
		{ s: { r: rowLength - 4, c: 2 }, e: { r: rowLength - 4, c: 3 } },
		{ s: { r: rowLength - 3, c: 2 }, e: { r: rowLength - 3, c: 3 } },
		{ s: { r: rowLength - 2, c: 2 }, e: { r: rowLength - 2, c: 3 } },
		{ s: { r: rowLength - 1, c: 2 }, e: { r: rowLength - 1, c: 3 } },
		{ s: { r: rowLength, c: 2 }, e: { r: rowLength, c: 3 } }
	];

	ws['!merges'] = [...merge, ...mergeForCompanyInfo];

	const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
	const expData = new Blob([excelBuffer], { type: fileType });

	return addStyleCustomerRoom(expData, rowLength, startRowIndex, comInfo);
};

const addStyleCustomerRoom = (workbookBlob, rowLength, startRowIndex, comInfo) => {
	return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
		workbook.sheets().forEach((sheet) => {
			sheet.usedRange().style({
				fontFamily: 'Times New Roman',
				// verticalAlignment: 'center',
				horizontalAlignment: 'left',
				wrapText: true
			});
			// company info
			if (comInfo.length) {
				sheet.range(`A${startRowIndex - comInfo.length}:U${startRowIndex - 1}`).style({
					fontSize: 13,
					bold: true
				});
			}
			// content table
			sheet.range(`A${startRowIndex + 1}:U${rowLength + 1}`).style({
				fontSize: 11,
				border: true
			});

			// set width for columns
			sheet.column('A').width(15);
			sheet.column('B').width(30);
			sheet.column('C').width(25);
			sheet.column('D').width(20);
			sheet.column('F').width(15);
			sheet.column('G').width(15);
			sheet.column('I').width(15);
			sheet.column('K').width(50);
			sheet.column('L').width(25);
			sheet.column('T').width(15);
			sheet.column('U').width(50);
			// header style
			sheet.range(`A${startRowIndex}:U${startRowIndex}`).style({
				fontSize: 14,
				bold: true,
				horizontalAlignment: 'center',
				verticalAlignment: 'center'
			});
			sheet.row(startRowIndex).height(30);
			sheet.range(`A${startRowIndex + 1}:U${startRowIndex + 1}`).style({
				fontSize: 12,
				fill: '82AEE8',
				bold: true,
				horizontalAlignment: 'center'
			});

			// summary
			sheet.range(`D${rowLength - 5}:U${rowLength + 1}`).style({
				border: false
			});
			sheet.range(`A${rowLength - 5}:D${rowLength + 1}`).style({
				fontSize: 12,
				bold: true,
				border: true
			});
			sheet.range(`A${rowLength - 5}:U${rowLength - 5}`).style({
				fontSize: 12,
				bold: true,
				border: true
			});
			sheet.range(`C${rowLength - 4}:C${rowLength - 4}`).style({
				numberFormat: '#,##0.00'
			});
			sheet.range(`C${rowLength - 3}:C${rowLength - 3}`).style({
				numberFormat: '#,##0'
			});
			sheet.range(`C${rowLength - 2}:C${rowLength - 2}`).style({
				numberFormat: '#,##0.00'
			});
			sheet.range(`C${rowLength - 1}:C${rowLength - 1}`).style({
				numberFormat: '#,##0'
			});
			sheet.range(`C${rowLength}:C${rowLength}`).style({
				numberFormat: '#,##0.00'
			});
			sheet.range(`C${rowLength + 1}:C${rowLength + 1}`).style({
				numberFormat: '#,##0'
			});
		});

		return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob));
	});
};
