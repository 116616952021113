import { Fragment, useContext, useState, useEffect } from 'react';
import { RoomTypeContext } from '../../../contexts/RoomTypeContext';
import { CruiseContext } from '../../../contexts/CruiseContext';
import {Modal, Button, Form} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const UpdateRoomTypeModal = () => {
    const { t } = useTranslation();
    // Context
    const {
        roomTypeState: {roomType},
        showUpdateRoomTypeModal,
        setShowUpdateRoomTypeModal,
        updateRoomType,
        setShowToast
    } = useContext(RoomTypeContext)

    const {
        getCruises
    } = useContext(CruiseContext)

    const [updatedRoomType, setUpdatedRoomType] = useState(roomType)
    useEffect( () => setUpdatedRoomType(roomType), [roomType] )
    const {
        id,
        name,
        description,
    } = updatedRoomType

    const closeDialog = () => {
        setShowUpdateRoomTypeModal(false)
    }
    const onChangeNewRoomForm = event => setUpdatedRoomType( {...updatedRoomType, [event.target.name]: event.target.value } )

    //updateRoomType
    const onSubmit = async event => {
        event.preventDefault()
        const response = await updateRoomType(updatedRoomType)
        if (response.status === 200) {
            closeDialog()
            setShowToast({show: true, message: t('Updated successful!'), type: 'success'})
            return
        }
        setShowToast({show: true, message: response.error && response.error.ErrorCode? response.error.ErrorCode: 'Update failed!', type: 'danger'})
    }
    // Start: Get all cruise , []
    useEffect( () => getCruises(), []) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <Fragment>
            <Modal show={showUpdateRoomTypeModal} onHide={closeDialog}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t('Update Room Type')}
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="">
                            <div className="col-sm">
                                <input type="hidden" name="id" value={id} />
                                <div className="form-group">
                                    <label>{t('Name')}</label>
                                    <input type="text" className="form-control" id="nameCruise" name="name" value={name} placeholder={t("Enter Room Name (No. 101)")} required aria-describedby='name-help' onChange={onChangeNewRoomForm} />
                                    <Form.Text id='name-help' muted>{t('Required')}</Form.Text>
                                </div>
                            </div>
                        <div className="">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>{t('Description')}</label>
                                    <textarea className="form-control" placeholder={t("Description")} rows="3" name='description' value={description} spellCheck="false" onChange={onChangeNewRoomForm}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> {t('Cancel')}</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> {t('Save')}!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default UpdateRoomTypeModal
