import { Fragment, useContext, useEffect } from "react";
import 'jquery';
import "datatables.net-dt/js/dataTables.dataTables.min"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { AuthContext } from '../../contexts/AuthContext';
import { PickupContext } from '../../contexts/PickupContext';
import DeleteModal from '../../components/layout/DeleteModal';
import UpdatePickupModal from './UpdatePickupModal';
import { Card, Spinner } from "react-bootstrap";

const ListPickup = () => {

    // Context
    const {authState: {user: {name} }} = useContext(AuthContext)

    const {
        pickupsState: {pickup, pickups, pickupsLoading},
        getPickups,
        findPickup,
        deletePickup,
        setShowUpdatePickupModal,
        setShowToast
    } = useContext(PickupContext)

    // Start: Get all cruise , []
    useEffect( () => getPickups(), []) // eslint-disable-line react-hooks/exhaustive-deps

    setTimeout(function(){ $('#list-pickup').DataTable(); }, 1000);

    const choosePickup = pickupId => {
        findPickup(pickupId)
        setShowUpdatePickupModal(true)
    }

    let body = null

    if(pickupsLoading){
        body = (
            <div className="spinner-container">
                <Spinner animation='border' variant='info'></Spinner>
            </div>
        )
    }else if(pickups === null || pickups.length === 0){
        body = (
            <Fragment>
                <Card className='text-center mx-5 my-5'>
                    <Card.Header as='h1'>Hi {name}</Card.Header>
                    <Card.Body>
                        <Card.Title>Welcome to Pickup Address Manage</Card.Title>
                        <Card.Text>
                            Click the button add pickup address to create your first pickup address
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Fragment>
        )
    }else{
        body = (
            <Fragment>
                <div className="ajax-booking-cruise">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="table-info-cruise mg-b-20">
                                <div className="table-info-cruise-body">
                                    <div className="table-responsive">
                                        <table id="list-pickup" className="table display">
                                            <thead>
                                                <tr>
                                                    <th>STT</th>
                                                    <th>Address</th>
                                                    <th className="w-220"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {pickups.map((pickup, index) => (
                                                <tr key={index}>
                                                    <td style={ {lineHeight: "38px"} }>{index + 1}</td>
                                                    <td style={ {lineHeight: "38px"} }>{pickup.address}</td>
                                                    <td>
                                                        <div className="row">
                                                            <div className="col-sm">
                                                                <button className="btn btn-success btn-with-icon" onClick={choosePickup.bind(this, pickup.id)}><i className="fe fe-edit"></i> Edit</button>
                                                            </div>
                                                            <div className="col-sm">
                                                                <DeleteModal idProps={pickup.id} deleteFunc={deletePickup} setShowToast={setShowToast} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {body}
            {pickup !== null && <UpdatePickupModal />}
        </Fragment>
    )
}

export default ListPickup
