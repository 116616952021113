import { createContext, useState, useReducer } from 'react';
import { roleReducer } from '../reducers/roleReducer';
import { apiUrl, ROLES_LOADED_SUCCESS, ROLES_LOADED_FAIL, ADD_ROLE, FIND_ROLE, UPDATE_ROLE, DELETE_ROLE } from './constants';
import axios from 'axios';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';

export const RoleContext = createContext()

const RoleContextProvider = ({ children }) => {

    // State
    const [roleState, dispatch] = useReducer(roleReducer, {
        role: null,
        roles: [],
        rolesLoading: true
    })

    const [showAddRoleModal, setShowAddRoleModal] = useState(false)
    const [showUpdateRoleModal, setShowUpdateRoleModal] = useState(false)
    const [showUpdateRolePermissionModal, setShowUpdateRolePermissionModal] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all Roles
    const getRoles = async() => {
        const response = await getAPI(`${apiUrl}/roles`)
        if (response.data) {
            dispatch({ type: ROLES_LOADED_SUCCESS, payload: response.data })
            return response
        }
        
        dispatch({ type: ROLES_LOADED_FAIL })
        return response
    }

    // Add Role
    const addRole = async newRole => {
        const response = await postAPI(`${apiUrl}/role/insert`, newRole)
        if (response.data) {
            dispatch({
                type: ADD_ROLE,
                payload: response.data
            })
        }
        return response
    }

    // Find Role when user is updating Role
    const findRole = roleId => {
        const role = roleState.roles.find(role => role.id === roleId)
        dispatch({
            type: FIND_ROLE,
            payload: role
        })
    }

    // set role state
    const setRole = role => {
        dispatch({
            type: FIND_ROLE,
            payload: role
        })
    }

    // update Role
    const updateRole = async updateRole => {
        const response = await postAPI(`${apiUrl}/role/update`, updateRole)
        if (response.status === 200) {
            dispatch({
                type: UPDATE_ROLE,
                payload: updateRole
            })
        }
        return response
    }

    // Delete Role
    const deleteRole = async roleId => {
        const response = await getDeleteAPI(`${apiUrl}/role/${roleId}/delete`)
        if (response.status === 200) {
            dispatch({
                type: DELETE_ROLE,
                payload: roleId
            })
        }
        
        return response
    }

    // Role Context Data
    const roleContextData = {
        roleState,
        getRoles,
        addRole,
        findRole,
        updateRole,
        deleteRole,
        showToast,
        setShowToast,
        showAddRoleModal,
        setShowAddRoleModal,
        showUpdateRoleModal,
        setShowUpdateRoleModal,
        showUpdateRolePermissionModal,
        setShowUpdateRolePermissionModal,
        setRole
    }

    return (
        <RoleContext.Provider value = { roleContextData } >
            { children }
        </RoleContext.Provider>
    )

}

export default RoleContextProvider