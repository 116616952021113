import { Fragment, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import NumberFormat from 'react-number-format';
import { BookingExpireContext } from '../../contexts/BookingExpireContext';
import { Card, Spinner } from 'react-bootstrap';
import { getTable, getTableOptions, getDefaultSort } from '../../utils/table';
import { Link } from 'react-router-dom';
import { CruiseContext } from '../../contexts/CruiseContext';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { diffDays, formatDateGMT7 } from '../../utils/common';
import { NO_PERMISSION, PERMISSIONS, ROLES } from '../../contexts/constants';

const ExpirePending = () => {
	const { t } = useTranslation();
	const {
		bookingExpireState: { bookingsPayment, bookingsPaymentLoading },
		setShowToast,
		getExpirePayment
	} = useContext(BookingExpireContext);
	const {
		authState: { user }
	} = useContext(AuthContext);

	const {
		cruiseState: { cruises }
	} = useContext(CruiseContext);

	const [cruiseOptions, setCruiseOptions] = useState([]);
	const [currentCruise, setCurrentCruise] = useState({});

	const dt = new Date();
	const month = ('0' + (dt.getMonth() + 1)).slice(-2);
	const year = dt.getFullYear();

	const daysInMonths = new Date(year, month, 0).getDate();
	const [fromDate, setFromDate] = useState(`${year}-${month}-01`);
	const [toDate, setToDate] = useState(`${year}-${month}-${daysInMonths}`);

	useEffect(() => {
		if (cruises && cruises.length > 0) {
			const cruiseOpts = cruises.map((cruise) => {
				return {
					label: cruise.name,
					value: cruise.id
				};
			});
			setCruiseOptions(cruiseOpts);
			setCurrentCruise(cruiseOpts[0]);
		}
	}, [cruises]);
	const handleCruiseChange = (cruise) => {
		setCurrentCruise(cruise);
	};
	useEffect(() => {
		if (!bookingsPayment || !bookingsPayment.length) getExpirePayment();
	}, []);
	const customStyles = {
		menuPortal: (provided) => ({ ...provided, zIndex: 5 })
	};

	if (bookingsPaymentLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info'></Spinner>
			</div>
		);
	}
	if (bookingsPayment === null || bookingsPayment.length === 0) {
		return (
			<Fragment>
				<Card className='text-center mx-5 my-5'>
					<Card.Header as='h1'>{t('Hi')} {user.name}</Card.Header>
					<Card.Body>
						<Card.Title>{t('Welcome to expired payment bookings Management')}</Card.Title>
					</Card.Body>
				</Card>
			</Fragment>
		);
	}
	const tableProps = {
		currentCruise,
		cruises,
		user,
		bookingsPayment,
		setShowToast,
		fromDate,
		toDate
	};

	const onChangeFrom = (date) => {
		setFromDate(date);
		if (diffDays(date, toDate) > 31) {
			const inputDate = new Date(date);
			const eDate = new Date(inputDate.setMonth(inputDate.getMonth()+1));
			const strEDate = formatDateGMT7(eDate, 'YYYY-MM-DD')
			setToDate(strEDate);
			return;
		}
	};
	const onChangeTo = (date) => {
		setToDate(date);
		if (diffDays(fromDate, date) > 31) {
			const inputDate = new Date(date);
			const fDate = new Date(inputDate.setMonth(inputDate.getMonth()-1));
			const strFDate = formatDateGMT7(fDate, 'YYYY-MM-DD')
			setFromDate(strFDate);
			return;
		}
	};
	const changeFrom = (event) => {
		var dateFrom = event.target.value;
		onChangeFrom(dateFrom);
	};

	const changeEnd = (event) => {
		var dateEnd = event.target.value;
		onChangeTo(dateEnd);
	};

	if (!user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR)
			&& !user.Permissions.includes(PERMISSIONS.VIEW_NOTIFICATION)) return NO_PERMISSION;
	return (
		<Fragment>
			<div className='horizontal-mainwrapper container clearfix'>
				<div className='explain-dashboard mt-5'>
					<div className='explain-header mb-3'>
						<div className='row'>
							<div className='col-md-4'>
								<h3 className='pull-left'>{t('Expired payment bookings')}</h3>
							</div>
							<div className='col-md-2 ml-auto'></div>
						</div>
						<hr />
						<div className=''>
							<h5>{t('Filter')}</h5>
						</div>
						<div className='row'>
							<div className='col-md-3'>
								<div className='form-group'>
									<label>{t('Cruise')}</label>

									<Select
										isClearable
										name='cruise_id'
										onChange={handleCruiseChange.bind(this)}
										options={cruiseOptions}
										placeholder={t('Choose Cruise')}
										value={currentCruise}
										menuPosition={'fixed'}
										styles={customStyles}
									/>
								</div>
							</div>
							<div className='col-md-3'>
								<div className='form-group'>
									<label>
										<strong>{t('From')}</strong>
									</label>
									<input
										value={fromDate}
										className='form-control'
										type='date'
										name='fromDate'
										onChange={changeFrom.bind(this)}
									/>
								</div>
							</div>
							<div className='col-md-3'>
								<div className='form-group'>
									<label>
										<strong>{t('To')}</strong>
									</label>
									<input
										value={toDate}
										className='form-control'
										type='date'
										name='endDate'
										onChange={changeEnd.bind(this)}
									/>
								</div>
							</div>
						</div>
					</div>
					{getBookingPending(tableProps, t)}
				</div>
			</div>
		</Fragment>
	);
};
const getBookingPending = (tableProps, t) => {
	if (!tableProps.bookingsPayment || tableProps.bookingsPayment.length === 0) return null;
	const tableData = getTableData(tableProps);
	const columns = getColumns(t);
	const defaultSorted = getDefaultSort([['index', 'asc']]);
	const options = getTableOptions(tableData.length, t);

	return getTable(tableData, columns, defaultSorted, options);
};
const getTableData = (tableProps) => {
	const { currentCruise, cruises, bookingsPayment, fromDate, toDate } = tableProps;
	return bookingsPayment
		.filter((item) => {
			const isValidDate = item.start_date >= fromDate && item.start_date <= toDate;
			return (currentCruise ? item.cruise_id === currentCruise.value : true) && isValidDate;
		})
		.map((bookingPayment, index) => {
			const nameCruise = cruises.find((cruise) => cruise.id === bookingPayment.cruise_id);
			return {
				index: index + 1,
				new_code: (
					<Link className='show-booking' to={`/bookings/show/${bookingPayment.id}`} target='_blank'>
						{bookingPayment.new_code}
					</Link>
				),
				booking_code: bookingPayment.booking_code,
				partner_name: bookingPayment.partner_name,
				start_date: bookingPayment.start_date,
				hold_date: bookingPayment.hold_date,
				hold_time: bookingPayment.hold_time,
				total: (
					<NumberFormat
						value={bookingPayment.total}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={0}
					/>
				),
				total_paid: (
					<NumberFormat
						value={bookingPayment.total_paid}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={0}
					/>
				),
				money_type: bookingPayment.money_type,
				cruise_name: nameCruise && nameCruise.name ? nameCruise.name : null
			};
		});
};
const getColumns = (t) => {
	return [
		{
			dataField: 'index',
			text: 'STT',
			sort: true
		},
		{
			dataField: 'new_code',
			text: 'Booking ID',
			sort: true
		},
		{
			dataField: 'booking_code',
			text: 'booking Code',
			sort: true,
			hidden: true
		},
		{
			dataField: 'partner_name',
			text: t('Partner name'),
			sort: true
		},
		{
			dataField: 'start_date',
			text: t('Start Date'),
			sort: true
		},
		{
			dataField: 'hold_date',
			text: t('Hold Date'),
			sort: true
		},
		{
			dataField: 'hold_time',
			text: t('Hold Time'),
			sort: true
		},
		{
			dataField: 'total',
			text: t('Total'),
			sort: true
		},
		{
			dataField: 'total_paid',
			text: t('Total Paid'),
			sort: true
		},
		{
			dataField: 'money_type',
			text: t('Money Type'),
			sort: true
		},
		{
			dataField: 'cruise_name',
			text: t('Cruise Name'),
			sort: true
		}
	];
};
export default ExpirePending;
