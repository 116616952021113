import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Form, Dropdown } from 'react-bootstrap';
import Select from 'react-select';
import { BOOKING_STATUSES, CRUISE_TYPES } from '../../../../contexts/constants';
import ItemCustomerTransportation from './ItemCustomerTransportation';
import { useTranslation } from 'react-i18next';
import { RoomContext } from '../../../../contexts/RoomContext';

const ItemInforCustomer = ({
	chooseDuration,
	assignTo,
	cruiseID,
	partnerID,
	start_date,
	checkDayCruise,
	customers,
	index,
	handleChangeRoom,
	optionRooms,
	optionSpecials,
	handleChangeMrCustoms,
	mrCustoms,
	onChangeCustomerText,
	onChangeLeader,
	handleChangeCountry,
	optionCountries,
	handleChangeGender,
	genders,
	optionPickups,
	onChangeCustomerNumber,
	setNewBooking,
	newBooking,
	bookingStatus,
	setSpecialService,
	defaultData,
	moneyType,
	setCustomerTransportAmount
}) => {
	const {
		roomState: { rooms },
		getRooms,
	} = useContext(RoomContext);

	if (!rooms && cruiseID) {
		getRooms(`?cruise_id=${cruiseID}`);
	}
	useEffect(() => {
		if (cruiseID) {
			getRooms(`?cruise_id=${cruiseID}`);
		}
	}, [cruiseID]); // eslint-disable-line react-hooks/exhaustive-deps

	const [allRoomOpts, setAllRoomOpts] = useState([]);

	useEffect(() => {
		if (rooms && rooms.length) {
			setAllRoomOpts(rooms.filter((r) => {
				return r.is_locked !== true;
			}).map((r) => {
				return {
					value: r.id,
					label: r.name,
					roomTypeID: r.room_type_id
				};
			}));
			setRoom('');
		}
	}, [rooms]);

	useEffect(() => {
		if (!customers[index]) {
			customers[index] = {};
		}
		customers[index]['cruise_id'] = cruiseID;
		customers[index]['assign_to'] = assignTo;
		customers[index]['start_date'] = start_date;
		customers[index]['partner_id'] = partnerID;
		customers[index]['cruise_itinerary_id'] = chooseDuration;
		if (index === 0) {
			customers[index]['leader'] = 1;
		}
	}, [index, start_date, partnerID, cruiseID, assignTo, chooseDuration]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleChangeSpecial = (newValue, actionMeta) => {
		let textMulti = '';
		const dataSpecial = newValue;
		var textName = actionMeta.name;
		var theNumber = textName.match(/\d+/)[0];
		if (!customers[theNumber]) {
			customers[theNumber] = {};
		}
		customers[theNumber]['specials'] = dataSpecial;
		const fieldNames = actionMeta.name.split('-');
		const fieldName = fieldNames[0];
		if (actionMeta.action === 'select-option') {
			if (dataSpecial.length > 0) {
				dataSpecial.forEach((data, index) => {
					if (index === 0) {
						textMulti = String(data.value);
					} else {
						textMulti = textMulti + ',' + String(data.value);
					}
				});
				customers[theNumber][fieldName] = textMulti;
				customers[theNumber]['start_date'] = start_date;
				customers[theNumber]['partner_id'] = partnerID;
			}
		} else if (actionMeta.action === 'remove-value') {
			customers[theNumber]['start_date'] = start_date;
			customers[theNumber]['partner_id'] = partnerID;
			if (dataSpecial.length > 0) {
				dataSpecial.forEach((data, index) => {
					if (index === 0) {
						textMulti = String(data.value);
					} else {
						textMulti = textMulti + ',' + String(data.value);
					}
				});
				customers[theNumber][fieldName] = textMulti;
			}
		}

		let specialTotal = 0;
		customers.forEach((customer) => {
			if (customer.specials && customer.specials.length > 0) {
				customer.specials.forEach((special) => {
					specialTotal += special.price;
				});
			}
		});
		setSpecialService(specialTotal);
	};

	const [room, setRoom] = useState('');
	const [roomSpecials, setRoomSpecials] = useState(optionSpecials);
	const changeRoom = (data, action) => {
		if (!data) return;
		setRoom(data);
		handleChangeRoom(data, action);
		setRSpecials(data);
	};

	const setRSpecials = (data) => {
		const currentRoomType = data.roomTypeID;
		if (!currentRoomType) {
			setRoomSpecials([]);
			return;
		}
		console.log('optionSpecials', optionSpecials);
		const rSpecials = optionSpecials.filter((s) => {
			const rTypes = s.roomTypes ? s.roomTypes.split(',') : [];
			if (rTypes.includes(currentRoomType.toString())) {
				return true;
			}
		});
		console.log('rSpecials', rSpecials);
		setRoomSpecials(rSpecials);
	};

	useEffect(() => {
		if (index > 0) return;
		if (!optionRooms || !defaultData) {
			setRoom('');
			return;
		}

		const dRoom = optionRooms.find((item) => {
			return item.value === defaultData.room_id;
		});
		if (!dRoom) {
			return;
		}

		setRoom(dRoom);
		handleChangeRoom(dRoom, {
			name: 'room_id-0',
			action: 'select-option'
		});
		setRSpecials(dRoom);
	}, [defaultData, optionRooms, optionSpecials]);
	useEffect(() => {
		if (bookingStatus === BOOKING_STATUSES.CHARTER && !room) {
			const roomIndex = Math.floor(index / 2);
			console.log(optionRooms[roomIndex]);
			const r = optionRooms[roomIndex] ? optionRooms[roomIndex] : '';
			setRoom(r);
			handleChangeRoom(r, {
				name: `room_id-${index}`,
				action: 'select-option'
			});
		}
	}, [bookingStatus, optionRooms]);

	const { t } = useTranslation();
	const colourStyles = {
		control: styles => ({ ...styles, backgroundColor: 'white' }),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			const isAvailableRoom = optionRooms.find((item) => {
				return item.value === data.value;
			});
			return {
			...styles,
			// backgroundColor: isBookedRoom ? 'red' : 'none',
			color: !isAvailableRoom ? 'red' : 'none'
			// cursor: isDisabled ? 'not-allowed' : 'default'
			};
		}
	  };
	return (
		<Fragment>
			<tr>
				{checkDayCruise === CRUISE_TYPES.NIGHT && (
					<>
						<td>
							<div className='select select-mobile'>
								<Select
									isClearable
									onChange={changeRoom.bind(this)}
									options={allRoomOpts}
									placeholder={t('Room')}
									name={`room_id-${index}`}
									value={room}
									styles={colourStyles}
								/>
							</div>
						</td>
					</>
				)}
				{checkDayCruise === CRUISE_TYPES.NIGHT && (
					<td>
						<div className='select select-mobile'>
							<Select
								isClearable
								onChange={handleChangeSpecial.bind(this)}
								options={roomSpecials}
								placeholder={t('Cabin')}
								isMulti={true}
								name={`cabin_type-${index}`}
							/>
						</div>
					</td>
				)}
				<td>
					<div className='select select-mobile'>
						<Select
							isClearable
							onChange={handleChangeMrCustoms.bind(this)}
							options={mrCustoms}
							placeholder={t('Title')}
							name={`title-${index}`}
						/>
					</div>
				</td>
				<td>
					<input
						type='text'
						className='form-control select-mobile'
						name={`full_name-${index}`}
						placeholder={t('Pax') + `  ${index + 1}`}
						onChange={onChangeCustomerText.bind(this, index)}
					/>
				</td>
				<td>
					{index === 0 ? (
						<Form.Check
							type='radio'
							name={`leader`}
							id='formHorizontalRadios1'
							defaultChecked='defaultChecked'
							onChange={onChangeLeader.bind(this, index)}
						/>
					) : (
						<Form.Check
							type='radio'
							name={`leader`}
							id='formHorizontalRadios1'
							onChange={onChangeLeader.bind(this, index)}
						/>
					)}
				</td>
				<td>
					<ItemCustomerTransportation
						currency={moneyType}
						customers={customers}
						customerIndex={index}
						setCustomerTransportAmount={setCustomerTransportAmount}
						start_date={start_date}
					/>
				</td>
				<td>
					<Dropdown className='info-customer'>
						<Dropdown.Toggle variant='success' id='dropdown-basic'>
							{t('More')} <i className='fe fe-chevron-down horizontal-icon'></i>
						</Dropdown.Toggle>

						<Dropdown.Menu className='content-button' id='content-button'>
							<div className='header-more'>
								<div className='form-group'>
									<div className='row'>
										<div className='col-sm-5'>
											<label>
												<strong>{t('PASSPORT NO') + '.'}</strong>
											</label>
										</div>
										<div className='col-sm-7'>
											<input
												type='text'
												className='form-control'
												name={`passport_number-${index}`}
												placeholder={t('Passport No' + '.')}
												onChange={onChangeCustomerText.bind(this, index)}
											/>
										</div>
									</div>
								</div>
								<div className='form-group'>
									<div className='row'>
										<div className='col-sm-5'>
											<label>
												<strong>{t('Email')}</strong>
											</label>
										</div>
										<div className='col-sm-7'>
											<input
												type='email'
												className='form-control'
												name={`email-${index}`}
												placeholder='abc@abc.com'
												onChange={onChangeCustomerText.bind(this, index)}
											/>
										</div>
									</div>
								</div>
								<div className='form-group'>
									<div className='row'>
										<div className='col-sm-5'>
											<label>
												<strong>{t('Country')}</strong>
											</label>
										</div>
										<div className='col-sm-7'>
											<div className='select select-mobile'>
												<Select
													isClearable
													name={`country-${index}`}
													onChange={handleChangeCountry.bind(this)}
													options={optionCountries}
													placeholder={t('Country')}
													className='select-country'
												/>
											</div>
										</div>
									</div>
								</div>
								<div className='form-group'>
									<div className='row'>
										<div className='col-sm-5'>
											<label>
												<strong>{t('Gender')}</strong>
											</label>
										</div>
										<div className='col-sm-7'>
											<Select
												isClearable
												name={`gender-${index}`}
												onChange={handleChangeGender.bind(this)}
												options={genders}
												placeholder={t('Gender')}
											/>
										</div>
									</div>
								</div>
								{/*<div className="form-group">
                                    <div className="row">
                                        <div className="col-sm-5">
                                            <label><strong>Pickup</strong></label>
                                        </div>
                                        <div className="col-sm-7">
                                            <Select isClearable
                                                name={`pickup_id-${index}`}
                                                onChange={handleChangePickup.bind(this)}
                                                options={optionPickups}
                                                placeholder="Pickup"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-sm-5">
                                            <label><strong>Drop Off</strong></label>
                                        </div>
                                        <div className="col-sm-7">
                                            <Select isClearable
                                                name={`dropoff_id-${index}`}
                                                onChange={handleChangeDropOff.bind(this)}
                                                options={optionPickups}
                                                placeholder="Drop Off"
                                            />
                                        </div>
                                    </div>
                                </div>*/}
								<div className='form-group'>
									<div className='row'>
										<div className='col-sm-5'>
											<label>
												<strong>{t('Age')}</strong>
											</label>
										</div>
										<div className='col-sm-7'>
											<input
												type='text'
												className='form-control'
												name={`age-${index}`}
												placeholder={t('Age')}
												onChange={onChangeCustomerNumber.bind(this, index)}
											/>
										</div>
									</div>
								</div>
								<div className='form-group'>
									<div className='row'>
										<div className='col-sm-5'>
											<label>
												<strong>{t('Birthday')}</strong>
											</label>
										</div>
										<div className='col-sm-7'>
											<input
												type='date'
												className='form-control'
												placeholder='mm/dd/yyyy'
												name={`birthday-${index}`}
												onChange={onChangeCustomerText.bind(this, index)}
											/>
										</div>
									</div>
								</div>
								<div className='form-group'>
									<div className='row'>
										<div className='col-sm-5'>
											<label>
												<strong>{t('Visa Expiration')}</strong>
											</label>
										</div>
										<div className='col-sm-7'>
											<input
												type='date'
												className='form-control'
												placeholder='mm/dd/yyyy'
												name={`passport_expired-${index}`}
												onChange={onChangeCustomerText.bind(this, index)}
											/>
										</div>
									</div>
								</div>
								<div className='form-group'>
									<div className='row'>
										<div className='col-sm-5'>
											<label>
												<strong>{t('Phone')}</strong>
											</label>
										</div>
										<div className='col-sm-7'>
											<input
												type='tel'
												className='form-control'
												placeholder={t('Phone Number')}
												name={`phone-${index}`}
												onChange={onChangeCustomerText.bind(this, index)}
											/>
										</div>
									</div>
								</div>
								<div className='form-group'>
									<div className='row'>
										<div className='col-sm-5'>
											<label>
												<strong>{t('Notes')}</strong>
											</label>
										</div>
										<div className='col-sm-7'>
											<textarea
												rows='3'
												className='form-control'
												placeholder={t('Notes')}
												name={`note-${index}`}
												onChange={onChangeCustomerText.bind(this, index)}
											></textarea>
										</div>
									</div>
								</div>
							</div>
						</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>
		</Fragment>
	);
};

export default ItemInforCustomer;
