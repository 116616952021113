import { SPECIAL_CABINS_LOADED_SUCCESS, SPECIAL_CABINS_LOADED_FAIL, ADD_SPECIAL_CABIN, FIND_SPECIAL_CABIN, UPDATE_SPECIAL_CABIN, DELETE_SPECIAL_CABIN } from '../contexts/constants';

export const specialReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case SPECIAL_CABINS_LOADED_SUCCESS:
            return {
                ...state,
                specials: payload,
                specialsLoading: false
            }
        case SPECIAL_CABINS_LOADED_FAIL:
            return {
                ...state,
                specials: [],
                specialsLoading: false
            }
        case ADD_SPECIAL_CABIN:
            {
                return {
                    ...state,
                    specials: [...state.specials, payload],
                }
            }
        case FIND_SPECIAL_CABIN:
            return {
                ...state,
                special: payload
            }
        case UPDATE_SPECIAL_CABIN:
            const newSpecial = state.specials.map(special => {
                if (special.id === payload.id) {
                    return payload
                } else {
                    return special
                }
            })
            return {
                ...state,
                specials: newSpecial
            }
        case DELETE_SPECIAL_CABIN:
            return {
                ...state,
                specials: state.specials.filter(special => special.id !== payload)
            }
        default:
            return state
    }
}