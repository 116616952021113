import { Fragment, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CruiseContext } from '../../../contexts/CruiseContext';
import { AuthContext } from '../../../contexts/AuthContext';
import jQuery from 'jquery';
import { useTranslation } from 'react-i18next';

const MainMenu = () => {

    const {authState: { user }} = useContext(AuthContext)
    const {
        cruiseState: { cruises },
        getCruises
    } = useContext(CruiseContext)

    const dt = new Date();
    const month = ("0" + (dt.getMonth() + 1)).slice(-2); //("0" + 3).slice(-2); //("0" + (dt.getMonth() + 1)).slice(-2);
    const year = dt.getFullYear(); //2019 //dt.getFullYear();

    useEffect( () => getCruises(), []) // eslint-disable-line react-hooks/exhaustive-deps
    const subMenuOnClick = (event) => {
        jQuery(event.target).toggleClass('horizontal-active menu-openarrow').parent().siblings().children().removeClass('horizontal-active menu-openarrow');
        jQuery(".horizontalMenu > .horizontalMenu-list > li > .sub-menu, .horizontal-megamenu").not(jQuery(this).siblings('.horizontalMenu > .horizontalMenu-list > li > .sub-menu, .horizontal-megamenu')).slideUp('slow');
        jQuery(".horizontal-active").siblings('.sub-menu').slideToggle('slow');
        jQuery(this).siblings('.horizontal-megamenu').slideToggle('slow');
    }

    const { t } = useTranslation();
    const location = useLocation();

    return (
        <Fragment>
            <div className="sticky">
                <div className="horizontal-main hor-menu clearfix side-header">
                    <div className="horizontal-mainwrapper container clearfix">
                        <nav className="horizontalMenu clearfix">
                            <ul className="horizontalMenu-list">
                                <li><Link to="/dashboard" title="Dashboard" className={location.pathname.includes('dashboard')? 'active': ''}><svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" viewBox="0 0 24 24" ><path d="M0 0h24v24H0V0z" fill="none"/><path d="M5 5h4v6H5zm10 8h4v6h-4zM5 17h4v2H5zM15 5h4v2h-4z" opacity=".3"/><path d="M3 13h8V3H3v10zm2-8h4v6H5V5zm8 16h8V11h-8v10zm2-8h4v6h-4v-6zM13 3v6h8V3h-8zm6 4h-4V5h4v2zM3 21h8v-6H3v6zm2-4h4v2H5v-2z"/></svg>{t('Dashboard')}</Link></li>

                                <li><span className="horizontalMenu-click" onClick={subMenuOnClick.bind(this)}><i className="horizontalMenu-arrow fa fa-angle-down"></i></span><a href="#" className={location.pathname.includes('bookings')? 'active sub-icon': 'sub-icon'}><svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M10.9 19.91c.36.05.72.09 1.1.09 2.18 0 4.16-.88 5.61-2.3L14.89 13l-3.99 6.91zm-1.04-.21l2.71-4.7H4.59c.93 2.28 2.87 4.03 5.27 4.7zM8.54 12L5.7 7.09C4.64 8.45 4 10.15 4 12c0 .69.1 1.36.26 2h5.43l-1.15-2zm9.76 4.91C19.36 15.55 20 13.85 20 12c0-.69-.1-1.36-.26-2h-5.43l3.99 6.91zM13.73 9h5.68c-.93-2.28-2.88-4.04-5.28-4.7L11.42 9h2.31zm-3.46 0l2.83-4.92C12.74 4.03 12.37 4 12 4c-2.18 0-4.16.88-5.6 2.3L9.12 11l1.15-2z" opacity=".3"></path><path d="M12 22c5.52 0 10-4.48 10-10 0-4.75-3.31-8.72-7.75-9.74l-.08-.04-.01.02C13.46 2.09 12.74 2 12 2 6.48 2 2 6.48 2 12s4.48 10 10 10zm0-2c-.38 0-.74-.04-1.1-.09L14.89 13l2.72 4.7C16.16 19.12 14.18 20 12 20zm8-8c0 1.85-.64 3.55-1.7 4.91l-4-6.91h5.43c.17.64.27 1.31.27 2zm-.59-3h-7.99l2.71-4.7c2.4.66 4.35 2.42 5.28 4.7zM12 4c.37 0 .74.03 1.1.08L10.27 9l-1.15 2L6.4 6.3C7.84 4.88 9.82 4 12 4zm-8 8c0-1.85.64-3.55 1.7-4.91L8.54 12l1.15 2H4.26C4.1 13.36 4 12.69 4 12zm6.27 3h2.3l-2.71 4.7c-2.4-.67-4.35-2.42-5.28-4.7h5.69z"></path></svg> Booking <i className="fe fe-chevron-down horizontal-icon"></i></a>
                                    <ul className="sub-menu">
                                        {cruises ? cruises.map((cruise, index) => (
                                            <li key={index}><Link to={`/bookings/${month}-${year}-${cruise.id}`} className={location.pathname === `/bookings/${month}-${year}-${cruise.id}`? 'active slide-item': 'slide-item'}>{cruise.name}</Link></li>
                                        )) : null}

                                        <li className='dropdown' ><Link to="/bookings/notifications/expire-pending" className={location.pathname.includes('notifications')? 'active slide-item': 'slide-item'} title="notification">{t('Notifications')}</Link>
                                            <ul className='sub-menu dropdown-content' >
                                                <li><Link to="/bookings/notifications/expire-pending" className={location.pathname === '/bookings/notifications/expire-pending'? 'active slide-item': 'slide-item'} title="pending">{t('Pending Expired')}</Link></li>
                                                <li><Link to="/bookings/notifications/expire-payment" className={location.pathname === '/bookings/notifications/expire-payment'? 'active slide-item': 'slide-item'} title="payment">{t('Payment Expired')}</Link></li>
                                                <li><Link to="/bookings/notifications/expire-deposit" className={location.pathname === '/bookings/notifications/expire-deposit'? 'active slide-item': 'slide-item'} title="deposit">{t('Deposit Expired')}</Link></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>

                                <li><span className="horizontalMenu-click" onClick={subMenuOnClick.bind(this)}><i className="horizontalMenu-arrow fa fa-angle-down"></i></span><a href="#" className={location.pathname.includes('manage')? 'active sub-icon': 'sub-icon'}><svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M6.26 9L12 13.47 17.74 9 12 4.53z" opacity=".3"/><path d="M19.37 12.8l-7.38 5.74-7.37-5.73L3 14.07l9 7 9-7zM12 2L3 9l1.63 1.27L12 16l7.36-5.73L21 9l-9-7zm0 11.47L6.26 9 12 4.53 17.74 9 12 13.47z"/></svg> {t('Management')} <i className="fe fe-chevron-down horizontal-icon"></i></a>
                                    <ul className="sub-menu">
                                        <li><Link to="/manage/cruises" className={location.pathname === '/manage/cruises'? 'active slide-item': 'slide-item'} title="Management Cruises">{t('Cruises')}</Link></li>
                                        <li><Link to="/manage/cruise-setting" className={location.pathname === '/manage/cruise-setting'? 'active slide-item': 'slide-item'} title="Setting Cruises">{t('Setting Cruises')}</Link></li>
                                        <li><Link to="/manage/itineraries" className={location.pathname === '/manage/itineraries'? 'active slide-item': 'slide-item'} title="Management Itineraries">{t('Itineraries')}</Link></li>
                                        <li><Link to="/manage/special-cabins" className={location.pathname === '/manage/special-cabins'? 'active slide-item': 'slide-item'} title="Management Services of Cabin">{t('Cabin Services')}</Link></li>
                                        <li><Link to="/manage/rooms" className={location.pathname === '/manage/rooms'? 'active slide-item': 'slide-item'} title="Management Rooms">{t('Rooms')}</Link></li>
                                        <li><Link to="/manage/room-types" className={location.pathname === '/manage/room-types'? 'active slide-item': 'slide-item'} title="Room Type">{t('Room Types')}</Link></li>
                                        <li><Link to="/manage/prices" className={location.pathname === '/manage/prices'? 'active slide-item': 'slide-item'} title="Management Prices">{t('Prices')}</Link></li>
                                        <li><Link to="/manage/partners" className={location.pathname === '/manage/partners'? 'active slide-item': 'slide-item'} title="Management Partner">{t('Partners')}</Link></li>
                                        <li><Link to="/manage/guides" className={location.pathname === '/manage/guides'? 'active slide-item': 'slide-item'} title="Management Guide &amp; Driver">{t('Guide & Driver')}</Link></li>
                                        <li><Link to="/manage/countries" className={location.pathname === '/manage/countries'? 'active slide-item': 'slide-item'} title="Management Countries">{t('Countries')}</Link></li>
                                        <li className='dropdown' ><Link to="/manage/transportation-routes" className={location.pathname.includes('transportation')? 'active slide-item': 'slide-item'} title="Management Transportations">{t('Transportations')}</Link>
                                            <ul className='sub-menu dropdown-content' >
                                                <li><Link to="/manage/transportation-pickups" className={location.pathname === '/manage/transportation-pickups'? 'active slide-item': 'slide-item'} title="Pick up">{t('Pick up')}</Link></li>
                                                <li><Link to="/manage/transportation-types" className={location.pathname === '/manage/transportation-types'? 'active slide-item': 'slide-item'} title="Transportation Type">{t('Transportation Type')}</Link></li>
                                                <li><Link to="/manage/transportation-routes" className={location.pathname === '/manage/transportation-routes'? 'active slide-item': 'slide-item'} title="Transportation Route">{t('Transportation Route')}</Link></li>
                                                <li><Link to="/manage/cars" className={location.pathname === '/manage/cars'? 'active slide-item': 'slide-item'} title="Management Cars">{t('Cars')}</Link></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li><span className="horizontalMenu-click" onClick={subMenuOnClick.bind(this)}><i className="horizontalMenu-arrow fa fa-angle-down"></i></span><a href="#" className={location.pathname.includes('accounting')? 'active sub-icon': 'sub-icon'}><svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" viewBox="0 0 24 24" ><path d="M0 0h24v24H0z" fill="none"/><path d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8c.28 0 .5-.22.5-.5 0-.16-.08-.28-.14-.35-.41-.46-.63-1.05-.63-1.65 0-1.38 1.12-2.5 2.5-2.5H16c2.21 0 4-1.79 4-4 0-3.86-3.59-7-8-7zm-5.5 9c-.83 0-1.5-.67-1.5-1.5S5.67 10 6.5 10s1.5.67 1.5 1.5S7.33 13 6.5 13zm3-4C8.67 9 8 8.33 8 7.5S8.67 6 9.5 6s1.5.67 1.5 1.5S10.33 9 9.5 9zm5 0c-.83 0-1.5-.67-1.5-1.5S13.67 6 14.5 6s1.5.67 1.5 1.5S15.33 9 14.5 9zm4.5 2.5c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5.67-1.5 1.5-1.5 1.5.67 1.5 1.5z" opacity=".3"/><path d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10c1.38 0 2.5-1.12 2.5-2.5 0-.61-.23-1.21-.64-1.67-.08-.09-.13-.21-.13-.33 0-.28.22-.5.5-.5H16c3.31 0 6-2.69 6-6 0-4.96-4.49-9-10-9zm4 13h-1.77c-1.38 0-2.5 1.12-2.5 2.5 0 .61.22 1.19.63 1.65.06.07.14.19.14.35 0 .28-.22.5-.5.5-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.14 8 7c0 2.21-1.79 4-4 4z"/><circle cx="6.5" cy="11.5" r="1.5"/><circle cx="9.5" cy="7.5" r="1.5"/><circle cx="14.5" cy="7.5" r="1.5"/><circle cx="17.5" cy="11.5" r="1.5"/></svg> {t('Accountants')} <i className="fe fe-chevron-down horizontal-icon"></i></a>
                                    <ul className="sub-menu">
                                        <li ><Link to="/accounting/revenues" className={location.pathname === '/accounting/revenues'? 'active slide-item': 'slide-item'} title="Management Revenues"> {t('Revenue')}</Link></li>
                                        <li ><Link to="/accounting/revenue-statistic" className={location.pathname === '/accounting/revenue-statistic'? 'active slide-item': 'slide-item'} title="Revenue statistic"> {t('Revenue Statistic')}</Link></li>
                                        {cruises ? cruises.map((cruise, index) => (
                                            <li key={index}><Link to={`/accounting/debts/${month}-${year}-${cruise.id}`} className={location.pathname === `/accounting/debts/${month}-${year}-${cruise.id}`? 'active slide-item': 'slide-item'} >{t('Debts of Agents')} - {cruise.name}</Link></li>
                                        )) : null}
                                        <li ><Link to={`/accounting/top-account/${month}-${year}`} className={location.pathname.includes('top-account')? 'active slide-item': 'slide-item'}>{t('Top Account')}</Link></li>
                                    </ul>
                                </li>
                                <li ><span className="horizontalMenu-click" onClick={subMenuOnClick.bind(this)}><i className="horizontalMenu-arrow fa fa-angle-down"></i></span><a href="#" className={location.pathname.includes('report')? 'active sub-icon': 'sub-icon'}><svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" className="side-menu__icon" viewBox="0 0 24 24" ><g><rect fill="none"/></g><g><g/><g><path d="M21,5c-1.11-0.35-2.33-0.5-3.5-0.5c-1.95,0-4.05,0.4-5.5,1.5c-1.45-1.1-3.55-1.5-5.5-1.5S2.45,4.9,1,6v14.65 c0,0.25,0.25,0.5,0.5,0.5c0.1,0,0.15-0.05,0.25-0.05C3.1,20.45,5.05,20,6.5,20c1.95,0,4.05,0.4,5.5,1.5c1.35-0.85,3.8-1.5,5.5-1.5 c1.65,0,3.35,0.3,4.75,1.05c0.1,0.05,0.15,0.05,0.25,0.05c0.25,0,0.5-0.25,0.5-0.5V6C22.4,5.55,21.75,5.25,21,5z M3,18.5V7 c1.1-0.35,2.3-0.5,3.5-0.5c1.34,0,3.13,0.41,4.5,0.99v11.5C9.63,18.41,7.84,18,6.5,18C5.3,18,4.1,18.15,3,18.5z M21,18.5 c-1.1-0.35-2.3-0.5-3.5-0.5c-1.34,0-3.13,0.41-4.5,0.99V7.49c1.37-0.59,3.16-0.99,4.5-0.99c1.2,0,2.4,0.15,3.5,0.5V18.5z"/><path d="M11,7.49C9.63,6.91,7.84,6.5,6.5,6.5C5.3,6.5,4.1,6.65,3,7v11.5C4.1,18.15,5.3,18,6.5,18 c1.34,0,3.13,0.41,4.5,0.99V7.49z" opacity=".3"/></g><g><path d="M17.5,10.5c0.88,0,1.73,0.09,2.5,0.26V9.24C19.21,9.09,18.36,9,17.5,9c-1.28,0-2.46,0.16-3.5,0.47v1.57 C14.99,10.69,16.18,10.5,17.5,10.5z"/><path d="M17.5,13.16c0.88,0,1.73,0.09,2.5,0.26V11.9c-0.79-0.15-1.64-0.24-2.5-0.24c-1.28,0-2.46,0.16-3.5,0.47v1.57 C14.99,13.36,16.18,13.16,17.5,13.16z"/><path d="M17.5,15.83c0.88,0,1.73,0.09,2.5,0.26v-1.52c-0.79-0.15-1.64-0.24-2.5-0.24c-1.28,0-2.46,0.16-3.5,0.47v1.57 C14.99,16.02,16.18,15.83,17.5,15.83z"/></g></g></svg>{t('Reports')}<i className="fe fe-chevron-down horizontal-icon"></i></a>
                                    <ul className="sub-menu">
                                        {cruises ? cruises.map((cruise, index) => (
                                            <li key={index}><Link to={`/report/report-sales/${month}-${year}-${cruise.id}`} className={location.pathname === `/report/report-sales/${month}-${year}-${cruise.id}`? 'active slide-item': 'slide-item'} >{t('Report')} - {cruise.name}</Link></li>
                                        )) : null}
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default MainMenu
