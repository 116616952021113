import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const RevenueDate = props => {
    const { t } = useTranslation();
    const fromDate = props.fromDateProps
    const endDate = props.endDateProps
    const onChangeFrom = props.onChangeFromProps
    const onChangeEnd = props.onChangeEndProps

    //let dataNewBooking = []

    const changeFrom = (event) => {
        var dateFrom = event.target.value
        onChangeFrom(dateFrom)
    }

    const changeEnd = (event) => {
        var dateEnd = event.target.value
        onChangeEnd(dateEnd)
    }

    return (
        <Fragment>
            <div className="col-sm">
                <div className="form-group">
                    <label><strong>{t('From')}</strong></label>
                    <input className="form-control" type="date" value={fromDate} name='fromDate' onChange={changeFrom.bind(this)} />
                </div>
            </div>
            <div className="col-sm">
                <div className="form-group">
                    <label><strong>{t('To')}</strong></label>
                    <input className="form-control" type="date" value={endDate} name='endDate' onChange={changeEnd.bind(this)} />
                </div>
            </div>
        </Fragment>
    )
}

export default RevenueDate
