import { Fragment, useContext, useEffect, useState } from "react";
import { AuthContext } from '../../contexts/AuthContext';
import { PermissionRoleContext } from '../../contexts/PermissionRoleContext';
import { RoleContext } from '../../contexts/RoleContext';
import { Card, Spinner } from "react-bootstrap";
import DeleteModal from "../../components/layout/DeleteModal";
import UpdatePermissionRole from "./UpdatePermissionRole";

const ListPermissionRole = () => {

    // Context
    const {authState: {user: {name} }} = useContext(AuthContext)

    const {
        permissionRoleState: { permissionRole, permissionRoles, permissionRolesLoading },
        getPermissionRoles,
        //findPermissionRole,
        //deletePermissionRole,
        //setShowUpdatePermissionRoleModal
    } = useContext(PermissionRoleContext)

    const {
        roleState: { role, roles },
        getRoles,
        setShowUpdateRolePermissionModal
    } = useContext(RoleContext)

    // Start: Get all specials , []
    useEffect( () => getPermissionRoles(), []) // eslint-disable-line react-hooks/exhaustive-deps

    // Start: Get all specials , []
    useEffect( () => getRoles(), []) // eslint-disable-line react-hooks/exhaustive-deps

    const [editRoleID, setEditRoleID] = useState({});
    const choosePermissionRole = roleID => {
        setEditRoleID(roleID)

        setShowUpdateRolePermissionModal(true)
    }
    let body = null

    if(permissionRolesLoading){
        body = (
            <div className="spinner-container">
                <Spinner animation='border' variant='info'></Spinner>
            </div>
        )
    }else{
        body = (
            <div className="ajax-booking-cruise mt-3">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="table-info-cruise mg-b-20">
                            <div className="table-info-cruise-body">
                                <div className="table-responsive">
                                    <table id="list-pickup" className="table display">
                                        <thead>
                                            <tr>
                                                <th>RoleID</th>
                                                <th>Role Name</th>
                                                <th className="w-220"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {roles.map((role, index) => (
                                                <tr key={index}>
                                                    <td>{role.id}</td>
                                                    <td>{role.display_name}</td>
                                                    <td>
                                                        <div className="row">
                                                            <div className="col-sm">
                                                                <button className="btn btn-success btn-with-icon" onClick={choosePermissionRole.bind(this, role.id)}><i className="fe fe-edit"></i> Set permissions</button>
                                                            </div>
                                                            {/* <div className="col-sm">
                                                                <DeleteModal idProps={permission.id} deleteFunc={deleteRole} />
                                                            </div> */}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            {body}
            {editRoleID !== null && <UpdatePermissionRole editRoleID={editRoleID} setEditRoleID={setEditRoleID} roles={roles} permissionRoles={permissionRoles} />}
        </Fragment>
    )
}

export default ListPermissionRole
