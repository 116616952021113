import { ROLES_LOADED_SUCCESS, ROLES_LOADED_FAIL, ADD_ROLE, FIND_ROLE, UPDATE_ROLE, DELETE_ROLE } from '../contexts/constants';

export const roleReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case ROLES_LOADED_SUCCESS:
            return {
                ...state,
                roles: payload,
                rolesLoading: false
            }
        case ROLES_LOADED_FAIL:
            return {
                ...state,
                roles: [],
                rolesLoading: false
            }
        case ADD_ROLE:
            {
                return {
                    ...state,
                    roles: [...state.roles, payload],
                }
            }
        case FIND_ROLE:
            return {
                ...state,
                role: payload
            }
        case UPDATE_ROLE:
            const newRole = state.roles.map(role => {
                if (role.id === payload.id) {
                    return payload
                } else {
                    return role
                }
            })
            return {
                ...state,
                roles: newRole
            }
        case DELETE_ROLE:
            return {
                ...state,
                roles: state.roles.filter(role => role.id !== payload)
            }
        default:
            return state
    }
}