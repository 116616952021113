import { Fragment, useContext, useState } from "react";
import { RoleContext } from '../../contexts/RoleContext';
import { Button, Form, Modal } from "react-bootstrap";

const AddRole = () => {

    const { 
        showAddRoleModal, 
        setShowAddRoleModal, 
        addRole, 
        setShowToast
    } = useContext(RoleContext)

    // State
    const [newRole, setNewRole] = useState({
        name: '',
        display_name: '',
        description: ''
    })

    const { name, display_name, description } = newRole

    const onChange = event => setNewRole( {...newRole, [event.target.name]: event.target.value } )

    const onSubmit = async event => {
        event.preventDefault()
        const response = await addRole(newRole)
        if (response.data) {
            closeDialog()
            setShowToast({show: true, message: 'Added sucessful!', type: 'success'})
            return
        }
        setShowToast({show: true, message: response.error && response.error.ErrorCode? response.error.ErrorCode: 'Add failed!', type: 'danger'})
    }    

    const closeDialog = () => {
        setNewRole({
            name: '',
            display_name: '',
            description: ''
        })
        setShowAddRoleModal(false)
    }

    return (
        <Fragment>
            <Modal show={showAddRoleModal} onHide={closeDialog}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Create New Role
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="form-group">
                            <label>Name</label>
                            <input className="form-control" placeholder="Enter Name Permission" name='name' value={name} onChange={onChange} required aria-describedby='address-help' />
                            <Form.Text id='address-help' muted>Required</Form.Text>
                        </div>
                        <div className="form-group">
                            <label>Display Name</label>
                            <input className="form-control" placeholder="Display Name Permission" name='display_name' value={display_name} onChange={onChange} required aria-describedby='display-help' />
                            <Form.Text id='display-help' muted>Required</Form.Text>
                        </div>
                        <div className="form-group">
                            <label>Description</label>
                            <textarea className="form-control" placeholder="Description" rows="3" name='description' defaultValue={description} spellCheck="false" onChange={onChange}></textarea>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default AddRole
