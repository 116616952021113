import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './assets/css/icons.css';
import './assets/css/style.css';
import './assets/css/animate.css';
import './App.css';
import Landing from './components/layout/Landing';
import Auth from './views/Auth';
import Profile from './views/users/Profile';
import User from './views/users/User';
import Dashboard from './views/Dashboard';
import Booking from './views/bookings/Booking';
import ExpirePending from './views/notification/ExpirePending';
import ExpirePayment from './views/notification/ExpirePayment';
import ExpireDeposit from './views/notification/ExpireDeposit';
import Show from './views/bookings/Show';
import About from './views/About';
import Partner from './views/partners/Partner';
import PartnerDetail from './views/partners/PartnerDetail';
import Cruise from './views/cruises/Cruise';
import Room from './views/rooms/Room';
import RoomType from './views/roomType/RoomType';
import Guide from './views/guides/Guide';
import Service from './views/services/Service';
import Country from './views/countries/Country';
import Setting from './views/settings/Setting';
import Operating from './views/operating/Operating';
import Command from './views/command/Command';
import Customer from './views/customers/Customer';
import Revenue from './views/accountants/Revenue';
import RevenueStatistic from './views/accountants/RevenueStatistic';
import Debt from './views/debts/Debt';
import TopAccount from './views/topAccount/TopAccount';
import ReportSales from './views/reports/ReportSales';
import Car from './views/cars/Car';
import Pickup from './views/pickup/Pickup';
import Price from './views/prices/Price';
import Transportation from './views/transportations/Transportation';
import NoTransportation from './views/customers/NoTransportation';
import SpecialCabin from './views/specialCabins/SpecialCabin';
import TransportationPickUp from './views/transportationPickUp/TransportationPickUp';
import TransportationType from './views/transportationType/TransportationType';
import TransportationRoute from './views/transportationRoute/TransportationRoute';
import Itinerary from './views/itineraries/Itinerary';
import Role from './views/roles/Role';
import PermissionRole from './views/permissionRoles/PermissionRole';
import CruiseSetting from './views/cruiseSettings/CruiseSettings';
import UserRole from './views/userRoles/UserRole';
//import TestDrag from './views/TestDrag';
import OnboardService from './views/onboard/OnboardService';
import OnboardBooking from './views/onboard/OnboardBooking';

import AuthContextProvider from './contexts/AuthContext';
import ProtectedRoute from './components/routing/ProtectedRoute';
import PartnerContextProvider from './contexts/PartnerContext';
//import PostContextProvider from './contexts/PostContext';
import BookingContextProvider from './contexts/BookingContext';
import BookingExpireContextProvider from './contexts/BookingExpireContext';
import CruiseContextProvider from './contexts/CruiseContext';
import RoomContextProvider from './contexts/RoomContext';
import RoomTypeContextProvider from './contexts/RoomTypeContext';
import GuideContextProvider from './contexts/GuideContext';
import ServiceContextProvider from './contexts/ServiceContext';
import CountryContextProvider from './contexts/CountryContext';
import SettingContextProvider from './contexts/SettingContext';
import PriceContextProvider from './contexts/PriceContext';
import CarContextProvider from './contexts/CarContext';
import PickupContextProvider from './contexts/PickupContext';
import TransportationContextProvider from './contexts/TransportationContext';
import SpecialContextProvider from './contexts/SpecialContext';
import TransportationPickUpContextProvider from './contexts/TransportationPickUpContext';
import TransportationTypeContextProvider from './contexts/TransportationTypeContext';
import ItineraryContextProvider from './contexts/ItineraryContext';
import PaymentContextProvider from './contexts/PaymentContext';
import RoleContextProvider from './contexts/RoleContext';
import PermissionContextProvider from './contexts/PermissionContext';
import CruiseSettingContextProvider from './contexts/CruiseSettingContext';
import PermissionRoleContextProvider from './contexts/PermissionRoleContext';
import UserRoleContextProvider from './contexts/UserRoleContext';
import OnboardServiceContextProvider from './contexts/OnboardServiceContext';
import OnboardBookingContextProvider from './contexts/OnboardBookingContext';
import TransportationRouteContextProvider from './contexts/TransportationRouteContext';
import CustomerTransportContextProvider from './contexts/CustomerTransportContext';

function App() {
	return (
		<AuthContextProvider>
			<SettingContextProvider>
				<CruiseContextProvider>
					<BookingContextProvider>
						<BookingExpireContextProvider>
							<RoomContextProvider>
								<RoomTypeContextProvider>
									<GuideContextProvider>
										<PartnerContextProvider>
											<ServiceContextProvider>
												<CountryContextProvider>
													<PriceContextProvider>
														<CarContextProvider>
															<PickupContextProvider>
																<TransportationContextProvider>
																	<SpecialContextProvider>
																		<TransportationPickUpContextProvider>
																			<TransportationTypeContextProvider>
																				<ItineraryContextProvider>
																					<PaymentContextProvider>
																						<RoleContextProvider>
																							<PermissionContextProvider>
																								<CruiseSettingContextProvider>
																									<PermissionRoleContextProvider>
																										<UserRoleContextProvider>
																											<OnboardServiceContextProvider>
																												<OnboardBookingContextProvider>
																													<TransportationRouteContextProvider>
																														<CustomerTransportContextProvider>
																															<Router>
																																<Switch>
																																	<Route
																																		exact
																																		path='/'
																																		component={
																																			Landing
																																		}
																																	/>
																																	<Route
																																		exact
																																		path='/login'
																																		render={(
																																			props
																																		) => (
																																			<Auth
																																				{...props}
																																				authRoute='login'
																																			/>
																																		)}
																																	/>
																																	<Route
																																		exact
																																		path='/register'
																																		render={(
																																			props
																																		) => (
																																			<Auth
																																				{...props}
																																				authRoute='register'
																																			/>
																																		)}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/dashboard'
																																		component={
																																			Dashboard
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/bookings/:id'
																																		component={
																																			Booking
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/bookings/notifications/expire-pending'
																																		component={
																																			ExpirePending
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/bookings/notifications/expire-payment'
																																		component={
																																			ExpirePayment
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/bookings/notifications/expire-deposit'
																																		component={
																																			ExpireDeposit
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/bookings/show/:id'
																																		component={
																																			Show
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/manage/cruises'
																																		component={
																																			Cruise
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/manage/rooms'
																																		component={
																																			Room
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/manage/room-types'
																																		component={
																																			RoomType
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/about'
																																		component={
																																			About
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/manage/partners'
																																		component={
																																			Partner
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/manage/partners/:id'
																																		component={
																																			PartnerDetail
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/manage/guides'
																																		component={
																																			Guide
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/manage/services'
																																		component={
																																			Service
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/profile'
																																		component={
																																			Profile
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/users'
																																		component={
																																			User
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/manage/countries'
																																		component={
																																			Country
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/settings'
																																		component={
																																			Setting
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/operating/:date'
																																		component={
																																			Operating
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/command/:date'
																																		component={
																																			Command
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/customers/:date'
																																		component={
																																			Customer
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/accounting/revenues'
																																		component={
																																			Revenue
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/accounting/revenue-statistic'
																																		component={
																																			RevenueStatistic
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/accounting/debts/:date'
																																		component={
																																			Debt
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/accounting/top-account/:date'
																																		component={
																																			TopAccount
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/report/report-sales/:date'
																																		component={
																																			ReportSales
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/manage/prices'
																																		component={
																																			Price
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/manage/cars'
																																		component={
																																			Car
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/dispatch-addresses'
																																		component={
																																			Pickup
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/transportations/:date'
																																		component={
																																			Transportation
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/no-transportations/:date'
																																		component={
																																			NoTransportation
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/manage/special-cabins'
																																		component={
																																			SpecialCabin
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/manage/transportation-pickups'
																																		component={
																																			TransportationPickUp
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/manage/transportation-types'
																																		component={
																																			TransportationType
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/manage/itineraries'
																																		component={
																																			Itinerary
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/roles'
																																		component={
																																			Role
																																		}
																																	/>
																																	{/* <ProtectedRoute exact path='/permissions' component={Permission} /> */}
																																	<ProtectedRoute
																																		exact
																																		path='/role-permissions'
																																		component={
																																			PermissionRole
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/manage/cruise-setting'
																																		component={
																																			CruiseSetting
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/user-roles'
																																		component={
																																			UserRole
																																		}
																																	/>
																																	{/*<ProtectedRoute exact path='/khai-test' component={TestDrag} />*/}
																																	<ProtectedRoute
																																		exact
																																		path='/onboard-service'
																																		component={
																																			OnboardService
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/onboard-booking'
																																		component={
																																			OnboardBooking
																																		}
																																	/>
																																	<ProtectedRoute
																																		exact
																																		path='/manage/transportation-routes'
																																		component={
																																			TransportationRoute
																																		}
																																	/>
																																</Switch>
																															</Router>
																														</CustomerTransportContextProvider>
																													</TransportationRouteContextProvider>
																												</OnboardBookingContextProvider>
																											</OnboardServiceContextProvider>
																										</UserRoleContextProvider>
																									</PermissionRoleContextProvider>
																								</CruiseSettingContextProvider>
																							</PermissionContextProvider>
																						</RoleContextProvider>
																					</PaymentContextProvider>
																				</ItineraryContextProvider>
																			</TransportationTypeContextProvider>
																		</TransportationPickUpContextProvider>
																	</SpecialContextProvider>
																</TransportationContextProvider>
															</PickupContextProvider>
														</CarContextProvider>
													</PriceContextProvider>
												</CountryContextProvider>
											</ServiceContextProvider>
										</PartnerContextProvider>
									</GuideContextProvider>
								</RoomTypeContextProvider>
							</RoomContextProvider>
						</BookingExpireContextProvider>
					</BookingContextProvider>
				</CruiseContextProvider>
			</SettingContextProvider>
		</AuthContextProvider>
	);
}

export default App;
