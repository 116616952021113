import { Fragment, useContext } from "react";
import { Helmet } from "react-helmet";
import { PickupContext } from '../../contexts/PickupContext';
import {Toast} from 'react-bootstrap';
import ListPickup from "./ListPickup";
import AddPickupModal from './AddPickupModal';

const Pickup = () => {

    const { 
        setShowAddPickupModal,
        showToast: {show, message, type},
        setShowToast
    } = useContext(PickupContext)

    return (
        <Fragment>
            <Helmet>
                <title>List Pickup Address - Cruises Management Software</title>
            </Helmet>
            <div className="horizontal-mainwrapper container-fluid clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-3">
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <h3 className="pull-left">List Pickup Address</h3>
                            </div>
                            <div className="col-md-2 ml-auto">
                                <button className="right-content btn btn-success btn-block" onClick={setShowAddPickupModal.bind(this, true)}><i className="fe fe-plus-circle"></i> Add Pickup Address</button>
                            </div>
                        </div>
                        <hr className="mt-3" />
                        <ListPickup />
                        <AddPickupModal />
                        <Toast 
                            show={show} 
                            style={ {position: 'fixed', top: '20%', right: '10px'} } 
                            className={`bg-${type} text-white`} 
                            onClose={setShowToast.bind(this, {show: false, message: '', type: null})}
                            delay={3000}
                            autohide
                        >
                            <Toast.Body>
                                <strong style={ {color: '#ffffff'} }>{message}</strong>
                            </Toast.Body>
                        </Toast>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Pickup
