import { Fragment, useContext, useState } from "react";
import { PickupContext } from '../../contexts/PickupContext';
import { Button, Form, Modal } from "react-bootstrap";

const AddPickupModal = () => {

    const { 
        showAddPickupModal, 
        setShowAddPickupModal, 
        addPickup, 
        setShowToast
    } = useContext(PickupContext)

    // State
    const [newPickup, setNewPickup] = useState({
        address: ''
    })
    
    const { address } = newPickup

    const onChangeNew = event => setNewPickup( {...newPickup, [event.target.name]: event.target.value } )

    const onSubmit = async event => {
        event.preventDefault()
        const response = await addPickup(newPickup)
        if (response.data) {
            closeDialog()
            setShowToast({show: true, message: 'Added sucessful!', type: 'success'})
            return
        }
        setShowToast({show: true, message: response.error && response.error.ErrorCode? response.error.ErrorCode: 'Add failed!', type: 'danger'})
    }    

    const closeDialog = () => {
        setNewPickup({
            address: ''
        })
        setShowAddPickupModal(false)
    }

    return (
        <Fragment>
            <Modal show={showAddPickupModal} onHide={closeDialog}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Create New Pickup
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="form-group">
                            <label>Address</label>
                            <input className="form-control" placeholder="Enter Pickup Address" name='address' value={address} onChange={onChangeNew} required aria-describedby='address-help' />
                            <Form.Text id='address-help' muted>Required</Form.Text>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default AddPickupModal
