import moment from 'moment';
import * as XLSX from 'xlsx';
import * as XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';
export const handleExportCustomerTransport = async (data, dateInfo, quantity, comInfo) => {
	let startRowIndex = comInfo ? comInfo.length + 1 : 1;
	if (!startRowIndex) startRowIndex = 1;
	const ws = XLSX.utils.json_to_sheet(data, { origin: `A${startRowIndex + 3}` });

	// add company information
	const mergeForCompanyInfo = [];
	comInfo.forEach((item, index) => {
		const rowIndex = comInfo.length - index;
		XLSX.utils.sheet_add_aoa(ws, [[item]], { origin: `A${startRowIndex - rowIndex}` });
		mergeForCompanyInfo.push({
			s: { r: startRowIndex - rowIndex - 1, c: 0 },
			e: { r: startRowIndex - rowIndex - 1, c: 8 }
		});
	});
	const month = moment(dateInfo).format('MM'),
		year = moment(dateInfo).format('YYYY'),
		date = moment(dateInfo).format('DD');
	XLSX.utils.sheet_add_aoa(ws, [['DANH SÁCH ĐÓN TRẢ KHÁCH']], { origin: `A${startRowIndex}` });
	XLSX.utils.sheet_add_aoa(ws, [[`(ngày ${date} tháng ${month} năm ${year})`]], { origin: `A${startRowIndex + 1}` });
	const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
	const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	const rowLength = data.length + startRowIndex;

	const merge = [
		{ s: { r: startRowIndex - 1, c: 0 }, e: { r: startRowIndex - 1, c: 8 } },
		{ s: { r: startRowIndex, c: 0 }, e: { r: startRowIndex, c: 8 } },
		{ s: { r: startRowIndex + 1, c: 0 }, e: { r: startRowIndex + 1, c: 8 } }
	];

	ws['!merges'] = [...merge, ...mergeForCompanyInfo];

	const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
	const expData = new Blob([excelBuffer], { type: fileType });

	return addStyle(expData, rowLength, startRowIndex, comInfo);
};

const addStyle = (workbookBlob, rowLength, startRowIndex, comInfo) => {
	return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
		workbook.sheets().forEach((sheet) => {
			sheet.usedRange().style({
				fontFamily: 'Times New Roman',
				// verticalAlignment: 'center',
				horizontalAlignment: 'left',
				wrapText: true
			});
			// company info
			if (comInfo.length) {
				sheet.range(`A${startRowIndex - comInfo.length}:I${startRowIndex - 1}`).style({
					fontSize: 13,
					bold: true
				});
			}
			// content table
			sheet.range(`A${startRowIndex + 3}:I${rowLength + 3}`).style({
				fontSize: 11,
				border: true
			});

			// set width for columns
			sheet.column('A').width(10);
			sheet.column('B').width(15);
			sheet.column('C').width(15);
			sheet.column('D').width(25);
			sheet.column('E').width(25);
			sheet.column('F').width(15);
			sheet.column('G').width(30);
			sheet.column('H').width(30);
			sheet.column('I').width(30);
			// header style
			sheet.range(`A${startRowIndex}:I${startRowIndex + 1}`).style({
				fontSize: 14,
				bold: true,
				horizontalAlignment: 'center',
				verticalAlignment: 'center'
			});
			sheet.row(startRowIndex).height(30);
			sheet.range(`A${startRowIndex + 3}:I${startRowIndex + 3}`).style({
				fontSize: 12,
				fill: '82AEE8',
				bold: true,
				horizontalAlignment: 'center'
			});
		});

		return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob));
	});
};
