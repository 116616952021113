import { Fragment, useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import {Modal, Button, Form} from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AlertMessage from '../layout/AlertMessage';


const UpdateUserModal = (props) => {
    const selectedUser = props.selectedUser
    const setSelectedUser = props.setSelectedUser
    const {
        updateUser,
        showUpdateUserModal,
        setShowUpdateUserModal,
        setShowToast
    } = useContext(AuthContext)

    const [showData, setShowData] = useState();
    const [alert, setAlert] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState("");

    // State
    const [updatedUser, setUpdatedUser] = useState(selectedUser)
    useEffect( () => setUpdatedUser(selectedUser), [selectedUser] )

    const onChangeUser = event => setUpdatedUser( {...updatedUser, [event.target.name]: event.target.value } )
    const onChangeConfirmPass = event => setConfirmPassword(event.target.value)

    const handleChangeChk = (event) => {
        if(event.target.checked){
            setShowData(true)
        }else{
            setShowData(false)
        }
    }

    const handleChange = (event, editor) => {
        setUpdatedUser( {...updatedUser, detail: editor.getData() } )
    }

    const onSubmit = async event => {
        event.preventDefault()
        try {
            if(showData === true){
                if(updatedUser.password !== confirmPassword) {
                    setAlert({ type: 'danger', message: 'Password does not match!' })
                    setTimeout(() => setAlert(null), 5000)
                    return
                }
                
                updatedUser.new_password = confirmPassword
                const response = await updateUser(updatedUser)
                if (response.status !== 200) {
                    setShowToast({show: true, message: `Update failed: ${response.error.ErrorCode}`, type: 'danger'})
                    return
                }
                
                setSelectedUser(updatedUser)
                setUpdatedUser(updatedUser)
                
                setShowToast({show: true, message: 'Updated successful!', type: 'success'})
                
                return
            }

            const response = await updateUser(updatedUser)
            if (response.status !== 200) {
                setShowToast({show: true, message: `Update failed: ${response.error.ErrorCode}`, type: 'danger'})
                return
            }

            setShowUpdateUserModal(false)
            setShowToast({show: true, message: 'Updated successful!', type: 'success'})
            
            closeDialog();
        } catch (error) {
            setShowToast({show: true, message: error.message, type: 'danger'})
            return
        }
    }

    const closeDialog = () => {
        setUpdatedUser(updatedUser)
        setSelectedUser(updatedUser)
        setShowUpdateUserModal(false)
        setShowData(false)
    }

    return (
        <Fragment>
            <Modal show={showUpdateUserModal} onHide={closeDialog}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Update User Profile
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <AlertMessage info={alert} />
                    <Modal.Body>
                        <input type="hidden" name="id" value={updatedUser.id} />
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-9">
                                    <label>Full Name</label>
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Enter Full Name" value={updatedUser.name} onChange={onChangeUser} required aria-describedby='name-help' />
                                    <Form.Text id='name-help' muted>Required</Form.Text>
                                </div>
                                <div className="col-md-3">
                                    <label>Active</label>
                                    <select name="status" value={updatedUser.status} className="SlectBox form-control" required aria-describedby='status-help' onChange={onChangeUser}>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                    <Form.Text id='status-help' muted>Required</Form.Text>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-9">
                                    <label>Email</label>
                                    <input type="email" className="form-control" id="email" placeholder="Enter Email" value={updatedUser.email} readOnly aria-describedby='email-help' />
                                    <Form.Text id='email-help' muted>Required</Form.Text>
                                </div>
                                <div className="col-md-3">
                                    <label>Department</label>
                                    <select name="department" value={updatedUser.department} className="SlectBox form-control" required aria-describedby='department-help' onChange={onChangeUser}>
                                        <option label="Choose Department"></option>
                                        <option value="member">Member</option>
                                        <option value="sale">Sale</option>
                                        <option value="isale">Isale</option>
                                        <option value="opt">Operating</option>
                                        <option value="acc">Accountant</option>
                                        <option value="admin">Admin</option>
                                    </select>
                                    <Form.Text id='department-help' muted>Required</Form.Text>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="checkbox">
                                <div className="custom-checkbox custom-control">
                                    <input type="checkbox" data-checkboxes="mygroup" className="custom-control-input" id="checkbox-2" onChange={handleChangeChk.bind(this)} />
                                    <label htmlFor="checkbox-2" className="custom-control-label mt-1"><strong>Đổi mật khẩu</strong></label>
                                </div>
                            </div>
                        </div>
                        {showData ? (
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm">
                                    <label>Password</label>
                                    <input type="password" className="form-control" id="password" name="password" placeholder="Enter Password" autoComplete="on" onChange={onChangeUser} required aria-describedby='password-help' />
                                    <Form.Text id='password-help' muted>Required</Form.Text>
                                </div>
                                <div className="col-sm">
                                    <label>Confirm Password</label>
                                    <Form.Control type="password" placeholder="Confirm Password" id="confirmPassword" name="confirmPassword" autoComplete="on" onChange={onChangeConfirmPass} required aria-describedby='confirmPassword-help' />
                                    <Form.Text id='confirmPassword-help' muted>Required</Form.Text>
                                </div>
                            </div>
                        </div>
                        ): ( "" )}
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm">
                                    <label>Telephone</label>
                                    <input type="text" className="form-control" id="telephone" name="telephone" placeholder="Enter Telephone" value={updatedUser.telephone} onChange={onChangeUser} />
                                </div>
                                <div className="col-sm">
                                    <label>Address</label>
                                    <input type="text" className="form-control" id="address" name="address" placeholder="Enter Address" value={updatedUser.address} onChange={onChangeUser} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Description</label>
                            <CKEditor
                                editor={ ClassicEditor }
                                data={selectedUser.detail}
                                onChange={handleChange}
                            />
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default UpdateUserModal
