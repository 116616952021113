import { Fragment, useContext } from 'react'
import { CountryContext } from '../../contexts/CountryContext'
import ListCountry from '../../components/layout/countries/ListCountry'
import AddCountryModal from '../../components/layout/countries/AddCountryModal'
import { AuthContext } from '../../contexts/AuthContext';
import { ROLES, PERMISSIONS, NO_PERMISSION } from '../../contexts/constants';
import { useTranslation } from 'react-i18next';

const Country = () => {
    const { t } = useTranslation();
    const {
        setShowAddCountryModal
    } = useContext(CountryContext)

    const {
        authState: {user }
    } = useContext(AuthContext)

    if (!user) {
        return NO_PERMISSION
    }

    if (user.Roles.length == 1 && user.Roles.includes(ROLES.PARTNER)) return NO_PERMISSION;
    if (!(user.Permissions.includes(PERMISSIONS.GET_COUNTRY) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))) {
        return NO_PERMISSION
    }

    return (
        <Fragment>
            <div className="horizontal-mainwrapper container clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-3">
                        <div className="row">
                            <div className="col-md-4">
                                <h3 className="pull-left">{t('Country List')}</h3>
                            </div>
                            <div className="col-md-2 ml-auto">
                            {(user.Permissions.includes(PERMISSIONS.INSERT_COUNTRY) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))? <button className="right-content btn btn-success btn-block" onClick={setShowAddCountryModal.bind(this, true)}><i className="fe fe-plus-circle"></i> {t('Add Country')}</button>: null }
                            </div>
                        </div>
                    </div>
                    <ListCountry />
                    <AddCountryModal />
                    <div className='margin-bottom-150'></div>
                </div>
            </div>
        </Fragment>
    )
}

export default Country
