import { ROOMS_LOADED_SUCCESS, ROOMS_LOADED_FAIL, ADD_ROOM, FIND_ROOM, UPDATE_ROOM, DELETE_ROOM } from '../contexts/constants';

export const roomReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case ROOMS_LOADED_SUCCESS:
            return {
                ...state,
                rooms: payload,
                roomsLoading: false
            }
        case ROOMS_LOADED_FAIL:
            return {
                ...state,
                rooms: [],
                roomsLoading: false
            }
        case ADD_ROOM:
            return {
                ...state,
                rooms: [payload, ...state.rooms],
            }
        case FIND_ROOM:
            return {
                ...state,
                room: payload
            }
        case UPDATE_ROOM:
            const newRoom = state.rooms.map(room => {
                if (room.id === payload.id) {
                    return payload
                } else {
                    return room
                }
            })
            return {
                ...state,
                rooms: newRoom
            }
        case DELETE_ROOM:
            return {
                ...state,
                rooms: state.rooms.filter(room => room.id !== payload)
            }
        default:
            return state
    }
}