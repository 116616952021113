import { SETTINGS_LOADED_SUCCESS, SETTINGS_LOADED_FAIL, ADD_SETTING, FIND_SETTING, UPDATE_SETTING, DELETE_SETTING } from '../contexts/constants';

export const settingReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case SETTINGS_LOADED_SUCCESS:
            return {
                ...state,
                settings: payload,
                settingsLoading: false
            }
        case SETTINGS_LOADED_FAIL:
            return {
                ...state,
                settings: [],
                settingsLoading: false
            }
        case ADD_SETTING:
            return {
                ...state,
                settings: [...state.settings, payload],
            }
        case FIND_SETTING:
            return {
                ...state,
                setting: payload
            }
        case UPDATE_SETTING:
            const newSetting = state.settings.map(setting => {
                if (setting.id === payload.id) {
                    return payload
                } else {
                    return setting
                }
            })
            return {
                ...state,
                settings: newSetting
            }
        case DELETE_SETTING:
            return {
                ...state,
                settings: state.settings.filter(setting => setting.id !== payload)
            }
        default:
            return state
    }
}