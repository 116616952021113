import { createContext, useState, useReducer } from 'react';
import { bookingReducer } from '../reducers/bookingReducer';
import {
	CRUISE_TYPES,
	apiUrl,
	CUSTOMERS_DAY_BOOKINGS_LOADED_SUCCESS,
	BOOKINGS_LOADED_SUCCESS,
	BOOKINGS_LOADED_FAIL,
	ADD_BOOKING,
	FIND_BOOKING,
	UPDATE_BOOKING,
	DELETE_BOOKING,
	A_BOOKING,
	CUSTOMERS_BOOKINGS_LOADED_SUCCESS,
	BOOKING_STATUSES,
} from './constants';
import { getAPI, postAPI } from '../utils/api';

export const BookingContext = createContext();

const BookingContextProvider = ({ children }) => {
	// State
	const [bookingState, dispatch] = useReducer(bookingReducer, {
		booking: null,
		bookings: [],
		bookingsLoading: true,
	});
	const [addBookingDefaultData, setAddBookingDefaultData] = useState();
	const [showAddBookingModal, setShowAddBookingModal] = useState(false);
	const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
	const [showCopyBookingModal, setShowCopyBookingModal] = useState(false);
	const [showUpdateBookingModal, setShowUpdateBookingModal] = useState(false);
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null,
	});

	const getBookings = async (objectValidate) => {
		try {
			const response = await getAPI(`${apiUrl}/bookings`, `${objectValidate}`);
			if (response.data) {
				dispatch({
					type: BOOKINGS_LOADED_SUCCESS,
					payload: response.data,
				});
			}
		} catch (error) {
			dispatch({ type: BOOKINGS_LOADED_FAIL });
			return error.response
				? error.response
				: { success: false, message: 'Server error!' };
		}
	};

	// Find Booking when user is updating Booking
	const findBooking = (bookingId) => {
		const booking = bookingState.bookings.find(
			(booking) => booking.id === bookingId
		);
		dispatch({
			type: FIND_BOOKING,
			payload: booking,
		});
	};

	// Add Cruise
	const addBooking = async (newBooking) => {
		const response = await postAPI(`${apiUrl}/booking/insert`, newBooking);
		if (response.date) {
			dispatch({
				type: ADD_BOOKING,
				payload: response.data,
			});
		}
		return response;
	};

	// Find a Booking
	const FindABooking = async (bookingId) => {
		if (bookingId) {
			const response = await getAPI(`${apiUrl}/booking/${bookingId}`);
			if (response.status === 200) {
				dispatch({
					type: A_BOOKING,
					payload: response.data,
				});
			}

			return response;
		}
	};

	const daysInMonths = (year, month) => {
		return new Date(year, month, 0).getDate();
	};
	const daysInCurrentMonth = (month, year) => {
		return daysInMonths(year, month);
	};

	const getBookingIdsInMonth = (
		cruiseID,
		month,
		year,
		texCheck,
		excludeStatuses = [],
		isOnboardBooking = 0
	) => {
		const monthDayLength = daysInCurrentMonth(month, year);
		let promises = [];
		for (let i = 1; i <= monthDayLength; i++) {
			const day = ('0' + i).slice(-2);
			const date = `${year}-${
				month.toString().length === 1 ? '0' + month : month
			}-${day}`;
			const promise = getBookingIdsByDate(
				cruiseID,
				date,
				texCheck,
				isOnboardBooking
			);
			promises.push(promise);
		}

		return Promise.all(promises).then((bookingIds) => {
			// Tra lai ket qua trong mot thang
			if (bookingIds) {
				const bookingData = prepareBookingData(
					bookingIds,
					texCheck,
					excludeStatuses
				);
				dispatch({
					type: CUSTOMERS_BOOKINGS_LOADED_SUCCESS,
					payload: bookingData,
				});
			}
		});
	};

	const getBookingIdsInDay = (cruiseID, day, month, year, texCheck) => {
		let promises = [];
		const date = `${year}-${month}-${day}`;
		const promise = getBookingIdsByDate(cruiseID, date, texCheck);
		promises.push(promise);

		return Promise.all(promises).then((bookingIds) => {
			// Tra lai ket qua trong mot thang
			if (bookingIds) {
				const bookingData = prepareBookingData(bookingIds, texCheck);
				dispatch({
					type: CUSTOMERS_DAY_BOOKINGS_LOADED_SUCCESS,
					payload: bookingData,
				});
			}
		});
	};

	const mergeNewNigthBooking = (
		bookingID,
		newBooking,
		newBookingData,
		bookings
	) => {
		newBooking.customers.forEach((c) => {
			let startDate = new Date(c.start_date);
			let endDate = new Date(newBooking.end_date);
			startDate.setHours(0, 0, 0, 0);
			endDate.setHours(0, 0, 0, 0);
			let nextDate = startDate;
			while (nextDate.valueOf() < endDate.valueOf()) {
				if (!bookings[c.room_id]) {
					bookings[c.room_id] = {};
				}
				const strDate =
					nextDate.getFullYear() +
					'-' +
					('0' + (nextDate.getMonth() + 1)).slice(-2) +
					'-' +
					('0' + nextDate.getDate()).slice(-2);
				if (!bookings[c.room_id][strDate]) {
					bookings[c.room_id][strDate] = [];
				}

				const assign_to_name = newBookingData.data.assign_name;
				const newCustomer = {
					...newBookingData.data,
					booking_id: bookingID,
					...c,
					assign_to_name: assign_to_name,
				};

				bookings[c.room_id][strDate].push(newCustomer);
				nextDate.setDate(nextDate.getDate() + 1);
			}
		});

		return bookings;
	};

	const mergeNewDayBooking = (
		bookingID,
		newBooking,
		newBookingData,
		bookings
	) => {
		newBooking.customers.forEach((c) => {
			if (!bookings[c.start_date]) {
				bookings[c.start_date] = [];
			}

			const assign_to_name = newBookingData.data.assign_name;
			const newCustomer = {
				...newBookingData.data,
				booking_id: bookingID,
				...c,
				assign_to_name: assign_to_name,
			};

			bookings[c.start_date].push(newCustomer);
		});

		return bookings;
	};

	const setBookingsData = async (cruiseMode, bookings, newBooking) => {
		if (!bookings) return;
		if (!newBooking) return;
		if (!newBooking.customers) return;

		const newBookingData = await getAPI(`${apiUrl}/booking/${newBooking.id}`);
		if (newBookingData.error) {
			return;
		}

		const bookingID = newBookingData.data.id;
		delete newBookingData.data.id;
		let mergedBookings;

		if (cruiseMode === CRUISE_TYPES.NIGHT) {
			mergedBookings = mergeNewNigthBooking(
				bookingID,
				newBooking,
				newBookingData,
				bookings
			);
		} else {
			mergedBookings = mergeNewDayBooking(
				bookingID,
				newBooking,
				newBookingData,
				bookings
			);
		}

		dispatch({
			type: CUSTOMERS_DAY_BOOKINGS_LOADED_SUCCESS,
			payload: mergedBookings,
		});
	};

	const prepareBookingData = (bookingIds, texCheck, excludeStatuses = []) => {
		let data = {};

		// for night trip
		if (texCheck === CRUISE_TYPES.NIGHT) {
			bookingIds.forEach((item) => {
				const itemDate = item.date;
				if (item.data) {
					item.data.forEach((bookingItem) => {
						if (excludeStatuses.includes(bookingItem.status)) {
							return;
						}
						if (!data[bookingItem.room_id]) {
							data[bookingItem.room_id] = {};
						}
						if (!data[bookingItem.room_id][itemDate]) {
							data[bookingItem.room_id][itemDate] = [bookingItem];
						} else {
							data[bookingItem.room_id][itemDate].push(bookingItem);
						}
					});
				}
			});
			return data;
		}

		// for day trip
		bookingIds.forEach((item) => {
			const itemDate = item.date;
			if (item.data) {
				item.data.forEach((bookingItem) => {
					if (!data[itemDate]) {
						data[itemDate] = [bookingItem];
					} else {
						data[itemDate].push(bookingItem);
					}
				});
			}
		});

		return data;
	};

	const getBookingIdsByDate = async (
		cruiseID,
		date,
		texCheck,
		isOnboardBooking = 0
	) => {
		var response = [];
		// for night trip
		if (texCheck === CRUISE_TYPES.NIGHT) {
			response = await getAPI(
				`${apiUrl}/customer/bookings`,
				`start_date=${date}&filterFields=cruise_id&filterFieldValues=${cruiseID}&isOnboardBooking=${isOnboardBooking}`
			);
			return { date: date, data: response.data };
		}

		// for day trip
		response = await getAPI(
			`${apiUrl}/customer/bookings`,
			`start_date=${date}&filterFields=cruise_id&filterFieldValues=${cruiseID}&isNight=0`
		);
		return { date: date, data: response.data };
	};

	// Update Booking
	const updateBooking = async (updateBooking) => {
		const data = JSON.stringify(updateBooking);
		const response = await postAPI(`${apiUrl}/booking/update`, data, {
			headers: {
				// Overwrite Axios's automatically set Content-Type
				'Content-Type': 'application/json',
			},
		});
		if (response.status === 200) {
			dispatch({
				type: UPDATE_BOOKING,
				payload: updateBooking,
			});
		}
		return response;
	};

	// Post Context Data
	const bookingContextData = {
		bookingState,
		getBookings,
		findBooking,
		addBooking,
		updateBooking,
		FindABooking,
		getBookingIdsByDate,
		getBookingIdsInDay,
		showAddBookingModal,
		setShowAddBookingModal,
		showUpdateBookingModal,
		setShowUpdateBookingModal,
		showAddPaymentModal,
		setShowAddPaymentModal,
		showCopyBookingModal,
		setShowCopyBookingModal,
		showToast,
		setShowToast,
		getBookingIdsInMonth,
		setBookingsData,
		setAddBookingDefaultData,
		addBookingDefaultData,
	};

	return (
		<BookingContext.Provider value={bookingContextData}>
			{children}
		</BookingContext.Provider>
	);
};

export default BookingContextProvider;
