import { Fragment, useEffect, useState } from 'react';
import { apiUrl } from '../../contexts/constants';
import ListBookingPartner from '../../components/layout/partners/ListBookingPartner';
import ChartsPartner from '../../components/layout/partners/ChartsPartner';
import { getAPI } from '../../utils/api';
import { useTranslation } from 'react-i18next';
import { escapedNewLineToLineBreakTag } from '../../utils/common';

const PartnerDetail = () => {
    const { t } = useTranslation();
    let param1 = window.location.pathname;
    let arrUrl = param1.split('/')
    let partnerID = 0

    if(arrUrl !== null){
        partnerID = arrUrl[3]
    }

    const dt = new Date();
    const currentMonth = ("0" + (dt.getMonth() + 1)).slice(-2); //("0" + 3).slice(-2); //("0" + (dt.getMonth() + 1)).slice(-2);
    const currentYear = dt.getFullYear(); //2019 //dt.getFullYear();

    const [partner, setPartner] = useState([])
    const [namePartner, setNamePartner] = useState(null)
    const [bookings, setBooking] = useState([])

    const [month, setMonth] = useState(currentMonth)
    const [year, setYear] = useState(currentYear)

    useEffect(() => {
        async function partnerData(){
            try {
                const response = await getAPI(`${apiUrl}/partner/${partnerID}`)
                setPartner(response.data)
                setNamePartner(response.data.name)
            } catch (error) {
                return error.response ? error.response : {success: false, message: 'Server error!'}
            }
        }
        partnerData()
    }, [partnerID]) // eslint-disable-line react-hooks/exhaustive-deps

    // const [dateCondition, setDateCondition] = useState(`?filterFields=partner_id&filterFieldValues=${partnerID}`)

    // useEffect(() => {
    //     async function bookingData(){
    //         try {
    //             const response = await getAPI(`${apiUrl}/report/bookings`, `${dateCondition}`)
    //             setBooking(response.data.booking_report)
    //         } catch (error) {
    //             return error.response ? error.response : {success: false, message: 'Server error!'}
    //         }
    //     }
    //     bookingData()
    // }, [partnerID]) // eslint-disable-line react-hooks/exhaustive-deps

    function setChangeBooking(dataMonth, dataYear){
        setMonth(dataMonth)
        setYear(dataYear)

    }

    let dem = 0
    let dataArrays = []
    let dataItemGroups = []
    if(partner && partner.prices !== undefined){
        partner.prices.forEach((price) => {
            if (!dataArrays[price.price_group]) {
                dataArrays[price.price_group] = {};
                dataItemGroups.push(price.price_group);
                dem = 0
            }
            dataArrays[price.price_group][dem] = price;
            dem++
        })
    }

    let arrContracts = []

    if(partner && partner.contract_file !== undefined){
        arrContracts = partner.contract_file.split(',');
    }

    let body = null

    if(partner){
        body = (
            <Fragment>
                <div className="horizontal-mainwrapper container-fluid clearfix">
                    <div className="explain-dashboard mt-5">
                        <div className="explain-header mb-3">
                            <div className="container">
                                <div className='row mt-3'>
                                    <div className="col-md-12">
                                        <h3 className="pull-left">{t('Partner')}: {partner.name}</h3>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="container">
                                <div className='row'>
                                    <div className="col-md-6">
                                        <article className="message is-info">
                                            <div className="message-body">
                                                <div className="field is-horizontal">
                                                    <div className="field-label is-normal">
                                                        <label className="label">{t('Address')}</label>
                                                    </div>
                                                    <div className="field-body">
                                                        <div className="field">
                                                            <p className="control">
                                                                {partner.address}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="field is-horizontal">
                                                    <div className="field-label is-normal">
                                                        <label className="label">{t('City')}</label>
                                                    </div>
                                                    <div className="field-body">
                                                        <div className="field">
                                                            <p className="control">
                                                                {partner.city}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="field is-horizontal">
                                                    <div className="field-label is-normal">
                                                        <label className="label">{t('Email')}</label>
                                                    </div>
                                                    <div className="field-body">
                                                        <div className="field">
                                                            <p className="control">
                                                                {partner.email}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="field is-horizontal">
                                                    <div className="field-label is-normal">
                                                        <label className="label">Hotline</label>
                                                    </div>
                                                    <div className="field-body">
                                                        <div className="field">
                                                            <p className="control">
                                                                {partner.hotline}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="field is-horizontal">
                                                    <div className="field-label is-normal">
                                                        <label className="label">{t('Phone')}</label>
                                                    </div>
                                                    <div className="field-body">
                                                        <div className="field">
                                                            <p className="control">
                                                                {partner.phone}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="field is-horizontal">
                                                    <div className="field-label is-normal">
                                                        <label className="label">Fax</label>
                                                    </div>
                                                    <div className="field-body">
                                                        <div className="field">
                                                            <p className="control">
                                                                {partner.fax}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="field is-horizontal">
                                                    <div className="field-label is-normal">
                                                        <label className="label">Website</label>
                                                    </div>
                                                    <div className="field-body">
                                                        <div className="field">
                                                            <p className="control">
                                                                {partner.weblink}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="field is-horizontal">
                                                    <div className="field-label is-normal">
                                                        <label className="label">{t('Type')}</label>
                                                    </div>
                                                    <div className="field-body">
                                                        <div className="field">
                                                            <p className="control">
                                                                {partner.partner_type}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </article>
                                        <article className="message is-info mt-3">
                                            <div className="message-body">
                                                <div className="field is-horizontal">
                                                    <div className="field-label is-normal">
                                                        <label className="label">{t('Assign To')}</label>
                                                    </div>
                                                    <div className="field-body">
                                                        <div className="field">
                                                            <p className="control">
                                                                {partner.assign_name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="field is-horizontal">
                                                    <div className="field-label is-normal">
                                                        <label className="label">{t('Price List')}</label>
                                                    </div>
                                                    <div className="field-body">
                                                        <div className="field">
                                                            <p className="control">
                                                            {dataItemGroups && dataItemGroups.length ? dataItemGroups.map((data, index) => {
                                                                const cruise = dataArrays[data]
                                                                if(index === 0){
                                                                    return (
                                                                        `${cruise[0].price_name}`
                                                                    )
                                                                }else{
                                                                    return (
                                                                        `, ${cruise[0].price_name}`
                                                                    )
                                                                }
                                                            }) : null}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {partner.contract_file !== undefined ? (
                                                    <div className="field is-horizontal">
                                                        <div className="field-label is-normal">
                                                            <label className="label">{t('Contract File')}</label>
                                                        </div>
                                                        <div className="field-body">
                                                            <div className="field">
                                                                <p className="control">
                                                                {arrContracts.map((itemContract, index) => {
                                                                    if(index === 0){
                                                                        return (
                                                                            <a href={`${apiUrl}/${itemContract}`} target="_blank">{itemContract}</a>
                                                                        )
                                                                    }else{
                                                                        return (
                                                                            <>
                                                                                <span>&nbsp;,</span><a href={`${apiUrl}/${itemContract.trimStart()}`} target="_blank">{itemContract.trimStart()}</a>
                                                                            </>
                                                                        )
                                                                    }
                                                                })}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </article>
                                    </div>
                                    <div className="col-md-6">
                                        <article className="message is-info">
                                            <div className="message-body">
                                                <label>{t('Description')}</label>
                                                <div className="control" dangerouslySetInnerHTML={{__html: escapedNewLineToLineBreakTag(partner.description)}}></div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                            <div className='container'>
                                <ChartsPartner partnerID={partnerID} setChangeBookingProps={setChangeBooking} monthProps={month} yearProps={year} />
                            </div>
                            
                            <div className="container">
                                <div className='row mt-5'>
                                    <div className="col-md-4">
                                        <h3 className="pull-left">{t('Booking List')}</h3>
                                    </div>
                                    <div className="col-md-12">
                                        <ListBookingPartner partnerID={partnerID} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }else{
        <Fragment>

        </Fragment>
    }

    return (
        <Fragment>
            {body}
        </Fragment>
    )
}

export default PartnerDetail
