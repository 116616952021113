import { Fragment, useContext, useEffect, useState } from "react";
import { AuthContext } from '../../contexts/AuthContext';
import { UserRoleContext } from '../../contexts/UserRoleContext';
import { Card, Spinner } from "react-bootstrap";
// import DeleteModal from "../../components/layout/DeleteModal";
import UpdateUserRole from "./UpdateUserRole";
import { RoleContext } from '../../contexts/RoleContext';

const ListUserRole = () => {

    // Context
    const {
        authState: { user, users },
        // findUser,
        getUsers,
        setShowUpdateUserRoleModal
    } = useContext(AuthContext)

    const {
        userRoleState: { userRoles, userRolesLoading },
        getUserRoles,
        // deleteUserRole,

    } = useContext(UserRoleContext)
    const {
        getRoles
    } = useContext(RoleContext)

    // Start: Get all specials , []
    useEffect( () => getUserRoles(), []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => getUsers(''), []) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => getRoles(), []) // eslint-disable-line react-hooks/exhaustive-deps

    const [editUserID, setEditUserID] = useState({});
    const chooseUserRole = userID => {
        setEditUserID(userID)

        setShowUpdateUserRoleModal(true)
    }

    let body = null

    if(userRolesLoading){
        body = (
            <div className="spinner-container">
                <Spinner animation='border' variant='info'></Spinner>
            </div>
        )
    } else{
        body = (
            <div className="ajax-booking-cruise mt-3">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="table-info-cruise mg-b-20">
                            <div className="table-info-cruise-body">
                                <div className="table-responsive">
                                    <table id="list-pickup" className="table display">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th className="w-220">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.map((userRo, index) => (
                                                <tr key={index}>
                                                    <td>{userRo.id}</td>
                                                    <td>{userRo.name}</td>
                                                    <td>
                                                        <div className="row">
                                                            <div className="col-sm">
                                                                <button className="btn btn-success btn-with-icon" onClick={chooseUserRole.bind(this, userRo.id)}><i className="fe fe-edit"></i> Set Roles</button>
                                                            </div>
                                                            {/* <div className="col-sm">
                                                                <DeleteModal idProps={userRo.id} deleteFunc={deleteUserRole} />
                                                            </div> */}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            {body}
            {user !== null && <UpdateUserRole editUserID={editUserID} users={users} userRoles={userRoles} setEditUserID={setEditUserID} />}
        </Fragment>
    )
}

export default ListUserRole
