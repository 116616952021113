import {
	BOOKINGS_PENDING_LOADED_SUCCESS,
	BOOKINGS_PENDING_LOADED_FAIL,
	BOOKINGS_PAYMENT_LOADED_FAIL,
	BOOKINGS_PAYMENT_LOADED_SUCCESS,
	BOOKINGS_DEPOSIT_LOADED_SUCCESS,
	BOOKINGS_DEPOSIT_LOADED_FAIL
} from '../contexts/constants';

export const bookingExpireReducer = (state, action) => {
	const { type, payload } = action;

	switch (type) {
		case BOOKINGS_PENDING_LOADED_SUCCESS:
			return {
				...state,
				bookingsPending: payload,
				bookingsPendingLoading: false
			};
		case BOOKINGS_PENDING_LOADED_FAIL:
			return {
				...state,
				bookingsPending: [],
				bookingsPendingLoading: false
			};

		case BOOKINGS_PAYMENT_LOADED_SUCCESS:
			return {
				...state,
				bookingsPayment: payload,
				bookingsPaymentLoading: false
			};
		case BOOKINGS_PAYMENT_LOADED_FAIL:
			return {
				...state,
				bookingsPayment: [],
				bookingsPaymentLoading: false
			};
		case BOOKINGS_DEPOSIT_LOADED_SUCCESS:
			return {
				...state,
				bookingsDeposit: payload,
				bookingsDepositLoading: false
			};
		case BOOKINGS_DEPOSIT_LOADED_FAIL:
			return {
				...state,
				bookingsDeposit: [],
				bookingsDepositLoading: false
			};
		default:
			return state;
	}
};
