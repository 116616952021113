import { Fragment, useState } from 'react';
import NumberFormat from 'react-number-format';
import TopAccountTdSubItem from './TopAccountTdSubItem';

const TopAccountTrItem = (props) => {
	const partnerData = props.bookingsProps;
	const stt = props.sttProps;
	const cruises = props.cruisesProps;
	const partnerID = props.partnerIDProps;
	const allTotalPeople = props.allTotalPeopleProps;

	return (
		<Fragment>
			<tr>
				<td>{stt}</td>
				<td className='td-left'>{partnerData.partner_name}</td>
				<td className='td-left'>{partnerData.asign_to_name}</td>
				<td className='td-right'>
					<NumberFormat
						value={partnerData.totalUSD}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={2}
					/>
				</td>
				<td className='td-right'>
					<NumberFormat
						value={partnerData.totalVND}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={0}
					/>
				</td>
				<td className='td-right main-color'>
					<strong><NumberFormat
						value={partnerData.totalPax}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={0}
					/></strong>
				</td>
				{cruises
					? cruises.map((cruise, index) => (
							<TopAccountTdSubItem
								key={index}
								partnerIDProps={partnerID}
								// bookingDataProps={cruiseBookingData[cruise.id]}
								bookingDataProps={partnerData[cruise.id]}
								cruiseProps={cruise}
							/>
					  ))
					: null}
				<td className='td-right'>{partnerData.bookingCount}</td>
				<td className='td-right'>{Number((partnerData.totalPax / allTotalPeople) * 100).toFixed(2)} %</td>
			</tr>
		</Fragment>
	);
};

export default TopAccountTrItem;
