import { Fragment, useContext, useState, useEffect } from 'react';
import { ItineraryContext } from '../../contexts/ItineraryContext';
import { CruiseContext } from '../../contexts/CruiseContext';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const AddItineraryModal = () => {
	const { t } = useTranslation();
	const { showAddItineraryModal, setShowAddItineraryModal, addItinerary, setShowToast } = useContext(ItineraryContext);

	let optionCruises = [];

	const {
		cruiseState: { cruises }
	} = useContext(CruiseContext);

	const [showAlert, setShowAlert] = useState(false);
	const [textContent, setTextContent] = useState(null);

	if (cruises) {
		cruises.map((cruise) => {
			// if(cruise.typeDay !== "tour" && cruise.typeDay !== "day trip"){
			optionCruises = [
				...optionCruises,
				{
					value: cruise.id,
					label: cruise.name,
					typeDay: cruise.typeDay
				}
			];
			// }
		});
	}

	// State
	const [newItinerary, setNewItinerary] = useState({
		name: '',
		duration: '',
		cruise_id: 0
	});

	const { name, duration, cruise_id } = newItinerary;

	const onChangeNew = (event) => setNewItinerary({ ...newItinerary, [event.target.name]: event.target.value });
	const onChangeNumber = (event) =>
		setNewItinerary({ ...newItinerary, [event.target.name]: Number(event.target.value) });

	const onSubmit = async (event) => {
		event.preventDefault();
		if (cruise_id === 0) {
			setTextContent(t('Please choose cruise!'));
			setShowAlert(true);
			return;
		}

		const response = await addItinerary(newItinerary);
		if (response.data) {
			closeDialog();
			setShowToast({ show: true, message: t('Added sucessful!'), type: 'success' });
			return;
		}
		setShowToast({
			show: true,
			message: response.error && response.error.ErrorCode ? response.error.ErrorCode : t('Add failed!'),
			type: 'danger'
		});
	};

	const closeDialog = () => {
		setNewItinerary({
			name: '',
			duration: '',
			cruise_id: 0
		});
		setShowAddItineraryModal(false);
	};

	// select cruise
	const handleChange = (newValue, actionMeta) => {
		if (newValue !== null) {
			setNewItinerary({ ...newItinerary, cruise_id: Number(newValue.value) });
		}
	};

	const AlertDismissible = () => {
		setTimeout(function () {
			setShowAlert(false);
		}, 3000);
		return (
			<Alert variant='danger'>
				{textContent !== null ? (
					<p>
						<strong>{textContent}</strong>
					</p>
				) : null}
			</Alert>
		);
	};

	return (
		<Fragment>
			<Modal
				show={showAddItineraryModal}
				onHide={closeDialog}
				size='lg'
				aria-labelledby='contained-modal-title-vcenter'
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>{t('Create New Itinerary')}</Modal.Title>
				</Modal.Header>
				<Form onSubmit={onSubmit}>
					<Modal.Body>
						{showAlert ? <AlertDismissible /> : null}
						<div className='form-group'>
							<Row>
								<Col>
									<div className='form-group'>
										<label>
											<strong>{t('Name')}</strong>
										</label>
										<input
											className='form-control'
											placeholder={t('Enter Itinerary Name')}
											name='name'
											value={name}
											onChange={onChangeNew}
											required
										/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className='form-group'>
										<label>
											<strong>{t('Duration')}</strong>
										</label>
										<input
											className='form-control'
											placeholder={t('No. Night')}
											name='duration'
											value={duration}
											onChange={onChangeNumber}
										/>
									</div>
								</Col>
								<Col>
									<div className='form-group'>
										<label>
											<strong>{t('Cruise')}</strong>
										</label>
										<Select
											isClearable
											name='cruiseID'
											onChange={handleChange.bind(this)}
											options={optionCruises}
											placeholder={t('Choose Cruise')}
										/>
									</div>
								</Col>
							</Row>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button className='btn btn-danger btn-with-icon' variant='secondary' onClick={closeDialog}>
							<i className='fe fe-x-circle'></i> {t('Cancel')}
						</Button>
						<Button className='btn btn-primary btn-with-icon' variant='primary' type='submit'>
							<i className='fe fe-save'></i> {t('Save')}!!!
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</Fragment>
	);
};

export default AddItineraryModal;
