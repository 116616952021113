import { Fragment, useContext, useState, useEffect } from 'react';
import { PriceContext } from '../../contexts/PriceContext';
import ListPrice from '../../components/layout/prices/ListPrice';
import AddPriceModal from '../../components/layout/prices/AddPriceModal';
import { AuthContext } from '../../contexts/AuthContext';
import { CruiseContext } from '../../contexts/CruiseContext';
import { ROLES, PERMISSIONS, NO_PERMISSION } from '../../contexts/constants';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const Price = () => {
	const { t } = useTranslation();
	const { setShowAddPriceModal } = useContext(PriceContext);

	const {
		authState: { user }
	} = useContext(AuthContext);

	const {
		cruiseState: { cruises }
	} = useContext(CruiseContext);

	const [cruiseOptions, setCruiseOptions] = useState([]);
	const [currentCruise, setCurrentCruise] = useState({});
	const [yearOptions, setYearOptions] = useState([]);
	const [currentYear, setCurrentYear] = useState({});
	useEffect(() => {
		if (cruises && cruises.length > 0) {
			const cruiseOpts = cruises.map((cruise) => {
				return {
					label: cruise.name,
					value: cruise.id
				};
			});
			setCruiseOptions(cruiseOpts);
			setCurrentCruise(cruiseOpts[0]);
		}
	}, [cruises]);

	const handleCruiseChange = (cruise) => {
		setCurrentCruise(cruise);
	};

	useEffect(() => {
		const currentYear = new Date().getFullYear();
		let yearOpts = [];
		for (let i = currentYear - 2; i < currentYear + 3; i++) {
			yearOpts.push({
				value: i,
				label: i
			});
		}
		setYearOptions(yearOpts);
		setCurrentYear(yearOpts[2]);
	}, []);

	const handleYearChange = (year) => {
		setCurrentYear(year);
	};

	if (!user) {
		return NO_PERMISSION;
	}

	if (!(user.Permissions.includes(PERMISSIONS.GET_PRICE) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))) {
		return NO_PERMISSION;
	}
	const customStyles = {
		menuPortal: (provided) => ({ ...provided, zIndex: 5 })
	};
	return (
		<Fragment>
			<div className='horizontal-mainwrapper container-fluid clearfix'>
				<div className='explain-dashboard mt-5'>
					<div className='explain-header mb-3'>
						<div className='row'>
							<div className='col-md-4'>
								<h3 className='pull-left'>{t('Price List')}</h3>
							</div>
							<div className='col-md-2 ml-auto'>
								{user.Permissions.includes(PERMISSIONS.INSERT_PRICE) ||
								user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ? (
									<button
										className='right-content btn btn-success btn-block'
										onClick={setShowAddPriceModal.bind(this, true)}
									>
										<i className='fe fe-plus-circle'></i> {t('Add Price')}
									</button>
								) : null}
							</div>
						</div>
						<hr />
						<div className='row'>
							<div className='col-md-1'>
								<h5>{t('Filter')}</h5>
							</div>
							<div className='col-md-3'>
								<div className='form-group'>
									<label>{t('Cruise')}</label>

									<Select
										isClearable
										name='cruise_id'
										onChange={handleCruiseChange.bind(this)}
										options={cruiseOptions}
										placeholder={t('Choose Cruise')}
										value={currentCruise}
										menuPosition={'fixed'}
										styles={customStyles}
									/>
								</div>
							</div>
							<div className='col-md-3'>
								<div className='form-group'>
									<label>{t('Year')}</label>
									<Select
										isClearable
										name='year'
										onChange={handleYearChange.bind(this)}
										options={yearOptions}
										placeholder={t('Choose Year')}
										value={currentYear}
										menuPosition={'fixed'}
										styles={customStyles}
									/>
								</div>
							</div>
						</div>
					</div>
					<ListPrice cruise={currentCruise} year={currentYear} />
					<AddPriceModal setCurrentCruise={setCurrentCruise} />
					<div className='margin-bottom-150'></div>
				</div>
			</div>
		</Fragment>
	);
};

export default Price;
