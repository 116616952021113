import { Fragment, useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { PaymentContext } from '../../contexts/PaymentContext';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import UpdatePaymentModal from './UpdatePaymentModal';
import { useTranslation } from 'react-i18next';

const ListPaymentBooking = ({ bookingDetail, paidAmount, balance, netIncome, setPaidPayment }) => {
	const {
		paymentState: { payment, payments },
		getPayments,
		findPayment,
		setShowUpdatePaymentModal
	} = useContext(PaymentContext);

	const choosePayment = (paymentId) => {
		findPayment(paymentId);
		setShowUpdatePaymentModal(true);
	};

	// Start: Get all rooms , []
	useEffect(() => {
		if (bookingDetail && bookingDetail.id) {
			getPayments(`?filterFields=booking_id&filterFieldValues=${bookingDetail.id}`);
		}
	}, [bookingDetail]); // eslint-disable-line react-hooks/exhaustive-deps

	// Start: Set PaidPayment , []
	useEffect(() => {
		if (payments !== null) {
			let totalItem = 0;
			payments.map((item) => {
				totalItem = totalItem + item.payment_amount;
			});
			setPaidPayment(totalItem);
		}
	}, [payments]); // eslint-disable-line react-hooks/exhaustive-deps

	let body = null;

	const { t } = useTranslation();

	if (payments !== null) {
		body = (
			<>
				<h1 className='info-header-detail mt-3'>{t('List Payment')}</h1>
				<div className='table-responsive'>
					<table className='table table-striped table-bordered mb-0 text-sm-nowrap text-lg-nowrap text-xl-nowrap'>
						<thead>
							<tr>
								<th>STT</th>
								<th>{t('Departure')}</th>
								<th>{t('Paid Amount')}</th>
								<th>{t('Currency Rate')}</th>
								<th>{t('Total')}</th>
								<td>{t('Action')}</td>
							</tr>
						</thead>
						<tbody>
							{payments.map((itemPayment, index) => {
								return (
									<tr key={index}>
										<td>{index + 1}</td>
										<td>{moment(itemPayment.payment_date).format('MMMM D, YYYY')}</td>
										<td>
											<NumberFormat
												value={itemPayment.payment_amount}
												displayType={'text'}
												thousandSeparator='.'
												decimalSeparator=','
												decimalScale={2}
												prefix={itemPayment.currency === 'usd'? '$': ''}
												suffix={itemPayment.currency === 'usd'? '': ' VNĐ'}
											/>
										</td>
										<td>
											<NumberFormat
												value={itemPayment.currency_rate}
												displayType={'text'}
												thousandSeparator='.'
												decimalSeparator=','
												decimalScale={0}
											/>
										</td>
										<td>
											<NumberFormat
												value={bookingDetail.total}
												displayType={'text'}
												thousandSeparator='.'
												decimalSeparator=','
												decimalScale={0}
												prefix={bookingDetail.money_type === 'usd'? '$': ''}
												suffix={bookingDetail.money_type === 'usd'? '': ' VNĐ'}
											/>
										</td>
										<td>
											<Button
												className='btn btn-primary btn-with-icon'
												onClick={choosePayment.bind(this, itemPayment.id)}
											>
												<i className='fe fe-edit'></i>
											</Button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</>
		);
	} else {
		body = (
			<>
				<h1 className='info-header-detail mt-3'>{t('List Payment')}</h1>
				<div className='table-responsive'>
					<table className='table table-striped table-bordered mb-0 text-sm-nowrap text-lg-nowrap text-xl-nowrap'>
						<thead>
							<tr>
								<th>STT</th>
								<th>{t('Departure')}</th>
								<th>{t('Paid Amount')}</th>
								<th>{t('Currency Rate')}</th>
								<th>{t('Total')}</th>
								<td>{t('Action')}</td>
							</tr>
						</thead>
						<tbody></tbody>
					</table>
				</div>
			</>
		);
	}

	return (
		<Fragment>
			{body}
			{payment !== null && (
				<UpdatePaymentModal
					bookingDetail={bookingDetail}
					paidAmount={paidAmount}
					balance={balance}
					netIncome={netIncome}
				/>
			)}
		</Fragment>
	);
};

export default ListPaymentBooking;