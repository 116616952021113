import React, { useEffect } from "react";
import Select from 'react-select';

function AddMoreOnboardService(props) {
    const onboardServiceOptions = props.onboardServiceOptions;
    const onboardServices = props.onboardServices;
    const calculateTotal = props.calculateTotal;
    const inputList = props.inputList;
    const setinputList = props.setinputList;

    // set default inpurtlist
    useEffect(() => {
      if (!inputList) {
        setinputList([{onboard_service:'', quantity:''}])
        return
      }
      if (inputList.length === 0) {
        setinputList([{onboard_service:'', quantity:''}])
        return
      }
    }, [inputList])
    useEffect(() => {
      if (onboardServices) {
        const services = JSON.parse(onboardServices);
        if (services) {
          const list = services.map(service => {
            return {
              'onboard_service': service.service_id,
              'quantity': service.quantity
            }
          });
          setinputList(list);
        }
      }
    }, [onboardServices])

    const handleSelectchange=(e, index)=>{
      if (!e) {
        return;
      }
      const list= [...inputList];
      list[index]['onboard_service']= e.value ? e.value: 0;
      setinputList(list);
      calculateTotal(list);
    }

    const handleinputchange=(e, index)=>{
      const {name, value}= e.target;
      const list= [...inputList];
      list[index][name]= value;
      setinputList(list);
      calculateTotal(list);
    }
  
    const handleremove= index=>{
      const list=[...inputList];
      list.splice(index,1);
      setinputList(list);
      calculateTotal(list);
    }

    const handleaddclick=()=>{ 
      setinputList([...inputList, { onboard_service:'', quantity:''}]);
    }
    const customStyles = {
      menuPortal: provided => ({ ...provided, zIndex: 5 }),
    }
    return (
      <div className="content">
      <div className="row">
        <div className="col-sm-12">
          <h6 className="mt-3 mb-4 fw-bold">Onboard Services </h6>
            
              { 
              inputList.map( (x,i)=>{
                return(
                  <div className="row">
                      <div className="form-group col-md-6">
                          <Select isClearable
                              onChange={ e=>handleSelectchange(e,i) }
                              options={onboardServiceOptions}
                              menuPosition={'fixed'}
                              placeholder="Service"
                              name="onboard_service"
                              styles={customStyles}
                              value={onboardServiceOptions.find((item) => item.value === x.onboard_service) ? onboardServiceOptions.find((item) => item.value === x.onboard_service) : ''}
                          />
                      </div>
                      <div className="form-group col-md-2">
                          <input type="number"  name="quantity" className="form-control"   placeholder="0" onChange={ e=>handleinputchange(e,i) } value={x.quantity} min="0"/>
                      </div>
                      <div className="form-group col-md-4">
                          {
                              inputList.length!==1 &&
                              <button  className="btn btn-danger mx-1" onClick={()=> handleremove(i)}>Remove</button>
                          }
                          { inputList.length-1===i &&
                          <button  className="btn btn-success" onClick={ handleaddclick}>Add More</button>
                          }
                      </div>
                  </div>
                );
              } )} 

                
        </div>
      </div>
      </div>
    );
}
export default AddMoreOnboardService;
