import { createContext, useState, useReducer } from 'react';
import { transportationReducer } from '../reducers/transportationReducer';
import { apiUrl, TRANSPORTATIONS_LOADED_SUCCESS, TRANSPORTATIONS_LOADED_FAIL, ADD_TRANSPORTATION, FIND_TRANSPORTATION, UPDATE_TRANSPORTATION, DELETE_TRANSPORTATION } from './constants';
import axios from 'axios';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';

export const TransportationContext = createContext()

const TransportationContextProvider = ({ children }) => {

    // State
    const [transportationState, dispatch] = useReducer(transportationReducer, {
        transportation: null,
        transportations: [],
        transportationsLoading: true
    })

    const [showAddTransportationModal, setShowAddTransportationModal] = useState(false)
    const [showUpdateTransportationModal, setShowUpdateTransportationModal] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all Transportations
    const getTransportations = async() => {
            const response = await getAPI(`${apiUrl}/dispatches`)
            if (response.data) {
                dispatch({ type: TRANSPORTATIONS_LOADED_SUCCESS, payload: response.data })
                return response
            }

            dispatch({ type: TRANSPORTATIONS_LOADED_FAIL })
            return response
    }

    // Add Transportation
    const addTransportation = async newTransportation => {
        const response = await postAPI(`${apiUrl}/dispatch/insert`, newTransportation)
        if (response.data) {
            dispatch({
                type: ADD_TRANSPORTATION,
                payload: response.data
            })
        }
        return response
    }

    // Find Transportation when user is updating Transportation
    const findTransportation = transportationId => {
        const transportation = transportationState.transportations.find(transportation => transportation.id === transportationId)
        dispatch({
            type: FIND_TRANSPORTATION,
            payload: transportation
        })
    }

    // update Transportation
    const updateTransportation = async updatenewTransportation => {
        const response = await postAPI(`${apiUrl}/dispatch/update`, updatenewTransportation)
        if (response.status === 200) {
            dispatch({
                type: UPDATE_TRANSPORTATION,
                payload: updatenewTransportation
            })
        }
        return response
    }

    // Delete Transportation
    const deleteTransportation = async transportationId => {
        const response = await getDeleteAPI(`${apiUrl}/dispatch/${transportationId}/delete`)
        if (response.status === 200) {
            dispatch({
                type: DELETE_TRANSPORTATION,
                payload: transportationId
            })
        }
        return response
    }

    // Post Context Data
    const transportationContextData = {
        transportationState,
        getTransportations,
        addTransportation,
        findTransportation,
        updateTransportation,
        deleteTransportation,
        showToast,
        setShowToast,
        showAddTransportationModal,
        setShowAddTransportationModal,
        showUpdateTransportationModal,
        setShowUpdateTransportationModal
    }

    return (
        <TransportationContext.Provider value = { transportationContextData } >
            { children }
        </TransportationContext.Provider>
    )

}

export default TransportationContextProvider