import { createContext, useState, useReducer } from 'react';
import { permissionRoleReducer } from '../reducers/permissionRoleReducer';
import { apiUrl, PERMISSION_ROLE_LOADED_SUCCESS, PERMISSION_ROLE_LOADED_FAIL, ADD_PERMISSION_ROLE, FIND_PERMISSION_ROLE, UPDATE_PERMISSION_ROLE, DELETE_PERMISSION_ROLE } from './constants';
import axios from 'axios';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';

export const PermissionRoleContext = createContext()

const PermissionRoleContextProvider = ({ children }) => {

    // State
    const [permissionRoleState, dispatch] = useReducer(permissionRoleReducer, {
        permissionRole: null,
        permissionRoles: [],
        permissionRolesLoading: true
    })

    const [showAddPermissionRoleModal, setShowAddPermissionRoleModal] = useState(false)
    const [showUpdatePermissionRoleModal, setShowUpdatePermissionRoleModal] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all Permission Role
    const getPermissionRoles = async () => {
        const response = await getAPI(`${apiUrl}/role-permissions`)
        if (response.data) {
            dispatch({ type: PERMISSION_ROLE_LOADED_SUCCESS, payload: response.data })
            return response
        }
    
        dispatch({ type: PERMISSION_ROLE_LOADED_FAIL })
        return response
    }

    const setRolePermissions = (rolePermissions) => {
        dispatch({ type: PERMISSION_ROLE_LOADED_SUCCESS, payload: rolePermissions })
    }

    // Add PermissionRole
    const addPermissionRole = async newPayment => {
        const response = await postAPI(`${apiUrl}/role-permission/update-multiple`, newPayment)
        if (response.data) {
            dispatch({
                type: ADD_PERMISSION_ROLE,
                payload: response.data
            })
        }
        return response
    }

    // Find Permission Role when user is updating Permission Role
    const findPermissionRole = permissionId => {
        const permissionRole = permissionRoleState.permissionRoles.find(permissionRole => permissionRole.id === permissionId)
        dispatch({
            type: FIND_PERMISSION_ROLE,
            payload: permissionRole
        })
    }

    // update Permission Role
    const updatePermissionRole = async updatePermissionRole => {
        const response = await postAPI(`${apiUrl}/role-permission/update`, updatePermissionRole)
        if (response.status === 200) {
            dispatch({
                type: UPDATE_PERMISSION_ROLE,
                payload: updatePermissionRole
            })
        }
        return response
    }

    // Delete Permission Role
    const deletePermissionRole = async permissionId => {
        const response = await getDeleteAPI(`${apiUrl}/role-permission/${permissionId}/delete`)
        if (response.status === 200) {
            dispatch({
                type: DELETE_PERMISSION_ROLE,
                payload: permissionId
            })
        }
        return response
    }

    // Post Context Data
    const permissionRoleContextData = {
        permissionRoleState,
        getPermissionRoles,
        setRolePermissions,
        addPermissionRole,
        findPermissionRole,
        updatePermissionRole,
        deletePermissionRole,
        showToast,
        setShowToast,
        showAddPermissionRoleModal,
        setShowAddPermissionRoleModal,
        showUpdatePermissionRoleModal,
        setShowUpdatePermissionRoleModal
    }

    return (
        <PermissionRoleContext.Provider value = { permissionRoleContextData } >
            { children }
        </PermissionRoleContext.Provider>
    )

}

export default PermissionRoleContextProvider