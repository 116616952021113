import { CARS_LOADED_SUCCESS, CARS_LOADED_FAIL, ADD_CAR, FIND_CAR, UPDATE_CAR, DELETE_CAR } from '../contexts/constants';

export const carReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case CARS_LOADED_SUCCESS:
            return {
                ...state,
                cars: payload,
                carsLoading: false
            }
        case CARS_LOADED_FAIL:
            return {
                ...state,
                cars: [],
                carsLoading: false
            }
        case ADD_CAR:
            {
                state.cars = state.cars && state.cars.length > 0? state.cars: [];
                return {
                    ...state,
                    cars: [...state.cars, payload],
                }
            }
        case FIND_CAR:
            return {
                ...state,
                car: payload
            }
        case UPDATE_CAR:
            const newCar = state.cars.map(car => {
                if (car.id === payload.id) {
                    return payload
                } else {
                    return car
                }
            })
            return {
                ...state,
                cars: newCar
            }
        case DELETE_CAR:
            return {
                ...state,
                cars: state.cars.filter(car => car.id !== payload)
            }
        default:
            return state
    }
}