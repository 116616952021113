import { Fragment} from 'react';
import NightCheckinCell from './NightCheckinCell';


const NightCheckinItem = props => {

    const customerBookings = props.bookingProps
    const itineraries = props.itineraries
    const dateSetting = props.dateSettingProps
    const date = props.date
    const roomName = props.roomName? props.roomName.substring(0,10) + '...': '';
    
    let body = null
    let infoCustomer = []
    let checkID = null
    let idCustomerBooking = []

    let bgBlack = ''

    if(dateSetting !== undefined){
        bgBlack = 'day-black'
    }

    if(customerBookings === undefined){
        body = (
            <td><label className='disable-room-label'>{roomName}</label></td>
        )
    }else if(customerBookings === null){
        body = (
            <td><label className='disable-room-label'>{roomName}</label></td>
        )
    }else{
        customerBookings.map((customer) => {
            if(customer.booking_id !== checkID){
                if(customer.full_name !== null){
                    infoCustomer = customer
                }
                idCustomerBooking.push(customer.booking_id)
                checkID = customer.booking_id
            }
        })
        
        body = (
            <td className={bgBlack}>
                <NightCheckinCell customerProps={customerBookings} idBookingProps={idCustomerBooking} itineraries={itineraries} date={date} roomName={roomName} roomNameFull={props.roomName}/>
            </td>
        )
    }

    return (
        <Fragment>
            {body}
        </Fragment>
    )
}

export default NightCheckinItem
