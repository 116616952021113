import { Fragment, useContext, useState, useEffect } from 'react';
import { ServiceContext } from '../../../contexts/ServiceContext';
import {Modal, Button, Form} from 'react-bootstrap';

const UpdateServiceModal = () => {

    // Context
    const {
        serviceState: {service},
        showUpdateServiceModal,
        setShowUpdateServiceModal,
        updateService,
        setShowToast
    } = useContext(ServiceContext)

    // State
    const [updatedService, setUpdatedService] = useState(service)
    useEffect( () => setUpdatedService(service), [service] )

    const {
        id,
        name,
        service_type,
        status
    } = updatedService

    const onChangeNewService = event => setUpdatedService( {...updatedService, [event.target.name]: event.target.value } )

    const closeDialog = () => {
        setUpdatedService(service)
        setShowUpdateServiceModal(false)
    }

    const onSubmit = async event => {
        event.preventDefault()
        const response = await updateService(updatedService)
        if (response.status === 200) {
            closeDialog()
            setShowToast({show: true, message: 'Updated successful!', type: 'success'})
            return
        }
        setShowToast({show: true, message: response.error && response.error.ErrorCode? response.error.ErrorCode: 'Update failed!', type: 'danger'})
    }

    return (
        <Fragment>
            <Modal show={showUpdateServiceModal} onHide={closeDialog}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Update Service
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-sm">
                                <input type="hidden" name="id" value={id} />
                                <input type="hidden" name="status" value={status} />
                                <div className="form-group">
                                    <label><strong>Name</strong></label>
                                    <input type="text" className="form-control" id="nameCruise" name="name" value={name} placeholder="Enter Name" required aria-describedby='name-help' onChange={onChangeNewService} />
                                    <Form.Text id='name-help' muted>Required</Form.Text>
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <label><strong>Service Type</strong></label>
                                    <select name="service_type" value={service_type} className="SlectBox form-control" required aria-describedby='type-help' onChange={onChangeNewService}>
                                        <option label="Choose Type"></option>
                                        <option value="simple">Day Trip</option>
                                        <option value="multiple">Trip many days</option>
                                    </select>
                                    <Form.Text id='type-help' muted>Required</Form.Text>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default UpdateServiceModal
