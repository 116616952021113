import { Fragment, useContext, useState, useEffect } from 'react';
import { CruiseContext } from '../../../contexts/CruiseContext';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const UpdateCruiseModal = () => {
	const { t } = useTranslation();
	// Context
	const {
		cruiseState: { cruise },
		showUpdateCruiseModal,
		showAddCruiseModal,
		setShowAddCruiseModal,
		setShowUpdateCruiseModal,
		updateCruise,
		addCruise,
		setShowToast,
		clearCruise,
	} = useContext(CruiseContext);

	const convertString = (stringTexts) => {
		var AccentsMap = [
			'aàảãáạăằẳẵắặâầẩẫấậ',
			'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
			'dđ',
			'DĐ',
			'eèẻẽéẹêềểễếệ',
			'EÈẺẼÉẸÊỀỂỄẾỆ',
			'iìỉĩíị',
			'IÌỈĨÍỊ',
			'oòỏõóọôồổỗốộơờởỡớợ',
			'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
			'uùủũúụưừửữứự',
			'UÙỦŨÚỤƯỪỬỮỨỰ',
			'yỳỷỹýỵ',
			'YỲỶỸÝỴ',
		];

		for (var i = 0; i < AccentsMap.length; i++) {
			var re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
			var char = AccentsMap[i][0];
			stringTexts = stringTexts.replace(re, char);
		}

		stringTexts = stringTexts.split(' ');
		var resultText = '';

		stringTexts.forEach((itemText, dem) => {
			if (dem === 0) {
				resultText = itemText;
			} else {
				resultText = resultText + '-' + itemText;
			}
		});
		return resultText.toLocaleLowerCase();
	};

	// State
	const [updatedCruise, setUpdatedCruise] = useState(cruise ? cruise : {});
	useEffect(() => setUpdatedCruise(cruise ? cruise : {}), [cruise]);

	const { id, name, code, rating, class_type, cabins, sort_order, description, notes, max_customer } = updatedCruise;

	const onChangeUpdatedCruiseForm = (event) =>
		setUpdatedCruise({ ...updatedCruise, [event.target.name]: event.target.value });
	const onChangeNewCruiseFormParseNumber = (event) =>
		setUpdatedCruise({ ...updatedCruise, [event.target.name]: Number(event.target.value) });

	const closeDialog = () => {
		setShowUpdateCruiseModal(false);
		setShowAddCruiseModal(false);
		clearCruise();
	};

	const onSubmit = async (event) => {
		event.preventDefault();

		updatedCruise.typeDay = updatedCruise.typeDay ? updatedCruise.typeDay : getTypeDay(updatedCruise);
		updatedCruise.status = updatedCruise.status ? updatedCruise.status : 'yes';
		updatedCruise.official = updatedCruise.official ? updatedCruise.official : 'yes';
		updatedCruise.name_alias = updatedCruise.name_alias ? updatedCruise.name_alias : convertString(name);

		// add cruise
		if (showAddCruiseModal) {
			delete updatedCruise.id;
			const response = await addCruise(updatedCruise);
			if (response.error) {
				setShowToast({
					show: true,
					message: response.error.ErrorCode ? response.error.ErrorCode : t('Added failed!'),
					type: 'danger',
				});
				return;
			}
			closeDialog();
			setShowToast({ show: true, message: t('Added successful!'), type: 'success' });
			return;
		}

		// update cruise
		const response = await updateCruise(updatedCruise);
		if (response.error) {
			setShowToast({
				show: true,
				message: response.error.ErrorCode ? response.error.ErrorCode : t('Update failed!'),
				type: 'danger',
			});

			return;
		}
		closeDialog();
		setShowToast({ show: true, message: t('Updated sucessful!'), type: 'success' });
	};

	return (
		<Fragment>
			<Modal
				show={showUpdateCruiseModal || showAddCruiseModal}
				onHide={closeDialog}
				size='lg'
				aria-labelledby='contained-modal-title-vcenter'
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>{showUpdateCruiseModal ? t('Update Cruise') : t('Add Cruise')}</Modal.Title>
				</Modal.Header>
				<Form onSubmit={onSubmit}>
					<Modal.Body>
						<div className='form-group'>
							<input type='hidden' name='id' value={id} />
							<div className='row'>
								<div className='col-md-6'>
									<label>{t('Cruise Name')}</label>
									<input
										type='text'
										className='form-control'
										id='name'
										name='name'
										placeholder={t('Enter Cruise Name')}
										value={name}
										onChange={onChangeUpdatedCruiseForm}
										required
										aria-describedby='name-help'
									/>
									<Form.Text id='name-help' muted>
										Required
									</Form.Text>
								</div>
								<div className='col-md-3'>
									<label>{t('Cruise Code')}</label>
									<input
										type='text'
										className='form-control'
										id='code'
										name='code'
										placeholder={t('Enter Cruise Code')}
										value={code}
										onChange={onChangeUpdatedCruiseForm}
										required
										aria-describedby='code-help'
									/>
									<Form.Text id='code-help' muted>
										Required
									</Form.Text>
								</div>
								<div className='col-md-3'>
									<label>{t('Max Customer')}</label>
									<input
										type='text'
										className='form-control'
										id='max_customer'
										name='max_customer'
										placeholder={t('Enter Max Customer')}
										value={max_customer}
										onChange={onChangeNewCruiseFormParseNumber}
										aria-describedby='max-customer-help'
									/>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-sm'>
								<div className='form-group'>
									<label>{t('Rating')}</label>
									<input
										type='text'
										className='form-control'
										id='rating'
										name='rating'
										placeholder='0.0'
										value={rating}
										onChange={onChangeNewCruiseFormParseNumber}
									/>
								</div>
							</div>
							<div className='col-sm'>
								<div className='form-group'>
									<label>{t('Class Room')}</label>
									<input
										type='text'
										className='form-control'
										id='class_type'
										name='class_type'
										placeholder={t('Deluxe')}
										value={class_type}
										onChange={onChangeUpdatedCruiseForm}
									/>
								</div>
							</div>
							<div className='col-sm'>
								<div className='form-group'>
									<label>{t('No. Cabin')}</label>
									<input
										type='text'
										className='form-control'
										id='cabins'
										name='cabins'
										placeholder={t('No. Cabin')}
										value={cabins}
										onChange={onChangeNewCruiseFormParseNumber}
									/>
								</div>
							</div>
							<div className='col-sm'>
								<div className='form-group'>
									<label>{t('Sort Order')}</label>
									<input
										type='text'
										className='form-control'
										id='sort_order'
										name='sort_order'
										placeholder={t('No.')}
										value={sort_order}
										onChange={onChangeNewCruiseFormParseNumber}
									/>
								</div>
							</div>
						</div>
						<div className='form-group'>
							<label>{t('Description')}</label>
							<textarea
								className='form-control'
								placeholder={t('Description')}
								rows='3'
								name='description'
								value={description}
								onChange={onChangeUpdatedCruiseForm}
								spellCheck='false'
							></textarea>
						</div>
						<div className='form-group'>
							<label>{t('Sale Note')}</label>
							<textarea
								className='form-control'
								placeholder={t('Sale Note')}
								rows='3'
								name='notes'
								value={notes}
								onChange={onChangeUpdatedCruiseForm}
								spellCheck='false'
							></textarea>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button className='btn btn-danger btn-with-icon' variant='secondary' onClick={closeDialog}>
							<i className='fe fe-x-circle'></i> {t('Cancel')}
						</Button>
						<Button className='btn btn-primary btn-with-icon' variant='primary' type='submit'>
							<i className='fe fe-save'></i> {t('Save')}!!!
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</Fragment>
	);
};

const getTypeDay = (updatedCruise) => {
	if (updatedCruise.typeDay) {
		return updatedCruise.typeDay;
	}

	if (!updatedCruise.cabins) {
		return 'tour';
	}

	return 'night sleep';
};

export default UpdateCruiseModal;
