import { Fragment, useContext } from 'react'
import { ItineraryContext } from '../../contexts/ItineraryContext'
import ListItineraries from './ListItineraries'
import AddItineraryModal from './AddItineraryModal'
import { AuthContext } from '../../contexts/AuthContext';
import { ROLES, PERMISSIONS, NO_PERMISSION } from '../../contexts/constants';
import { useTranslation } from 'react-i18next';

const Itinerary = () => {
    const { t } = useTranslation();
    const {
        setShowAddItineraryModal
    } = useContext(ItineraryContext)

    const {
        authState: {user }
    } = useContext(AuthContext)

    if (!user) {
        return NO_PERMISSION
    }

    if (!(user.Permissions.includes(PERMISSIONS.GET_ITINERARY) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))) {
        return NO_PERMISSION
    }

    return (
        <Fragment>
            <div className="horizontal-mainwrapper container clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-3">
                        <div className="row">
                            <div className="col-md-4">
                                <h3 className="pull-left">{t('Itinerary List')}</h3>
                            </div>
                            <div className="col-md-2 ml-auto">
                                {(user.Permissions.includes(PERMISSIONS.INSERT_ITINERARY) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))? <button className="right-content btn btn-success btn-block" onClick={setShowAddItineraryModal.bind(this, true)}><i className="fe fe-plus-circle"></i> {t('Add Itinerary')}</button> : null }
                            </div>
                        </div>
                        <hr />
                    </div>
                    <ListItineraries />
                    <AddItineraryModal />
                </div>
            </div>
            <div className="margin-bottom-150"></div>
        </Fragment>
    )
}

export default Itinerary
