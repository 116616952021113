import { Fragment, useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { TransportationTypeContext } from '../../contexts/TransportationTypeContext';
import DeleteModal from '../../components/layout/DeleteModal';
import UpdateTransportationTypeModal from './UpdateTransportationType';
import { Card, Spinner } from 'react-bootstrap';
import { ROLES, PERMISSIONS } from '../../contexts/constants';
import { getTable, getTableOptions, getDefaultSort } from '../../utils/table';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

const ListTransportationType = () => {
	const { t } = useTranslation();
	const {
		authState: { user }
	} = useContext(AuthContext);

	const {
		transportationTypeState: { transportationType, transportationTypes, transportationTypesLoading },
		getTransportationTypes,
		findTransportationType,
		deleteTransportationType,
		setShowUpdateTransportationTypeModal,
		setShowToast
	} = useContext(TransportationTypeContext);

	useEffect(() => {
		if (!transportationTypes || !transportationTypes.length) getTransportationTypes();
	}, []);

	const chooseTransportationType = (transportationTypeId) => {
		findTransportationType(transportationTypeId);
		setShowUpdateTransportationTypeModal(true);
	};
	if (transportationTypesLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info'></Spinner>
			</div>
		);
	}
	if (transportationTypes === null || transportationTypes.length === 0) {
		return (
			<Fragment>
				<Card className='text-center mx-5 my-5'>
					<Card.Header as='h1'>{t('Hi')} {user.name}</Card.Header>
					<Card.Body>
						<Card.Title>{t('Welcome to Transportation Type Manage')}</Card.Title>
						<Card.Text>
							{t('Click The Button Add Transportation Type to Create Your First Transportation Type')}
						</Card.Text>
					</Card.Body>
				</Card>
				{transportationType !== null && <UpdateTransportationTypeModal />}
			</Fragment>
		);
	}

	const tableProps = {
		user,
		transportationTypes,
		chooseTransportationType,
		deleteTransportationType,
		setShowToast,
		t
	};
	return getTransportationType(tableProps);
};

const getTransportationType = (tableProps) => {
	if (!tableProps.transportationTypes || tableProps.transportationTypes.length === 0) return null;
	const tableData = getTableData(tableProps);
	const columns = getColumns(tableProps.t);
	const defaultSorted = getDefaultSort([['index', 'asc']]);
	const options = getTableOptions(tableData.length, tableProps.t);

	return getTable(tableData, columns, defaultSorted, options);
};
const getTableData = (tableProps) => {
	const { user, transportationTypes, chooseTransportationType, deleteTransportationType, setShowToast, t } = tableProps;
	return transportationTypes.map((transportationType, index) => {
		return {
			index: index + 1,
			id: transportationType.id,
			name: transportationType.name,
			price: (
				<NumberFormat
					value={transportationType.price}
					displayType={'text'}
					thousandSeparator='.'
					decimalSeparator=','
					decimalScale={2}
				/>
			),
			currency: transportationType.currency,
			description: transportationType.description,
			action: (
				<div className='row'>
					<div className='action'>
						{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
						user.Permissions.includes(PERMISSIONS.UPDATE_TRANSPORTATION_TYPE) ? (
							<button
								className='btn btn-success btn-with-icon'
								onClick={chooseTransportationType.bind(this, transportationType.id)}
							>
								<i className='fe fe-edit'></i> {t('Edit')}
							</button>
						) : null}
					</div>
					<div className='action'>
						{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
						user.Permissions.includes(PERMISSIONS.DELETE_TRANSPORTATION_TYPE) ? (
							<DeleteModal
								nameProps={transportationType.name}
								idProps={transportationType.id}
								deleteFunc={deleteTransportationType}
								setShowToast={setShowToast}
							/>
						) : null}
					</div>
				</div>
			)
		};
	});
};
const getColumns = (t) => {
	return [
		{
			dataField: 'index',
			text: 'STT',
			sort: true
		},
		{
			dataField: 'id',
			text: 'ID',
			sort: true
		},
		{
			dataField: 'name',
			text: t('Name'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'price',
			text: t('Price'),
			sort: true,
			classes: 'td-right'
		},
		{
			dataField: 'currency',
			text: t('Currency'),
			sort: true
		},
		{
			dataField: 'description',
			text: t('Description'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'action',
			text: '',
			sort: true
		}
	];
};

export default ListTransportationType;
