import { Fragment, useState, useEffect } from 'react';
import { apiUrl } from '../../../contexts/constants';
import { getAPI } from '../../../utils/api';

import InfoHistoryBooking from './InfoHistoryBooking';
import { useTranslation } from 'react-i18next';

const CustomerActivities = (props) => {

    const booking = props.bookingProps
    const tripType = props.tripType

    const [bookingHistories, setBookingHistories] = useState([])


    //get itineraries
    useEffect(() => {
        async function getBookingHistories(){
            try {
                if(booking.id > 0){
                    const response = await getAPI(`${apiUrl}/booking-histories`, `filterFields=booking_id&filterFieldValues=${booking.id}&orderBy=id&direction=asc`)
                    setBookingHistories(response.data)
                }
            } catch (error) {
                return error.response ? error.response : {success: false, message: 'Server error!'}
            }
        }
        getBookingHistories()
    }, [booking]) // eslint-disable-line react-hooks/exhaustive-deps

    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12 table-mobile-scroll">
                    <div className="info-header-detail mt-3">
                        <h1 className="float-left">{t('Activities')}</h1>
                        <hr />
                    </div>
                    <table className="table table-bordered mg-b-0 text-md-nowrap">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>{t('Content')}</th>
                            <th>{t('Action')}</th>
                            <th>{t('DateTime')}</th>
                        </tr>
                    </thead>
                        <tbody>
                        {bookingHistories !== null ? (
                            bookingHistories.map((bookingHistory, index) => {
                                return (
                                    <InfoHistoryBooking key={bookingHistory.id} index={index} bookingHistory={bookingHistory} booking={booking} tripType={tripType} />
                                )})
                            ) : ('')}
                        </tbody>
                    </table>
                </div>
            </div>
        </Fragment>
    )
}

export default CustomerActivities
