import { Fragment, useContext, useEffect, useState } from 'react';
import { SpecialContext } from '../../contexts/SpecialContext';
import ListCabins from './ListCabins';
import UpdateCabinModal from './UpdateCabinModal';
import { AuthContext } from '../../contexts/AuthContext';
import { ROLES, PERMISSIONS, NO_PERMISSION } from '../../contexts/constants';
import Select from 'react-select';
import { CruiseContext } from '../../contexts/CruiseContext';
import { useTranslation } from 'react-i18next';

const SpecialCabin = () => {
	const { t } = useTranslation();
	const { setShowAddSpecialModal } = useContext(SpecialContext);

	const {
		authState: { user },
	} = useContext(AuthContext);
	const {
		cruiseState: { cruises },
	} = useContext(CruiseContext);

	const [cruiseOptions, setCruiseOptions] = useState([]);
	const [currentCruise, setCurrentCruise] = useState({});
	useEffect(() => {
		if (cruises && cruises.length > 0) {
			const cruiseOpts = cruises.map((cruise) => {
				return {
					label: cruise.name,
					value: cruise.id,
				};
			});
			setCruiseOptions(cruiseOpts);
			setCurrentCruise(cruiseOpts[0]);
		}
	}, [cruises]);

	const handleCruiseChange = (cruise) => {
		setCurrentCruise(cruise);
	};

	if (!user) {
		return NO_PERMISSION;
	}

	if (
		!(user.Permissions.includes(PERMISSIONS.GET_CABIN_SPECIAL) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))
	) {
		return NO_PERMISSION;
	}
	const customStyles = {
		menuPortal: (provided) => ({ ...provided, zIndex: 5 }),
	};
	return (
		<Fragment>
			<div className='horizontal-mainwrapper container clearfix'>
				<div className='explain-dashboard mt-5'>
					<div className='explain-header mb-3'>
						<div className='row'>
							<div className='col-md-4'>
								<h3 className='pull-left'>{t('Cabin Specials')}</h3>
							</div>
							<div className='col-md-2 ml-auto'>
								{user.Permissions.includes(PERMISSIONS.INSERT_CABIN_SPECIAL) ||
								user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ? (
									<button
										className='right-content btn btn-success btn-block'
										onClick={setShowAddSpecialModal.bind(this, true)}
									>
										<i className='fe fe-plus-circle'></i> {t('Add Cabin Special')}
									</button>
								) : null}
							</div>
						</div>
						<hr />
						<div className=''>
							<h5>{t('Filter')}</h5>
						</div>
						<div className='row'>
							<div className='col-md-3'>
								<div className='form-group'>
									<label>{t('Cruise')}</label>

									<Select
										isClearable
										name='cruise_id'
										onChange={handleCruiseChange.bind(this)}
										options={cruiseOptions}
										placeholder={t('Choose Cruise')}
										value={currentCruise}
										menuPosition={'fixed'}
										styles={customStyles}
									/>
								</div>
							</div>
						</div>
					</div>
					<ListCabins cruise={currentCruise} />
					<UpdateCabinModal />
				</div>
			</div>
			<div className='margin-bottom-150'></div>
		</Fragment>
	);
};

export default SpecialCabin;
