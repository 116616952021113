import { Fragment, useContext, useEffect, useState } from 'react';
import DateControl from '../../components/layout/bookings/DateControl';
import ListBooking from '../../components/layout/bookings/ListBooking';
import ListBookingDayTrip from '../../components/layout/bookings/ListBookingDayTrip';
import { CruiseContext } from '../../contexts/CruiseContext';
import { BookingContext } from '../../contexts/BookingContext';
import AddBookingModal from '../../components/layout/bookings/AddBookingModal';
import { Helmet } from 'react-helmet';
import { Toast } from 'react-bootstrap';
import { CRUISE_TYPES, ROLES, PERMISSIONS, NO_PERMISSION } from '../../contexts/constants';
import { AuthContext } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { getCompany } from '../../utils/common';

const Booking = () => {
	const {
		authState: { user },
	} = useContext(AuthContext);

	const {
		setShowAddBookingModal,
		showToast: { show, message, type },
		setShowToast,
	} = useContext(BookingContext);

	const {
		cruiseState: { cruise },
		FindACruise,
	} = useContext(CruiseContext);

	let param1 = window.location.pathname;
	let arrUrl = param1.split('/');

	let month = 0;
	let year = 0;
	let cruiseID = 0;

	if (arrUrl !== null) {
		var arrInfo = arrUrl[2].split('-');
		month = arrInfo[0];
		year = arrInfo[1];
		cruiseID = arrInfo[2];
	}

	const [addBookingDefaultData, setAddBookingDefaultData] = useState();

	const [currentMonth, setCurrentMonth] = useState(month);
	const [currentYear, setCurrentYear] = useState(year);

	//const [idCruise, setIdCruise] = useState(cruiseID)
	let checkDayCruise = '';

	useEffect(() => {
		if (cruiseID) {
			//setIdCruise(cruiseID)
			FindACruise(cruiseID);
		}
	}, [cruiseID]); // eslint-disable-line react-hooks/exhaustive-deps

	let body = null;
	const { t } = useTranslation();
	// Only for bdc
	const company = getCompany();

	const [cancelledLabel, setCanceledLabel] = useState({
		isShow: false,
		label: t('Show Canceled')
	});
	

	const showCanceledLabel = () => {
		if (cancelledLabel.isShow ) {
			setCanceledLabel({ isShow: false, label: t('Show Canceled') });
		} else {
			setCanceledLabel({ isShow: true, label: t('Hide Canceled') });
		}
	};
	
	if (user.Roles.length == 1 && user.Roles.includes(ROLES.PARTNER) && company === 'bdc' && cruiseID != 15) return NO_PERMISSION;
	// end bdc
	if (cruise !== null) {
		if (cruise.cabins === 0) {
			checkDayCruise = CRUISE_TYPES.DAY;
		} else {
			checkDayCruise = CRUISE_TYPES.NIGHT;
		}

		body = (
			<>
				<Helmet>
					<title>
						Booking {cruise ? cruise.name : ''} - Cruises Management Software
					</title>
				</Helmet>
				<div className='horizontal-mainwrapper container-fluid clearfix'>
					<div className='explain-dashboard mt-5'>
						<div className='explain-header mb-3'>
							<div className='row'>
								<div className='col-md-4'>
									<h3 className='pull-left'>{t('Manage Bookings')}</h3>
								</div>
								<div className='col-md-2 ml-auto'>
										<button
											className='right-content btn btn-info btn-block'
											onClick={showCanceledLabel}
										>
											<i className='fe fe-eye'></i> {t(cancelledLabel.label)}
										</button>
								</div>
								<div className='col-md-2'>
									{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
									user.Permissions.includes(PERMISSIONS.ADD_BOOKING) ? (
										<button
											className='right-content btn btn-success btn-block'
											onClick={setShowAddBookingModal.bind(this, true)}
										>
											<i className='fe fe-plus-circle'></i> {t('Add Booking')}
										</button>
									) : null}
								</div>
							</div>
						</div>
						<div className='explain-body'>
							<div className='row'>
								<div className='col-sm'>
									<p className='text-center bg-blue bg-font'>2N1D</p>
								</div>
								<div className='col-sm'>
									<p className='text-center bg-green bg-font'>3N2D</p>
								</div>
								<div className='col-sm'>
									<p className='text-center bg-red bg-font'>{t('Charter')}</p>
								</div>
								<div className='col-sm'>
									<p className='text-center bg-pending bg-font'>{t('Pending')}</p>
								</div>
								<div className='col-sm'>
									<p className='text-center bg-canceled bg-font'>{t('Canceled')}</p>
								</div>
								<div className='col-sm'>
									<p className='text-center bg-transferred bg-font'>
										{t('Transferred')}
									</p>
								</div>
								<div className='col-sm'>
									<p className='text-center bg-inspection bg-font'>
										{t('Inspection')}
									</p>
								</div>
							</div>
						</div>
						<hr />
						<DateControl
							monthProps={currentMonth}
							yearProps={currentYear}
							idCruiseProps={cruiseID}
							setCurrentMonth={setCurrentMonth}
							setCurrentYear={setCurrentYear}
						/>
						<hr />
						{checkDayCruise === CRUISE_TYPES.DAY ||
						checkDayCruise === CRUISE_TYPES.TOUR ? (
							<ListBookingDayTrip
								cruiseProps={cruise}
								checkDayCruise={checkDayCruise}
								setAddBookingDefaultData={setAddBookingDefaultData}
								monthProps={currentMonth}
								yearProps={currentYear}
							/>
						) : (
							<ListBooking
								cruiseProps={cruise}
								monthProps={currentMonth}
								yearProps={currentYear}
								checkDayCruise={checkDayCruise}
								setAddBookingDefaultData={setAddBookingDefaultData}
								cancelledLabel={cancelledLabel}
							/>
						)}
						<AddBookingModal
							checkDayCruise={checkDayCruise}
							defaultData={addBookingDefaultData}
							setAddBookingDefaultData={setAddBookingDefaultData}
						/>
						<Toast
							show={show}
							style={{
								position: 'fixed',
								top: '20%',
								right: '10px',
							}}
							className={`bg-${type} text-white`}
							onClose={setShowToast.bind(this, {
								show: false,
								message: '',
								type: null,
							})}
							delay={3000}
							autohide
						>
							<Toast.Body>
								<strong style={{ color: '#ffffff' }}>{message}</strong>
							</Toast.Body>
						</Toast>
					</div>
				</div>
			</>
		);
	}

	return <Fragment>{body !== null && body}</Fragment>;
};

export default Booking;
