import { Fragment } from 'react';
let url = process.env.REACT_APP_CONTROLLER_URL ? process.env.REACT_APP_CONTROLLER_URL : 'http://k2cruise.com:5000';
let domain = window.location.hostname;
const findTerm = (term) => {
	if (domain.includes(term)) {
		return domain;
	}
	return '';
};

switch (domain) {
	case findTerm('demo.local'):
		url = 'http://demo.local:2053';
		break;
	case findTerm('baitho.local'):
		url = 'http://baitho.local:2053';
		break;
	case findTerm('sena.local'):
		url = 'http://sena.local:2053';
		break;
	case findTerm('vlc.local'):
		url = 'http://vlc.local:2053';
		break;
	case findTerm('bdc.local'):
		url = 'http://bdc.local:2053';
		break;
	case findTerm('local'):
		url = 'http://baitho.local:2053';
		break;
	case findTerm('baitho'):
		url = 'http://baitho.k2cruise.com:2053';
		break;
	case findTerm('demo'):
		url = 'http://demo.k2cruise.com:2053';
		break;
	case findTerm('bdc'):
		url = 'http://bdc.k2cruise.com:2053';
		break;
	case findTerm('vlc'):
		url = 'http://vlc.k2cruise.com:2053';
		break;
	default:
		url = 'http://demo.k2cruise.com:2053';
}
export const CRUISE_TYPES = {
	NIGHT: 'night sleep',
	DAY: 'day trip',
	TOUR: 'tour'
};
export const apiUrl = url;
export const LOCAL_STORAGE_TOKEN_NAME = 'learnit-mern';
export const accessToken = '';
export const GET_SALES_LOADED_SUCCESS = 'GET_SALES_LOADED_SUCCESS';
export const GET_SALES_LOADED_FAIL = 'GET_SALES_LOADED_FAIL';

// Post
export const PAYMENTS_LOADED_SUCCESS = 'PAYMENTS_LOADED_SUCCESS';
export const PAYMENTS_LOADED_FAIL = 'PAYMENTS_LOADED_FAIL';
export const ADD_PAYMENT = 'ADD_PAYMENT';
export const DELETE_PAYMENT = 'DELETE_PAYMENT';
export const FIND_PAYMENT = 'FIND_PAYMENT';
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';

// Cruise
export const CRUISES_LOADED_SUCCESS = 'CRUISES_LOADED_SUCCESS';
export const CRUISES_LOADED_FAIL = 'CRUISES_LOADED_FAIL';
export const ADD_CRUISE = 'ADD_CRUISE';
export const FIND_CRUISE = 'FIND_CRUISE';
export const UPDATE_CRUISE = 'UPDATE_CRUISE';
export const DELETE_CRUISE = 'DELETE_CRUISE';
export const A_CRUISE = 'A_CRUISE';

// Partners
export const PARTNERS_LOADED_SUCCESS = 'PARTNERS_LOADED_SUCCESS';
export const PARTNERS_LOADED_FAIL = 'PARTNERS_LOADED_FAIL';
export const ADD_PARTNER = 'ADD_PARTNER';
export const ADD_MULTIPLE_PARTNERS = 'ADD_MULTIPLE_PARTNERS';
export const UPDATE_PARTNER = 'UPDATE_PARTNER';
export const FIND_PARTNER = 'FIND_PARTNER';
export const DELETE_PARTNER = 'DELETE_PARTNER';
export const A_PARTNER = 'A_PARTNER';
export const PARTNERS_PRICE_GROUPS = 'PARTNERS_PRICE_GROUPS';

// Rooms
export const ROOMS_LOADED_SUCCESS = 'ROOMS_LOADED_SUCCESS';
export const ROOMS_LOADED_FAIL = 'ROOMS_LOADED_FAIL';
export const ADD_ROOM = 'ADD_ROOM';
export const FIND_ROOM = 'FIND_ROOM';
export const UPDATE_ROOM = 'UPDATE_ROOM';
export const DELETE_ROOM = 'DELETE_ROOM';

// Room type
export const ROOM_TYPE_LOADED_SUCCESS = 'ROOM_TYPE_LOADED_SUCCESS';
export const ROOM_TYPE_LOADED_FAIL = 'ROOM_TYPE_LOADED_FAIL';
export const ADD_ROOM_TYPE = 'ADD_ROOM_TYPE';
export const FIND_ROOM_TYPE = 'FIND_ROOM_TYPE';
export const UPDATE_ROOM_TYPE = 'UPDATE_ROOM_TYPE';
export const DELETE_ROOM_TYPE = 'DELETE_ROOM_TYPE';

// Guides
export const GUIDES_LOADED_SUCCESS = 'GUIDES_LOADED_SUCCESS';
export const GUIDES_LOADED_FAIL = 'GUIDES_LOADED_FAIL';
export const ADD_GUIDE = 'ADD_GUIDE';
export const FIND_GUIDE = 'FIND_GUIDE';
export const UPDATE_GUIDE = 'UPDATE_GUIDE';
export const DELETE_GUIDE = 'DELETE_GUIDE';

// Services
export const SERVICES_LOADED_SUCCESS = 'SERVICES_LOADED_SUCCESS';
export const SERVICES_LOADED_FAIL = 'SERVICES_LOADED_FAIL';
export const ADD_SERVICE = 'ADD_SERVICE';
export const FIND_SERVICE = 'FIND_SERVICE';
export const UPDATE_SERVICE = 'UPDATE_SERVICE';
export const DELETE_SERVICE = 'DELETE_SERVICE';

// User
export const USERS_LOADED_SUCCESS = 'USERS_LOADED_SUCCESS';
export const USERS_LOADED_FAIL = 'USERS_LOADED_FAIL';
export const ADD_USER = 'ADD_USER';
export const FIND_USER = 'FIND_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const A_USER = 'A_USER';
export const EXTEND_TOKEN = 'EXTEND_TOKEN';

// Booking
export const BOOKINGS_LOADED_SUCCESS = 'BOOKINGS_LOADED_SUCCESS';
export const BOOKINGS_LOADED_FAIL = 'BOOKINGS_LOADED_FAIL';
export const ADD_BOOKING = 'ADD_BOOKING';
export const FIND_BOOKING = 'FIND_BOOKING';
export const UPDATE_BOOKING = 'UPDATE_BOOKING';
export const DELETE_BOOKING = 'DELETE_BOOKING';
export const A_BOOKING = 'A_BOOKING';
export const CUSTOMERS_BOOKINGS_LOADED_SUCCESS = 'CUSTOMERS_BOOKINGS_LOADED_SUCCESS';
export const CUSTOMERS_DAY_BOOKINGS_LOADED_SUCCESS = 'CUSTOMERS_DAY_BOOKINGS_LOADED_SUCCESS';

//Bookings Pending
export const BOOKINGS_PENDING_LOADED_SUCCESS = 'BOOKINGS_PENDING_LOADED_SUCCESS';
export const BOOKINGS_PENDING_LOADED_FAIL = 'BOOKINGS_PENDING_LOADED_FAIL';
export const BOOKINGS_PAYMENT_LOADED_SUCCESS = 'BOOKINGS_PAYMENT_LOADED_SUCCESS';
export const BOOKINGS_PAYMENT_LOADED_FAIL = 'BOOKINGS_PAYMENT_LOADED_FAIL';
export const BOOKINGS_DEPOSIT_LOADED_SUCCESS = 'BOOKINGS_DEPOSIT_LOADED_SUCCESS';
export const BOOKINGS_DEPOSIT_LOADED_FAIL = 'BOOKINGS_DEPOSIT_LOADED_FAIL';

// Countries
export const COUNTRIES_LOADED_SUCCESS = 'COUNTRIES_LOADED_SUCCESS';
export const COUNTRIES_LOADED_FAIL = 'COUNTRIES_LOADED_FAIL';
export const ADD_COUNTRY = 'ADD_COUNTRY';
export const FIND_COUNTRY = 'FIND_COUNTRY';
export const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
export const DELETE_COUNTRY = 'DELETE_COUNTRY';

// Date Setting for cruises
export const SETTINGS_LOADED_SUCCESS = 'SETTINGS_LOADED_SUCCESS';
export const SETTINGS_LOADED_FAIL = 'SETTINGS_LOADED_FAIL';
export const ADD_SETTING = 'ADD_SETTING';
export const FIND_SETTING = 'FIND_SETTING';
export const UPDATE_SETTING = 'UPDATE_SETTING';
export const DELETE_SETTING = 'DELETE_SETTING';

// Price
export const PRICES_LOADED_SUCCESS = 'PRICES_LOADED_SUCCESS';
export const PRICES_LOADED_FAIL = 'PRICES_LOADED_FAIL';
export const ADD_PRICE = 'ADD_PRICE';
export const FIND_PRICE = 'FIND_PRICE';
export const UPDATE_PRICE = 'UPDATE_PRICE';
export const DELETE_PRICE = 'DELETE_PRICE';

// Cars

export const CARS_LOADED_SUCCESS = 'CARS_LOADED_SUCCESS';
export const CARS_LOADED_FAIL = 'CARS_LOADED_FAIL';
export const ADD_CAR = 'ADD_CAR';
export const FIND_CAR = 'FIND_CAR';
export const UPDATE_CAR = 'UPDATE_CAR';
export const DELETE_CAR = 'DELETE_CAR';

// Pickup

export const PICKUP_LOADED_SUCCESS = 'PICKUP_LOADED_SUCCESS';
export const PICKUP_LOADED_FAIL = 'PICKUP_LOADED_FAIL';
export const ADD_PICKUP = 'ADD_PICKUP';
export const FIND_PICKUP = 'FIND_PICKUP';
export const UPDATE_PICKUP = 'UPDATE_PICKUP';
export const DELETE_PICKUP = 'DELETE_PICKUP';

// transportation pickup

export const TRANSPORTATION_PICKUP_LOADED_SUCCESS = 'TRANSPORTATION_PICKUP_LOADED_SUCCESS';
export const TRANSPORTATION_PICKUP_LOADED_FAIL = 'TRANSPORTATION_PICKUP_LOADED_FAIL';
export const ADD_TRANSPORTATION_PICKUP = 'ADD_TRANSPORTATION_PICKUP';
export const FIND_TRANSPORTATION_PICKUP = 'FIND_TRANSPORTATION_PICKUP';
export const UPDATE_TRANSPORTATION_PICKUP = 'UPDATE_TRANSPORTATION_PICKUP';
export const DELETE_TRANSPORTATION_PICKUP = 'DELETE_TRANSPORTATION_PICKUP';

// transportation type

export const TRANSPORTATION_TYPES_LOADED_SUCCESS = 'TRANSPORTATION_TYPES_LOADED_SUCCESS';
export const TRANSPORTATION_TYPES_LOADED_FAIL = 'TRANSPORTATION_TYPES_LOADED_FAIL';
export const ADD_TRANSPORTATION_TYPE = 'ADD_TRANSPORTATION_TYPE';
export const FIND_TRANSPORTATION_TYPE = 'FIND_TRANSPORTATION_TYPE';
export const UPDATE_TRANSPORTATION_TYPE = 'UPDATE_TRANSPORTATION_TYPE';
export const DELETE_TRANSPORTATION_TYPE = 'DELETE_TRANSPORTATION_TYPE';

// Transportation

export const TRANSPORTATIONS_LOADED_SUCCESS = 'TRANSPORTATIONS_LOADED_SUCCESS';
export const TRANSPORTATIONS_LOADED_FAIL = 'TRANSPORTATIONS_LOADED_FAIL';
export const ADD_TRANSPORTATION = 'ADD_TRANSPORTATION';
export const FIND_TRANSPORTATION = 'FIND_TRANSPORTATION';
export const UPDATE_TRANSPORTATION = 'UPDATE_TRANSPORTATION';
export const DELETE_TRANSPORTATION = 'DELETE_TRANSPORTATION';

// Special

export const SPECIAL_CABINS_LOADED_SUCCESS = 'SPECIAL_CABINS_LOADED_SUCCESS';
export const SPECIAL_CABINS_LOADED_FAIL = 'SPECIAL_CABINS_LOADED_FAIL';
export const ADD_SPECIAL_CABIN = 'ADD_SPECIAL_CABIN';
export const FIND_SPECIAL_CABIN = 'FIND_SPECIAL_CABIN';
export const UPDATE_SPECIAL_CABIN = 'UPDATE_SPECIAL_CABIN';
export const DELETE_SPECIAL_CABIN = 'DELETE_SPECIAL_CABIN';

//Itinerary

export const ITINERARIES_LOADED_SUCCESS = 'ITINERARIES_LOADED_SUCCESS';
export const ITINERARIES_LOADED_FAIL = 'ITINERARIES_LOADED_FAIL';
export const ADD_ITINERARY = 'ADD_ITINERARY';
export const FIND_ITINERARY = 'FIND_ITINERARY';
export const UPDATE_ITINERARY = 'UPDATE_ITINERARY';
export const DELETE_ITINERARY = 'DELETE_ITINERARY';

// Cruise Settings
export const CRUISE_SETTINGS_LOADED_SUCCESS = 'CRUISE_SETTINGS_LOADED_SUCCESS';
export const CRUISE_SETTINGS_LOADED_FAIL = 'CRUISE_SETTINGS_LOADED_FAIL';
export const ADD_CRUISE_SETTING = 'ADD_CRUISE_SETTING';
export const FIND_CRUISE_SETTING = 'FIND_CRUISE_SETTING';
export const UPDATE_CRUISE_SETTING = 'UPDATE_CRUISE_SETTING';
export const DELETE_CRUISE_SETTING = 'DELETE_CRUISE_SETTING';

// Permission
export const PERMISSIONS_LOADED_SUCCESS = 'PERMISSIONS_LOADED_SUCCESS';
export const PERMISSIONS_LOADED_FAIL = 'PERMISSIONS_LOADED_FAIL';
export const ADD_PERMISSION = 'ADD_PERMISSION';
export const FIND_PERMISSION = 'FIND_PERMISSION';
export const UPDATE_PERMISSION = 'UPDATE_PERMISSION';
export const DELETE_PERMISSION = 'DELETE_PERMISSION';

// Permission Role
export const PERMISSION_ROLE_LOADED_SUCCESS = 'PERMISSION_ROLE_LOADED_SUCCESS';
export const PERMISSION_ROLE_LOADED_FAIL = 'PERMISSION_ROLE_LOADED_FAIL';
export const ADD_PERMISSION_ROLE = 'ADD_PERMISSION_ROLE';
export const FIND_PERMISSION_ROLE = 'FIND_PERMISSION_ROLE';
export const UPDATE_PERMISSION_ROLE = 'UPDATE_PERMISSION_ROLE';
export const DELETE_PERMISSION_ROLE = 'DELETE_PERMISSION_ROLE';

// Roles

export const ROLES_LOADED_SUCCESS = 'ROLES_LOADED_SUCCESS';
export const ROLES_LOADED_FAIL = 'ROLES_LOADED_FAIL';
export const ADD_ROLE = 'ADD_ROLE';
export const FIND_ROLE = 'FIND_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';

// User Role

export const USER_ROLES_LOADED_SUCCESS = 'USER_ROLES_LOADED_SUCCESS';
export const USER_ROLES_LOADED_FAIL = 'USER_ROLES_LOADED_FAIL';
export const ADD_USER_ROLE = 'ADD_USER_ROLE';
export const FIND_USER_ROLE = 'FIND_USER_ROLE';
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export const DELETE_USER_ROLE = 'DELETE_USER_ROLE';

// OnboardService
export const ONBOARD_SERVICES_LOADED_SUCCESS = 'ONBOARD_SERVICES_LOADED_SUCCESS';
export const ONBOARD_SERVICES_LOADED_FAIL = 'ONBOARD_SERVICES_LOADED_FAIL';
export const ADD_ONBOARD_SERVICE = 'ADD_ONBOARD_SERVICE';
export const FIND_ONBOARD_SERVICE = 'FIND_ONBOARD_SERVICE';
export const UPDATE_ONBOARD_SERVICE = 'UPDATE_ONBOARD_SERVICE';
export const DELETE_ONBOARD_SERVICE = 'DELETE_ONBOARD_SERVICE';

// OnboardBooking
export const ONBOARD_BOOKINGS_LOADED_SUCCESS = 'ONBOARD_BOOKINGS_LOADED_SUCCESS';
export const ONBOARD_BOOKINGS_LOADED_FAIL = 'ONBOARD_BOOKINGS_LOADED_FAIL';
export const ADD_ONBOARD_BOOKING = 'ADD_ONBOARD_BOOKING';
export const FIND_ONBOARD_BOOKING = 'FIND_ONBOARD_BOOKING';
export const UPDATE_ONBOARD_BOOKING = 'UPDATE_ONBOARD_BOOKING';
export const DELETE_ONBOARD_BOOKING = 'DELETE_ONBOARD_BOOKING';

// OnboardService
export const TRANSPORTATION_ROUTES_LOADED_SUCCESS = 'TRANSPORTATION_ROUTES_LOADED_SUCCESS';
export const TRANSPORTATION_ROUTES_LOADED_FAIL = 'TRANSPORTATION_ROUTES_LOADED_FAIL';
export const ADD_TRANSPORTATION_ROUTE = 'ADD_TRANSPORTATION_ROUTE';
export const FIND_TRANSPORTATION_ROUTE = 'FIND_TRANSPORTATION_ROUTE';
export const UPDATE_TRANSPORTATION_ROUTE = 'UPDATE_TRANSPORTATION_ROUTE';
export const DELETE_TRANSPORTATION_ROUTE = 'DELETE_TRANSPORTATION_ROUTE';

// Customer transportation
export const CUSTOMER_TRANSPORTS_LOADED_SUCCESS = 'CUSTOMER_TRANSPORTS_LOADED_SUCCESS';
export const CUSTOMER_TRANSPORTS_LOADED_FAIL = 'CUSTOMER_TRANSPORTS_LOADED_FAIL';
export const ADD_CUSTOMER_TRANSPORT = 'ADD_CUSTOMER_TRANSPORT';
export const FIND_CUSTOMER_TRANSPORT = 'FIND_CUSTOMER_TRANSPORT';
export const UPDATE_CUSTOMER_TRANSPORT = 'UPDATE_CUSTOMER_TRANSPORT';
export const DELETE_CUSTOMER_TRANSPORT = 'DELETE_CUSTOMER_TRANSPORT';
export const A_CUSTOMER_TRANSPORT = 'A_CUSTOMER_TRANSPORT';
export const CUSTOMER_NO_TRANSPORTS_LOADED_SUCCESS = 'CUSTOMER_NO_TRANSPORTS_LOADED_SUCCESS';
export const CUSTOMER_NO_TRANSPORTS_LOADED_FAIL = 'CUSTOMER_NO_TRANSPORTS_LOADED_FAIL';

export const ROLES = {
	SUPPER_ADMINISTRATOR: 'superadministrator',
	OPT: 'Opt',
	MEMBER: 'Member',
	ADMINISTRATOR: 'administrator',
	SALE: 'Sale',
	ACC: 'ACC',
	PARTNER: 'Partner'
};
export const PERMISSIONS = {
	GET_BOOKING: 'get_booking',
	VIEW_BOOKING: 'view_booking',
	ADD_BOOKING: 'insert_booking',
	UPDATE_BOOKING: 'update_booking',
	UPDATE_BOOKING_PAYMENT: 'update_booking_payment',
	UPDATE_USER: 'update_user',
	ADD_CRUISE: 'insert_cruise',
	UPDATE_CRUISE: 'update_cruise',
	GET_CRUISE: 'get_cruise',
	DELETE_CRUISE: 'delete_cruise',
	GET_ITINERARY: 'get_itinerary',
	INSERT_ITINERARY: 'insert_itinerary',
	UPDATE_ITINERARY: 'update_itinerary',
	DELETE_ITINERARY: 'delete_itinerary',
	GET_CABIN_SPECIAL: 'get_cabin_special',
	INSERT_CABIN_SPECIAL: 'insert_cabin_special',
	UPDATE_CABIN_SPECIAL: 'update_cabin_special',
	DELETE_CABIN_SPECIAL: 'delete_cabin_special',
	GET_ROOM: 'get_room',
	INSERT_ROOM: 'insert_room',
	UPDATE_ROOM: 'update_room',
	DELETE_ROOM: 'delete_room',
	// ROOM_TYPE
	GET_ROOM_TYPE: 'get_room_type',
	INSERT_ROOM_TYPE: 'insert_room_type',
	UPDATE_ROOM_TYPE: 'update_room_type',
	DELETE_ROOM_TYPE: 'delete_room_type',
	// TRANSPORTATION PICKUP
	GET_TRANSPORTATION_PICKUP: 'get_transportation_pickup',
	INSERT_TRANSPORTATION_PICKUP: 'insert_transportation_pickup',
	UPDATE_TRANSPORTATION_PICKUP: 'update_transportation_pickup',
	DELETE_TRANSPORTATION_PICKUP: 'delete_transportation_pickup',
	// TRANSPORTATION TYPE
	GET_TRANSPORTATION_TYPE: 'get_transportation_type',
	INSERT_TRANSPORTATION_TYPE: 'insert_transportation_type',
	UPDATE_TRANSPORTATION_TYPE: 'update_transportation_type',
	DELETE_TRANSPORTATION_TYPE: 'delete_transportation_type',

	GET_PRICE: 'get_price',
	INSERT_PRICE: 'insert_price',
	UPDATE_PRICE: 'update_price',
	DELETE_PRICE: 'delete_price',
	GET_PARTNER: 'get_partner',
	VIEW_PARTNER: 'view_partner',
	GET_OWN_PARTNER: 'get_own_partner',
	INSERT_PARTNER: 'insert_partner',
	UPDATE_PARTNER: 'update_partner',
	DELETE_PARTNER: 'delete_partner',
	GET_SERVICE: 'get_service',
	INSERT_SERVICE: 'insert_service',
	UPDATE_SERVICE: 'update_service',
	DELETE_SERVICE: 'delete_service',
	GET_GUIDE: 'get_guide',
	INSERT_GUIDE: 'insert_guide',
	UPDATE_GUIDE: 'update_guide',
	DELETE_GUIDE: 'delete_guide',
	GET_COUNTRY: 'get_country',
	INSERT_COUNTRY: 'insert_country',
	UPDATE_COUNTRY: 'update_country',
	DELETE_COUNTRY: 'delete_country',
	GET_CAR: 'get_car',
	INSERT_CAR: 'insert_car',
	UPDATE_CAR: 'update_car',
	DELETE_CAR: 'delete_car',
	// onboard
	GET_ONBOARD_BOOKING: 'get_onboard_booking',
	INSERT_ONBOARD_BOOKING: 'insert_onboard_booking',
	UPDATE_ONBOARD_BOOKING: 'update_onboard_booking',
	DELETE_ONBOARD_BOOKING: 'delete_onboard_booking',
	GET_ONBOARD_SERVICE: 'get_onboard_service',
	INSERT_ONBOARD_SERVICE: 'insert_onboard_service',
	UPDATE_ONBOARD_SERVICE: 'update_onboard_service',
	DELETE_ONBOARD_SERVICE: 'delete_onboard_service',
	// transportation route
	GET_TRANSPORTATION_ROUTE: 'get_transportation_route',
	INSERT_TRANSPORTATION_ROUTE: 'insert_transportation_route',
	UPDATE_TRANSPORTATION_ROUTE: 'update_transportation_route',
	DELETE_TRANSPORTATION_ROUTE: 'delete_transportation_route',
	// transportation type
	GET_TRANSPORTATION_TYPE: 'get_transportation_type',
	INSERT_TRANSPORTATION_TYPE: 'insert_transportation_type',
	UPDATE_TRANSPORTATION_TYPE: 'update_transportation_type',
	DELETE_TRANSPORTATION_TYPE: 'delete_transportation_type',
	// transportation pickup
	GET_TRANSPORTATION_PICKUP: 'get_transportation_pickup',
	INSERT_TRANSPORTATION_PICKUP: 'insert_transportation_pickup',
	UPDATE_TRANSPORTATION_PICKUP: 'update_transportation_pickup',
	DELETE_TRANSPORTATION_PICKUP: 'delete_transportation_pickup',
	// customer transportation
	GET_CUSTOMER_TRANSPORTATION: 'get_customer_transportation',
	INSERT_CUSTOMER_TRANSPORTATION: 'insert_customer_transportation',
	UPDATE_CUSTOMER_TRANSPORTATION: 'update_customer_transportation',
	DELETE_CUSTOMER_TRANSPORTATION: 'delete_customer_transportation',
	// dashboard
	VIEW_DASHBOARD: 'view_dashboard',
	// report
	VIEW_REPORT: 'view_report',
	// notification
	VIEW_NOTIFICATION: 'view_notification',
	// operation
	VIEW_OPERATION: 'view_operation',
	// command
	VIEW_COMMAND: 'view_command'

};
export const NO_PERMISSION = (
	<Fragment>
		<div className='horizontal-mainwrapper container-fluid clearfix'>
			<div className='explain-dashboard mt-5'>
				<div className='row'>
					<div className='col-md-4'>
						<h3 className='pull-left'>You don't have permission to access here</h3>
					</div>
				</div>
				<hr />
			</div>
		</div>
	</Fragment>
);

export const BOOKING_STATUSES = {
	CONFIRMED: 'Confirmed',
	PENDING: 'Pending',
	TRANSFERRED: 'Transferred',
	INSPECTION: 'Inspection',
	CHARTER: 'Charter',
	UPTO: 'Upto',
	CANCELED: 'Canceled'
};
