import React, { useState, useEffect } from 'react'

const PaymentInformationVat = ({ paymentInformationVat, paymentInformation }) => {

    const [updatedPayment, setUpdatedPayment] = useState(paymentInformationVat)
    useEffect( () => setUpdatedPayment(paymentInformationVat), [paymentInformationVat] )

    const { 
        accountNumber,
        beneficiary,
        bankName
    } = updatedPayment

    const onChangeCustomerText = (index, event) => {
        if (!paymentInformation[index]) {
            paymentInformation[index] = {};
        }
        const fieldNames = event.target.name.split('-');
        const fieldName = fieldNames[0];
        paymentInformation[index][fieldName] =  event.target.value;
        setUpdatedPayment({...updatedPayment, [fieldName]: event.target.value })
    }

    return (
        <div className='info-payment-item'>
            <label style={{color: '#b40505', fontWeight: '600'}}>Payment Information (VAT)</label>
            <div className="form-group">
                <div className='row'>
                    <div className='col-md-3'>
                        <label>Account number:</label>
                    </div>
                    <div className='col-md-9'>
                        <input type='text' className="form-control" placeholder="0099 xxx 688 xxx" id="accountNumber-0" name='accountNumber-0' value={accountNumber}  onChange={onChangeCustomerText.bind(this, 0)} />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className='row'>
                    <div className='col-md-3'>
                        <label>Beneficiary name:</label>
                    </div>
                    <div className='col-md-9'>
                        <input type='text' className="form-control" placeholder="CONG TY CO PHAN K2 Software" id="beneficiary-0" name='beneficiary-0' value={beneficiary} onChange={onChangeCustomerText.bind(this, 0)} />
                    </div>
                </div>
            </div>
            <div className="form-group">
                <div className='row'>
                    <div className='col-md-3'>
                        <label>Bank name:</label>
                    </div>
                    <div className='col-md-9'>
                        <input type='text' className="form-control" placeholder="Vietcombank - Branch Transaction Office, Hanoi" id="bankName-0" name='bankName-0' value={bankName} onChange={onChangeCustomerText.bind(this, 0)} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentInformationVat
