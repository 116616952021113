import { Fragment, useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { AuthContext } from '../../../contexts/AuthContext';
import { findOptionItem, getOptions } from '../../../utils/common';
import { useTranslation } from 'react-i18next';

const RevenuePartnerAssign = (props) => {
	const { t } = useTranslation();
	const filterDataRevenue = props.filterDataRevenue;
	const applyFilter = props.applyFilter;
	const { getUsers } = useContext(AuthContext);

	const [saleUsers, setSaleUsers] = useState([]);
	useEffect(() => {
		const getSaleUsers = async () => {
			const userData = await getUsers('?department=sale');
			if (userData.error) {
				console.log('getSaleUsers error: ', userData.error);
				return;
			}

			setSaleUsers(userData.data);
		};
		getSaleUsers();
	}, []);
	const [optionUsers, setOptionUsers] = useState([]);
	useEffect(() => {
		const partnerOpts = getOptions(saleUsers, ['label', 'value'], ['name', 'id']);
		setOptionUsers(partnerOpts);
	}, [saleUsers]);

	return (
		<Fragment>
			<div className='form-group'>
				<label>
					<strong>{t('Assign To')}</strong>
				</label>
				<Select
					isClearable
					name='assign_to'
					onChange={applyFilter.bind(this)}
					menuPosition={'fixed'}
					menuPortalTarget={document.body}
					options={optionUsers}
					value={findOptionItem(optionUsers, filterDataRevenue.assign_to)}
					placeholder={t('Choose Sales')}
				/>
			</div>
		</Fragment>
	);
};

export default RevenuePartnerAssign;
