import { Fragment, useContext, useEffect, useState } from 'react';
import { CruiseContext } from '../../contexts/CruiseContext';
import {Helmet} from "react-helmet";
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { NO_PERMISSION, PERMISSIONS, ROLES, apiUrl } from '../../contexts/constants';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ChartBar from './ChartBar';
import { getAPI } from '../../utils/api';
import { useTranslation } from 'react-i18next';
import RoomChart from './RoomChart';
import { AuthContext } from '../../contexts/AuthContext';


const ReportSales = () => {
    const {
		authState: { user }
	} = useContext(AuthContext);
    const { t } = useTranslation();
    const {
        cruiseState: { cruise },
        FindACruise
    } = useContext(CruiseContext)

    const [bookings, setBookings] = useState([])
    const [companyData, setCompanyData] = useState([])
    const [roomBookings, setRoomBookings] = useState([])
    const [companyRoomData, setCompanyRoomData] = useState([])

    let defaultStatus = ['Confirmed', 'Pending', 'Transferred', 'Inspection', 'Charter', 'UpTo']

    let idCruise = 0

    let param1 = window.location.pathname;
    let arrUrl = param1.split('/')
    let month = 0;
    let year = 0;

    if(arrUrl !== null){
        var arrInfo = arrUrl[3].split('-')
        month = arrInfo[0]
        year = arrInfo[1]
        idCruise = arrInfo[2]
    }

    let daysInMonths = new Date(year, month, 0).getDate();

    let fromDate = `${year}-${month}-01`
    let endDate = `${year}-${month}-${daysInMonths}`
    const [currentMonth, setCurrentMonth] = useState(month)
    const [currentYear, setCurrentYear] = useState(year)

    useEffect( () => {
        if(idCruise) {
            FindACruise(idCruise)
        }
    }, [idCruise] ) // eslint-disable-line react-hooks/exhaustive-deps

    let arrMonths = []
    let arrYears = []
    let textMonth = ''
    let textYear = ''
    let cruiseName = ''
    let maxCustomer = 0;

    if(cruise){
        cruiseName = cruise.name;
        maxCustomer = cruise.max_customer;
    }

    let dateCondition = `?start_date=${fromDate}&end_date=${endDate}&cruise_id=${idCruise}`
    let months = [t('January'),t('February'),t('March'),t('April'), t('May'), t('June'), t('July'), t('August'), t('September'), t('October'), t('November'), t('December')]

    // eslint-disable-next-line no-lone-blocks
    {Array.from(Array(12), (e, i) => {
        if((i + 1) === Number(month)){
            textMonth = months[i]
        }
        arrMonths = [...arrMonths,
            {
                value: (i + 1),
                label: months[i]
            }
        ]
    })}

    const yearNum = 10;
	const minYear = year - 5 > 2022? year - 5: 2022;
	// eslint-disable-next-line no-lone-blocks
    {Array.from(Array(yearNum), (e, i) => {
        if(Number(i + minYear) === Number(year)){
            textYear = (minYear + i)
        }
        arrYears = [...arrYears,
            {
                value: (minYear + i),
                label: (minYear + i)
            }
        ]
    })}

    const handleChangeDay = (newValue, actionMeta) => {
        const dataDay = newValue;
        if (!dataDay) return;
        if(actionMeta.name === `currentMonth` ){
            var textMonth = ("0" + dataDay.value).slice(-2);
            setCurrentMonth(textMonth)
        }

        if(actionMeta.name === `currentYear` ){
            setCurrentYear(dataDay.value)
        }
    }

    const [tabKey, setTabKey] = useState('customer');
    const [dataSettings, setDataSettings] = useState([]);

    useEffect(() => {
        daysInMonths = new Date(currentYear, currentMonth, 0).getDate();
        fromDate = `${currentYear}-${currentMonth}-01`;
        endDate = `${currentYear}-${currentMonth}-${daysInMonths}`;
        dateCondition = `?start_date=${fromDate}&end_date=${endDate}&cruise_id=${idCruise}`;
        async function bookingData(){
            try {
                const response = await getAPI(`${apiUrl}/sale-report`, `${dateCondition}`)
                if (response.data) {
                    setBookings(response.data)
                } else {
                    setBookings([])
                }
            } catch (error) {
                return error.response ? error.response : {success: false, message: 'Server error!'}
            }
        }

        async function bookingRoomData(){
            try {
                const response = await getAPI(`${apiUrl}/sale-room-report`, `${dateCondition}`)
                if (response.data) {
                    setRoomBookings(response.data)
                } else {
                    setRoomBookings([])
                }
            } catch (error) {
                return error.response ? error.response : {success: false, message: 'Server error!'}
            }
        }

        async function settingData() {
            try {
                const response = await getAPI(
                    `${apiUrl}/cruise-day-settings`,
                    `filterFields=cruise_id,oos&filterFieldValues=${idCruise},true&start_date=${currentYear}-${currentMonth}-01&end_date=${currentYear}-${currentMonth}-${daysInMonths}`
                );
                setDataSettings(response.data);
            } catch (error) {
                return error.response ? error.response : { success: false, message: 'Server error!' };
            }
        }

        settingData();

        if (tabKey === 'customer') {
            bookingData();
            return;
        }

        bookingRoomData();
        
    }, [currentMonth, currentYear, idCruise, tabKey]);

    const [oosDaysNumber, setOosDaysNumber] = useState(0);

    useEffect(() => {
        if (!dataSettings) {
            setOosDaysNumber(0);
            return;
        }
        setOosDaysNumber(dataSettings.filter(item => item.oos === "true").length);
    }, [dataSettings])

    // get company report
    useEffect(() => {
        const days_of_month = new Date(currentYear, currentMonth, 0).getDate();
        const from_date = `${currentYear}-${currentMonth}-01`;
        const to_date = `${currentYear}-${currentMonth}-${days_of_month}`;
        const queryParrams = `?start_date=${from_date}&end_date=${to_date}`;
        async function companyData(){
            try {
                const response = await getAPI(`${apiUrl}/company-report`, `${queryParrams}`)
                if (response.data) {
                    setCompanyData(response.data)
                } else {
                    setCompanyData([])
                }
            } catch (error) {
                return error.response ? error.response : {success: false, message: 'Server error!'}
            }
        }

        async function companyRoomData(){
            try {
                const response = await getAPI(`${apiUrl}/company-room-report`, `${queryParrams}`)
                if (response.data) {
                    setCompanyRoomData(response.data)
                } else {
                    setCompanyRoomData([])
                }
            } catch (error) {
                return error.response ? error.response : {success: false, message: 'Server error!'}
            }
        }

        if (tabKey === 'customer') {
            companyData();
            return;
        }

        companyRoomData();
        
    }, [currentMonth, currentYear, tabKey]);

    const openLinkNewCruise = () => {
        var newPageUrl = `/report/report-sales/${currentMonth}-${currentYear}-${idCruise}`
        window.open(newPageUrl, "_blank")
    }

    if (!user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR)
			&& !user.Permissions.includes(PERMISSIONS.VIEW_REPORT)) return NO_PERMISSION;

    return (
        <Fragment>
            <Helmet>
                <title>{t('Personal Report')} {month} - {year} - {cruiseName}</title>
            </Helmet>
            <div className="horizontal-mainwrapper container clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-3">
                        <Row md={8}>
                            <Col xs={2}>
                                <div className="form-group">
                                    <label><strong>{t('Month')}</strong></label>
                                    <Select isClearable
                                        name="currentMonth"
                                        defaultValue={{ label: textMonth, value: month }}
                                        onChange={handleChangeDay.bind(this)}
                                        options={arrMonths}
                                        placeholder={t("Month")}
                                    />
                                </div>
                            </Col>
                            <Col xs={2}>
                                <div className="form-group">
                                    <label><strong>{t('Year')}</strong></label>
                                    <Select isClearable
                                        name="currentYear"
                                        defaultValue={{ label: textYear, value: year }}
                                        onChange={handleChangeDay.bind(this)}
                                        options={arrYears}
                                        placeholder={t("Year")}
                                    />
                                </div>
                            </Col>
                            <Col xs={1}>
                                <div className="form-group">
                                    <button className="btn btn-primary btn-block mt-17" onClick={openLinkNewCruise.bind(this)}><i className="fe fe-corner-down-right"></i>{t('Go')}</button>
                                </div>
                            </Col>
                        </Row>
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <h3 className="pull-left">{t('Personal Report')} {currentMonth} - {currentYear} - {cruiseName}</h3>
                            </div>
                        </div>
                        <hr />
                        <Tabs
                            id="sale-report-tabs"
                            activeKey={tabKey}
                            onSelect={(k) => setTabKey(k)}
                            className="mb-3">
                            <Tab eventKey="customer" title={t('By Customer')}>
                                <ChartBar bookingProps={bookings} cruiseNameProps={cruiseName} maxCustomer={maxCustomer} year={currentYear} month={currentMonth} companyData={companyData} idCruise={idCruise} oosDaysNumber={oosDaysNumber}/>
                            </Tab>
                            <Tab eventKey="room" title={t('By Room')}>
                                <RoomChart bookingProps={roomBookings} cruiseNameProps={cruiseName} maxCustomer={maxCustomer} year={currentYear} month={currentMonth} companyData={companyRoomData} idCruise={idCruise} oosDaysNumber={oosDaysNumber}/>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ReportSales
