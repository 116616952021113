import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory, {
	PaginationProvider,
	PaginationListStandalone,
	SizePerPageDropdownStandalone,
	PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator';

export const getTable = (tableData, columns, defaultSorted, options, tableClasses = '') => {
	const { SearchBar } = Search;
	const table = ({ paginationProps, paginationTableProps }) => (
		<div className='contentTable'>
			<div className='pull-right'>
				<SizePerPageDropdownStandalone {...paginationProps} />
				<PaginationTotalStandalone {...paginationProps} />
			</div>
			<PaginationListStandalone {...paginationProps} />
			<ToolkitProvider
				keyField='id'
				data={tableData}
				columns={columns}
				search={{ defaultSearch: '' }}
				defaultSorted={defaultSorted}
				pagination={paginationFactory()}
			>
				{(props) => (
					<div>
						<SearchBar {...props.searchProps} />
						<hr />
						<div className={`table-responsive ${tableClasses}`}>
							<div className='fullWidth100 widthFull'>
								<BootstrapTable striped hover {...props.baseProps} {...paginationTableProps} />
							</div>
						</div>
						<div className='pull-right'>
							<SizePerPageDropdownStandalone {...paginationProps} />
							<PaginationTotalStandalone {...paginationProps} />
						</div>
					</div>
				)}
			</ToolkitProvider>
			<PaginationListStandalone {...paginationProps} />
		</div>
	);
	return <PaginationProvider pagination={paginationFactory(options)}>{table}</PaginationProvider>;
};

export const getTableOptions = (size, t) => {
	return {
		custom: true,
		sizePerPage: 10,
		paginationSize: 4,
		pageStartIndex: 1,
		firstPageText: t('First'),
		prePageText:t('Back'),
		nextPageText: t('Next'),
		lastPageText: t('Last'),
		nextPageTitle: t('First'),
		prePageTitle: t('Pre page'),
		firstPageTitle: t('Next page'),
		lastPageTitle: t('Last page'),
		showTotal: true,
		totalSize: size,
	};
};

export const getDefaultSort = (data) => {
	if (!data || data.length === 0) {
		return [
			{
				dataField: 'id',
				order: 'asc',
			},
		];
	}

	let dataSort = [];
	data.forEach((item) => {
		dataSort.push({
			dataField: item[0],
			order: item[1],
		});
	});
};
