import { createContext, useReducer, useEffect, useState } from 'react';
import { authReducer } from '../reducers/authReducer';
import {
	apiUrl,
	LOCAL_STORAGE_TOKEN_NAME,
	GET_SALES_LOADED_SUCCESS,
	GET_SALES_LOADED_FAIL,
	ADD_USER,
	FIND_USER,
	UPDATE_USER,
	DELETE_USER,
	A_USER,
	EXTEND_TOKEN
} from './constants';
import setAuthToken from '../utils/setAuthToken';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
	const [authState, dispatch] = useReducer(authReducer, {
		authLoading: true,
		isAuthenticated: false,
		user: null,
		users: []
	});

	const [showAddUserModal, setShowAddUserModal] = useState(false);
	const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);
	const [showUpdateUserRoleModal, setShowUpdateUserRoleModal] = useState(false);
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null
	});

	// Authenticate User
	const loadUser = async () => {
		if (localStorage[LOCAL_STORAGE_TOKEN_NAME]) {
			setAuthToken(localStorage[LOCAL_STORAGE_TOKEN_NAME]);
		}

		if (localStorage[LOCAL_STORAGE_TOKEN_NAME] !== undefined) {
			const response = await getAPI(`${apiUrl}/user/profile`);
			if (response.data) {
				if (!response.data.Permissions) {
					response.data.Permissions = [];
				}
				if (!response.data.Roles) {
					response.data.Roles = [];
				}
				dispatch({
					type: 'SET_AUTH',
					payload: { isAuthenticated: true, user: response.data }
				});
			} else {
				localStorage.removeItem(LOCAL_STORAGE_TOKEN_NAME);
				dispatch({
					type: 'SET_AUTH',
					payload: { isAuthenticated: false, user: null }
				});
			}
			return;
		}

		localStorage.removeItem(LOCAL_STORAGE_TOKEN_NAME);
		setAuthToken(null);
		dispatch({
			type: 'SET_AUTH',
			payload: { isAuthenticated: false, user: null }
		});
	};

	useEffect(() => loadUser(), []);

	// Login
	const loginUser = async (userForm) => {
		const authCode = 'Basic ' + Buffer.from(userForm.username + ':' + userForm.password).toString('base64');
		const response = await getAPI(`${apiUrl}/user/login`, '', {
			headers: {
				Authorization: authCode
			}
		});
		const accessToken = response.data;
		if (response.status === 200) {
			localStorage.setItem(LOCAL_STORAGE_TOKEN_NAME, response.data);
			await loadUser(accessToken);
		}

		return response;
	};
	Date.prototype.addDays = function (days) {
		var date = new Date(this.valueOf());
		date.setDate(date.getDate() + days);
		return date;
	};

	// Login
	const extendToken = async (user) => {
		const response = await getAPI(`${apiUrl}/token/extend`);
		if (response.status === 200) {
			const oldDate = new Date(user.Expiration);
			user.Expiration = oldDate.addDays(1);
			dispatch({
				type: EXTEND_TOKEN,
				payload: { user }
			});
			return response;
		}

		dispatch({
			type: GET_SALES_LOADED_FAIL,
			payload: { isAuthenticated: false, users: null }
		});
		return response;
	};

	// Register

	const registerUser = async (userForm) => {
		const response = await postAPI(`${apiUrl}/auth/register`, userForm);
		if (response.data) {
			localStorage.setItem(LOCAL_STORAGE_TOKEN_NAME, response.data.accessToken);
			await loadUser();
		}

		return response;
	};

	// Get users
	const getUsers = async (objectValidate) => {
		const response = await getAPI(`${apiUrl}/users/`, `${objectValidate}`);
		if (response.status === 200) {
			dispatch({
				type: GET_SALES_LOADED_SUCCESS,
				payload: { isAuthenticated: true, users: response.data }
			});
			return response;
		}

		dispatch({
			type: GET_SALES_LOADED_FAIL,
			payload: { isAuthenticated: false, users: null }
		});
		return response;
	};

	// addUser
	const addUser = async (newUser) => {
		const response = await postAPI(`${apiUrl}/user/insert`, newUser);
		if (response.status === 200) {
			dispatch({
				type: ADD_USER,
				payload: { isAuthenticated: true, users: response.data }
			});
		}
		return response;
	};

	const findUser = (userId) => {
		return authState.users.find((user) => user.id === userId);
	};

	// Find a User
	const FindAUser = async (userId) => {
		if (userId) {
			const response = await getAPI(`${apiUrl}/user/${userId}`);
			if (response.status === 200) {
				return response.data.JSON();
			}

			return response;
		}
	};

	// update User
	const updateUser = async (updateUser) => {
		const response = await postAPI(`${apiUrl}/user/update`, updateUser);
		if (response.status === 200) {
			dispatch({
				type: UPDATE_USER,
				payload: { isAuthenticated: false, user: updateUser }
			});
		}

		return response;
	};

	// delete User
	const deleteUser = async (userId) => {
		const response = await getDeleteAPI(`${apiUrl}/user/${userId}/delete`);
		if (response.status === 200) {
			dispatch({
				type: DELETE_USER,
				payload: { isAuthenticated: true, user: userId }
			});
		}
		return response;
	};

	// Logout

	const logoutUser = async () => {
		localStorage.removeItem(LOCAL_STORAGE_TOKEN_NAME);
		await getAPI(`${apiUrl}/user2/logout`);
		dispatch({
			type: 'SET_AUTH',
			payload: { isAuthenticated: false, user: null }
		});
	};

	// Context data
	const authContextData = {
		loginUser,
		registerUser,
		logoutUser,
		getUsers,
		addUser,
		findUser,
		FindAUser,
		updateUser,
		deleteUser,
		extendToken,
		authState,
		loadUser,
		showAddUserModal,
		setShowAddUserModal,
		showUpdateUserModal,
		setShowUpdateUserModal,
		showUpdateUserRoleModal,
		setShowUpdateUserRoleModal,
		showToast,
		setShowToast
	};

	// Return provider
	return <AuthContext.Provider value={authContextData}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
