import { useState, useEffect, useContext } from 'react';
import { OnboardServiceContext } from '../../../contexts/OnboardServiceContext';
import { Modal, Button, Form } from 'react-bootstrap';
const AddOnboardServiceModal = () => {

    // Context
    const {
        addOnboardService,
        showAddOnboardServiceModal,
        setShowAddOnboardServiceModal,
        setShowToast
    } = useContext(OnboardServiceContext)

    // State
    const [newOnboardService, setNewOnboardService] = useState({
        name: '',
        price_vnd: 0,
        price_usd: 0,
        description: '',
        status: 'Active',
    })

    const {name, price_vnd, price_usd, description, status} = newOnboardService

    const onChangeNewOnboardServiceForm = event => setNewOnboardService( {...newOnboardService, [event.target.name]: event.target.value } )
    const onChangeNumber = event => setNewOnboardService( {...newOnboardService, [event.target.name]: Number(event.target.value) } )
    const onChangeNumberFloat = event => setNewOnboardService( {...newOnboardService, [event.target.name]: parseFloat(event.target.value) } )

    const onChangeCheckboxLock = event => {
        if(event.target.checked === true){
            setNewOnboardService( {...newOnboardService, [event.target.name]: 'Disabled' } )
        }else{
            setNewOnboardService( {...newOnboardService, [event.target.name]: 'Active' } )
        }
    }

    const onSubmit = async event => {
        event.preventDefault()
        const response = await addOnboardService(newOnboardService)
        if (response.data) {
            closeDialog()
            setShowToast({show: true, message: 'Added sucessful!', type: 'success'})
            return
        }
        setShowToast({show: true, message: response.error && response.error.ErrorCode? response.error.ErrorCode: 'Add failed!', type: 'danger'})
    }

    const closeDialog = () => {
        resetaddOnboardServiceData()
    }

    const resetaddOnboardServiceData = () => {
        setNewOnboardService({
            name: '',
            price_vnd: 0,
            price_usd: 0,
            description: '',
            status: 'Active',
        })
        setShowAddOnboardServiceModal(false)
    }

    return (
        <Modal show={showAddOnboardServiceModal} onHide={closeDialog}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Create New Onboard Service
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group">
                                <label>Name</label>
                                <input type="text" className="form-control" id="name" name="name" value={name} placeholder="Enter Service Name" required aria-describedby='name-help' onChange={onChangeNewOnboardServiceForm} />
                                <Form.Text id='name-help' muted>Required</Form.Text>
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <label>Price (VNĐ)</label>
                                <input type="number" className="form-control" id="price_vnd" name="price_vnd" value={price_vnd} placeholder="0" aria-describedby='price_vnd-help' onChange={onChangeNumber} min="0"/>
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <label>Price (USD)</label>
                                <input type="number" className="form-control" id="price_usd" name="price_usd" value={price_usd} placeholder="0" aria-describedby='price_usd-help' onChange={onChangeNumberFloat} min="0"/>
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group justify-content-end">
                                <label>Disable</label>
                                <div className="checkbox">
                                    <div className="custom-checkbox custom-control">
                                        <input type="checkbox" value={status} className="custom-control-input" name="status" id="status" onChange={onChangeCheckboxLock}/>
                                        <label htmlFor="status" className="custom-control-label mt-1">Yes</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Description</label>
                                <textarea className="form-control" placeholder="Description" rows="3" name='description' defaultValue={description} spellCheck="false" onChange={onChangeNewOnboardServiceForm}></textarea>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                    <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default AddOnboardServiceModal
