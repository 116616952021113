import React, { Fragment, useEffect, useState } from 'react';
import { Form, Dropdown } from 'react-bootstrap';
import Select from 'react-select';
import DeleteCustomerModal from '../../DeleteCustomerModal';
import { CRUISE_TYPES } from '../../../../contexts/constants';
import ItemCustomerTransportation from './ItemCustomerTransportation';
import { useTranslation } from 'react-i18next';

let defaultArrayText = [];
const ItemInforUpdatedCustomer = ({
	tripType,
	booking_id,
	cruise_id,
	assign_to,
	partner_id,
	cruise_itinerary_id,
	start_date,
	index,
	customer,
	handleChangeRoom,
	allRooms,
	roomOptions,
	optionSpecials,
	mrCustoms,
	numberCustomers,
	handleChangeCountry,
	optionCountries,
	handleChangeGender,
	genders,
	setSpecialService,
	customers,
	defaultPriceService,
	setDefaultPriceService,
	adults,
	children11,
	children,
	infants,
	setUpdatedBooking,
	updatedBooking,
	setCustomers,
	moneyType,
	setCustomerTransportAmount
}) => {
	const [updatedCustomer, setUpdatedCustomer] = useState(customer);
	useEffect(() => setUpdatedCustomer(customer), [customer]);

	const { id, full_name, passport_number, email, age, birthday, passport_expired, phone, note } = updatedCustomer;

	const onChangeCustomerText = (index, event) => {
		if (!customers[index]) {
			customers[index] = {};
		}
		const fieldNames = event.target.name.split('-');
		const fieldName = fieldNames[0];
		customers[index][fieldName] = event.target.value;
		setUpdatedCustomer({ ...updatedCustomer, [fieldName]: event.target.value });
	};

	const onChangeCustomerNumber = (index, event) => {
		if (!customers[index]) {
			customers[index] = {};
		}
		const fieldNames = event.target.name.split('-');
		const fieldName = fieldNames[0];
		customers[index][fieldName] = Number(event.target.value);
		setUpdatedCustomer({
			...updatedCustomer,
			[fieldName]: Number(event.target.value)
		});
	};

	useEffect(() => {
		setUpdatedCustomer((delete updatedCustomer['acc_note'], updatedCustomer));
		setUpdatedCustomer((delete updatedCustomer['cabin_desc'], updatedCustomer));
		setUpdatedCustomer((delete updatedCustomer['opt_note'], updatedCustomer));
		setUpdatedCustomer((delete updatedCustomer['sale_note'], updatedCustomer));
		setUpdatedCustomer((delete updatedCustomer['assign_to_name'], updatedCustomer));
		setUpdatedCustomer((delete updatedCustomer['booking_code'], updatedCustomer));
		setUpdatedCustomer((delete updatedCustomer['end_date'], updatedCustomer));
		setUpdatedCustomer((delete updatedCustomer['hold_date'], updatedCustomer));
		setUpdatedCustomer((delete updatedCustomer['hold_time'], updatedCustomer));
		setUpdatedCustomer((delete updatedCustomer['message'], updatedCustomer));
		setUpdatedCustomer((delete updatedCustomer['money_type'], updatedCustomer));
		setUpdatedCustomer((delete updatedCustomer['new_code'], updatedCustomer));
		setUpdatedCustomer((delete updatedCustomer['partner_name'], updatedCustomer));
		setUpdatedCustomer((delete updatedCustomer['status'], updatedCustomer));
		setUpdatedCustomer((delete updatedCustomer['total'], updatedCustomer));
	}, [index]); // eslint-disable-line react-hooks/exhaustive-deps

	const [roomSpecials, setRoomSpecials] = useState(optionSpecials);
	const changeRoom = (data, action) => {
		if (!data) return;
		handleChangeRoom(data, action);
		setRSpecials(data);
	};

	const setRSpecials = (data) => {
		const currentRoomType = data.roomTypeID;
		if (!currentRoomType) {
			setRoomSpecials([]);
			return;
		}
		const rSpecials = optionSpecials.filter((s) => {
			const rTypes = s.roomTypes ? s.roomTypes.split(',') : [];
			if (rTypes.includes(currentRoomType.toString())) {
				return true;
			}
		});

		setRoomSpecials(rSpecials);
	};

	useEffect(() => {
		if (!roomOptions) {
			return;
		}

		const dRoom = roomOptions.find((item) => {
			return item.value === customer.room_id;
		});
		if (!dRoom) {
			return;
		}

		setRSpecials(dRoom);
	}, [roomOptions, optionSpecials]);

	//
	const handleChangeMrCustoms = (newValue, actionMeta) => {
		const dataMrCustom = newValue;
		if (dataMrCustom) {
			var textName = actionMeta.name;
			var theNumber = textName.match(/\d+/)[0];
			if (!customers[theNumber]) {
				customers[theNumber] = {};
			}
			const fieldNames = actionMeta.name.split('-');
			const fieldName = fieldNames[0];
			customers[theNumber][fieldName] = dataMrCustom.value;
			setUpdatedCustomer({
				...updatedCustomer,
				[fieldName]: dataMrCustom.value
			});
		}
	};

	const onChangeLeader = (index, event) => {
		const theNumber = index;
		const fieldName = event.target.name;
		customers[theNumber][fieldName] = 1;
	};

	let arrCabinType = [];

	if (customer.cabin_type) {
		defaultArrayText = [];
		arrCabinType = customer.cabin_type.split(',');
		let defaultArraySpecials = optionSpecials.filter((u) => arrCabinType.includes(String(u.value)));

		if (defaultArraySpecials.length > 0) {
			defaultArraySpecials.forEach((option) => {
				defaultArrayText.push({
					value: option.value,
					label: option.label,
					price: option.price
				});
			});
		}
	} else {
		defaultArrayText = [];
	}

	const calculateSpecialService = () => {
		let specialTotal = 0;
		customers.forEach((customer) => {
			if (customer.cabin_type) {
				const arrCabinType = customer.cabin_type.split(',');
				let defaultArraySpecials = optionSpecials.filter((u) => arrCabinType.includes(String(u.value)));
				defaultArraySpecials.forEach((item) => {
					specialTotal += item.price;
				});
			}
		});

		setSpecialService(specialTotal);
	};

	const handleChangeSpecial = (newValue, actionMeta) => {
		let textMulti = '';
		let specialLables = '';
		let dataSpecial = newValue;
		var textName = actionMeta.name;
		var theNumber = textName.match(/\d+/)[0];
		if (!customers[theNumber]) {
			customers[theNumber] = {};
		}
		customers[theNumber]['specials'] = dataSpecial;
		const fieldNames = actionMeta.name.split('-');
		const fieldName = fieldNames[0];

		if (actionMeta.action === 'select-option') {
			if (dataSpecial.length > 0) {
				dataSpecial.forEach((data, index) => {
					if (index === 0 && textMulti === '') {
						textMulti = String(data.value);
						specialLables = data.label;
					} else {
						textMulti = textMulti + ',' + String(data.value);
						specialLables = specialLables + ',' + data.label;
					}
				});
				customers[theNumber][fieldName] = textMulti;
				customers[theNumber]['cabin_speical_name'] = specialLables;
				calculateSpecialService();
				return;
			}
		}

		if (actionMeta.action === 'remove-value') {
			if (dataSpecial.length > 0) {
				dataSpecial.forEach((data, index) => {
					if (index === 0) {
						textMulti = String(data.value);
						specialLables = data.label;
					} else {
						textMulti = textMulti + ',' + String(data.value);
						specialLables = specialLables + ',' + data.label;
					}
				});
				customers[theNumber][fieldName] = textMulti;
				customers[theNumber]['cabin_speical_name'] = specialLables;
			} else {
				customers[theNumber][fieldName] = '';
				customers[theNumber]['cabin_speical_name'] = '';
			}
			calculateSpecialService();
			return;
		}

		if (actionMeta.action === 'clear') {
			customers[theNumber][fieldName] = '';
			customers[theNumber]['cabin_speical_name'] = '';
			calculateSpecialService();
			return;
		}
	};

	const { t } = useTranslation();
	const colourStyles = {
		control: styles => ({ ...styles, backgroundColor: 'white' }),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			const isAvailableRoom = roomOptions.find((item) => {
				return item.value === data.value;
			});
			return {
			...styles,
			// backgroundColor: isBookedRoom ? 'red' : 'none',
			color: !isAvailableRoom ? 'red' : 'none'
			// cursor: isDisabled ? 'not-allowed' : 'default'
			};
		}
	};

	let body = (
		<tr>
			{tripType === CRUISE_TYPES.NIGHT && (
				<td>
					<div className='select select-mobile'>
						<Select
							isClearable
							value={allRooms.filter((option) =>
								option.value === customer.room_id ? { label: option.label, value: option.value } : ''
							)}
							onChange={changeRoom.bind(this)}
							options={allRooms}
							placeholder={t('Room')}
							name={`room_id-${index}`}
							styles={colourStyles}
						/>
					</div>
				</td>
			)}
			{tripType === CRUISE_TYPES.NIGHT && (
				<td>
					<div className='select select-mobile'>
						<Select
							isClearable
							onChange={handleChangeSpecial.bind(this)}
							options={roomSpecials}
							placeholder={t('Cabin')}
							isMulti={true}
							name={`cabin_type-${index}`}
							defaultValue={defaultArrayText}
						/>
					</div>
				</td>
			)}
			<td>
				<div className='select select-mobile'>
					<Select
						isClearable
						onChange={handleChangeMrCustoms.bind(this)}
						options={mrCustoms}
						placeholder={t('Title')}
						name={`title-${index}`}
						defaultValue={mrCustoms.filter((option) =>
							option.value === customer.title ? { label: option.label, value: option.value } : ''
						)}
					/>
				</div>
			</td>
			<td>
				<input
					type='text'
					className='form-control select-mobile'
					name={`full_name-${index}`}
					value={full_name}
					placeholder={t('Pax') + `  ${index + 1}`}
					onChange={onChangeCustomerText.bind(this, index)}
				/>
			</td>
			<td>
				{customer.leader === 1 ? (
					<Form.Check
						type='radio'
						name={`leader`}
						id='formHorizontalRadios1'
						defaultChecked='defaultChecked'
						value={1}
						onChange={onChangeLeader.bind(this, index)}
					/>
				) : index === 0 ? (
					<Form.Check
						type='radio'
						name={`leader`}
						id='formHorizontalRadios1'
						value={1}
						defaultChecked='defaultChecked'
						onChange={onChangeLeader.bind(this, index)}
					/>
				) : (
					<Form.Check
						type='radio'
						name={`leader`}
						value={0}
						id='formHorizontalRadios1'
						onChange={onChangeLeader.bind(this, index)}
					/>
				)}
			</td>
			<td>
				<ItemCustomerTransportation
					currency={moneyType}
					customers={customers}
					customerIndex={index}
					setCustomerTransportAmount={setCustomerTransportAmount}
					start_date={start_date}
				/>
			</td>
			<td>
				<Dropdown className='info-customer'>
					<Dropdown.Toggle variant='success' id='dropdown-basic'>
						{t('More')} <i className='fe fe-chevron-down horizontal-icon'></i>
					</Dropdown.Toggle>

					<Dropdown.Menu className='content-button' id='content-button'>
						<div className='header-more'>
							<div className='form-group'>
								<div className='row'>
									<div className='col-sm-5'>
										<label>
											<strong>{t('PASSPORT NO') + '.'}</strong>
										</label>
									</div>
									<div className='col-sm-7'>
										<input
											type='text'
											className='form-control'
											name={`passport_number-${index}`}
											value={passport_number}
											placeholder={t('Passport No') + '.'}
											onChange={onChangeCustomerText.bind(this, index)}
										/>
									</div>
								</div>
							</div>
							<div className='form-group'>
								<div className='row'>
									<div className='col-sm-5'>
										<label>
											<strong>{t('Email')}</strong>
										</label>
									</div>
									<div className='col-sm-7'>
										<input
											type='email'
											className='form-control'
											name={`email-${index}`}
											value={email}
											placeholder='abc@abc.com'
											onChange={onChangeCustomerText.bind(this, index)}
										/>
									</div>
								</div>
							</div>
							<div className='form-group'>
								<div className='row'>
									<div className='col-sm-5'>
										<label>
											<strong>{t('Country')}</strong>
										</label>
									</div>
									<div className='col-sm-7'>
										<div className='select select-mobile'>
											<Select
												isClearable
												onChange={handleChangeCountry.bind(this)}
												options={optionCountries}
												placeholder={t('Country')}
												name={`country-${index}`}
												defaultValue={optionCountries.filter((option) =>
													option.value === customer.country
														? { label: option.label, value: option.value }
														: ''
												)}
												className='select-country'
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='form-group'>
								<div className='row'>
									<div className='col-sm-5'>
										<label>
											<strong>{t('Gender')}</strong>
										</label>
									</div>
									<div className='col-sm-7'>
										<Select
											isClearable
											name={`gender-${index}`}
											onChange={handleChangeGender.bind(this)}
											options={genders}
											defaultValue={genders.filter((option) =>
												option.value === customer.gender
													? { label: option.label, value: option.value }
													: ''
											)}
											placeholder={t('Gender')}
										/>
									</div>
								</div>
							</div>
							<div className='form-group'>
								<div className='row'>
									<div className='col-sm-5'>
										<label>
											<strong>{t('Age')}</strong>
										</label>
									</div>
									<div className='col-sm-7'>
										<input
											type='text'
											className='form-control'
											name={`age-${index}`}
											value={age}
											placeholder={t('Age')}
											onChange={onChangeCustomerNumber.bind(this, index)}
										/>
									</div>
								</div>
							</div>
							<div className='form-group'>
								<div className='row'>
									<div className='col-sm-5'>
										<label>
											<strong>{t('Birthday')}</strong>
										</label>
									</div>
									<div className='col-sm-7'>
										<input
											type='date'
											className='form-control'
											placeholder='mm/dd/yyyy'
											value={birthday}
											name={`birthday-${index}`}
											onChange={onChangeCustomerText.bind(this, index)}
										/>
									</div>
								</div>
							</div>
							<div className='form-group'>
								<div className='row'>
									<div className='col-sm-5'>
										<label>
											<strong>{t('Visa Expiration')}</strong>
										</label>
									</div>
									<div className='col-sm-7'>
										<input
											type='date'
											className='form-control'
											placeholder='mm/dd/yyyy'
											value={passport_expired}
											name={`passport_expired-${index}`}
											onChange={onChangeCustomerText.bind(this, index)}
										/>
									</div>
								</div>
							</div>
							<div className='form-group'>
								<div className='row'>
									<div className='col-sm-5'>
										<label>
											<strong>{t('Phone')}</strong>
										</label>
									</div>
									<div className='col-sm-7'>
										<input
											type='tel'
											className='form-control'
											placeholder={t('Phone Number')}
											value={phone}
											name={`phone-${index}`}
											onChange={onChangeCustomerText.bind(this, index)}
										/>
									</div>
								</div>
							</div>
							<div className='form-group'>
								<div className='row'>
									<div className='col-sm-5'>
										<label>
											<strong>{t('Notes')}</strong>
										</label>
									</div>
									<div className='col-sm-7'>
										<textarea
											rows='3'
											className='form-control'
											placeholder={t('Notes')}
											name={`note-${index}`}
											value={note}
											onChange={onChangeCustomerText.bind(this, index)}
										></textarea>
									</div>
								</div>
							</div>
						</div>
					</Dropdown.Menu>
				</Dropdown>
			</td>
			<td>
				<DeleteCustomerModal
					id={id}
					adults={adults}
					children11={children11}
					children={children}
					infants={infants}
					setUpdatedBooking={setUpdatedBooking}
					updatedBooking={updatedBooking}
					customers={customers}
					setCustomers={setCustomers}
					index={index}
				/>
			</td>
		</tr>
	);

	return <Fragment>{body !== null && body}</Fragment>;
};

export default ItemInforUpdatedCustomer;
