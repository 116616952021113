import { Fragment, useContext, useEffect, useState } from "react";
import { AuthContext } from '../../contexts/AuthContext';
import { RoleContext } from '../../contexts/RoleContext';
import { Button, Form, Modal } from "react-bootstrap";
import Select from 'react-select';
import { apiUrl } from "../../contexts/constants";
import { postAPI } from "../../utils/api";
import { UserRoleContext } from '../../contexts/UserRoleContext';

const UpdateUserRole = ( { editUserID, users, userRoles, setEditUserID } ) => {

    // Context
    const {
        showUpdateUserRoleModal,
        setShowUpdateUserRoleModal,
        setShowToast
    } = useContext(AuthContext)

    const {
        setUserRoles,
    } = useContext(UserRoleContext)

    const [currentUID, setCurrentUID] = useState(editUserID)
    const [currentUserRoles, setCurrentUserRoles] = useState([])

    const [updatedRole, setUpdatedRole] = useState({
        user_id: Number(editUserID)
    })

    const {
        roleState: { roles },
    } = useContext(RoleContext)

    useEffect( () => {
        setCurrentUID(editUserID)
    }, [editUserID])

    // Start: Get all specials , []
    useEffect( () => {
        if(userRoles){
            let arrText = []
            userRoles.map((userIdRole) => {
                if(Number(userIdRole.user_id) === Number(currentUID)){
                    arrText.push(Number(userIdRole.role_id));
                }
            })
            setCurrentUserRoles(arrText)
        }
        setUpdatedRole( {...updatedRole, user_id: Number(currentUID) } )
    }, [currentUID]) // eslint-disable-line react-hooks/exhaustive-deps

    let optionRoles = []
    let optionUsers = []

    if(roles){
        roles.map((role) =>
            optionRoles = [ ...optionRoles,
                {
                    value: role.id,
                    label: role.display_name
                }
            ]
        )
    }

    if(users && users.length > 0){
        users.map((userRole) =>
            optionUsers = [ ...optionUsers,
                {
                    value: userRole.id,
                    label: userRole.name
                }
            ]
        )
    }

    const handleChangeChk = (e) => {

        let updatedList = [...currentUserRoles];
        // if (checked) { <- this part, checked is the array of checked item right?
        if(e.target.checked) {
            updatedList = [...currentUserRoles, Number(e.target.value)];
        } else {
            updatedList.splice(currentUserRoles.indexOf(Number(e.target.value)), 1);
        }
        setCurrentUserRoles(updatedList);
    }

    const handleChange = (newValue, actionMeta) => {
        const dataOption = newValue;
        if(dataOption) {
            // if(actionMeta.name === 'sales'){
                // setUpdatedRole( {...updatedRole, user_id: Number(dataOption.value) } )
            // }
            setCurrentUID(Number(dataOption.value))
        }

    };

    const updateUserRole = async newPermission => {
        const response = await postAPI(`${apiUrl}/user-role/update-multiple`, newPermission)
        return response
    }

    const onSubmit = async event => {
        event.preventDefault()
        updatedRole['role_ids'] = currentUserRoles
        const response = await updateUserRole(updatedRole)
        if (response.error) {
            setShowToast({show: true, message: response.error && response.error.ErrorCode? response.error.ErrorCode: 'Update failed!', type: 'danger'})
            return
        }
        
        let newUserRoles = []
        // set user roles
        userRoles.forEach(userRole => {
            if (userRole.user_id == updatedRole.user_id) {
                return;
            }
            newUserRoles.push(userRole);
        });
        currentUserRoles.forEach(roleId => {
            const userRole = {
                user_id: updatedRole.user_id,
                role_id: roleId,
            };
            newUserRoles.push(userRole);
        });
        setUserRoles(newUserRoles)
        
        closeDialog()
        setShowToast({show: true, message: 'Updated successful!', type: 'success'})
    }

    const closeDialog = () => {
        setCurrentUserRoles([])
        setEditUserID(0)
        setShowUpdateUserRoleModal(false)
    }

    return (
        <Fragment>
            <Modal show={showUpdateUserRoleModal} onHide={closeDialog}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Update User Role
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={onSubmit}>
                    <Modal.Body>
                        <div className="form-group">
                            <label><strong>User</strong></label>
                            <Select isClearable
                                defaultValue={
                                    optionUsers.filter(option =>
                                        Number(option.value) === Number(editUserID) ? {label: option.label, value: option.value} : ''
                                    )
                                }
                                name="sales"
                                onChange={handleChange.bind(this)}
                                options={optionUsers}
                                placeholder="Choose User"
                                menuPosition={'fixed'}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                        </div>
                        <div className="form-group">
                            <label><strong>Roles</strong></label>
                            <div className="row">
                                {roles && roles.map((permission, index) => (
                                    <div key={index} className="col-md-4">
                                        <div className="checkbox">
                                            <div className="custom-checkbox custom-control">
                                                <input type="checkbox" data-checkboxes="mygroup" name="permission_id" className="custom-control-input" id={`checkbox-${index+1}`} value={permission.id} onChange={handleChangeChk.bind(this)} checked={currentUserRoles.find((t) => t === permission.id ? true : false)}  />
                                                <label htmlFor={`checkbox-${index+1}`} className="custom-control-label mt-1">{permission.display_name}</label>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> Cancel</Button>
                        <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> Save!!!</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default UpdateUserRole
