import { Fragment, useContext, useState, useEffect } from 'react';
import { SpecialContext } from '../../contexts/SpecialContext';
import { CruiseContext } from '../../contexts/CruiseContext';
import { RoomTypeContext } from '../../contexts/RoomTypeContext';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import { apiUrl, CRUISE_TYPES } from '../../contexts/constants';
import { getAPI } from '../../utils/api';
import { getOptions, moneyOptions, findOptionItem, findOptionItems } from '../../utils/common';
import { useTranslation } from 'react-i18next';

const UpdateCabinModal = () => {
	const { t } = useTranslation();
	const [showAlert, setShowAlert] = useState(false);
	const [textContent, setTextContent] = useState(null);

	const {
		specialState: { special },
		showUpdateSpecialModal,
		showAddSpecialModal,
		setShowUpdateSpecialModal,
		setShowAddSpecialModal,
		updateSpecial,
		addSpecial,
		setShowToast,
		clearSpecial
	} = useContext(SpecialContext);

	let optionCruises = [];
	let optionItineraries = [];
	let optionRoomTypes = [];

	const [arrItineraries, setArrItineraries] = useState([]);
	const [cruiseID, setCruiseID] = useState(special ? special.cruise_id : null);

	const {
		cruiseState: { cruises }
	} = useContext(CruiseContext);
	const {
		roomTypeState: { roomTypes }
	} = useContext(RoomTypeContext);

	// Start: Get all cruise , []
	useEffect(() => {
		async function itineraryData() {
			try {
				const response = await getAPI(
					`${apiUrl}/cruise-itineraries`,
					`filterFields=cruise_id&filterFieldValues=${cruiseID}`
				);
				setArrItineraries(response.data);
			} catch (error) {
				return error.response ? error.response : { success: false, message: t('Server error!') };
			}
		}
		itineraryData();
	}, [cruiseID]); // eslint-disable-line react-hooks/exhaustive-deps

	// State
	const [updatedSpecial, setUpdatedSpecial] = useState(special ? special : {});
	useEffect(() => {
		setUpdatedSpecial(special ? special : {});
		setCruiseID(special ? special.cruise_id : null);
	}, [special]);

	const { id, name, room_types, price, cruise_id, duration, money_type } = updatedSpecial;

	const onChangeName = (event) =>
		setUpdatedSpecial({
			...updatedSpecial,
			[event.target.name]: event.target.value
		});
	const onChangeNumber = (event) =>
		setUpdatedSpecial({
			...updatedSpecial,
			[event.target.name]: Number(event.target.value)
		});

	optionCruises = getOptions(cruises, ['label', 'value', 'typeDay'], ['name', 'id', 'typeDay']).filter((item) => {
		return item.typeDay === CRUISE_TYPES.NIGHT;
	});
	optionItineraries = getOptions(arrItineraries, ['label', 'value'], ['name', 'id']);
	optionRoomTypes = getOptions(roomTypes, ['label', 'value'], ['name', 'id']);

	const onSubmit = async (event) => {
		event.preventDefault();
		if (!cruise_id) {
			setTextContent(t('Please choose cruise!'));
			setShowAlert(true);
			return;
		}
		if (!duration) {
			setTextContent(t('Please choose duration!'));
			setShowAlert(true);
			return;
		}
		if (!money_type) {
			setTextContent(t('Please Choose Money Type!'));
			setShowAlert(true);
			return;
		}
		if (!room_types) {
			setTextContent(t('Please Choose Room Type!'));
			setShowAlert(true);
			return;
		}

		// add cabin special
		if (showAddSpecialModal) {
			delete updatedSpecial.id;
			const response = await addSpecial(updatedSpecial);
			if (response.data) {
				closeDialog();
				setShowToast({ show: true, message: t('Added successful!'), type: 'success' });
				return;
			}
			setShowToast({
				show: true,
				message: response.error && response.error.ErrorCode ? response.error.ErrorCode : t('Add failed!'),
				type: 'danger'
			});
			return;
		}

		// update cabin special
		const response = await updateSpecial(updatedSpecial);
		if (response.status === 200) {
			closeDialog();
			setShowToast({
				show: true,
				message: t('Updated successful!'),
				type: 'success'
			});
			return;
		}
		setShowToast({
			show: true,
			message: response.error && response.error.ErrorCode ? response.error.ErrorCode : t('Update failed!'),
			type: 'danger'
		});
	};

	const closeDialog = () => {
		clearSpecial();
		setShowUpdateSpecialModal(false);
		setShowAddSpecialModal(false);
	};

	// select cruise
	const handleChange = (newValue, actionMeta) => {
		if (!newValue) {
			return;
		}

		switch (actionMeta.name) {
			case 'cruiseID':
				setUpdatedSpecial({
					...updatedSpecial,
					cruise_id: Number(newValue.value),
					duration: 0
				});
				setCruiseID(Number(newValue.value));
				break;
			case 'duration':
				setUpdatedSpecial({
					...updatedSpecial,
					duration: Number(newValue.value)
				});
				break;
			case 'money_type':
				setUpdatedSpecial({
					...updatedSpecial,
					money_type: newValue.value
				});
				break;
			case 'room_types':
				const roomTypeIds = newValue.map((item) => {
					return item.value;
				});
				const strRoomTypeIDs = roomTypeIds.join(',');
				setUpdatedSpecial({
					...updatedSpecial,
					room_types: strRoomTypeIDs
				});
				break;
			default:
				break;
		}
	};

	const AlertDismissible = () => {
		setTimeout(function () {
			setShowAlert(false);
		}, 3000);
		return (
			<Alert variant='danger'>
				{textContent !== null ? (
					<p>
						<strong>{textContent}</strong>
					</p>
				) : null}
			</Alert>
		);
	};

	return (
		<Fragment>
			<Modal
				show={showUpdateSpecialModal || showAddSpecialModal}
				onHide={closeDialog}
				size='lg'
				aria-labelledby='contained-modal-title-vcenter'
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title> {showUpdateSpecialModal ? t('Update Special Cabin') : t('Add Special Cabin')} </Modal.Title>
				</Modal.Header>
				<Form onSubmit={onSubmit}>
					<Modal.Body>
						{showAlert ? <AlertDismissible /> : null}
						<div className='form-group'>
							<input type='hidden' name='id' value={id} />
							<Row>
								<Col>
									<div className='form-group'>
										<label>
											<strong>{t('Name')}</strong>
										</label>
										<input
											className='form-control'
											placeholder={t('Enter Special Cabin Name')}
											name='name'
											value={name}
											onChange={onChangeName}
											required
										/>
									</div>
								</Col>
								<Col>
									<div className='form-group'>
										<label>
											<strong>{t('Room Types')}</strong>
										</label>
										<Select
											isClearable
											name='room_types'
											onChange={handleChange.bind(this)}
											options={optionRoomTypes}
											placeholder={t('Choose Room Type')}
											value={findOptionItems(optionRoomTypes, room_types)}
											isMulti
										/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className='form-group'>
										<label>
											<strong>{t('Cruise')}</strong>
										</label>
										<Select
											isClearable
											name='cruiseID'
											onChange={handleChange.bind(this)}
											options={optionCruises}
											placeholder={t('Choose Cruise')}
											value={findOptionItem(optionCruises, cruise_id)}
										/>
									</div>
								</Col>
								<Col>
									<div className='form-group'>
										<label>
											<strong>{t('Duration')}</strong>
										</label>
										<Select
											isClearable
											name='duration'
											onChange={handleChange.bind(this)}
											options={optionItineraries}
											placeholder={t('Choose Duration')}
											value={findOptionItem(optionItineraries, updatedSpecial.duration)}
										/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className='form-group'>
										<label>
											<strong>{t('Money Type')}</strong>
										</label>
										<Select
											isClearable
											name='money_type'
											onChange={handleChange.bind(this)}
											options={moneyOptions}
											placeholder={t('Choose Money Type')}
											value={findOptionItem(moneyOptions, updatedSpecial.money_type)}
										/>
									</div>
								</Col>
								<Col>
									<div className='form-group'>
										<label>
											<strong>{t('Price')}</strong>
										</label>
										<input
											className='form-control'
											placeholder={t('No. Price')}
											name='price'
											value={price}
											onChange={onChangeNumber}
											required
										/>
									</div>
								</Col>
							</Row>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button className='btn btn-danger btn-with-icon' variant='secondary' onClick={closeDialog}>
							<i className='fe fe-x-circle'></i> {t('Cancel')}
						</Button>
						<Button className='btn btn-primary btn-with-icon' variant='primary' type='submit'>
							<i className='fe fe-save'></i> {t('Save')}!!!
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</Fragment>
	);
};

export default UpdateCabinModal;
