import { Fragment, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import NumberFormat from 'react-number-format';
import RevenueDate from '../includes/RevenueDate';
import { getBookingCellBackground } from '../../../utils/common';
import { Collapse } from 'react-bootstrap';
import { getTable, getTableOptions, getDefaultSort } from '../../../utils/table';
import { useTranslation } from 'react-i18next';

const RevenueTable = (props) => {
	const { t } = useTranslation();
	const {
		authState: { user }
	} = useContext(AuthContext);

	const bookings = props.bookingsProps;
	const originalBookings = props.oBookings;
	const getNameDuration = props.getNameDuration;
	const setNewBookings = props.setNewBookingFunc;
	const getChildLabelSetting = props.getChildLabelSetting;

	const [fromDate, setFromDate] = useState(props.fromDate);
	const [endDate, setEndDate] = useState(props.endDate);

	useEffect(() => {
		setFromDate(props.fromDate);
	}, [props.fromDate]);
	useEffect(() => {
		setEndDate(props.endDate);
	}, [props.endDate]);

	const onChangeFrom = (date) => {
		setFromDate(date);
		getNewBookings();
	};
	const onChangeEnd = (date) => {
		setEndDate(date);
		getNewBookings();
	};

	const applyFilter = () => {
		getNewBookings();
	};

	const getNewBookings = () => {
		const startDate = Date.parse(fromDate);
		const toDate = Date.parse(endDate);
		if (startDate > toDate) {
			alert('To date must be greater than or equal to from date');
			return;
		}
		const newBookings = originalBookings.filter((booking) => {
			const updatedDate = Date.parse(booking.updated_at);
			return updatedDate >= startDate && updatedDate <= toDate;
		});
		setNewBookings(newBookings);
	};

	const resetFilter = () => {
		setFromDate(props.fromDate);
		setEndDate(props.endDate);
		setNewBookings(originalBookings);
	};

	let body = null;
	const [showFilter, setShowFilter] = useState(false);
	const filterOnClick = () => {
		setShowFilter(!showFilter);
	};

	const revenueTable = getRevenueTable(bookings, getNameDuration, t, getChildLabelSetting);

	body = (
		<div>
			<div className='row'>
				<div className='col-md-3'>
					<button className='btn btn-primary dropdown-toggle' onClick={filterOnClick.bind(this)}>
						{t('Filter Modified Bookings')} <i className='fe fe-chevron-right'></i>
					</button>
				</div>
				<Collapse className='col-md-9' in={showFilter}>
					<div className='row'>
						<div className='col-md-6'>
							<div className='row'>
								<RevenueDate
									fromDateProps={fromDate}
									endDateProps={endDate}
									onChangeFromProps={onChangeFrom}
									onChangeEndProps={onChangeEnd}
								/>
							</div>
						</div>
						<div className='col-md-6'>
							<button className='btn btn-success btn-reset-filter' onClick={applyFilter}>
								{t('Apply Filter')}
							</button>
							<button className='btn btn-info btn-reset-filter' onClick={resetFilter}>
								{t('Reset Filter')}
							</button>
						</div>
					</div>
				</Collapse>
			</div>
			<hr></hr>
			{revenueTable}
		</div>
	);

	return <Fragment>{body ? body : null}</Fragment>;
};

const getRevenueTableData = (bookings, getNameDuration, t, getChildLabelSetting) => {
	return bookings.map((booking) => {
		let paidAmount = 0;
		let balance = 0;
		let netIncome = 0;
		let bk = {};
		let totalAll = booking.total;

		if (booking.total_payment > 0) {
			paidAmount = booking.total_payment;
			balance = booking.total - booking.total_payment;

			if (bookings.com_year !== undefined) {
				netIncome = booking.total - booking.com_year;
			} else {
				netIncome = booking.total;
			}

			if (booking.discount > 0) {
				totalAll = totalAll + booking.discount;
			}
		} else {
			balance = booking.total;

			if (booking.com_year !== undefined) {
				netIncome = booking.total - booking.com_year;
			} else {
				netIncome = booking.total;
			}

			if (booking.discount > 0) {
				totalAll = totalAll + booking.discount;
			}
		}
		bk.id = booking.id;
		bk.new_code = booking.new_code;
		bk.code = (
			<Link
				to={`/bookings/show/${booking.id}`}
				target='_blank'
				className={getBookingCellBackground(booking.status, getNameDuration(booking.cruise_itinerary_id))}
			>
				{booking.new_code ? booking.new_code : booking.id}
			</Link>
		);
		bk.partner_name = booking.partner_name;
		bk.start_date = Moment(booking.start_date).format('MMMM D, YYYY');
		bk.end_date = Moment(booking.end_date).format('MMMM D, YYYY');
		bk.adults = booking.adults;

		const cruiseSetting = getChildLabelSetting(booking.cruise_id)

		bk.child =
			(booking.children11 ? `${booking.children11} [${cruiseSetting && cruiseSetting.child_lable2? t(cruiseSetting.child_lable2): '8-11'}]` : '') +
			', ' +
			(booking.children ? `${booking.children} [${cruiseSetting && cruiseSetting.child_label1? t(cruiseSetting.child_label1): '5-7'}]` : '');
		bk.infants = booking.infants;
		bk.numDays =
			getNameDuration(booking.cruise_itinerary_id) > 1
				? getNameDuration(booking.cruise_itinerary_id) + 'N' + (getNameDuration(booking.cruise_itinerary_id) - 1) + 'D'
				: getNameDuration(booking.cruise_itinerary_id) + 'N';
		bk.pickup = <div dangerouslySetInnerHTML={{ __html: booking.pickup }} />;

		booking.money_type === 'usd'
			? (bk.total = (
					<NumberFormat
						value={totalAll ? totalAll : 0}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={2}
						prefix={'$'}
					/>
			  ))
			: (bk.total = (
					<NumberFormat
						value={totalAll ? totalAll : 0}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={0}
						prefix={'VNĐ '}
					/>
			  ));
		booking.money_type === 'usd'
			? (bk.net = (
					<NumberFormat
						value={netIncome ? netIncome : 0}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={2}
						prefix={'$'}
					/>
			  ))
			: (bk.net = (
					<NumberFormat
						value={netIncome ? netIncome : 0}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={0}
						prefix={'VNĐ '}
					/>
			  ));
		booking.money_type === 'usd'
			? (bk.discount = (
					<NumberFormat
						value={booking.discount > 0 ? booking.discount : 0}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={2}
						prefix={'$'}
					/>
			  ))
			: (bk.discount = (
					<NumberFormat
						value={booking.discount > 0 ? booking.discount : 0}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={0}
						prefix={'VNĐ '}
					/>
			  ));
		booking.money_type === 'usd'
			? (bk.extra = (
					<NumberFormat
						value={booking.extra_service > 0 ? booking.extra_service : 0}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={2}
						prefix={'$'}
					/>
			  ))
			: (bk.extra = (
					<NumberFormat
						value={booking.extra_service > 0 ? booking.extra_service : 0}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={0}
						prefix={'VNĐ '}
					/>
			  ));
		booking.money_type === 'usd'
			? (bk.com = (
					<NumberFormat
						value={booking.comYear > 0 ? booking.comYear : 0}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={2}
						prefix={'$'}
					/>
			  ))
			: (bk.com = (
					<NumberFormat
						value={booking.comYear > 0 ? booking.comYear : 0}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={0}
						prefix={'VNĐ '}
					/>
			  ));
		booking.money_type === 'usd'
			? (bk.paid = (
					<NumberFormat
						value={Number(paidAmount)}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={2}
						prefix={'$'}
					/>
			  ))
			: (bk.paid = (
					<NumberFormat
						value={paidAmount ? paidAmount : 0}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={0}
						prefix={'VNĐ '}
					/>
			  ));
		booking.money_type === 'usd'
			? (bk.balance = (
					<NumberFormat
						value={balance ? Number(balance) : 0}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={2}
						prefix={'$'}
					/>
			  ))
			: (bk.balance = (
					<NumberFormat
						value={balance ? balance : 0}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={0}
						prefix={'VNĐ '}
					/>
			  ));
		bk.note = <div dangerouslySetInnerHTML={{ __html: booking.acc_note }} />;
		bk.cruise = booking.cruise_name;
		bk.assign = booking.assign_to_name;

		return bk;
	});
};

const getRevenueColumns = (t) => {
	return [
		{
			dataField: 'id',
			text: 'ID',
			hidden: true
		},
		{
			dataField: 'code',
			text: t('Code'),
			sort: true
		},
		{
			dataField: 'new_code',
			text: t('New Code'),
			hidden: true
		},
		{
			dataField: 'partner_name',
			text: t('Partner'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'start_date',
			text: t('Depar'),
			sort: true
		},
		{
			dataField: 'end_date',
			text: t('Return'),
			sort: true
		},
		{
			dataField: 'adults',
			text: t('Adults'),
			sort: true,
			classes: 'td-right'
		},
		{
			dataField: 'child',
			text: t('Child'),
			sort: true,
			classes: 'td-right'
		},
		{
			dataField: 'infants',
			text: t('Infant'),
			sort: true,
			classes: 'td-right'
		},
		{
			dataField: 'numDays',
			text: t('No. Day'),
			sort: true
		},
		{
			dataField: 'pickup',
			text: t('Pick/Drop'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'total',
			text: t('Total'),
			sort: true,
			classes: 'td-right'
		},
		{
			dataField: 'net',
			text: t('NET'),
			sort: true,
			classes: 'td-right'
		},
		{
			dataField: 'discount',
			text: t('Discount'),
			sort: true,
			classes: 'td-right'
		},
		{
			dataField: 'extra',
			text: t('Extra'),
			sort: true,
			classes: 'td-right'
		},
		{
			dataField: 'com',
			text: t('Com'),
			sort: true,
			classes: 'td-right'
		},
		{
			dataField: 'paid',
			text: t('Paid'),
			sort: true,
			classes: 'td-right'
		},
		{
			dataField: 'balance',
			text: t('Balance'),
			sort: true,
			classes: 'td-right'
		},
		{
			dataField: 'note',
			text: t('Note'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'cruise',
			text: t('Cruise'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'assign',
			text: t('Assign'),
			sort: true,
			classes: 'td-left'
		}
	];
};

const getRevenueTable = (bookings, getNameDuration, t, getChildLabelSetting) => {
	if (!bookings || bookings.length === 0) return null;
	const tableData = getRevenueTableData(bookings, getNameDuration, t, getChildLabelSetting);
	const columns = getRevenueColumns(t);
	const defaultSorted = getDefaultSort();
	const options = getTableOptions(tableData.length, t);

	return getTable(tableData, columns, defaultSorted, options, 'revenue-table');
};

export default RevenueTable;
