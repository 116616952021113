import { Fragment, useContext, useEffect } from 'react';
import { RoomContext } from '../../../contexts/RoomContext';
import NightCheckin from './NightCheckin';

const ListOnboardBooking = props => {

    const cruise = props.cruiseProps
    const month = props.monthProps
    const year = props.yearProps

    const {
        roomState: { rooms },
        getRooms
    } = useContext(RoomContext)

    let body = null

    // Start: Get all rooms , []
    useEffect( () => {
        if(cruise && cruise.id){
            getRooms(`?cruise_id=${cruise.id}`)
        }
    }, [cruise]) // eslint-disable-line react-hooks/exhaustive-deps


    if(cruise){

        body = (
            <div className="ajax-booking-cruise ajax-booking-cruise-onboard">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="table-info-cruise mg-b-20">
                            <div className="table-info-cruise-header pb-0">
                                <div className="d-flex justify-content-between">
                                    <h3 className="table-info-cruise-title mg-b-15">{cruise.name}</h3>
                                </div>
                            </div>
                            <div className="table-info-cruise-body">
                                <div className="table-responsive">
                                    <NightCheckin cruiseProps={cruise} roomsProps={rooms} monthProps={month} yearProps={year} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }




    return (
        <Fragment>
            {body}
        </Fragment>
    )
}

export default ListOnboardBooking
