import { Fragment, useContext, useEffect, useState } from 'react';
import { CruiseContext } from '../../contexts/CruiseContext';
import {Helmet} from "react-helmet";
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import DebtTable from '../debts/DebtTable';
import { apiUrl, ROLES, NO_PERMISSION } from '../../contexts/constants';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { getAPI } from '../../utils/api';
import { AuthContext } from '../../contexts/AuthContext'
import { useTranslation } from 'react-i18next';
import { findOptionItem, getYearOptions, sort } from '../../utils/common';

const Debt = () => {
    const { t } = useTranslation();
    const {
        authState: {user }
    } = useContext(AuthContext)

    const {
        cruiseState: { cruise },
        FindACruise
    } = useContext(CruiseContext)

    const [bookings, setBookings] = useState([])

    let defaultStatus = ['Confirmed', 'Pending', 'Transferred', 'Inspection', 'Charter', 'UpTo']

    let idCruise = 0

    let param1 = window.location.pathname;
    let arrUrl = param1.split('/')
    let month = 0;
    let year = 0;


    if(arrUrl !== null){
        var arrInfo = arrUrl[3].split('-')
        month = arrInfo[0]
        year = arrInfo[1]
        idCruise = arrInfo[2]
    }

    let daysInMonths = new Date(year, month, 0).getDate();

    let fromDate = `${year}-${month}-01`
    let endDate = `${year}-${month}-${daysInMonths}`
    const [currentMonth, setCurrentMonth] = useState(month)
    const [currentYear, setCurrentYear] = useState(year)

    useEffect( () => {
        if(idCruise) {
            FindACruise(idCruise)
        }
    }, [idCruise] ) // eslint-disable-line react-hooks/exhaustive-deps

    let arrMonths = []
    let textMonth = ''
    let textYear = ''
    let cruiseName = ''

    if(cruise){
        cruiseName = cruise.name
    }

    let months = [t('January'),t('February'),t('March'),t('April'), t('May'), t('June'), t('July'), t('August'), t('September'), t('October'), t('November'), t('December')]

    // eslint-disable-next-line no-lone-blocks
    {Array.from(Array(12), (e, i) => {
        if((i + 1) === Number(month)){
            textMonth = months[i]
        }
        arrMonths = [...arrMonths,
            {
                value: (i + 1),
                label: months[i]
            }
        ]
    })}

    arrMonths.unshift({
        value: 'all',
        label: t('All')
    });

    const arrYears = getYearOptions();
    const [partnerOriginalData, setPartnerOriginalData] = useState();
    const [partnerRevenues, setPartnerRevenues] = useState([])
    useEffect(() => {
        async function bookingData(){
            try {
                const dataArr = []
                let filterCondition = `?year=${currentYear}&month=${currentMonth}&cruise_id=${idCruise}`
                const response = await getAPI(`${apiUrl}/report/partner-revenue`, `${filterCondition}`)
                if (response.data) {
                    setPartnerOriginalData(response.data);
                } else {
                    setPartnerRevenues([]);
                }
            } catch (error) {
                setPartnerRevenues([]);
                return error.response ? error.response : {success: false, message: 'Server error!'}
            }
        }
        bookingData()
    }, [currentYear, currentMonth, idCruise]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeDay = (newValue, actionMeta) => {
        const dataDay = newValue;
        if (!dataDay) return;
        if(actionMeta.name === `currentMonth` ){
            if (dataDay.value == 'all') {
                setCurrentMonth('all');
                return;
            }
            var textMonth = ("0" + dataDay.value).slice(-2);
            setCurrentMonth(textMonth)

            return;
        }

        if(actionMeta.name === `currentYear` ){
            setCurrentYear(dataDay.value)
        }
    }

    const openLinkNewCruise = () => {
        var newPageUrl = `/accounting/debts/${currentMonth}-${currentYear}-${idCruise}`
        window.open(newPageUrl, "_blank")
    }

    const preparePartnerRevenue = (partnerRevenues) => {
        let data = {};
        partnerRevenues.forEach((item) => {
            if (!data[item.partner_id]) {
                data[item.partner_id] = {
                    partner_id: item.partner_id,
                    totalVND: 0,
                    totalUSD: 0,
                    totalPax: 0,
                    bookingCount: 0,
                    comVNDValue: 0,
                    comUSDValue: 0,
                    depositVNDValue: 0,
                    depositUSDValue:0,
                    discountVNDValue: 0,
                    discountUSDValue: 0
                };
            }

            data[item.partner_id].partner_name = item.partner_name;
            data[item.partner_id].asign_to_name = item.assign_name;
            data[item.partner_id].totalVND += item.money_type == 'vnd'? item.revenue: 0;
            data[item.partner_id].totalUSD += item.money_type == 'usd'? item.revenue: 0;
            data[item.partner_id].totalPax += item.pax_total;
            data[item.partner_id].bookingCount += item.booking_count;
            data[item.partner_id].comVNDValue += item.money_type == 'vnd'? item.com_value: 0;
            data[item.partner_id].depositVNDValue += item.money_type == 'vnd'? item.deposit_value: 0;
            data[item.partner_id].discountVNDValue += item.money_type == 'vnd'? item.discount_value: 0;
            data[item.partner_id].comUSDValue += item.money_type == 'usd'? item.com_value: 0;
            data[item.partner_id].depositUSDValue += item.money_type == 'usd'? item.deposit_value: 0;
            data[item.partner_id].discountUSDValue += item.money_type == 'usd'? item.discount_value: 0;
        });

        const partnerRecords = Object.values(data);

        sort(partnerRecords, 'totalPax');
        partnerRecords.reverse();
        setPartnerRevenues(partnerRecords);        
    }

    useEffect(() => {
        if (!partnerOriginalData || !partnerOriginalData.length) return;
        preparePartnerRevenue(partnerOriginalData)
    }, [partnerOriginalData])

    if (!user) {
        return NO_PERMISSION
    }

    if (!(user.Roles.includes(ROLES.OPT) || user.Roles.includes(ROLES.ACC) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))) {
        return NO_PERMISSION
    }

    const customStyles = {
		menuPortal: (provided) => ({ ...provided, zIndex: 5 }),
	};

    return (
        <Fragment>
            <Helmet>
                <title>{t('Debts of Agents')} {month} - {year} - {cruiseName}</title>
            </Helmet>
            <div className="horizontal-mainwrapper container-fluid clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-3">
                        <Row className='sel-month-year' md={4}>
                            <Col xs={2}>
                                <div className="form-group">
                                    <label><strong>{t('Month')}</strong></label>
                                    <Select isClearable
                                        name="currentMonth"
                                        defaultValue={{ label: textMonth, value: month }}
                                        onChange={handleChangeDay.bind(this)}
                                        options={arrMonths}
                                        placeholder={t("Month")}
                                        menuPosition={'fixed'}
                                        styles={customStyles}
                                    />
                                </div>
                            </Col>
                            <Col xs={2}>
                                <div className="form-group">
                                    <label><strong>{t('Year')}</strong></label>
                                    <Select isClearable
                                        name="currentYear"
                                        defaultValue={findOptionItem(arrYears, currentYear)}
                                        onChange={handleChangeDay.bind(this)}
                                        options={arrYears}
                                        placeholder={t("Year")}
                                        menuPosition={'fixed'}
                                        styles={customStyles}
                                    />
                                </div>
                            </Col>
                            <Col xs={2}>
                                <div className="form-group">
                                    <button className="btn btn-primary btn-block mt-17" onClick={openLinkNewCruise.bind(this)}><i className="fe fe-corner-down-right"></i> {t('Go')}</button>
                                </div>
                            </Col>
                            <Col xs={2}>
                                <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="btn btn-primary float-right"
                                        table="debt-table"
                                        filename={`Debts of Agents ${cruiseName} in ${month} - ${year}`}
                                        sheet="sheet 1"
                                        buttonText={t(`Export to Excel`)}/>
                            </Col>
                        </Row>
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <h3 className="pull-left">{t('Debts of Agents')} {month} - {year} - {cruiseName}</h3>
                            </div>
                            <div className="col-md-3 ml-auto">
                                {/* <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="btn btn-primary float-right"
                                        table="debt-table"
                                        filename={`Debts of Agents ${cruiseName} in ${month} - ${year}`}
                                        sheet="sheet 1"
                                        buttonText={`Export to Excel`}/> */}
                            </div>
                        </div>
                        <hr className="mt-3" />
                        <DebtTable bookingsProps={partnerRevenues} />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Debt
