import { useContext, useState, useEffect } from 'react';
import { TransportationRouteContext } from '../../contexts/TransportationRouteContext';
import { Button, Form, Modal } from 'react-bootstrap';
import { TransportationPickUpContext } from '../../contexts/TransportationPickUpContext';
import { getOptions, findOptionItem, moneyOptions } from '../../utils/common';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const UpdateTransportationRouteModal = () => {
	const { t } = useTranslation();
	const {
		transportationRouteState: { transportationRoute },
		showUpdateTransportationRouteModal,
		showAddTransportationRouteModal,
		setShowAddTransportationRouteModal,
		setShowUpdateTransportationRouteModal,
		updateTransportationRoute,
		addTransportationRoute,
		setShowToast,
		clearTransportationRoute
	} = useContext(TransportationRouteContext);
	const {
		transportationPickUpState: { transportationPickUps },
		getTransportationPickUps
	} = useContext(TransportationPickUpContext);

	useEffect(() => {
		if (!transportationPickUps || !transportationPickUps.length) getTransportationPickUps();
	}, []);

	const [updatedTransportationRoute, setUpdatedTransportationRoute] = useState(
		transportationRoute ? transportationRoute : {}
	);

	const { id, pickup_id, dropoff_id, price, currency, description } = updatedTransportationRoute;
	useEffect(() => {
		setUpdatedTransportationRoute(transportationRoute ? transportationRoute : {});
	}, [transportationRoute]);

	const onChangePrice = (event) => {
		setUpdatedTransportationRoute({
			...updatedTransportationRoute,
			[event.target.name]: Number(event.target.value)
		});
	};

	const onChangeDescription = (event) => {
		setUpdatedTransportationRoute({
			...updatedTransportationRoute,
			[event.target.name]: event.target.value
		});
	};

	const pickupOpts = getOptions(transportationPickUps, ['label', 'value'], ['name', 'id']);
	const dropoffOpts = getOptions(transportationPickUps, ['label', 'value'], ['name', 'id']);

	const onSubmit = async (event) => {
		event.preventDefault();

		// add transportation Route
		if (showAddTransportationRouteModal) {
			delete updatedTransportationRoute.id;
			const response = await addTransportationRoute(updatedTransportationRoute);
			if (response.data) {
				closeDialog();
				setShowToast({ show: true, message: t('Added successful!'), type: 'success' });
				return;
			}
			setShowToast({
				show: true,
				message: response.error && response.error.ErrorCode ? response.error.ErrorCode : 'Add failed!',
				type: 'danger'
			});
			return;
		}

		// update transportation Route
		const response = await updateTransportationRoute(updatedTransportationRoute);
		if (response.status === 200) {
			closeDialog();
			setShowToast({
				show: true,
				message: t('Updated successful!'),
				type: 'success'
			});
			return;
		}
		setShowToast({
			show: true,
			message: response.error && response.error.ErrorCode ? response.error.ErrorCode : 'Update failed!',
			type: 'danger'
		});
	};
	const closeDialog = () => {
		clearTransportationRoute();
		setShowUpdateTransportationRouteModal(false);
		setShowAddTransportationRouteModal(false);
	};

	const handleChange = (newValue, actionMeta) => {
		if (!newValue) {
			return;
		}
		switch (actionMeta.name) {
			case 'pickup_id':
				if (Number(newValue.value) !== updatedTransportationRoute.dropoff_id) {
					setUpdatedTransportationRoute({
						...updatedTransportationRoute,
						pickup_id: Number(newValue.value)
					});
				}

				break;
			case 'dropoff_id':
				if (Number(newValue.value) !== updatedTransportationRoute.pickup_id) {
					setUpdatedTransportationRoute({
						...updatedTransportationRoute,
						dropoff_id: Number(newValue.value)
					});
				}
				break;
			case 'currency':
				setUpdatedTransportationRoute({
					...updatedTransportationRoute,
					currency: newValue.value
				});
				break;
			default:
				break;
		}
	};

	return (
		<Modal
			show={showAddTransportationRouteModal || showUpdateTransportationRouteModal}
			onHide={closeDialog}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>
					{showUpdateTransportationRouteModal ? t('Update Transportation Route') : t('Create New Transportation Route')}
				</Modal.Title>
			</Modal.Header>
			<Form onSubmit={onSubmit}>
				<Modal.Body>
					<div className='row'>
						<div className='col-sm'>
							<div className='form-group'>
								<label>{t('Pick Up')}</label>
								<Select
									name='pickup_id'
									value={findOptionItem(pickupOpts, pickup_id)}
									required
									placeholder={t('Choose Pick Up')}
									options={pickupOpts}
									onChange={handleChange.bind(this)}
								/>

								<Form.Text id='pickup-help' muted>
									{t('Required')}
								</Form.Text>
							</div>
						</div>
						<div className='col-sm'>
							<div className='form-group'>
								<label>{t('Drop-Off')}</label>
								<Select
									name='dropoff_id'
									value={findOptionItem(dropoffOpts, dropoff_id)}
									required
									placeholder={t('Choose Drop Off')}
									options={dropoffOpts}
									onChange={handleChange.bind(this)}
								/>
								<Form.Text id='dropoff-help' muted>
									{t('Required')}
								</Form.Text>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-sm'>
							<div className='form-group'>
								<label>{t('Currency')}</label>
								<Select
									placeholder={t('Choose currency')}
									name='currency'
									value={findOptionItem(moneyOptions, currency)}
									options={moneyOptions}
									onChange={handleChange.bind(this)}
									required
								/>
							</div>
						</div>
						<div className='col-sm'>
							<div className='form-group'>
								<label>{t('Price')}</label>
								<input
									type='number'
									step='any'
									className='form-control'
									placeholder={t('Price')}
									name='price'
									value={price}
									onChange={onChangePrice}
									required
								></input>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-sm'>
							<div className='form-group'>
								<label>{t('Description')}</label>
								<textarea
									className='form-control'
									placeholder={t('Description')}
									rows='3'
									name='description'
									value={description}
									spellCheck='false'
									onChange={onChangeDescription}
								></textarea>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button className='btn btn-danger btn-with-icon' variant='secondary' onClick={closeDialog}>
						<i className='fe fe-x-circle'></i> {t('Cancel')}
					</Button>
					<Button className='btn btn-primary btn-with-icon' variant='primary' type='submit'>
						<i className='fe fe-save'></i> {t('Save')}!!!
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default UpdateTransportationRouteModal;
