import { Fragment, useEffect, useState, useContext } from 'react';
import { NO_PERMISSION, PERMISSIONS, ROLES, apiUrl } from '../contexts/constants';
import { AuthContext } from '../contexts/AuthContext';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { getAPI } from '../utils/api';
import { getTable, getTableOptions, getDefaultSort } from '../utils/table';
import { useTranslation } from 'react-i18next';
import { getYearMonthDay, not_count_booked_statuses, numberFormatter } from '../utils/common';
import DashboardBookingSummaryFilter from './DashboardBookingSummaryFilter';

const Dashboard = () => {
	const {
		authState: { user }
	} = useContext(AuthContext);

	const [dataBookings, setDataBookings] = useState([]);
	const [dataOverdueBookings, setDataOverdueBookings] = useState([]);
	const [dataBookingCount, setDataBookingCount] = useState([]);

	useEffect(() => {
		async function getDataBookings() {
			const response = await getAPI(`${apiUrl}/bookings/reminder`);
			if (response.data) {
				setDataBookings(response.data);
				return;
			}
		}
		getDataBookings();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(async () => {
		async function getDataOverdueBookings() {
			const response = await getAPI(`${apiUrl}/bookings/overdue`);
			if (response.data) {
				setDataOverdueBookings(response.data);
				return;
			}
		}
		await getDataOverdueBookings();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const curDate = getYearMonthDay();
	const [fromDate, setFromDate] = useState(curDate.currentDate);
	const [filterBy, setFilterBy] = useState('day');
	const [cruiseType, setCruiseType] = useState('night sleep');

	useEffect(async () => {
		let startDate = fromDate;
		let endDate = fromDate;
		if (filterBy !== 'day') {
			const tmpDate = getYearMonthDay(fromDate);
			const daysInMonths = new Date(tmpDate.year, tmpDate.month, 0).getDate();
			startDate = `${tmpDate.year}-${tmpDate.month}-01`;
			endDate = `${tmpDate.year}-${tmpDate.month}-${daysInMonths}`;
		}
		async function getCruiseBookingCount() {
			const response = await getAPI(`${apiUrl}/cruise-booking-count?from_date=${startDate}&to_date=${endDate}&cruise_type=${cruiseType}`);
			if (response.data) {
				setDataBookingCount(response.data);
				return;
			} 
				
			setDataBookingCount([]);
		}
		await getCruiseBookingCount();
	}, [fromDate, filterBy, cruiseType]); // eslint-disable-line react-hooks/exhaustive-deps

	// It is a hook imported from 'react-i18next'
	const { t } = useTranslation();
	const overdueTableData = getOverdueTable(dataOverdueBookings, t);

	const unpaidTableData = getUnpaidTable(dataBookings, t);

	const cruiseBookingCount = getCruiseBookingCountTable(dataBookingCount, t, cruiseType);

	const onChangeFrom = (fromDate) => {
        setFromDate(fromDate);
	};

	const onChangeFilterBy = (filterBy) => {
		setFilterBy(filterBy);
	};
	
	const onChangeCruiseType = (cruiseType) => {
		setCruiseType(cruiseType);
	}

	if (!user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR)
			&& !user.Permissions.includes(PERMISSIONS.VIEW_DASHBOARD)) return NO_PERMISSION;

	return (
		<Fragment>
			<div className='main-content horizontal-content'>
				{user.id !== '64' && (
					<div className='container'>
						<div className='breadcrumb-header justify-content-between'>
							<div className='left-content'>
								<div>
									<h2 className='main-content-title tx-24 mg-b-1 mg-b-lg-1'>{t('Hi, welcome back!')}</h2>
									<p className='mg-b-0'>{t('Monitoring dashboard')}</p>
								</div>
							</div>
							<div className='main-dashboard-header-right'>
								{/* <div>
									<label className='tx-13'>Online Sales</label>
									<h5>563,275</h5>
								</div>
								<div>
									<label className='tx-13'>Offline Sales</label>
									<h5>783,675</h5>
								</div> */}
							</div>
						</div>
						<div className='row row-sm row-deck'>
							<div className='col-md-12 col-lg-12 col-xl-12'>
								<div className='card card-table-two'>
									<div className='d-flex justify-content-between'>
										<h4 className='card-title mb-1'>{t('Booking Summary')}</h4>
									</div>
									<div className='row margin-top-30'>
										<DashboardBookingSummaryFilter onChangeFilterBy={onChangeFilterBy} onChangeFrom={onChangeFrom} fromDate={fromDate} filterBy={filterBy} onChangeCruiseType={onChangeCruiseType}/>
									</div>

									<div>{cruiseBookingCount}</div>
								</div>
							</div>
						</div>
						<div className='row row-sm row-deck'>
							<div className='col-md-12 col-lg-12 col-xl-12'>
								<div className='card card-table-two unpaid-table'>
									<div className='d-flex justify-content-between'>
										<h4 className='card-title mb-1'>{t('Unpaid Booking List')}</h4>
									</div>
									<span className='tx-12 tx-muted mb-3 '>{t('This is unpaid bookings in 3 days')}</span>
									<div>{unpaidTableData}</div>
								</div>
							</div>
						</div>

						<div className='row row-sm row-deck'>
							<div className='col-md-12 col-lg-12 col-xl-12'>
								<div className='card card-table-two overdue-table'>
									<div className='d-flex justify-content-between'>
										<h4 className='card-title mb-1'>{t('Unpaid Overdue Booking List')}</h4>
									</div>
									<span className='tx-12 tx-muted mb-3 '>{t('List of unpaid overdue pre-orders')}</span>

									<div>{overdueTableData}</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			<div className='margin-bottom-150'></div>
		</Fragment>
	);
};

const getOverdueTableData = (dataOverdueBookings) => {
	return dataOverdueBookings.map((booking, index) => {
		let bk = {};
		bk.id = booking.id;
		bk.stt = index + 1;
		bk.new_code = booking.new_code;
		bk.code = (
			<Link className='show-booking' to={`/bookings/show/${booking.id}`} target='_blank'>
				{booking.new_code}
			</Link>
		);
		bk.partner = booking.partner_name;
		bk.start_date = moment(booking.start_date).format('MMMM D, YYYY');
		bk.unpaid = (
					<NumberFormat
						value={booking.total - booking.total_paid}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={2}
						prefix={booking.money_type === 'usd'? '$': ''}
						suffix={booking.money_type === 'usd'? '': ' VNĐ'}
					/>
			  );
		booking.total_paid !== 0
			? (bk.paid = (
					<NumberFormat
						value={booking.total_paid}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={2}
						prefix={booking.money_type === 'usd'? '$': ''}
						suffix={booking.money_type === 'usd'? '': ' VNĐ'}
					/>
			  ))
			: (bk.paid = `${booking.total_paid}`);
		bk.total = (
					<NumberFormat
						value={booking.total}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={2}
						prefix={booking.money_type === 'usd'? '$': ''}
						suffix={booking.money_type === 'usd'? '': ' VNĐ'}
					/>
			  );
		return bk;
	});
};

const getOverdueColumns = (t) => {
	return [
		{
			dataField: 'id',
			text: 'ID',
			hidden: true
		},
		{
			dataField: 'stt',
			text: 'STT',
			sort: true
		},
		{
			dataField: 'code',
			text: 'Booking ID',
			sort: true
		},
		{
			dataField: 'new_code',
			text: 'New Code',
			hidden: true
		},
		{
			dataField: 'partner',
			text: t('Partner'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'start_date',
			text: t('Departure'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'unpaid',
			text: t('Unpaid'),
			sort: true,
			classes: 'td-right'
		},
		{
			dataField: 'paid',
			text: t('Paid'),
			sort: true,
			classes: 'td-right'
		},
		{
			dataField: 'total',
			text: t('Total'),
			sort: true,
			classes: 'td-right'
		}
	];
};

const getOverdueTable = (dataOverdueBookings, t) => {
	if (!dataOverdueBookings || dataOverdueBookings.length === 0) return null;
	const tableData = getOverdueTableData(dataOverdueBookings);
	const columns = getOverdueColumns(t);
	const defaultSorted = getDefaultSort();
	const options = getTableOptions(tableData.length, t);

	return getTable(tableData, columns, defaultSorted, options);
};

const getUnpaidTableData = (dataBookings) => {
	return dataBookings.map((booking, index) => {
		let bk = {};
		bk.id = booking.id;
		bk.stt = index + 1;
		bk.new_code = booking.new_code;
		bk.code = (
			<Link className='show-booking' to={`/bookings/show/${booking.id}`} target='_blank'>
				{booking.new_code}
			</Link>
		);
		bk.partner = booking.partner_name;
		bk.start_date = moment(booking.start_date).format('MMMM D, YYYY');
		bk.unpaid = (
					<NumberFormat
						value={booking.total - booking.total_paid}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={2}
						prefix={booking.money_type === 'usd'? '$': ''}
						suffix={booking.money_type === 'usd'? '': ' VNĐ'}
					/>
			  );
		booking.total_paid !== 0
			? (bk.paid = (
					<NumberFormat
						value={booking.total_paid}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={2}
						prefix={booking.money_type === 'usd'? '$': ''}
						suffix={booking.money_type === 'usd'? '': ' VNĐ'}
					/>
			  ))
			: (bk.paid = `${booking.total_paid}`);
		bk.total = (
					<NumberFormat
						value={booking.total}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={2}
						prefix={booking.money_type === 'usd'? '$': ''}
						suffix={booking.money_type === 'usd'? '': ' VNĐ'}
					/>
			  );
		return bk;
	});
};

const getCruiseBookingTableData = (dataBookingCount, t) => {
	let totalItem = {
		'cruise_name': t('Total'),
		'booking_status': '',
		'booked_rooms': 0,
		'room_total': 0,
		'available_rooms': 0,
		'booked_customers': 0,
		'available_slots': 0,
		'cruise_max_customer': 0,
		'booked_rooms_percent': 0,
		'booked_customer_percent': 0
	};
	let currentCruiseName = '';
	let summaryItem = {};
	const resetSummaryItem = () => {
		summaryItem = {
			'cruise_name': '',
			'booking_status': '',
			'booked_rooms': 0,
			'room_total': 0,
			'available_rooms': 0,
			'booked_customers': 0,
			'available_slots': 0,
			'cruise_max_customer': 0,
			'booked_rooms_percent': 0,
			'booked_customer_percent': 0,
			'statuses': []
		};
	}

	const addToTotalItem = () => {
		totalItem.booked_rooms += summaryItem.booked_rooms;
		totalItem.room_total += summaryItem.room_total;
		totalItem.booked_customers += summaryItem.booked_customers;
		totalItem.cruise_max_customer += summaryItem.cruise_max_customer;
		totalItem.available_rooms += summaryItem.available_rooms;
	};

	const addToSummary = (summaryItem, item, bk) => {
		summaryItem.cruise_name = item.cruise_name;
		summaryItem.booking_status = '';
		summaryItem.booked_rooms += !not_count_booked_statuses.includes(item.booking_status) ? item.booked_rooms: 0;
		summaryItem.room_total = item.room_total;
		summaryItem.booked_customers += !not_count_booked_statuses.includes(item.booking_status) ? item.booked_customers: 0;
		summaryItem.available_rooms = summaryItem.room_total > summaryItem.booked_rooms? summaryItem.room_total - summaryItem.booked_rooms: 0;
		summaryItem.cruise_max_customer = item.cruise_max_customer;
		summaryItem.available_slots = summaryItem.cruise_max_customer > summaryItem.booked_customers? summaryItem.cruise_max_customer - summaryItem.booked_customers: 0;
		summaryItem.booked_customer_percent = summaryItem.cruise_max_customer > 0? numberFormatter(0).format(summaryItem.booked_customers * 100 / summaryItem.cruise_max_customer, 0): 0;
		summaryItem.booked_rooms_percent = summaryItem.room_total > 0? numberFormatter(0).format(summaryItem.booked_rooms * 100 / summaryItem.room_total, 0): 0;
	};

	let dataResult = dataBookingCount.flatMap((item, index) => {
		let bk = {};
		bk.cruise_name = item.cruise_name === currentCruiseName? '': item.cruise_name;
		bk.booking_status = item.booking_status;
		bk.booked_rooms = item.booked_rooms;
		bk.booked_rooms_percent = item.room_total > 0? numberFormatter(0).format(item.booked_rooms * 100 / item.room_total, 0): 0;
		bk.available_rooms = '-';
		bk.booked_customers = item.booked_customers;
		bk.booked_customer_percent = item.cruise_max_customer > 0? numberFormatter(0).format(item.booked_customers * 100 / item.cruise_max_customer, 0): 0;
		bk.available_slots = '-';
		bk.room_total = item.room_total;
		bk.cruise_max_customer = item.cruise_max_customer;
		if (!summaryItem.statuses) {
			summaryItem.statuses = [];
		}
		if (item.booking_status) {
			summaryItem.statuses.push(item);
		}
			
		if (currentCruiseName !== item.cruise_name) {
			currentCruiseName = item.cruise_name;
			if (index > 0) {
				const newItem = [{...summaryItem, cruise_name: '-', isSummary: true}, bk];
				addToTotalItem();
				resetSummaryItem();
				addToSummary(summaryItem, item, bk);
				if (index === dataBookingCount.length - 1) {
					newItem.push({...summaryItem, cruise_name: '-', isSummary: true});
					addToTotalItem();
					resetSummaryItem();
					return newItem;
				}
				
				return newItem;
			}

			resetSummaryItem();
			addToSummary(summaryItem, item, bk);
		} else {
			addToSummary(summaryItem, item, bk);
		}

		if (index === dataBookingCount.length - 1) {
			const newItem = [bk, {...summaryItem, cruise_name: '-', isSummary: true}];
			addToTotalItem();
			resetSummaryItem();
			return newItem;
		}

		return bk;
	});

	totalItem.booked_rooms_percent = totalItem.room_total > 0? numberFormatter(0).format(totalItem.booked_rooms * 100 / totalItem.room_total, 0): 0;
	totalItem.booked_customer_percent = totalItem.cruise_max_customer > 0? numberFormatter(0).format(totalItem.booked_customers * 100 / totalItem.cruise_max_customer, 0): 0;
	totalItem.available_slots = totalItem.cruise_max_customer > totalItem.booked_customers? totalItem.cruise_max_customer - totalItem.booked_customers: 0;
	dataResult.push({
		'cruise_name': totalItem.cruise_name,
		'booking_status': '',
		'booked_rooms': totalItem.booked_rooms,
		'booked_rooms_percent': totalItem.booked_rooms_percent,
		'available_rooms': totalItem.available_rooms,
		'booked_customers': totalItem.booked_customers,
		'booked_customer_percent': totalItem.booked_customer_percent,
		'available_slots': totalItem.available_slots,
		'room_total': totalItem.room_total,
		'cruise_max_customer': totalItem.cruise_max_customer,
		'isTotal': true
	});
	
	return dataResult;
};

const getUnpaidTable = (dataBookings, t) => {
	if (!dataBookings || dataBookings.length === 0) return null;
	const tableData = getUnpaidTableData(dataBookings);
	const columns = getOverdueColumns(t);
	const defaultSorted = getDefaultSort();
	const options = getTableOptions(tableData.length, t);

	return getTable(tableData, columns, defaultSorted, options);
};

const getCruiseBookingCountTable = (dataCruiseBooking, t, cruiseType) => {
	if (!dataCruiseBooking || dataCruiseBooking.length === 0) return null;
	const tableData = getCruiseBookingTableData(dataCruiseBooking, t);
	const columns = getCruiseBookingColumns(t, cruiseType);
	const defaultSorted = getDefaultSort();
	const options = getTableOptions(tableData.length, t);
	options.sizePerPage = 20;

	return getTable(tableData, columns, defaultSorted, options);
}

const getCruiseBookingColumns = (t, cruiseType) => {
	return [
		{
			dataField: 'cruise_name',
			text: '',
			classes: 'td-left',
			formatter: (cell, row) => {
				if (row.isSummary || row.isTotal) {
					let colorBar = '';
					let availablePercent = 100;
					if (cruiseType == 'tour') {
						availablePercent -= Number(row.booked_customer_percent);
					
						colorBar += `<div title="Booked: ${row.booked_customer_percent}%" class="bar-status bar-booked" style="height:24px; width:${row.booked_customer_percent}%"></div>`;
						colorBar += `<div title="Available: ${availablePercent}%" class="bar-status bar-available" style="height:24px; width:${availablePercent}%"></div>`;
					} else {
						availablePercent -= Number(row.booked_rooms_percent);
					
						colorBar += `<div title="Booked: ${row.booked_rooms_percent}%" class="bar-status bar-booked" style="height:24px; width:${row.booked_rooms_percent}%"></div>`;
						colorBar += `<div title="Available: ${availablePercent}%" class="bar-status bar-available" style="height:24px; width:${availablePercent}%"></div>`;
					}

					return <div className='k2-bar' dangerouslySetInnerHTML={{__html: colorBar}} />;
				}

				return <strong>{cell}</strong>;
			}
		},
		{
			dataField: 'booking_status',
			text: t('Status'),
			classes: 'td-left',
			formatter: (cell, row) => {
				return t(cell);
			}
		},
		{
			dataField: 'booked_rooms',
			text: t('Booked Rooms'),
			formatter: (cell, row) => {
				return (
					`${cell} / ${row.room_total}`
				);
			},
			classes: (cell, row) => {
				return `td-right ${row.isTotal? 'row-total': ''} ${row.isSummary? 'row-summary': ''} ${cruiseType === 'tour'? 'td-hidden': ''}`;
			},
			headerClasses: `${cruiseType === 'tour'? 'td-hidden': ''}`
		},
		{
			dataField: 'available_rooms',
			text: t('Available Rooms'),
			classes: `td-right ${cruiseType === 'tour'? 'td-hidden': ''}`,
			headerClasses: `${cruiseType === 'tour'? 'td-hidden': ''}`
		},
		{
			dataField: 'booked_rooms_percent',
			text: t('Booked Rooms %'),
			classes: `td-right ${cruiseType === 'tour'? 'td-hidden': ''}`,
			headerClasses: `${cruiseType === 'tour'? 'td-hidden': ''}`,
			formatter: (cell, row) => {
				return (`${cell} %`);
			}
		},
		{
			dataField: 'booked_customers',
			text: t('Booked Customers'),
			formatter: (cell, row) => {
				return (
					`${cell} / ${row.cruise_max_customer}`
				);
			},
			classes: 'td-right'
		},
		{
			dataField: 'available_slots',
			text: t('Available Slots'),
			classes: 'td-right'
		},
		{
			dataField: 'booked_customer_percent',
			text: t('Booked Customers %'),
			classes: 'td-right',
			formatter: (cell, row) => {
				return (`${cell} %`);
			}
		}
	];
};

export default Dashboard;