import { createContext, useState, useReducer } from 'react';
import { userRoleReducer } from '../reducers/userRoleReducer';
import { apiUrl, USER_ROLES_LOADED_SUCCESS, USER_ROLES_LOADED_FAIL, ADD_USER_ROLE, FIND_USER_ROLE, UPDATE_USER_ROLE, DELETE_USER_ROLE } from './constants';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';

export const UserRoleContext = createContext()

const UserRoleContextProvider = ({ children }) => {

    // State
    const [userRoleState, dispatch] = useReducer(userRoleReducer, {
        userRole: null,
        userRoles: [],
        userRolesLoading: true
    })

    const [showAddUserRoleModal, setShowAddUserRoleModal] = useState(false)
    const [showUpdateUserRoleModal, setShowUpdateUserRoleModal] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all User Roles
    const getUserRoles = async() => {
        const response = await getAPI(`${apiUrl}/user-roles`)
        if (response.data) {
            dispatch({ type: USER_ROLES_LOADED_SUCCESS, payload: response.data })
            return response
        }

        dispatch({ type: USER_ROLES_LOADED_FAIL })
        return response
    }

    const setUserRoles = (userRoles) => {
        dispatch({ type: USER_ROLES_LOADED_SUCCESS, payload: userRoles })
    }

    // Add User Role
    const addUserRole = async newUserRole => {
        const response = await postAPI(`${apiUrl}/user-role/insert`, newUserRole)
        if (response.data) {
            dispatch({
                type: ADD_USER_ROLE,
                payload: response.data
            })
        }
        return response
    }

    // Find User Role when user is updating User Role
    const findUserRole = userRoleId => {
        const userRole = userRoleState.userRoles.find(userRole => userRole.user_id === userRoleId)
        dispatch({
            type: FIND_USER_ROLE,
            payload: userRole
        })
    }

    // update user Role
    const updateUserRole = async updateUserRole => {
        const response = await postAPI(`${apiUrl}/user-role/update`, updateUserRole)
        if (response.status === 200) {
            dispatch({
                type: UPDATE_USER_ROLE,
                payload: updateUserRole
            })
        }
        return response
    }

    // Delete User Role
    const deleteUserRole = async userRoleId => {
        const response = await getDeleteAPI(`${apiUrl}/user-role/${userRoleId}/delete`)
        if (response.status === 200) {
            dispatch({
                type: DELETE_USER_ROLE,
                payload: userRoleId
            })
        }
        return response
    }

    // Post Context Data
    const userRoleContextData = {
        userRoleState,
        getUserRoles,
        addUserRole,
        findUserRole,
        updateUserRole,
        deleteUserRole,
        showToast,
        setShowToast,
        showAddUserRoleModal,
        setShowAddUserRoleModal,
        showUpdateUserRoleModal,
        setShowUpdateUserRoleModal,
        setUserRoles
    }

    return (
        <UserRoleContext.Provider value = { userRoleContextData } >
            { children }
        </UserRoleContext.Provider>
    )

}

export default UserRoleContextProvider