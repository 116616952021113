import { PAYMENTS_LOADED_SUCCESS, PAYMENTS_LOADED_FAIL, ADD_PAYMENT, FIND_PAYMENT, UPDATE_PAYMENT, DELETE_PAYMENT } from '../contexts/constants';

export const paymentReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case PAYMENTS_LOADED_SUCCESS:
            return {
                ...state,
                payments: payload,
                paymentsLoading: false
            }
        case PAYMENTS_LOADED_FAIL:
            return {
                ...state,
                payments: [],
                paymentsLoading: false
            }
        case ADD_PAYMENT:
            {
                return {
                    ...state,
                    payments: [...state.payments, payload],
                }
            }
        case FIND_PAYMENT:
            return {
                ...state,
                payment: payload
            }
        case UPDATE_PAYMENT:
            const newPayment = state.payments.map(payment => {
                if (payment.id === payload.id) {
                    return payload
                } else {
                    return payment
                }
            })
            return {
                ...state,
                payments: newPayment
            }
        case DELETE_PAYMENT:
            return {
                ...state,
                payments: state.payments.filter(payment => payment.id !== payload)
            }
        default:
            return state
    }
}