import { TRANSPORTATIONS_LOADED_SUCCESS, TRANSPORTATIONS_LOADED_FAIL, ADD_TRANSPORTATION, FIND_TRANSPORTATION, UPDATE_TRANSPORTATION, DELETE_TRANSPORTATION } from '../contexts/constants';

export const transportationReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case TRANSPORTATIONS_LOADED_SUCCESS:
            return {
                ...state,
                transportations: payload,
                transportationsLoading: false
            }
        case TRANSPORTATIONS_LOADED_FAIL:
            return {
                ...state,
                transportations: [],
                transportationsLoading: false
            }
        case ADD_TRANSPORTATION:
            {
                return {
                    ...state,
                    transportations: [...state.transportations, payload],
                }
            }
        case FIND_TRANSPORTATION:
            return {
                ...state,
                transportation: payload
            }
        case UPDATE_TRANSPORTATION:
            const newTransportation = state.transportations.map(transportation => {
                if (transportation.id === payload.id) {
                    return payload
                } else {
                    return transportation
                }
            })
            return {
                ...state,
                cars: newTransportation
            }
        case DELETE_TRANSPORTATION:
            return {
                ...state,
                transportations: state.transportations.filter(transportation => transportation.id !== payload)
            }
        default:
            return state
    }
}