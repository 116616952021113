import { createContext, useState, useReducer } from 'react';
import { customerTransportReducer } from '../reducers/customerTransportReducer';
import {
	apiUrl,
	CUSTOMER_TRANSPORTS_LOADED_SUCCESS,
	CUSTOMER_TRANSPORTS_LOADED_FAIL,
	ADD_CUSTOMER_TRANSPORT,
	FIND_CUSTOMER_TRANSPORT,
	UPDATE_CUSTOMER_TRANSPORT,
	DELETE_CUSTOMER_TRANSPORT,
	CUSTOMER_NO_TRANSPORTS_LOADED_SUCCESS,
	CUSTOMER_NO_TRANSPORTS_LOADED_FAIL
} from '../contexts/constants';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';

export const CustomerTransportContext = createContext();

const CustomerTransportContextProvider = ({ children }) => {
	// State
	const [customerTransportState, dispatch] = useReducer(customerTransportReducer, {
		customerTransport: null,
		customerTransports: [],
		customerNoTransports: [],
		customerTransportsLoading: true,
		customerNoTransportsLoading: true
	});

	const [showAddCustomerTransportModal, setshowAddCustomerTransportModal] = useState(false);
	const [showUpdatecustomerTransportModal, setShowUpdateCustomerTransportModal] = useState(false);
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null
	});

	// Get all Customer Transportations
	const getcustomerTransports = async (queryString) => {
		const response = await getAPI(`${apiUrl}/customer-transportations?orderBy=id&direction=desc${queryString}`);
		if (response.data) {
			dispatch({ type: CUSTOMER_TRANSPORTS_LOADED_SUCCESS, payload: response.data });
			return response;
		}

		dispatch({ type: CUSTOMER_TRANSPORTS_LOADED_FAIL });
		return response;
	};

	// Get all Customer No Transportations
	const getcustomerNoTransports = async (queryString) => {
		const response = await getAPI(`${apiUrl}/customer-no-transportations?orderBy=id&direction=desc${queryString}`);
		if (response.data) {
			dispatch({ type: CUSTOMER_NO_TRANSPORTS_LOADED_SUCCESS, payload: response.data });
			return response;
		}

		dispatch({ type: CUSTOMER_NO_TRANSPORTS_LOADED_FAIL });
		return response;
	};

	// Add Customer Transportation
	const addcustomerTransport = async (newcustomerTransport) => {
		const response = await postAPI(`${apiUrl}/customer-transportation/insert`, newcustomerTransport);
		if (response.data) {
			dispatch({
				type: ADD_CUSTOMER_TRANSPORT,
				payload: response.data
			});
		}
		return response;
	};

	// Find Customer Transportation when user is updating post
	const findcustomerTransport = (customerTransportId) => {
		const customerTransport = customerTransportState.customerTransports.find(
			(customerTransport) => customerTransport.id === customerTransportId
		);
		dispatch({
			type: FIND_CUSTOMER_TRANSPORT,
			payload: customerTransport
		});
	};

	// update Customer Transportation
	const updatecustomerTransport = async (updatecustomerTransport) => {
		const response = await postAPI(`${apiUrl}/customer-transportation/update`, updatecustomerTransport);
		if (response.status === 200) {
			dispatch({
				type: UPDATE_CUSTOMER_TRANSPORT,
				payload: updatecustomerTransport
			});
		}
		return response;
	};

	// Delete Customer Transportation
	const deletecustomerTransport = async (customerTransportId) => {
		const response = await getDeleteAPI(`${apiUrl}/customer-transportation/${customerTransportId}/delete`);
		if (response.status === 200) {
			dispatch({
				type: DELETE_CUSTOMER_TRANSPORT,
				payload: customerTransportId
			});
		}
		return response;
	};

	const clearcustomerTransport = () => {
		dispatch({
			type: FIND_CUSTOMER_TRANSPORT,
			payload: {}
		});
	};

	// Post Context Data
	const customerTransportContextData = {
		customerTransportState,
		getcustomerTransports,
		getcustomerNoTransports,
		addcustomerTransport,
		findcustomerTransport,
		updatecustomerTransport,
		deletecustomerTransport,
		showToast,
		setShowToast,
		showAddCustomerTransportModal,
		setshowAddCustomerTransportModal,
		showUpdatecustomerTransportModal,
		setShowUpdateCustomerTransportModal,
		clearcustomerTransport
	};

	return (
		<CustomerTransportContext.Provider value={customerTransportContextData}>
			{children}
		</CustomerTransportContext.Provider>
	);
};

export default CustomerTransportContextProvider;
