import { Fragment, useContext, useEffect, useState } from 'react';
import { RoomContext } from '../../../contexts/RoomContext';
import { RoomTypeContext } from '../../../contexts/RoomTypeContext';
import { AuthContext } from '../../../contexts/AuthContext';
import UpdateRoomModal from './UpdateRoomModal';
import { Spinner, Card } from 'react-bootstrap';
import DeleteModal from '../DeleteModal';
import { ROLES, PERMISSIONS } from '../../../contexts/constants';
import { getTable, getTableOptions, getDefaultSort } from '../../../utils/table';
import { useTranslation } from 'react-i18next';

const ListRoom = (props) => {
	const { t } = useTranslation();
	// Context
	const {
		authState: { user }
	} = useContext(AuthContext);
	const {
		roomState: { room, rooms, roomsLoading },
		getRooms,
		findRoom,
		deleteRoom,
		setShowUpdateRoomModal,
		setShowToast
	} = useContext(RoomContext);

	// lay toan bo room types từ room type context.
	const {
		roomTypeState: { roomTypes },
		getRoomTypes
	} = useContext(RoomTypeContext);

	const currentCruise = props.cruise;
	const [isShowView, setIsShowView] = useState(false);
	// Start: Get all room types , []
	useEffect(() => {
		if (!roomTypes || roomTypes.length === 0) {
			getRoomTypes('');
		}
	}, []);

	const chooseRoom = (roomId, isView) => {
		findRoom(roomId);
		setShowUpdateRoomModal(true);
		setIsShowView(!!isView);
	};

	// Start: Get all rooms , []
	useEffect(() => getRooms(''), []);

	if (roomsLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info'></Spinner>
			</div>
		);
	}
	if (rooms === null) {
		return (
			<Fragment>
				<Card className='text-center mx-5 my-5'>
					<Card.Header as='h1'>Hi {user.name}</Card.Header>
					<Card.Body>
						<Card.Title>{t('Welcome to Room Manage')}</Card.Title>
						<Card.Text>{t('Click the Add Room button to create your first Room')}</Card.Text>
					</Card.Body>
				</Card>
			</Fragment>
		);
	}
	const tableProps = { currentCruise, user, rooms, roomTypes, chooseRoom, deleteRoom, setShowToast, t };
	const roomTable = getListRoomTable(tableProps);
	return (
		<Fragment>
			{roomTable}
			{room !== null && <UpdateRoomModal isView={isShowView} />}
		</Fragment>
	);
};
const findRoomTypeName = (roomTypes, roomTypeId) => {
	const roomType = roomTypes.find((rt) => {
		return rt.id === roomTypeId;
	});

	return roomType ? roomType.name : '';
};
const getListRoomTable = (tableProps) => {
	if (!tableProps.rooms || tableProps.rooms.length === 0) return null;
	const tableData = getTableData(tableProps);
	const columns = getColumns(tableProps.t);
	const defaultSorted = getDefaultSort([['index', 'asc']]);
	const options = getTableOptions(tableData.length, tableProps.t);

	return getTable(tableData, columns, defaultSorted, options);
};
const getTableData = (tableProps) => {
	const { currentCruise, user, rooms, roomTypes, chooseRoom, deleteRoom, setShowToast, t } = tableProps;
	return rooms
		.filter((item) => {
			return currentCruise ? item.cruise_id === currentCruise.value : true;
		})
		.map((room, index) => {
			return {
				index: index + 1,
				id: room.id,
				name: room.name,
				bed_type: room.bed_type,
				room_type: room.room_type_id ? findRoomTypeName(roomTypes, room.room_type_id) : '',
				max_room: room.max_room,
				max_adult: room.max_adult,
				cruise: room.ROOM_name,
				location: room.location,
				status: room.status ? room.status : 'Active',
				action: (
					<div className='row'>
						<div className='col-sm'>
							<button
								className='btn btn-info btn-with-icon btn-block'
								onClick={() => chooseRoom(room.id, true)}
							>
								<i className='fe fe-eye'></i> {t('View')}
							</button>
						</div>
						<div className='col-sm'>
							{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
							user.Permissions.includes(PERMISSIONS.UPDATE_ROOM) ? (
								<button
									className='btn btn-success btn-with-icon btn-block'
									onClick={() => chooseRoom(room.id)}
								>
									<i className='fe fe-edit'></i> {t('Edit')}
								</button>
							) : null}
						</div>
						<div className='col-sm'>
							{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
							user.Permissions.includes(PERMISSIONS.DELETE_ROOM) ? (
								<DeleteModal
									nameProps={room.name}
									idProps={room.id}
									deleteFunc={deleteRoom}
									setShowToast={setShowToast}
								/>
							) : null}
						</div>
					</div>
				)
			};
		});
};

const getColumns = (t) => {
	return [
		{
			dataField: 'index',
			text: 'STT',
			sort: true
		},
		{
			dataField: 'id',
			text: 'ID',
			sort: true
		},
		{
			dataField: 'name',
			text: t('Name'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'bed_type',
			text: t('Bed Type'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'room_type',
			text: t('Room Type'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'max_room',
			text: t('Max People in Room'),
			sort: true,
			classes: 'td-right'
		},
		{
			dataField: 'max_adult',
			text: t('Max Adult'),
			sort: true,
			classes: 'td-right'
		},
		{
			dataField: 'cruise',
			text: t('Cruise'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'location',
			text: t('Location'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'status',
			text: t('Status'),
			sort: true
		},
		{
			dataField: 'action',
			text: '',
			sort: true
		}
	];
};

export default ListRoom;
