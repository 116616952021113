import { Fragment, useContext, useEffect, useState } from 'react'
import { OnboardBookingContext } from '../../contexts/OnboardBookingContext'
import ListOnboardBooking from '../../components/layout/onboard-bookings/ListOnboardBooking'
import AddOnboardBookingModal from '../../components/layout/onboard-bookings/AddOnboardBookingModal'
import { AuthContext } from '../../contexts/AuthContext';
import { CruiseContext } from '../../contexts/CruiseContext';
import CheckinDateControl from '../../components/layout/onboard-bookings/CheckinDateControl';
import Select from 'react-select';
import { ROLES, PERMISSIONS, NO_PERMISSION, CRUISE_TYPES } from '../../contexts/constants';

const OnboardBooking = () => {
    let month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();
    let checkDayCruise = CRUISE_TYPES.NIGHT

    const {
        cruiseState: {cruise, cruises},
    } = useContext(CruiseContext)

    const { 
        setShowAddOnboardBookingModal
    } = useContext(OnboardBookingContext)

    const {
        authState: {user }
    } = useContext(AuthContext)

    const [cruiseID, setCruiseID] = useState(null)
    const [cruiseOptions, setCruiseOptions] = useState([])
    const [currentCruise, setCurrentCruise] = useState(cruise)
    const [currentMonth, setCurrentMonth] = useState(month)
    const [currentYear, setCurrentYear] = useState(year)

    useEffect(() => {
        if (cruises && cruises.length > 0) {
            const cOptions = cruises.map((cruise) =>
                {
                    return {
                        value: cruise.id,
                        label: cruise.name,
                        typeDay: cruise.typeDay
                    }
                }
            )
            setCruiseOptions(cOptions)
        }
    }, [cruises]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (currentCruise && currentCruise.cabins === 0){
            checkDayCruise = CRUISE_TYPES.DAY
        }
    }, [currentCruise])

    // select cruise
    const handleChangeCruise = async (newValue) => {
        const dataCruise = newValue;
        if(dataCruise){
            setCruiseID(dataCruise.value)
            const cCruise = cruises.find(item => item.id === dataCruise.value)
            setCurrentCruise(cCruise)
        }else{
            setCruiseID(null)
        }
    };

    if (!user) {
        return NO_PERMISSION
    }

    if (!(user.Permissions.includes(PERMISSIONS.GET_ONBOARD_BOOKING) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))) {
        return NO_PERMISSION
    }

    return (
        <Fragment>
            <div className="horizontal-mainwrapper container-fluid clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-3">
                        <div className="row">
                            <div className="col-md-4">
                                <h3 className="pull-left">Onboard Booking Management</h3>
                            </div>
                            {/* <div className="col-md-2 ml-auto">
                                {(user.Permissions.includes(PERMISSIONS.INSERT_ONBOARD_BOOKING) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))? <button className="right-content btn btn-success btn-block" onClick={setShowAddOnboardBookingModal.bind(this, true)}><i className="fe fe-plus-circle"></i> Add Onboard Booking</button> : null }
                            </div> */}
                        </div>
                        <hr />
                    </div>
                    {/* <div className="explain-body">
                        <div className="row">
                            <div className="col-sm-2">
                                <p className="text-center bg-green bg-font">Checked In</p>
                            </div>
                            <div className="col-sm-2">
                                <p className="text-center bg-red bg-font">Checked Out</p>
                            </div>
                        </div>
                    </div> */}
                    {/* <hr /> */}
                    <div className='row'>
                        <div className='col-sm-3'>
                            <label><strong>Cruise</strong></label>
                            <Select isClearable
                                name="cruise_id"
                                onChange={handleChangeCruise.bind(this)}
                                options={cruiseOptions}
                                placeholder="Choose Cruise"
                            />
                        </div>
                        <div className='col-sm-4'>
                            <CheckinDateControl monthProps={currentMonth} yearProps={currentYear} idCruiseProps={cruiseID} setCurrentMonth={setCurrentMonth} setCurrentYear={setCurrentYear} />
                        </div>
                    </div>
                    
                    <hr />
                    {(checkDayCruise === CRUISE_TYPES.DAY || checkDayCruise === CRUISE_TYPES.TOUR) ? (<></>) :
                    (<ListOnboardBooking cruiseProps={currentCruise} monthProps={currentMonth} yearProps={currentYear} />)}
                </div>
            </div>
        </Fragment>
    )
}

export default OnboardBooking;
