import { ONBOARD_BOOKINGS_LOADED_SUCCESS, ONBOARD_BOOKINGS_LOADED_FAIL, ADD_ONBOARD_BOOKING, FIND_ONBOARD_BOOKING, UPDATE_ONBOARD_BOOKING, DELETE_ONBOARD_BOOKING } from '../contexts/constants';

export const onboardBookingReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case ONBOARD_BOOKINGS_LOADED_SUCCESS:
            return {
                ...state,
                onboardBookings: payload,
                onboardBookingsLoading: false
            }
        case ONBOARD_BOOKINGS_LOADED_FAIL:
            return {
                ...state,
                onboardBookings: [],
                onboardBookingsLoading: false
            }
        case ADD_ONBOARD_BOOKING:
            return {
                ...state,
                onboardBookings: [...state.onboardBookings, payload],
            }
        case FIND_ONBOARD_BOOKING:
            return {
                ...state,
                onboardBooking: payload
            }
        case UPDATE_ONBOARD_BOOKING:
            const newOnboardBooking = state.onboardBookings.map(onboardBooking => {
                if (onboardBooking.id === payload.id) {
                    return payload
                } else {
                    return onboardBooking
                }
            })
            return {
                ...state,
                onboardBookings: newOnboardBooking
            }
        case DELETE_ONBOARD_BOOKING:
            return {
                ...state,
                onboardBookings: state.onboardBookings.filter(onboardBooking => onboardBooking.id !== payload)
            }
        default:
            return state
    }
}