import { Fragment, useContext } from 'react';
import { RoomTypeContext } from '../../contexts/RoomTypeContext';
import ListRoomType from '../../components/layout/room-type/ListRoomType';
import AddRoomTypeModal from '../../components/layout/room-type/AddRoomTypeModal';
import { AuthContext } from '../../contexts/AuthContext';
import { ROLES, PERMISSIONS, NO_PERMISSION } from '../../contexts/constants';
import { useTranslation } from 'react-i18next';

const RoomType = () => {
	const { t } = useTranslation();
	const { setShowAddRoomTypeModal } = useContext(RoomTypeContext);

	const {
		authState: { user }
	} = useContext(AuthContext);

	if (!user) {
		return NO_PERMISSION;
	}

	if (!(user.Permissions.includes(PERMISSIONS.GET_ROOM_TYPE) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))) {
		return NO_PERMISSION;
	}
	return (
		<Fragment>
			<div className='horizontal-mainwrapper container clearfix'>
				<div className='explain-dashboard mt-5'>
					<div className='explain-header mb-3'>
						<div className='row'>
							<div className='col-md-4'>
								<h3 className='pull-left'>{t('Room Type List')}</h3>
							</div>
							<div className='col-md-2 ml-auto'>
								{user.Permissions.includes(PERMISSIONS.INSERT_ROOM_TYPE) ||
								user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ? (
									<button
										className='right-content btn btn-success btn-block'
										onClick={setShowAddRoomTypeModal.bind(this, true)}
									>
										<i className='fe fe-plus-circle'></i> {t('Add Room Type')}
									</button>
								) : null}
							</div>
						</div>
						<hr />
					</div>
					<ListRoomType />
					<AddRoomTypeModal />
					<div className='margin-bottom-150'></div>
				</div>
			</div>
		</Fragment>
	);
};

export default RoomType;
