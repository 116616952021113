import * as XLSX from 'xlsx';
import * as XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';
export const handleExportCustomerBasic = async (exportCustomerBasic, dateInfo, customerQuantity) => {
	const ws = XLSX.utils.json_to_sheet(exportCustomerBasic, { origin: 'A1' });
	const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
	const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
	const data = new Blob([excelBuffer], { type: fileType });
	const rowLength = exportCustomerBasic.length;
	return addStyleCustomerRoom(data, rowLength);
};

const addStyleCustomerRoom = (workbookBlob, rowLength) => {
	return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
		workbook.sheets().forEach((sheet) => {
			// general style
			sheet.usedRange().style({
				fontFamily: 'Times New Roman',
				verticalAlignment: 'center',
				horizontalAlignment: 'center',
				wrapText: true
			});

			sheet.range(`A1:K${rowLength + 1}`).style({
				border: true
			});

			// set width for columns
			sheet.column('A').width(15).style({ horizontalAlignment: 'right' });
			sheet.column('B').width(30).style({ horizontalAlignment: 'left' });
			sheet.column('C').width(15).style({ horizontalAlignment: 'left' });
			sheet.column('D').width(30).style({ horizontalAlignment: 'left' });
			sheet.column('E').width(15).style({ horizontalAlignment: 'left' });
			sheet.column('F').width(15).style({ horizontalAlignment: 'left' });
			sheet.column('G').width(20).style({ horizontalAlignment: 'center' });
			sheet.column('H').width(20).style({ horizontalAlignment: 'left' });
			sheet.column('I').width(15).style({ horizontalAlignment: 'left' });
			sheet.column('J').width(15).style({ horizontalAlignment: 'left' });
			sheet.column('K').width(15).style({ horizontalAlignment: 'left' });

			// header style
			sheet.range('A1:K1').style({
				fill: '82AEE8',
				horizontalAlignment: 'center'
			});

			for (let i = 3; i < rowLength + 2; i++) {
				if (i % 2 === 0) continue;
				sheet.range(`A${i}:K${i}`).style({
					fill: 'e5f3f3'
				});
			}
		});

		return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob));
	});
};
