import { Fragment, useContext, useEffect, useState } from 'react';
import { BookingContext } from '../../../contexts/BookingContext';
import BookingDayItem from './BookingDayItem';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { CRUISE_TYPES, apiUrl, BOOKING_STATUSES } from '../../../contexts/constants';
import { getAPI } from '../../../utils/api';
import { CruiseSettingContext } from '../../../contexts/CruiseSettingContext';
import { CheckWeekend, CheckHoliday, getDayOfWeek } from '../../../utils/common';
import { useTranslation } from 'react-i18next';

const TableDayCruise = (props) => {
	const {
		bookingState: { bookings },
		getBookingIdsInMonth
	} = useContext(BookingContext);

	const {
		cruiseSettingState: { cruiseSettings },
		getCruiseSettings
	} = useContext(CruiseSettingContext);

	useEffect(() => {
		if (cruiseSettings && cruiseSettings.length > 0) return;
		getCruiseSettings('');
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const cruise = props.cruiseProps;
	const setAddBookingDefaultData = props.setAddBookingDefaultData;
	const month = props.monthProps;
	const year = props.yearProps;
	const daysInMonths = new Date(year, month, 0).getDate();
	const [dataSettings, setDataSettings] = useState([]);

	useEffect(() => getBookingIdsInMonth(cruise.id, month, year, CRUISE_TYPES.DAY), [cruise.id, month, year]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		async function settingData() {
			try {
				const response = await getAPI(
					`${apiUrl}/cruise-day-settings`,
					`filterFields=cruise_id,oos&filterFieldValues=${cruise.id},true&start_date=${year}-${month}-01&end_date=${year}-${month}-${daysInMonths}`
				);
				setDataSettings(response.data);
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		settingData();
	}, [cruise.id]); // eslint-disable-line react-hooks/exhaustive-deps

	const [itineraries, setItineraries] = useState([]);

	//get itineraries
	useEffect(() => {
		async function getDataItineraries() {
			try {
				if (cruise) {
					const response = await getAPI(
						`${apiUrl}/cruise-itineraries`,
						`filterFields=cruise_id&filterFieldValues=${cruise.id}`
					);
					setItineraries(response.data);
				}
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		getDataItineraries();
	}, [cruise]); // eslint-disable-line react-hooks/exhaustive-deps

	const arrDayCountCustomers = getCustomersCountByDay(bookings, daysInMonths, month, year);

	const arrDateSetting = getDataSettings(dataSettings);
	const { t } = useTranslation();

	return (
		<Fragment>
			<div className='fullWidth100 widthFull'>
				<table className='table table-bordered mg-b-0 text-md-nowrap'>
					<thead>
						<tr className='day-of-week'>
							{Array.from(Array(daysInMonths), (e, i) => {
								const day = ('0' + (i + 1)).slice(-2);
								const date = `${year}-${month.toString().length === 1 ? '0' + month : month}-${day}`; //${year}-${month}-${day}
								const weekend = CheckWeekend(cruiseSettings, cruise.id, date);
								const holiday = CheckHoliday(cruiseSettings, cruise.id, date);
								const dayOfWeek = getDayOfWeek(date);
								return (
									<td key={i} className={`${weekend} ${holiday}`} title={dayOfWeek}>
										{t(dayOfWeek)}
									</td>
								);
							})}
						</tr>
						<tr>
							{Array.from(Array(daysInMonths), (e, i) => {
								const day = ('0' + (i + 1)).slice(-2);
								const date = `${year}-${month.toString().length === 1 ? '0' + month : month}-${day}`; //${year}-${month}-${day}
								return (
									<th key={i}>
										<Link
											target='_blank'
											to={`/operating/${date}-${cruise.id}`}
											title={`Operating ${cruise.name} in ${date}`}
										>
											{i + 1}
										</Link>
									</th>
								);
							})}
						</tr>
					</thead>
					<tbody>
						<tr>
							{Array.from(Array(daysInMonths), (e, i) => {
								const day = ('0' + (i + 1)).slice(-2);
								const date = `${year}-${month.toString().length === 1 ? '0' + month : month}-${day}`; //${year}-${month}-${day}
								return (
									<BookingDayItem
										key={i}
										bookingProps={bookings ? bookings[date] : []}
										dateSettingProps={arrDateSetting ? arrDateSetting[date] : []}
										itineraries={itineraries}
										startDate={date}
										setAddBookingDefaultData={setAddBookingDefaultData}
										cruise={cruise}
									/>
								);
							})}
						</tr>
						<tr>
							{Array.from(Array(daysInMonths), (e, i) => {
								return (
									<td key={i}>
										{Number(cruise.max_customer) > 0
											? `${arrDayCountCustomers[i] ? arrDayCountCustomers[i] : 0}/${cruise.max_customer}`
											: arrDayCountCustomers[i]}
									</td>
								);
							})}
						</tr>
					</tbody>
				</table>
			</div>
		</Fragment>
	);
};

// Get customers number by day
const getCustomersCountByDay = (bookings, daysInMonths, month, year) => {
	const arrDayCountCustomers = [];

	if (!bookings) return arrDayCountCustomers;

	Array.from(Array(daysInMonths), (e, i) => {
		const dayCustomer = ('0' + (i + 1)).slice(-2);
		const dateCustomer = `${year}-${month.toString().length === 1 ? '0' + month : month}-${dayCustomer}`;
		let countCustomer = 0;
		if (!bookings[dateCustomer]) return arrDayCountCustomers;

		bookings[dateCustomer].forEach((arrCus) => {
			if (arrCus.status === BOOKING_STATUSES.CANCELED) return;

			countCustomer += 1;
			arrDayCountCustomers[i] = countCustomer;
		});
	});

	return arrDayCountCustomers;
};

// get data settings
const getDataSettings = (dataSettings) => {
	let arrDateSetting = [];
	if (!dataSettings) return [];

	dataSettings.forEach((setting) => {
		var dateSetting = moment(setting.day_setting).format('YYYY-MM-DD');
		arrDateSetting[dateSetting] = setting.oos;
	});

	return arrDateSetting;
};

export default TableDayCruise;
