import { ROOM_TYPE_LOADED_SUCCESS, ROOM_TYPE_LOADED_FAIL, ADD_ROOM_TYPE, FIND_ROOM_TYPE, UPDATE_ROOM_TYPE, DELETE_ROOM_TYPE } from '../contexts/constants';

export const roomTypeReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case ROOM_TYPE_LOADED_SUCCESS:
            return {
                ...state,
                roomTypes: payload,
                roomTypesLoading: false
            }
        case ROOM_TYPE_LOADED_FAIL:
            return {
                ...state,
                roomTypes: [],
                roomTypesLoading: false
            }
        case ADD_ROOM_TYPE:
            return {
                ...state,
                roomTypes: [...state.roomTypes, payload],
            }
        case FIND_ROOM_TYPE:
            return {
                ...state,
                roomType: payload
            }
        case UPDATE_ROOM_TYPE:
            const newRoomType = state.roomTypes.map(roomType => {
                if (roomType.id === payload.id) {
                    return payload
                } else {
                    return roomType
                }
            })
            return {
                ...state,
                roomTypes: newRoomType
            }
        case DELETE_ROOM_TYPE:
            return {
                ...state,
                roomTypes: state.roomTypes.filter(roomType => roomType.id !== payload)
            }
        default:
            return state
    }
}