import { Fragment } from 'react';
import NumberFormat from 'react-number-format';

const TopAccountTdSubItem = (props) => {
	const cruiseData = props.bookingDataProps;

	return (
		<Fragment>
			<td className='td-right'>
				<NumberFormat
					value={cruiseData? cruiseData.totalUSD: 0}
					displayType={'text'}
					thousandSeparator='.'
					decimalSeparator=','
					decimalScale={2}
				/>
			</td>
			<td className='td-right'>
				<NumberFormat
					value={cruiseData? cruiseData.totalVND: 0}
					displayType={'text'}
					thousandSeparator='.'
					decimalSeparator=','
					decimalScale={0}
				/>
			</td>
			<td className='td-right'>
				<NumberFormat
					value={cruiseData? cruiseData.totalPax: 0}
					displayType={'text'}
					thousandSeparator='.'
					decimalSeparator=','
					decimalScale={0}
				/>
			</td>
		</Fragment>
	);
};

export default TopAccountTdSubItem;
