import { CRUISE_SETTINGS_LOADED_SUCCESS, CRUISE_SETTINGS_LOADED_FAIL, ADD_CRUISE_SETTING, FIND_CRUISE_SETTING, UPDATE_CRUISE_SETTING, DELETE_CRUISE_SETTING } from '../contexts/constants';

export const cruiseSettingReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case CRUISE_SETTINGS_LOADED_SUCCESS:
            return {
                ...state,
                cruiseSettings: payload,
                cruiseSettingsLoading: false
            }
        case CRUISE_SETTINGS_LOADED_FAIL:
            return {
                ...state,
                cruiseSettings: [],
                cruiseSettingsLoading: false
            }
        case ADD_CRUISE_SETTING:
            {
                return {
                    ...state,
                    cruiseSettings: [...state.cruiseSettings, payload],
                }
            }
        case FIND_CRUISE_SETTING:
            return {
                ...state,
                cruiseSetting: payload
            }
        case UPDATE_CRUISE_SETTING:
            const newCruiseSetting = state.cruiseSettings.map(cruiseSetting => {
                if (cruiseSetting.id === payload.id) {
                    return payload
                } else {
                    return cruiseSetting
                }
            })
            return {
                ...state,
                cruiseSettings: newCruiseSetting
            }
        case DELETE_CRUISE_SETTING:
            return {
                ...state,
                cruiseSettings: state.cruiseSettings.filter(cruiseSetting => cruiseSetting.id !== payload)
            }
        default:
            return state
    }
}