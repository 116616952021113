import { createContext, useReducer, useState } from 'react';
import { itineraryReducer } from '../reducers/itineraryReducer';
import { apiUrl, ITINERARIES_LOADED_SUCCESS, ITINERARIES_LOADED_FAIL, ADD_ITINERARY, FIND_ITINERARY, UPDATE_ITINERARY, DELETE_ITINERARY } from './constants';
import axios from 'axios';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';


export const ItineraryContext = createContext()

const ItineraryContextProvider = ({ children }) => {

    // State
    const [itineraryState, dispatch] = useReducer(itineraryReducer, {
        itinerary: null,
        itineraries: [],
        itinerariesLoading: true
    })

    const [showAddItineraryModal, setShowAddItineraryModal] = useState(false)
    const [showUpdateItineraryModal, setShowUpdateItineraryModal] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all specials
    const getItineraries = async objectValidate => {
        const response = await getAPI(`${apiUrl}/cruise-itineraries`, `${objectValidate}`)
        if(response.data){
            dispatch({type: ITINERARIES_LOADED_SUCCESS, payload: response.data})
        } else {
            dispatch({ type: ITINERARIES_LOADED_FAIL })
        }
        
        return response
    }

    // Add Itinerary
    const addItinerary = async newItinerary => {
        const response = await postAPI(`${apiUrl}/cruise-itinerary/insert`, newItinerary)
        if(response.data){
            dispatch( {
                type: ADD_ITINERARY,
                payload: response.data
            } )
        }
        return response
    }

    // Find special when user is updating itinerary
    const findItinerary = itineraryId => {
        const itinerary = itineraryState.itineraries.find(itinerary => itinerary.id === itineraryId)
        dispatch({
            type: FIND_ITINERARY,
            payload: itinerary
        })
    }

    // Update Itinerary
    const updateItinerary = async updateItinerary => {
        const response = await postAPI(`${apiUrl}/cruise-itinerary/update`, updateItinerary)
        if(response.status === 200){
            dispatch( {
                type: UPDATE_ITINERARY,
                payload: updateItinerary
            } )
        }
        return response
    }

    // Delete Itinerary
    const deleteItinerary = async itineraryId => {
        const response = await getDeleteAPI(`${apiUrl}/cruise-itinerary/${itineraryId}/delete`)
        if(response.status === 200) {
            dispatch( {
                type: DELETE_ITINERARY,
                payload: itineraryId
            } )
        }
        return response
    }

    const itineraryContextData = {
        itineraryState,
        getItineraries,
        addItinerary,
        findItinerary,
        updateItinerary,
        deleteItinerary,
        showAddItineraryModal,
        setShowAddItineraryModal,
        showUpdateItineraryModal,
        setShowUpdateItineraryModal,
        showToast,
        setShowToast
    }

    return (
        <ItineraryContext.Provider value={itineraryContextData}>
            {children}
        </ItineraryContext.Provider>
    )
}

export default ItineraryContextProvider