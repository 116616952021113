import { Fragment, useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { SpecialContext } from '../../contexts/SpecialContext';
import { CruiseContext } from '../../contexts/CruiseContext';
import { RoomTypeContext } from '../../contexts/RoomTypeContext';
import { ItineraryContext } from '../../contexts/ItineraryContext';
import DeleteModal from '../../components/layout/DeleteModal';
import UpdateCabinModal from './UpdateCabinModal';
import { Card, Spinner } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { ROLES, PERMISSIONS } from '../../contexts/constants';
import { getTable, getTableOptions, getDefaultSort } from '../../utils/table';
import { useTranslation } from 'react-i18next';

const ListCabins = (props) => {
	const { t } = useTranslation();
	// Context
	const {
		authState: { user }
	} = useContext(AuthContext);

	const {
		specialState: { special, specials, specialsLoading },
		getSpecials,
		findSpecial,
		deleteSpecial,
		setShowUpdateSpecialModal,
		setShowToast
	} = useContext(SpecialContext);

	const {
		cruiseState: { cruises }
	} = useContext(CruiseContext);

	const {
		itineraryState: { itineraries },
		getItineraries
	} = useContext(ItineraryContext);
	// lay toan bo room types từ room type context.
	const {
		roomTypeState: { roomTypes },
		getRoomTypes
	} = useContext(RoomTypeContext);

	const currentCruise = props.cruise;

	// Start: Get all room types , []
	useEffect(() => {
		if (!roomTypes || roomTypes.length === 0) {
			getRoomTypes('');
		}
	}, []);

	// Start: Get all specials , []
	useEffect(() => getSpecials(), []);

	// Start: Get all cruise , []
	useEffect(() => getItineraries(''), []);

	const chooseSpecial = (specialId) => {
		findSpecial(specialId);
		setShowUpdateSpecialModal(true);
	};

	if (specialsLoading) {
		return (
			<div className='spinner-container'>
				<Spinner animation='border' variant='info'></Spinner>
			</div>
		);
	}

	if (specials === null || specials.length === 0) {
		return (
			<Fragment>
				<Card className='text-center mx-5 my-5'>
					<Card.Header as='h1'>{t('Hi')} {user.name}</Card.Header>
					<Card.Body>
						<Card.Title>{t('Welcome to Special Cabin Manage')}</Card.Title>
						<Card.Text>{t('Click The Button Add Cabin Special to Create Your First Special Cabin')}</Card.Text>
					</Card.Body>
				</Card>
				{special !== null && <UpdateCabinModal />}
			</Fragment>
		);
	}
	const tableProps = {
		currentCruise,
		user,
		itineraries,
		cruises,
		specials,
		chooseSpecial,
		deleteSpecial,
		setShowToast,
		t
	};
	return getListCabinsTable(tableProps);
};

const getListCabinsTable = (tableProps) => {
	if (!tableProps.specials || tableProps.specials.length === 0) return null;
	const tableData = getTableData(tableProps);
	const columns = getColumns(tableProps.t);
	const defaultSorted = getDefaultSort([['index', 'asc']]);
	const options = getTableOptions(tableData.length, tableProps.t);

	return getTable(tableData, columns, defaultSorted, options);
};

const getTableData = (tableProps) => {
	const { currentCruise, user, itineraries, cruises, specials, chooseSpecial, deleteSpecial, setShowToast, t } =
		tableProps;
	return specials
		.filter((item) => {
			return currentCruise ? item.cruise_id === currentCruise.value : true;
		})
		.map((special, index) => {
			const nameCruise = cruises.find((cruise) => cruise.id === special.cruise_id);
			const nameItinerary = itineraries.find((itinerary) => itinerary.id === special.duration);
			return {
				index: index + 1,
				id: special.id,
				name: special.name,
				price: (
					<NumberFormat
						value={special.price}
						displayType={'text'}
						thousandSeparator='.'
						decimalSeparator=','
						decimalScale={0}
					/>
				),
				money_type: special.money_type.toUpperCase(),
				name_cruise: nameCruise && nameCruise.name ? nameCruise.name : null,
				name_itinerary: nameItinerary && nameItinerary.name ? nameItinerary.name : null,
				action: (
					<div className='row'>
						<div className='col-sm'>
							{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
							user.Permissions.includes(PERMISSIONS.UPDATE_CABIN_SPECIAL) ? (
								<button className='btn btn-success btn-with-icon btn-block' onClick={chooseSpecial.bind(this, special.id)}>
									<i className='fe fe-edit'></i> {t('Edit')}
								</button>
							) : null}
						</div>
						<div className='col-sm'>
							{user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ||
							user.Permissions.includes(PERMISSIONS.DELETE_CABIN_SPECIAL) ? (
								<DeleteModal idProps={special.id} deleteFunc={deleteSpecial} setShowToast={setShowToast} nameProps={special.name} />
							) : null}
						</div>
					</div>
				)
			};
		});
};

const getColumns = (t) => {
	return [
		{
			dataField: 'index',
			text: 'STT',
			sort: true
		},
		{
			dataField: 'id',
			text: 'ID',
			sort: true
		},
		{
			dataField: 'name',
			text: t('Name'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'price',
			text: t('Price'),
			sort: true,
			classes: 'td-right'
		},
		{
			dataField: 'money_type',
			text: t('Currency'),
			sort: true
		},
		{
			dataField: 'name_cruise',
			text: t('Cruise Name'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'name_itinerary',
			text: t('Itinerary Name'),
			sort: true,
			classes: 'td-left'
		},
		{
			dataField: 'action',
			text: ''
		}
	];
};

export default ListCabins;
