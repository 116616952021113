import { Fragment, useContext } from 'react';
import {Helmet} from "react-helmet";
import RevenueController from '../../components/layout/accountants/RevenueController';
import { AuthContext } from '../../contexts/AuthContext'
import { ROLES, NO_PERMISSION } from '../../contexts/constants'
import { useTranslation } from 'react-i18next';

const Revenue = () => {
    const { t } = useTranslation();
    const {
        authState: {user }
    } = useContext(AuthContext)

    if (!user) {
        return NO_PERMISSION
    }

    if (!(user.Roles.includes(ROLES.OPT) || user.Roles.includes(ROLES.ACC) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))) {
        return NO_PERMISSION
    }

    const dt = new Date();
    const month = ("0" + (dt.getMonth() + 1)).slice(-2); //("0" + 3).slice(-2); //("0" + (dt.getMonth() + 1)).slice(-2);
    const year = dt.getFullYear(); //2019 //dt.getFullYear();

    return (
        <Fragment>
            <Helmet>
                <title>{t('Revenues in') + `: ${month}, ${year}`}</title>
            </Helmet>
            <div className="horizontal-mainwrapper container-fluid clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-3">
                        <div className="row">
                            <div className="col-md-4">
                                <h3>{t('Revenues in')}: {month}, {year}</h3>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <RevenueController monthProps={month} yearProps={year} />
                    <div className='margin-bottom-150'></div>
                </div>
            </div>
        </Fragment>
    )
}

export default Revenue
