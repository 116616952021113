import * as XLSX from 'xlsx';
import * as XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';
export const handleExportPartnerRevenue = async (exportPartnerRevenueData, dateInfo, cruises, reportTitle) => {
	const ws = XLSX.utils.json_to_sheet(exportPartnerRevenueData, { origin: 'A3' });
	const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
	const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	const columnLength = Object.values(exportPartnerRevenueData[0]).length;

	XLSX.utils.sheet_add_aoa(ws, [[reportTitle.toUpperCase()]], { origin: 'A1' });
	
	// merge example
	const merge = [
		{ s: { r: 0, c: 0 }, e: { r: 0, c: columnLength - 1 } }, // title, r = row, c = column

		{ s: { r: 1, c: 0 }, e: { r: 2, c: 0 } }, // title, r = row, c = column
		{ s: { r: 1, c: 1 }, e: { r: 2, c: 1 } }, // title, r = row, c = column
		{ s: { r: 1, c: 2 }, e: { r: 2, c: 2 } }, // title, r = row, c = column
		{ s: { r: 1, c: 3 }, e: { r: 1, c: 5 } }, // title, r = row, c = column
	];

	for (let i = 6; i < columnLength - 2; i += 3) {
		merge.push({
			s: { r: 1, c: i }, e: { r: 1, c: i + 2 }
		})
	}

	merge.push({s: { r: 1, c: columnLength - 2 }, e: { r: 2, c: columnLength - 2 }})
	merge.push({s: { r: 1, c: columnLength - 1 }, e: { r: 2, c: columnLength - 1 }})

	ws['!merges'] = merge;

	XLSX.utils.sheet_add_aoa(ws, [['STT']], { origin: 'A2' });
	XLSX.utils.sheet_add_aoa(ws, [['ĐỐI TÁC']], { origin: 'B2' });
	XLSX.utils.sheet_add_aoa(ws, [['GIAO CHO']], { origin: 'C2' });
	XLSX.utils.sheet_add_aoa(ws, [['TỔNG']], { origin: 'D2' });
	XLSX.utils.sheet_add_aoa(ws, [['USD']], { origin: 'D3' });
	XLSX.utils.sheet_add_aoa(ws, [['VND']], { origin: 'E3' });
	XLSX.utils.sheet_add_aoa(ws, [['NGƯỜI']], { origin: 'F3' });

	for (let i = 0; i < cruises.length; i++) {
		const j = 6 + i * 3;
		XLSX.utils.sheet_add_aoa(ws, [[cruises[i].name.toUpperCase()]], { origin: {r: 1, c: j} });
		XLSX.utils.sheet_add_aoa(ws, [['USD']], { origin: {r: 2, c: j} });
		XLSX.utils.sheet_add_aoa(ws, [['VND']], { origin: {r: 2, c: j + 1} });
		XLSX.utils.sheet_add_aoa(ws, [['NGƯỜI']], { origin: {r: 2, c: j + 2} });
	}

	XLSX.utils.sheet_add_aoa(ws, [['BOOKINGS']], { origin: { r: 1, c: columnLength - 2 } });
	XLSX.utils.sheet_add_aoa(ws, [['ĐẠT %']], { origin: { r: 1, c: columnLength - 1 } });

	const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
	const data = new Blob([excelBuffer], { type: fileType });
	const rowLength = exportPartnerRevenueData.length + 2;
	
	return addStylePartnerRevenue(data, rowLength, columnLength);
};

const addStylePartnerRevenue = (workbookBlob, rowLength, columnLength) => {
	let lastColumn = '';
	return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
		workbook.sheets().forEach((sheet) => {
			// general style
			sheet.usedRange().style({
				fontFamily: 'Times New Roman',
				verticalAlignment: 'center',
				horizontalAlignment: 'center',
				wrapText: true
			});

			// set width for columns
			sheet.column('A').width(15).style({ horizontalAlignment: 'right' });
			sheet.column('B').width(30).style({ horizontalAlignment: 'left' });
			sheet.column('C').width(20).style({ horizontalAlignment: 'left' });
			for (let i = 4; i < columnLength + 1; i++) {
				if (i == columnLength) {
					sheet.column(i).width(15).style({ horizontalAlignment: 'center' });
					lastColumn = sheet.column(i).columnName();
				} else {
					sheet.column(i).width(15).style({ horizontalAlignment: 'right' });
				}
			}
			
			sheet.range(`A2:${lastColumn}${rowLength + 1}`).style({
				border: true,
				
			});

			// header style
			sheet.range(`A2:${lastColumn}3`).style({
				fill: '82AEE8',
				horizontalAlignment: 'center'
			});
			sheet.range(`A1:${lastColumn}1`).style({
				horizontalAlignment: 'left',
				bold: true,
				fontSize: 14,
				verticalAlignment: 'center',
			});
			sheet.row(1).height(60);

			for (let i = 4; i < rowLength + 2; i++) {
				if (i % 2 === 0) continue;
				sheet.range(`A${i}:${lastColumn}${i}`).style({
					fill: 'e5f3f3'
				});
			}
		});

		return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob));
	});
};
