import { Fragment, useContext, useEffect, useState } from 'react';
import { CruiseSettingContext } from '../../contexts/CruiseSettingContext';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { apiUrl } from '../../contexts/constants';
import PaymentInformationVat from './PaymentInformationVat';
import PaymentInformationNoVat from './PaymentInformationNoVat';
import { postAPI } from '../../utils/api';
import { useTranslation } from 'react-i18next';

const UpdateCruiseSettingModal = () => {
	const { t } = useTranslation();
	const {
		cruiseSettingState: { cruiseSetting },
		showUpdateCruiseSettingModal,
		setShowUpdateCruiseSettingModal,
		updateCruiseSetting,
		clearCruise,
		setShowToast
	} = useContext(CruiseSettingContext);

	// State
	const [updatedCruiseSetting, setUpdatedCruiseSetting] = useState(cruiseSetting);

	const [weekend, setWeekend] = useState([]);
	const [holidays, setHolidays] = useState([]);

	useEffect(() => {
		setUpdatedCruiseSetting(cruiseSetting);
		if (cruiseSetting.holidays) setHolidays(cruiseSetting.holidays.split(','));
		if (cruiseSetting.weekend) setWeekend(cruiseSetting.weekend.split(','));
	}, [cruiseSetting]);

	const { cruise_id, property_information, important_note } = updatedCruiseSetting;

	const [paymentInformation, setPaymentInformation] = useState([]);

	useEffect(() => {
		if (cruiseSetting.payment_information !== undefined) {
			setPaymentInformation(JSON.parse(cruiseSetting.payment_information));
		}
	}, [cruiseSetting]);

	const arrSlides = cruiseSetting.images ? cruiseSetting.images.split(',') : [];

	//JSON.stringify(newBooking)
	const ChangeToSlug = (Text) => {
		var slug;

		//Đổi chữ hoa thành chữ thường
		slug = Text.toLowerCase();

		//Đổi ký tự có dấu thành không dấu
		slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
		slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
		slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
		slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
		slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
		slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
		slug = slug.replace(/đ/gi, 'd');
		//Xóa các ký tự đặt biệt
		slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi, '');
		//Đổi khoảng trắng thành ký tự gạch ngang
		slug = slug.replace(/ /gi, '-');
		//Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
		//Phòng trường hợp người nhập vào quá nhiều ký tự trắng
		slug = slug.replace(/\-\-\-\-\-/gi, '-');
		slug = slug.replace(/\-\-\-\-/gi, '-');
		slug = slug.replace(/\-\-\-/gi, '-');
		slug = slug.replace(/\-\-/gi, '-');
		//Xóa các ký tự gạch ngang ở đầu và cuối
		slug = '@' + slug + '@';
		slug = slug.replace(/\@\-|\-\@|\@/gi, '');
		//In slug ra textbox có id “slug”
		return slug;
	};

	const randomText = (length) => {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	};

	const handleChangeUploadPhoto = async (event) => {
		event.preventDefault();
		const file = event.target.files[0];
		const formData = new FormData();
		const nameFile = ChangeToSlug(cruiseSetting.cruise_name);
		const ext = file.name.split('.').slice(-1)[0];
		formData.append('file', file, nameFile + '-' + randomText(8) + '.' + ext);
		const response = await postAPI(`${apiUrl}/file/upload?folder=cruises/logo`, formData);

		if (response) {
			const fileUrl = new URL(response.data.url);
			setUpdatedCruiseSetting({ ...updatedCruiseSetting, logo: fileUrl.pathname });
		}
	};

	const handleChangeUploadSlides = async (event) => {
		//
		event.preventDefault();
		const files = event.target.files;
		var ext = '';
		var textFile = '';
		const formData = new FormData();
		for (let i = 0; i < files.length; i++) {
			if (i === 0) {
				ext = files[i].name.split('.').slice(-1)[0];
				formData.append(`files`, files[i], ChangeToSlug(files[i].name.split('.')[0]) + '-' + randomText(8) + '.' + ext);
				textFile = ChangeToSlug(files[i].name.split('.')[0]) + '-' + randomText(8) + '.' + ext;
			} else {
				ext = files[i].name.split('.').slice(-1)[0];
				formData.append(`files`, files[i], ChangeToSlug(files[i].name.split('.')[0]) + '-' + randomText(8) + '.' + ext);
				textFile = textFile + ', ' + ChangeToSlug(files[i].name.split('.')[0]) + '-' + randomText(8) + '.' + ext;
			}
		}

		try {
			const response = await postAPI(`${apiUrl}/file/upload-multiple?folder=cruises/slides`, formData);
			if (response) {
				var textDataFiles = '';
				response.data.urls.map((itemUrl, index) => {
					const fileUrl = new URL(itemUrl);
					if (index === 0) {
						textDataFiles = fileUrl.pathname;
					} else {
						textDataFiles = textDataFiles + ',' + fileUrl.pathname;
					}
				});
				if (cruiseSetting.images !== undefined) {
					setUpdatedCruiseSetting({ ...updatedCruiseSetting, images: cruiseSetting.images + ',' + textDataFiles });
				} else {
					setUpdatedCruiseSetting({ ...updatedCruiseSetting, images: textDataFiles });
				}
			}
		} catch (error) {
			return error.response ? error.response : { success: false, message: 'Server error!' };
		}
	};

	const onChangeChildLabel = (event) => {
		setUpdatedCruiseSetting({ ...updatedCruiseSetting, [event.target.name]: event.target.value });
	}

	const onSubmit = async (event) => {
		event.preventDefault();
		updatedCruiseSetting['payment_information'] = JSON.stringify(paymentInformation);
		updatedCruiseSetting['weekend'] = weekend.join(',');
		updatedCruiseSetting['holidays'] = holidays.join(',');

		const response = await updateCruiseSetting(updatedCruiseSetting);
		if (response.status === 200) {
			closeDialog();
			setShowToast({ show: true, message: 'Updated successful!', type: 'success' });
			return;
		}
		setShowToast({
			show: true,
			message: response.error && response.error.ErrorCode ? response.error.ErrorCode : 'Update failed!',
			type: 'danger'
		});
	};

	const closeDialog = () => {
		// setUpdatedCruiseSetting({ important_note: '', property_information: '' });
		clearCruise();
		setShowUpdateCruiseSettingModal(false);
		setHolidays([]);
		setWeekend([]);

		//window.location.reload()
	};

	const onSelectDate = (data) => {
		if (!data || !data.target || !data.target.value) {
			return;
		}
		const index = holidays.indexOf(data.target.value);
		if (index > -1) return;
		setHolidays([...holidays, data.target.value]);
	};
	const removeHolidayItem = (item, object) => {
		if (!item) {
			return;
		}
		const index = holidays.indexOf(item);
		if (index > -1) {
			holidays.splice(index, 1);
			setHolidays([...holidays]);
		}
	};
	const removeAllHolidayItems = () => {
		setHolidays([]);
	};

	const weekendOnchange = (object) => {
		// checked
		if (object.target.checked) {
			setWeekend([...weekend, object.target.value]);

			return;
		}

		// unchecked
		const index = weekend.indexOf(object.target.value);
		if (index > -1) {
			weekend.splice(index, 1);
			setWeekend([...weekend]);
		}
	};
	const isWeekend = (val) => {
		return weekend.indexOf(val) > -1;
	};

	return (
		<Fragment>
			<Modal
				show={showUpdateCruiseSettingModal}
				onHide={closeDialog}
				size='full'
				aria-labelledby='contained-modal-title-vcenter'
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Update Setting Cruises</Modal.Title>
				</Modal.Header>
				<Form onSubmit={onSubmit}>
					<Modal.Body>
						<div className='form-group'>
							<Row>
								<Col>
									<label>
										<strong>Cruise</strong>
									</label>
									<input
										type='text'
										id='imageAvatar'
										value={cruiseSetting.cruise_name}
										className='form-control'
										readOnly
									/>
								</Col>
								<Col>
									<label>
										<strong>Cruise Logo (300x300px)</strong>
									</label>
									<input
										type='file'
										id='imageAvatar'
										onChange={handleChangeUploadPhoto.bind(this)}
										placeholder='Upload Logo'
										className='form-control'
									/>
								</Col>
								<Col>
									<label>
										<strong>Slides</strong>
									</label>
									<input
										type='file'
										id='fileContract'
										onChange={handleChangeUploadSlides.bind(this)}
										placeholder='Upload Contract'
										multiple
										className='form-control'
									/>
								</Col>
							</Row>
							<Row>
								{cruiseSetting.logo && (
									<div className='col-md-2'>
										<img width={130} alt='' src={cruiseSetting.logo && `${apiUrl}/${cruiseSetting.logo}`} />
									</div>
								)}
								{cruiseSetting.images && (
									<div className='col-md-10'>
										<div className='image-block'>
											{arrSlides.map((slide, index) => (
												<div key={index} className='image-block-item'>
													<img width={130} alt='' src={cruiseSetting.logo && `${apiUrl}/${slide}`} />
												</div>
											))}
										</div>
									</div>
								)}
							</Row>
						</div>
						<div className='form-group'>
							<Card>
								<Card.Body>
									<label>
										<strong>Payment Information Group</strong>
									</label>
									<div className='info-payment mt-3'>
										{paymentInformation.length > 0 && (
											<Row>
												<Col>
													<PaymentInformationVat
														paymentInformationVat={paymentInformation[0]}
														paymentInformation={paymentInformation}
													/>
												</Col>
												<Col>
													<PaymentInformationNoVat
														paymentInformationVat={paymentInformation[1]}
														paymentInformation={paymentInformation}
													/>
												</Col>
											</Row>
										)}
									</div>
								</Card.Body>
							</Card>
						</div>
						<div className='form-group'>
							<Card>
								<Card.Body>
									<fieldset className='form-group child-label'>
										<label>
											<strong>{t('Child label')}</strong>
										</label>
										<Row>
											<Col className='col-md-6'>
												<div className='form-group'>
													<div className='row'>
														<div className='col-md-3'>
															<label>{t('Child label 1')}</label>
														</div>
														<div className='col-md-9'>
															<input
																type='text'
																className='form-control'
																placeholder={t('Child (5-7)')}
																id='child-label-1'
																name='child_label1'
																value={updatedCruiseSetting.child_label1}
																onChange={onChangeChildLabel.bind(this)}
															/>
														</div>
													</div>
												</div>
												<div className='form-group'>
													<div className='row'>
														<div className='col-md-3'>
															<label>{t('Child label 2')}</label>
														</div>
														<div className='col-md-9'>
															<input
																type='text'
																className='form-control'
																placeholder={t('Child (8-11)')}
																id='child-label-2'
																name='child_lable2'
																value={updatedCruiseSetting.child_lable2}
																onChange={onChangeChildLabel.bind(this)}
															/>
														</div>
													</div>
												</div>
											</Col>
										</Row>
									</fieldset>
								</Card.Body>
							</Card>
						</div>
						<div className='form-group'>
							<Card>
								<Card.Body>
									<fieldset className='form-group weekend'>
										<label>
											<strong>Weekend?</strong>
										</label>
										<Row>
											<Col>
												<div className='weekend-item'>
													<input
														key='1'
														type='checkbox'
														name='weekend'
														value='1'
														onChange={weekendOnchange}
														checked={isWeekend('1')}
													/>
													Monday
												</div>
												<div className='weekend-item'>
													<input
														key='2'
														type='checkbox'
														name='weekend'
														value='2'
														onChange={weekendOnchange}
														checked={isWeekend('2')}
													/>
													Tuesday
												</div>
												<div className='weekend-item'>
													<input
														key='3'
														type='checkbox'
														name='weekend'
														value='3'
														onChange={weekendOnchange}
														checked={isWeekend('3')}
													/>
													Wednesday
												</div>
												<div className='weekend-item'>
													<input
														key='4'
														type='checkbox'
														name='weekend'
														value='4'
														onChange={weekendOnchange}
														checked={isWeekend('4')}
													/>
													Thursday
												</div>
												<div className='weekend-item'>
													<input
														key='5'
														type='checkbox'
														name='weekend'
														value='5'
														onChange={weekendOnchange}
														checked={isWeekend('5')}
													/>
													Friday
												</div>
												<div className='weekend-item'>
													<input
														key='6'
														type='checkbox'
														name='weekend'
														value='6'
														onChange={weekendOnchange}
														checked={isWeekend('6')}
													/>
													Saturday
												</div>
												<div className='weekend-item'>
													<input
														key='0'
														type='checkbox'
														name='weekend'
														value='0'
														onChange={weekendOnchange}
														checked={isWeekend('0')}
													/>
													Sunday
												</div>
											</Col>
										</Row>
									</fieldset>
									<fieldset>
										<label>
											<strong>Holidays?</strong>
										</label>
										<Row>
											<Col className='form-group col-md-3'>
												<label>
													<strong>Select date</strong>
												</label>
												<Form.Control key='weekend' type='date' name='holiday' onChange={onSelectDate} />
											</Col>
											<Col className='form-group col-md-9'>
												<label>
													<strong>Selected dates</strong>
												</label>
												<div className='holiday-group'>
													{holidays &&
														holidays.map((item) => {
															return (
																<div className='holiday-item'>
																	<label>{item}</label>
																	<button
																		type='button'
																		className='close'
																		aria-label='Close'
																		onClick={removeHolidayItem.bind(this, item)}
																	>
																		<span aria-hidden='true'>&times;</span>
																	</button>
																</div>
															);
														})}
													{holidays && holidays.length > 0 && (
														<button
															type='button'
															className='close remove-all'
															aria-label='Close'
															title='Remove All'
															onClick={removeAllHolidayItems.bind(this)}
														>
															<span aria-hidden='true'>&times;</span>
														</button>
													)}
												</div>
											</Col>
										</Row>
									</fieldset>
								</Card.Body>
							</Card>
						</div>

						<div className='form-group'>
							<label>
								<strong>Important Note</strong>
							</label>
							<div className='info-payment'>
								<CKEditor
									editor={ClassicEditor}
									data={important_note}
									onReady={(editor) => {
										// You can store the "editor" and use when it is needed.
										//console.log( 'Editor is ready to use!', editor );
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setUpdatedCruiseSetting({ ...updatedCruiseSetting, important_note: data });
										//console.log( { event, editor, data } );
									}}
									onBlur={(event, editor) => {
										//console.log( 'Blur.', editor );
									}}
									onFocus={(event, editor) => {
										//console.log( 'Focus.', editor );
									}}
								/>
							</div>
						</div>
						<div className='form-group'>
							<label>
								<strong>Property Information</strong>
							</label>
							<div className='info-property'>
								<CKEditor
									style={{ maxHeight: '650px', overflow: 'scroll' }}
									editor={ClassicEditor}
									data={property_information}
									onReady={(editor) => {
										// You can store the "editor" and use when it is needed.
										//console.log( 'Editor is ready to use!', editor );
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setUpdatedCruiseSetting({ ...updatedCruiseSetting, property_information: data });
										//console.log( { event, editor, data } );
									}}
									onBlur={(event, editor) => {
										//console.log( 'Blur.', editor );
									}}
									onFocus={(event, editor) => {
										//console.log( 'Focus.', editor );
									}}
								/>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button className='btn btn-danger btn-with-icon' variant='secondary' onClick={closeDialog}>
							<i className='fe fe-x-circle'></i> Cancel
						</Button>
						<Button className='btn btn-primary btn-with-icon' variant='primary' type='submit'>
							<i className='fe fe-save'></i> Save!!!
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</Fragment>
	);
};

export default UpdateCruiseSettingModal;
