import { Fragment, useState, useRef, useContext } from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
//import { AuthContext } from '../../contexts/AuthContext';
import MessageNotification from './mainHeader/MessageNotification';
import Notifications from './mainHeader/Notifications';
import ProfileMenu from './mainHeader/ProfileMenu';
import Languages from './mainHeader/Languages';
// image
//import desktopImg from '../../assets/img/brand/logo-white.png';
//import logoImg from '../../assets/img/brand/logo.png';
import iconImg from '../../assets/img/brand/logo.png';
import iconImgWhite from '../../assets/img/brand/favicon-white.png';
import { ROLES, apiUrl } from '../../contexts/constants';
import jQuery from 'jquery';
import { getAPI } from '../../utils/api';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../contexts/AuthContext';

const MainHeaderTop = () => {
    const {
        authState: {user }
    } = useContext(AuthContext)
    const menuOnClick = () => {
        jQuery('body').toggleClass('active menu-open');
    }

    const [options, setOptions] = useState([]);
    const previousController = useRef();

    const getData = async (searchTerm) => {
        if (user.Roles.length == 1 && user.Roles.includes(ROLES.PARTNER)) {
            setOptions([])
            return
        }
        if (previousController.current) {
          previousController.current.abort();
        }
        const controller = new AbortController();
        const signal = controller.signal;
        previousController.current = controller;
        const response = await getAPI(`${apiUrl}/booking/search/` + searchTerm, '', {
          signal,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          }
        })
        
        if (response.error) {
            setOptions([])
            return
        }

        if (response.data) {
            setOptions(response.data)
        }
      };
      

    const onInputChange = (event, value, reason) => {
        if (reason === 'clear') {
            return;
        }

        // selected event
        if (event.type === 'click' && reason === 'reset') {
            const selectedVals = value.split('|');
            const seletedOption = options.find((opt) => {
                
                return opt.booking_code === selectedVals[0].trim();
            })
            if (seletedOption) {
                window.open( `/bookings/show/${seletedOption.booking_id}`, '_blank')
            }
        }

        if (value.length < 3) {
            return
        }

        if (value) {
            getData(value);
        } else {
            setOptions([]);
        }
    };

    const { t } = useTranslation();
    return (
        <Fragment>
            <div className="main-header nav nav-item hor-header">
                <div className="container">
                    <div className="main-header-left ">
                        <a className="animated-arrow hor-toggle horizontal-navtoggle" onClick={menuOnClick.bind(this)}><span></span></a>
                        <Link className="header-brand" to="/" title="K2 Software">
                            <img src={iconImg} className="desktop-dark" alt="K2 Software" />
                            <img src={iconImg} className="desktop-logo-1" alt="K2 Software" />
                            <strong className="float-left" style={ {lineHeight: "32px"} }><img src={iconImg} className="desktop-logo float-left" alt="K2 Software" /></strong>
                            <img src={iconImgWhite} className="desktop-logo-dark" alt="K2 Software" />
                        </Link>
                        <div className="main-header-center ml-4">
                            <div className='header-search'>
                                <Autocomplete
                                    id="booking-search"
                                    options={options}
                                    onInputChange={onInputChange}
                                    getOptionLabel={(option) => {
                                        return `${option.booking_code} | ${option.partner_code ? option.partner_code: ''} | ${option.partner_name ? option.partner_name: ''} | ${option.customer_name? option.customer_name: ''}`;
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t("Search booking")} variant="outlined" />
                                    )}
                                />
                            </div>
                            {/*<input className="form-control" placeholder="Search..." type="search" /><button className="btn"><i className="bx bx-search"></i></button>*/}
                        </div>
                    </div>
                    <div className="main-header-right">
                        <div className="nav nav-item  navbar-nav-right ml-auto">
                            <div className='dropdown nav-item main-header-message contact affiliate'><a href="https://k2software.vn/tin-tuc/kiem-30-doanh-thu-tron-doi-voi-k2-cruise-phan-mem-quan-ly-du-thuyen-hang-dau" target='_blank'><i className='fe fe-dollar-sign'></i>{t('Affiliate')}</a></div>
                            <div className='dropdown nav-item main-header-message contact'><a href="https://k2software.vn/#home-contact" target='_blank'><i className="fe fe-inbox"></i>{t('Contact Us')}</a></div>
                            <Languages />
                            {/* <MessageNotification /> */}
                            {/* <Notifications /> */}
                            <ProfileMenu />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default MainHeaderTop
