import { Fragment, useContext, useEffect, useState } from 'react'
import ListPartner from '../../components/layout/partners/ListPartner'
import AddPartnerModal from '../../components/layout/partners/AddPartnerModal'
import ImportPartnerModal from '../../components/layout/partners/ImportPartnerModal'
import { PartnerContext } from '../../contexts/PartnerContext'
import { AuthContext } from '../../contexts/AuthContext'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ListExportPartner from '../../components/layout/partners/ListExportPartner'
import { ROLES, PERMISSIONS, NO_PERMISSION } from '../../contexts/constants';
import { useTranslation } from 'react-i18next'
import { findOptionItem, getOptions } from '../../utils/common';
import Select from 'react-select';

const Partner = () => {
    const { t } = useTranslation();
    useEffect(() => getUsers('?department=sale'), []) // eslint-disable-line react-hooks/exhaustive-deps
    const {
        setShowAddPartnerModal,
        setShowImportPartnerModal
    } = useContext(PartnerContext)

    const {
        authState: {user, users },
        getUsers
    } = useContext(AuthContext)


    const [saleUsers, setSaleUsers] = useState([]);
	useEffect(() => {
		const getSaleUsers = async () => {
			const userData = await getUsers('?department=sale');
			if (userData.error) {
				console.log('getSaleUsers error: ', userData.error);
				return;
			}

			setSaleUsers(userData.data);
		};
		getSaleUsers();
	}, []);
	const [optionUsers, setOptionUsers] = useState([]);
	useEffect(() => {
		const partnerOpts = getOptions(saleUsers, ['label', 'value'], ['name', 'id']);
        partnerOpts.unshift({label: 'All', value: ''});
		setOptionUsers(partnerOpts);
	}, [saleUsers]);

    const [assignTo, setAssignTo] = useState();

    const applyFilter = (data) => {
        if (!data) {
            setAssignTo('');
            return;
        }

        setAssignTo(data.value);
    };

    if (!user) {
        return NO_PERMISSION
    }

    if (!(user.Permissions.includes(PERMISSIONS.VIEW_PARTNER) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))) {
        return NO_PERMISSION
    }

    return (
        <Fragment>
            <div className="horizontal-mainwrapper container-fluid clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-3">
                        <div className="row">
                            <div className="col-md-4">
                                <h3 className="pull-left">{t('Partner List')}</h3>
                            </div>
                            <div className="col-md-4 ml-auto btn-partner-list">
                                    <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="btn btn-primary "
                                        table="table-to-xls"
                                        filename={`Export Partner - ${user.name}`}
                                        sheet="sheet 1"
                                        buttonText={t(`Export Partner to Excel`)}
                                    />
                                <button className="btn btn-success  " onClick={setShowAddPartnerModal.bind(this, true)}><i className="fe fe-plus-circle"></i> {t('Add Partner')}</button>
                                <button className="btn btn-success  " onClick={setShowImportPartnerModal.bind(this, true)}><i className="fe fe-plus-circle"></i> {t('Import Partner')}</button>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <div className='row'>
                        <div className='col-md-2'>
                            <div className='form-group'>
                                <label>
                                    <strong>{t('Assign To')}</strong>
                                </label>
                                <Select
                                    isClearable
                                    name='assign_to'
                                    onChange={applyFilter.bind(this)}
                                    menuPosition={'fixed'}
                                    menuPortalTarget={document.body}
                                    options={optionUsers}
                                    // value={findOptionItem(optionUsers, filterDataRevenue.assign_to)}
                                    placeholder={t('Assign To')}
                                />
                            </div>
                        </div>
                    </div>
                    <ListPartner usersProps={users} assignTo={assignTo}/>
                    <ListExportPartner usersProps={users} />
                    <AddPartnerModal usersProps={users} />
                    <ImportPartnerModal />
                    <div className='margin-bottom-150'></div>
                </div>
            </div>
        </Fragment>
    )
}

export default Partner
