import { PERMISSION_ROLE_LOADED_SUCCESS, PERMISSION_ROLE_LOADED_FAIL, ADD_PERMISSION_ROLE, FIND_PERMISSION_ROLE, UPDATE_PERMISSION_ROLE, DELETE_PERMISSION_ROLE } from '../contexts/constants';

export const permissionRoleReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case PERMISSION_ROLE_LOADED_SUCCESS:
            return {
                ...state,
                permissionRoles: payload,
                permissionRolesLoading: false
            }
        case PERMISSION_ROLE_LOADED_FAIL:
            return {
                ...state,
                permissionRoles: [],
                permissionRolesLoading: false
            }
        case ADD_PERMISSION_ROLE:
            {
                return {
                    ...state,
                    permissionRoles: [...state.permissionRole, payload],
                }
            }
        case FIND_PERMISSION_ROLE:
            return {
                ...state,
                permissionRole: payload
            }
        case UPDATE_PERMISSION_ROLE:
            const newPermissionRole = state.permissionRoles.map(permissionRole => {
                if (permissionRole.id === payload.id) {
                    return payload
                } else {
                    return permissionRole
                }
            })
            return {
                ...state,
                permissionRoles: newPermissionRole
            }
        case DELETE_PERMISSION_ROLE:
            return {
                ...state,
                permissionRoles: state.permissionRoles.filter(permissionRole => permissionRole.id !== payload)
            }
        default:
            return state
    }
}