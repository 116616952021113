import {
	CUSTOMER_TRANSPORTS_LOADED_SUCCESS,
	CUSTOMER_TRANSPORTS_LOADED_FAIL,
	ADD_CUSTOMER_TRANSPORT,
	FIND_CUSTOMER_TRANSPORT,
	UPDATE_CUSTOMER_TRANSPORT,
	DELETE_CUSTOMER_TRANSPORT,
	CUSTOMER_NO_TRANSPORTS_LOADED_SUCCESS,
	CUSTOMER_NO_TRANSPORTS_LOADED_FAIL
} from '../contexts/constants';

export const customerTransportReducer = (state, action) => {
	const { type, payload } = action;
	
	switch (type) {
		case CUSTOMER_TRANSPORTS_LOADED_SUCCESS:
			return {
				...state,
				customerTransports: payload,
				customerTransportsLoading: false
			};
		case CUSTOMER_TRANSPORTS_LOADED_FAIL:
			return {
				...state,
				customerTransports: [],
				customerTransportsLoading: false
			};
		case CUSTOMER_NO_TRANSPORTS_LOADED_SUCCESS:
			return {
				...state,
				customerNoTransports: payload,
				customerNoTransportsLoading: false
			};
		case CUSTOMER_NO_TRANSPORTS_LOADED_FAIL:
			return {
				...state,
				customerNoTransports: [],
				customerNoTransportsLoading: false
			};
		case ADD_CUSTOMER_TRANSPORT: {
			return {
				...state,
				customerTransports: [...state.customerTransports, payload]
			};
		}
		case FIND_CUSTOMER_TRANSPORT:
			return {
				...state,
				customerTransport: payload
			};
		case UPDATE_CUSTOMER_TRANSPORT:
			const newcustomerTransport = state.customerTransports.map((customerTransport) => {
				if (customerTransport.id === payload.id) {
					return payload;
				} else {
					return customerTransport;
				}
			});
			return {
				...state,
				cars: newcustomerTransport
			};
		case DELETE_CUSTOMER_TRANSPORT:
			return {
				...state,
				customerTransports: state.customerTransports.filter((customerTransport) => customerTransport.id !== payload)
			};
		default:
			return state;
	}
};
