import { Fragment, useContext, useEffect, useState } from 'react';
import { Bar, Doughnut } from "react-chartjs-2";
import { AuthContext } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { currencyFormatter, numberFormatter, sort } from '../../utils/common';
import { CruiseContext } from '../../contexts/CruiseContext';
import { apiUrl } from '../../contexts/constants';
import { getAPI } from '../../utils/api';

const RevenueChartBar = props => {
    const { t } = useTranslation();
    const { authState: { users }, getUsers } = useContext(AuthContext)
    useEffect(() => getUsers(''), []) // eslint-disable-line react-hooks/exhaustive-deps

    const cruises = props.cruises;
    const year = props.year;
    const cruise = props.cruise;
    const [revenueData, setRevenueData] = useState([]);
    const [filterData, setFilterData] = useState([])

    const getRevenueData = async () => {
        const response = await getAPI(
            `${apiUrl}/revenue-year`,
            `year=${year}`
        );
        
        if (!response.data) {
            setRevenueData([])
            return;
        }

        setRevenueData(response.data);
    };

    useEffect(async () => {
        await getRevenueData();
    }, [year])

    useEffect(() => {
        if (!revenueData || revenueData.length == 0) {
            setFilterData([]);
            return;
        }
        if (!cruise || cruise.value == 'all') {
            setFilterData(revenueData);
            return;
        }
        
        const data = revenueData.filter((item) => item.cruise_id == cruise.value);
        setFilterData(data);
    }, [cruise, revenueData])

    const months = {1: t('January'), 2: t('February'), 3: t('March'), 4: t('April'), 5:t('May'), 6: t('June'), 
    7: t('July'), 8: t('August'), 9: t('September'), 10: t('October'), 11: t('November'), 12: t('December')};

    function getRandomColor() { //generates random colours and puts them in string
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var x = 0; x < 6; x++) {
        color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const getCruiseData = (c, currency = 'vnd') => {
        const result = [];
        for (const [key, value] of Object.entries(months)) {
            const dataItem = filterData.find((item) => {
                return item.month == key && item.cruise_id == c.id;
            });
            result.push(dataItem? dataItem[`revenue_${currency}`]: 0);
        }

        return result;
    };

    let datasets = [];
    let usdDatasets = [];

    cruises.forEach((c) => {
        if (cruise && cruise.value > 0 && cruise.value != c.id) {
            return;
        }
        const bgColor = getRandomColor();
        const borderColor = getRandomColor();
        const data = getCruiseData(c);
        const cDataSet = {
            label: c.name,
            backgroundColor: bgColor,
            borderColor: borderColor,
            borderWidth: 1,
            stack: 1,
            data: data
        }
        datasets.push(cDataSet);

        const usdData = getCruiseData(c, 'usd');
        const cUSDDataSet = {
            label: c.name,
            backgroundColor: bgColor,
            borderColor: borderColor,
            borderWidth: 1,
            stack: 1,
            data: usdData
        }
        usdDatasets.push(cUSDDataSet);
    });

    const totalVND = datasets.reduce((n, {data}) => {
        return n + data.reduce((partialSum, a) => partialSum + a, 0);
    }, 0);
    const totalUSD = usdDatasets.reduce((n, {data}) => {
        return n + data.reduce((partialSum, a) => partialSum + a, 0);
    }, 0)
    
    const monthLabels = Object.values(months);
    
    return (
        <Fragment>
            <div className="horizontal-mainwrapper clearfix">
                <div className="charjs">
                    <div className="row">
                        <div className="col-md-6">
                            <div className='row'>
                                <div className="col-md-12">
                                    <h3>{t('Revenue by VND')}: {currencyFormatter().format(totalVND)}</h3>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-md-12">
                                    <div className="style-bar">
                                        <div className="style-bar-chart">
                                            <Bar
                                                data={{
                                                labels: monthLabels,
                                                datasets: datasets
                                                }}
                                                options={{
                                                    responsive: true,
                                                    legend: { display: false },
                                                    title: {
                                                        display: true,
                                                        text: t("Revenue Report")
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className='row'>
                                <div className="col-md-12">
                                    <h3>{t('Revenue by USD')}: {currencyFormatter('USD').format(totalUSD)}</h3>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-md-12">
                                    <div className="style-bar">
                                        <div className="style-bar-chart">
                                            <Bar
                                                data={{
                                                labels: monthLabels,
                                                datasets: usdDatasets
                                                }}
                                                options={{
                                                    responsive: true,
                                                    legend: { display: false },
                                                    title: {
                                                        display: true,
                                                        text: t("Revenue Report")
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default RevenueChartBar
