import { Fragment, useContext, useState, useEffect } from 'react';
import { RoomContext } from '../../../contexts/RoomContext';
import { RoomTypeContext } from '../../../contexts/RoomTypeContext';
import { CruiseContext } from '../../../contexts/CruiseContext';
import { Modal, Button, Form } from 'react-bootstrap';
import { CRUISE_TYPES, apiUrl } from '../../../contexts/constants';
import { fixFilename, deleteFilesApi, uploadFiles, diffDays, formatDate, formatDateGMT7 } from '../../../utils/common';
import { useTranslation } from 'react-i18next';

const UpdateRoomModal = ({ isView }) => {
	const { t } = useTranslation();
	// Context

	const {
		roomTypeState: { roomTypes }
	} = useContext(RoomTypeContext);
	const {
		roomState: { room },
		showUpdateRoomModal,
		setShowUpdateRoomModal,
		setShowAddRoomModal,
		updateRoom,
		setShowToast,
		clearRoom,
		roomImageUrls,
		setRoomImageUrls,
		deleteFiles,
		setDeleteFiles
	} = useContext(RoomContext);

	const {
		cruiseState: { cruises },
		getCruises
	} = useContext(CruiseContext);

	const checkExtra = () => {
		if (room.extra_bed === 'yes') {
			return true;
		} else {
			return false;
		}
	};
	const checkConnecting = () => {
		if (room.connecting === 'yes') {
			return true;
		} else {
			return false;
		}
	};
	const checkLock = () => {
		if (room.status === 'Locked') {
			return true;
		} else {
			return false;
		}
	};
	const [checked, setChecked] = useState(checkExtra());
	const [checkedCon, setCheckedCon] = useState(checkConnecting());
	const [isConnect, setIsConnect] = useState(checkConnecting());
	const [checkedLock, setCheckedLock] = useState(checkLock());
	const [updatedRoom, setUpdatedRoom] = useState(room);
	const [formDataValue, setFormDataValue] = useState(null);
	useEffect(() => setUpdatedRoom(room), [room]);
	const {
		id,
		name,
		bed_type,
		room_type_id,
		max_room,
		max_adult,
		extra_bed,
		connecting,
		connect_room,
		location,
		description,
		status,
		cruise_id,
		images,
		lock_from,
		lock_to
	} = updatedRoom;

	const closeDialog = () => {
		setShowUpdateRoomModal(false);
		setShowAddRoomModal();
		clearRoom();
		setRoomImageUrls([]);
		setFormDataValue(null);
	};
	useEffect(() => {
		if (images) {
			setRoomImageUrls(images.split(','));
		} else {
			setRoomImageUrls([]);
		}
	}, [images]);

	const handleClickClose = async (url, event) => {
		event.preventDefault();
		if (!url || !roomImageUrls || !roomImageUrls.length) return;

		// call api delete image
		const filename = url.replace(apiUrl, '');
		if (!filename) return;

		setDeleteFiles([...deleteFiles, filename]);
		const imgs = roomImageUrls.reduce((res, item) => {
			if (url !== item) {
				res.push(item);
			}
			return res;
		}, []);
		setRoomImageUrls(imgs);
		setUpdatedRoom({ ...updatedRoom, images: imgs.join(',') });
	};

	const onChangeNewRoomForm = (event) => setUpdatedRoom({ ...updatedRoom, [event.target.name]: event.target.value });
	const onChangeNewRoomFormParseNumber = (event) =>
		setUpdatedRoom({ ...updatedRoom, [event.target.name]: Number(event.target.value) });

	const onChangeCheckBox = (event) => {
		if (event.target.checked === true) {
			setUpdatedRoom({ ...updatedRoom, [event.target.name]: 'yes' });
			setChecked(true);
			return;
		}

		setUpdatedRoom({ ...updatedRoom, [event.target.name]: 'no' });
		setChecked(false);
	};

	const onChangeCheckBoxCon = (event) => {
		if (event.target.name === 'connecting') {
			setIsConnect(!isConnect);
		}
		if (event.target.checked === true) {
			setUpdatedRoom({ ...updatedRoom, [event.target.name]: 'yes' });
			setCheckedCon(true);
			return;
		}

		setUpdatedRoom({ ...updatedRoom, [event.target.name]: 'no' });
		setCheckedCon(false);
	};

	const onChangeCheckboxLock = (event) => {
		if (event.target.checked === true) {
			setUpdatedRoom({ ...updatedRoom, [event.target.name]: 'Locked' });
			setCheckedLock(true);
			return;
		}

		setUpdatedRoom({ ...updatedRoom, [event.target.name]: 'Active' });
		setCheckedLock(false);
	};

	const handleChangeUploadRoom = async (event) => {
		event.preventDefault();
		const tmpFiles = Array.from(event.target.files).map((item) => URL.createObjectURL(item));

		if (tmpFiles.length > 0) {
			setRoomImageUrls([...roomImageUrls, ...tmpFiles]);
		}

		const formData = formDataValue ? formDataValue : new FormData();

		for (let i = 0; i < event.target.files.length; i++) {
			const file = event.target.files[i];
			const newFilename = fixFilename(file);
			formData.append('files', file, newFilename);
		}

		setFormDataValue(formData);
	};

	const validateLockTime = (room) => {
		if (!room.lock_from && !room.lock_to) {
			return true;
		}
		if (!room.lock_from || !room.lock_to) {
			return false;
		}
		const dDays = diffDays(room.lock_from, room.lock_to);
		
		if (dDays < 0) {
			return false;
		}

		return true;
	};

	const onSubmit = async (event) => {
		event.preventDefault();
		const updatedRoomClone = { ...updatedRoom };
		
		const isValidLockTime = validateLockTime(updatedRoomClone);
		if (!isValidLockTime) {
			alert(t('Invalid lock time'));
			return;
		}
		
		// updatedRoomClone.lock_from = formatDate(updatedRoomClone.lock_from, 'YYYY-MM-DD');
		// updatedRoomClone.lock_to = formatDate(updatedRoomClone.lock_to, 'YYYY-MM-DD');
		updatedRoomClone.status = updatedRoom.status === 'Active' ? updatedRoom.status : 'Locked';
		updatedRoomClone.extra_bed = updatedRoom.extra_bed === 'yes' ? updatedRoom.extra_bed : 'no';
		updatedRoomClone.connecting = updatedRoom.connecting === 'yes' ? updatedRoom.connecting : 'no';

		// upload file to server
		const roomImagesData = await uploadFiles(roomImageUrls, formDataValue);
		const response = await updateRoom({ ...updatedRoomClone, images: roomImagesData });
		if (response.status === 200) {
			// delete files from server
			await deleteFilesApi(deleteFiles);
			closeDialog();
			setShowToast({ show: true, message: t('Updated successful!'), type: 'success' });
			return;
		}
		setShowToast({
			show: true,
			message: response.error && response.error.ErrorCode ? response.error.ErrorCode : 'Update failed!',
			type: 'danger'
		});
	};

	// Start: Get all cruise , []
	useEffect(() => {
		if (cruises && cruises.length) return;
		getCruises();
	}, []);
	return (
		<Fragment>
			<Modal
				show={showUpdateRoomModal}
				onHide={closeDialog}
				size='lg'
				aria-labelledby='contained-modal-title-vcenter'
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>{isView ? t(`Room Detail`) : t(`Update Room`)}</Modal.Title>
				</Modal.Header>
				<Form onSubmit={onSubmit}>
					<Modal.Body>
						<div className='row'>
							<div className='col-sm'>
								<input type='hidden' name='id' value={id} />
								<div className='form-group'>
									<label>{t('Name')}</label>
									<input
										type='text'
										className='form-control'
										id='nameCruise'
										name='name'
										value={name}
										placeholder={t('Enter Room Name (No. 101)')}
										required
										aria-describedby='name-help'
										onChange={onChangeNewRoomForm}
										disabled={isView}
									/>
									<Form.Text id='name-help' muted>
										{t('Required')}
									</Form.Text>
								</div>
							</div>
							<div className='col-sm'>
								<div className='form-group'>
									<label>{t('Cruise')}</label>
									<select
										name='cruise_id'
										value={cruise_id}
										className='SelectBox form-control'
										required
										aria-describedby='cruise-help'
										onChange={onChangeNewRoomFormParseNumber}
										disabled={isView}
									>
										<option label={t('Choose Cruise')}></option>
										{cruises
											.filter((item) => item.typeDay === CRUISE_TYPES.NIGHT)
											.map((cruise, index) => (
												<option key={index} value={cruise.id}>
													{cruise.name}
												</option>
											))}
									</select>
									<Form.Text id='cruise-help' muted>
										{t('Required')}
									</Form.Text>
								</div>
							</div>
							<div className='col-sm'>
								<div className='form-group'>
									<label>{t('Bed Type')}</label>
									<select
										name='bed_type'
										value={bed_type}
										className='SlectBox form-control'
										required
										aria-describedby='bed-help'
										onChange={onChangeNewRoomForm}
										disabled={isView}
									>
										<option label={t('Choose Bed Type')}></option>
										<option value='double'>{t('Double')}</option>
										<option value='twin'>{t('Twin')}</option>
										<option value='double/twin'>{t('Double/Twin')}</option>
										<option value='triple'>{t('Triple')}</option>
										<option value='family'>{t('Family')}</option>
									</select>
									<Form.Text id='bed-help' muted>
										{t('Required')}
									</Form.Text>
								</div>
							</div>
							<div className='col-sm'>
								<div className='form-group'>
									<label>{t('Room Type')}</label>
									<select
										name='room_type_id'
										value={room_type_id}
										className='SlectBox form-control'
										required
										aria-describedby='room-type-help'
										onChange={onChangeNewRoomFormParseNumber}
										disabled={isView}
									>
										<option label={t('Choose Room Type')}></option>
										{roomTypes.map((roomType, index) => (
											<option key={index} value={roomType.id}>
												{roomType.name}
											</option>
										))}
									</select>
									<Form.Text id='roomType-help' muted>
										{t('Required')}
									</Form.Text>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col-sm'>
								<div className='form-group'>
									<label>{t('Max People in Room')}</label>
									<select
										id='max_room'
										name='max_room'
										value={max_room}
										className='SlectBox form-control'
										required
										aria-describedby='max-room-help'
										onChange={onChangeNewRoomFormParseNumber}
										disabled={isView}
									>
										<option label={t('No. People')}></option>
										{[...Array(50)].map((e, i) => (
											<option value={i + 1} key={i}>
												{i + 1} {t('People')}
											</option>
										))}
									</select>
									<Form.Text id='max-room-help' muted>
										{t('Required')}
									</Form.Text>
								</div>
							</div>
							<div className='col-sm'>
								<div className='form-group'>
									<label>{t('Max Adult')}</label>
									<select
										id='max_adult'
										name='max_adult'
										value={max_adult}
										className='SlectBox form-control'
										required
										aria-describedby='max-adults-help'
										onChange={onChangeNewRoomFormParseNumber}
										disabled={isView}
									>
										<option label='No. Adult(s)'></option>
										{[...Array(8)].map((e, i) => (
											<option value={i + 1} key={i}>
												{i + 1} {t('Adult')}(s)
											</option>
										))}
									</select>
									<Form.Text id='max-adults-help' muted>
										{t('Required')}
									</Form.Text>
								</div>
							</div>
							<div className='col-sm'>
								<div className='form-group'>
									<label>{t('Location')}</label>
									<input
										type='text'
										className='form-control'
										id='location'
										name='location'
										value={location}
										placeholder={t('First Deck')}
										onChange={onChangeNewRoomForm}
										disabled={isView}
									/>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-sm'>
								<div className='form-group'>
									<label>
										<strong>{t('Room Image')}</strong>
									</label>
									{!isView && (
										<input
											type='file'
											id='imagesRoom'
											multiple
											onChange={handleChangeUploadRoom.bind(this)}
											placeholder={t('Upload Room')}
											className='form-control'
										/>
									)}
								</div>

								<div className='main-img-room'>
									{roomImageUrls &&
										roomImageUrls.map((item, index) => {
											return (
												<div className='room-images'>
													{!isView && (
														<button
															className='close-image'
															key={index}
															onClick={handleClickClose.bind(this, item)}
														>
															X
														</button>
													)}

													<img
														id='blah'
														src={`${!item.includes('blob') ? apiUrl : ''}${item}`}
														alt=''
													/>
												</div>
											);
										})}
								</div>
							</div>
						</div>
						<hr />
						<div className='row'>
							<div className='col-sm'>
								<div className='form-group justify-content-end'>
									<label>{t('Extra Bed')}</label>
									<div className='checkbox'>
										<div className='custom-checkbox custom-control'>
											<input
												type='checkbox'
												className='custom-control-input'
												name='extra_bed'
												id='extra_bed'
												value={extra_bed}
												onChange={onChangeCheckBox}
												defaultChecked={checked}
												disabled={isView}
											/>
											<label htmlFor='extra_bed' className='custom-control-label mt-1'>
												{t('Yes')}
											</label>
										</div>
									</div>
								</div>
							</div>
							<div className='col-sm'>
								<div className='form-group justify-content-end'>
									<label>{t('Connecting')}</label>
									<div className='checkbox'>
										<div className='custom-checkbox custom-control'>
											<input
												type='checkbox'
												value={connecting}
												className='custom-control-input'
												name='connecting'
												id='connecting'
												onChange={onChangeCheckBoxCon}
												defaultChecked={checkedCon}
												disabled={isView}
											/>
											<label htmlFor='connecting' className='custom-control-label mt-1'>
												{t('Yes')}
											</label>
										</div>
									</div>
								</div>
								{isConnect === true ? (
									<div className='form-group'>
										<label>{t('Connect Room')}</label>
										<input
											type='text'
											className='form-control'
											id='connect_room'
											name='connect_room'
											value={connect_room}
											placeholder={t('No. Room')}
											onChange={onChangeNewRoomForm}
											disabled={isView}
										/>
									</div>
								) : null}
							</div>
							<div className='col-sm'>
								<div className='form-group justify-content-end'>
									<label>{t('Lock From')}</label>
									<input
										type='datetime-local'
										className='form-control'
										// placeholder='mm/dd/yyyy'
										name='lock_from'
										onChange={onChangeNewRoomForm.bind(this)}
										value={lock_from}
									/>
								</div>
							</div>
							<div className='col-sm'>
								<div className='form-group justify-content-end'>
									<label>{t('Lock To')}</label>
									<input
										type='datetime-local'
										className='form-control'
										// placeholder='mm/dd/yyyy'
										name='lock_to'
										onChange={onChangeNewRoomForm.bind(this)}
										value={lock_to}
									/>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col-md-12'>
								<div className='form-group'>
									<label>{t('Description')}</label>
									<textarea
										className='form-control'
										placeholder={t('Description')}
										rows='3'
										name='description'
										value={description}
										spellCheck='false'
										onChange={onChangeNewRoomForm}
										disabled={isView}
									></textarea>
								</div>
							</div>
						</div>
					</Modal.Body>
					{!isView && (
						<Modal.Footer>
							<Button className='btn btn-danger btn-with-icon' variant='secondary' onClick={closeDialog}>
								<i className='fe fe-x-circle'></i> {t('Cancel')}
							</Button>
							<Button className='btn btn-primary btn-with-icon' variant='primary' type='submit'>
								<i className='fe fe-save'></i> {t('Save')}!!!
							</Button>
						</Modal.Footer>
					)}
				</Form>
			</Modal>
		</Fragment>
	);
};

export default UpdateRoomModal;
