import { Fragment, useEffect, useState, useContext } from 'react';
import { CRUISE_TYPES, apiUrl } from '../../contexts/constants';
import moment from 'moment';
import { getAPI } from '../../utils/api';
import { ItineraryContext } from '../../contexts/ItineraryContext';
import { CountryContext } from '../../contexts/CountryContext';
import { formatDate } from '../../utils/common';
import { useTranslation } from 'react-i18next';
import { CruiseSettingContext } from '../../contexts/CruiseSettingContext';

const CustomerList = (props) => {
	const idCruise = props.cruiseIdProps;
	const dateInfo = props.dateInfoProps;
	const typeDay = props.checkDayProps;
	const setExportExcelData = props.setExportExcelData;
	const {
		itineraryState: { itineraries },
		getItineraries
	} = useContext(ItineraryContext);

	const [bookings, setDataBookings] = useState([]);
	let textUrl = `${apiUrl}/customer/bookings`,
		params = '';

	if (typeDay !== '') {
		if (typeDay !== CRUISE_TYPES.TOUR && typeDay !== CRUISE_TYPES.DAY) {
			params = `start_date=${dateInfo}&filterFields=cruise_id&filterFieldValues=${idCruise}`;
		} else {
			params = `start_date=${dateInfo}&filterFields=cruise_id&filterFieldValues=${idCruise}&isNight=0`;
		}
	}

	const {
        cruiseSettingState: { cruiseSettings },
    } = useContext(CruiseSettingContext)

    const [cruiseSetting, setCruiseSetting] = useState({});
    useEffect(() => {
        if (!idCruise) return;
		const cruiseSetting = cruiseSettings? cruiseSettings.find((item) => {
			return item.cruise_id == idCruise;
		}): {}
		setCruiseSetting(cruiseSetting);
	}, [idCruise]);

	const {
		countryState: { countries },
		getCountries
	} = useContext(CountryContext);

	useEffect(() => {
		if (!countries || !countries.length) getCountries();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const getCountryCode3 = (val) => {
		const country = countries.find((item) => item.name === val);
		return country ? country.iso_code_3 : '';
	};

	useEffect(() => {
		if (typeDay !== '') {
			async function getBookings() {
				try {
					const response = await getAPI(`${textUrl}`, params);
					setDataBookings(response.data);
				} catch (error) {
					return error.response ? error.response : { success: false, message: 'Server error!' };
				}
			}
			getBookings();
		}
	}, [typeDay]); // eslint-disable-line react-hooks/exhaustive-deps

	// Start: Get all specials , []
	useEffect(() => getItineraries(''), []); // eslint-disable-line react-hooks/exhaustive-deps

	const getNameDuration = (idItinerary) => {
		const itinerary = itineraries.find((itinerary) => {
			return itinerary.id === idItinerary;
		});
		return itinerary && itinerary.duration ? itinerary.duration : 0;
	};

	useEffect(() => {
		if (!bookings || bookings.length === 0) return;
		const exportExcelCustomerRoomData = getExportCustomerListData(bookings, getNameDuration, getCountryCode3, cruiseSetting);
		setExportExcelData(exportExcelCustomerRoomData);
		// cónt;
	}, [bookings]);

	const { t } = useTranslation();

	return (
		<Fragment>
			<div className='row'>
				<div className='col-md-12'>
					<div className='card-content'>
						<table id='example' className='table is-narrow'>
							<thead>
								<tr>
									<th>STT</th>
									<th>Họ Tên</th>
									<th>Ngày Sinh</th>
									<th>Ngày Sinh Dùng Đến</th>
									<th>Giới Tính</th>
									<th>Mã Quốc Tịch</th>
									<th>Số Hộ Chiếu</th>
									<th>Số Phòng</th>
									<th>Ngày Đến</th>
									<th>Ngày Đi Dự Kiến</th>
									<th>Ngày Trả Phòng</th>
								</tr>
							</thead>
							<tbody>
								{bookings &&
									bookings.map((customer, i) =>
										typeDay !== CRUISE_TYPES.TOUR && typeDay !== CRUISE_TYPES.DAY ? (
											<tr key={i}>
												<td>{i + 1}</td>
												<td>{customer.full_name}</td>
												<td>{customer.birthday ? formatDate(customer.birthday, 'DD/MM/YYYY') : ''}</td>
												<td>D</td>
												<td>{customer.gender === 'male' ? t('M') : t('F')}</td>
												<td>{getCountryCode3(customer.country)}</td>
												<td>{customer.passport_number}</td>
												<td>{customer.room_name ? customer.room_name : ''}</td>
												<td>{formatDate(customer.start_date, 'DD/MM/YYYY')}</td>
												<td>{formatDate(customer.end_date, 'DD/MM/YYYY')}</td>
												<td>{formatDate(customer.end_date, 'DD/MM/YYYY')}</td>
											</tr>
										) : (
											<tr key={i}>
												<td>{i + 1}</td>
												<td>{customer.full_name}</td>
												<td>{customer.birthday ? formatDate(customer.birthday, 'DD/MM/YYYY') : ''}</td>
												<td>D</td>
												<td>{customer.gender === 'male' ? t('M') : t('F')}</td>
												<td>{getCountryCode3(customer.country)}</td>
												<td>{customer.passport_number}</td>
												<td>{formatDate(customer.start_date, 'DD/MM/YYYY')}</td>
												<td>{formatDate(customer.end_date, 'DD/MM/YYYY')}</td>
												<td>{formatDate(customer.end_date, 'DD/MM/YYYY')}</td>
											</tr>
										)
									)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

const getExportCustomerListData = (bookings, getNameDuration, getCountryCode3, cruiseSetting) => {
	let customerQuantity = {
		adults: 0,
		children: 0,
		children11: 0,
		infant: 0
	};

	let tmpBookings = {};
	let exportCustomerRoomData = [];
	let exportForMaritimeAdministrationData = [];
	let exportCustomerBasicData = [];

	bookings.forEach((customer, i) => {
		if (!tmpBookings[customer.booking_id]) {
			customerQuantity.adults += customer.adults ? customer.adults : 0;
			customerQuantity.children += customer.children ? customer.children : 0;
			customerQuantity.children11 += customer.children11 ? customer.children11 : 0;
			customerQuantity.infant += customer.infant ? customer.infant : 0;
			tmpBookings[customer.booking_id] = customer.booking_id;
		}

		const customerRoomData = {
			STT: i + 1,
			'CODE\nPHẦN MỀM': customer.new_code,
			'HỌ TÊN': customer.full_name,
			'GIỚI TÍNH': customer.gender === 'male' ? 'Nam' : 'Nữ',
			'NĂM SINH': formatDate(customer.birthday, 'DD/MM/YYYY'),
			'SỐ CMND/\nPASSPORT': customer.passport_number,
			'QUỐC TỊCH/\nTỈNH THÀNH': getCountryCode3(customer.country),
			'LỊCH TRÌNH':
				getNameDuration(customer.cruise_itinerary_id) > 1
					? getNameDuration(customer.cruise_itinerary_id) +
					  'N' +
					  (getNameDuration(customer.cruise_itinerary_id) - 1) +
					  'Đ'
					: getNameDuration(customer.cruise_itinerary_id) + 'N',
			'TUỔI CỦA\nTRẺ EM':
				customer.children > 0
					? `${customer.children} [${cruiseSetting && cruiseSetting.child_label1? cruiseSetting.child_label1: 'Children (5-7)'}] `
					: '' + customer.children11 > 0
					? `${customer.children11} [${cruiseSetting && cruiseSetting.child_lable2? cruiseSetting.child_lable2: 'Children (8-11)'}] `
					: '' + customer.infants > 0
					? `${customer.infants} Infant`
					: '',
			'SỐ PHÒNG': customer.room_name ? customer.room_name : '',
			'LOẠI PHÒNG': customer.cabin_desc ? customer.cabin_desc : '',
			'YÊU CẦU ĐẶC BIỆT\n(ăn kiêng, set up phòng…)': customer.sale_note
				? customer.sale_note.replace(/<\/?[^>]+(>|$)/g, '\n').replace(/\&nbsp;/g, '')
				: '',
			PAYMENT: customer.opt_note ? customer.opt_note.replace(/<\/?[^>]+(>|$)/g, '\n').replace(/\&nbsp;/g, '') : ''
		};

		const customerForMaritimeAdministrationData = {
			STT: i + 1,
			'HỌ TÊN': customer.full_name,
			'GIỚI TÍNH': customer.gender === 'male' ? 'Nam' : 'Nữ',
			'NĂM SINH': formatDate(customer.birthday, 'DD/MM/YYYY'),
			'SỐ CMND/\nPASSPORT': customer.passport_number,
			'QUỐC TỊCH/\nTỈNH THÀNH': getCountryCode3(customer.country),
			'LỊCH TRÌNH':
				getNameDuration(customer.cruise_itinerary_id) > 1
					? getNameDuration(customer.cruise_itinerary_id) +
					  'N' +
					  (getNameDuration(customer.cruise_itinerary_id) - 1) +
					  'Đ'
					: getNameDuration(customer.cruise_itinerary_id) + 'N'
		};

		const customerBasicData = {
			so_thu_tu: i + 1,
			ho_ten: customer.full_name,
			ngay_sinh: customer.birthday ? formatDate(customer.birthday, 'DD/MM/YYYY') : '',
			ngay_sinh_dung_den: 'D',
			gioi_tinh: customer.gender === 'male' ? 'M' : 'F',
			ma_quoc_tich: getCountryCode3(customer.country),
			so_ho_chieu: customer.passport_number,
			so_phong: customer.room_name ? customer.room_name : '',
			ngay_den: formatDate(customer.start_date, 'DD/MM/YYYY'),
			ngay_di_du_kien: formatDate(customer.end_date, 'DD/MM/YYYY'),
			ngay_tra_phong: formatDate(customer.end_date, 'DD/MM/YYYY')
		};

		exportCustomerBasicData.push(customerBasicData);
		exportCustomerRoomData.push(customerRoomData);
		exportForMaritimeAdministrationData.push(customerForMaritimeAdministrationData);
	});

	return { customerQuantity, exportCustomerRoomData, exportForMaritimeAdministrationData, exportCustomerBasicData };
};

export default CustomerList;
