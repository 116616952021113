import { createContext, useState, useReducer } from 'react';
import { transportationPickUpReducer } from '../reducers/transportationPickUpReducer';
import {
	apiUrl,
	TRANSPORTATION_PICKUP_LOADED_SUCCESS,
	TRANSPORTATION_PICKUP_LOADED_FAIL,
	ADD_TRANSPORTATION_PICKUP,
	FIND_TRANSPORTATION_PICKUP,
	UPDATE_TRANSPORTATION_PICKUP,
	DELETE_TRANSPORTATION_PICKUP
} from '../contexts/constants';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';

export const TransportationPickUpContext = createContext();

const TransportationPickUpContextProvider = ({ children }) => {
	// State
	const [transportationPickUpState, dispatch] = useReducer(transportationPickUpReducer, {
		transportationPickUp: null,
		transportationPickUps: [],
		transportationPickUpsLoading: true
	});

	const [showAddTransportationPickUpModal, setShowAddTransportationPickUpModal] = useState(false);
	const [showUpdateTransportationPickUpModal, setShowUpdateTransportationPickUpModal] = useState(false);
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null
	});

	// Get all Transportation Pickups
	const getTransportationPickUps = async () => {
		const response = await getAPI(`${apiUrl}/transportation-pickups?orderBy=created_at&direction=desc`);
		if (response.data) {
			dispatch({ type: TRANSPORTATION_PICKUP_LOADED_SUCCESS, payload: response.data });
			return response;
		}

		dispatch({ type: TRANSPORTATION_PICKUP_LOADED_FAIL });
		return response;
	};

	// Add Transportation Pickup
	const addTransportationPickUp = async (newTransportationPickUp) => {
		const response = await postAPI(`${apiUrl}/transportation-pickup/insert`, newTransportationPickUp);
		if (response.data) {
			dispatch({
				type: ADD_TRANSPORTATION_PICKUP,
				payload: response.data
			});
		}
		return response;
	};

	// Find Transportation Pickup when user is updating post
	const findTransportationPickUp = (transportationPickUpId) => {
		const transportationPickUp = transportationPickUpState.transportationPickUps.find(
			(transportationPickUp) => transportationPickUp.id === transportationPickUpId
		);
		dispatch({
			type: FIND_TRANSPORTATION_PICKUP,
			payload: transportationPickUp
		});
	};

	// update Transportation Pickup
	const updateTransportationPickUp = async (updateTransportationPickUp) => {
		const response = await postAPI(`${apiUrl}/transportation-pickup/update`, updateTransportationPickUp);
		if (response.status === 200) {
			dispatch({
				type: UPDATE_TRANSPORTATION_PICKUP,
				payload: updateTransportationPickUp
			});
		}
		return response;
	};

	// Delete Transportation Pickup
	const deleteTransportationPickUp = async (TransportationPickUpId) => {
		const response = await getDeleteAPI(`${apiUrl}/transportation-pickup/${TransportationPickUpId}/delete`);
		if (response.status === 200) {
			dispatch({
				type: DELETE_TRANSPORTATION_PICKUP,
				payload: TransportationPickUpId
			});
		}
		return response;
	};

	const clearTransportationPickUp = () => {
		dispatch({
			type: FIND_TRANSPORTATION_PICKUP,
			payload: {}
		});
	};

	// Post Context Data
	const transportationPickUpContextData = {
		transportationPickUpState,
		getTransportationPickUps,
		addTransportationPickUp,
		findTransportationPickUp,
		updateTransportationPickUp,
		deleteTransportationPickUp,
		showToast,
		setShowToast,
		showAddTransportationPickUpModal,
		setShowAddTransportationPickUpModal,
		showUpdateTransportationPickUpModal,
		setShowUpdateTransportationPickUpModal,
		clearTransportationPickUp
	};

	return (
		<TransportationPickUpContext.Provider value={transportationPickUpContextData}>
			{children}
		</TransportationPickUpContext.Provider>
	);
};

export default TransportationPickUpContextProvider;
