export const companyInfo = {
	sena: [
		'CÔNG TY CỔ PHẦN DU LỊCH VỊNH THIÊN NGA',
		'Địa chỉ VPGD: Số 44 - Hàm Tử Quan - Phường Chương Dương - Quận Hoàn Kiếm - Hà Nội',
		'Tell: 0243 933 5599'
	],
	bdc: [
		'CÔNG TY CỔ PHẦN ĐẦU TƯ VÀ DỊCH VỤ QUỐC TẾ CRH',
		'Địa chỉ VPGD: Tầng 14, Tòa nhà Diamond Flower, 48 Lê Văn Lương, Thanh Xuân, Hà Nội',
		'Tell: (+84) 934 583 638'
	]
};
