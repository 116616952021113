import { useContext, useState, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap';
import { PartnerContext } from '../../../contexts/PartnerContext';
import { PriceContext } from '../../../contexts/PriceContext';
import Select from 'react-select';
import { apiUrl } from '../../../contexts/constants';
import { postAPI } from '../../../utils/api';
import { useTranslation } from 'react-i18next';
import { csvFileToArray } from '../../../utils/file';
import { AuthContext } from '../../../contexts/AuthContext';

const ImportPartnerModal = props => {
    const { t } = useTranslation();
    // Context
    const {
        showImportPartnerModal,
        setShowImportPartnerModal,
        addMultiplePartners,
        setShowToast
    } = useContext(PartnerContext)
    const {
        partnerState: {partners},
    } = useContext(PartnerContext)

    const {
        authState: { users },
    } = useContext(AuthContext)

    const [file, setFile] = useState();
    const [isDisabledBtn, setIsDisabledBtn] = useState(false);
    const [submitText, setSubmitText] = useState(t('Save'));
    const fileReader = new FileReader();

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
    };

    const getAssignTo = (username) => {
        const fUser = users.find((u) => u.name == username.trim());
        if (!fUser) {
            return null;
        }
        return fUser.id;
    }

    const onSubmit = async event => {
        event.preventDefault()

        if (file) {
            fileReader.onload = async function (event) {
                // add loading
                setIsDisabledBtn(true);
                setSubmitText(t('Processing...'));
                // 

                const csvOutput = event.target.result;
                const partVals = csvFileToArray(csvOutput);
                partVals.forEach((item) => {
                    const comm = parseFloat(item.commision);
                    item.commission = comm? comm: null;
                    item.vat = parseFloat(item.vat);
                    if (!item.assign_to) {
                        console.log('item not assign_to', item);
                    }
                    if (!item.partner_type) {
                        console.log('item not partner_type', item);
                    }
                    item.assign_to = getAssignTo(item.assign_to);
                    Object.keys(item).forEach((k) => item[k] == null && delete item[k]);
                });
                
                const validParts = partVals.filter((p) => {
                    const _p = partners.find((item) => {
                        return item.name == p.name;
                    });
                    if (_p) {
                        return false;
                    }
                    return true;
                });

                // call to insert partners API.
                if (validParts && validParts.length) {
                    await addMultiplePartners(validParts)
                }
                
                // remove loading and close dialog
                closeDialog();
            };

            fileReader.readAsText(file);
        }
    }

    const closeDialog = () => {
        setIsDisabledBtn(false);
        setSubmitText(t('Save'));
        setShowImportPartnerModal(false);
    }

    return (
        <Modal show={showImportPartnerModal} onHide={closeDialog}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('Import Partner')}
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit}>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label>{t('CSV File')}</label>
                                <input type="file" className="form-control" id="partner-csv" name="partner-csv" onChange={handleOnChange} required aria-describedby='partner-csv-help' />
                                <Form.Text id='partner-csv-help' muted>{t('Required')}</Form.Text>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> {t('Cancel')}</Button>
                    <Button disabled={isDisabledBtn} className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> {submitText}!!!</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default ImportPartnerModal
