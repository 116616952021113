import { Fragment, useContext } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { AuthContext } from '../../../contexts/AuthContext';
import { BOOKING_STATUSES, ROLES } from '../../../contexts/constants';
import { getBookingCellBackground, convertUTCtoGMT7 } from '../../../utils/common';
import {OnboardBookingContext} from '../../../contexts/OnboardBookingContext';

import Moment from 'moment';

const NightCheckinCell = props => {

    const { 
        onboardBookingState: {onboardBooking, onboardBookings, onboardBookingsLoading},
        getOnboardBookings,
        findOnboardBooking,
        deleteOnboardBooking,
        setShowAddOnboardBookingModal,
        setShowToast,
        setOnBoardBookingData,
    } = useContext(OnboardBookingContext)

    const {authState: {user}} = useContext(AuthContext)
    
    let dataBooking = []
    
    const customers = props.customerProps
    const bookingId = props.idBookingProps
    const itineraries = props.itineraries
    const cellDate = props.date
    const roomName = props.roomName
    const roomNameFull = props.roomNameFull
    let dataArrays = []
    let idCheck = []

    const popover = (dataBooking) => {
        
        const str_checked_in = convertUTCtoGMT7(dataBooking.checked_in);
        const str_checked_out = convertUTCtoGMT7(dataBooking.checked_out);
        
        return (<Popover id="popover-basic">
            <Popover.Content className="message is-info">
                <div className="message-body">
                    <div className="row">
                        <div className="col-md-5"><label>Booking</label></div>
                        <div className="col-md-7"><strong>{dataBooking.new_code ? dataBooking.new_code : dataBooking.id}</strong></div>
                    </div>
                    {/* {user.department !== 'member' && (
                        <div className="row">
                            <div className="col-md-5"><label>Partner</label></div>
                            <div className="col-md-7"><strong>{dataBooking.partner_name}</strong></div>
                        </div>
                    )} */}
                    <div className="row">
                        <div className="col-md-5"><label>Departure</label></div>
                        <div className="col-md-7"><strong>{Moment(dataBooking.start_date).format('MMMM D, YYYY')}</strong></div>
                    </div>
                    <div className="row">
                        <div className="col-md-5"><label>Group Size</label></div>
                        <div className="col-md-7"><strong>
                            {dataBooking.adults} Adult {dataBooking.children ? dataBooking.children + 'Children (5-7)' : '' }  {dataBooking.children11 ? dataBooking.children11 + 'Children (8-11)' : ''}  {dataBooking.infants ? ' - ' + dataBooking.infants + ' Infant' : ''}
                        </strong></div>
                    </div>
                    <div className="row">
                        <div className="col-md-5"><label>Participators</label></div>
                        <div className="col-md-7">
                            <strong>{getNameCustomer(dataBooking.data)}</strong>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5"><label>Cabin</label></div>
                        <div className="col-md-7"><strong>{dataBooking.cabin_desc}</strong></div>
                    </div>
                    <div className="row">
                        <div className="col-md-5"><label>Cabin Name</label></div>
                        <div className="col-md-7"><strong>{roomNameFull}</strong></div>
                    </div>
                    <div className="row">
                        <div className="col-md-5"><label>Checked In</label></div>
                        <div className="col-md-7"><strong>{str_checked_in ? str_checked_in: ''}</strong></div>
                    </div>
                    <div className="row">
                        <div className="col-md-5"><label>Checked Out</label></div>
                        <div className="col-md-7"><strong>{str_checked_out ? str_checked_out : ''}</strong></div>
                    </div>
                    <div className="row">
                        <div className="col-md-5"><label>Onboard Service Total</label></div>
                        <div className="col-md-7"><strong>{dataBooking.onboard_total} {dataBooking.onboard_currency === "usd" ? "USD" : "VND"}</strong></div>
                    </div> 
                </div>
            </Popover.Content>
        </Popover>
    )};
    
    if(customers.length > 0){
        if(bookingId.length > 1){
            bookingId.map((booking, index) => {
                customers.map((customer, i) => {
                    if(customer.booking_id === booking){
                        if(idCheck.includes(customer.booking_id) !== true){
                            idCheck.push(customer.booking_id)
                            customer.data = customers
                            dataArrays.push(customer)
                        }
                    }
                })
            })
        }else{
            if(customers.length > 1){
                customers.map((customer, i) => {
                    customer.data = customers
                    dataBooking = customer
                })
            }else{
                customers[0].data = customers[0]
                dataBooking = customers[0]
            }
        }
    }

    const getNameCustomer = (customers) => {
        var namePopover = ''
        if(customers !== undefined ){
            if(customers.length > 1){
                customers.map((customer) => {
                    if(customer.full_name !== undefined){
                        namePopover = customer.full_name
                    }
                })
            }else{
                if(customers.full_name !== undefined){
                    namePopover = customers.full_name
                }
            }
        }
        
        return namePopover
    }

    const getNameDuration = (idItinerary) => {
        var duration = 0;
        itineraries.map((itinerary) => {
            if(itinerary.id === idItinerary){
                duration = itinerary.duration
            }
        })
        return duration
    }

    const chooseOnboardBooking = (bookingId, roomId, data) => {
        setShowAddOnboardBookingModal(true)
        setOnBoardBookingData({
            bookingId,
            roomId,
            date: cellDate,
            roomData: data
        })
    }

    if(dataArrays.length > 1){
        return (
            <Fragment>
                <div className='border-black'>
                    {
                        dataArrays.map((data, index) => {
                            if(data.status !== BOOKING_STATUSES.TRANSFERRED){
                                if(index === 0){
                                    return (
                                        <OverlayTrigger key={index} trigger={["hover", "hover"]} placement="bottom" overlay={popover(data)}>
                                            <button onClick={chooseOnboardBooking.bind(this, data.booking_id, data.room_id, data)}
                                            className={getBookingCellBackground(data.status, getNameDuration(data.cruise_itinerary_id), user) + ' show-booking'}
                                            >
                                                {data.checked_in && data.checked_in !== '0001-01-01 00:00:00 +0000 UTC' ? <div className='checked-in'></div>: ''}
                                                {data.new_code ? data.new_code : data.id}
                                                {data.checked_out && data.checked_out !== '0001-01-01 00:00:00 +0000 UTC' ? <div className='checked-out'></div>: ''}
                                            </button>
                                        </OverlayTrigger>
                                    )
                                }

                                return (
                                    <OverlayTrigger key={index} trigger={["hover", "hover"]} placement="bottom" overlay={popover(data)}>
                                        <button onClick={chooseOnboardBooking.bind(this, data.booking_id, data.room_id, data)}
                                        className={getBookingCellBackground(data.status, getNameDuration(data.cruise_itinerary_id), user) + ' hide-link show-booking'}
                                        >
                                            {''}
                                        </button>
                                    </OverlayTrigger>
                                )
                            }

                            return (
                                <OverlayTrigger key={index} trigger={["hover", "hover"]} placement="bottom" overlay={popover(data)}>
                                    <button onClick={chooseOnboardBooking.bind(this, data.booking_id, data.room_id, data)}
                                    className={getBookingCellBackground(data.status, getNameDuration(data.cruise_itinerary_id)) + ' show-booking'}
                                    >
                                        {''}
                                    </button>
                                </OverlayTrigger>
                            )
                        })
                    }
                </div>
            </Fragment>
        )
    }
    
    return (
        <Fragment>
            {dataBooking && dataBooking.booking_id ? (
                <OverlayTrigger trigger={["hover", "hover"]} placement="bottom" overlay={popover(dataBooking)}>
                    <button onClick={chooseOnboardBooking.bind(this, dataBooking.booking_id, dataBooking.room_id, dataBooking)}
                    className={getBookingCellBackground(dataBooking.status, getNameDuration(dataBooking.cruise_itinerary_id), user) + ' show-booking'}
                    >
                        {dataBooking.checked_in && dataBooking.checked_in !== '0001-01-01 00:00:00 +0000 UTC' ? <div className='checked-in'></div>: ''}
                        {
                           dataBooking.status !== BOOKING_STATUSES.TRANSFERRED ? dataBooking.new_code ? dataBooking.new_code : dataBooking.id : ''
                        }
                        {dataBooking.checked_out && dataBooking.checked_out !== '0001-01-01 00:00:00 +0000 UTC' ? <div className='checked-out'></div>: ''}
                    </button>
                </OverlayTrigger>
            ) : (<label className='disable-room-label'>{roomName}</label>)}
        </Fragment>
    )
}

export default NightCheckinCell
