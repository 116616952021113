import { Fragment, useEffect, useContext, useState, useRef } from 'react';
import BookingNightItem from './BookingNightItem';
import { Link } from 'react-router-dom';
import { BookingContext } from '../../../contexts/BookingContext';
import moment from 'moment';
import { CRUISE_TYPES, apiUrl, BOOKING_STATUSES } from '../../../contexts/constants';
import { getAPI } from '../../../utils/api';
import { CruiseSettingContext } from '../../../contexts/CruiseSettingContext';
import { CheckWeekend, CheckHoliday, getDayOfWeek, getYearMonthDay } from '../../../utils/common';
import { useTranslation } from 'react-i18next';

const TableNightCruise = (props) => {
	const {
		bookingState: { bookings },
		getBookingIdsInMonth
	} = useContext(BookingContext);

	const {
		cruiseSettingState: { cruiseSettings },
		getCruiseSettings
	} = useContext(CruiseSettingContext);

	useEffect(() => {
		if (cruiseSettings && cruiseSettings.length > 0) return;
		getCruiseSettings('');
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const setAddBookingDefaultData = props.setAddBookingDefaultData;
	const cruise = props.cruiseProps;
	const rooms = props.roomsProps;
	const month = props.monthProps;
	const year = props.yearProps;
	const cancelledLabel = props.cancelledLabel;
	const daysInMonths = new Date(year, month, 0).getDate();

	const [dataSettings, setDataSettings] = useState([]);
	const arrDateSetting = [];

	useEffect(() => getBookingIdsInMonth(cruise.id, month, year, CRUISE_TYPES.NIGHT), [cruise.id, month, year]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		async function settingData() {
			try {
				const response = await getAPI(
					`${apiUrl}/cruise-day-settings`,
					`filterFields=cruise_id,oos&filterFieldValues=${cruise.id},true&start_date=${year}-${month}-01&end_date=${year}-${month}-${daysInMonths}`
				);
				setDataSettings(response.data);
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		settingData();
	}, [cruise.id, month, year]); // eslint-disable-line react-hooks/exhaustive-deps

	const arrCountCustomers = [];
	const arrDayCountCustomers = [];
	const arrDayCountRooms = [];
	const [itineraries, setItineraries] = useState([]);

	//get itineraries
	useEffect(() => {
		async function getDataItineraries() {
			try {
				if (cruise) {
					const response = await getAPI(
						`${apiUrl}/cruise-itineraries`,
						`filterFields=cruise_id&filterFieldValues=${cruise.id}`
					);
					setItineraries(response.data);
				}
			} catch (error) {
				return error.response ? error.response : { success: false, message: 'Server error!' };
			}
		}
		getDataItineraries();
	}, [cruise]); // eslint-disable-line react-hooks/exhaustive-deps
	  
	const bookingRoomCount = (bookings) => {
		const bookingRoomCount = {};
	  
		Object.values(bookings).forEach((booking) => {
		  Object.values(booking).forEach((room) => {
			for (const item3 of room) {
			  const { booking_id, room_id } = item3;
			  if (!bookingRoomCount[booking_id]) {
				bookingRoomCount[booking_id] = new Set();
			  }
			  if (room_id) {
				bookingRoomCount[booking_id].add(room_id);
			  }
			}
		  });
		});
	  
		// Convert Set to count of unique room IDs
		Object.keys(bookingRoomCount).forEach((booking_id) => {
		  bookingRoomCount[booking_id] = bookingRoomCount[booking_id].size;
		});	  

		return bookingRoomCount;
	}
	
	let bookingRoomData = {};
	if (bookings) {
		bookingRoomData = bookingRoomCount(bookings);
		if (!arrCountCustomers) {
			arrCountCustomers = [];
		}
		rooms.forEach((room, index) => {
			if (bookings[room.id]) {
				arrCountCustomers[room.id] = bookings[room.id];
				rooms[index].bookings = bookings[room.id];
			}
		});

		// eslint-disable-next-line no-lone-blocks
		{
			Array.from(Array(daysInMonths), (e, i) => {
				const dayCustomer = ('0' + (i + 1)).slice(-2);
				const dateCustomer = `${year}-${month.toString().length === 1 ? '0' + month : month}-${dayCustomer}`;
				var countCustomer = 0;

				rooms.forEach((room) => {
					if (!arrCountCustomers[room.id]) {
						return; // continue
					}

					if (!arrCountCustomers[room.id][dateCustomer]) {
						return;
					}

					if (arrCountCustomers[room.id][dateCustomer].length > 1) {
						arrCountCustomers[room.id][dateCustomer].forEach((arrCus) => {
							if (arrCus.status === BOOKING_STATUSES.CANCELED || arrCus.status === BOOKING_STATUSES.TRANSFERRED) {
								return;
							}

							countCustomer += 1;
							arrDayCountCustomers[i] = countCustomer;

							if (!arrDayCountRooms[i]) {
								arrDayCountRooms[i] = [];
							}

							arrDayCountRooms[i].push(room.id);
						});

						return;
					}

					arrCountCustomers[room.id][dateCustomer].forEach((arrCus) => {
						if (arrCus.status === BOOKING_STATUSES.CANCELED || arrCus.status === BOOKING_STATUSES.TRANSFERRED) {
							return;
						}

						countCustomer += 1;
						arrDayCountCustomers[i] = countCustomer;
						if (!arrDayCountRooms[i]) {
							arrDayCountRooms[i] = [];
						}

						arrDayCountRooms[i].push(room.id);
					});
				});
			});
		}
	}

	if (dataSettings) {
		dataSettings.forEach((setting) => {
			var dateSetting = moment(setting.day_setting).format('YYYY-MM-DD');
			arrDateSetting[dateSetting] = setting.oos;
		});
	}

	const uniqueArray = (arr) => {
		var newArr = [];
		if (arr !== undefined) {
			for (var i = 0; i < arr.length; i++) {
				if (newArr.indexOf(arr[i]) === -1) {
					newArr.push(arr[i]);
				}
			}
			return newArr.length;
		} else {
			return 0;
		}
	};

	const getTotalCustomerBGClass = (blackDay, customerTotalInDay) => {
		let className = blackDay ? 'day-black' : '';
		// if (customerTotalInDay >= Number(cruise.max_customer)) {
		//     className += ' cruise-full'
		// }

		return className;
	};

	const { t } = useTranslation();
	const curDate = getYearMonthDay();
	const scrollTo = (ref) => {
		if (ref) {
		  ref.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "center"  })
		}
	}

	return (
		<Fragment>
			<div className='fullWidth100'>
				<table className='table table-bordered mg-b-0 text-md-nowrap table-list-booking'>
					<thead>
						<tr className='day-of-week'>
							<td className='zui-sticky-col last-child'></td>
							<td className='zui-sticky-col2 last-child'></td>
							{Array.from(Array(daysInMonths), (e, i) => {
								const day = ('0' + (i + 1)).slice(-2);
								const date = `${year}-${month.toString().length === 1 ? '0' + month : month}-${day}`; //${year}-${month}-${day}
								const weekend = CheckWeekend(cruiseSettings, cruise.id, date);
								const holiday = CheckHoliday(cruiseSettings, cruise.id, date);
								const dayOfWeek = getDayOfWeek(date);
								
								return (
									<td key={i} className={`${weekend} ${holiday}`} title={dayOfWeek}>
										{t(dayOfWeek)}
									</td>
								);
							})}
						</tr>
						<tr>
							<th className='zui-sticky-col'>{t('Cabin')}</th>
							<th className='zui-sticky-col2'>{t('Type')}</th>
							{Array.from(Array(daysInMonths), (e, i) => {
								const day = ('0' + (i + 1)).slice(-2);
								const date = `${year}-${month.toString().length === 1 ? '0' + month : month}-${day}`; //${year}-${month}-${day}
								// const weekend = CheckWeekend(cruiseSettings, cruise.id, date);
								// const holiday = CheckHoliday(cruiseSettings, cruise.id, date);
								// const dayOfWeek = getDayOfWeek(date);
								const todayClass = curDate.currentDate === date? 'today-item': '';
								return (
									<th key={i}>
										<Link
											target='_blank'
											to={`/operating/${date}-${cruise.id}`}
											title={`Operating ${cruise.name} in ${date}`}
											className={todayClass}
											ref={todayClass? scrollTo: null }
										>
											{i + 1}
										</Link>
									</th>
								);
							})}
						</tr>
					</thead>
					<tbody>
						{rooms.map((room, index) => (
							<tr key={index}>
								<td className='zui-sticky-col'>
									{room.name}{' '}
									{room.connect_room !== undefined ? (
										<>
											<i className='fe fe-repeat'></i> {room.connect_room}
										</>
									) : null}
								</td>
								<td className='zui-sticky-col2 capitalize'>{room.bed_type}</td>
								{Array.from(Array(daysInMonths), (e, i) => {
									const day = ('0' + (i + 1)).slice(-2);
									const date = `${year}-${month.toString().length === 1 ? '0' + month : month}-${day}`; //${year}-${month}-${day}
									return (
										<BookingNightItem
											key={i}
											bookingProps={room.bookings ? room.bookings[date] : []}
											dateSettingProps={arrDateSetting ? arrDateSetting[date] : []}
											itineraries={itineraries}
											room={room}
											startDate={date}
											setAddBookingDefaultData={setAddBookingDefaultData}
											cruise={cruise}
											bookingRoomData={bookingRoomData}
											cancelledLabel={cancelledLabel}
										/>
									);
								})}
							</tr>
						))}
						<tr>
							<td className='zui-sticky-col last-child'>
								<strong>{t('Total')}</strong>
							</td>
							<td className='zui-sticky-col2 last-child'>
								<strong>{t('Customer')}</strong>
							</td>
							{Array.from(Array(daysInMonths), (e, i) => {
								const day = ('0' + (i + 1)).slice(-2);
								const date = `${year}-${month.toString().length === 1 ? '0' + month : month}-${day}`;
								return (
									<td className={getTotalCustomerBGClass(arrDateSetting[date], arrDayCountCustomers[i])} key={i}>
										{Number(cruise.max_customer) > 0
											? `${arrDayCountCustomers[i] ? arrDayCountCustomers[i] : 0}/${cruise.max_customer}`
											: arrDayCountCustomers[i]}
									</td>
								);
							})}
						</tr>
						<tr>
							<td className='zui-sticky-col last-child'>
								<strong>{t('Vacancy')}</strong>
							</td>
							<td className='zui-sticky-col2 last-child'>
								<strong>{t('Room')}</strong>
							</td>
							{Array.from(Array(daysInMonths), (e, i) => {
								//const day = ("0" + (i+1)).slice(-2);
								//const date = `${year}-${month}-${day}`;
								return (
									<td key={i}>
										<strong>{rooms.length - uniqueArray(arrDayCountRooms[i])}</strong>
									</td>
								);
							})}
						</tr>
					</tbody>
				</table>
			</div>
		</Fragment>
	);
};

export default TableNightCruise;
