import { Fragment, useContext } from 'react';
import { Toast } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import { UserRoleContext } from '../../contexts/UserRoleContext';
// import AddUserRole from './AddUserRole';
import ListUserRole from './ListUserRole';

const UserRole = () => {

    const {
        // setShowAddUserRoleModal,
        showToast: {show, message, type},
        setShowToast
    } = useContext(UserRoleContext)

    return (
        <Fragment>
            <Helmet>
                <title>List User Roles - Cruises Management Software</title>
            </Helmet>

            <div className="horizontal-mainwrapper container clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-3">
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <h3 className="pull-left">User Role Settings</h3>
                            </div>
                            {/* <div className="col-md-4 ml-auto">
                                <button className="right-content btn btn-success btn-block" onClick={setShowAddUserRoleModal.bind(this)}><i className="fe fe-plus-circle"></i> Add User Role</button>
                            </div> */}
                        </div>
                        <ListUserRole />
                        {/* <AddUserRole /> */}
                        <Toast
                            show={show}
                            style={ {position: 'fixed', top: '20%', right: '10px'} }
                            className={`bg-${type} text-white`}
                            onClose={setShowToast.bind(this, {show: false, message: '', type: null})}
                            delay={3000}
                            autohide
                        >
                            <Toast.Body>
                                <strong style={ {color: '#ffffff'} }>{message}</strong>
                            </Toast.Body>
                        </Toast>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default UserRole
