import { createContext, useReducer, useState } from 'react';
import { serviceReducer } from '../reducers/serviceReducer';
import { apiUrl, SERVICES_LOADED_SUCCESS, SERVICES_LOADED_FAIL, ADD_SERVICE, FIND_SERVICE, UPDATE_SERVICE, DELETE_SERVICE } from './constants';
import axios from 'axios';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';


export const ServiceContext = createContext()

const ServiceContextProvider = ({ children }) => {

    // State
    const [serviceState, dispatch] = useReducer(serviceReducer, {
        service: null,
        services: [],
        servicesLoading: true
    })

    const [showAddServiceModal, setShowAddServiceModal] = useState(false)
    const [showUpdateServiceModal, setShowUpdateServiceModal] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all guides
    const getServices = async() => {
        const response = await getAPI(`${apiUrl}/services`)
        if (response.data) {
            dispatch({ type: SERVICES_LOADED_SUCCESS, payload: response.data })
            return response
        }
    
        dispatch({ type: SERVICES_LOADED_FAIL })
        return response
    }

    // Add service
    const addService = async newService => {
        const response = await postAPI(`${apiUrl}/service/insert`, newService)
        if (response.data) {
            dispatch({
                type: ADD_SERVICE,
                payload: response.data
            })
        }
        return response
    }

    // Find guide when user is updating guide
    const findService = serviceId => {
        const service = serviceState.services.find(service => service.id === serviceId)
        dispatch({
            type: FIND_SERVICE,
            payload: service
        })
    }

    // Update Service
    const updateService = async updateService => {
        const response = await postAPI(`${apiUrl}/service/update`, updateService)
        if (response.status === 200) {
            dispatch({
                type: UPDATE_SERVICE,
                payload: updateService
            })
        }
        return response
    }

    // Delete Service
    const deleteService = async serviceId => {
        const response = await getDeleteAPI(`${apiUrl}/service/${serviceId}/delete`)
        if (response.status === 200) {
            dispatch({
                type: DELETE_SERVICE,
                payload: serviceId
            })
        }
        return response
    }

    const serviceContextData = {
        serviceState,
        getServices,
        addService,
        findService,
        updateService,
        deleteService,
        showAddServiceModal,
        setShowAddServiceModal,
        showUpdateServiceModal,
        setShowUpdateServiceModal,
        showToast,
        setShowToast
    }

    return (
        <ServiceContext.Provider value = { serviceContextData } >
            { children }
        </ServiceContext.Provider>
    )
}

export default ServiceContextProvider