import { Fragment, useContext, useState, useEffect } from 'react';
import { PriceContext } from '../../../contexts/PriceContext';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import { CruiseContext } from '../../../contexts/CruiseContext';
import Moment from 'moment';
import UpdatePriceItem from './UpdatePriceItem';
import { AuthContext } from '../../../contexts/AuthContext';
import { getOptions, findOptionItem, moneyOptions } from '../../../utils/common';
import { getAPI } from '../../../utils/api';
import { apiUrl } from '../../../contexts/constants';
import { useTranslation } from 'react-i18next';

const UpdatePriceModal = (props) => {
	const { t } = useTranslation();
	// Context
	const {
		authState: { user }
	} = useContext(AuthContext);

	const [arrPrices, setArrPrices] = useState(props.updatedPrices);
	useEffect(() => {
		setArrPrices(props.updatedPrices);
	}, [props.updatedPrices]);
	const { showUpdatePriceModal, setShowUpdatePriceModal, updatePrice, setShowToast } = useContext(PriceContext);

	const {
		cruiseState: { cruises }
	} = useContext(CruiseContext);

	const [prices, setPrices] = useState([]);
	const [arrItineraries, setArrItineraries] = useState([]);
	const [checkIti, setCheckIti] = useState(true);
	// State
	const [updatedPrice, setUpdatedPrice] = useState({
		price_group: arrPrices[0].price_group,
		price_name: arrPrices[0].price_name,
		cruise_id: arrPrices[0].cruise_id,
		season_type: arrPrices[0].season_type,
		from_date: arrPrices[0].from_date,
		to_date: arrPrices[0].to_date
	});
	const [cruiseID, setCruiseID] = useState(updatedPrice.cruise_id);

	// Start: Get all cruise , []
	useEffect(() => {
		if (cruiseID > 0) {
			async function itineraryData() {
				try {
					const response = await getAPI(
						`${apiUrl}/cruise-itineraries`,
						`filterFields=cruise_id&filterFieldValues=${cruiseID}`
					);
					setArrItineraries(response.data);
				} catch (error) {
					return error.response ? error.response : { success: false, message: 'Server error!' };
				}
			}
			itineraryData();
		}
	}, [cruiseID]); // eslint-disable-line react-hooks/exhaustive-deps

	let optionCruises = [];

	useEffect(() => {
		setUpdatedPrice({
			price_group: arrPrices[0].price_group,
			price_name: arrPrices[0].price_name,
			cruise_id: arrPrices[0].cruise_id,
			season_type: arrPrices[0].season_type,
			from_date: arrPrices[0].from_date,
			to_date: arrPrices[0].to_date
		});
		const prs = arrPrices.map((item, index) => {
			return {
				id: item.id,
				adult_price: item.adult_price,
				child_price: item.child_price ? item.child_price : 0,
				child_price_2: item.child_price_2 ? item.child_price_2 : 0,
				infant_price: item.infant_price > 0 ? item.infant_price : 0,
				discount: item.discount > 0 ? item.discount : 0,
				single_price: item.single_price > 0 ? item.single_price : 0,
				duration: item.duration,
				money_type: item.money_type,
				itinerary_id: item.itinerary
			};
		});

		setPrices(prs);
		setCruiseID(arrPrices[0].cruise_id);
	}, [arrPrices]);

	const setFuncPriceChange = (price, index) => {
		if (!price.id) return;

		if (!prices[index]) {
			prices[index] = {};
		}

		if (price) prices[index] = price;
	};

	const { price_name, season_type, from_date, to_date } = updatedPrice;

	const onChangeText = (event) => setUpdatedPrice({ ...updatedPrice, [event.target.name]: event.target.value });

	optionCruises = getOptions(cruises, ['label', 'value', 'typeDay'], ['name', 'id', 'typeDay']);
	
	const selectedCruise = cruises.find((item) => {
		return item.id == updatedPrice.cruise_id;
	})

	// select cruise
	const handleChangeCruise = (newValue) => {
		const dataCruise = newValue;
		if (dataCruise) {
			setCruiseID(dataCruise.value);
			setUpdatedPrice({ ...updatedPrice, cruise_id: Number(dataCruise.value), typeDay: dataCruise.typeDay });
			setCheckIti(true);
		} else {
			setCheckIti(false);
			setUpdatedPrice({ ...updatedPrice, cruise_id: '', typeDay: '' });
			setCruiseID(0);
		}
	};

	const onSubmit = async (event) => {
		event.preventDefault();
		updatedPrice['prices'] = prices;
		updatedPrice.from_date =
			updatedPrice.from_date && updatedPrice.from_date.length > 10
				? updatedPrice.from_date.substring(0, 10)
				: updatedPrice.from_date;
		updatedPrice.to_date =
			updatedPrice.to_date && updatedPrice.to_date.length > 10
				? updatedPrice.to_date.substring(0, 10)
				: updatedPrice.to_date;
		const response = await updatePrice(updatedPrice);
		if (response.status === 200) {
			closeDialog();
			setShowToast({ show: true, message: t('Updated successful!'), type: 'success' });
			return;
		}
		setShowToast({
			show: true,
			message: response.error && response.error.ErrorCode ? response.error.ErrorCode : 'Update failed!',
			type: 'danger'
		});
	};

	const closeDialog = () => {
		// setUpdatedPrice(updatedPrice);
		setShowUpdatePriceModal(false);
	};

	return (
		<Fragment>
			<Modal
				show={showUpdatePriceModal}
				onHide={closeDialog}
				size='full'
				aria-labelledby='contained-modal-title-vcenter'
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>{t('Update Price')}</Modal.Title>
				</Modal.Header>
				<Form onSubmit={onSubmit}>
					<Modal.Body>
						<div className='row'>
							<div className='col-md-8'>
								<div className='form-group'>
									<label>
										<strong>{t('Name')}</strong>
									</label>
									<input
										type='text'
										className='form-control'
										id='price_name'
										name='price_name'
										placeholder={t('Price Name')}
										value={price_name}
										required
										aria-describedby='name-help'
										onChange={onChangeText}
									/>
									<Form.Text id='name-help' muted>
										{t('Required')}
									</Form.Text>
								</div>
							</div>
							<div className='col-md-4'>
								<div className='form-group'>
									<label>
										<strong>{t('Cruise')}</strong>
									</label>
									<Select
										isClearable
										name='cruise_id'
										isDisabled={true}
										value={findOptionItem(optionCruises, arrPrices[0].cruise_id)}
										onChange={handleChangeCruise.bind(this)}
										options={optionCruises}
										placeholder={t('Choose Cruise')}
										aria-describedby='cruise-type'
									/>
									<Form.Text id='cruise-type' muted>
										{t('Required')}
									</Form.Text>
								</div>
							</div>
						</div>
						<div className='info-price'>
							<div className='row'>
								<div className='col-md-6'>
									<div className='form-group'>
										<label>
											<strong>{t('Price Type')}</strong>
										</label>
										<input
											type='text'
											className='form-control'
											id='season_type'
											name='season_type'
											value={season_type}
											placeholder={t('Ex: High Season')}
											required
											aria-describedby='type-price'
											onChange={onChangeText}
										/>
										<Form.Text id='type-price' muted>
											{t('Required')}
										</Form.Text>
									</div>
								</div>
								<div className='col-md-6'>
									<div className='row'>
										<div className='col-md-6'>
											<div className='form-group'>
												<label>
													<strong>{t('From')}</strong>
												</label>
												<Form.Control
													type='date'
													name='from_date'
													onChange={onChangeText}
													value={Moment(from_date).format('YYYY-MM-DD')}
													required
													aria-describedby='from-date'
												/>
												<Form.Text id='from-date' muted>
													{t('Required')}
												</Form.Text>
											</div>
										</div>
										<div className='col-md-6'>
											<div className='form-group'>
												<label>
													<strong>{t('To')}</strong>
												</label>
												<Form.Control
													type='date'
													name='to_date'
													onChange={onChangeText}
													value={Moment(to_date).format('YYYY-MM-DD')}
													required
													aria-describedby='to-date'
												/>
												<Form.Text id='to-date' muted>
													{t('Required')}
												</Form.Text>
											</div>
										</div>
									</div>
								</div>
							</div>
							<hr />
							{checkIti === true ? (
								<div id='accordion'>
									{prices &&
										prices.length &&
										arrItineraries.map((item, index) => {
											return (
												<UpdatePriceItem
													key={item.id}
													moneyOptions={moneyOptions}
													item={item}
													index={index}
													price={findPrice(prices, item)}
													setFuncPriceChange={setFuncPriceChange}
													typeDay={selectedCruise.typeDay}
													cruiseID={cruiseID}
												/>
											);
										})}
								</div>
							) : (
								''
							)}
						</div>
					</Modal.Body>
					{user.department !== 'acc' && (
						<Modal.Footer>
							<Button className='btn btn-danger btn-with-icon' variant='secondary' onClick={closeDialog}>
								<i className='fe fe-x-circle'></i> {t('Cancel')}
							</Button>
							<Button className='btn btn-primary btn-with-icon' variant='primary' type='submit'>
								<i className='fe fe-save'></i> {t('Save')}!!!
							</Button>
						</Modal.Footer>
					)}
				</Form>
			</Modal>
		</Fragment>
	);
};

const findPrice = (prices, itinerary) => {
	const pr = prices.find((p) => p.itinerary_id === itinerary.id);
	return pr ? pr : {};
};

export default UpdatePriceModal;
