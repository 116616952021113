import {
	TRANSPORTATION_PICKUP_LOADED_SUCCESS,
	TRANSPORTATION_PICKUP_LOADED_FAIL,
	ADD_TRANSPORTATION_PICKUP,
	FIND_TRANSPORTATION_PICKUP,
	UPDATE_TRANSPORTATION_PICKUP,
	DELETE_TRANSPORTATION_PICKUP
} from '../contexts/constants';

export const transportationPickUpReducer = (state, action) => {
	const { type, payload } = action;

	switch (type) {
		case TRANSPORTATION_PICKUP_LOADED_SUCCESS:
			return {
				...state,
				transportationPickUps: payload,
				transportationPickUpsLoading: false
			};
		case TRANSPORTATION_PICKUP_LOADED_FAIL:
			return {
				...state,
				transportationPickUps: [],
				transportationPickUpsLoading: false
			};
		case ADD_TRANSPORTATION_PICKUP: {
			return {
				...state,
				transportationPickUps: [payload, ...state.transportationPickUps]
			};
		}
		case FIND_TRANSPORTATION_PICKUP:
			return {
				...state,
				transportationPickUp: payload
			};
		case UPDATE_TRANSPORTATION_PICKUP:
			const newTransportationPickUp = state.transportationPickUps.map((transportationPickUp) => {
				if (transportationPickUp.id === payload.id) {
					return payload;
				} else {
					return transportationPickUp;
				}
			});
			return {
				...state,
				transportationPickUps: newTransportationPickUp
			};
		case DELETE_TRANSPORTATION_PICKUP:
			return {
				...state,
				transportationPickUps: state.transportationPickUps.filter(
					(transportationPickUp) => transportationPickUp.id !== payload
				)
			};
		default:
			return state;
	}
};
