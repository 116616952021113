import { createContext, useState, useReducer } from 'react';
import { carReducer } from '../reducers/carReducer';
import { apiUrl, CARS_LOADED_SUCCESS, CARS_LOADED_FAIL, ADD_CAR, FIND_CAR, UPDATE_CAR, DELETE_CAR } from './constants';
import axios from 'axios';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';

export const CarContext = createContext()

const CarContextProvider = ({ children }) => {

    // State
    const [carState, dispatch] = useReducer(carReducer, {
        car: null,
        cars: [],
        carsLoading: true
    })

    const [showAddCarModal, setShowAddCarModal] = useState(false)
    const [showUpdateCarModal, setShowUpdateCarModal] = useState(false)
    const [showToast, setShowToast] = useState({
        show: false,
        message: '',
        type: null
    })

    // Get all Cars
    const getCars = async() => {
        const response = await getAPI(`${apiUrl}/cars`)
        if(response.status === 200){
            dispatch({ type: CARS_LOADED_SUCCESS, payload: response.data })
            return response
        }
    
        dispatch({ type: CARS_LOADED_FAIL })
        return response
    }

    // Add Car
    const addCar = async newCruise => {
        const response = await postAPI(`${apiUrl}/car/insert`, newCruise)
        if(response.status === 200){
            dispatch({
                type: ADD_CAR,
                payload: response.data
            })
        }
    
        return response
    }

    // Find Car when user is updating post
    const findCar = carId => {
        const car = carState.cars.find(car => car.id === carId)
        dispatch({
            type: FIND_CAR,
            payload: car
        })
    }

    // update Car
    const updateCar = async updateCruise => {
        const response = await postAPI(`${apiUrl}/car/update`, updateCruise)
        if(response.status === 200){
            dispatch({
                type: UPDATE_CAR,
                payload: response.data
            })
        }
    
        return response
    }

    // Delete Car
    const deleteCar = async carId => {
        const response = await getDeleteAPI(`${apiUrl}/car/${carId}/delete`)
        if(response.status === 200){
            dispatch({
                type: DELETE_CAR,
                payload: carId
            })
        }
        return response
    }

    // Post Context Data
    const carContextData = {
        carState,
        getCars,
        addCar,
        findCar,
        updateCar,
        deleteCar,
        showToast,
        setShowToast,
        showAddCarModal,
        setShowAddCarModal,
        showUpdateCarModal,
        setShowUpdateCarModal
    }

    return (
        <CarContext.Provider value = { carContextData } >
            { children }
        </CarContext.Provider>
    )

}

export default CarContextProvider