import { SERVICES_LOADED_SUCCESS, SERVICES_LOADED_FAIL, ADD_SERVICE, FIND_SERVICE, UPDATE_SERVICE, DELETE_SERVICE } from '../contexts/constants';

export const serviceReducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case SERVICES_LOADED_SUCCESS:
            return {
                ...state,
                servicesLoading: false,
                services: payload
            }
        case SERVICES_LOADED_FAIL:
            return {
                ...state,
                services: [],
                servicesLoading: false
            }
        case ADD_SERVICE:
            return {
                ...state,
                services: [...state.services, payload],
            }
        case FIND_SERVICE:
            return {
                ...state,
                service: payload
            }
        case UPDATE_SERVICE:
            const newService = state.services.map(service => {
                if (service.id === payload.id) {
                    return payload
                } else {
                    return service
                }
            })
            return {
                ...state,
                services: newService
            }
        case DELETE_SERVICE:
            return {
                ...state,
                services: state.services.filter(service => service.id !== payload)
            }
        default:
            return state
    }
}