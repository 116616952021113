import { Fragment, useContext, useState, useEffect } from 'react';
import { ItineraryContext } from '../../contexts/ItineraryContext';
import { CruiseContext } from '../../contexts/CruiseContext';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';

const UpdateItineraryModal = () => {
	const [showAlert, setShowAlert] = useState(false);
	const [textContent, setTextContent] = useState(null);

	const {
		itineraryState: { itinerary },
		showUpdateItineraryModal,
		setShowUpdateItineraryModal,
		updateItinerary,
		setShowToast
	} = useContext(ItineraryContext);

	let optionCruises = [];

	const {
		cruiseState: { cruises },
		getCruises
	} = useContext(CruiseContext);

	// Start: Get all cruise , []
	useEffect(() => getCruises(), []); // eslint-disable-line react-hooks/exhaustive-deps

	// State
	const [updatedItinerary, setUpdatedItinerary] = useState(itinerary);
	useEffect(() => setUpdatedItinerary(itinerary), [itinerary]);

	const { id, name, duration, cruise_id } = updatedItinerary;

	const onChangeNew = (event) => setUpdatedItinerary({ ...updatedItinerary, [event.target.name]: event.target.value });
	const onChangeNumber = (event) =>
		setUpdatedItinerary({ ...updatedItinerary, [event.target.name]: Number(event.target.value) });

	if (cruises) {
		cruises.map((cruise) => {
			optionCruises = [
				...optionCruises,
				{
					value: cruise.id,
					label: cruise.name,
					typeDay: cruise.typeDay
				}
			];
		});
	}

	const onSubmit = async (event) => {
		event.preventDefault();
		if (cruise_id === 0) {
			setTextContent('Please choose cruise!');
			setShowAlert(true);
			return;
		}
		const response = await updateItinerary(updatedItinerary);
		if (response.status === 200) {
			closeDialog();
			setShowToast({ show: true, message: 'Updated successful!', type: 'success' });
			return;
		}
		setShowToast({
			show: true,
			message: response.error && response.error.ErrorCode ? response.error.ErrorCode : 'Update failed!',
			type: 'danger'
		});
	};

	const closeDialog = () => {
		setUpdatedItinerary(itinerary);
		setShowUpdateItineraryModal(false);
	};

	// select cruise
	const handleChange = (newValue, actionMeta) => {
		if (newValue !== null) {
			setUpdatedItinerary({ ...updatedItinerary, cruise_id: Number(newValue.value) });
		}
	};

	const AlertDismissible = () => {
		setTimeout(function () {
			setShowAlert(false);
		}, 3000);
		return (
			<Alert variant='danger'>
				{textContent !== null ? (
					<p>
						<strong>{textContent}</strong>
					</p>
				) : null}
			</Alert>
		);
	};

	return (
		<Fragment>
			<Modal
				show={showUpdateItineraryModal}
				onHide={closeDialog}
				size='lg'
				aria-labelledby='contained-modal-title-vcenter'
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Update Itinerary</Modal.Title>
				</Modal.Header>
				<Form onSubmit={onSubmit}>
					<Modal.Body>
						{showAlert ? <AlertDismissible /> : null}
						<div className='form-group'>
							<input type='hidden' name='id' value={id} />
							<Row>
								<Col>
									<div className='form-group'>
										<label>
											<strong>Name</strong>
										</label>
										<input
											className='form-control'
											placeholder='Enter Itinerary Name'
											name='name'
											value={name}
											onChange={onChangeNew}
											required
										/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className='form-group'>
										<label>
											<strong>Duration</strong>
										</label>
										<input
											className='form-control'
											placeholder='No. Night'
											name='duration'
											value={duration}
											onChange={onChangeNumber}
										/>
									</div>
								</Col>
								<Col>
									<div className='form-group'>
										<label>
											<strong>Cruise</strong>
										</label>
										<Select
											isClearable
											name='cruiseID'
											onChange={handleChange.bind(this)}
											options={optionCruises}
											placeholder='Choose Cruise'
											defaultValue={optionCruises.filter((option) =>
												option.value === cruise_id ? { label: option.label, value: option.value } : ''
											)}
											value={optionCruises.filter((option) =>
												option.value === cruise_id ? { label: option.label, value: option.value } : ''
											)}
										/>
									</div>
								</Col>
							</Row>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button className='btn btn-danger btn-with-icon' variant='secondary' onClick={closeDialog}>
							<i className='fe fe-x-circle'></i> Cancel
						</Button>
						<Button className='btn btn-primary btn-with-icon' variant='primary' type='submit'>
							<i className='fe fe-save'></i> Save!!!
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</Fragment>
	);
};

export default UpdateItineraryModal;
