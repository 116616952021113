import { Fragment, useContext } from 'react'
import { OnboardServiceContext } from '../../contexts/OnboardServiceContext'
import ListOnboardService from '../../components/layout/onboard-services/ListOnboardService'
import AddOnboardServiceModal from '../../components/layout/onboard-services/AddOnboardServiceModal'
import { AuthContext } from '../../contexts/AuthContext';
import { ROLES, PERMISSIONS, NO_PERMISSION } from '../../contexts/constants';

const OnboardService = () => {

    const { 
        setShowAddOnboardServiceModal
    } = useContext(OnboardServiceContext)

    const {
        authState: {user }
    } = useContext(AuthContext)

    if (!user) {
        return NO_PERMISSION
    }

    if (!(user.Permissions.includes(PERMISSIONS.GET_ONBOARD_SERVICE) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))) {
        return NO_PERMISSION
    }
    return (
        <Fragment>
            <div className="horizontal-mainwrapper container-fluid clearfix">
                <div className="explain-dashboard mt-5">
                    <div className="explain-header mb-3">
                        <div className="row">
                            <div className="col-md-4">
                                <h3 className="pull-left">Onboard Service List</h3>
                            </div>
                            <div className="col-md-2 ml-auto">
                            {(user.Permissions.includes(PERMISSIONS.INSERT_ONBOARD_SERVICE) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))? <button className="right-content btn btn-success btn-block" onClick={setShowAddOnboardServiceModal.bind(this, true)}><i className="fe fe-plus-circle"></i> Add Onboard Service</button> : null }
                            </div>
                        </div>
                        <hr />
                    </div>
                    <ListOnboardService />
                    <AddOnboardServiceModal />
                </div>
            </div>
        </Fragment>
    )
}

export default OnboardService;
