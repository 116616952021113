import { Fragment, useState, useEffect, useContext } from 'react';
import { Accordion, Button, Card, Form } from 'react-bootstrap';
import Select from 'react-select';
import { CRUISE_TYPES } from '../../../contexts/constants';
import { useTranslation } from 'react-i18next';
import { CruiseSettingContext } from '../../../contexts/CruiseSettingContext';
import NumberFormat from 'react-number-format'

const UpdatePriceItem = ({ moneyOptions, item, index, price, setFuncPriceChange, typeDay, cruiseID }) => {
	const { t } = useTranslation();
	const [idx, setIdx] = useState([]);
	const [updatedItemPrice, setUpdatedItemPrice] = useState(price ? price : {});
	useEffect(() => {
		if (price) setUpdatedItemPrice(price);
	}, [price]);

	const {
        cruiseSettingState: { cruiseSettings },
    } = useContext(CruiseSettingContext)

	const [cruiseSetting, setCruiseSetting] = useState({});
	useEffect(() => setFuncPriceChange(updatedItemPrice, index), [updatedItemPrice, index]);

	const {
		id,
		adult_price,
		child_price,
		child_price_2,
		infant_price,
		single_price,
		discount,
		duration,
		money_type,
		itinerary_id
	} = updatedItemPrice;

	const onChangeNumber = (event) =>{
		console.log('event', event);
		setUpdatedItemPrice({
			...updatedItemPrice,
			[event.target.name]: Number(event.target.value),
			itinerary_id: item.id
		});
	}

	const handleChangeMoney = (newValue) => {
		const data = newValue;
		if (data) {
			setUpdatedItemPrice({ ...updatedItemPrice, money_type: data.value });
		} else {
			setUpdatedItemPrice({ ...updatedItemPrice, money_type: data.value });
		}
	};

	const handleChangeAccordion = (numberIndex) => {
		if (idx.includes(numberIndex)) setIdx(idx.filter((i) => i !== numberIndex));
		else setIdx([numberIndex]);
	};

	useEffect(() => {
		const cruiseSetting = cruiseSettings? cruiseSettings.find((item) => {
			return item.cruise_id == cruiseID;
		}): {}
		setCruiseSetting(cruiseSetting);
	}, [cruiseID]);

	const test = (a, b) => {
		console.log('a', a);
		console.log('b', b);
	};

	return (
		<Fragment>
			<Card>
				<Accordion>
					<Card.Header className='position-relative'>
						<Accordion.Toggle
							as={Button}
							variant='link'
							eventKey={`${index}`}
							onClick={handleChangeAccordion.bind(this, index)}
							className={`text-left`}
						>
							<label style={{ margin: '0px', lineHeight: '35px', cursor: 'pointer' }}>
								{idx.includes(index) ? <i className='fe fe-minus'></i> : <i className='fe fe-plus'></i>}
								&nbsp;&nbsp;&nbsp;<strong>{item.name}</strong>
							</label>
						</Accordion.Toggle>
					</Card.Header>
					<Accordion.Collapse eventKey={`${index}`}>
						<Card.Body>
							<div className='content-accordion'>
								<div className='row'>
									<div className='col-sm'>
										<div className='form-group'>
											<label>
												<strong>1 {t('Adult')}</strong>
											</label>
											<input
												type='number'
												step='any'
												className='form-control'
												id='adult_price'
												name='adult_price'
												value={adult_price}
												placeholder={t('No. Price')}
												required
												onChange={onChangeNumber.bind(this)}
											/>
											{/* <NumberFormat
												step='any'
												// thousandSeparator={true} 
												thousandSeparator=","
												decimalSeparator="."
												decimalScale={2}
												// prefix={'$'} 
												id='adult_price'
												name='adult_price'
												// value={adult_price}
												required
												onChange={onChangeNumber.bind(this)}
												placeholder={t('No. Price')}
												onValueChange={test.bind(this, 'adult_price')}
												// isAllowed={(values) => {
												// 	const {floatValue} = values;
												// 	return floatValue >= 5 &&  floatValue <= 10000;
												// }}
											/> */}
										</div>
									</div>
									<div className='col-sm'>
										<div className='form-group'>
											<label>
												<strong>{cruiseSetting && cruiseSetting.child_label1? t(cruiseSetting.child_label1): t('Child (5-7)')}</strong>
											</label>
											<input
												type='number'
												step='any'
												className='form-control'
												id='child_price'
												name='child_price'
												value={child_price}
												placeholder={t('No. Price')}
												required
												onChange={onChangeNumber.bind(this)}
											/>
										</div>
									</div>
									<div className='col-sm'>
										<div className='form-group'>
											<label>
												<strong>{cruiseSetting && cruiseSetting.child_lable2? t(cruiseSetting.child_lable2): t('Child (8-11)')}</strong>
											</label>
											<input
												type='number'
												step='any'
												className='form-control'
												id='child_price_2'
												name='child_price_2'
												value={child_price_2}
												placeholder={t('No. Price')}
												required
												onChange={onChangeNumber.bind(this)}
											/>
										</div>
									</div>
									<div className='col-sm'>
										<div className='form-group'>
											<label>
												<strong>{`1` + t('Infant') + `(< 5)`}</strong>
											</label>
											<input
												type='number'
												step='any'
												className='form-control'
												id='infant_price'
												name='infant_price'
												value={infant_price}
												placeholder={t('No. Price')}
												onChange={onChangeNumber.bind(this)}
											/>
										</div>
									</div>
									{/* <div className="col-sm">
                                        <div className="form-group">
                                            <label><strong>{`Discount`}</strong></label>
                                            <input type="number" step="any" className="form-control" id="discount" name="discount" value={discount} placeholder="No. Price" onChange={onChangeNumber.bind(this)} />
                                        </div>
                                    </div> */}
									{typeDay === CRUISE_TYPES.NIGHT && (
										<div className='col-sm'>
											<div className='form-group'>
												<label>
													<strong>{t(`Single`)}</strong>
												</label>
												<input
													type='number'
													step='any'
													className='form-control'
													id='single_price'
													name='single_price'
													value={single_price}
													placeholder={t('No. Price')}
													onChange={onChangeNumber.bind(this)}
												/>
											</div>
										</div>
									)}
									<div className='col-sm'>
										<div className='form-group'>
											<label>
												<strong>{t('Money Type')}</strong>
											</label>
											<Select
												isClearable
												required
												aria-describedby={'money-type-help-' + id}
												name='money'
												defaultValue={moneyOptions.filter((option) =>
													option.value === money_type ? { label: option.label, value: option.value } : ''
												)}
												onChange={handleChangeMoney.bind(this)}
												options={moneyOptions}
												placeholder={t('Choose Money Type')}
												menuPosition={'fixed'}
											/>
											<Form.Text id={'money-type-help' + id} muted>
												{t('Required')}
											</Form.Text>
										</div>
									</div>
								</div>
							</div>
						</Card.Body>
					</Accordion.Collapse>
				</Accordion>
			</Card>
		</Fragment>
	);
};

export default UpdatePriceItem;
