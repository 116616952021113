import { Fragment, useState } from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import CustomerInfoHistory from './CustomerInfoHistory';
import { useTranslation } from 'react-i18next';

const InfoHistoryBooking = ({ index, bookingHistory, booking, tripType }) => {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	let paidAmount = 0;
	let balance = 0;
	let netIncome = 0;

	if (bookingHistory) {
		if (bookingHistory.deposit !== undefined) {
			paidAmount = bookingHistory.deposit;
			balance = bookingHistory.total - bookingHistory.deposit;
			if (bookingHistory.com_year !== undefined) {
				netIncome = bookingHistory.total - bookingHistory.deposit - bookingHistory.com_year;
			} else {
				netIncome = bookingHistory.total - bookingHistory.deposit;
			}
		} else {
			balance = bookingHistory.total;
			if (bookingHistory.com_year !== undefined) {
				netIncome = bookingHistory.total - bookingHistory.com_year;
			} else {
				netIncome = bookingHistory.total;
			}
		}
	}

	const { t } = useTranslation();

	return (
		<Fragment>
			<tr>
				<td>{index + 1}</td>
				<td>
					<button className='btn btn-customer' onClick={handleShow}>
						{bookingHistory.updated_by_name}
					</button>
					<Modal show={show} onHide={handleClose} size='full' aria-labelledby='contained-modal-title-vcenter' centered>
						<Modal.Header closeButton>
							<Modal.Title>
								{bookingHistory.updated_by_name} - {index === 0 ? t('Created') : t('Updated')}
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className='row'>
								<div className='col-md-6'>
									<article className='message is-info'>
										<div className='message-body'>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Booking Code')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>{booking.new_code}</p>
													</div>
												</div>
											</div>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Partner')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															<Link to='#' className='link-partner' title={bookingHistory.partner_name} target='_blank'>
																{bookingHistory.partner_name}
															</Link>
														</p>
													</div>
												</div>
											</div>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Partner Code')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>{booking.booking_code}</p>
													</div>
												</div>
											</div>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Cruise')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>{bookingHistory.cruise_name}</p>
													</div>
												</div>
											</div>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Itinerary')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															<strong>{bookingHistory.itinerary_name}</strong>
														</p>
													</div>
												</div>
											</div>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Departure')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															<strong>{moment(bookingHistory.start_date).format('MMMM D, YYYY')}</strong>
														</p>
													</div>
												</div>
											</div>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Return')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															<strong>{moment(bookingHistory.end_date).format('MMMM D, YYYY')}</strong>
														</p>
													</div>
												</div>
											</div>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Group size')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															<strong>
																{bookingHistory.adults + ' Adult'}{' '}
																{bookingHistory.children > 0
																	? ' - ' + bookingHistory.children + ' ' + t('Children')+ ' (5 - 11)'
																	: ''}{' '}
																{bookingHistory.children11 > 0
																	? ' - ' + bookingHistory.children11 + ' '+ t('Children') + ' (> 11)'
																	: ''}{' '}
																{bookingHistory.infants > 0 ? ' - ' + bookingHistory.infants + ' ' + t('Infant') : ''}
															</strong>
														</p>
													</div>
												</div>
											</div>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Status')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															<strong>{bookingHistory.status}</strong>
														</p>
													</div>
												</div>
											</div>

											{bookingHistory.status === 'Pending' ? (
												<Fragment>
													<div className='field is-horizontal'>
														<div className='field-label is-normal'>
															<label className='label'>{t('Hold until')}</label>
														</div>
														<div className='field-body'>
															<div className='field'>
																<p className='control'>
																	<strong>{moment(bookingHistory.hold_date).format('MMMM D, YYYY')}</strong>
																</p>
															</div>
														</div>
													</div>
													<div className='field is-horizontal'>
														<div className='field-label is-normal'>
															<label className='label'>{t('Select time')}</label>
														</div>
														<div className='field-body'>
															<div className='field'>
																<p className='control'>
																	<strong>{bookingHistory.hold_time}</strong>
																</p>
															</div>
														</div>
													</div>
													<div className='field is-horizontal'>
														<div className='field-label is-normal'>
															<label className='label'>{t('Message')}</label>
														</div>
														<div className='field-body'>
															<div className='field'>
																<p className='control'>{bookingHistory.message}</p>
															</div>
														</div>
													</div>
												</Fragment>
											) : (
												''
											)}

											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Assign To')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>{bookingHistory.assign_name}</p>
													</div>
												</div>
											</div>
										</div>
									</article>
								</div>
								<div className='col-md-6'>
									<article className='message is-info'>
										<div className='message-body'>
											{bookingHistory.duration > 1 ? (
												<div className='field is-horizontal'>
													<div className='field-label is-normal'>
														<label className='label'>{t('Cabin')}</label>
													</div>
													<div className='field-body'>
														<div className='field'>
															<p className='control'>
																<strong>{bookingHistory.cabin_desc}</strong>
															</p>
														</div>
													</div>
												</div>
											) : (
												''
											)}
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Pick-up/Drop-off')}</label>
												</div>
												<div className='field-body'>
													<div
														className='field'
														dangerouslySetInnerHTML={{
															__html: bookingHistory.pickup
														}}
													></div>
												</div>
											</div>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Sales notes')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p
															className='control'
															dangerouslySetInnerHTML={{
																__html: bookingHistory.sale_note
															}}
														></p>
													</div>
												</div>
											</div>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Notes Accounting')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p
															className='control'
															dangerouslySetInnerHTML={{
																__html: bookingHistory.acc_note
															}}
														></p>
													</div>
												</div>
											</div>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Operation Notes')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p
															className='control'
															dangerouslySetInnerHTML={{
																__html: bookingHistory.opt_note
															}}
														></p>
													</div>
												</div>
											</div>

											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Create By')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>{bookingHistory.updated_by_name}</p>
													</div>
												</div>
											</div>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Create Date')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															{moment(bookingHistory.created_at).utc().format('MMMM D, YYYY, h:mm:ss a')}
														</p>
													</div>
												</div>
											</div>
										</div>
									</article>
								</div>
							</div>
							<div className='row'>
								<div className='col-md-6'></div>
								<div className='col-md-6'>
									<article className='message is-danger'>
										<div className='message-body'>
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Total')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															{bookingHistory.money_type === 'usd' ? (
																<NumberFormat
																	value={bookingHistory.total}
																	displayType={'text'}
																	thousandSeparator='.'
																	decimalSeparator=','
																	decimalScale={2}
																	prefix={'USD '}
																/>
															) : (
																//bookingDetail.total + ' USD'
																<NumberFormat
																	value={bookingHistory.total}
																	displayType={'text'}
																	thousandSeparator='.'
																	decimalSeparator=','
																	decimalScale={0}
																	prefix={'VNĐ '}
																/>
															)}
														</p>
													</div>
												</div>
											</div>
											{/*
                                        @if($booking->comYear > 0)
                                            <div className="field is-horizontal">
                                                <div className="field-label is-normal">
                                                    <label className="label">Commission</label>
                                                </div>
                                                <div className="field-body">
                                                    <div className="field">
                                                        <p className="control">
                                                            
                                                        </p>
                                                        <p className="control">
                                                            
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        @endif
                                        */}

											{/*@if($booking->discount > 0)
                                            <div className="field is-horizontal">
                                                <div className="field-label is-normal">
                                                    <label className="label">Discount</label>
                                                </div>
                                                <div className="field-body">
                                                    <div className="field">
                                                        <p className="control">
                                                            @if($booking->moneyType == "usd")
                                                                {{$booking->discount}} USD
                                                            @else
                                                                {{number_format($booking->discount, 0, '', ',')}} VNĐ
                                                            @endif
                                                        </p>
                                                        <p className="control">
                                                            {{$booking->discountNote}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        @endif
                                        */}
											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Paid Amount')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															{booking.money_type === 'usd' ? (
																<NumberFormat
																	value={paidAmount}
																	displayType={'text'}
																	thousandSeparator='.'
																	decimalSeparator=','
																	decimalScale={2}
																	prefix={'USD '}
																/>
															) : (
																//bookingDetail.total + ' USD'
																<NumberFormat
																	value={paidAmount}
																	displayType={'text'}
																	thousandSeparator='.'
																	decimalSeparator=','
																	decimalScale={0}
																	prefix={'VNĐ '}
																/>
															)}
														</p>
													</div>
												</div>
											</div>

											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Balance')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															{booking.money_type === 'usd' ? (
																<NumberFormat
																	value={balance}
																	displayType={'text'}
																	thousandSeparator='.'
																	decimalSeparator=','
																	decimalScale={2}
																	prefix={'USD '}
																/>
															) : (
																<NumberFormat
																	value={balance}
																	displayType={'text'}
																	thousandSeparator='.'
																	decimalSeparator=','
																	decimalScale={0}
																	prefix={'VNĐ '}
																/>
															)}
														</p>
													</div>
												</div>
											</div>

											<div className='field is-horizontal'>
												<div className='field-label is-normal'>
													<label className='label'>{t('Net Income')}</label>
												</div>
												<div className='field-body'>
													<div className='field'>
														<p className='control'>
															{booking.money_type === 'usd' ? (
																<NumberFormat
																	value={netIncome}
																	displayType={'text'}
																	thousandSeparator='.'
																	decimalSeparator=','
																	decimalScale={2}
																	prefix={'USD '}
																/>
															) : (
																<NumberFormat
																	value={netIncome}
																	displayType={'text'}
																	thousandSeparator='.'
																	decimalSeparator=','
																	decimalScale={0}
																	prefix={'VNĐ '}
																/>
															)}
														</p>
													</div>
												</div>
											</div>
										</div>
									</article>
								</div>
							</div>
							<CustomerInfoHistory
								bookingIdProps={bookingHistory.id}
								durationProps={bookingHistory.duration}
								tripType={tripType}
							/>
						</Modal.Body>
					</Modal>
				</td>
				<td>{index === 0 ? t('Created') : t('Updated')}</td>
				<td>{moment(bookingHistory.created_at).utc().format('MMMM D, YYYY, h:mm:ss a')}</td>
			</tr>
		</Fragment>
	);
};

export default InfoHistoryBooking;
