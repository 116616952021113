import { createContext, useReducer, useState } from 'react';
import { specialReducer } from '../reducers/specialReducer';
import {
	apiUrl,
	SPECIAL_CABINS_LOADED_SUCCESS,
	SPECIAL_CABINS_LOADED_FAIL,
	ADD_SPECIAL_CABIN,
	FIND_SPECIAL_CABIN,
	UPDATE_SPECIAL_CABIN,
	DELETE_SPECIAL_CABIN,
} from './constants';
import { getAPI, getDeleteAPI, postAPI } from '../utils/api';

export const SpecialContext = createContext();

const SpecialContextProvider = ({ children }) => {
	// State
	const [specialState, dispatch] = useReducer(specialReducer, {
		special: null,
		specials: [],
		specialsLoading: true,
	});

	const [showAddSpecialModal, setShowAddSpecialModal] = useState(false);
	const [showUpdateSpecialModal, setShowUpdateSpecialModal] = useState(false);
	const [showToast, setShowToast] = useState({
		show: false,
		message: '',
		type: null,
	});

	// Get all specials
	const getSpecials = async () => {
		const response = await getAPI(`${apiUrl}/cabin-specials`);
		if (response.data) {
			dispatch({ type: SPECIAL_CABINS_LOADED_SUCCESS, payload: response.data });
			return response;
		}

		dispatch({ type: SPECIAL_CABINS_LOADED_FAIL });
		return response;
	};

	// Add Special
	const addSpecial = async (newSpecial) => {
		const response = await postAPI(`${apiUrl}/cabin-special/insert`, newSpecial);
		if (response.data) {
			dispatch({
				type: ADD_SPECIAL_CABIN,
				payload: response.data,
			});
		}
		return response;
	};

	// Find special when user is updating special
	const findSpecial = (specialId) => {
		const special = specialState.specials.find((special) => special.id === specialId);
		dispatch({
			type: FIND_SPECIAL_CABIN,
			payload: special,
		});
	};

	// Update Special
	const updateSpecial = async (updateSpecial) => {
		const response = await postAPI(`${apiUrl}/cabin-special/update`, updateSpecial);
		if (response.status === 200) {
			dispatch({
				type: UPDATE_SPECIAL_CABIN,
				payload: updateSpecial,
			});
		}
		return response;
	};

	// Delete Special
	const deleteSpecial = async (guideId) => {
		const response = await getDeleteAPI(`${apiUrl}/cabin-special/${guideId}/delete`);
		if (response.status === 200) {
			dispatch({
				type: DELETE_SPECIAL_CABIN,
				payload: guideId,
			});
		}
		return response;
	};

	const clearSpecial = () => {
		dispatch({
			type: FIND_SPECIAL_CABIN,
			payload: {},
		});
	};

	const specialContextData = {
		specialState,
		getSpecials,
		addSpecial,
		findSpecial,
		updateSpecial,
		deleteSpecial,
		showAddSpecialModal,
		setShowAddSpecialModal,
		showUpdateSpecialModal,
		setShowUpdateSpecialModal,
		showToast,
		setShowToast,
		clearSpecial,
	};

	return <SpecialContext.Provider value={specialContextData}>{children}</SpecialContext.Provider>;
};

export default SpecialContextProvider;
