import { Fragment, useContext, useEffect } from 'react';
import { CruiseSettingContext } from '../../contexts/CruiseSettingContext';
import UpdateCruiseSettingModal from './UpdateCruiseSettingModal';
import { Card, Spinner } from "react-bootstrap";
import { AuthContext } from '../../contexts/AuthContext';
import moment from 'moment';
import "datatables.net-dt/js/dataTables.dataTables.min"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { useTranslation } from 'react-i18next';

const ListCruiseSetting = () => {
    const { t } = useTranslation();
    // Context
    const {authState: {user: { name } }} = useContext(AuthContext)

    const {
        cruiseSettingState: { cruiseSetting, cruiseSettings, cruiseSettingsLoading },
        getCruiseSettings,
        findCruiseSetting,
        setShowUpdateCruiseSettingModal
    } = useContext(CruiseSettingContext)

    // Start: Get all specials , []
    useEffect( () => getCruiseSettings(), []) // eslint-disable-line react-hooks/exhaustive-deps

    setTimeout(function(){ $('#list-itinerary').DataTable(); }, 1000);

    const chooseCruiseSetting = cruiseSettingId => {
        findCruiseSetting(cruiseSettingId)
        setShowUpdateCruiseSettingModal(true)
    }

    let body = null

    if(cruiseSettingsLoading){
        body = (
            <div className="spinner-container">
                <Spinner animation='border' variant='info'></Spinner>
            </div>
        )
    }else if(cruiseSettings === null || cruiseSettings.length === 0){
        body = (
            <Fragment>
                <Card className='text-center mx-5 my-5'>
                    <Card.Header as='h1'>Hi {name}</Card.Header>
                    <Card.Body>
                        <Card.Title>{t('Welcome to Cruise Setting List Management')}</Card.Title>
                        <Card.Text>
                            {t('Click The Button "Cruise Setting" to Save Your Cruise Settings')}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Fragment>
        )
    }else{
        body = (
            <Fragment>
                <div className="ajax-booking-cruise">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="table-info-cruise mg-b-20">
                                <div className="table-info-cruise-body">
                                    <div className="table-responsive">
                                        <table id="list-itinerary" className="table display">
                                            <thead>
                                                <tr>
                                                    <th>STT</th>
                                                    <th>{t('Cruises')}</th>
                                                    <th>{t('Created date')}</th>
                                                    <th className="w-220"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cruiseSettings.map((setting, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={ {lineHeight: "38px"} }>{index + 1}</td>
                                                            <td style={ {lineHeight: "38px"} }>{setting.cruise_name}</td>
                                                            <td style={ {lineHeight: "38px"} }>{moment(setting.created_at).format("MMMM D, YYYY")}</td>
                                                            <td>
                                                                <div className="row">
                                                                    <div className="col-sm">
                                                                        <button className="btn btn-success btn-with-icon" onClick={chooseCruiseSetting.bind(this, setting.id)}><i className="fe fe-edit"></i> {t('Edit')}</button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                )})}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            {body}
            {cruiseSetting !== null && <UpdateCruiseSettingModal />}
        </Fragment>
    )
}

export default ListCruiseSetting
