const CSV = require('csv-string');
export const csvFileToArray = str => {
    const separator = CSV.detect(str);
    const separateLines = str.split(/\r?\n|\r|\n/g);
    separateLines.shift();
    const csvHeader = separateLines.shift().split(separator);

    const array = separateLines.map(i => {
        const values = i.split(separator);
        const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
        }, {});
        return obj;
    });

    return array;
};