import { useContext, useState, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap';
import { PartnerContext } from '../../../contexts/PartnerContext';
import { PriceContext } from '../../../contexts/PriceContext';
import Select from 'react-select';
import { apiUrl } from '../../../contexts/constants';
import { postAPI } from '../../../utils/api';
import { useTranslation } from 'react-i18next';

const AddPartnerModal = props => {
    const { t } = useTranslation();
    // Context
    const {
        addPartner,
        showAddPartnerModal,
        setShowAddPartnerModal,
        setShowToast
    } = useContext(PartnerContext)

    const {
        priceState: { prices },
        getPrices
    } = useContext(PriceContext)

    // Start: Get all rooms , []
    useEffect( () => getPrices(), []) // eslint-disable-line react-hooks/exhaustive-deps

    //const [isValid, setIsValid] = useState(false);

    let optionPrices = []
    let dem = 0
    let dataArrays = []
    let dataItemGroups = []
    prices.forEach((price) => {
        if (!dataArrays[price.price_group]) {
            dataArrays[price.price_group] = {};
            dataItemGroups.push(price.price_group);
            dem = 0
        }
        dataArrays[price.price_group][dem] = price;
        dem++
    })

    if(dataItemGroups){
        dataItemGroups.map((data) => {
            const cruise = dataArrays[data]
            optionPrices = [ ...optionPrices,
                {
                    value: cruise[0].price_group,
                    label: cruise[0].price_name,
                }
            ]
        })
    }

    const users = props.usersProps

    // State
    const [newPartner, setNewPartner] = useState({
        name: '',
        phone: '',
        fax: '',
        hotline: '',
        email: '',
        weblink: '',
        address: '',
        city: '',
        description: '',
        assign_to: '',
        commission: 0,
        commission_note: '',
        vat: 0,
        partner_type: '',
    })

    const {name, phone, fax, hotline, email, weblink, address, city, description, assign_to, commission, commission_note, vat, partner_type} = newPartner

    const onChangeNewPartnerForm = event => setNewPartner( {...newPartner, [event.target.name]: event.target.value } )
	const onChangeNumber = event => setNewPartner( {...newPartner, [event.target.name]: Number(event.target.value) } )

    const closeDialog = () => {
        resetAddPartnerData()
    }

    const resetAddPartnerData = () => {
        setNewPartner({
            name: '',
            phone: '',
            fax: '',
            hotline: '',
            email: '',
            weblink: '',
            address: '',
            city: '',
            description: '',
            assign_to: '',
            commission: 0,
            commission_note: '',
            vat: 0,
            partner_type: '',
        })
        setShowAddPartnerModal(false)
    }
    const onSubmit = async event => {
        event.preventDefault()
        const response = await addPartner(newPartner)
        if (response.status === 200) {
            resetAddPartnerData()
            setShowToast({show: true, message: t('Added successful!'), type: 'success'})
            return
        }
        setShowToast({show: true, message: response.error && response.error.ErrorCode? response.error.ErrorCode: 'Add failed!', type: 'danger'})
    }

    // pending choose
    const handleChange = (newValue) => {
        const dataOption = newValue;
        let arrGroupPrice = ""
        if(dataOption.length > 0) {
            dataOption.map((data, index) => {
                if(index === 0) {
                    arrGroupPrice = data.value
                }else{
                    arrGroupPrice = arrGroupPrice + "," + data.value
                }
            })
            setNewPartner( {...newPartner, price_groups: arrGroupPrice } )
            //setIsValid(!isValid)
        }else{
            arrGroupPrice = ""
            setNewPartner( {...newPartner, price_groups: arrGroupPrice } )
            //setIsValid(!isValid)
        }
    };

    const ChangeToSlug = (Text) =>
    {
        var slug;

        //Đổi chữ hoa thành chữ thường
        slug = Text.toLowerCase();

        //Đổi ký tự có dấu thành không dấu
        slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
        slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
        slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
        slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
        slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
        slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
        slug = slug.replace(/đ/gi, 'd');
        //Xóa các ký tự đặt biệt
        slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi, '');
        //Đổi khoảng trắng thành ký tự gạch ngang
        slug = slug.replace(/ /gi, "-");
        //Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
        //Phòng trường hợp người nhập vào quá nhiều ký tự trắng
        slug = slug.replace(/\-\-\-\-\-/gi, '-');
        slug = slug.replace(/\-\-\-\-/gi, '-');
        slug = slug.replace(/\-\-\-/gi, '-');
        slug = slug.replace(/\-\-/gi, '-');
        //Xóa các ký tự gạch ngang ở đầu và cuối
        slug = '@' + slug + '@';
        slug = slug.replace(/\@\-|\-\@|\@/gi, '');
        //In slug ra textbox có id “slug”
        return slug;
    }

    const randomText = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const handleChangeUploadAvatar = async (event) => { //
        event.preventDefault()
        const files = event.target.files;
        var ext = "";
        var textFile = ""
        const formData = new FormData()
        for (let i = 0; i < files.length; i++) {
            if(i === 0){
                ext = files[i].name.split(".").slice(-1)[0];
                formData.append(`files`, files[i], ChangeToSlug(files[i].name.split(".")[0]) + "-" + randomText(8) + "." + ext);
                textFile = ChangeToSlug(files[i].name.split(".")[0]) + "-" + randomText(8) + "." + ext;
            }else{
                ext = files[i].name.split(".").slice(-1)[0];
                formData.append(`files`, files[i], ChangeToSlug(files[i].name.split(".")[0]) + "-" + randomText(8) + "." + ext);
                textFile = textFile + ', ' + ChangeToSlug(files[i].name.split(".")[0]) + "-" + randomText(8) + "." + ext;
            }
        }

        try {
            const response = await postAPI(`${apiUrl}/file/upload-multiple?folder=contracts`, formData)
            if(response){
                var textDataFiles = ""
                response.data.urls.map((itemUrl, index) => {
                    const fileUrl = new URL(itemUrl);
                    if(index === 0){
                        textDataFiles = fileUrl.pathname
                    }else{
                        textDataFiles = textDataFiles + ', ' + fileUrl.pathname
                    }
                })
                setNewPartner( {...newPartner, contract_file: textDataFiles } )
            }
        } catch (error) {
            return error.response ? error.response : { success: false, message: 'Server error!' }
        }

    }

    return (
        <Modal show={showAddPartnerModal} onHide={closeDialog}
        size="full"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('Create New Partner')}
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit}>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className="form-group">
                                <label>{t('Name')}</label>
                                <input type="text" className="form-control" id="nameCruise" name="name" placeholder={t("Enter Your Name Partner")} value={name} onChange={onChangeNewPartnerForm} required aria-describedby='name-help' />
                                <Form.Text id='name-help' muted>{t('Required')}</Form.Text>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>{t('Type')}</label>
                                <select name="partner_type" value={partner_type} className="SelectBox form-control" onChange={onChangeNewPartnerForm} required>                                    
                                    <option label={t("Choose one")}></option>
                                    <option key='Travel Agent' value='Travel Agent'>
                                        Travel Agent
                                    </option>
                                    <option key='Online' value='Online'>
                                        Online
                                    </option>
                                    <option key='Hotel' value='Hotel'>
                                        Hotel
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label><strong>{t('Choose Prices')}</strong></label>
                        <Select isClearable
                            name="optionPrices"
                            onChange={handleChange.bind(this)}
                            options={optionPrices}
                            placeholder={t("Choose Prices")}
                            isMulti
                        />
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group">
                                <label>{t('Phone')}</label>
                                <input type="text" className="form-control" id="phone" name="phone" placeholder="0243 999 999" value={phone} onChange={onChangeNewPartnerForm} />
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <label>{t('Fax')}</label>
                                <input type="text" className="form-control" id="fax" name="fax" placeholder={t("Partner Fax")} value={fax} onChange={onChangeNewPartnerForm} />
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <label>{t('Hotline')}</label>
                                <input type="text" className="form-control" id="hotline" name="hotline" placeholder="0908 999 999" value={hotline} onChange={onChangeNewPartnerForm} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group">
                                <label>{t('Email')}</label>
                                <input type="text" className="form-control" id="email" name="email" placeholder={t("Email")} value={email} onChange={onChangeNewPartnerForm} />
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <label>{t('weblink')}</label>
                                <input type="text" className="form-control" id="weblink" name="weblink" placeholder={t("weblink")} value={weblink} onChange={onChangeNewPartnerForm} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group">
                                <label>{t('Address')}</label>
                                <input type="text" className="form-control" id="address" name="address" placeholder={t("Address")} value={address} onChange={onChangeNewPartnerForm} />
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <label>{t('City')}</label>
                                <input type="text" className="form-control" id="city" name="city" placeholder="Hà Nội" value={city} onChange={onChangeNewPartnerForm} />
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <label>{t('Assign To')}</label>
                                <select name="assign_to" value={assign_to} className="SlectBox form-control" onChange={onChangeNewPartnerForm} required>
                                    <option label={t("Choose one")}></option>
                                    {users ? users.map((user, key) => (
                                        <option key={key} value={user.id}>
                                            {user.name}
                                        </option>
                                    )) : null}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group">
                                <label>{t('Commission')}</label>
                                <input type="number" className="form-control" id="commission" name="commission" placeholder={t("No. Price")} value={commission} onChange={onChangeNumber} />
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <label>{t('Commission Note')}</label>
                                <input type="text" className="form-control" id="commission_note" name="commission_note" placeholder={t("Commission Note")} value={commission_note} onChange={onChangeNewPartnerForm} />
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <label>VAT</label>
                                <input type="number" className="form-control" id="vat" name="vat" placeholder={t("No. Price")} value={vat} onChange={onChangeNumber} />
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <label><strong>{t('File Contract')} </strong></label>
                                <input type="file" id="fileContract" onChange={handleChangeUploadAvatar.bind(this)} placeholder={t("Upload Contract")} multiple className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>{t('Description')}</label>
                        <textarea className="form-control" placeholder={t("Description")} rows="5" name='description' value={description} onChange={onChangeNewPartnerForm} spellCheck="false"></textarea>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> {t('Cancel')}</Button>
                    <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> {t('Save')}!!!</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default AddPartnerModal
