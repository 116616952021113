import { useState, useContext } from 'react';
import { CountryContext } from '../../../contexts/CountryContext';
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const AddCountryModal = () => {
    const { t } = useTranslation();
    // Context
    const {
        addCountry,
        showAddCountryModal,
        setShowAddCountryModal,
        setShowToast
    } = useContext(CountryContext)

    // State
    const [newCountry, setNewCountry] = useState({
        name: '',
        iso_code_2: '',
        iso_code_3: ''
    })

    const { name, iso_code_2, iso_code_3 } = newCountry
    const onChangeCountry = event => setNewCountry( {...newCountry, [event.target.name]: event.target.value } )

    const onSubmit = async event => {
        event.preventDefault()
        const response = await addCountry(newCountry)
        if (!response.error) {
            resetAddCountryData()
            setShowToast({show: true, message: t('Added successful!'), type: 'success'})
            return
        }
        setShowToast({show: true, message: response.error.ErrorCode, type : 'danger'})
    }

    const closeDialog = () => {
        resetAddCountryData()
    }

    const resetAddCountryData = () => {
        setNewCountry({
            name: '',
            iso_code_2: '',
            iso_code_3: ''
        })
        setShowAddCountryModal(false)
    }

    return (
        <Modal show={showAddCountryModal} onHide={closeDialog}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {t('Create New Country')}
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label><strong>{t('Name')}</strong></label>
                                <input type="text" className="form-control" id="nameCruise" name="name" value={name} placeholder={t("Country Name")} required aria-describedby='name-help' onChange={onChangeCountry} />
                                <Form.Text id='name-help' muted>{t('Required')}</Form.Text>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group">
                                <label><strong>Iso Code 2</strong></label>
                                <input type="text" className="form-control" id="iso_code_2" name="iso_code_2" value={iso_code_2} placeholder="VN" required aria-describedby='iso-code-2' onChange={onChangeCountry} />
                                <Form.Text id='iso-code-2' muted>{t('Required')}</Form.Text>
                            </div>
                        </div>
                        <div className="col-sm">
                            <div className="form-group">
                                <label><strong>Iso Code 3</strong></label>
                                <input type="text" className="form-control" id="iso_code_3" name="iso_code_3" value={iso_code_3} placeholder="VNM" required aria-describedby='iso-code-3' onChange={onChangeCountry} />
                                <Form.Text id='iso-code-3' muted>{t('Required')}</Form.Text>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-danger btn-with-icon" variant='secondary' onClick={closeDialog}><i className="fe fe-x-circle"></i> {t('Cancel')}</Button>
                    <Button className="btn btn-primary btn-with-icon" variant='primary' type='submit'><i className="fe fe-save"></i> {t('Save')}!!!</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default AddCountryModal
