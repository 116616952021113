import { Fragment, useEffect, useContext, useState } from 'react';
import { CruiseContext } from '../../../contexts/CruiseContext';
import imageCruise from '../../../assets/img/cruises/perla.jpg';
import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import Moment from 'moment';
import { Bar } from "react-chartjs-2";
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { findOptionItem, getMonthOptions, getYearOptions } from '../../../utils/common';
import { getAPI } from '../../../utils/api';
import { apiUrl } from '../../../contexts/constants';

const ChartsPartner = props => {
    const { t } = useTranslation();
    //const partnerID = props.partnerIdProps

    const currentYear = new Date().getFullYear();
	const currentMonth = new Date().getMonth() + 1;

    const [month, setMonth] = useState(currentMonth)
    const [year, setYear] = useState(currentYear)
    
    const [bookings, setBookings] = useState([]);
    useEffect(() => {
		if (!month || !year || !props.partnerID) return;
		async function bookingData(){
			try {
				const response = await getAPI(`${apiUrl}/report/bookings`, `?filterFields=partner_id&filterFieldValues=${props.partnerID}&month=${month}&year=${year}`)
				setBookings(response.data.booking_report? response.data.booking_report: [])
			} catch (error) {
				return error.response ? error.response : {success: false, message: 'Server error!'}
			}
		}

		bookingData();
	}, [month, year, props.partnerID]);

    const { 
        cruiseState: { cruises },
        getCruises
    } = useContext(CruiseContext)

    const renderTooltip = (name) => (
        <Tooltip id="button-tooltip">
            {name}
        </Tooltip>
    );

    // Start: Get all cruise , []
    useEffect( () => getCruises(), []) // eslint-disable-line react-hooks/exhaustive-deps
    const [monthAdults, setMonthAdults] = useState([])
    const [monthChild, setMonthChild] = useState([])
    const [monthInfants, setMonthInfants] = useState([])

    const [_labelMonthCharts, setLabelMonthCharts] = useState([]);
    useEffect(() => {
        if (!bookings) return;

        //
        let labelMonthCharts = [];
        let numberMonthCharts = []
        //let checkMonth = 0
        
        let dataNumberMonths = []
        let dataNumberYear = []

        //booking

        Array.from(Array(12), (e, i) => {
            numberMonthCharts.push((i +1) + '-' + year);
        })
        console.log('numberMonthCharts', numberMonthCharts);
        var sorted = numberMonthCharts.sort(function(a,b) {
            a = a.split("-");
            b = b.split("-")
            return new Date(a[1], a[0], 1) - new Date(b[1], b[0], 1)
        });

        sorted.map((item) => {
            var data = item.split("-")
            var numberMonth = Number(data[0])
            var numberYear = Number(data[1])

            if (Number(numberMonth) === 1){
                labelMonthCharts.push(t('January') + ` - ${numberYear}`)
            }else if (Number(numberMonth) === 2){
                labelMonthCharts.push(t('February') + ` - ${numberYear}`)
            }else if (Number(numberMonth) === 3){
                labelMonthCharts.push(t('March') + ` - ${numberYear}`)
            }else if (Number(numberMonth) === 4){
                labelMonthCharts.push(t('April') + ` - ${numberYear}`)
            }else if (Number(numberMonth) === 5){
                labelMonthCharts.push(t('May') + ` - ${numberYear}`)
            }else if (Number(numberMonth) === 6){
                labelMonthCharts.push(t('June') + ` - ${numberYear}`)
            }else if (Number(numberMonth) === 7){
                labelMonthCharts.push(t('July') + ` - ${numberYear}`)
            }else if (Number(numberMonth) === 8){
                labelMonthCharts.push(t('August') + ` - ${numberYear}`)
            }else if (Number(numberMonth) === 9){
                labelMonthCharts.push(t('September') + ` - ${numberYear}`)
            }else if (Number(numberMonth) === 10){
                labelMonthCharts.push(t('October') + ` - ${numberYear}`)
            }else if (Number(numberMonth) === 11){
                labelMonthCharts.push(t('November') + ` - ${numberYear}`)
            }else if (Number(numberMonth) === 12){
                labelMonthCharts.push(t('December') + ` - ${numberYear}`)
            }
        })
        setLabelMonthCharts(labelMonthCharts);
        //
        let _monthAdults = [];
        let _monthChild = [];
        let _monthInfants = [];
        let totalAdultsArr = [];
        
        bookings.map((booking) => {
            var checkDateBooking = Moment(booking.start_date, 'YYYY/MM/DD')
            
            var bookingMonth = checkDateBooking.format('M');
            var bookingYear = checkDateBooking.format('YYYY');
            
            if (!totalAdultsArr[bookingMonth]) {
                totalAdultsArr[bookingMonth] = {}
            }

            if (!totalAdultsArr[bookingMonth][bookingYear]) {
                totalAdultsArr[bookingMonth][bookingYear] = [booking];
            } else {
                totalAdultsArr[bookingMonth][bookingYear].push(booking);
            }
        })
    
        let arrDataAllBookings = []
    
        totalAdultsArr.map((item, index) => {
            if(item){
                arrDataAllBookings[index] = item
            }
        })
    
        Array.from(Array(12), (e, i) => {
            var item = sorted[i]
            var dataDate = item.split("-")
            var nam = Number(dataDate[1])
            var thang = Number(dataDate[0])
            var chay = thang
            
            if(arrDataAllBookings[chay] !== undefined){
                var dataYears = arrDataAllBookings[chay]
                if(dataYears[nam] !== undefined){
                    var dataBookings = dataYears[nam]
                    var totalAdults = 0
                    var totalChildren = 0
                    var totalInfant = 0
                    dataBookings.map((booking) => {
                        totalAdults += booking.adults
                        if(booking.children !== undefined){
                            totalChildren += booking.children
                        }
                        if(booking.children11 !== undefined){
                            totalChildren += booking.children11
                        }
                
                        if(booking.infants !== undefined){
                            totalInfant += booking.infants
                        }
                    })
    
                    _monthAdults.push(totalAdults)
                    _monthChild.push(totalChildren)
                    _monthInfants.push(totalInfant)
                }else{
                    _monthAdults.push(0)
                    _monthChild.push(0)
                    _monthInfants.push(0)
                }
            }else{
                _monthAdults.push(0)
                _monthChild.push(0)
                _monthInfants.push(0)
            }
        })

        setMonthAdults(_monthAdults);
        setMonthChild(_monthChild);
        setMonthInfants(_monthInfants);
    }, [bookings]);

    const arrMonths = getMonthOptions(t);

    const arrYears = getYearOptions();

    const handleChangeDay = (newValue, actionMeta) => {
        const dataDay = newValue;
        if(actionMeta.name === `currentMonth` ){
            setMonth(dataDay.value)
        }

        if(actionMeta.name === `currentYear` ){
            setYear(dataDay.value)
        }
    }

    return (
        <Fragment>
            <div className="row mt-5">
                <div className="col-md-4">
                    <h3 className="pull-left">{t('Number of Guests in 12 months')}</h3>
                </div>
                <div className="col-md-8">
                    <div className="cruises-content">
                        <div className="item-cruise">
                            {cruises.map((cruise) => (
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip(cruise.name)}
                                    key={cruise.id}
                                >
                                    <Button className="btn-image"><img src={imageCruise} alt={cruise.name} /></Button>
                                </OverlayTrigger>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Row md={4}>
                <Col xs={2}>
                    <div className="form-group">
                        <label><strong>{t('Month')}</strong></label>
                        <Select isClearable
                            name="currentMonth"
                            defaultValue={findOptionItem(arrMonths, month)}
                            onChange={handleChangeDay.bind(this)}
                            options={arrMonths} 
                            placeholder="Month"
                        />
                    </div>
                </Col>
                <Col xs={2}>
                    <div className="form-group">
                        <label><strong>{t('Year')}</strong></label>
                        <Select isClearable
                            name="currentYear"
                            defaultValue={findOptionItem(arrYears, year)}
                            onChange={handleChangeDay.bind(this)}
                            options={arrYears} 
                            placeholder="Year"
                        />
                    </div>
                </Col>
            </Row>
            <div className="row">
                <div className="col-md-12">
                <div className="style-bar">
                    <div className="style-bar-chart-partner">
                        <Bar
                            data={{
                            labels: _labelMonthCharts,
                            datasets: [
                                {
                                    label: t('Adults'),
                                    backgroundColor: "rgba(240,197,65,.6)",
                                    borderColor: "rgba(240,197,65,.6)",
                                    borderWidth: 1,
                                    stack: 1,
                                    //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                                    //hoverBorderColor: 'rgba(255,99,132,1)',
                                    data: monthAdults
                                },
                                {
                                    label: t('Children'),
                                    backgroundColor: "rgba(46,205,153,.6)",
                                    borderColor: "rgba(46,205,153,.6)",
                                    borderWidth: 1,
                                    stack: 1,
                                    //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                                    //hoverBorderColor: 'rgba(255,99,132,1)',
                                    data: monthChild
                                },
                                {
                                    label: t('Infants'),
                                    backgroundColor: "rgba(78,157,230,.6)",
                                    borderColor: "rgba(78,157,230,.6)",
                                    borderWidth: 1,
                                    stack: 1,
                                    //hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                                    //hoverBorderColor: 'rgba(255,99,132,1)',
                                    data: monthInfants
                                }
                                ]
                            }}
                            options={{
                                responsive: true,
                                legend: { display: false },
                                title: {
                                    display: true,
                                    text: t("Predicted world population (millions) in 2050")
                                }
                            }}
                        />
                    </div>    
                </div> 
                </div>
            </div>
        </Fragment>
    )
}

export default ChartsPartner
