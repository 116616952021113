import { Fragment, useContext } from 'react';
import { TransportationPickUpContext } from '../../contexts/TransportationPickUpContext';
import ListTransportationPickUp from './ListTransportationPickUp';
import UpdateTransportationPickUp from './UpdateTransportationPickUp';
import { AuthContext } from '../../contexts/AuthContext';
import { ROLES, PERMISSIONS, NO_PERMISSION } from '../../contexts/constants';
import { useTranslation } from 'react-i18next';

const TransportationPickUp = () => {
	const { setShowAddTransportationPickUpModal } = useContext(TransportationPickUpContext);
	const { t } = useTranslation();
	const {
		authState: { user },
	} = useContext(AuthContext);


	if (!user) {
		return NO_PERMISSION;
	}

	if (
		!(user.Permissions.includes(PERMISSIONS.GET_TRANSPORTATION_PICKUP) || user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR))
	) {
		return NO_PERMISSION;
	}
	return (
		<Fragment>
			<div className='horizontal-mainwrapper container clearfix'>
				<div className='explain-dashboard mt-5'>
					<div className='explain-header mb-3'>
						<div className='row'>
							<div className='col-md-4'>
								<h3 className='pull-left'>{t('Transportation PickUp')}</h3>
							</div>
							<div className='col-md-2 ml-auto'>
								{user.Permissions.includes(PERMISSIONS.INSERT_TRANSPORTATION_PICKUP) ||
								user.Roles.includes(ROLES.SUPPER_ADMINISTRATOR) ? (
									<button
										className='right-content btn btn-success btn-block'
										onClick={setShowAddTransportationPickUpModal.bind(this, true)}
									>
										<i className='fe fe-plus-circle'></i> {t('Add Transportation PickUp')}
									</button>
								) : null}
							</div>
						</div>
						<hr />
						
					</div>
					<ListTransportationPickUp />
					<UpdateTransportationPickUp />
					<div className='margin-bottom-150'></div>
				</div>
			</div>
		</Fragment>
	);
};

export default TransportationPickUp;
